import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";

import { SafetyRule } from "../../../models/safety-rule";

export interface SafetyRuleModalComponentData {
    safetyRules: SafetyRule[];
}

@Component({
    templateUrl: "./safety-rule-modal.component.html",
    styleUrls: ["./safety-rule-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SafetyRuleModalComponent extends BaseModalComponent {
    public data!: SafetyRuleModalComponentData;
}
