<div class="item" *transloco="let t; read: 'knowledgeBank.search.results'">
    <a class="item__anchor" (mousedown)="mousedown($event)" [routerLink]="data.url">
        <h2 class="item__anchor__heading" [innerHTML]="data.heading"></h2>

        <div *ngIf="data.excerpt?.length > 1" class="item__anchor__excerpt" [innerHTML]="data.excerpt"></div>
    </a>

    <div *ngIf="data.extraContent?.length > 0" class="item__media" [class.item__media--expanded]="showAllMedia">
        <div class="item__media__items">
            <div class="item__media__items__inner">
                <knowledge-bank-search-results-extra-content [data]="extraItem" *ngFor="let extraItem of data.extraContent">
                </knowledge-bank-search-results-extra-content>
                <div *ngIf="showAllMedia" class="item__media__toggle-all" (click)="toggleAllMedia()">{{ t("media.showLess") }}
                </div>
            </div>
        </div>
        <div *ngIf="!showAllMedia" class="item__media__toggle-all" (click)="toggleAllMedia()">{{ t("media.showAll", { itemTotal: data.extraContent?.length })
            }}</div>
    </div>

    <div class="item__footer">
        <div class="item__footer__item">
            <span class="item__footer__item__label">{{ t("startPublish")}}:</span>
            <span class="item__footer__item__date">{{ data.startPublish | haDate:"fullDateShortMonth" }}</span>
        </div>
        <div class="item__footer__item">
            <span class="item__footer__item__label">{{ t("changed")}}:</span>
            <span class="item__footer__item__date">{{ data.changed | haDate:"fullDateShortMonth" }}</span>
        </div>
    </div>

    <hr class="item__divider">
</div>
