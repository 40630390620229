/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injector } from "@angular/core";
import { camelToKebabCase } from "@ha/util/common";
import { TRANSLOCO_SCOPE, TranslocoService } from "@ngneat/transloco";


/**
 * Provider factory factory to provide a scope, alias and loader for a application module
 *
 * @param {string} module
 * @returns
 */
export const moduleTranslationProviderFactory = (module: string) => {
    return {
        provide: TRANSLOCO_SCOPE,
        useFactory: (translocoService: TranslocoService) => {
            return {
                scope: module,
                loader: scopeLoader(translocoService, module)
            };
        },
        deps: [TranslocoService, Injector]
    };
};

/**
 * Loader to lazy load a application modules i18n folders.
 *
 * @param {TranslocoService} translocoService
 * @param {string} module
 * @returns
 */
export const scopeLoader = (translocoService: TranslocoService, module: string) => {
    const languages = (translocoService.getAvailableLangs() as string[]);

    const kebabModule = camelToKebabCase(module);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return languages.reduce((acc: any, lang: string) => {
        // eslint-disable-next-line function-paren-newline
        acc[lang] = () => import(`apps/app/src/app/${kebabModule}/i18n/${kebabModule}.${lang}.json`);
        return acc;
    }, {});
};

// If we put these in the import() above we can configure Webpack to put all imports in the same module
// We should do this if the web server don't support http2 to reduce number of JS-files loaded
/* webpackMode: "lazy-once" */
/* webpackChunkName: "i18n" */
