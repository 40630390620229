import { Selector } from "@ngxs/store";

import { HaError } from "../../models/error.model";
import { ErrorsState, ErrorsStateModel } from "../../states/errors.state";

export class ErrorSelectors {
    @Selector([ErrorsState])
    public static errors(state: ErrorsStateModel): HaError[] {
        return state.errors;
    }

    @Selector([ErrorSelectors.errors])
    public static firstError(errors: HaError[]): HaError {
        return errors[0];
    }
}
