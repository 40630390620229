import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HaUiFormsModule } from "@ha/ui/forms";
import { libTranslationProviderFactory } from "@ha/util/common";
import { TranslocoModule } from "@ngneat/transloco";

import { routes } from "./feature-permit.routes";
import { PermitRootModule } from "./permit-root/permit-root.module";
import { PermitModule } from "./permit/permit.module";
import { SharedModule } from "./shared/shared.module";
import { UserAgreementModule } from "./user-agreement/user-agreement.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HaUiFormsModule,
        PermitModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        SharedModule,
        TranslocoModule,
        UserAgreementModule,
        PermitRootModule
    ],
    exports: [],
    declarations: [
    ],
    providers: [
        libTranslationProviderFactory("permit", "feature/permit")
    ]
})
export class FeaturePermitModule {}
