export namespace UserStateActions {
    export class GetUser {
        public static readonly type = "[User] GetUser";
    }

    export class GetAgreement {
        public static readonly type = "[User] GetUserAccountAgreement";
    }

    export class GetAgreementLatestPublishedVersionNumber {
        public static readonly type = "[User] GetAgreementLatestPublishedVersionNumber";
    }

}
