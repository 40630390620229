import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SidebarActions, SidebarState } from "@ha/ui/navigation";
import { PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CurrentUserState } from "../../states/current-user.state";

@Component({
    selector: "header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Select(SidebarState.personalPanelExpanded)
    public personalPanelExpanded$: Observable<boolean>;

    @Select(CurrentUserState.fullName)
    public name$: Observable<string>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    constructor(private store: Store) {}

    public togglePersonalPanelOpen(): void {
        this.store.dispatch(new SidebarActions.TogglePersonalPanelExpanded());
    }
}
