<div class="card-qr-scanner" *transloco="let t; read:'permit.team.search.cardQrScanner'">
    <zxing-scanner #scanner class="card-qr-scanner__scanner" [formats]="allowedFormats" [torch]="isTorchOn" (torchCompatible)="torchCompatibleHandler($event)"
                   (camerasFound)="camerasFoundHandler($event)" (camerasNotFound)="camerasNotFoundHandler($event)" (scanSuccess)="scanSuccessHandler($event)"
                   (scanError)="scanErrorHandler($event)" (scanFailure)="scanFailureHandler($event)" (scanComplete)="scanCompleteHandler($event)">
    </zxing-scanner>

    <div class="card-qr-scanner__content">
        <button class="card-qr-scanner__content__change-camera-button" *ngIf="canChangeCamera" (click)="changeCamera()">{{ t("changeCamera") }}</button>
        <button class="card-qr-scanner__content__toggle-torch-button" *ngIf="isTorchCompatible" (click)="toggleTorch()">{{ t("toggleTorch") }}</button>
    </div>
</div>
