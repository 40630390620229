import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";

import { SharedModule } from "../shared/shared.module";
import { CertificateCardFaceBackComponent } from "./components/certificate-card-face-back.component";
import { CertificateCardFaceComponent } from "./components/certificate-card-face.component";
import { CertificateCardComponent } from "./components/certificate-card.component";
import { CertificateState } from "./state/certificate.state";

@NgModule({
    declarations: [
        CertificateCardComponent,
        CertificateCardFaceComponent,
        CertificateCardFaceComponent,
        CertificateCardFaceBackComponent,
    ],
    imports: [CommonModule, SharedModule, NgxsModule.forFeature([CertificateState])],
    exports: [CertificateCardComponent],
})
export class CertificateCardModule {}
