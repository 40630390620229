import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PermitStep, PhotoRequirement } from "@ha/data/basic";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { filter, Observable, pairwise, startWith } from "rxjs";

import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitStepState, PermitStepStateModel } from "../../../permit/states/permit-step.state";
import { CustomerPermitTemplate } from "../../models/customer-permit-template.model";
import { FixedTemplate } from "../../models/fixed-template.model";
import { Permit } from "../../models/permit.model";
import { WorkplaceDetails } from "../../models/workplace-details.model";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-permit-create",
    templateUrl: "permit-create.component.html",
    styleUrls: ["permit-create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitCreateComponent implements OnInit {
    @Input() public permitId: number | undefined;

    @Select(PermitStepSelectors.currentStep)
    public currentStep$: Observable<CurrentStep>;

    @Select(PermitCreateSelectors.fixedTemplateDetails)
    public fixedTemplate$: Observable<FixedTemplate>;

    @Select(PermitCreateSelectors.customerPermitTemplate)
    public customerTemplate$: Observable<CustomerPermitTemplate>;

    @Select(PermitCreateSelectors.workplaceDetails)
    public workplaceDetails$: Observable<WorkplaceDetails>;

    @Select(PermitCreateSelectors.permit)
    public permit$: Observable<Permit>;

    public PermitStep = PermitStep;
    public PhotoRequirement = PhotoRequirement;

    constructor(private store: Store, private activatedRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitCreateActions.GetActiveFixedTemplate());

        this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
            const permitId = parseInt(params.permitId as string);
            if (isNaN(permitId) || !params.permitId) {
                this.store.dispatch(new PermitStepActions.StartCreate());
            }
        });

        this.store.select(PermitStepState).pipe(
            untilDestroyed(this),
            startWith(undefined),
            pairwise(),
            filter(([previousState, currentState]: PermitStepStateModel[]) => {
                // Cancel if no permit id
                if (!currentState.permitId) {
                    return false;
                }

                // If previous state was empty we want to get permit
                if (!previousState) {
                    return true;
                }

                // If permit id have changed we want to get permit
                if (currentState.permitId !== previousState.permitId) {
                    return true;
                }

                return false;
            }),
        ).subscribe(([, currentSate]: PermitStepStateModel[]) => {
            this.store.dispatch(new PermitCreateActions.GetPermit(currentSate.permitId));
        });
    }
}
