import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { UserAgreement } from "../models/user-agreement.model";
import { UserDetails } from "../models/user-details.model";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private httpClient: HttpClient) {}

    public getUserDetails() {
        return this.httpClient.get<UserDetails>("/api/user/UserDetails");
    }

    public getAgreement() {
        return this.httpClient.get<UserAgreement>("/api/user/Agreement");
    }

    public getAgreementLatestPublishedVersionNumber() {
        return this.httpClient.get<number>("/api/user/AgreementLatestVersionNumber");
    }
}
