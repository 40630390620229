<ng-container *transloco="let t; read:'permit'">
    <div class="root" *ngIf="photos$ | async as photos">
        <div class="root__question-text" [innerHTML]="t('permitPhoto.heading')"></div>

        <div *ngIf="photoRequirement === PhotoRequirement.Required && photos.length < 1" class="root__photo-required">{{ t("permitPhoto.photoRequired") }}</div>
        <div *ngIf="photoRequirement === PhotoRequirement.Prohibited" class="root__photo-prohibited">
            {{ t((isDemo$ | async) === true ? "permitPhoto.demoPhoto" : "permitPhoto.photoProhibited") }}</div>
        <div *ngIf="photoRequirement !== PhotoRequirement.Prohibited" class="root__photo-warning">{{ t("permitPhoto.photoWarning") }}</div>

        <div *ngIf="photos.length > 0" class="root__photo-list">
            <div *ngFor="let photo of photos" class="root__photo-list__item">
                <img class="root__photo-list__item__image" [src]="photo.url" (click)="openModal(photo)">
            </div>
        </div>

        <input #fileUpload class="root__file-input" type="file" multiple accept="image/*" capture="environment" (change)="processSelectedImages(fileUpload)">

        <button (click)="fileUpload.click()" class="root__upload-button"
                [class.root__upload-button--disabled]="PhotoRequirement.Prohibited === photoRequirement">
            {{t((photos$ | async)?.length > 0 ? "permitPhoto.addAdditionalPhoto" : "permitPhoto.yesAddPhoto") }}
        </button>

        <div class="root__actions">
            <button class="root__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
            <button class="root__actions__continue" type="button" (click)="continue()" [class.root__actions__continue--disabled]="!canContinue()">
                {{ t("next") }}
            </button>
        </div>
    </div>
</ng-container>
