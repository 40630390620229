import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";

import { Bookmark } from "../../models/bookmark";
import { BookmarksState } from "../../state/bookmarks.state";

@Component({
    selector: "bookmarks",
    templateUrl: "./bookmarks.component.html",
    styleUrls: ["./bookmarks.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarksComponent {
    @Select(BookmarksState.bookmarks)
    public bookmarks$: Observable<Bookmark[]>;

    @Input() public direction: "horizontal" | "vertical" = null;
}
