<ng-container *transloco="let t; read:'permit.workplace.premium'">
    <!-- List items view -->
    <div class="item" (click)="stepInto(structureNode)"
            [class.item--disabled]="structureNode.childNodes?.length <= 0 && !structureNode.isWorkplaceContainer"
            [class.item--folder]="!structureNode.isWorkplaceContainer"
            *ngIf="(selectedNode$ | async) === undefined || (selectedNode$ | async)?.structureNodeId === structureNode.parentId">
        <div class="item__content">
            <div class="item__content__name">{{ structureNode.name }}</div>
            <div *ngIf="structureNode.isWorkplaceContainer && structureNode.emergencyLocation" class="item__content__emergency-location">
                {{ structureNode.emergencyLocation }}</div>
            <div *ngIf="!structureNode.isWorkplaceContainer" class="item__content__child-count">
                {{ structureNode.childNodes?.length + " " + t("items") }}
            </div>
        </div>

        <div class="item__arrow"></div>
    </div>

    <ng-container *ngIf="(selectedNode$ | async) as node">
        <!-- Loop children / reached bottom of tree and is at a WorkplaceContainer -->
        <ng-container *ngIf="node.childNodes?.length > 0 || node.isWorkplaceContainer">

            <div class="breadcrumbs" *ngIf="node.structureNodeId === structureNode.structureNodeId">
                <div class="breadcrumbs__crumb" *ngFor="let breadcrumb of breadcrumbs$ | async; let first = first; let last = last"
                            (click)="first ? stepInto(undefined) : stepInto(breadcrumb.node)">
                    <div class="breadcrumbs__crumb__name"
                                [class.breadcrumbs__crumb__name--current]="node.structureNodeId === breadcrumb.node.structureNodeId"
                                [class.breadcrumbs__crumb__name--first]="first">
                        {{ !first ? breadcrumb.name : "" }} <span class="breadcrumbs__crumb__name__separator">{{ !last ? "&rsaquo;&nbsp;" : "" }}</span>
                    </div>
                </div>
            </div>

            <div class="nodes">
                <pmt-workplace-premium-item class="nodes__child-nodes" *ngFor="let childNode of structureNode.childNodes"
                                            [structureNode]="childNode">
                </pmt-workplace-premium-item>
            </div>
        </ng-container>

        <!-- Workplace container view -->
        <ng-container *ngIf="node.structureNodeId === structureNode.structureNodeId && node.isWorkplaceContainer">
            <div class="workplaces">
                <ng-container *ngFor="let workplace of structureNode.workplaces">
                    <div class="workplaces__item" (click)="toggleWorkplace(workplace)" [class.workplaces__item--selected]="(selectedWorkplace$ | async)?.id === workplace.id">
                        {{ workplace.name }}
                    </div>
                </ng-container>

                <div *ngIf="structureNode.allowWorkplaceCreationFromPermit === true" class="workplaces__create-new" (click)="createNew()"
                     [attr.data-tooltip]="t('createNew')"></div>
            </div>

            <form [formGroup]="freeTextForm" ngxsForm="permitWorkplace.freeTextForm">
                <ha-input class="input" [label]="t('freeText')" formControlName="additionalInfo"></ha-input>
            </form>
        </ng-container>
    </ng-container>
</ng-container>
