import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DynamicEpiPropertyBaseComponent } from "@ha/feature/episerver";
import { Store } from "@ngxs/store";

import { KnowledgeBankStartPage } from "../../models/knowledge-bank-start-page";
import { KnowledgeBankActions } from "../../state/knowledge-bank.actions";

@Component({
    templateUrl: "knowledge-bank-start-page.component.html",
    styleUrls: ["knowledge-bank-start-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankStartPageComponent implements DynamicEpiPropertyBaseComponent, OnInit {
    public data: KnowledgeBankStartPage;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new KnowledgeBankActions.SearchClearQuery());
    }
}
