<div class="root" *transloco="let t; read:'permit'">
    <h2 class="root__question-text" [innerHTML]="emergencyLocationSettings.translations | haTranslate"></h2>
    <pmt-safety-rule class="root__safety-rule-anchor" [safetyRules]="safetyRules$ | async">
    </pmt-safety-rule>

    <form [formGroup]="form" [ngxsFormDebounce]="0" ngxsForm="permitCreate.emergencyLocationForm">
        <ha-input #emergencyLocationInput class="root__input" formControlName="emergencyLocation" [label]="t('emergencyLocation.emergencyLocation')">
        </ha-input>

        <ng-container *ngIf="(isPremium$ | async) === false || (allowGpsLocation$ | async) === true">
            <div class="root__location" *ngIf="!latitude" (click)="getLocation()">{{ t("emergencyLocation.getLocation") }}</div>
            <div class="root__location" *ngIf="latitude > 0" (click)="manualLocation()">{{ t("emergencyLocation.manualLocation") }}</div>
        </ng-container>
    </form>

    <pmt-permit-confirm-dialog class="root__alert" [alert]="emergencyLocationSettings.alert" (confirmedChange)="setAlertConfirmed($event)">
    </pmt-permit-confirm-dialog>

    <div class="root__actions">
        <button class="root__actions__back" type="button" (click)="cancel()">{{ t("cancel") }}</button>
        <button class="root__actions__continue" type="button" (click)="continue()" [class.root__actions__continue--disabled]="!canContinue">
            {{ t("next") }}
        </button>
    </div>
</div>
