import { Component, Input } from "@angular/core";

import { BaseControlValueAccessor, MakeValueAccessorProvider } from "../base-control-value-accessor.component";

@Component({
    selector: "ha-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"],
    providers: [MakeValueAccessorProvider(CheckboxComponent)],
})
export class CheckboxComponent extends BaseControlValueAccessor<boolean> {
    @Input() public label: string;
}
