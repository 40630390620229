<ng-container *ngIf="bookmarks$ | async as bookmarks">
    <ng-container *transloco="let t; read:'bookmarks'">
        <ul class="list" [class]="'list--' + direction">
            <li *ngFor="let item of bookmarks" class="list__item">
                <a [routerLink]="item.url" class="list__item__anchor">
                    <div class="list__item__anchor__icon"></div>
                    <div class="list__item__anchor__text" [innerHTML]="item.name"></div>
                </a>
            </li>
        </ul>

        <div *ngIf="bookmarks.length === 0" class="no-bookmarks">
            {{ t("noBookmarks") }}
        </div>
    </ng-container>
</ng-container>
