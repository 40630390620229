import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CheckpointDto } from "../../models/checkpoint-dto";
import { PermitDetailsActions } from "../../states/permit-details.action";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";

@Component({
    selector: "pmt-permit-checkpoints",
    templateUrl: "permit-checkpoints.component.html",
    styleUrls: ["permit-checkpoints.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitCheckpointsComponent implements OnInit {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.fixedRules)
    public fixedRules$: Observable<number[]>;

    @Select(PermitDetailsSelectors.fixedCheckpoints)
    public fixedCheckpoints$: Observable<CheckpointDto[]>;

    @Select(PermitDetailsSelectors.customerCheckpoints)
    public customerCheckpoints$: Observable<CheckpointDto[]>;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitDetailsActions.GetCheckpoints(this.permitId));
    }
}
