<!-- StructureNode -->
<div class="item" *ngIf="isStructureNode(searchResult) as node" (click)="stepInto(node)"
        [class.item--disabled]="node.childNodes?.length <= 0 && !node.isWorkplaceContainer"
        [class.item--workplace-container]="node.isWorkplaceContainer"
        [class.item--folder]="!node.isWorkplaceContainer">
    <div class="item__content">
        <div class="item__content__breadcrumbs">{{ node.hierarchy }}</div>
        <div class="item__content__name">{{ node.name }}</div>
        <div *ngIf="node.isWorkplaceContainer && node.emergencyLocation" class="item__content__emergency-location">{{ node.emergencyLocation }}</div>
    </div>
</div>

<!-- Workplace -->
<div class="item" *ngIf="isWorkplace(searchResult) as workplace" (click)="stepInto(workplace)" [class.item--workplace]="true">
    <div class="item__content">
        <div class="item__content__breadcrumbs">{{ workplace.hierarchy }}</div>
        <div class="item__content__name">{{ workplace.name }}</div>
    </div>
</div>
