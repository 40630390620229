import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ErrorsStateActions } from "@ha/ui/common";
import { Network } from "@ngx-pwa/offline";
import { Store } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { v4 as uuid } from "uuid";

@Injectable({ providedIn: "root" })
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store, protected network: Network) {}

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.url.startsWith("/api") || req.url.startsWith("api")) {
            req = req.clone({
                setHeaders: {
                    "x-correlation-id": uuid(),
                },
            });
        }

        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            // Only show error messages on 500
            if (error.status === 500) {
                this.store.dispatch(new ErrorsStateActions.New(
                    req.headers.get("x-correlation-id") ?? "",
                    error.status,
                    error.message,
                    error.error as string,
                ));
            }
            return throwError(error);
        }));
    }
}
