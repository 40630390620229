import { Injectable } from "@angular/core";
import { globalEnvironment } from "@ha/constants";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

@Injectable({ providedIn: "root" })
export class ApplicationInsightsMonitoringService {
    private appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                autoTrackPageVisitTime: true,
                instrumentationKey: globalEnvironment.instrumentationKey,
                enableAutoRouteTracking: true // option to log all route changes
            }
        });

        this.appInsights.loadAppInsights();
    }

    public logPageView(name?: string, url?: string): void { // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    public logEvent(name: string, properties?: { [key: string]: unknown }): void {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    public logMetric(name: string, average: number, properties?: { [key: string]: unknown }): void {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    public logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    public logTrace(message: string, properties?: { [key: string]: unknown }): void {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
