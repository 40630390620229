import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { StartPageSection } from "../../models/start-page-sections";
import { KnowledgeBankActions } from "../../state/knowledge-bank.actions";
import { KnowledgeBankState } from "../../state/knowledge-bank.state";

@Component({
    selector: "knowledge-bank-start-page-sections",
    templateUrl: "knowledge-bank-start-page-sections.component.html",
    styleUrls: ["knowledge-bank-start-page-sections.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankStartPageSectionsComponent implements OnInit {
    @Select(KnowledgeBankState.startPageSections)
    public startPageSections$: Observable<StartPageSection[]>;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new KnowledgeBankActions.GetStartPageSections());
    }
}
