import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";

@Directive({
    selector: "[ctrlClick]",
})
export class CtrlClickDirective implements OnInit, OnDestroy {
    private unsubscribe: (() => void) | undefined;

    @Output()
    public ctrlClick = new EventEmitter();

    constructor(private readonly renderer: Renderer2, private readonly element: ElementRef) {}

    public ngOnInit(): void {
        this.unsubscribe = this.renderer.listen(this.element.nativeElement, "click", (event: MouseEvent) => {
            if (event.ctrlKey) {
                event.preventDefault();
                event.stopPropagation();
                // unselect accidentally selected text (browser default behaviour)
                document.getSelection()?.removeAllRanges();

                this.ctrlClick.emit(event);
            }
        });
    }

    public ngOnDestroy(): void {
        if (!this.unsubscribe) {
            return;
        }

        this.unsubscribe();
    }
}
