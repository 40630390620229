import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { PermitUserActions } from "../../shared/states/permit-user.action";
import { SubscriptionInvitation } from "../models/subscription-invitation";
import { SubscriptionsService } from "../services/subscriptions.service";
import { SubscriptionsActions } from "./subscriptions.actions";

export interface SubscriptionsStateModel {
    activeAndPendingSubscriptions: SubscriptionInvitation[];
}

@Injectable()
@State<SubscriptionsStateModel>({
    name: "subscriptions",
})
export class SubscriptionsState {
    constructor(private store: Store, private subscriptionsService: SubscriptionsService) {}

    @Action(SubscriptionsActions.GetActiveAndPendingSubscriptions)
    public getActiveAndPendingSubscriptions(ctx: StateContext<SubscriptionsStateModel>): Observable<SubscriptionInvitation[]> {
        return this.subscriptionsService.getActiveAndPendingSubscriptions().pipe(tap((activeAndPendingSubscriptions) => {
            ctx.patchState({
                activeAndPendingSubscriptions: activeAndPendingSubscriptions
            });
        }));
    }

    @Action(SubscriptionsActions.AnswerInvitation)
    public answerInvitation(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionsActions.AnswerInvitation): Observable<boolean> {
        return this.subscriptionsService.answerInvitation(action.subscriptionInvitation, action.accept).pipe(tap((response) => {
            if (response) {
                ctx.dispatch(new PermitUserActions.GetCurrentPerson());
            }
        }));
    }
}
