import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitDetailsSelectors } from "../../states/permit-details.selectors";
import {
    ExportPermitEmailModalComponent,
    ExportPermitEmailModalComponentData,
} from "../export-permit-email-modal/export-permit-email-modal.component";

@UntilDestroy()
@Component({
    selector: "pmt-export-permit",
    templateUrl: "./export-permit.component.html",
    styleUrls: ["./export-permit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportPermitComponent {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.currentUserIsIssuer)
    public currentUserIsIssuer$: Observable<boolean>;

    public PersonRole = PersonRole;

    public waitingForDownload = false;

    public get pdfUrl() {
        return `/api/Permit/GeneratePdf?permitId=${this.permitId}`;
    }

    constructor(private store: Store, private haModalService: HaModalService) {}

    public openExportPermitEmailModal(): void {
        this.haModalService.open(ExportPermitEmailModalComponent, true, <ExportPermitEmailModalComponentData>{ permitId: this.permitId });
    }

    public beginDownload(): void {
        this.waitingForDownload = true;
    }

    public endDownload(): void {
        this.waitingForDownload = false;
    }
}
