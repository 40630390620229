<ng-container (click)="hideAllMenus()">
    <ha-dimmer *ngIf="(menuVisible$ | async)" (click)="hideAllMenus()">
    </ha-dimmer>

    <ng-container *transloco="let t; read: 'menu'">
        <div *ngIf="menuVisible$ | async" class="hamburger-menu" @slideUpDownAnimation>
            <a routerLink="/" aria-label="home" routerLinkActive="hamburger-menu__item--selected" [routerLinkActiveOptions]="{exact: true}"
               class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("start") }}</div>
            </a>
            <a *ngIf="isHotworker$ | async" [routerLink]="applicationPaths.Certificate.Root" aria-label="certificate"
               routerLinkActive="hamburger-menu__item--selected" [routerLinkActiveOptions]="{exact: true}" class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("myCertificate") }}</div>
            </a>
            <a *ngIf="isEducationParticipant$ | async" [routerLink]="applicationPaths.Education.Root" aria-label="education"
               routerLinkActive="hamburger-menu__item--selected" [routerLinkActiveOptions]="{exact: false}" class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("myEducation") }}</div>
            </a>
            <a [routerLink]="applicationPaths.Permit.Root" aria-label="permit" routerLinkActive="hamburger-menu__item--selected"
               [routerLinkActiveOptions]="{exact: true}" class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("permit") }}</div>
            </a>
            <a [routerLink]="applicationPaths.KnowledgeBank.Root" aria-label="knowledge bank" routerLinkActive="hamburger-menu__item--selected"
               [routerLinkActiveOptions]="{exact: false}" class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("knowledgeBank") }}</div>
            </a>
            <a [routerLink]="applicationPaths.PublicEducationEvents" aria-label="public education events" routerLinkActive="hamburger-menu__item--selected"
               [routerLinkActiveOptions]="{exact: false}" class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("publicEducationEvents") }}</div>
            </a>

            <hr class="hamburger-menu__divider" />

            <a [routerLink]="applicationPaths.Support" aria-label="customer support" routerLinkActive="hamburger-menu__item--selected"
               [routerLinkActiveOptions]="{exact: true}" class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("support") }}</div>
            </a>
            <div (click)="openApplicationModal()" aria-label="switch-application" class="hamburger-menu__item">
                <div class="hamburger-menu__item__icon"></div>
                <div class="hamburger-menu__item__text">{{ t("switchApplication") }}</div>
            </div>
        </div>

        <div class="menu" [class.menu--open]="menuVisible$ | async">
            <a routerLink="/" aria-label="home" routerLinkActive="menu__item--selected" [routerLinkActiveOptions]="{exact: true}" class="menu__item"></a>
            <a [routerLink]="applicationPaths.Certificate.Root" aria-label="certificate" routerLinkActive="menu__item--selected"
               [routerLinkActiveOptions]="{exact: true}" class="menu__item" [class.menu__item--disabled]="(isHotworker$ | async) === false"></a>

            <a (click)="toggleHamburgerMenu()" aria-label="menu" routerLinkActive="menu__item--selected" class="menu__item menu__item--hamburger"></a>

            <a [routerLink]="applicationPaths.Permit.Root" aria-label="permit" routerLinkActive="menu__item--selected" [routerLinkActiveOptions]="{exact: true}"
               class="menu__item"></a>

            <a [routerLink]="applicationPaths.KnowledgeBank.Root" aria-label="knowledge bank" routerLinkActive="menu__item--selected"
               [routerLinkActiveOptions]="{exact: true}" class="menu__item" [routerLinkActiveOptions]="{exact: false}"></a>
        </div>
    </ng-container>
</ng-container>
