import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { VimeoMetaData } from "./vimeo-meta-data";
import { VimeoVideoService } from "./vimeo-video.service";

@UntilDestroy()
@Component({
    selector: "vimeo-video-link",
    templateUrl: "./vimeo-video-link.component.html",
    styleUrls: ["./vimeo-video-link.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VimeoVideoLinkComponent {
    @Input() public url: string;
    public metaData: VimeoMetaData;

    constructor(private vimeoVideoService: VimeoVideoService, private cdr: ChangeDetectorRef) {}

    public getVideoMetadata(): void {
        this.vimeoVideoService.getVideoMetadata(this.url)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.metaData = data;
                this.cdr.markForCheck();
            });
    }
}
