import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NewsItem } from "@ha/data/basic";
import { Store } from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";

@Component({
    selector: "news-puff",
    templateUrl: "./news-puff.component.html",
    styleUrls: ["./news-puff.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsPuffComponent {
    @Input()
    public item: NewsItem;

    public isOpen = false;
    public get colorClass() {
        return "puff--" + this.item.color;
    }

    public get hasUrl() {
        return this.item.url;
    }

    public onClick() {
        if (this.item.url) {
            const url = this.item.url;
            if (Array.isArray(url)) {
                this.store.dispatch(new Navigate(url));
            } else {
                location.href = url;
            }
        }
    }

    constructor(private store: Store) {}

    public openCard() {
        this.isOpen = !this.isOpen;
    }


    // public onClickLink() {
    //     if (this.item.url) {
    //         const url = this.item.url;
    //         if (Array.isArray(url)) {
    //             this.store.dispatch(new Navigate(url));
    //         } else {
    //             location.href = url;
    //         }
    //     } else {
    //         this.isOpen = false;
    //     }
    // }
}
