import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitPaths } from "../../../permit-paths";
import { PermitBasicDetailsDto } from "../../models/permit-basic-details.model";
import { PermitDetailsActions } from "../../states/permit-details.action";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";

@Component({
    selector: "pmt-permit-completed",
    templateUrl: "./permit-completed.component.html",
    styleUrls: ["./permit-completed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitCompletedComponent {
    @Select(PermitDetailsSelectors.basicDetails)
    public basicDetails$: Observable<PermitBasicDetailsDto>;

    public PermitPaths = PermitPaths;

    constructor(private store: Store) { }

    public refresh(): void {
        this.store.dispatch(new PermitDetailsActions.ShowPermitStarted(false));
    }
}
