import { Component, Input } from "@angular/core";

import { SelectionList } from "../../models/selection-list.model";
import { BaseControlValueAccessor, MakeValueAccessorProvider } from "../base-control-value-accessor.component";

@Component({
    selector: "input-radio-group",
    templateUrl: "./input-radio-group.component.html",
    styleUrls: ["./input-radio-group.component.scss"],
    providers: [MakeValueAccessorProvider(InputRadioGroupComponent)],
})
export class InputRadioGroupComponent extends BaseControlValueAccessor<string> {
    @Input() public list: SelectionList;

    private randomNameNumber = Math.floor(Math.random() * 10000);

    public get name(): string {
        return `${this.formControlName}-${this.randomNameNumber}`;
    }
}
