import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { untilDestroyed } from "@ngneat/until-destroy";
import { Store, Actions, ofActionCompleted } from "@ngxs/store";
import { PermitListActions } from "../../states/permit-list.actions";

export interface RemovePlannedPermitModalComponentData {
    permitId: number;
    workplace: string;
}

@Component({
    templateUrl: "./remove-planned-permit-modal.component.html",
    styleUrls: ["./remove-planned-permit-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemovePlannedPermitModalComponent extends BaseModalComponent implements OnInit {
    public data: RemovePlannedPermitModalComponentData;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionCompleted(PermitListActions.RemovePlannedPermit), untilDestroyed(this)).subscribe(() => {
            this.closeModal();
            this.store.dispatch(new PermitListActions.GetAllPlanned());
        });
    }

    public removePermit() {
        this.store.dispatch(new PermitListActions.RemovePlannedPermit(this.data.permitId));
    }

    public close() {
        this.closeModal();
    }
}
