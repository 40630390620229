import { Component, ChangeDetectionStrategy, HostListener } from "@angular/core";

@Component({
    selector: "scroll-to-top",
    templateUrl: "./scroll-to-top.component.html",
    styleUrls: ["./scroll-to-top.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent {
    public showScrollTop: boolean;

    @HostListener("window:scroll", ["$event"])
    public toggleScrollTop(): void {
        if (window.pageYOffset > 300) {
            this.showScrollTop = true;
        } else {
            this.showScrollTop = false;
        }
    }

    public scrollTop(): void {
        window.scrollTo(0, 0);
    }
}
