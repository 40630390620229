import { Selector } from "@ngxs/store";

import { CustomerQuestion, FixedQuestion } from "../models/question.model";
import { PermitQuestionState, PermitQuestionStateModel } from "./permit-question.state";

export class PermitQuestionSelectors {
    @Selector([PermitQuestionState])
    public static fixedQuestion(state: PermitQuestionStateModel): FixedQuestion | undefined {
        return state?.fixedQuestion !== undefined ? state.fixedQuestion : undefined;
    }

    @Selector([PermitQuestionState])
    public static customerQuestion(state: PermitQuestionStateModel): CustomerQuestion | undefined {
        return state?.customerQuestion !== undefined ? state.customerQuestion : undefined;
    }

    @Selector([PermitQuestionState])
    public static loadingQuestion(state: PermitQuestionStateModel): boolean | undefined {
        return state?.loadingQuestion !== undefined ? state.loadingQuestion : undefined;
    }
}
