import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Workplace } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { IssuerStructureNode } from "../../../models/issuer-structure-node.model";
import { StructureNodeBreadcrumb } from "../../../models/structure-node-breadcrumb.model";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";
import { PermitWorkplaceSelectors } from "../../../states/permit-workplace.selectors";
import { CreateNewWorkplaceModalComponent, CreateNewWorkplaceModalComponentData } from "../create-new-workplace-modal/create-new-workplace-modal.component";

@Component({
    selector: "pmt-workplace-premium-item",
    templateUrl: "permit-workplace-premium-item.component.html",
    styleUrls: ["permit-workplace-premium-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitWorkplacePremiumItemComponent {
    @Input() public structureNode: IssuerStructureNode;

    @Select(PermitWorkplaceSelectors.selectedNode)
    public selectedNode$: Observable<IssuerStructureNode>;

    @Select(PermitWorkplaceSelectors.selectedNodeBreadcrumbs)
    public breadcrumbs$: Observable<StructureNodeBreadcrumb[]>;

    @Select(PermitWorkplaceSelectors.selectedWorkplace)
    public selectedWorkplace$: Observable<Workplace>;

    public freeTextForm = this.fb.group({ additionalInfo: "" });

    constructor(private store: Store, private modalService: HaModalService, private fb: FormBuilder) { }

    public stepInto(node: IssuerStructureNode): void {
        this.store.dispatch(new PermitWorkplaceActions.ToggleSelectedNode(node));
    }

    public toggleWorkplace(workplace: Workplace) {
        this.store.dispatch(new PermitWorkplaceActions.ToggleSelectedWorkplace(workplace));
    }

    public selectDefaultWorkplace() {
        this.store.dispatch(new PermitWorkplaceActions.SetPremiumDefaultWorkplace(this.structureNode));
    }

    public createNew(): void {
        this.modalService.open(
            CreateNewWorkplaceModalComponent,
            true,
            <CreateNewWorkplaceModalComponentData>{ workplaceContainer: this.structureNode }
        );
    }

    public formatBreadcrumbs(breadcrumbs: StructureNodeBreadcrumb[]): string {
        return breadcrumbs.map(_ => _.name).join(" > ");
    }
}
