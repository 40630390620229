import { SignPermitWithSignatureCommand } from "../../permit-team/models/sign-permit-with-signature-command";
import { CommentDto } from "../models/permit-comments-dto";

export namespace PermitDetailsActions {
    export class GetSummary {
        public static readonly type = "[PermitDetailsActions] GetSummary";
        constructor(public permitId: number) { }
    }

    export class GetNotifications {
        public static readonly type = "[PermitDetailsActions] GetNotifications";
        constructor(public permitId: number) { }
    }

    export class GetCheckpoints {
        public static readonly type = "[PermitDetailsActions] GetCheckpoints";
        constructor(public permitId: number) { }
    }

    export class GetComments {
        public static readonly type = "[PermitDetailsActions] GetComments";
        constructor(public permitId: number) { }
    }

    export class GetBasicDetails {
        public static readonly type = "[PermitDetailsActions] GetBasicDetails";
        constructor(public permitId: number) { }
    }

    export class GetCurrentUserRoles {
        public static readonly type = "[PermitDetailsActions] GetCurrentUserRole";
        constructor(public permitId: number) { }
    }

    export class CreateComment {
        public static readonly type = "[PermitDetailsActions] CreateComment";
        constructor(public permitId: number, public text: string) { }
    }

    export class RemoveComment {
        public static readonly type = "[PermitDetailsActions] RemoveComment";
        constructor(public comment: CommentDto) { }
    }

    export class ShowPermitStarted {
        public static readonly type = "[PermitDetailsActions] ShowPermitStarted";
        constructor(public show: boolean) { }
    }

    export class EndPermitWithSignature {
        public static readonly type = "[PermitDetailsActions] EndPermitWithSignature";
        constructor(public command: SignPermitWithSignatureCommand) { }
    }

    export class SendPdfMail {
        public static readonly type = "[PermitDetailsActions] SendExportMail";
        constructor(public permitId: number, public email: string) { }
    }
}
