<div class="navigation" *ngIf="navigationLinks$ | async as links">
    <hr class="navigation__divider">

    <div class="navigation__content" *ngIf="links.previousPageUrl">
        <div class="navigation__content__header">Föregående avsnitt:</div>
        <a class="navigation__content__anchor" [routerLink]="links.previousPageUrl">{{ links.previousPageTitle }}</a>
    </div>

    <div class="navigation__content" *ngIf="links.nextPageUrl">
        <div class="navigation__content__header">Nästa avsnitt:</div>
        <a class="navigation__content__anchor" [routerLink]="links.nextPageUrl">{{ links.nextPageTitle }}</a>
    </div>
</div>
