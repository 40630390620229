import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad";
import { NgModule } from "@angular/core";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsModule } from "@ngxs/store";
import { ZXingScannerModule } from "@zxing/ngx-scanner";

import { SharedModule } from "../shared/shared.module";
import { AddPersonModalComponent } from "./components/add-person-modal/add-person-modal.component";
import { CardQrScannerComponent } from "./components/card-qr-scanner/card-qr-scanner.component";
import { CreateNewPersonComponent } from "./components/create-new-person/create-new-person.component";
import { FindPersonComponent } from "./components/find-person/find-person.component";
import { FireWatcherNotNeededComponent } from "./components/firewatcher-not-needed/firewatcher-not-needed.component";
import { LastUsedPersonsListComponent } from "./components/last-used-persons-list/last-used-persons-list.component";
import { RemovePersonModalComponent } from "./components/remove-person-modal/remove-person-modal.component";
import { ScanCardModalComponent } from "./components/scan-card-modal/scan-card-modal.component";
import { StagePersonComponent } from "./components/stage-person/stage-person.component";
import { PermitTeamState } from "./states/permit-team.state";

@NgModule({
    imports: [
        SharedModule,
        NgxsFormPluginModule,
        NgxsModule.forFeature([PermitTeamState]),
        AngularSignaturePadModule,
        ZXingScannerModule,
    ],
    exports: [AddPersonModalComponent],
    declarations: [
        AddPersonModalComponent,
        CreateNewPersonComponent,
        FindPersonComponent,
        FireWatcherNotNeededComponent,
        LastUsedPersonsListComponent,
        CardQrScannerComponent,
        ScanCardModalComponent,
        RemovePersonModalComponent,
        StagePersonComponent,
    ],
    providers: [],
})
export class PermitTeamModule {}
