import { PhotoRequirement } from "@ha/data/basic";
import { Selector } from "@ngxs/store";
import { PermitPhoto } from "../models/permit-photo.model";
import { PermitPhotoState, PermitPhotoStateModel } from "./permit-photo.state";

export class PermitPhotoSelectors {
    @Selector([PermitPhotoState])
    public static permitPhotos(state: PermitPhotoStateModel): PermitPhoto[] {
        return state?.photos;
    }

    @Selector([PermitPhotoState])
    public static permitPhotoRequirement(state: PermitPhotoStateModel): PhotoRequirement {
        return state?.photoRequirement;
    }
}
