import { Selector } from "@ngxs/store";

import { Account } from "../models/current-account.model";
import { AuthState, AuthStateModel } from "./auth.state";

export class AuthSelectors {
    @Selector([AuthState])
    public static isAuthenticated(state: AuthStateModel): boolean {
        return state && state.isAuthenticated;
    }

    @Selector([AuthState])
    public static currentAccount(state: AuthStateModel): Account | undefined {
        return state?.currentAccount;
    }
}
