import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitPaths } from "../../../permit-paths";
import { PermitTeam } from "../../../permit-team/models/permit-team.model";
import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitTeamSelectors } from "../../../permit-team/states/permit-team.selectors";
import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitCreateSignPersonModalComponent, PermitCreateSignPersonModalComponentData } from "../../../shared/components/permit-create-sign-person-modal/permit-create-sign-person-modal.component";
import { ViewSignatureModalComponent, ViewSignatureModalComponentData } from "../../../shared/components/view-signature-modal/view-signature-modal.component";
import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";

@Component({
    selector: "pmt-signature",
    templateUrl: "permit-signature.component.html",
    styleUrls: ["permit-signature.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitSignatureComponent implements OnInit {
    public PersonRole = PersonRole;

    @Select(PermitCreateSelectors.isFlammableHotWork)
    public isFlammableHotWork$: Observable<boolean>;

    @Select(PermitTeamSelectors.team)
    public team$: Observable<PermitTeam>;

    @Select(PermitTeamSelectors.teamSigned)
    public teamSigned$: Observable<boolean>;

    constructor(private store: Store, private router: Router, private modalService: HaModalService) {}

    public get isDemo(): boolean {
        return this.store.selectSnapshot(PermitCreateSelectors.isDemo);
    }

    public get isNotFlammable(): boolean {
        return !this.store.selectSnapshot(PermitCreateSelectors.isFlammableHotWork);
    }

    public ngOnInit(): void {
        this.store.dispatch(new PermitTeamActions.GetTeam(this.store.selectSnapshot(PermitStepSelectors.permitId)));
    }

    public showSignPerson(teamMember: PermitPerson, role: PersonRole) {
        if (this.isDemo && role !== PersonRole.PermitIssuer) {
            return;
        }

        if (!teamMember.signature) {
            this.modalService.open(
                PermitCreateSignPersonModalComponent,
                true,
                <PermitCreateSignPersonModalComponentData> {
                    person: teamMember,
                    permitId: this.store.selectSnapshot(PermitStepSelectors.permitId),
                    role: role,
                    isDemo: this.isDemo,
                },
            );
        } else {
            this.modalService.open(
                ViewSignatureModalComponent,
                true,
                <ViewSignatureModalComponentData> {
                    name: `${teamMember.firstName} ${teamMember.lastName}`,
                    signature: teamMember.signature,
                },
            );
        }
    }

    public continue(): void {
        if (!this.store.selectSnapshot(PermitTeamSelectors.teamSigned)) {
            return;
        }

        if (this.isNotFlammable) {
            this.store.dispatch(new PermitCreateActions.EndDemoOrNotFlammableHotWork());
        } else {
            this.store.dispatch(new PermitStepActions.GoForward());
        }
    }

    public cancel(): void {
        void this.router.navigate([PermitPaths.Root]);
    }
}
