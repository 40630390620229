import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { debounceTime, Observable, tap } from "rxjs";

import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { CustomerQuestion, FixedQuestion } from "../../models/question.model";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitQuestionActions } from "../../states/permit-question.actions";
import { PermitQuestionSelectors } from "../../states/permit-question.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-questions",
    templateUrl: "permit-questions.component.html",
    styleUrls: ["permit-questions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitQuestionsComponent implements OnInit {
    @Select(PermitQuestionSelectors.fixedQuestion)
    public currentFixedQuestion$: Observable<FixedQuestion>;

    @Select(PermitQuestionSelectors.customerQuestion)
    public currentCustomerQuestion$: Observable<CustomerQuestion>;

    @Input() public permitId: number;
    @Input() public isPrepare = false;

    constructor(private store: Store, private actions$: Actions) { }

    public ngOnInit(): void {
        this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(PermitStepActions.GoForward, PermitStepActions.GoBackwards, PermitCreateActions.GetPermit), debounceTime(50), tap(() => {
            this.store.dispatch(new PermitQuestionActions.GetPermitAndCurrentQuestion());
        })).subscribe();
    }
}
