<div class="root" *transloco="let t; read:'permit.details.notifications'">
    <ng-container *ngIf="(notifications$ | async) !== undefined">
        <ng-container *ngIf="(notifications$ | async) as notifications">
            <div *ngIf="notifications.startedNotifications.length > 0" class="root__list">
                <div class="root__list__heading">{{ t("startedHeading") }} </div>
                <div *ngFor="let notification of notifications.startedNotifications" class="root__list__recipient">
                    <div class="root__list__recipient__icon"></div>
                    <div class="root__list__recipient__data">
                        <div class="root__list__recipient__data__name">{{ notification.fullName }}</div>
                        <div class="root__list__recipient__data__endpoint">{{ notification.email }}</div>
                        <div class="root__list__recipient__data__endpoint">{{ notification.mobile }}</div>
                    </div>
                </div>
            </div>

            <div *ngIf="notifications.endedNotifications?.length > 0" class="root__list">
                <div class="root__list__heading">{{ t("endedHeading") }} </div>
                <div *ngFor="let notification of notifications.endedNotifications" class="root__list__recipient">
                    <div class="root__list__recipient__icon"></div>
                    <div class="root__list__recipient__data">
                        <div class="root__list__recipient__data__name">{{ notification.fullName }}</div>
                        <div class="root__list__recipient__data__endpoint">{{ notification.email }}</div>
                        <div class="root__list__recipient__data__endpoint">{{ notification.mobile }}</div>
                    </div>
                </div>
            </div>

            <div *ngIf="notifications.startedNotifications.length + notifications.endedNotifications?.length === 0" class="root__no-notifications">{{
                t("noNotifications") }}
            </div>
        </ng-container>
    </ng-container>
</div>
