import { animate, style, transition, trigger } from "@angular/animations";

export const MobileNavigationComponentAnimations = {
    slideTrigger: trigger("slideUpDownAnimation", [
        transition(":enter", [
            style({
                bottom: "-38rem",
            }),
            animate(
                "200ms",
                style({
                    bottom: "7rem",
                }),
            ),
        ]),
        transition(":leave", [
            style({
                bottom: "7rem",
            }),
            animate(
                "200ms",
                style({
                    bottom: "-38rem",
                }),
            ),
        ]),
    ]),
};
