import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { globalEnvironment } from "@ha/constants";
import { GoogleGeolocationService } from "@ha/ui/common";
import { BaseValidationResult, InputComponent } from "@ha/ui/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable, take, tap } from "rxjs";

import { PermitPaths } from "../../../permit-paths";
import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { SpecialStepInformation } from "../../models/fixed-template.model";
import { SafetyRule } from "../../models/safety-rule";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-emergency-location",
    templateUrl: "permit-emergency-location.component.html",
    styleUrls: ["permit-emergency-location.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitEmergencyLocationComponent implements OnInit {
    @Input() public emergencyLocationSettings: SpecialStepInformation;
    @Input() public permitId: number;

    @Select(PermitCreateSelectors.emergencyLocationSafetyRules)
    public safetyRules$!: Observable<SafetyRule[]>;

    @Select(PermitCreateSelectors.allowGpsLocation)
    public allowGpsLocation$!: Observable<boolean>;

    @Select(PermitCreateSelectors.isPremium)
    public isPremium$!: Observable<boolean>;

    public alertConfirmed = false;

    public latitude: number;
    public longitude: number;

    @ViewChild("emergencyLocationInput") private emergencyLocationInput: InputComponent;

    constructor(
        private fb: FormBuilder,
        private store: Store,
        private cdr: ChangeDetectorRef,
        private geoLocationService: GoogleGeolocationService,
        private actions$: Actions,
        private router: Router
    ) { }

    public form = this.fb.group({
        emergencyLocation: ["", Validators.required],
    });

    public get canContinue(): boolean {
        return this.form.getRawValue().emergencyLocation?.length > 0 && this.alertConfirmed;
    }

    public ngOnInit(): void {
        this.actions$.pipe(ofActionSuccessful(PermitCreateActions.SetEmergencyLocation))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                const result: BaseValidationResult = this.store.selectSnapshot(state => state.permitCreate.setEmergencyLocationValidationResult);
                if (result?.success) {
                    this.store.dispatch(new PermitStepActions.GoForward(this.permitId));
                }
            });
    }

    public continue(): void {
        const inputValue = this.form.getRawValue().emergencyLocation;
        this.store.dispatch(new PermitCreateActions.SetEmergencyLocation(this.permitId, inputValue, this.latitude, this.longitude));
    }

    public cancel(): void {
        void this.router.navigate([PermitPaths.Root]);
    }

    public setAlertConfirmed(confirmed: boolean): void {
        this.alertConfirmed = confirmed;
    }

    public manualLocation(): void {
        this.latitude = undefined;
        this.longitude = undefined;
        this.form.reset();
        this.form.get("emergencyLocation").enable();
        this.emergencyLocationInput.setFocus();
        this.form.patchValue({ emergencyLocation: this.store.selectSnapshot(PermitCreateSelectors.emergencyLocation) });
        this.cdr.detectChanges();
    }

    public getLocation(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                this.latitude = pos.coords.latitude;
                this.longitude = pos.coords.longitude;

                // Call google geolocation api to get address etc
                this.geoLocationService.getReverseGeocodeAddress(this.latitude, this.longitude, globalEnvironment.googleGeolocationKey).pipe(
                    untilDestroyed(this),
                    tap((geoCode => {
                        if (geoCode && geoCode.results && geoCode.results[0] && geoCode.results[0].formatted_address) {
                            const formattedAddress = geoCode.results[0].formatted_address;
                            const address = formattedAddress.slice(0, formattedAddress.lastIndexOf(","));

                            this.form.get("emergencyLocation").setValue(address);
                            this.form.get("emergencyLocation").disable();
                            this.cdr.detectChanges();
                        }
                    }))
                ).subscribe();
            });
        }
    }
}
