import { Translation } from "@ha/data/basic";

import { Placeholder } from "./fixed-template.model";
import { SafetyRule } from "./safety-rule";

export interface QuestionDto {
    customerQuestion: CustomerQuestion;
    fixedQuestion: FixedQuestion;
}

export interface FixedQuestion extends BaseQuestion {
    alert: FixedQuestionAlert;
    customerComment: FixedQuestionComment;
}

export interface CustomerQuestion extends BaseQuestion {
    alternatives: CustomerQuestionAnswer;
}

export interface BaseQuestion {
    id: number;
    isPrepatory: boolean;
    currentAnswer: CustomerQuestionAnswer;
    translations: Translation[];
    safetyRules: SafetyRule[];
}

export interface FixedQuestionAlert {
    answerIsNo: boolean;
    answerIsYes: boolean;
    confirmToProceed: boolean;
    proceedNotAllowed: boolean;
    translations: Translation[];
    placeholder: Placeholder;
}

export interface FixedQuestionComment {
    customerPermitTemplateId: number;
    fixedQuestionId: number;
    translations: Translation[];
}

export enum CustomerQuestionAnswer {
    Yes = 1,
    No = 1 << 1,
    NotApplicable = 1 << 2,
}
