import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PermitStep, PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { HaTranslatePipe } from "@ha/util/pipes";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitTeamSelectors } from "../../../permit-team/states/permit-team.selectors";
import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitSummaryDto, WorkMethodTranslation } from "../../models/permit-summary.model";
import { PermitDetailsActions } from "../../states/permit-details.action";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";
import {
    PermitCheckpointsModalComponent,
    PermitCheckpointsModalComponentData,
} from "../permit-checkpoints-modal/permit-checkpoints-modal.component";
import {
    PermitValidityPeriodModalComponent,
    PermitValidityPeriodModalComponentData
} from "../permit-validity-period-modal/permit-validity-period-modal.component";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";
import { WorkplaceDetails } from "../../../permit-create/models/workplace-details.model";
import { Permit } from "../../../permit-create/models/permit.model";
import { PermitCreateActions } from "../../../permit-create/states/permit-create.actions";

@Component({
    selector: "pmt-permit-summary",
    templateUrl: "permit-summary.component.html",
    styleUrls: ["permit-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HaTranslatePipe],
})
export class PermitSummaryComponent implements OnInit {
    @Input()
    public permitId: number;

    @Select(PermitDetailsSelectors.currentUserRoles)
    public currentUserRoles$: Observable<PersonRole[]>;

    @Select(PermitDetailsSelectors.summary)
    public summary$: Observable<PermitSummaryDto>;

    @Select(PermitCreateSelectors.workplaceDetails)
    public workplaceDetails$: Observable<WorkplaceDetails>;

    @Select(PermitCreateSelectors.permit)
    public permit$: Observable<Permit>;

    @Select(PermitTeamSelectors.permitIssuer)
    public permitIssuer$: Observable<PermitPerson>;

    @Select(PermitStepSelectors.currentStep)
    public currentStep$: Observable<CurrentStep>;

    public PersonRole = PersonRole;

    public PermitStep = PermitStep;

    public get pdfUrl() {
        return `/api/Permit/GeneratePdf?permitId=${this.permitId}`;
    }

    constructor(private store: Store, private haModalService: HaModalService, private translatePipe: HaTranslatePipe) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitCreateActions.GetActiveFixedTemplate());
        this.store.dispatch(new PermitCreateActions.GetPermit(this.permitId));
        this.store.dispatch(new PermitDetailsActions.GetSummary(this.permitId));
        this.store.dispatch(new PermitTeamActions.GetTeam(this.permitId));
    }


    public canExtend(userRoles: PersonRole[]): boolean {
        return userRoles?.some((_) => _ === PersonRole.PermitIssuer);
    }

    public openPermitCheckpointsModal(): void {
        this.haModalService.open(
            PermitCheckpointsModalComponent,
            true,
            <PermitCheckpointsModalComponentData> { permitId: this.permitId },
        );
    }

    public getTranslatedWorkMethodText(workMethods: WorkMethodTranslation[], workMethodManualInput: string): string {
        const strArr = workMethods.map((_) => this.translatePipe.transform(_.translations));

        if (workMethodManualInput) {
            strArr.push(workMethodManualInput);
        }
        return strArr.join(", ");
    }

    public openValidityPeriodModal(): void  {
        this.haModalService.open(
            PermitValidityPeriodModalComponent,
            true,
            <PermitValidityPeriodModalComponentData> { permitId: this.permitId },
        );

    }
}
