<div class="root" *transloco="let t; read:'permit'">
    <h2 class="root__question-text" [innerHTML]="t('validTime.heading')"></h2>

    <div *ngIf="comment" class="root__comment" [innerHTML]="comment"></div>

    <div *ngIf="(isPremium$ | async) === false" class="root__info">{{ t("validTime.info") }}</div>
    <div *ngIf="(isPremium$ | async) === true" class="root__info"[innerHTML]="t('validTime.premiumInfo', { time: default })"></div>

    <pmt-time-stepper [label]="t('validTime.input')" [default]="default > 8 ? 8 : default" [minValue]="1" [maxValue]="(isPremium$ | async) === true ? default : 168"
                      (selectedValue)="setValue($event)" [smallStep]="1" [bigStep]="2">
    </pmt-time-stepper>
    <div class="root__date" [innerHTML]="t('validTime.date', { date: validDateTime | haDate: 'dateTimeStandard' })"></div>

    <div class="root__actions">
        <button class="root__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
        <button class="root__actions__continue" type="button" (click)="start()" [class.root__actions__continue--disabled]="!canProceed">
            {{ t("validTime.start") }}
        </button>
    </div>
</div>
