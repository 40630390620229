import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { VimeoMetaData } from "./vimeo-meta-data";

@Injectable({ providedIn: "root" })
export class VimeoVideoService {
    private readonly map = new Map<string, Observable<VimeoMetaData>>();

    constructor(private httpClient: HttpClient) {}

    public getVideoMetadata(url: string): Observable<VimeoMetaData> {
        if (!this.map.has(url)) {
            this.map.set(url, this.getData(url).pipe(shareReplay()));
        }

        return this.map.get(url);
    }

    private getData(url: string) {
        const options = {
            params: {
                url: url,
                width: "800",
            },
        };

        return this.httpClient.get<VimeoMetaData>("https://vimeo.com/api/oembed.json", options);
    }
}
