<ng-container *ngIf="showModal">
    <div class="dimmer"></div>
    <div class="modal-wrapper">
        <div #modal class="modal" [class]="'modal--' + width">
            <div class="modal__text" [class]="'modal__text--' + textSource">
                <ng-content></ng-content>
            </div>

            <div class="modal__actions">
                <div *ngIf="cancelLabel" class="modal__actions__cancel-button" (click)="emitCancel()">
                    {{ cancelLabel }}
                </div>
                <div *ngIf="closeLabel" class="modal__actions__close-button" (click)="emitClose()">
                    {{ closeLabel }}
                </div>
                <div *ngIf="okLabel" class="modal__actions__ok-button" (click)="emitOk()">
                    {{ okLabel }}
                </div>
            </div>
        </div>
    </div>
</ng-container>
