<ng-container *transloco="let t; read:'permit.subscriptions'">
    <ng-container *ngIf="invitations$ | async as invitations">
        <div class="root" *ngIf="invitations && invitations.length > 0">
            <div class="root__alert" [innerHTML]="t(invitations.length > 1 ? 'alertMultiple' : 'alertSingle')"></div>
            <div class="root__intro" [innerHTML]="t(invitations.length > 1 ? 'introMultiple' : 'introSingle')"></div>

            <div class="root__invitations">
                <div class="root__invitations__invitation" *ngFor="let invitation of invitations" (click)="answer(invitation)">
                    <div>{{ invitation.subscriptionName }}</div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
