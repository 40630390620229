<div class="find" *transloco="let t; read:'permit.team.search'">
    <div class="find__information" [innerHtml]="t('information')"></div>
    <search-box class="find__search-box" (triggerSearch)="search($event)" [ngModel]="query$ | async" [placeholder]="t('search')">
    </search-box>

    <button *ngIf="!isDesktop" class="find__scan-card-button" (click)="openScanCardModal()">{{ t("openScanCardModal") }}</button>

    <div class="find__search-result" *ngIf="searchResult$ | async as searchResult">
        <pmt-stage-person *ngIf="searchResult.person" [person]="searchResult.person" [permitId]="permitId" [role]="role">
        </pmt-stage-person>
        <div *ngIf="searchResult.errorMessage">{{t("errorMessages." + searchResult.errorMessage)}}</div>
    </div>
</div>
