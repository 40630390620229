import { IssuerStatus } from "@ha/data/basic";
import { Selector } from "@ngxs/store";

import { SubscriptionInvitation } from "../models/subscription-invitation";
import { SubscriptionsState, SubscriptionsStateModel } from "./subscriptions.state";

export class SubscriptionsSelectors {
    @Selector([SubscriptionsState])
    public static invitations(state: SubscriptionsStateModel): SubscriptionInvitation[] {
        return state?.activeAndPendingSubscriptions?.filter(_ => _.status === IssuerStatus.pending);
    }

    @Selector([SubscriptionsState])
    public static active(state: SubscriptionsStateModel): SubscriptionInvitation[] {
        return state?.activeAndPendingSubscriptions?.filter(_ => _.status === IssuerStatus.active);
    }

    @Selector([SubscriptionsSelectors.active])
    public static hasActiveSubscription(activeSubscriptions: SubscriptionInvitation[]): boolean {
        return activeSubscriptions?.length > 0;
    }
}
