import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Directive, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
    selector: '[downloadFile]'
})
export class DownloadFileDirective {
    @Output()
    public done = new EventEmitter();

    constructor(private readonly httpClient: HttpClient) { }

    private downloadUrl: string;

    @Input('downloadFile')
    public set url(url: string) {
        this.downloadUrl = url;
    };

    @HostListener('click')
    public async onClick(): Promise<void> {
        // Download the document as a blob
        this.httpClient.get(
            this.downloadUrl,
            { responseType: 'blob', observe: 'response' }
        ).subscribe(response => {
            if (!response.body) {
                console.error("No response body from server");
                return;
            }

            // Create a URL for the blob
            const url = URL.createObjectURL(response.body);

            // Create an anchor element to "point" to it
            const anchor = document.createElement('a');
            anchor.href = url;

            // Get the suggested filename for the file from the response headers
            // anchor.download = this.getFilenameFromHeaders(response.headers) || 'file';

            // Extract filename from header
            const fileName = this.getFilenameFromHeaders(response.headers);

            anchor.download = fileName;

            // Simulate a click on our anchor element
            anchor.click();

            // Discard the object data
            URL.revokeObjectURL(url);

            this.done.emit();
        });
    }

    private getFilenameFromHeaders(headers: HttpHeaders) {
        const key = "filename*=";

        const fileName = headers.get("content-disposition")
            ?.split(";")
            ?.find(n => n.includes(key))
            ?.replace(key + "UTF-8''", "")
            .trim();

        if (!fileName) {
            console.error("Filename missing from content-disposition header");
            return "name.missing";
        }

        return decodeURIComponent(fileName);
    }
}
