<div class="nav" *transloco="let t; read: 'menu'">
    <ng-container *ngIf="currentUser$ | async as currentUser">
        <logo class="nav__logo"></logo>

        <ha-impersonated-information-panel></ha-impersonated-information-panel>

        <knowledge-bank-search-box *ngIf="(isExternal$ | async) === false" class="nav__search"></knowledge-bank-search-box>

        <hr class="nav__divider">

        <a routerLink="/" aria-label="home" routerLinkActive="nav__item--selected" [routerLinkActiveOptions]="{exact: true}" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("start") }}</div>
        </a>
        <a *ngIf="currentUser.isHotworker" [routerLink]="applicationPaths.Certificate.Root" aria-label="certificate" routerLinkActive="nav__item--selected"
           class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("myCertificate") }}</div>
        </a>
        <a *ngIf="(isExternal$ | async) === false" [routerLink]="applicationPaths.User.Root" aria-label="user" routerLinkActive="nav__item--selected"
           class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("user") }}</div>
        </a>
        <a *ngIf="currentUser.isEducationParticipant" [routerLink]="applicationPaths.Education.Root" [routerLinkActiveOptions]="{exact: false}"
           aria-label="education" routerLinkActive="nav__item--selected" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("myEducation") }}</div>
        </a>
        <a [routerLink]="applicationPaths.Permit.Root" aria-label="permit" routerLinkActive="nav__item--selected" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("permit") }}</div>
        </a>
        <a *ngIf="(isExternal$ | async) === false" [routerLink]="applicationPaths.KnowledgeBank.Root" aria-label="knowledge bank"
           routerLinkActive="nav__item--selected" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("knowledgeBank") }}</div>
        </a>
        <a [routerLink]="applicationPaths.PublicEducationEvents" aria-label="public education events" routerLinkActive="nav__item--selected" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("publicEducationEvents") }}</div>
        </a>

        <div class="nav__spacer"></div>

        <a [routerLink]="applicationPaths.Support" aria-label="customer support" routerLinkActive="nav__item--selected" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("support") }}</div>
        </a>
        <div aria-label="switch-application" (click)="openApplicationModal()" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("switchApplication") }}</div>
        </div>
        <a [routerLink]="applicationPaths.SignOut" aria-label="sign out" class="nav__item">
            <div class="nav__item__icon"></div>
            <div class="nav__item__text">{{ t("signOut") }}</div>
        </a>
    </ng-container>
</div>
