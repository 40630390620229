import { animate, style, transition, trigger } from "@angular/animations";

export const DimmerComponentAnimations = {
    fadeInOutTrigger: trigger("fadeInOutTrigger", [
        transition(":enter", [
            style({
                opacity: "0",
            }),
            animate(
                "300ms",
                style({
                    opacity: "0.1",
                }),
            ),
        ]),
        transition(":leave", [
            style({
                opacity: "0.1",
            }),
            animate(
                "300ms",
                style({
                    opacity: "0",
                }),
            ),
        ]),
    ]),
};
