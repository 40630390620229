import { Selector } from "@ngxs/store";

import { LanguageState, LanguageStateModel } from "./language.state";

export class LanguageStateSelectors {
    @Selector([LanguageState])
    public static currentLanguage(state: LanguageStateModel): string {
        return state?.currentLanguage;
    }
}
