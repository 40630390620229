import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResult, PersonRole } from "@ha/data/basic";
import { BaseValidationResult } from "@ha/ui/forms";
import { Observable } from "rxjs";

import { SignPermitWithSignatureCommand } from "../../permit-team/models/sign-permit-with-signature-command";
import { CheckpointSummaryDto } from "../models/checkpoint-summary-dto";
import { CreateCommentCommand } from "../models/create-comment-command";
import { CreateCommentResult } from "../models/create-comment-result";
import { PermitBasicDetailsDto } from "../models/permit-basic-details.model";
import { PermitCommentsDto } from "../models/permit-comments-dto";
import { PermitNotificationsDto } from "../models/permit-notifications-dto";
import { PermitSummaryDto } from "../models/permit-summary.model";
import { SendEmailCommand } from "../models/send-email-command";

@Injectable({ providedIn: "root" })
export class PermitDetailsService {
    constructor(private httpClient: HttpClient) { }

    public getCheckpoints(permitId: number): Observable<CheckpointSummaryDto> {
        return this.httpClient.get<CheckpointSummaryDto>("/api/PermitCheckpoints/GetCheckpoints", { params: { permitId } });
    }

    public getSummary(permitId: number): Observable<PermitSummaryDto> {
        return this.httpClient.get<PermitSummaryDto>("/api/PermitSummary/Get", { params: { permitId } });
    }

    public getBasicDetails(permitId: number): Observable<PermitBasicDetailsDto> {
        return this.httpClient.get<PermitBasicDetailsDto>("/api/Permit/GetBasicDetails", { params: { permitId } });
    }

    public getNotifications(permitId: number): Observable<PermitNotificationsDto> {
        return this.httpClient.get<PermitNotificationsDto>("/api/Permit/GetNotifications", { params: { permitId } });
    }

    public getComments(permitId: number): Observable<PermitCommentsDto> {
        return this.httpClient.get<PermitCommentsDto>("/api/Permit/GetComments", { params: { permitId } });
    }

    public getCurrentUserRoles(permitId: number): Observable<PersonRole[]> {
        return this.httpClient.get<PersonRole[]>("/api/PermitPerson/GetCurrentUserRolesForPermit", { params: { permitId } });
    }

    public createComment(command: CreateCommentCommand): Observable<CreateCommentResult> {
        return this.httpClient.post<CreateCommentResult>("/api/Permit/CreateComment", command);
    }

    public removeComment(commentId: number): Observable<boolean> {
        return this.httpClient.delete<boolean>("/api/Permit/RemoveComment", { params: { commentId } });
    }

    public endPermit(command: SignPermitWithSignatureCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/EndPermit", command);
    }

    public sendPdfMail(command: SendEmailCommand): Observable<BaseResult> {
        return this.httpClient.post<BaseValidationResult>("/api/Permit/SendPdfMail", command);
    }
}
