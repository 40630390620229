import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { EpiserverAngularModuleConfig } from "../../models/episerver-angular-module-config";
import { LinkItem } from "../../models/episerver-base-types.model";
import { EpiserverState } from "../../states/episerver.state";

@Component({
    selector: "link-item",
    templateUrl: "link-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkItemComponent implements OnChanges {
    @Input()
    public link: LinkItem;

    @Select(EpiserverState.currentConfig)
    public currentConfig$: Observable<EpiserverAngularModuleConfig>;

    constructor(private store: Store) {}

    public url: string;

    /**
     * a link inside current Angular app. So we should use routerLink instead of href
     *
     * @type {boolean}
     * @memberof LinkItemComponent
     */
    public isInternal: boolean;

    public ngOnChanges(): void {
        if (!this.link) {
            return;
        }

        this.setUrl();
        this.isInternal = !this.url.startsWith("http")
            && !this.url.startsWith("mailto")
            && this.url.indexOf("?") === -1;
    }

    public setUrl(): void {
        let url = "";

        if (this.link) {
            if (this.link.url) {
                url = this.link.url;
            } else if (this.link.href) {
                url = this.link.href;
            }
        }

        url = this.rewriteUrlToAngular(url);
        this.url = url + this.fragment;
    }

    private rewriteUrlToAngular(url: string) {
        if (!url.startsWith("/")) {
            return url;
        }

        const currentConfig = this.store.selectSnapshot(EpiserverState.currentConfig);

        if (currentConfig) {
            const foundRootUrl = currentConfig.rootUrls.find((rootUrl) => {
                return url.startsWith(rootUrl.url);
            });

            if (foundRootUrl) {
                return "/" + url.replace(foundRootUrl.url, currentConfig.moduleRootPath + "/");
            }
        }

        // to www
        const host = location.hostname;
        const appName = host.split(".")[0].replace("test", "");
        const newHost = host.replace(appName, "www");
        const wwwHost = `https://${newHost}`;
        return wwwHost + url;
    }

    public get title(): string {
        return this.link?.title;
    }

    public get target(): string {
        return this.isInternal ? "" : "_blank";
        // return this.link?.target;
    }

    public get fragment(): string {
        return this.link?.fragment ?? "";
    }
}
