<div class="root" *transloco="let t; read:'permit.prepare'">
    <div class="root__intro">{{ t((permits$ | async)?.length > 0 ? "preparedPermitsListIntro" : "preparedPermitsNotFound") }}</div>

    <div class="root__list" *ngIf="(permits$ | async)?.length > 0 && permits$ | async as permits">
        <pmt-prepared-permit-list-item *ngFor="let permit of permits" [item]="permit">
        </pmt-prepared-permit-list-item>
    </div>

    <div class="root__refresh" (click)="refresh()">{{ t("refresh") }}</div>

    <div class="root__actions">
        <button class="root__actions__cancel" (click)="cancel()">{{ t("cancel") }}</button>
    </div>
</div>
