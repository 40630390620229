<ng-container *transloco="let t; read:'permit.team.add'">
    <div class="last-used">
        <search-box class="find__search-box" (triggerSearch)="search($event)" [ngModel]="query" [placeholder]="t('searchLastUsed')">
        </search-box>
        <div class="last-used__clear" (click)="clearSearchResults()">{{ t("clearSearch") }}</div>
        <pmt-stage-person *ngFor="let person of result$ | async" [person]="person" [permitId]="permitId" [role]="role">
        </pmt-stage-person>
    </div>
    <a [routerLink]="" class="load-more-link" *ngIf="(recentTeamMembersFullyLoaded$ | async) === false" (click)="loadMore()" >{{t("loadMoreButton")}}</a>
</ng-container>
