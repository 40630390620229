import { NgSignaturePadOptions, SignaturePadComponent } from "@almothafar/angular-signature-pad";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, Store } from "@ngxs/store";
import { take } from "rxjs";

import { PermitCreateSignPersonModalComponentData } from "../../../shared/components/permit-create-sign-person-modal/permit-create-sign-person-modal.component";
import { PermitDetailsActions } from "../../states/permit-details.action";

@Component({
    selector: "pmt-permit-end-signature-modal",
    templateUrl: "./permit-end-signature-modal.component.html",
    styleUrls: ["./permit-end-signature-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitEndSignatureModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
    @ViewChild("signature")
    public signaturePad: SignaturePadComponent;

    public signaturePadOptions: NgSignaturePadOptions = undefined;

    public data: PermitCreateSignPersonModalComponentData;

    public hasSignature = false;

    public disabledFireAlarmRestored = false;

    constructor(private store: Store, private cdr: ChangeDetectorRef, private actions$: Actions) {
        super();
    }

    public get isValid() {
        if (this.data.disabledFireAlarm && !this.disabledFireAlarmRestored) {
            return false;
        }

        return this.hasSignature;
    }

    public ngOnInit(): void {
        this.actions$.pipe(ofActionCompleted(PermitDetailsActions.EndPermitWithSignature)).pipe(
            untilDestroyed(this),
            take(1),
        ).subscribe(() => {
            this.closeModal();
        });
    }

    public ngAfterViewInit(): void {
        this.getScreenSize();
        this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }

    @HostListener("window:resize", ["$event"])
    public getScreenSize() {
        let width = 400;
        if (window.innerWidth < width) {
            width = window.innerWidth;
        }

        this.signaturePadOptions = {
            minWidth: 1,
            maxWidth: 4,
            backgroundColor: "#ffffff",
            canvasWidth: width,
            canvasHeight: width / 2,
        };

        this.cdr.detectChanges();
    }

    public clear() {
        this.signaturePad.clear();
        this.hasSignature = !this.signaturePad.isEmpty();
    }

    public endPermit() {
        if (this.isValid) {
            this.store.dispatch(
                new PermitDetailsActions.EndPermitWithSignature({
                    permitId: this.data.permitId,
                    personId: this.data.person.personId,
                    role: this.data.role,
                    signatureData: this.signaturePad.toDataURL(),
                    endPostWatchSignature: false
                }),
            );
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public drawComplete(event: MouseEvent | Touch): void {
        this.hasSignature = !this.signaturePad.isEmpty();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public drawStart(event: MouseEvent | Touch): void {
    }
}
