import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HaModalService } from "@ha/ui/common";

import { SafetyRule } from "../../../models/safety-rule";
import { SafetyRuleModalComponent, SafetyRuleModalComponentData } from "../safety-rule-modal/safety-rule-modal.component";

@Component({
    selector: "pmt-safety-rule",
    templateUrl: "./safety-rule.component.html",
    styleUrls: ["./safety-rule.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SafetyRuleComponent {
    @Input() public safetyRules: SafetyRule[];

    constructor(private modalService: HaModalService) { }

    public getIdsString(safetyRules: SafetyRule[]) {
        return safetyRules.map(_ => _.id).join(", ");
    }

    public openModal(safetyRules: SafetyRule[]) {
        this.modalService.open(SafetyRuleModalComponent, true, <SafetyRuleModalComponentData>{ safetyRules });
    }
}
