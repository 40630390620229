import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { BaseModalComponent, ModalComponent } from "@ha/ui/common";
import { UserActivateState } from "../user-activate/states/user-activate.state";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { UserAgreement } from "../user/models/user-agreement.model";
import { AuthActions } from "@ha/feature/auth";
import { UserActivateActions } from "../user-activate/states/user-activate.actions";

@Component({
    templateUrl: "./user-accept-new-agreement.component.html",
    styleUrls: ["./user-accept-new-agreement.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAcceptNewAgreementComponent extends BaseModalComponent implements OnInit {
    @Select(UserActivateState.activationAgreement)
    public activationAgreement$: Observable<UserAgreement>;

    constructor(private store: Store) {
        super();
    }

    public ngOnInit(): void {
        this.store.dispatch(new UserActivateActions.GetActivationAgreement());
    }

    public cancel(): void {
        this.store.dispatch(new AuthActions.SignOut());
    }

    public accept(): void {
        this.store.dispatch(new UserActivateActions.AcceptNewAgreement());
        this.closeModal();
    }

}
