<div class="root" *transloco="let t; read:'permit.completed'">
    <ng-container *ngIf="basicDetails$ | async as basicDetails">
        <ng-container *ngIf="basicDetails.isFlammableHotWork === true">
            <h1 class="root__heading" [innerHTML]="t('heading', { workplace: basicDetails.fullWorkplaceName })"></h1>
            <div *ngIf="!basicDetails.isDemo" class="root__button" (click)="refresh()">{{ t("showPermit") }}</div>
            <div *ngIf="basicDetails.isDemo" class="root__button" routerLink="..">{{ t("goBack") }}</div>
        </ng-container>
        <ng-container *ngIf="basicDetails.isFlammableHotWork === false">
            <h1 class="root__heading" [innerHTML]="t('headingNotFlammable', { workplace: basicDetails.fullWorkplaceName })"></h1>
            <div class="root__button" routerLink="..">{{ t("goBack") }}</div>
        </ng-container>
    </ng-container>
</div>
