<div #menu class="menu" *ngIf="items?.length > 0">
    <h3 class="menu__heading">
        {{ heading }}
    </h3>

    <ul class="menu__list">
        <anchor-menu-item *ngFor="let item of items" [data]="item" [currentFragment]="currentFragment"> </anchor-menu-item>
    </ul>

    <hr class="menu__divider">

    <a (click)="scrollToTop()" class="menu__to-the-moon">{{ toTheTopText }}</a>
</div>
