import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Navigate } from "@ngxs/router-plugin";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { PermitPaths } from "../../../permit-paths";

import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitCreateActions } from "../../states/permit-create.actions";

@UntilDestroy()
@Component({
    selector: "pmt-is-flammable-hotwork",
    templateUrl: "permit-is-flammable-hot-work.component.html",
    styleUrls: ["permit-is-flammable-hot-work.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitIsFlammableHotWorkComponent implements OnInit {

    @Select(PermitStepSelectors.currentStep)
    public currentStep$!: Observable<CurrentStep>;

    public isFlammableHotWork: boolean;

    public get canProceed() {
        return this.isFlammableHotWork !== undefined;
    }

    constructor(private store: Store, private router: Router) { }

    public ngOnInit(): void {
        const isDemo = this.router.routerState.snapshot.url.includes(PermitPaths.CreateDemo);
        this.store.dispatch(new PermitCreateActions.SetIsDemo(isDemo));
    }

    public select(isFlammableHotWork: boolean) {
        this.isFlammableHotWork = isFlammableHotWork;
    }

    public continue(): void {
        if (this.isFlammableHotWork === undefined) {
            return;
        }

        this.store.dispatch(new PermitCreateActions.SetIsFlammableHotWork(this.isFlammableHotWork));
    }

    public goBack(): void {
        this.store.dispatch(new Navigate([PermitPaths.Root]));
    }
}
