import { Workplace } from "@ha/data/basic";
import { SelectionListItem } from "@ha/ui/forms";
import { Selector } from "@ngxs/store";

import { IssuerStructureNode } from "../models/issuer-structure-node.model";
import { PermitWorkPlaceViewEnum } from "../models/permit-work-place-view.enum";
import { RecentPermitWorkplace } from "../models/recent-workplaces.model";
import { StructureNodeBreadcrumb } from "../models/structure-node-breadcrumb.model";
import { PermitWorkplaceState, PermitWorkplaceStateModel } from "./permit-workplace.state";

const groupBy = <T>(array: T[], predicate: (v: T) => string | number) =>
    array.reduce((acc, value) => {
        (acc[predicate(value)] ||= []).push(value);
        return acc;
    }, {} as { [key: string]: T[]; });

export class PermitWorkplaceSelectors {
    @Selector([PermitWorkplaceState])
    public static subscriptions(state: PermitWorkplaceStateModel): SelectionListItem[] {
        const group = groupBy(state?.structure, _ => _.subscriptionId);
        return Object.keys(group).map(_ => group[_][0]).map((_) => (<SelectionListItem>{ value: _.subscriptionId, text: _.companyName, isDisabled: !_.isSubscriptionActive }));
    }

    @Selector([PermitWorkplaceState])
    public static structure(state: PermitWorkplaceStateModel): IssuerStructureNode[] {
        return state?.structure;
    }

    @Selector([PermitWorkplaceState])
    public static structureNodes(state: PermitWorkplaceStateModel): IssuerStructureNode[] {
        return state?.structure?.find(_ => _.subscriptionId === state.selectedSubscriptionId)?.childNodes;
    }

    @Selector([PermitWorkplaceState])
    public static rootNode(state: PermitWorkplaceStateModel): IssuerStructureNode {
        return state?.structure?.find(_ => _.subscriptionId === state.selectedSubscriptionId);
    }

    @Selector([PermitWorkplaceState])
    public static parentNode(state: PermitWorkplaceStateModel): IssuerStructureNode {
        return state?.flatStructure?.find(_ => _.structureNodeId === state?.selectedNode?.parentId);
    }

    @Selector([PermitWorkplaceState])
    public static hasPremiumWorkplaceContainers(state: PermitWorkplaceStateModel): boolean {
        return state?.structure?.some(_ => _.childNodes.length > 0 && _.childNodes.some(_ => _.isSubscriptionActive));
    }

    @Selector([PermitWorkplaceState])
    public static recentWorkplaces(state: PermitWorkplaceStateModel): RecentPermitWorkplace[] {
        return state?.recentWorkplacesToShow;
    }

    @Selector([PermitWorkplaceState])
    public static canLoadMoreRecentWorkplaces(state: PermitWorkplaceStateModel): boolean {
        return state?.recentWorkplaces?.length > state?.recentWorkplacesToShow?.length;
    }

    @Selector([PermitWorkplaceState])
    public static view(state: PermitWorkplaceStateModel): PermitWorkPlaceViewEnum {
        return state?.view ?? PermitWorkPlaceViewEnum.Start;
    }

    @Selector([PermitWorkplaceState])
    public static loading(state: PermitWorkplaceStateModel): boolean {
        return state?.loading;
    }

    @Selector([PermitWorkplaceState])
    public static selectedNode(state: PermitWorkplaceStateModel): IssuerStructureNode | undefined {
        return state?.selectedNode;
    }

    @Selector([PermitWorkplaceState])
    public static selectedWorkplace(state: PermitWorkplaceStateModel): Workplace | undefined {
        return state?.selectedWorkplace;
    }

    @Selector([PermitWorkplaceState])
    public static selectedSubscriptionId(state: PermitWorkplaceStateModel): number | undefined {
        return state?.selectedSubscriptionId;
    }

    @Selector([PermitWorkplaceState])
    public static selectedNodeBreadcrumbs(state: PermitWorkplaceStateModel): StructureNodeBreadcrumb[] {
        return state?.selectedNodeBreadcrumbs;
    }

    @Selector([PermitWorkplaceState])
    public static searchResult(state: PermitWorkplaceStateModel): (IssuerStructureNode | Workplace)[] {
        return state?.searchResult;
    }
}
