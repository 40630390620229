export namespace PermitListActions {
    export class GetAllCompleted {
        public static readonly type = "[PermitList] GetAllCompleted";
    }

    export class GetAllOngoing {
        public static readonly type = "[PermitList] GetAllOngoing";
    }

    export class UpdateFilter {
        public static readonly type = "[PermitList] UpdateFilter";
        constructor(public value: string[]) {}
    }

    export class GetAllPlanned {
        public static readonly type = "[PermitList] GetAllPlanned";
    }

    export class GetAllPrepared {
        public static readonly type = "[PermitList] GetAllPrepared";
    }

    export class ClaimPreparedPermit {
        public static readonly type = "[PermitList] ClaimPreparedPermit";
        constructor(public permitId: number) {}
    }

    export class RemovePlannedPermit {
        public static readonly type = "[PermitList] RemovePlannedPermit";
        constructor(public permitId: number) {}
    }

    export class ToggleShowAllPermits {
        public static readonly type = "[PermitList] ToggleShowAllPermits";
    }
}
