<div class="control" [class.control--invalid]="invalidAndDirty" *transloco="let t; read: list.read">
    <div *ngFor="let item of list.items" class="control__item">
        <input [attr.id]="name + item.value.toString()" [name]="name" class="control__item__input" type="radio" [value]="item.value" [(ngModel)]="value">
        <label [for]="name + item.value.toString()" class="control__item__label">
            <div class="control__item__label__box" [class.control__item__label__box--selected]="item.value === value">
            </div>
            <div class="control__item__label__text">{{ t(item.langKey) }}</div>
        </label>
    </div>
</div>
