import { BaseResult } from "@ha/data/basic";

export interface BaseValidationResult extends BaseResult {
    validationResult: ValidationResult;
}

export interface ValidationResult {
    isValid: boolean;
    errors: ValidationFailure[];
    ruleSetsExecuted: string[];
}

export interface ValidationFailure {
    propertyName: string;
    errorMessage: string;
    attemptedValue: unknown;
    customState: unknown;
    severity: Severity;
    errorCode: string;
    formattedMessageArguments: unknown[];
    formattedMessagePlaceholderValues: { [key: string]: unknown };
}

export enum Severity {
    Error = 0,
    Warning = 1,
    Info = 2,
}
