<div class="new" *transloco="let t; read:'permit.workplace.createNew'">
    <form [formGroup]="form" >
        <ha-input class="root__input" formControlName="workplace" [label]="t('workplace')" [autofocus]="true">
        </ha-input>
    </form>

    <div class="new__actions">

        <button class="new__actions__cancel" (click)="cancel()">{{ t("cancel") }}</button>
        <button class="new__actions__continue" type="button" (click)="createNew()" [class.new__actions__continue--disabled]="!form.valid">
            {{ t("next") }}
        </button>
    </div>
</div>
