import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { produce } from "immer";

export class SaveLocalQuestion {
    public static readonly type = "[PermitQuestionStorage] SaveLocalQuestion";
    constructor(public permitId: number, public questionId: number) {}
}

export interface PermitQuestionStorageStateModel {
    permitId: number;
    questionId: number;
}

@UntilDestroy()
@Injectable()
@State<PermitQuestionStorageStateModel>({
    name: "permitQuestionStorage",
    defaults: {
        permitId: undefined,
        questionId: undefined,
    },
})
export class PermitQuestionStorageState {
    @Selector()
    public static permitId(state: PermitQuestionStorageStateModel): number | undefined {
        return state.permitId;
    }

    @Selector()
    public static questionId(state: PermitQuestionStorageStateModel): number | undefined {
        return state.questionId;
    }

    @Action(SaveLocalQuestion)
    public saveLocalQuestion(ctx: StateContext<PermitQuestionStorageStateModel>, action: SaveLocalQuestion): PermitQuestionStorageStateModel {
        return ctx.setState(produce(draft => {
            draft.permitId = action.permitId;
            draft.questionId = action.questionId;
        }));
    }
}
