import { Injectable } from "@angular/core";
import { BaseValidationResult } from "@ha/ui/forms";
import { Navigate } from "@ngxs/router-plugin";
import { Action, State, StateContext } from "@ngxs/store";
import { Observable, tap } from "rxjs";

import { PermitPaths } from "../../permit-paths";
import { CreatePreparedPermitCommand, SelectRoleCommand } from "../models/create-permit-commands";
import { CreatePermitResult } from "../models/create-permit-result.model";
import { PrefixWorkCodeResponse } from "../models/prefix-work-code-response.model";
import { PreparePermitService } from "../services/prepare-permit.service";
import { PreparePermitActions } from "./prepare-permit.actions";

export interface PreparePermitStateModel {
    response: PrefixWorkCodeResponse;
    selectedWorkplaceId: number;
}

const defaults: PreparePermitStateModel = {
    response: undefined,
    selectedWorkplaceId: undefined
};

@Injectable()
@State<PreparePermitStateModel>({
    name: "preparePermit",
    defaults: defaults
})
export class PreparePermitState {
    constructor(private preparePermitService: PreparePermitService) { }

    @Action(PreparePermitActions.GetWorkplaceContainerForPreparation)
    public getWorkplaceContainerForPreparation(ctx: StateContext<PreparePermitStateModel>, action: PreparePermitActions.GetWorkplaceContainerForPreparation): Observable<PrefixWorkCodeResponse> {
        return this.preparePermitService.getWorkplaceContainerForPreparation(action.prefix, action.workCode).pipe(tap((result) => {
            ctx.patchState({ response: result });
        }));
    }

    @Action(PreparePermitActions.CreatePreparedPermit)
    public createPreparedPermit(ctx: StateContext<PreparePermitStateModel>): Observable<CreatePermitResult> {
        const state = ctx.getState();
        const response = state.response;
        const selectedWorkplace = state.response.workplaces.find(_ => _.id === state.selectedWorkplaceId);

        const command: CreatePreparedPermitCommand = {
            workplaceId: selectedWorkplace.id,
            workplace: selectedWorkplace.name,
            workplaceContainer: response.workplace,
            companyName: response.companyName,
            subscriptionId: response.subscriptionId,
        };

        return this.preparePermitService.createPreparedPermit(command).pipe(tap((data) => {
            if (data.success) {
                ctx.dispatch(new Navigate([PermitPaths.Root, data.newPermitId]));
            }
        }));
    }

    @Action(PreparePermitActions.SelectRole)
    public selectRole(ctx: StateContext<PreparePermitStateModel>, action: PreparePermitActions.SelectRole): Observable<BaseValidationResult> {
        const command: SelectRoleCommand = {
            permitId: action.permitId,
            roles: action.roles
        };

        return this.preparePermitService.selectRole(command);
    }

    @Action(PreparePermitActions.ToggleSelectedWorkplace)
    public toggleSelectedWorkplace(ctx: StateContext<PreparePermitStateModel>, action: PreparePermitActions.ToggleSelectedWorkplace): void {
        const state = ctx.getState();
        const workplaceId = state.selectedWorkplaceId === action.workplaceId ? undefined : action.workplaceId;

        ctx.patchState({ selectedWorkplaceId: workplaceId });
    }

    @Action(PreparePermitActions.ResetState)
    public reset(ctx: StateContext<PreparePermitStateModel>): void {
        ctx.setState(defaults);
    }
}
