<div #menu class="root" *ngIf="items?.length > 0">
    <hr class="root__divider root__divider--top">

    <div class="root__menu">
        <div class="root__menu__heading">
            {{ heading }}:
        </div>

        <a *ngFor="let item of items" routerLink="." [fragment]="item.id" class="root__menu__item">
            <span [innerHtml]="item.name"></span>
        </a>
    </div>

    <hr class="root__divider">
</div>
