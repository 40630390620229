import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DownloadFileDirective } from "./directives/download-file.directive";

export { DownloadFileDirective } from "./directives/download-file.directive";

@NgModule({
    imports: [CommonModule],
    declarations: [DownloadFileDirective],
    exports: [DownloadFileDirective],
})
export class HaUtilDirectivesModule {}
