import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Account, AccountImpersonateActions, AuthSelectors } from "@ha/feature/auth";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

@Component({
    selector: "ha-impersonated-information-panel",
    templateUrl: "./impersonated-information-panel.component.html",
    styleUrls: ["./impersonated-information-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonatedInformationPanelComponent {
    @Select(AuthSelectors.currentAccount)
    public currentAccount$: Observable<Account>;

    constructor(private store: Store) {

    }

    public impersonateStop() {
        this.store.dispatch(new AccountImpersonateActions.ImpersonateStop());
    }
}
