<ng-container *transloco="let t; read:'permit.signature'">
    <div class="modal">
        <div class="modal__content">
            <img class="modal__content__image" [src]="data.signature.url">
            <div class="modal__content__details">
                <div class="modal__content__details__name">{{data.name}}</div>
                <div class="modal__content__details__date">{{data.signature.date | haDate:'dateTimeStandard'}}</div>
            </div>

        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>
        </div>
    </div>
</ng-container>
