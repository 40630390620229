import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsModule } from "@ngxs/store";

import { PermitTeamModule } from "../permit-team/permit-team.module";
import { SharedModule } from "../shared/shared.module";
import { HelpModalComponent } from "./components/help/help-modal/help-modal.component";
import { HelpComponent } from "./components/help/help/help.component";
import { PermitConfirmDialogComponent } from "./components/permit-confirm-dialog/permit-confirm-dialog.component";
import { PermitCreateComponent } from "./components/permit-create/permit-create.component";
import {
    PermitEmergencyLocationComponent,
} from "./components/permit-emergency-location/permit-emergency-location.component";
import { PermitFireAlarmComponent } from "./components/permit-fire-alarm/permit-fire-alarm.component";
import {
    PermitIsFlammableHotWorkComponent,
} from "./components/permit-is-flammable-hot-work/permit-is-flammable-hot-work.component";
import { PermitPhotoComponent } from "./components/permit-photo/permit-photo.component";
import { PermitQuestionComponent } from "./components/permit-questions/permit-question/permit-question.component";
import { PermitQuestionsComponent } from "./components/permit-questions/permit-questions.component";
import { PermitSignatureComponent } from "./components/permit-signature/permit-signature.component";
import { PermitTeamComponent } from "./components/permit-team/permit-team.component";
import { ValidTimeComponent } from "./components/permit-valid-time/valid-time.component";
import { PermitWorkMethodsComponent } from "./components/permit-work-methods/permit-work-methods.component";
import {
    CreateNewWorkplaceModalComponent,
} from "./components/permit-workplace/create-new-workplace-modal/create-new-workplace-modal.component";
import {
    PermitWorkplaceBaseComponent,
} from "./components/permit-workplace/permit-workplace-base/permit-workplace-base.component";
import {
    PermitWorkplacePremiumItemComponent,
} from "./components/permit-workplace/permit-workplace-premium/permit-workplace-premium-item.component";
import {
    PermitWorkplacePremiumComponent,
} from "./components/permit-workplace/permit-workplace-premium/permit-workplace-premium.component";
import {
    PermitWorkplaceRecentComponent,
} from "./components/permit-workplace/permit-workplace-recent/permit-workplace-recent.component";
import {
    PermitWorkplaceSelectedComponent,
} from "./components/permit-workplace/permit-workplace-selected/permit-workplace-selected.component";
import {
    PermitWorkplaceStartComponent,
} from "./components/permit-workplace/permit-workplace-start/permit-workplace-start.component";
import { PermitWorkplaceComponent } from "./components/permit-workplace/permit-workplace.component";
import { WorkplaceSearchResultComponent } from "./components/permit-workplace/workplace-search/workplace-search-result.component";
import { WorkplaceSearchComponent } from "./components/permit-workplace/workplace-search/workplace-search.component";
import { PostWorkMonitoringTimeComponent } from "./components/post-work-monitoring-time/post-work-monitoring-time.component";
import { PrepareCompletedComponent } from "./components/prepare-permit/prepare-completed/prepare-completed.component";
import { PreparePermitComponent } from "./components/prepare-permit/prepare-permit.component";
import { SafetyRuleModalComponent } from "./components/safety-rule/safety-rule-modal/safety-rule-modal.component";
import { SafetyRuleComponent } from "./components/safety-rule/safety-rule/safety-rule.component";
import { SelectRoleComponent } from "./components/select-role/select-role.component";
import { PermitCreateState } from "./states/permit-create.state";
import { PermitPhotoState } from "./states/permit-photo.state";
import { PermitQuestionStorageState } from "./states/permit-question-storage.state";
import { PermitQuestionState } from "./states/permit-question.state";
import { PermitWorkplaceState } from "./states/permit-workplace.state";
import { PreparePermitState } from "./states/prepare-permit.state";

@NgModule({
    imports: [
        SharedModule,
        NgxsFormPluginModule,
        NgxsModule.forFeature([
            PermitCreateState,
            PermitQuestionState,
            PermitQuestionStorageState,
            PermitWorkplaceState,
            PermitPhotoState,
            PreparePermitState,
        ]),
        PermitTeamModule,
        ReactiveFormsModule,
    ],
    exports: [PermitCreateComponent],
    declarations: [
        CreateNewWorkplaceModalComponent,
        HelpComponent,
        HelpModalComponent,
        PermitConfirmDialogComponent,
        PermitCreateComponent,
        PermitEmergencyLocationComponent,
        PermitFireAlarmComponent,
        PermitIsFlammableHotWorkComponent,
        PermitPhotoComponent,
        PermitQuestionComponent,
        PermitQuestionsComponent,
        PermitSignatureComponent,
        PermitTeamComponent,
        PermitWorkMethodsComponent,
        PermitWorkplaceBaseComponent,
        PermitWorkplaceComponent,
        PermitWorkplacePremiumComponent,
        PermitWorkplacePremiumItemComponent,
        PermitWorkplaceRecentComponent,
        PermitWorkplaceSelectedComponent,
        PermitWorkplaceStartComponent,
        PostWorkMonitoringTimeComponent,
        PrepareCompletedComponent,
        PreparePermitComponent,
        SafetyRuleComponent,
        SafetyRuleModalComponent,
        SelectRoleComponent,
        ValidTimeComponent,
        WorkplaceSearchComponent,
        WorkplaceSearchResultComponent
    ],
    providers: [],
})
export class PermitCreateModule {}
