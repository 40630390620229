import { Workplace } from "@ha/data/basic";

import { IssuerStructureNode } from "../models/issuer-structure-node.model";
import { PermitWorkPlaceViewEnum } from "../models/permit-work-place-view.enum";
import { RecentPermitWorkplace } from "../models/recent-workplaces.model";

export namespace PermitWorkplaceActions {
    export class GetCurrentIssuerCompanyStructure {
        public static readonly type = "[PermitWorkplaceActions] GetCurrentIssuerCompanyStructure";
        constructor(public sortByDescending: boolean = false) { }
    }

    export class GetRecentWorkplaceBasePermits {
        public static readonly type = "[PermitWorkplaceActions] GetRecentWorkplaceBasePermits";
    }

    export class LoadMoreRecentWorkplaces {
        public static readonly type = "[PermitWorkplaceActions] LoadMoreRecentWorkplaces";
        constructor(public currentLength: number) { }
    }

    export class ChangeView {
        public static readonly type = "[PermitWorkplaceActions] ChangeView";

        constructor(public view: PermitWorkPlaceViewEnum) { }
    }

    export class SetPremiumWorkplace {
        public static readonly type = "[PermitWorkplaceActions] SetPremiumWorkplace";
    }

    export class SetPremiumDefaultWorkplace {
        public static readonly type = "[PermitWorkplaceActions] SetPremiumDefaultWorkplace";
        constructor(public workplaceContainer: IssuerStructureNode) { }
    }

    export class SetPremiumWorkplaceFromHistory {
        public static readonly type = "[PermitWorkplaceActions] SetPremiumWorkplaceFromHistory";
        constructor(public recentWorkplace: RecentPermitWorkplace) { }
    }

    export class CreatePermit {
        public static readonly type = "[PermitWorkplaceActions] CreatePermit";
        constructor(public workplace: string) { }
    }

    export class SetWorkplaceAndEmergencyLocation {
        public static readonly type = "[PermitWorkplaceActions] SetWorkplaceAndEmergencyLocation";
        constructor(public workplace: string, public emergencyLocation: string) { }
    }

    export class CreateWorkplace {
        public static readonly type = "[PermitWorkplaceActions] CreateWorkplace";
        constructor(public workplaceContainer: IssuerStructureNode, public workplace: string) { }
    }

    export class ToggleSelectedNode {
        public static readonly type = "[PermitWorkplaceActions] ToggleSelectedNode";
        constructor(public node: IssuerStructureNode) { }
    }

    export class ToggleSelectedWorkplace {
        public static readonly type = "[PermitWorkplaceActions] ToggleSelectedWorkplace";
        constructor(public workplace: Workplace) { }
    }

    export class SelectSubscription {
        public static readonly type = "[PermitWorkplaceActions] SelectSubscription";
        constructor(public subscriptionId: number) { }
    }

    export class SearchWorkplace {
        public static readonly type = "[PermitWorkplaceActions] SearchWorkplace";
        constructor(public query: string) { }
    }
}
