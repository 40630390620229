import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IssuuItemBlock } from "./issuu-item-block";

@Component({
    selector: "issuu-item-block",
    templateUrl: "issuu-item-block.component.html",
    styleUrls: ["issuu-item-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssuuItemBlockComponent {
    @Input() public data: IssuuItemBlock;
}
