import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

interface AsidePageNavigationLink {
    id: string;
    name: string;
}

@Component({
    selector: "anchor-menu-item",
    templateUrl: "anchor-menu-item.component.html",
    styleUrls: ["anchor-menu-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorMenuItemComponent {
    @Input() public data: AsidePageNavigationLink;
    @Input() public currentFragment: string;

    public get isActive() {
        return this.data.id === this.currentFragment;
    }
}
