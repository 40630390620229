import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { AccountImpersonateCommand } from "../models/account-impersonate-command.model";
import { AccountImpersonateResult } from "../models/account-impersonate-result.model";
import { AccountImpersonateService } from "../services/account-impersonate.service";
import { AccountImpersonateActions } from "./account-impersonate.actions";

export interface AccountImpersonateStateModel {
    form: {
        model?: AccountImpersonateCommand;
        dirty: boolean;
        status: string;
        errors: unknown;
    };
    result: AccountImpersonateResult | undefined;
}

const defaults: AccountImpersonateStateModel = {
    form: {
        model: undefined,
        dirty: false,
        status: "",
        errors: {},
    },
    result: undefined,
};

@Injectable()
@State<AccountImpersonateStateModel>({
    name: "accountImpersonate",
    defaults: defaults,
})
export class AccountImpersonateState {
    constructor(private accountImpersonateService: AccountImpersonateService,) {}

    @Action(AccountImpersonateActions.ImpersonateStart, { cancelUncompleted: true })
    public impersonateStart(ctx: StateContext<AccountImpersonateStateModel>, action: AccountImpersonateActions.ImpersonateStart): Observable<AccountImpersonateResult | undefined> {
        ctx.patchState({
            result: undefined,
        });


        if (!action.identityUserId) {
            return of();
        }

        const command = <AccountImpersonateCommand><unknown>{
            ...ctx.getState().form.model,
            identityUserId: action.identityUserId
        };

        return this.accountImpersonateService.impersonateStart(command).pipe(tap((result) => {
            if (result.success) {
                ctx.setState(defaults);
                location.href = result.autoSignInRedirect;
            } else {
                ctx.patchState({
                    result: result,
                });
            }
        }));
    }

    @Action(AccountImpersonateActions.ImpersonateStop, { cancelUncompleted: true })
    public impersonateStop(ctx: StateContext<AccountImpersonateStateModel>): Observable<AccountImpersonateResult | undefined> {
        return this.accountImpersonateService.impersonateStop().pipe(tap((result) => {
            if (result.success) {
                ctx.setState(defaults);
                location.href = result.autoSignInRedirect;
            } else {
                ctx.patchState({
                    result: result,
                });
            }
        }));
    }
}
