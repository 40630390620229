import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { filter, tap } from "rxjs/operators";

import { HamburgerMenuStateActions } from "./hamburger-menu.actions";
import { ProfileStateActions } from "./profile.actions";

export interface HamburgerMenuStateModel {
    menuVisible: boolean;
    knowledgebankMenuVisible: boolean;
}

@Injectable()
@State<HamburgerMenuStateModel>({
    name: "harmburgerMenu",
    defaults: {
        menuVisible: false,
        knowledgebankMenuVisible: false,
    },
})
export class HamburgerMenuState implements NgxsOnInit {
    @Selector()
    public static menuVisible(state: HamburgerMenuStateModel): boolean {
        return state.menuVisible;
    }

    @Selector()
    public static knowledgebankMenuVisible(state: HamburgerMenuStateModel): boolean {
        return state.knowledgebankMenuVisible;
    }

    constructor(private router: Router) {}

    public ngxsOnInit(ctx: StateContext<HamburgerMenuStateModel>): void {
        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationStart),
                tap(() => {
                    ctx.dispatch(new HamburgerMenuStateActions.HideAll());
                }),
            )
            .subscribe();
    }

    @Action(HamburgerMenuStateActions.ToggleMenu)
    public toggleMenu(ctx: StateContext<HamburgerMenuStateModel>): void {
        const currentState = ctx.getState();

        if (!currentState.menuVisible) {
            ctx.dispatch(new ProfileStateActions.HideMenu());
        }

        ctx.patchState({
            menuVisible: !currentState.menuVisible,
            knowledgebankMenuVisible: false,
        });
    }

    @Action(HamburgerMenuStateActions.ToggleKnowledgebankMenu)
    public toggleKnowledgebankMenu(ctx: StateContext<HamburgerMenuStateModel>): void {
        const currentState = ctx.getState();

        if (!currentState.menuVisible) {
            ctx.dispatch(new ProfileStateActions.HideMenu());
        }

        ctx.patchState({
            knowledgebankMenuVisible: !currentState.knowledgebankMenuVisible,
            menuVisible: false,
        });
    }

    @Action(HamburgerMenuStateActions.HideAll)
    public hideAllMenus(ctx: StateContext<HamburgerMenuStateModel>): void {
        ctx.patchState({
            knowledgebankMenuVisible: false,
            menuVisible: false,
        });
    }
}
