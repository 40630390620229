import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import produce from "immer";
import { Observable, tap } from "rxjs";

import { CertificateStatus } from "../../../permit-create/models/certificate-status.enum";
import { AbortChangeIssuerCommand, ChangeIssuerCommand, ChangeIssuerDto, IssuerDto } from "../../models/change-issuer-dto";
import { PermitChangeIssuerService } from "../../services/permit-change-issuer.service";
import { PermitChangeIssuerActions } from "./change-issuer.action";

export interface PermitChangeIssuerStateModel {
    availableIssuers: IssuerDto[];
    canChangeIssuer: boolean;
    changing: boolean;
    isPremium: boolean;
    issuerToBe: IssuerDto;
    searching: boolean;
    searchResult: IssuerDto;
    searchResultStatus: CertificateStatus;
    selectedNewIssuer: IssuerDto;
}

const defaults = {
    availableIssuers: undefined,
    canChangeIssuer: false,
    changing: undefined,
    isPremium: undefined,
    issuerToBe: undefined,
    searching: undefined,
    searchResult: undefined,
    searchResultStatus: undefined,
    selectedNewIssuer: undefined,
};

@Injectable()
@State<PermitChangeIssuerStateModel>({
    name: "permitDetailsChangeIssuer",
    defaults: defaults,
})
export class PermitChangeIssuerState {
    constructor(private issuerService: PermitChangeIssuerService) {}

    @Action(PermitChangeIssuerActions.AbortChangeIssuer)
    public abortChangeIssuer(ctx: StateContext<PermitChangeIssuerStateModel>, action: PermitChangeIssuerActions.AbortChangeIssuer): Observable<boolean> {
        const command: AbortChangeIssuerCommand = {
            permitId: action.permitId,
        };

        return this.issuerService.abortChangeIssuer(command).pipe(tap(() => {
            ctx.setState(produce(draft => {
                draft.issuerToBe = undefined;
            }));
        }));
    }

    @Action(PermitChangeIssuerActions.ChangeIssuer)
    public changeIssuer(ctx: StateContext<PermitChangeIssuerStateModel>, action: PermitChangeIssuerActions.ChangeIssuer): Observable<boolean> {
        const command: ChangeIssuerCommand = {
            permitId: action.permitId,
            newIssuerCertificateId: ctx.getState().selectedNewIssuer.certificateId
        };

        ctx.setState(produce(draft => {
            draft.changing = true;
        }));

        return this.issuerService.changeIssuer(command).pipe(tap(() => {
            ctx.setState(produce(draft => {
                draft.changing = false;
                draft.selectedNewIssuer = undefined;
            }));
        }));
    }

    @Action(PermitChangeIssuerActions.GetStatus)
    public getStatus(ctx: StateContext<PermitChangeIssuerStateModel>, action: PermitChangeIssuerActions.GetStatus): Observable<any> {
        return this.issuerService.getStatus(action.permitId).pipe(tap((status: ChangeIssuerDto) => {
            ctx.setState(produce(draft => {
                draft.canChangeIssuer = status.canChangeIssuer;
                draft.isPremium = status.isPremium;
                draft.availableIssuers = status.issuers;
                draft.issuerToBe = status.issuerToBe;
            }));
        }));
    }

    @Action(PermitChangeIssuerActions.SearchIssuer)
    public searchIssuer(ctx: StateContext<PermitChangeIssuerStateModel>, action: PermitChangeIssuerActions.SearchIssuer): Observable<IssuerDto> {
        ctx.setState(produce(draft => {
            draft.searching = true;
            draft.searchResult = undefined;
            draft.searchResultStatus = undefined;
        }));

        return this.issuerService.searchIssuer(action.permitId, action.query).pipe(tap((issuer: IssuerDto) => {
            let status: CertificateStatus = !issuer || issuer == null ? CertificateStatus.CertificateNotFound : issuer.status;

            ctx.setState(produce(draft => {
                draft.searching = false;
                draft.searchResult = issuer;
                draft.searchResultStatus = status;
            }));
        }));
    }

    @Action(PermitChangeIssuerActions.SelectIssuer)
    public selectIssuer(ctx: StateContext<PermitChangeIssuerStateModel>, action: PermitChangeIssuerActions.SelectIssuer): PermitChangeIssuerStateModel {
        return ctx.setState(produce(draft => {
            if (draft.selectedNewIssuer && draft.selectedNewIssuer.certificateId === action.certificateId) {
                draft.selectedNewIssuer = undefined;
            } else {
                if (draft.isPremium) {
                    draft.selectedNewIssuer = draft.availableIssuers.find(_ => _.certificateId === action.certificateId);
                } else if (draft.searchResult.certificateId == action.certificateId) {
                    draft.selectedNewIssuer = draft.searchResult as IssuerDto;
                }
            }
        }));
    }

    @Action(PermitChangeIssuerActions.Reset)
    public reset(ctx: StateContext<PermitChangeIssuerStateModel>): PermitChangeIssuerStateModel {
        return ctx.setState(defaults);
    }
}
