import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";

import { PermitCreateModule } from "../permit-create/permit-create.module";
import { PermitDetailsModule } from "../permit-details/permit-details.module";
import { SharedModule } from "../shared/shared.module";
import { PermitComponent } from "./components/permit/permit.component";
import { PermitStepState } from "./states/permit-step.state";

@NgModule({
    imports: [SharedModule, NgxsModule.forFeature([PermitStepState]), PermitCreateModule, PermitDetailsModule],
    exports: [],
    declarations: [PermitComponent],
    providers: [],
})
export class PermitModule {}
