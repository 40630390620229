export namespace HamburgerMenuStateActions {
    export class ToggleMenu {
        public static readonly type = "[HamburgerMenu] ToggleMenu";
    }

    export class ToggleKnowledgebankMenu {
        public static readonly type = "[HamburgerMenu] ToggleKnowledgebankMenu";
    }

    export class HideAll {
        public static readonly type = "[HamburgerMenu] HideAll";
    }
}
