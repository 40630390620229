import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[clickOutside]",
})
export class ClickOutsideDirective {
    @Output() public clickOutside = new EventEmitter();

    private localEvent: Event | undefined;

    /** Compare event at the Document level to a reference of the Element:click
     * This method triggers when we are on Document level  - Document was clicked or event bubbling
     * If the Document click DON'T MATCH the Event:click reference  => than the click is from outside of the Element
     * @param event
     */
    @HostListener("document:click", ["$event"])
    public compareEvent(event: PointerEvent): void {
        function isClickInOverlay() {
            return (event.target as Element).className === "cdk-overlay-container";
        }

        if (event !== this.localEvent && isClickInOverlay()) {
            this.clickOutside.emit(event);
        }

        this.localEvent = undefined;
    }

    /** Track user click from inside the bound target
     *  We use this to track the click Event when it bubbles up the DOM tree
     * @param event
     */
    @HostListener("click", ["$event"])
    public trackEvent(event: Event): void {
        this.localEvent = event;
    }
}
