<div class="card-face-front">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 242.53 153.01"
         style="enable-background:new 0 0 242.53 153.01;" xml:space="preserve">
        <style type="text/css">
            .st0 {
                clip-path: url(#SVGID_00000114753534286022604090000007082777144434316469_);
            }

            .st1 {
                clip-path: url(#SVGID_00000150801533097598175900000011875619963831293351_);
                fill: #D3104D;
            }

            .st2 {
                clip-path: url(#SVGID_00000150801533097598175900000011875619963831293351_);
                fill: #C01544;
            }

            .st3 {
                clip-path: url(#SVGID_00000114753534286022604090000007082777144434316469_);
                fill: #C01544;
            }

            .st4 {
                clip-path: url(#SVGID_00000181064405447099552720000009385956194950177958_);
                fill: #EFD1D9;
            }

            .st5 {
                clip-path: url(#SVGID_00000181064405447099552720000009385956194950177958_);
                fill: #CC1249;
            }

            .st6 {
                clip-path: url(#SVGID_00000181064405447099552720000009385956194950177958_);
                fill: #BD1544;
            }

            .st7 {
                clip-path: url(#SVGID_00000114753534286022604090000007082777144434316469_);
                fill: #BD1544;
            }

            .st8 {
                clip-path: url(#SVGID_00000008133436197126801020000013079884580839685272_);
                fill: #BD1544;
            }

            .st9 {
                clip-path: url(#SVGID_00000008133436197126801020000013079884580839685272_);
                fill: #EFD1D9;
            }

            .st10 {
                clip-path: url(#SVGID_00000008133436197126801020000013079884580839685272_);
                fill: #CC1249;
            }

            .st11 {
                clip-path: url(#SVGID_00000181798130229106109070000007300940183566530440_);
                fill: #BD1544;
            }

            .st12 {
                clip-path: url(#SVGID_00000181798130229106109070000007300940183566530440_);
                fill: #EFD1D9;
            }

            .st13 {
                clip-path: url(#SVGID_00000181798130229106109070000007300940183566530440_);
                fill: #CC1249;
            }

            .st14 {
                clip-path: url(#SVGID_00000034084770382927308920000013759859466460778889_);
                fill: #BD1544;
            }

            .st15 {
                clip-path: url(#SVGID_00000034084770382927308920000013759859466460778889_);
                fill: #EFD1D9;
            }

            .st16 {
                clip-path: url(#SVGID_00000034084770382927308920000013759859466460778889_);
                fill: #CC1249;
            }

            .st17 {
                clip-path: url(#SVGID_00000124160508335690980310000017749317754646536854_);
                fill: #BD1544;
            }

            .st18 {
                clip-path: url(#SVGID_00000124160508335690980310000017749317754646536854_);
                fill: #EFD1D9;
            }

            .st19 {
                clip-path: url(#SVGID_00000124160508335690980310000017749317754646536854_);
                fill: #CC1249;
            }

            .st20 {
                clip-path: url(#SVGID_00000133530099291203128610000016903367638197871763_);
                fill: #BD1544;
            }

            .st21 {
                clip-path: url(#SVGID_00000133530099291203128610000016903367638197871763_);
                fill: #EFD1D9;
            }

            .st22 {
                clip-path: url(#SVGID_00000133530099291203128610000016903367638197871763_);
                fill: #CC1249;
            }

            .st23 {
                clip-path: url(#SVGID_00000163030728839871752670000017047748100724829355_);
                fill: #BD1544;
            }

            .st24 {
                clip-path: url(#SVGID_00000163030728839871752670000017047748100724829355_);
                fill: #EFD1D9;
            }

            .st25 {
                clip-path: url(#SVGID_00000163030728839871752670000017047748100724829355_);
                fill: #CC1249;
            }

            .st26 {
                clip-path: url(#SVGID_00000017492714299401537430000015031104516152178327_);
                fill: #BD1544;
            }

            .st27 {
                clip-path: url(#SVGID_00000017492714299401537430000015031104516152178327_);
                fill: #EFD1D9;
            }

            .st28 {
                clip-path: url(#SVGID_00000017492714299401537430000015031104516152178327_);
                fill: #CC1249;
            }

            .st29 {
                clip-path: url(#SVGID_00000127722333603916184920000011990101154260100287_);
                fill: #BD1544;
            }

            .st30 {
                clip-path: url(#SVGID_00000127722333603916184920000011990101154260100287_);
                fill: #CC1249;
            }

            .st31 {
                clip-path: url(#SVGID_00000176747443402020424020000017473312530516529838_);
                fill: #BD1544;
            }

            .st32 {
                clip-path: url(#SVGID_00000176747443402020424020000017473312530516529838_);
                fill: #EFD1D9;
            }

            .st33 {
                clip-path: url(#SVGID_00000176747443402020424020000017473312530516529838_);
                fill: #CC1249;
            }

            .st34 {
                clip-path: url(#SVGID_00000014622115228647671390000005536920181413884600_);
                fill: #BD1544;
            }

            .st35 {
                clip-path: url(#SVGID_00000014622115228647671390000005536920181413884600_);
                fill: #EFD1D9;
            }

            .st36 {
                clip-path: url(#SVGID_00000014622115228647671390000005536920181413884600_);
                fill: #CC1249;
            }

            .st37 {
                clip-path: url(#SVGID_00000087370200935468248790000013047137967989259695_);
                fill: #BD1544;
            }

            .st38 {
                clip-path: url(#SVGID_00000087370200935468248790000013047137967989259695_);
                fill: #EFD1D9;
            }

            .st39 {
                clip-path: url(#SVGID_00000087370200935468248790000013047137967989259695_);
                fill: #CC1249;
            }

            .st40 {
                clip-path: url(#SVGID_00000045597060318477437920000010853373780837145753_);
                fill: #BD1544;
            }

            .st41 {
                clip-path: url(#SVGID_00000045597060318477437920000010853373780837145753_);
                fill: #EFD1D9;
            }

            .st42 {
                clip-path: url(#SVGID_00000045597060318477437920000010853373780837145753_);
                fill: #CC1249;
            }

            .st43 {
                clip-path: url(#SVGID_00000162324653964228575070000007414773624996360840_);
                fill: #BD1544;
            }

            .st44 {
                clip-path: url(#SVGID_00000162324653964228575070000007414773624996360840_);
                fill: #EFD1D9;
            }

            .st45 {
                clip-path: url(#SVGID_00000162324653964228575070000007414773624996360840_);
                fill: #CC1249;
            }

            .st46 {
                clip-path: url(#SVGID_00000055673992037543419400000016909189629449905293_);
                fill: #BD1544;
            }

            .st47 {
                clip-path: url(#SVGID_00000055673992037543419400000016909189629449905293_);
                fill: #EFD1D9;
            }

            .st48 {
                clip-path: url(#SVGID_00000055673992037543419400000016909189629449905293_);
                fill: #CC1249;
            }

            .st49 {
                clip-path: url(#SVGID_00000005964783737572013090000014959363732345840283_);
                fill: #BD1544;
            }

            .st50 {
                clip-path: url(#SVGID_00000005964783737572013090000014959363732345840283_);
                fill: #EFD1D9;
            }

            .st51 {
                clip-path: url(#SVGID_00000005964783737572013090000014959363732345840283_);
                fill: #CC1249;
            }

            .st52 {
                clip-path: url(#SVGID_00000030487567185207644340000014441433805981993644_);
                fill: #BD1544;
            }

            .st53 {
                clip-path: url(#SVGID_00000030487567185207644340000014441433805981993644_);
                fill: #EFD1D9;
            }

            .st54 {
                clip-path: url(#SVGID_00000030487567185207644340000014441433805981993644_);
                fill: #CC1249;
            }

            .st55 {
                clip-path: url(#SVGID_00000090286754104778354900000010157179194747146152_);
                fill: #BD1544;
            }

            .st56 {
                clip-path: url(#SVGID_00000090286754104778354900000010157179194747146152_);
                fill: #EFD1D9;
            }

            .st57 {
                clip-path: url(#SVGID_00000090286754104778354900000010157179194747146152_);
                fill: #CC1249;
            }

            .st58 {
                clip-path: url(#SVGID_00000076601854528417738430000013932740219550143361_);
                fill: #BD1544;
            }

            .st59 {
                clip-path: url(#SVGID_00000076601854528417738430000013932740219550143361_);
                fill: #EFD1D9;
            }

            .st60 {
                clip-path: url(#SVGID_00000076601854528417738430000013932740219550143361_);
                fill: #CC1249;
            }

            .st61 {
                clip-path: url(#SVGID_00000096031766158923890530000013782516977022172853_);
                fill: #BD1544;
            }

            .st62 {
                clip-path: url(#SVGID_00000096031766158923890530000013782516977022172853_);
                fill: #EFD1D9;
            }

            .st63 {
                clip-path: url(#SVGID_00000096031766158923890530000013782516977022172853_);
                fill: #CC1249;
            }

            .st64 {
                clip-path: url(#SVGID_00000067914545733856687680000005714319542559821723_);
                fill: #BD1544;
            }

            .st65 {
                clip-path: url(#SVGID_00000067914545733856687680000005714319542559821723_);
                fill: #EFD1D9;
            }

            .st66 {
                clip-path: url(#SVGID_00000067914545733856687680000005714319542559821723_);
                fill: #CC1249;
            }

            .st67 {
                clip-path: url(#SVGID_00000172401022344926221960000002284705729214187440_);
                fill: #BD1544;
            }

            .st68 {
                clip-path: url(#SVGID_00000172401022344926221960000002284705729214187440_);
                fill: #EFD1D9;
            }

            .st69 {
                clip-path: url(#SVGID_00000172401022344926221960000002284705729214187440_);
                fill: #CC1249;
            }

            .st70 {
                clip-path: url(#SVGID_00000092417239729291112240000008386683770089161632_);
                fill: #BD1544;
            }

            .st71 {
                clip-path: url(#SVGID_00000092417239729291112240000008386683770089161632_);
                fill: #CC1249;
            }

            .st72 {
                clip-path: url(#SVGID_00000134962322921441757470000011925787462223298443_);
                fill: #BD1544;
            }

            .st73 {
                clip-path: url(#SVGID_00000134962322921441757470000011925787462223298443_);
                fill: #EFD1D9;
            }

            .st74 {
                clip-path: url(#SVGID_00000134962322921441757470000011925787462223298443_);
                fill: #CC1249;
            }

            .st75 {
                clip-path: url(#SVGID_00000092431792798452860840000002914465434710149538_);
                fill: #BD1544;
            }

            .st76 {
                clip-path: url(#SVGID_00000092431792798452860840000002914465434710149538_);
                fill: #EFD1D9;
            }

            .st77 {
                clip-path: url(#SVGID_00000092431792798452860840000002914465434710149538_);
                fill: #CC1249;
            }

            .st78 {
                clip-path: url(#SVGID_00000070089468944984104150000015682854911466798257_);
                fill: #BD1544;
            }

            .st79 {
                clip-path: url(#SVGID_00000070089468944984104150000015682854911466798257_);
                fill: #CC1249;
            }

            .st80 {
                clip-path: url(#SVGID_00000070089468944984104150000015682854911466798257_);
                fill: #EFD1D9;
            }

            .st81 {
                clip-path: url(#SVGID_00000172405087015809989070000003863797145415284608_);
                fill: #FFFFFF;
            }

            .st82 {
                clip-path: url(#SVGID_00000114753534286022604090000007082777144434316469_);
                fill: #FFFFFF;
            }

            .st83 {
                fill: none;
            }

        </style>
        <g id="Layer_1">
        </g>
        <g id="Layer_2">
            <g>
                <defs>
                    <path id="SVGID_1_" d="M231.37,153.01H11.17C5,153.01,0,148.01,0,141.85V11.17C0,5,5,0,11.17,0h220.2c6.17,0,11.17,5,11.17,11.17
				v130.68C242.53,148.01,237.53,153.01,231.37,153.01z" />
                </defs>
                <clipPath id="SVGID_00000090998985456101407600000005993978295494186410_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000047778270502512472720000006070688800347131776_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000017482839917050743090000003389981951641617793_">
                        <use xlink:href="#SVGID_00000047778270502512472720000006070688800347131776_" style="overflow:visible;" />
                    </clipPath>
                    <polyline style="clip-path:url(#SVGID_00000017482839917050743090000003389981951641617793_);fill:#D3104D;" points="
				246.79,157.27 -4.25,157.27 -4.25,-4.26 246.79,-4.26 246.79,157.27 			" />
                    <path style="clip-path:url(#SVGID_00000017482839917050743090000003389981951641617793_);fill:#C01544;" d="M105.63,6.33
				c5.92,0.83,10.79,7.09,13.76,11.61c1.1,1.69,2.2,3.39,3.31,5.08c0.42,0.58,1.03,0.48,1.67,0.48c2.33-0.22,3.55-7.88,2.71-11.59
				c-0.04-0.2-0.1-0.93-0.25-1.63c2.7,1.64,4.94,9.59,4.94,15.35c0.01,2.27-0.14,4.48-0.13,6.75c0.6,1.49,2.18,1.42,2.68,1.23
				c2.56-1.41,4.52-4.28,5.68-8.24c0.79,4.96-2.43,16.08-5.54,23.01c-0.35,0.61-0.57,1.23-0.91,1.84l-0.13-0.08
				c-0.78-1.62-1.57-3.24-2.35-4.86c-1.72-3.28-3.83-6.43-6.47-9.24c-1.8-1.91-3.72-3.65-5.76-5.08c-1.05-0.74-3.1-2.01-4.07-2.65
				c-0.06-2.08-0.45-3.99-0.79-5.97C112.52,16.01,110.91,10.38,105.63,6.33" />
                    <path style="clip-path:url(#SVGID_00000017482839917050743090000003389981951641617793_);fill:#C01544;" d="M158.4,11.76
				c2.45,1.37,4.5,4.42,6.1,6.55c9.44,12.59,8.94,28.77,6.06,44.74c-0.29,1.6-1.86,5.84,0.46,6.54c0.38,0.11,0.73,0.15,1.44-0.12
				c8.47-3.71,14.48-16.17,15.88-24.74c0.54-3.32,0.15-10.17,0.15-10.2c2,1.52,3.67,5.94,4.61,8.21c5.94,14.43,1.41,28.7-3.51,41.81
				c-1.48,3.93-3.82,7.53-4.45,11.87c-0.07,0.48-0.05,0.98,0.2,1.35c0.37,0.58,1.06,0.87,1.75,0.87c0.17-0.03,0.33-0.05,0.5-0.08
				c9.16-3.67,16.86-12.53,20.74-21.31c0.21-0.47,1.49-3.42,1.7-3.67c3.54,14.67-0.05,26.98-9.73,37.28
				c-4.1,4.36-9.22,7.48-13.51,11.39c-1.44,1.3-1.46,3.61,0.96,3.61c0.8-0.03,2.2-0.41,3.04-0.58c2.22-0.58,4.24-1.29,6.37-2.11
				c5.78-2.44,10.65-5.96,15.02-10.92c0.95-1.09,2.5-3.08,2.53-3.08c-1.65,12.76-10.8,22.04-21.62,27.93
				c-2.09,1.13-9.08,3.76-9.9,5.95c-1.14,3.04,3.6,2.78,5.55,2.78c0.55-0.03,2.21-0.07,5.26-0.82c1.66-0.38,8.49-3.23,11.84-4.85
				c-0.43,1.61-1.67,3.01-2.8,4.26c-5.12,5.63-11.33,9.47-18.58,11.01c-1.61,0.34-10.27,1.21-10.88,2.07
				c-2.51,3.59,10.31,7.04,14.75,6.82c0.68-0.05,2.88-0.08,2.63-0.08c-0.03,0.05-2.18,2.78-4.91,4.29
				c-8.63,4.77-18.43,4.08-27.04,0.11c-2.83-1.31-6.73-4.97-9.81-5.07c-0.87-0.03-1.56,0.33-2.13,0.9
				c-2.46,2.45,1.81,7.97,3.42,9.97c2.88,3.6,6.33,6.72,10.2,8.96c0.45,0.27,0.89,0.54,1.34,0.8c-1.29,0.07-14.58-1.57-20.96-4.11
				c-3.04-1.22-8.47-4.6-11.03-4.69c-2.11-0.09-4.08,2.38-5.64,3.62c-1.09,0.84-2.11,1.4-3.16,2.09c-3.75,2.3-7.72,3.7-12.15,4.7
				c-2.46,0.56-5.65,0.81-8.44,0.7c-0.03-0.03,11.02-13.61,6.43-15.1c-1.54-0.5-9.31,4.92-11.45,5.88
				c-2.11,0.95-4.42,1.55-6.73,2.08c-3.89,0.87-8.37,1.21-12.58,0.67c-1.94-0.23-5.06-1.33-5.04-1.36c2.45-0.1,5.03-0.21,6.38-0.48
				c1.82-0.39,2.92-0.81,4.39-1.47c1.57-0.66,8.78-4.78,7.7-7.11c-0.78-1.69-4.18-0.59-5.57-0.49c-15.01,1.07-22.33-2.47-27.86-5.09
				c-0.76-0.36-6.04-2.81-7.72-4.87c9.94,2.38,19.04,0.07,20.78-0.13c2.43-0.51,7.18-1.4,5.89-4.21c-0.29-0.62-0.93-0.98-1.52-1.23
				c-2.2-0.57-4.39-1.15-6.6-1.73c-2.81-0.71-5.51-1.81-8.13-2.88c-12.68-5.22-24.22-15.92-27.99-29.43
				c-0.76-2.72-1.35-7.87-0.95-8.61c4.76,7.54,15.45,17.75,24.9,17.75c0.43-0.08,0.68-0.38,0.88-0.74c1.47-2.06-2.21-4.23-3.5-5.27
				c-12.69-10.22-20.83-24.5-22.18-40.93c-0.14-1.72-0.09-3.47,0.08-5.17c0.05-0.13,0.09-0.27,0.14-0.41v0.08
				c0.63,1.12,1.25,2.25,1.87,3.38c0.92,1.35,1.85,2.71,2.77,4.06c2.29,2.96,12.91,13.16,16.54,9.65c0.81-0.78,0.69-1.83,0.39-2.83
				c-1.29-4.18-3.79-7.75-5.33-11.8c-4.47-11.73-2.43-24.7,2.76-35.55c0.74-1.54,3.83-5.11,4.39-5.81c0.46-0.57-0.58,3.11-0.78,3.9
				c-2.17,8.51,1.32,16.78,6.38,22.7c1.12,1.31,2.5,3.11,4.38,3.11c0.94-0.1,1.29-0.68,1.5-1.11c0.75-1.57-0.51-4.69-0.65-6.54
				c-0.19-2.46-0.38-4.93-0.57-7.4c-0.46-9.78,2.46-19.46,7.25-26.73c0.89-1.25,1.78-2.51,2.67-3.76c-0.31,1.27-0.63,2.53-0.95,3.79
				c-0.52,1.72-0.76,3.55-0.98,5.42c-0.65,5.47,1.29,23.17,7.85,23.17c1.18-0.1,2.15-0.99,2.39-2.4c0.49-2.97-0.9-6.23-1.13-9.19
				c-0.66-8.3,1.6-16.9,6.06-22.84c0.57-0.8,1.32-1.81,1.89-2.39c-0.82,6.37-2.25,13.09-0.61,19.62c2.35,9.43,9.48,16.81,9.31,27.57
				c-0.13,8.14-3.61,15.25-6.46,22.12c-1.69,4.07-4.29,8.75-3.49,13.72c0.04,0.19,0.07,0.38,0.11,0.57
				c1.11,5.53,5.65,9.37,11.58,9.37c0.33-0.03,0.66-0.05,0.98-0.08c0.17-0.03,0.33-0.05,0.49-0.08c0.17-0.03,0.33-0.06,0.49-0.08
				c1.93-0.81,3.69-1.07,5.41-2.3c3.86-2.77,6.09-7.1,7.33-11.33c3.64-12.4,1.39-24.13-3.73-34.91c-1.48-3.11-3.23-6.14-5.27-8.95
				c-0.93-1.14-1.86-2.29-2.79-3.44c0.33-0.47,1.82,0.07,2.15,0.19c4.8,1.7,7.95,5.98,10.98,9.9c2.85,3.69,4.93,0.2,3.4-3.23
				c-2.74-6.12-9.22-14.94-16.51-15.92c-1.01-0.04-2.02-0.08-3.03-0.12h-0.08v-0.08h0.08c1.55-2.11,4.52-3.41,7.34-3.68
				c2.73-0.31,7.08,1.2,9.18,2.44c4.89,2.89,8.2,7.48,10.69,12.32c4,7.81,6.14,16.53,7.35,25.75c1.3,9.94,1.28,20.24,3.53,29.74
				c0.63,2.67,1.27,6.34,4.74,6.34c0.54-0.31,1.08-0.61,1.62-0.92c0.09-0.1,0.18-0.2,0.28-0.3c0.17-0.22,0.33-0.44,0.5-0.66
				c0.1-0.15,0.19-0.29,0.28-0.44c0.15-0.24,0.29-0.48,0.44-0.72c0.09-0.18,0.19-0.35,0.29-0.53c0.13-0.26,0.26-0.52,0.38-0.78
				c0.16-0.36,0.31-0.73,0.46-1.09c2.63-7.37,0.81-16.09,0.07-23.74c-0.78-8.04-1.21-16.68-0.03-24.67
				c0.93-6.33,3.08-12.59,5.86-18.02c3.21-6.28,7.79-11.48,7.92-19.38c-0.01-0.43-0.03-0.86-0.04-1.29
				c-0.02-0.2-0.04-0.39-0.06-0.59c-0.02-0.22-0.05-0.44-0.07-0.66c-0.02-0.14-0.04-0.28-0.05-0.41c-0.02-0.11-0.03-0.21-0.04-0.32
				c-0.02-0.11-0.04-0.22-0.06-0.33c-0.1-0.49-0.19-0.97-0.29-1.46l-0.05-0.21c-0.1-0.38-0.2-0.75-0.3-1.13
				c-0.19-0.58-0.37-1.16-0.56-1.73l-0.09-0.24l-0.09-0.24C158.57,12.36,158.48,12.06,158.4,11.76" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#C01544;" d="M106.7,68.88
			c0.05,0,2.17,6.04,2.58,9.43c0.55,4.6,0.51,21.09-7.42,19.72c-1.21-0.21-2.39-0.69-2.92-1.8c-2.66-5.54,1.36-8.57,4.77-15.59
			C105.2,77.53,106.49,73.47,106.7,68.88" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000011713047565664095830000003983795661970862743_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000132807091778460277080000015708242561765607073_">
                        <use xlink:href="#SVGID_00000011713047565664095830000003983795661970862743_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000132807091778460277080000015708242561765607073_);fill:#EFD1D9;" d="M246.79,143.42v-0.59
				c4.78-1.1,9.57-2.14,14.39-3.06c4.61-0.88,9.24-1.66,13.9-2.23c2.99-0.37,5.99-0.66,9-0.83c0.96-0.05,1.91-0.09,2.86-0.1v0.51
				c-2.33,0.14-4.65,0.28-6.98,0.49c-3.26,0.3-6.5,0.69-9.73,1.18c-7.49,1.12-14.89,2.64-22.26,4.35
				C247.57,143.23,247.18,143.33,246.79,143.42 M-44.41,136.87c0-0.21,0-0.43,0-0.64c3.19-0.02,6.35-0.41,9.51-0.75
				c3.59-0.39,7.16-0.92,10.71-1.55c6.69-1.19,13.33-2.65,19.93-4.22v0.55c-0.37,0.09-0.74,0.18-1.11,0.27
				c-7.25,1.75-14.54,3.31-21.91,4.48c-3.54,0.56-7.09,1.07-10.67,1.38C-40.1,136.58-42.25,136.76-44.41,136.87" />
                    <path style="clip-path:url(#SVGID_00000132807091778460277080000015708242561765607073_);fill:#CC1249;" d="M186.36,154.94
				c0.77-0.21,1.53-0.45,2.28-0.72c0.37-0.03,0.74-0.07,1.11-0.1c4.97-0.48,9.91-1.12,14.83-1.95c9.47-1.61,18.83-3.78,28.17-6.02
				c4.67-1.12,9.35-2.25,14.03-3.32v0.59c-5.61,1.31-11.21,2.63-16.82,3.94c-4.05,0.95-8.1,1.92-12.17,2.8
				c-5.81,1.26-11.65,2.39-17.54,3.27c-3.5,0.52-7.02,0.98-10.55,1.27C188.6,154.8,187.48,154.87,186.36,154.94 M-4.25,130.26v-0.55
				c1.22-0.29,2.45-0.59,3.67-0.89c6.42-1.58,12.83-3.16,19.26-4.68c4.28-1.01,8.58-1.97,12.89-2.82c0.06,0.18,0.12,0.36,0.19,0.55
				c-0.15,0.03-0.3,0.06-0.45,0.09c-5.8,1.14-11.55,2.48-17.29,3.88C7.94,127.32,1.84,128.79-4.25,130.26 M38.4,120.66
				c-0.18-0.16-0.36-0.33-0.54-0.5c3.32-0.57,6.66-1.09,10.01-1.45c0.18,0.18,0.37,0.36,0.56,0.54
				C45.07,119.62,41.73,120.1,38.4,120.66" />
                    <path style="clip-path:url(#SVGID_00000132807091778460277080000015708242561765607073_);fill:#BD1544;" d="M178.17,155.25
				c-1.48,0-2.95-0.02-4.43-0.03c-2.39-0.02-4.79-0.21-7.17-0.45c-2.37-0.24-4.72-0.54-7.07-0.9c-3.3-0.51-6.55-1.27-9.77-2.14
				c-4.44-1.19-8.75-2.76-12.95-4.63c-4.27-1.91-8.37-4.15-12.33-6.65c-3.66-2.32-7.18-4.85-10.8-7.24
				c-5.3-3.5-10.86-6.51-16.77-8.83c-6.04-2.38-12.28-4.01-18.69-4.94c-3.51-0.51-7.03-0.82-10.58-0.96c-1.6-0.06-3.2-0.1-4.8-0.1
				c-0.86,0-1.73,0.01-2.59,0.03c-3.05,0.08-6.1,0.3-9.14,0.58c-0.89,0.08-1.77,0.17-2.65,0.27c-0.19-0.18-0.37-0.36-0.56-0.54
				c0.14-0.02,0.29-0.03,0.43-0.05c2.89-0.3,5.78-0.57,8.69-0.7c1.96-0.09,3.92-0.19,5.87-0.19c0.12,0,0.24,0,0.37,0
				c3.62,0.03,7.25,0.19,10.86,0.57c6.64,0.7,13.13,2.04,19.45,4.21c4.75,1.63,9.32,3.69,13.71,6.14c5.02,2.8,9.67,6.16,14.43,9.37
				c2.64,1.78,5.36,3.46,8.15,4.99c5.28,2.91,10.79,5.31,16.54,7.13c5.48,1.74,11.08,2.95,16.79,3.66
				c3.37,0.42,6.75,0.66,10.15,0.79c1.53,0.06,3.06,0.1,4.59,0.1c0.83,0,1.65-0.01,2.48-0.05c2.76-0.11,5.53-0.24,8.29-0.47
				c-0.75,0.27-1.51,0.51-2.28,0.72c-1.13,0.07-2.26,0.13-3.39,0.2C181.38,155.23,179.77,155.25,178.17,155.25 M31.76,121.87
				c-0.06-0.18-0.13-0.36-0.19-0.55c2-0.39,4-0.77,6.01-1.11c0.09-0.01,0.18-0.03,0.27-0.05c0.18,0.17,0.36,0.33,0.54,0.5
				C36.18,121.03,33.97,121.44,31.76,121.87" />
                    <path style="clip-path:url(#SVGID_00000132807091778460277080000015708242561765607073_);fill:#CC1249;" d="M191.63,118.24
				c0.29-0.23,0.58-0.45,0.88-0.68c0.48-0.05,0.96-0.11,1.44-0.17c5.27-0.64,10.5-1.49,15.7-2.51c-0.26,0.25-0.52,0.49-0.78,0.73
				c-4.02,0.78-8.06,1.47-12.13,2.01C195.04,117.86,193.33,118.06,191.63,118.24 M213.47,114.69c0.07-0.21,0.13-0.42,0.19-0.63
				c5.89-1.25,11.74-2.65,17.58-4.08c5.18-1.26,10.35-2.55,15.54-3.78v0.57c-4.09,0.96-8.17,1.97-12.25,2.98
				c-6.21,1.54-12.42,3.07-18.67,4.42C215.06,114.35,214.27,114.52,213.47,114.69 M142.76,112.27c-1.28-0.53-2.54-1.1-3.78-1.69
				c-0.15-0.25-0.27-0.51-0.39-0.78c1.55,0.75,3.12,1.46,4.71,2.11c0.02,0.01,0.04,0.02,0.06,0.03
				C143.16,112.04,142.96,112.15,142.76,112.27 M114.49,95.4c-1.21-0.72-2.43-1.41-3.66-2.08c-0.82-0.44-1.64-0.88-2.47-1.29
				c0.05-0.16,0.09-0.33,0.13-0.49c2.08,1.05,4.13,2.18,6.13,3.37C114.58,95.07,114.53,95.24,114.49,95.4" />
                    <path style="clip-path:url(#SVGID_00000132807091778460277080000015708242561765607073_);fill:#BD1544;" d="M178.66,119.01
				c-0.1,0-0.21,0-0.31,0c-3.71-0.08-7.42-0.22-11.12-0.6c-6.47-0.67-12.8-1.99-18.97-4.06c-1.87-0.63-3.71-1.32-5.51-2.08
				c0.2-0.11,0.4-0.22,0.6-0.34c6.26,2.56,12.74,4.34,19.44,5.32c3.59,0.53,7.2,0.85,10.83,1.03c1.6,0.08,3.2,0.12,4.8,0.12
				c1.08,0,2.15-0.02,3.23-0.05c3.63-0.12,7.25-0.39,10.86-0.79c-0.29,0.23-0.59,0.45-0.88,0.68c-1.29,0.14-2.58,0.26-3.87,0.36
				c-1.96,0.16-3.94,0.28-5.91,0.29C180.78,118.9,179.72,119.01,178.66,119.01 M208.87,115.62c0.26-0.24,0.52-0.49,0.78-0.73
				c0.45-0.09,0.89-0.18,1.34-0.27c0.89-0.18,1.78-0.37,2.67-0.55c-0.06,0.21-0.13,0.42-0.19,0.63
				C211.94,115.01,210.41,115.32,208.87,115.62 M138.97,110.57c-2.99-1.43-5.9-3.04-8.73-4.81c-3.49-2.19-6.84-4.6-10.27-6.89
				c-1.8-1.2-3.63-2.36-5.49-3.47c0.05-0.17,0.09-0.33,0.13-0.5c0.8,0.48,1.6,0.97,2.39,1.47c3.57,2.26,7.01,4.72,10.54,7.06
				c3.55,2.36,7.22,4.5,11.03,6.35C138.7,110.06,138.83,110.32,138.97,110.57" />
                    <path style="clip-path:url(#SVGID_00000132807091778460277080000015708242561765607073_);fill:#CC1249;" d="M-4.25,93.63V93.1
				c1.34-0.31,2.68-0.62,4.01-0.94c10.25-2.43,20.48-4.96,30.82-6.99c0.02,0.18,0.05,0.35,0.08,0.53
				c-3.85,0.74-7.68,1.55-11.49,2.44C11.36,89.96,3.55,91.8-4.25,93.63 M99.47,88.13c-1.25-0.47-2.51-0.91-3.79-1.33
				c-1.32-0.43-2.66-0.83-3.99-1.2c0.07-0.16,0.14-0.33,0.21-0.49c2.65,0.74,5.26,1.59,7.83,2.57
				C99.64,87.83,99.55,87.98,99.47,88.13 M35.93,84.74c-0.15-0.17-0.29-0.33-0.42-0.48c3.73-0.66,7.48-1.23,11.25-1.68
				c0.52-0.06,1.04-0.12,1.56-0.18c0.09,0.18,0.18,0.36,0.27,0.54C44.34,83.39,40.13,84.01,35.93,84.74" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M30.66,85.7
			c-0.03-0.18-0.05-0.35-0.08-0.53c0.21-0.04,0.42-0.08,0.64-0.12c1.43-0.28,2.85-0.54,4.29-0.79c0.13,0.15,0.27,0.31,0.42,0.48
			c-0.72,0.12-1.44,0.25-2.16,0.38C32.73,85.31,31.7,85.5,30.66,85.7 M91.69,85.59c-4.13-1.14-8.32-1.98-12.58-2.56
			c-2.67-0.36-5.35-0.59-8.04-0.77c-2.37-0.15-4.75-0.26-7.12-0.26c-0.85,0-1.7,0.01-2.56,0.04c-3.22,0.11-6.44,0.3-9.65,0.58
			c-1.06,0.09-2.11,0.19-3.16,0.31c-0.09-0.18-0.18-0.36-0.27-0.54c3.49-0.38,6.98-0.65,10.49-0.79c1.87-0.07,3.74-0.11,5.61-0.11
			c1.21,0,2.42,0.01,3.62,0.04c3.35,0.07,6.69,0.35,10.02,0.8c3.46,0.46,6.89,1.05,10.28,1.85c1.19,0.28,2.38,0.59,3.55,0.91
			C91.83,85.27,91.76,85.43,91.69,85.59" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000167358812716975990110000001102353851433291701_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000021079801558547771150000000874127874931054494_">
                        <use xlink:href="#SVGID_00000167358812716975990110000001102353851433291701_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000021079801558547771150000000874127874931054494_);fill:#BD1544;" d="M108.35,92.02
				c-2.89-1.46-5.85-2.75-8.88-3.89c0.08-0.15,0.16-0.3,0.25-0.46c0.94,0.35,1.87,0.72,2.79,1.11c2.03,0.85,4.02,1.76,5.97,2.75
				C108.44,91.7,108.4,91.86,108.35,92.02" />
                    <path style="clip-path:url(#SVGID_00000021079801558547771150000000874127874931054494_);fill:#EFD1D9;" d="M246.79,13.98v-0.6
				c1.75-0.33,3.5-0.64,5.25-0.95c4.31-0.75,8.64-1.39,12.98-1.91c3.56-0.42,7.13-0.73,10.71-0.95c3.74-0.24,7.47-0.22,11.21-0.23
				v0.51c-0.73,0-1.47,0-2.2,0c-0.24,0-0.49,0-0.73,0c-0.05,0-0.09,0-0.14,0c-2.71,0-5.43,0.11-8.13,0.28
				c-4.47,0.28-8.92,0.74-13.36,1.33c-4.57,0.61-9.11,1.35-13.65,2.17C248.08,13.74,247.43,13.86,246.79,13.98 M-44.41,9.84
				c0-0.17,0-0.34,0-0.51c0.52,0.02,1.05,0.02,1.57,0.02c2.07,0,4.14-0.12,6.2-0.18c2.75-0.09,5.5-0.34,8.25-0.58
				c5.1-0.45,10.17-1.07,15.23-1.84c2.97-0.45,5.95-0.93,8.91-1.42v0.55c-4.95,0.84-9.92,1.61-14.9,2.28
				c-3.55,0.48-7.12,0.89-10.69,1.15c-2.41,0.18-4.82,0.33-7.24,0.44C-39.53,9.87-41.97,9.79-44.41,9.84" />
                    <path style="clip-path:url(#SVGID_00000021079801558547771150000000874127874931054494_);fill:#CC1249;" d="M183.55,23.26
				c-1.21,0-2.43-0.01-3.64-0.04c-3.27-0.06-6.52-0.34-9.77-0.71c-1.05-0.12-2.11-0.26-3.16-0.41c-0.13-0.23-0.27-0.46-0.4-0.69
				c1.14,0.17,2.29,0.32,3.44,0.45c4.12,0.48,8.24,0.76,12.38,0.77c0.09,0,0.18,0,0.27,0c3.17,0,6.35-0.03,9.52-0.26
				c3.69-0.26,7.36-0.59,11.03-1.06c4.59-0.59,9.17-1.29,13.72-2.15c4.86-0.92,9.72-1.84,14.58-2.8c5.09-1.01,10.17-2.04,15.27-2.99
				v0.6c-4.79,0.88-9.56,1.83-14.32,2.81c-4.56,0.93-9.13,1.76-13.7,2.65c-4.75,0.93-9.54,1.67-14.34,2.34
				c-2.9,0.4-5.81,0.7-8.73,0.96C191.65,23.12,187.6,23.26,183.55,23.26 M160.35,20.94c-0.55-0.11-1.09-0.22-1.64-0.34
				c-4.04-0.85-8.01-1.96-11.95-3.18c-6.56-2.03-13-4.42-19.45-6.76c-0.16-0.15-0.33-0.27-0.49-0.37c0.01,0.07,0.03,0.14,0.04,0.21
				c-1.53-0.55-3.06-1.11-4.59-1.65c-6.31-2.23-12.67-4.31-19.15-6.04c-6.44-1.71-12.95-3.02-19.55-3.9
				c-3.26-0.44-6.54-0.71-9.82-0.96c-3.64-0.28-7.28-0.38-10.92-0.38c-1.6,0-3.2,0.02-4.81,0.05C54.52-2.3,51-2.08,47.48-1.8
				c-4.32,0.34-8.62,0.82-12.92,1.35c-3.05,0.38-6.09,0.88-9.14,1.32C20.65,1.55,15.9,2.38,11.15,3.2C6.63,3.99,2.11,4.77-2.41,5.56
				c-0.61,0.11-1.23,0.21-1.85,0.32V5.33C0.42,4.55,5.1,3.73,9.77,2.91c6.29-1.11,12.6-2.19,18.92-3.09
				c4.75-0.68,9.51-1.29,14.29-1.73c4.48-0.41,8.96-0.72,13.46-0.9c2.19-0.09,4.38-0.13,6.57-0.13c1.59,0,3.18,0.02,4.77,0.07
				c4.73,0.13,9.44,0.5,14.15,1.07c7.38,0.89,14.63,2.35,21.81,4.25c6.6,1.75,13.07,3.9,19.49,6.2c4.76,1.71,9.52,3.41,14.28,5.11
				c6.41,2.28,12.89,4.33,19.53,5.86c1.1,0.25,2.19,0.49,3.29,0.71C160.34,20.53,160.35,20.73,160.35,20.94" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M127.32,10.66
			c-0.15-0.05-0.3-0.11-0.45-0.16c-0.01-0.07-0.03-0.14-0.04-0.21C126.99,10.39,127.15,10.52,127.32,10.66" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000088825600724347141230000000407474144622212498_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000044864520893472188920000005646793352073173937_">
                        <use xlink:href="#SVGID_00000088825600724347141230000000407474144622212498_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#BD1544;" d="M166.99,22.1
				c-2.22-0.32-4.43-0.72-6.63-1.16c-0.01-0.21-0.01-0.41-0.02-0.62c2.07,0.42,4.16,0.78,6.25,1.09
				C166.72,21.64,166.85,21.87,166.99,22.1" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#EFD1D9;" d="M246.79,126.6v-0.59
				c7.12-1.67,14.27-3.21,21.49-4.39c3.79-0.62,7.6-1.15,11.43-1.48c2.41-0.2,4.81-0.48,7.23-0.49v0.51
				c-2.62,0.2-5.25,0.34-7.86,0.6c-4.38,0.44-8.72,1.06-13.06,1.81c-5.55,0.96-11.06,2.11-16.55,3.39
				C248.57,126.18,247.68,126.39,246.79,126.6 M-44.41,119.91c0-0.17,0-0.34,0-0.51c0.89-0.25,1.82-0.16,2.72-0.23
				c4.92-0.36,9.79-0.99,14.66-1.75c7.33-1.15,14.57-2.76,21.79-4.45c0.33-0.08,0.66-0.16,0.99-0.23v0.56
				c-2.85,0.67-5.7,1.33-8.56,1.96c-6.64,1.46-13.32,2.71-20.07,3.6c-2.67,0.35-5.35,0.65-8.04,0.82
				C-42.09,119.75-43.25,119.88-44.41,119.91" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#CC1249;" d="M191.18,138
				c0.61-0.28,1.14-0.52,1.56-0.73c4.96-0.57,9.9-1.33,14.81-2.24c5.58-1.04,11.12-2.25,16.64-3.55c6.53-1.55,13.05-3.17,19.57-4.74
				c1.01-0.24,2.02-0.48,3.03-0.72v0.59c-4.42,1.04-8.84,2.1-13.25,3.17c-6.07,1.48-12.14,2.96-18.25,4.26
				c-6.05,1.28-12.12,2.43-18.25,3.26C195.09,137.55,193.14,137.79,191.18,138 M-4.25,113.3v-0.56c6.06-1.43,12.1-2.95,18.15-4.41
				c7.19-1.74,14.4-3.37,21.68-4.68c0.3-0.05,0.59-0.11,0.89-0.16c0.09,0.17,0.18,0.35,0.27,0.52c-1.62,0.28-3.23,0.58-4.84,0.89
				c-5.09,0.98-10.15,2.13-15.19,3.32C9.71,109.89,2.74,111.64-4.25,113.3 M102.79,109.34c-2.81-1.27-5.68-2.39-8.62-3.36
				c-1.56-0.51-3.12-0.98-4.7-1.4c-0.13-0.21-0.25-0.42-0.36-0.64c2.04,0.53,4.06,1.14,6.05,1.82c2.85,0.97,5.63,2.08,8.34,3.34
				C103.27,109.17,103.03,109.25,102.79,109.34" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#BD1544;" d="M176.06,138.69
				c-0.09,0-0.18,0-0.28,0c-5.58-0.03-11.1-0.56-16.59-1.52c-5.99-1.04-11.81-2.7-17.45-4.95c-5.1-2.04-9.96-4.57-14.59-7.52
				c-2.69-1.72-5.25-3.61-7.8-5.52c-3.52-2.64-7.22-5.02-11.09-7.12c-1.79-0.98-3.62-1.88-5.47-2.72c0.24-0.08,0.48-0.16,0.72-0.24
				c3.36,1.56,6.62,3.34,9.79,5.32c3.34,2.09,6.44,4.5,9.62,6.81c3.37,2.46,6.91,4.64,10.6,6.6c4.46,2.37,9.09,4.33,13.89,5.87
				c5.23,1.68,10.57,2.88,16.02,3.57c3.03,0.38,6.07,0.63,9.13,0.78c1.65,0.08,3.31,0.12,4.96,0.12c1.19,0,2.38-0.02,3.57-0.05
				c3.45-0.09,6.9-0.33,10.34-0.71c0.44-0.05,0.88-0.1,1.31-0.15c-0.42,0.21-0.95,0.46-1.56,0.73c-1.4,0.15-2.8,0.27-4.21,0.38
				c-1.87,0.14-3.75,0.29-5.63,0.29c-0.22,0-0.44,0-0.67-0.01c-0.3-0.01-0.61-0.01-0.92-0.01c-0.62,0-1.23,0.01-1.85,0.02
				C177.3,138.68,176.68,138.69,176.06,138.69 M89.47,104.58c-4.14-1.11-8.36-1.91-12.64-2.41c-2.7-0.32-5.4-0.5-8.12-0.65
				c-1.61-0.09-3.21-0.12-4.82-0.12c-1.28,0-2.57,0.02-3.85,0.05c-2.94,0.07-5.89,0.3-8.82,0.57c-4.86,0.45-9.69,1.15-14.49,1.99
				c-0.09-0.17-0.18-0.35-0.27-0.52c3.72-0.66,7.45-1.25,11.2-1.66c2.57-0.28,5.14-0.56,7.73-0.69c2.01-0.1,4.03-0.12,6.04-0.27
				c0.82-0.06,1.63-0.08,2.45-0.08c1.67,0,3.33,0.1,4.99,0.16c4.84,0.19,9.64,0.75,14.4,1.67c1.96,0.38,3.9,0.82,5.83,1.32
				C89.22,104.15,89.34,104.37,89.47,104.58" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#EFD1D9;" d="M246.79,123.76v-0.56
				c0.15-0.04,0.3-0.07,0.44-0.1c6.93-1.63,13.88-3.14,20.92-4.27c3.77-0.61,7.55-1.16,11.36-1.48c2.4-0.2,4.79-0.48,7.2-0.48
				c0.07,0,0.15,0,0.22,0v0.51c-2.69,0.12-5.37,0.33-8.06,0.6c-3.95,0.38-7.88,0.95-11.8,1.6c-3.4,0.56-6.78,1.2-10.14,1.94
				C253.55,122.23,250.16,122.97,246.79,123.76 M-44.41,117.1c0-0.21,0-0.43,0-0.64c3.02-0.06,6.01-0.38,9-0.71
				c4.84-0.54,9.63-1.33,14.42-2.22c3.66-0.68,7.3-1.44,10.92-2.28c1.94-0.45,3.88-0.9,5.82-1.36v0.6
				c-6.16,1.44-12.34,2.82-18.56,3.95c-4.47,0.81-8.97,1.49-13.48,2C-38.99,116.74-41.71,116.89-44.41,117.1" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#CC1249;" d="M197.46,134.45
				c0.35-0.23,0.7-0.47,1.05-0.72c2.73-0.39,5.45-0.84,8.16-1.34c6.71-1.23,13.36-2.73,19.99-4.34c6.71-1.63,13.41-3.28,20.13-4.87
				v0.56c-0.28,0.07-0.57,0.13-0.85,0.2c-3.64,0.85-7.26,1.77-10.9,2.65c-4.23,1.02-8.47,2.02-12.71,3.03
				c-5.91,1.41-11.85,2.68-17.84,3.71C202.14,133.75,199.8,134.12,197.46,134.45 M-4.25,110.49v-0.6c3.71-0.87,7.41-1.76,11.11-2.65
				c4.89-1.19,9.78-2.39,14.7-3.5c4.53-1.03,9.08-1.99,13.65-2.82c0.08,0.18,0.16,0.35,0.24,0.53c-4.51,0.81-9.01,1.72-13.48,2.76
				c-4.01,0.94-8.03,1.86-12.04,2.82c-4.59,1.1-9.15,2.28-13.74,3.35C-3.96,110.42-4.11,110.45-4.25,110.49 M106.11,108.03
				c-4.91-2.46-10.02-4.4-15.3-5.91c-0.89-0.25-1.78-0.49-2.67-0.71c-0.05-0.19-0.1-0.39-0.14-0.59c2.72,0.66,5.41,1.46,8.06,2.39
				c3.64,1.27,7.15,2.79,10.57,4.52C106.45,107.83,106.28,107.93,106.11,108.03" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#BD1544;" d="M177.36,135.95
				c-1.69,0-3.37-0.05-5.05-0.13c-7.04-0.33-13.98-1.34-20.79-3.19c-5.99-1.63-11.74-3.87-17.24-6.73
				c-4.95-2.57-9.58-5.65-14.01-9.04c-4.1-3.15-8.47-5.89-13.07-8.27c-0.36-0.19-0.72-0.37-1.09-0.55c0.17-0.1,0.34-0.2,0.51-0.31
				c2.45,1.24,4.84,2.6,7.18,4.06c3.69,2.3,7.06,5.02,10.6,7.52c2.64,1.86,5.38,3.57,8.21,5.13c4.63,2.55,9.46,4.64,14.47,6.31
				c5.5,1.83,11.14,3.06,16.89,3.8c2.77,0.36,5.57,0.56,8.37,0.7c1.69,0.08,3.38,0.12,5.06,0.12c1.29,0,2.57-0.02,3.86-0.05
				c3.03-0.07,6.05-0.29,9.08-0.58c2.73-0.27,5.45-0.6,8.17-0.99c-0.35,0.24-0.69,0.48-1.05,0.72c-1.81,0.25-3.63,0.48-5.46,0.66
				c-3.99,0.4-7.99,0.64-12,0.79C179.12,135.94,178.24,135.95,177.36,135.95 M35.45,101.44c-0.08-0.17-0.16-0.35-0.24-0.53
				c2.85-0.52,5.71-0.98,8.58-1.38c3.32-0.46,6.65-0.84,10-1.08c2.84-0.21,5.68-0.37,8.53-0.37c0.23,0,0.46,0,0.7,0
				c0.2,0,0.39,0,0.59,0c0.5,0,1-0.01,1.51-0.01c0.5-0.01,1-0.01,1.5-0.01c0.69,0,1.38,0.01,2.06,0.04
				c3.6,0.18,7.19,0.47,10.77,1.01c2.89,0.43,5.75,0.99,8.57,1.68c0.04,0.2,0.09,0.39,0.14,0.59c-3.54-0.88-7.13-1.53-10.75-1.99
				c-2.82-0.36-5.65-0.56-8.5-0.7c-1.55-0.08-3.09-0.1-4.64-0.1c-1.07,0-2.13,0.01-3.2,0.03c-2.78,0.04-5.55,0.22-8.32,0.46
				c-4.76,0.41-9.5,1.01-14.21,1.81C37.5,101.08,36.47,101.26,35.45,101.44" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#CC1249;" d="M208.51,98.5
				c0.09-0.2,0.17-0.41,0.25-0.62c2.09-0.4,4.18-0.83,6.26-1.29c4.19-0.93,8.38-1.88,12.55-2.86c3.84-0.91,7.67-1.89,11.51-2.82
				c2.57-0.62,5.13-1.23,7.7-1.83v0.57c-2.38,0.56-4.76,1.13-7.14,1.71c-9.77,2.38-19.51,4.85-29.37,6.8
				C209.68,98.27,209.09,98.39,208.51,98.5 M147.89,96.4c-4.12-1.5-8.1-3.34-11.96-5.42c-0.19-0.1-0.38-0.2-0.57-0.31
				c-0.02-0.2-0.03-0.41-0.05-0.61c1.44,0.78,2.89,1.54,4.36,2.25c2.68,1.3,5.41,2.46,8.19,3.48
				C147.88,95.99,147.89,96.19,147.89,96.4 M115.8,79.04c-2.33-1.29-4.69-2.51-7.09-3.65c-0.05-0.21-0.11-0.42-0.16-0.63
				c1.2,0.55,2.38,1.13,3.56,1.74c1.22,0.63,2.43,1.28,3.63,1.95C115.75,78.65,115.78,78.84,115.8,79.04" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#BD1544;" d="M179.04,101.68
				c-0.48,0-0.96-0.01-1.45-0.02c-4.11-0.12-8.22-0.38-12.3-0.95c-5.79-0.81-11.43-2.18-16.93-4.14c-0.15-0.06-0.31-0.11-0.46-0.17
				c-0.01-0.2-0.01-0.4-0.03-0.61c2.54,0.93,5.11,1.74,7.74,2.42c3.75,0.98,7.55,1.67,11.39,2.14c2.46,0.3,4.94,0.46,7.42,0.58
				c1.55,0.08,3.1,0.1,4.65,0.1c0.98,0,1.95-0.01,2.93-0.02c3.14-0.04,6.27-0.29,9.4-0.58c4.23-0.39,8.43-1,12.62-1.7
				c1.59-0.27,3.17-0.55,4.75-0.85c-0.08,0.21-0.17,0.41-0.25,0.62c-4.31,0.82-8.64,1.54-13.01,2.05c-3.25,0.38-6.49,0.72-9.76,0.88
				C183.51,101.55,181.27,101.68,179.04,101.68 M135.36,90.67c-5.13-2.8-10.02-5.99-15.03-9c-1.5-0.9-3.01-1.78-4.54-2.63
				c-0.02-0.2-0.04-0.39-0.07-0.59c3.27,1.82,6.48,3.76,9.66,5.73c3.27,2.03,6.55,4.03,9.92,5.88
				C135.33,90.26,135.34,90.47,135.36,90.67" />
                    <path style="clip-path:url(#SVGID_00000044864520893472188920000005646793352073173937_);fill:#CC1249;" d="M-4.25,76.98v-0.56
				c5.12-1.16,10.23-2.35,15.35-3.5c4.23-0.95,8.45-1.9,12.69-2.79c3.7-0.78,7.42-1.47,11.14-2.11c3.09-0.54,6.19-1.01,9.3-1.41
				c0.01,0.18,0.02,0.37,0.02,0.55c-5.73,0.74-11.41,1.72-17.08,2.84c-7.11,1.4-14.17,3.04-21.23,4.65
				C2.54,75.42-0.85,76.21-4.25,76.98 M105.97,74.16c-1.16-0.5-2.33-0.99-3.51-1.45c-1.8-0.7-3.61-1.35-5.44-1.94
				c0.04-0.17,0.07-0.34,0.11-0.5c1.59,0.52,3.16,1.08,4.72,1.68c1.42,0.55,2.83,1.12,4.23,1.72
				C106.05,73.83,106.01,74,105.97,74.16 M61.1,65.82c-0.17-0.16-0.34-0.33-0.5-0.5c0.99-0.02,1.97-0.03,2.96-0.03
				c0.64,0,1.28,0,1.92,0.01c-0.03,0.17-0.07,0.34-0.12,0.49c-0.41,0-0.82-0.01-1.23-0.01C63.12,65.79,62.11,65.8,61.1,65.82" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M97.03,70.77
			c-3.42-1.11-6.9-2.03-10.43-2.76c-3.56-0.73-7.15-1.26-10.76-1.65c-3.48-0.37-6.98-0.54-10.47-0.57c0.06-0.15,0.1-0.32,0.12-0.49
			c1.04,0.01,2.07,0.02,3.11,0.04c3.26,0.05,6.52,0.37,9.77,0.77c3.14,0.39,6.26,0.92,9.35,1.6c3.19,0.7,6.33,1.55,9.43,2.56
			C97.1,70.44,97.06,70.6,97.03,70.77 M44.25,67.15c-0.01-0.18-0.02-0.37-0.02-0.55c0.62-0.08,1.24-0.15,1.85-0.23
			c2.44-0.29,4.9-0.48,7.35-0.7c2.39-0.21,4.78-0.31,7.16-0.36c0.16,0.17,0.33,0.34,0.5,0.5c-0.56,0.01-1.13,0.03-1.69,0.04
			C54.9,66,50.39,66.41,45.9,66.95C45.35,67.01,44.8,67.08,44.25,67.15" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000151510905232812961660000007549741974478791337_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000096772017807662963130000009710181291261579191_">
                        <use xlink:href="#SVGID_00000151510905232812961660000007549741974478791337_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000096772017807662963130000009710181291261579191_);fill:#BD1544;" d="M108.7,75.39
				c-0.9-0.43-1.81-0.84-2.73-1.24c0.04-0.16,0.07-0.33,0.11-0.49c0.82,0.36,1.64,0.72,2.46,1.1
				C108.6,74.98,108.65,75.19,108.7,75.39" />
                    <path style="clip-path:url(#SVGID_00000096772017807662963130000009710181291261579191_);fill:#EFD1D9;" d="M246.79,31.6v-0.61
				c3.95-0.8,7.92-1.54,11.9-2.19c4.36-0.72,8.73-1.33,13.12-1.76c3-0.29,6-0.49,9.01-0.65c2.04-0.11,4.08-0.05,6.11-0.1v0.51
				c-0.4-0.01-0.79-0.01-1.19-0.01c-2.6,0-5.2,0.12-7.79,0.29c-4.3,0.29-8.58,0.71-12.85,1.32c-4.6,0.66-9.19,1.42-13.76,2.29
				C249.83,31,248.31,31.3,246.79,31.6 M-44.41,26.81c0-0.17,0-0.34,0-0.51c3.43-0.15,6.87-0.22,10.3-0.48
				c5.21-0.4,10.39-1.03,15.55-1.79c4.79-0.71,9.55-1.52,14.31-2.4v0.56c-0.16,0.03-0.33,0.06-0.49,0.09
				c-2.92,0.54-5.85,1.03-8.78,1.51c-2.83,0.47-5.66,0.89-8.5,1.29c-2.75,0.39-5.51,0.71-8.28,0.98c-3.23,0.31-6.47,0.53-9.71,0.67
				C-41.49,26.78-42.95,26.76-44.41,26.81" />
                    <path style="clip-path:url(#SVGID_00000096772017807662963130000009710181291261579191_);fill:#CC1249;" d="M183.08,41.69
				c-0.43,0-0.87-0.01-1.3-0.02c-2.59-0.08-5.18-0.11-7.76-0.33c-0.58-0.05-1.17-0.1-1.75-0.16c-0.01-0.21-0.03-0.42-0.04-0.63
				c0.7,0.07,1.39,0.13,2.09,0.18c2.88,0.21,5.77,0.38,8.65,0.38c0.76,0,1.53-0.01,2.29-0.04c1.11-0.04,2.22-0.08,3.33-0.14
				c0,0.2-0.01,0.39-0.01,0.58C186.75,41.59,184.92,41.69,183.08,41.69 M192.51,41.31c-0.08-0.19-0.16-0.39-0.25-0.6
				c1.76-0.13,3.51-0.28,5.26-0.47c4.2-0.46,8.39-1.05,12.55-1.74c3.79-0.63,7.57-1.35,11.32-2.16c4.28-0.91,8.57-1.78,12.85-2.69
				c3.57-0.75,7.14-1.54,10.72-2.29c0.61-0.13,1.21-0.25,1.82-0.37v0.61c-3.23,0.65-6.45,1.32-9.67,2.01
				c-5.02,1.08-10.06,2.1-15.09,3.19c-3.76,0.81-7.54,1.51-11.32,2.18c-3.87,0.68-7.75,1.26-11.66,1.7
				C196.88,40.93,194.7,41.15,192.51,41.31 M154.07,37.73c-2.79-0.77-5.55-1.64-8.28-2.61c-2.12-0.75-4.23-1.54-6.33-2.36
				c0.04-0.18,0.07-0.36,0.1-0.54c3.19,1.24,6.4,2.41,9.66,3.45c1.71,0.55,3.43,1.06,5.15,1.54
				C154.27,37.39,154.17,37.56,154.07,37.73 M136.72,31.68c-1.43-0.58-2.86-1.16-4.29-1.76c-0.25-0.1-0.5-0.21-0.75-0.31
				c0.01-0.18,0.01-0.37,0.02-0.55c1.79,0.74,3.59,1.48,5.39,2.2C136.97,31.4,136.85,31.54,136.72,31.68 M113.99,22.5
				c-4.17-1.54-8.39-2.94-12.67-4.14c-5.86-1.64-11.79-2.95-17.82-3.8c-3.28-0.46-6.57-0.77-9.87-1.04
				c-3.51-0.28-7.03-0.39-10.55-0.39c-1.73,0-3.45,0.03-5.18,0.07c-3.69,0.09-7.37,0.34-11.05,0.69c-3.87,0.36-7.73,0.78-11.58,1.3
				c-3.45,0.47-6.89,1.03-10.33,1.59c-4.95,0.81-9.87,1.74-14.8,2.65c-4.81,0.89-9.6,1.85-14.41,2.74v-0.56
				c0.88-0.16,1.76-0.33,2.64-0.5c4.17-0.8,8.35-1.54,12.53-2.34c6.45-1.23,12.91-2.41,19.39-3.4c5.16-0.79,10.34-1.46,15.54-1.94
				c3.57-0.33,7.15-0.53,10.74-0.69c1.95-0.09,3.91-0.12,5.86-0.12c1.62,0,3.23,0.02,4.85,0.04c2.67,0.04,5.34,0.24,8,0.46
				c5.47,0.43,10.88,1.24,16.26,2.32c4.96,1,9.84,2.32,14.67,3.85c2.57,0.82,5.12,1.7,7.65,2.63c0.03,0.13,0.06,0.26,0.09,0.4
				C113.97,22.39,113.98,22.44,113.99,22.5" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M139.46,32.76
			c-0.91-0.36-1.83-0.72-2.74-1.08c0.12-0.14,0.25-0.28,0.37-0.43c0.82,0.33,1.65,0.65,2.47,0.98
			C139.53,32.4,139.49,32.58,139.46,32.76 M131.68,29.6c-3.83-1.6-7.67-3.16-11.52-4.71c-2.05-0.83-4.1-1.63-6.17-2.39
			c-0.01-0.06-0.02-0.11-0.03-0.17c-0.03-0.13-0.06-0.27-0.09-0.4c5.65,2.07,11.2,4.37,16.76,6.68c0.36,0.15,0.71,0.29,1.07,0.44
			C131.7,29.23,131.69,29.42,131.68,29.6" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000075141843655084479600000008788351175799124610_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000062175828288406284080000016914845168736068994_">
                        <use xlink:href="#SVGID_00000075141843655084479600000008788351175799124610_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#BD1544;" d="M188.59,41.51
				c0.01-0.19,0.01-0.39,0.01-0.58c1.22-0.06,2.44-0.13,3.66-0.22c0.09,0.2,0.17,0.4,0.25,0.6c-1.09,0.08-2.19,0.14-3.29,0.18
				C189.01,41.5,188.8,41.5,188.59,41.51 M172.28,41.17c-4.64-0.47-9.23-1.22-13.78-2.3c-1.48-0.35-2.96-0.74-4.43-1.14
				c0.1-0.17,0.2-0.34,0.3-0.51c3.33,0.92,6.69,1.7,10.1,2.29c2.58,0.44,5.16,0.79,7.76,1.04
				C172.25,40.76,172.27,40.96,172.28,41.17" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#EFD1D9;" d="M246.79,69.45v-0.56
				c1.58-0.36,3.16-0.71,4.74-1.05c5.9-1.27,11.82-2.42,17.8-3.26c3.63-0.51,7.28-0.94,10.94-1.2c2.22-0.16,4.44-0.28,6.67-0.36
				v0.51c-0.37,0.11-0.75,0.13-1.13,0.13c-3.06,0.02-6.1,0.32-9.14,0.61c-6.37,0.61-12.68,1.65-18.95,2.87
				C254.05,67.85,250.41,68.63,246.79,69.45 M-43.6,63.42c-0.27,0-0.54,0-0.81-0.01c0-0.17,0-0.34,0-0.51
				c3.08-0.07,6.14-0.34,9.2-0.61c4.32-0.39,8.6-1.01,12.87-1.7c4.38-0.71,8.74-1.53,13.09-2.42c1.67-0.34,3.33-0.69,4.99-1.05v0.55
				c-0.06,0.01-0.12,0.03-0.18,0.04c-6.96,1.47-13.94,2.86-20.98,3.92c-3.7,0.55-7.4,1.03-11.12,1.36
				C-38.88,63.2-41.24,63.42-43.6,63.42" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#CC1249;" d="M191.07,80.49
				c0.07-0.19,0.14-0.39,0.21-0.58c0.93-0.08,1.87-0.17,2.8-0.27c4.73-0.51,9.42-1.2,14.1-2.06c-0.1,0.21-0.19,0.42-0.29,0.63
				c-3.09,0.56-6.2,1.06-9.32,1.47C196.07,80.01,193.58,80.28,191.07,80.49 M210.83,77.66c-0.03-0.19-0.06-0.37-0.09-0.56
				c0.07-0.01,0.13-0.03,0.2-0.04c5.38-1.05,10.73-2.22,16.07-3.49c6.59-1.56,13.17-3.18,19.78-4.67v0.56
				c-0.91,0.21-1.82,0.42-2.72,0.63c-5.38,1.26-10.76,2.53-16.13,3.82c-4.3,1.03-8.6,2.01-12.93,2.91
				C213.61,77.1,212.22,77.39,210.83,77.66 M145.97,74.49c-1.5-0.6-2.99-1.24-4.47-1.9c-3.01-1.35-5.95-2.83-8.85-4.38
				c0,0,0,0-0.01,0c-0.04-0.22-0.09-0.43-0.14-0.65c1.57,0.83,3.14,1.64,4.73,2.43c2.86,1.42,5.75,2.74,8.71,3.92
				C145.95,74.1,145.96,74.29,145.97,74.49 M118.2,60.52c-0.74-0.38-1.49-0.75-2.24-1.12c-0.12-0.13-0.23-0.27-0.35-0.42
				c-0.12-0.16-0.24-0.32-0.37-0.48c1.18,0.56,2.34,1.14,3.51,1.74C118.59,60.37,118.41,60.47,118.2,60.52 M-4.25,57.67v-0.55
				c5.3-1.13,10.58-2.32,15.88-3.44c4.53-0.96,9.06-1.9,13.61-2.8c5.19-1.02,10.41-1.9,15.64-2.61c2.22-0.3,4.45-0.56,6.69-0.78
				c-0.07,0.19-0.14,0.38-0.21,0.57c-1.72,0.17-3.43,0.36-5.14,0.57c-3.35,0.41-6.69,0.95-10.01,1.51
				c-7.54,1.28-15.02,2.88-22.5,4.48C5.05,55.62,0.4,56.69-4.25,57.67 M107.42,55.54c-2.16-0.89-4.34-1.72-6.55-2.46
				c-2.06-0.69-4.14-1.33-6.23-1.91c-0.09-0.2-0.18-0.4-0.27-0.6c4.13,1.12,8.18,2.49,12.16,4.08c0.15,0.06,0.3,0.12,0.45,0.18
				C107.12,55.06,107.27,55.3,107.42,55.54 M82.27,48.52c-0.51-0.08-1.01-0.15-1.52-0.22c-2.17-0.29-4.35-0.52-6.54-0.71
				c-0.06-0.18-0.13-0.37-0.19-0.55c0.89,0.08,1.77,0.17,2.66,0.26c1.85,0.19,3.69,0.42,5.52,0.7
				C82.22,48.17,82.25,48.34,82.27,48.52 M52.4,47.61c-0.01-0.17-0.02-0.34-0.02-0.52c3.12-0.22,6.24-0.35,9.36-0.42
				c0.63-0.01,1.27-0.02,1.9-0.02c0.17,0,0.34,0,0.5,0c-0.01,0.18-0.03,0.36-0.04,0.53c-3.67,0.01-7.33,0.08-11,0.37
				C52.87,47.57,52.64,47.59,52.4,47.61" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#BD1544;" d="M180.63,81
				c-0.28,0-0.57,0-0.85,0c-3.35-0.03-6.7-0.25-10.03-0.59c-6.07-0.62-12-1.89-17.8-3.76c-2.02-0.65-4.01-1.38-5.98-2.16
				c-0.01-0.19-0.02-0.39-0.02-0.58c1.81,0.72,3.65,1.4,5.51,2c5.45,1.77,11.01,3.05,16.7,3.72c2.64,0.31,5.28,0.5,7.93,0.64
				c1.57,0.08,3.14,0.12,4.7,0.12c1.11,0,2.22-0.02,3.32-0.05c2.39-0.07,4.78-0.22,7.16-0.43c-0.07,0.19-0.14,0.39-0.21,0.58
				c-0.16,0.01-0.32,0.03-0.48,0.04C187.27,80.8,183.96,81,180.63,81 M207.88,78.21c0.1-0.21,0.2-0.42,0.29-0.63
				c0.86-0.16,1.71-0.32,2.57-0.49c0.03,0.19,0.06,0.37,0.09,0.56C209.85,77.85,208.86,78.03,207.88,78.21 M132.64,68.2
				c-3.84-2.05-7.65-4.15-11.51-6.17c-0.97-0.51-1.95-1.02-2.93-1.51c0.2-0.05,0.39-0.15,0.55-0.29c0.55,0.28,1.1,0.56,1.64,0.85
				c4.06,2.12,8.06,4.34,12.11,6.48C132.55,67.77,132.59,67.99,132.64,68.2 M115.96,59.4c-1.73-0.85-3.48-1.67-5.25-2.45
				c-1.09-0.48-2.19-0.95-3.29-1.4c-0.15-0.24-0.29-0.47-0.45-0.71c2.8,1.13,5.55,2.36,8.27,3.67c0.12,0.16,0.24,0.32,0.37,0.48
				C115.73,59.12,115.85,59.27,115.96,59.4 M94.64,51.17c-2.08-0.57-4.18-1.09-6.3-1.53c-2.02-0.42-4.04-0.81-6.07-1.12
				c-0.02-0.17-0.05-0.35-0.08-0.52c3.46,0.53,6.88,1.22,10.28,2.08c0.63,0.16,1.26,0.32,1.89,0.49
				C94.46,50.77,94.55,50.96,94.64,51.17 M47.36,48.06c0.07-0.19,0.14-0.38,0.21-0.57c1.19-0.12,2.37-0.22,3.56-0.31
				c0.42-0.03,0.83-0.06,1.25-0.09c0,0.17,0.01,0.35,0.02,0.52C50.72,47.74,49.04,47.89,47.36,48.06 M74.21,47.59
				c-0.1-0.01-0.19-0.02-0.29-0.02c-3.1-0.26-6.2-0.39-9.3-0.39c-0.12,0-0.24,0-0.36,0c-0.05,0-0.09,0-0.14,0
				c0.01-0.18,0.02-0.36,0.04-0.53c1.6,0.01,3.19,0.05,4.79,0.08c1.7,0.04,3.39,0.16,5.08,0.31C74.08,47.23,74.14,47.41,74.21,47.59
				" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#EFD1D9;" d="M246.79,52.04v-0.55
				c3.99-0.87,7.99-1.69,12.01-2.42c4.43-0.8,8.88-1.5,13.35-2c2.97-0.33,5.95-0.62,8.94-0.77c1.85-0.1,3.7-0.23,5.55-0.23
				c0.1,0,0.2,0,0.3,0v0.51c-3.45,0.21-6.9,0.29-10.35,0.6c-5.52,0.5-11.01,1.26-16.46,2.22C255.66,50.19,251.21,51.07,246.79,52.04
				 M-44.05,46.58c-0.12,0-0.24,0-0.37,0c0-0.17,0-0.34,0-0.51c2.48-0.13,4.96-0.21,7.43-0.41c4.02-0.31,8.02-0.78,12.02-1.34
				c5.41-0.75,10.79-1.69,16.14-2.72c1.52-0.29,3.04-0.59,4.56-0.89v0.52c-2.69,0.54-5.38,1.08-8.07,1.57
				c-5.3,0.98-10.62,1.87-15.98,2.52c-3.22,0.39-6.45,0.73-9.69,0.96C-40.01,46.43-42.02,46.58-44.05,46.58" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#CC1249;" d="M180.26,63
				c-1.43-0.04-2.86-0.15-4.3-0.2c-1.77-0.06-3.53-0.24-5.29-0.43c0.03-0.19,0.07-0.38,0.1-0.57c2.43,0.26,4.88,0.44,7.33,0.5
				c0.88,0.02,1.76,0.05,2.64,0.07C180.58,62.58,180.42,62.79,180.26,63 M195.65,62.18c0.02-0.2,0.04-0.4,0.06-0.6
				c4.16-0.45,8.29-1.04,12.41-1.76c3.51-0.62,7.01-1.33,10.51-2.05c8.43-1.74,16.78-3.78,25.18-5.64c0.99-0.22,1.98-0.44,2.97-0.65
				v0.55c-0.62,0.14-1.23,0.27-1.84,0.41c-3.73,0.84-7.46,1.69-11.19,2.53c-2.77,0.63-5.55,1.27-8.32,1.9
				c-6.73,1.53-13.49,2.92-20.31,4.02C201.97,61.41,198.82,61.83,195.65,62.18 M146.92,56.67c-1-0.37-1.99-0.76-2.98-1.16
				c-3.88-1.56-7.68-3.32-11.46-5.11c-2.77-1.31-5.52-2.65-8.29-3.96c-0.18-0.27-0.37-0.54-0.55-0.8c2.73,1.29,5.45,2.6,8.17,3.9
				c5,2.38,10.03,4.68,15.22,6.6C146.99,56.32,146.96,56.49,146.92,56.67 M-4.25,41.23v-0.52c2.75-0.55,5.5-1.11,8.25-1.66
				c4.14-0.84,8.28-1.68,12.42-2.54c5.49-1.14,11.01-2.13,16.55-3.01c4.09-0.65,8.19-1.21,12.31-1.61
				c4.25-0.41,8.49-0.75,12.76-0.91c1.95-0.07,3.9-0.11,5.85-0.11c1.24,0,2.47,0.02,3.71,0.05c0.21,0.01,0.41,0.01,0.62,0.02
				c-0.08,0.18-0.16,0.35-0.24,0.53c-1.61-0.05-3.23-0.08-4.84-0.08c-1.06,0-2.13,0.01-3.19,0.03c-2.9,0.05-5.81,0.21-8.71,0.45
				c-2.83,0.23-5.67,0.47-8.49,0.8c-7.15,0.83-14.25,2.01-21.32,3.37c-6.11,1.17-12.2,2.46-18.3,3.69
				C0.65,40.23-1.8,40.73-4.25,41.23 M102.12,37.37c-2.47-0.8-4.96-1.53-7.48-2.17c-2.22-0.56-4.45-1.07-6.7-1.51
				c-0.02-0.17-0.04-0.35-0.06-0.52c4.96,0.97,9.85,2.24,14.66,3.82c0.07,0.02,0.13,0.04,0.19,0.06
				C102.53,37.15,102.33,37.26,102.12,37.37 M81.68,32.64c-2.26-0.31-4.52-0.56-6.8-0.76c-0.97-0.09-1.94-0.16-2.91-0.22
				c0.01-0.19,0.02-0.37,0.04-0.54c3.27,0.21,6.52,0.55,9.76,1.01C81.74,32.3,81.71,32.47,81.68,32.64" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#BD1544;" d="M181.03,63.01
				c-0.26,0-0.51,0-0.77-0.01c0.16-0.21,0.32-0.42,0.48-0.63c0.56,0.01,1.12,0.02,1.67,0.02c0.5,0,0.99-0.01,1.49-0.02
				c3.26-0.11,6.52-0.27,9.78-0.58c0.68-0.06,1.36-0.13,2.04-0.21c-0.02,0.2-0.04,0.4-0.06,0.6c-0.53,0.06-1.05,0.12-1.58,0.17
				c-2.02,0.21-4.06,0.29-6.09,0.44c-1.97,0.15-3.95,0.09-5.92,0.18C181.72,63,181.37,63.01,181.03,63.01 M170.67,62.37
				c-0.26-0.03-0.53-0.06-0.8-0.09c-3.09-0.33-6.14-0.87-9.17-1.52c-4.71-1-9.29-2.42-13.78-4.1c0.03-0.18,0.07-0.36,0.1-0.53
				c0.47,0.17,0.94,0.35,1.42,0.51c6.04,2.14,12.22,3.78,18.57,4.68c1.25,0.18,2.5,0.33,3.76,0.47
				C170.74,61.99,170.71,62.18,170.67,62.37 M124.19,46.44c-4.64-2.2-9.31-4.34-14.1-6.22c-2.63-1.03-5.29-1.99-7.97-2.86
				c0.2-0.11,0.41-0.22,0.62-0.32c5.25,1.73,10.37,3.79,15.41,6.05c1.84,0.83,3.67,1.68,5.49,2.54
				C123.82,45.9,124.01,46.17,124.19,46.44 M87.95,33.68c-1.59-0.31-3.18-0.59-4.78-0.83c-0.49-0.07-0.99-0.14-1.48-0.21
				c0.03-0.17,0.07-0.34,0.1-0.51c0.76,0.11,1.52,0.22,2.27,0.35c1.28,0.21,2.56,0.44,3.84,0.69
				C87.91,33.34,87.93,33.51,87.95,33.68 M71.97,31.66c-1.34-0.09-2.67-0.15-4.01-0.19c0.08-0.18,0.16-0.35,0.24-0.53
				c1.27,0.04,2.54,0.1,3.81,0.19C72,31.29,71.98,31.47,71.97,31.66" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#EFD1D9;" d="M246.79,10.99v-0.55
				c0.99-0.18,1.99-0.37,2.98-0.55c4.16-0.75,8.33-1.41,12.53-1.93c3.64-0.45,7.29-0.81,10.95-1.09c3.98-0.31,7.97-0.35,11.96-0.35
				c0.58,0,1.15,0,1.73,0v0.51c-0.02,0-0.04,0-0.05,0c-0.63,0-1.25,0-1.88-0.01c-0.62,0-1.24-0.01-1.87-0.01
				c-0.84,0-1.67,0.01-2.51,0.03c-2.97,0.08-5.93,0.23-8.89,0.47c-5.21,0.42-10.39,1.07-15.55,1.85
				C253.04,9.85,249.91,10.43,246.79,10.99 M-42.72,7.06c-0.57,0-1.13-0.01-1.7-0.02c0-0.17,0-0.34,0-0.51
				c0.58,0.02,1.16,0.03,1.74,0.03c2.12,0,4.24-0.1,6.35-0.19c3.64-0.15,7.28-0.42,10.91-0.81c3.86-0.42,7.72-0.89,11.56-1.43
				c3.21-0.45,6.4-0.96,9.6-1.48v0.48c-0.77,0.13-1.53,0.26-2.3,0.38c-3.12,0.51-6.24,1.03-9.37,1.45c-3.05,0.4-6.1,0.79-9.17,1.11
				c-2.79,0.29-5.58,0.48-8.37,0.72c-0.95,0.08-1.91,0.09-2.86,0.13C-38.45,6.97-40.58,7.06-42.72,7.06 M83.17-4.26H78.8
				c-0.73-0.07-1.47-0.14-2.2-0.2c-3-0.25-6.01-0.44-9.02-0.52c-1.83-0.05-3.67-0.11-5.5-0.11c-0.61,0-1.21,0.01-1.82,0.02
				c-3.16,0.09-6.32,0.19-9.47,0.39c-1.81,0.12-3.61,0.26-5.42,0.41h-4.99c2.68-0.27,5.36-0.52,8.04-0.71
				c3.81-0.28,7.63-0.45,11.45-0.52c1.1-0.02,2.19-0.03,3.29-0.03c1.92,0,3.83,0.03,5.75,0.09c2.88,0.09,5.76,0.31,8.63,0.56
				C79.41-4.7,81.29-4.49,83.17-4.26" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#CC1249;" d="M183.51,20.17
				c-1.16,0-2.31-0.02-3.47-0.06c-4.97-0.16-9.9-0.62-14.82-1.39c-0.16-0.03-0.32-0.05-0.48-0.08c-0.08-0.11-0.17-0.22-0.25-0.33
				c-0.08-0.11-0.16-0.21-0.24-0.33c2.75,0.45,5.51,0.8,8.29,1.08c3.72,0.37,7.45,0.5,11.17,0.5c1.49,0,2.99-0.02,4.48-0.06
				c5.14-0.12,10.26-0.61,15.35-1.27c4.93-0.64,9.84-1.4,14.73-2.31c6.14-1.15,12.26-2.38,18.4-3.56c3.37-0.65,6.74-1.29,10.11-1.92
				v0.55c-0.3,0.05-0.6,0.11-0.9,0.16c-2.92,0.53-5.85,1.08-8.76,1.66c-4.56,0.9-9.11,1.85-13.68,2.71
				c-5.19,0.98-10.4,1.93-15.63,2.7c-4.26,0.63-8.54,1.13-12.84,1.46c-2.35,0.18-4.7,0.31-7.05,0.4
				C186.45,20.14,184.98,20.17,183.51,20.17 M160.05,17.78c-2.61-0.53-5.2-1.15-7.78-1.83c-4.95-1.31-9.82-2.88-14.66-4.56
				c-3.65-1.27-7.3-2.55-10.94-3.83c-2.83-0.99-5.65-1.98-8.48-2.95c-2.43-0.83-4.89-1.59-7.34-2.34C107,1.1,103.13,0.04,99.22-0.87
				c-2.48-0.58-4.98-1.09-7.48-1.56c-2.34-0.44-4.69-0.81-7.04-1.14c-1.96-0.27-3.92-0.5-5.89-0.69h4.37
				c4.19,0.53,8.35,1.24,12.49,2.11c5.39,1.13,10.71,2.54,15.97,4.16c4.68,1.43,9.3,3.02,13.92,4.61c5.1,1.76,10.17,3.61,15.29,5.33
				c6.26,2.1,12.61,3.94,19.09,5.24c0.03,0.16,0.06,0.32,0.1,0.48C160.03,17.71,160.04,17.75,160.05,17.78 M-4.25,3.11V2.63
				C-1.5,2.17,1.26,1.7,4.02,1.24C10.66,0.12,17.28-1.1,23.94-2.1c4.91-0.74,9.83-1.46,14.77-1.98c0.55-0.06,1.11-0.12,1.66-0.17
				h4.99c-2.3,0.2-4.59,0.43-6.88,0.67c-3.82,0.41-7.63,0.93-11.43,1.5C23.57-1.57,20.09-1,16.62-0.42
				C12.26,0.31,7.92,1.11,3.56,1.8C0.95,2.22-1.65,2.67-4.25,3.11" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#BD1544;" d="M164.74,18.64
				c-1.57-0.26-3.14-0.54-4.7-0.86c-0.01-0.03-0.01-0.07-0.02-0.1c-0.03-0.16-0.06-0.32-0.1-0.48c0.17,0.03,0.34,0.07,0.52,0.1
				c1.27,0.25,2.53,0.47,3.8,0.68c0.08,0.11,0.16,0.22,0.24,0.33C164.58,18.42,164.66,18.53,164.74,18.64" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#EFD1D9;" d="M246.79,49.09v-0.54
				c1.1-0.24,2.2-0.47,3.3-0.7c5.91-1.22,11.84-2.32,17.82-3.14c2.86-0.39,5.72-0.74,8.6-0.96c3.19-0.25,6.37-0.5,9.57-0.5
				c0.29,0,0.57,0,0.85,0.01v0.51c-0.03,0-0.06,0-0.09,0c-3.28,0-6.55,0.23-9.83,0.49c-5.72,0.47-11.39,1.28-17.04,2.24
				C255.56,47.26,251.17,48.16,246.79,49.09 M-44.41,43.77c0-0.21,0-0.43,0-0.64c0.14,0,0.28,0,0.43,0c3.55,0,7.09-0.3,10.63-0.62
				c5.84-0.52,11.63-1.39,17.41-2.38c3.91-0.67,7.81-1.41,11.7-2.17v0.52c-0.25,0.05-0.5,0.1-0.74,0.14
				c-3.88,0.75-7.75,1.49-11.65,2.14c-3.33,0.55-6.66,1.1-10.01,1.53c-2.61,0.33-5.22,0.64-7.85,0.86
				c-2.98,0.24-5.96,0.51-8.96,0.51C-43.78,43.66-44.1,43.73-44.41,43.77" />
                    <path style="clip-path:url(#SVGID_00000062175828288406284080000016914845168736068994_);fill:#CC1249;" d="M180.59,59.88
				c-0.11,0-0.22,0-0.32,0c-3.04-0.03-6.07-0.24-9.09-0.54c0.03-0.18,0.06-0.37,0.08-0.55c0.63,0.07,1.27,0.13,1.9,0.18
				c3.01,0.26,6.03,0.38,9.04,0.38c0.19,0,0.38,0,0.58,0c-0.1,0.17-0.21,0.34-0.31,0.51c-0.09,0-0.18,0-0.28,0.01
				C181.66,59.87,181.12,59.88,180.59,59.88 M195.88,59.1c0.01-0.18,0.01-0.37,0.02-0.55c6.58-0.69,13.09-1.8,19.58-3.08
				c5.02-0.99,10.02-2.13,15.01-3.26c5.43-1.22,10.85-2.49,16.29-3.66v0.54c-0.35,0.07-0.71,0.15-1.06,0.22
				c-3.32,0.7-6.63,1.5-9.94,2.24c-4.25,0.95-8.49,1.91-12.74,2.84c-3.13,0.68-6.27,1.36-9.41,1.98c-4.21,0.82-8.44,1.53-12.69,2.11
				C199.26,58.71,197.57,58.92,195.88,59.1 M147.54,53.81c-0.8-0.29-1.59-0.59-2.38-0.9c-3.61-1.41-7.16-2.96-10.69-4.58
				c0.06-0.14,0.13-0.29,0.19-0.43c4.27,1.95,8.58,3.82,13,5.42C147.62,53.48,147.58,53.65,147.54,53.81 M131.98,47.18
				c-1.2-0.56-2.4-1.12-3.6-1.69c-2.67-1.26-5.35-2.52-8.04-3.74c-0.32-0.32-0.66-0.63-1-0.93c3.88,1.73,7.72,3.55,11.57,5.35
				c0.25,0.12,0.5,0.23,0.74,0.35C131.76,46.74,131.87,46.96,131.98,47.18 M-4.25,38.48v-0.52c2.28-0.45,4.55-0.91,6.82-1.37
				c6.33-1.29,12.66-2.56,19-3.78c6.28-1.21,12.59-2.25,18.93-3.04c4.3-0.53,8.6-0.95,12.93-1.21c3.19-0.19,6.37-0.33,9.56-0.33
				c0.27,0,0.54,0,0.81,0c1.89,0.01,3.78,0.06,5.67,0.15c-0.08,0.16-0.17,0.31-0.25,0.47c-1.9-0.09-3.8-0.14-5.7-0.14
				c-0.62,0-1.23,0-1.85,0.02c-4.01,0.07-8.01,0.27-12.01,0.59c-5.59,0.44-11.15,1.14-16.69,2c-4.38,0.68-8.76,1.42-13.12,2.3
				c-5.08,1.03-10.17,2.02-15.25,3.04C1.66,37.26-1.29,37.89-4.25,38.48 M106.19,36.01c-2.72-0.96-5.47-1.84-8.26-2.63
				c-3.36-0.95-6.76-1.73-10.18-2.39c0-0.17-0.01-0.33-0.01-0.5c2.62,0.5,5.23,1.08,7.82,1.75c3.97,1.03,7.88,2.26,11.72,3.65
				c-0.17,0.01-0.33,0.02-0.48,0.04C106.6,35.96,106.4,35.98,106.19,36.01 M82.26,30.05c0,0-0.01,0-0.01,0
				c-3.02-0.46-6.07-0.73-9.12-0.96c-0.29-0.02-0.57-0.04-0.86-0.06c0.02-0.16,0.05-0.33,0.07-0.49c1.56,0.1,3.12,0.23,4.68,0.39
				c1.79,0.18,3.58,0.4,5.36,0.66C82.34,29.75,82.3,29.9,82.26,30.05" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M134.47,48.33
			c-0.83-0.38-1.66-0.77-2.49-1.15c-0.11-0.22-0.22-0.45-0.32-0.67c1,0.47,2,0.93,3.01,1.39C134.6,48.05,134.54,48.19,134.47,48.33" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000042697432581761999160000016708575428839396530_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000000192237228127419810000000787071803900407173_">
                        <use xlink:href="#SVGID_00000042697432581761999160000016708575428839396530_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#BD1544;" d="M182.47,59.85
				c0.11-0.17,0.21-0.34,0.31-0.51c0.82,0,1.65-0.02,2.47-0.04c2.95-0.08,5.89-0.31,8.83-0.58c0.61-0.06,1.22-0.12,1.82-0.18
				c0,0.18-0.01,0.37-0.02,0.55c-1.88,0.2-3.76,0.37-5.64,0.51c-1.7,0.13-3.4,0.24-5.11,0.24c-0.25,0-0.49,0-0.74-0.01
				c-0.2,0-0.4-0.01-0.61-0.01C183.35,59.83,182.91,59.84,182.47,59.85 M171.18,59.34c-0.84-0.08-1.69-0.18-2.53-0.28
				c-3.55-0.42-7.05-1.12-10.51-1.99c-3.59-0.9-7.12-2-10.6-3.27c0.04-0.16,0.08-0.33,0.12-0.49c1.02,0.37,2.05,0.72,3.08,1.06
				c4.27,1.4,8.6,2.55,13.01,3.35c2.49,0.45,4.99,0.8,7.5,1.06C171.23,58.97,171.2,59.16,171.18,59.34 M120.35,41.75
				c-3.21-1.45-6.44-2.85-9.74-4.11c-1.47-0.56-2.94-1.11-4.42-1.63c0.21-0.03,0.41-0.06,0.61-0.07c0.15-0.02,0.32-0.03,0.48-0.04
				c2.68,0.97,5.34,2.02,7.97,3.13c1.37,0.58,2.73,1.18,4.09,1.78C119.69,41.12,120.02,41.43,120.35,41.75 M87.76,30.99
				c-1.82-0.35-3.66-0.66-5.5-0.94c0.04-0.15,0.08-0.3,0.12-0.45c1.8,0.26,3.58,0.56,5.36,0.9C87.75,30.66,87.75,30.83,87.76,30.99
				 M72.27,29.03c-1.01-0.07-2.03-0.13-3.05-0.18c0.08-0.16,0.17-0.32,0.25-0.47c0.96,0.04,1.91,0.1,2.87,0.16
				C72.32,28.7,72.29,28.87,72.27,29.03" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#EFD1D9;" d="M246.79,140.58v-0.53
				c4.78-1.11,9.57-2.16,14.39-3.08c4.99-0.96,10-1.78,15.04-2.36c2.57-0.3,5.15-0.54,7.73-0.7c1-0.06,1.99-0.09,2.99-0.1v0.51
				c-3.29,0.03-6.56,0.39-9.83,0.72c-4.74,0.48-9.43,1.27-14.11,2.09c-2.17,0.38-4.33,0.89-6.49,1.3c-2.5,0.48-4.99,1.03-7.47,1.61
				C248.28,140.23,247.53,140.4,246.79,140.58 M-43.94,133.95c-0.16,0-0.32,0-0.48-0.01c0-0.17,0-0.34,0-0.51
				c3.06-0.03,6.1-0.39,9.13-0.72c5.54-0.6,11.02-1.55,16.48-2.62c3.88-0.76,7.74-1.59,11.59-2.52c0.99-0.24,1.97-0.47,2.96-0.71
				v0.55c-0.28,0.07-0.57,0.13-0.85,0.2c-4.9,1.15-9.81,2.28-14.76,3.22c-3.17,0.6-6.34,1.22-9.54,1.65
				c-3.09,0.41-6.18,0.86-9.29,1.1C-40.44,133.72-42.18,133.95-43.94,133.95" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#CC1249;" d="M194.58,151.41
				c0.36-0.21,0.71-0.44,1.06-0.66c2.97-0.37,5.93-0.82,8.89-1.32c4.36-0.73,8.69-1.61,13.01-2.54c4.82-1.03,9.62-2.17,14.41-3.31
				c4.94-1.18,9.88-2.38,14.83-3.53v0.53c-2.6,0.61-5.2,1.23-7.8,1.85c-2.56,0.61-5.12,1.22-7.69,1.82
				c-3.48,0.81-6.95,1.64-10.43,2.42c-2.9,0.65-5.81,1.28-8.72,1.86c-4.08,0.82-8.18,1.55-12.3,2.17
				C198.1,150.96,196.34,151.2,194.58,151.41 M-4.25,127.41v-0.55c5.42-1.3,10.84-2.61,16.25-3.94c6.25-1.53,12.51-3,18.82-4.27
				c0.04,0.17,0.08,0.34,0.12,0.5c-2.77,0.55-5.53,1.14-8.29,1.78c-4.57,1.06-9.14,2.12-13.7,3.26
				C4.55,125.29,0.15,126.37-4.25,127.41 M35.87,118.21c-0.14-0.15-0.29-0.3-0.43-0.45c3.31-0.61,6.63-1.14,9.97-1.58
				c0.14,0.16,0.29,0.32,0.44,0.47C42.51,117.08,39.18,117.61,35.87,118.21" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#BD1544;" d="M177.42,152.47
				c-2.8,0-5.6-0.08-8.4-0.26c-4.95-0.33-9.84-1.05-14.68-2.11c-4.09-0.9-8.1-2.12-12.02-3.59c-3.03-1.13-5.99-2.42-8.87-3.87
				c-3.44-1.72-6.78-3.64-9.97-5.78c-3.51-2.36-6.96-4.8-10.48-7.14c-3.33-2.21-6.81-4.17-10.43-5.88c-2.96-1.4-5.97-2.67-9.08-3.7
				c-5.09-1.69-10.29-2.94-15.61-3.66c-3.72-0.51-7.46-0.78-11.22-0.94c-1.12-0.05-2.23-0.07-3.34-0.07c-1.92,0-3.84,0.06-5.76,0.15
				c-3.92,0.18-7.82,0.54-11.71,1.04c-0.15-0.15-0.29-0.31-0.44-0.47c0.88-0.11,1.77-0.22,2.65-0.32c2.76-0.31,5.53-0.54,8.31-0.7
				c2.41-0.13,4.81-0.24,7.22-0.24c0.59,0,1.17,0.01,1.76,0.02c3.78,0.09,7.54,0.35,11.29,0.83c4.82,0.62,9.55,1.6,14.2,3
				c3.44,1.04,6.81,2.28,10.09,3.76c4.84,2.19,9.43,4.79,13.82,7.77c2.92,1.98,5.78,4.05,8.71,6c6.38,4.24,13.16,7.69,20.39,10.24
				c5.96,2.11,12.06,3.56,18.31,4.43c2.94,0.41,5.9,0.66,8.87,0.84c1.84,0.11,3.69,0.17,5.53,0.17c0.23,0,0.46,0,0.7,0
				c2.84-0.02,5.68-0.08,8.52-0.29c3.2-0.24,6.38-0.52,9.56-0.92c0.1-0.01,0.21-0.02,0.31-0.04c-0.35,0.23-0.7,0.45-1.06,0.66
				c-1.3,0.15-2.6,0.29-3.9,0.41c-3.85,0.35-7.7,0.6-11.57,0.64C178.55,152.47,177.98,152.47,177.42,152.47 M30.93,119.15
				c-0.04-0.16-0.08-0.33-0.12-0.5c0.73-0.15,1.47-0.29,2.21-0.43c0.81-0.16,1.61-0.31,2.42-0.46c0.14,0.15,0.28,0.3,0.43,0.45
				c-0.39,0.07-0.79,0.14-1.18,0.22C33.43,118.66,32.18,118.9,30.93,119.15" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#EFD1D9;" d="M246.79,72.37v-0.55
				c4.81-1.09,9.63-2.13,14.48-3.01c4.14-0.75,8.29-1.44,12.46-1.92c2.49-0.29,4.98-0.55,7.48-0.7c1.7-0.1,3.4-0.24,5.11-0.24
				c0.2,0,0.41,0,0.61,0.01v0.51c-3.27,0.01-6.52,0.3-9.78,0.61c-6.41,0.6-12.76,1.64-19.08,2.87
				C254.3,70.68,250.53,71.49,246.79,72.37 M-44.41,66.34c0-0.17,0-0.34,0-0.51c1.17-0.26,2.37-0.15,3.55-0.23
				c3.11-0.22,6.22-0.51,9.32-0.87c4.35-0.5,8.66-1.22,12.98-1.96c3.87-0.67,7.73-1.44,11.57-2.27c0.92-0.2,1.83-0.39,2.75-0.59
				v0.51c-2,0.43-4,0.85-6,1.26c-4.91,1-9.84,1.93-14.79,2.69c-3.63,0.56-7.27,1.03-10.93,1.37C-38.78,65.99-41.6,66.14-44.41,66.34
				" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#CC1249;" d="M189.94,83.59
				c0.07-0.2,0.15-0.41,0.22-0.61c0.12-0.01,0.25-0.02,0.37-0.03c4.6-0.36,9.16-0.95,13.71-1.71c0.75-0.13,1.51-0.25,2.26-0.39
				c-0.11,0.2-0.22,0.4-0.34,0.6c-2.02,0.35-4.05,0.68-6.08,0.97C196.72,82.91,193.34,83.3,189.94,83.59 M211.18,80.52
				c-0.01-0.17-0.03-0.34-0.05-0.51c2.65-0.51,5.3-1.07,7.94-1.67c4.53-1.04,9.06-2.11,13.58-3.18c3.87-0.91,7.72-1.86,11.59-2.76
				c0.85-0.19,1.69-0.39,2.54-0.58v0.55c-0.22,0.05-0.45,0.1-0.67,0.16c-5.33,1.26-10.67,2.53-16,3.79
				c-3.39,0.8-6.77,1.63-10.18,2.37C217.02,79.32,214.11,79.94,211.18,80.52 M146.14,77.6c-1.3-0.52-2.6-1.07-3.88-1.64
				c-3.06-1.36-6.04-2.87-8.98-4.44c-0.03-0.19-0.07-0.39-0.1-0.58c3.77,2,7.6,3.9,11.56,5.53c0.46,0.19,0.92,0.37,1.38,0.56
				C146.12,77.22,146.13,77.41,146.14,77.6 M-4.25,60.42v-0.51c2.39-0.51,4.78-1,7.16-1.52c2.53-0.55,5.05-1.16,7.58-1.72
				c2.18-0.48,4.37-0.88,6.55-1.37c2.59-0.59,5.2-1.08,7.8-1.6c3.83-0.76,7.68-1.49,11.54-2.06c3.4-0.5,6.8-0.97,10.21-1.34
				c-0.05,0.17-0.11,0.34-0.16,0.51c-6.28,0.67-12.51,1.66-18.71,2.82C21.45,54.8,15.22,56.15,9,57.52
				C4.58,58.49,0.17,59.47-4.25,60.42 M109.49,59.17c-1.52-0.67-3.05-1.3-4.6-1.91c-2.96-1.15-5.95-2.17-8.98-3.05
				c-0.07-0.18-0.14-0.37-0.21-0.55c2.1,0.61,4.19,1.3,6.26,2.04c2.44,0.87,4.84,1.83,7.21,2.85
				C109.27,58.76,109.38,58.97,109.49,59.17 M82.33,51.2c-2.3-0.36-4.62-0.64-6.94-0.85c-0.09-0.17-0.18-0.34-0.27-0.52
				c0.09,0.01,0.17,0.02,0.26,0.02c2.34,0.21,4.67,0.51,6.99,0.87C82.37,50.88,82.35,51.04,82.33,51.2 M52.69,50.23
				c-0.03-0.16-0.05-0.33-0.08-0.49c3.39-0.25,6.79-0.38,10.19-0.39c0.19,0,0.38,0,0.57,0c0.22,0,0.43,0,0.65,0c0,0.15,0,0.3,0,0.45
				c-0.25,0-0.5,0-0.75,0c-1.83,0-3.66,0.04-5.5,0.12C56.08,49.99,54.38,50.1,52.69,50.23" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#BD1544;" d="M180.53,83.99
				c-1.31,0-2.62-0.02-3.93-0.07c-6.81-0.25-13.53-1.17-20.13-2.91c-3.52-0.93-6.96-2.08-10.33-3.42c-0.01-0.19-0.03-0.38-0.04-0.57
				c3.99,1.57,8.07,2.86,12.23,3.89c4.22,1.05,8.5,1.67,12.81,2.09c1.75,0.17,3.51,0.27,5.27,0.32c1.32,0.03,2.64,0.11,3.95,0.11
				c0.29,0,0.59,0,0.88-0.01c2.97-0.09,5.94-0.22,8.91-0.44c-0.07,0.2-0.15,0.41-0.22,0.61c-0.31,0.03-0.62,0.05-0.93,0.07
				C186.2,83.88,183.36,83.99,180.53,83.99 M206.17,81.45c0.12-0.2,0.23-0.4,0.34-0.6c1.54-0.27,3.09-0.55,4.62-0.85
				c0.02,0.17,0.03,0.34,0.05,0.51C209.51,80.85,207.84,81.16,206.17,81.45 M133.27,71.52c-2.62-1.39-5.21-2.84-7.81-4.27
				c-5.23-2.89-10.51-5.67-15.97-8.07c-0.11-0.21-0.22-0.41-0.33-0.62c3.2,1.39,6.35,2.9,9.45,4.51c3.21,1.66,6.35,3.43,9.52,5.16
				c1.68,0.91,3.35,1.82,5.04,2.72C133.21,71.13,133.24,71.33,133.27,71.52 M95.91,54.21c-4.4-1.28-8.88-2.29-13.44-3
				c-0.05-0.01-0.09-0.01-0.14-0.02c0.02-0.16,0.03-0.32,0.04-0.48c3.16,0.5,6.29,1.13,9.39,1.89c1.32,0.32,2.62,0.67,3.93,1.05
				C95.77,53.85,95.84,54.03,95.91,54.21 M46.43,50.81c0.05-0.17,0.11-0.34,0.16-0.51c0.57-0.06,1.15-0.12,1.72-0.18
				c1.43-0.15,2.87-0.27,4.3-0.38c0.02,0.16,0.05,0.33,0.08,0.49c-1.88,0.15-3.76,0.32-5.64,0.52
				C46.84,50.77,46.63,50.79,46.43,50.81 M75.4,50.35c-1.63-0.15-3.26-0.27-4.89-0.35c-2.16-0.12-4.33-0.18-6.49-0.2
				c0-0.15,0-0.3,0-0.45c3.71,0.01,7.41,0.15,11.11,0.48C75.22,50.01,75.31,50.18,75.4,50.35" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#CC1249;" d="M186.22,98.48
				c-0.28-0.11-0.53-0.28-0.73-0.5c1.34-0.07,2.68-0.17,4.03-0.28c-0.49,0.24-0.97,0.46-1.47,0.67
				C187.44,98.4,186.83,98.44,186.22,98.48 M209.67,95.34c0.06-0.19,0.12-0.38,0.17-0.57c1.91-0.37,3.82-0.77,5.73-1.17
				c4.53-0.96,9.03-2.06,13.54-3.14c5.89-1.42,11.77-2.88,17.68-4.26v0.54c-2.63,0.62-5.25,1.26-7.88,1.87
				c-3.99,0.93-7.96,1.98-11.95,2.93c-3.84,0.91-7.71,1.74-11.56,2.61C213.5,94.56,211.59,94.96,209.67,95.34 M147.7,93.25
				c-2.73-1.02-5.41-2.18-8.04-3.45c-1.54-0.74-3.06-1.53-4.56-2.35c-0.01-0.18-0.03-0.36-0.04-0.55c2.01,1.09,4.04,2.13,6.12,3.09
				c2.13,0.99,4.29,1.89,6.48,2.71C147.67,92.89,147.68,93.07,147.7,93.25 M115.35,75.91c-1.52-0.82-3.06-1.62-4.61-2.38
				c-0.98-0.48-1.96-0.94-2.95-1.39c-0.06-0.19-0.12-0.38-0.18-0.57c0.06,0.03,0.12,0.05,0.17,0.08c2.54,1.14,5.02,2.39,7.46,3.71
				C115.29,75.55,115.32,75.73,115.35,75.91" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#BD1544;" d="M179.6,98.66
				c-0.13,0-0.26,0-0.4,0c-4.8-0.02-9.58-0.31-14.34-1.02c-4.46-0.67-8.86-1.64-13.15-3c-1.35-0.43-2.69-0.89-4.01-1.39
				c-0.01-0.18-0.03-0.37-0.05-0.55c5.57,2.07,11.31,3.56,17.25,4.4c3.09,0.44,6.19,0.71,9.31,0.89c1.65,0.1,3.3,0.13,4.94,0.13
				c1.37,0,2.74-0.03,4.1-0.06c0.74-0.02,1.49-0.05,2.23-0.09c0.2,0.22,0.45,0.39,0.73,0.5C184.02,98.6,181.81,98.66,179.6,98.66
				 M188.05,98.36c0.49-0.21,0.98-0.43,1.47-0.67c1.13-0.1,2.26-0.21,3.38-0.32c5.7-0.58,11.34-1.51,16.95-2.61
				c-0.06,0.19-0.12,0.38-0.17,0.57c-1.88,0.37-3.76,0.71-5.65,1.04c-3.81,0.66-7.63,1.19-11.48,1.59
				C191.05,98.12,189.55,98.26,188.05,98.36 M135.1,87.45c-4.3-2.35-8.45-4.94-12.64-7.47c-2.34-1.41-4.71-2.77-7.11-4.07
				c-0.03-0.18-0.07-0.36-0.1-0.55c4.52,2.45,8.92,5.13,13.32,7.8c2.13,1.29,4.29,2.55,6.48,3.74
				C135.07,87.08,135.08,87.26,135.1,87.45" />
                    <path style="clip-path:url(#SVGID_00000000192237228127419810000000787071803900407173_);fill:#CC1249;" d="M-4.25,74.17v-0.53
				c3.3-0.73,6.6-1.49,9.9-2.24c6.19-1.41,12.38-2.84,18.61-4.11c6.14-1.26,12.31-2.36,18.52-3.2c0.48-0.06,0.96-0.13,1.44-0.19
				c0,0.18-0.01,0.35-0.01,0.53c-2.83,0.35-5.65,0.74-8.46,1.2c-3.39,0.56-6.77,1.24-10.14,1.9c-3.96,0.77-7.9,1.63-11.84,2.53
				c-4.66,1.07-9.31,2.12-13.97,3.18C-1.55,73.56-2.9,73.87-4.25,74.17 M106.45,71.55c-2.93-1.28-5.91-2.42-8.95-3.41
				c0.02-0.15,0.04-0.3,0.06-0.45c3.03,0.99,6.02,2.13,8.95,3.4C106.49,71.24,106.47,71.39,106.45,71.55 M58.76,63.18
				c-0.13-0.16-0.26-0.32-0.38-0.48c1.82-0.07,3.64-0.1,5.46-0.1c0.47,0,0.94,0,1.41,0.01c0.03,0.15,0.06,0.3,0.09,0.45
				c-0.45-0.01-0.9-0.02-1.34-0.02C62.25,63.04,60.51,63.12,58.76,63.18" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M97.5,68.14
			c-0.47-0.15-0.95-0.3-1.42-0.45c-4.23-1.29-8.52-2.34-12.88-3.06c-2.97-0.49-5.96-0.88-8.97-1.11c-2.5-0.19-5-0.26-7.5-0.41
			c-0.46-0.03-0.92-0.05-1.38-0.06c-0.03-0.15-0.06-0.3-0.09-0.45c1.25,0.01,2.49,0.03,3.74,0.07c3.24,0.09,6.47,0.41,9.7,0.82
			c5.35,0.67,10.61,1.74,15.78,3.24c1.04,0.3,2.07,0.62,3.09,0.95C97.54,67.84,97.52,67.99,97.5,68.14 M44.2,64.43
			c0-0.18,0.01-0.35,0.01-0.53c3.01-0.38,6.04-0.66,9.07-0.9c1.7-0.14,3.4-0.24,5.11-0.3c0.13,0.16,0.25,0.32,0.38,0.48
			c-0.21,0.01-0.42,0.01-0.63,0.02c-3.45,0.11-6.9,0.41-10.34,0.8C46.6,64.14,45.4,64.28,44.2,64.43" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000047780581721937362920000000076910011957800839_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000067921306819836683690000017871339270367670458_">
                        <use xlink:href="#SVGID_00000047780581721937362920000000076910011957800839_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000067921306819836683690000017871339270367670458_);fill:#BD1544;" d="M107.8,72.14
				c-0.45-0.2-0.89-0.4-1.34-0.6c0.02-0.15,0.04-0.3,0.06-0.46c0.37,0.16,0.74,0.32,1.11,0.49
				C107.68,71.76,107.74,71.95,107.8,72.14" />
                    <path style="clip-path:url(#SVGID_00000067921306819836683690000017871339270367670458_);fill:#EFD1D9;" d="M246.79,34.51v-0.53
				c2.21-0.45,4.42-0.87,6.63-1.29c3.7-0.7,7.4-1.32,11.12-1.85c3.22-0.45,6.44-0.84,9.68-1.11c2.98-0.26,5.97-0.44,8.96-0.54
				c1.25-0.04,2.5-0.05,3.76-0.09v0.51c-2.74,0.09-5.48,0.1-8.21,0.28c-4.04,0.26-8.07,0.68-12.09,1.2
				C259.97,31.96,253.37,33.19,246.79,34.51 M-42.91,29.64c-0.5,0-1-0.01-1.5-0.02c0-0.17,0-0.34,0-0.51c0.19,0,0.37,0,0.56,0
				c3.38,0,6.75-0.24,10.12-0.5c5.02-0.39,10.01-1,14.98-1.76c4.85-0.74,9.68-1.56,14.49-2.47v0.5c-1.17,0.22-2.34,0.43-3.5,0.65
				c-4.24,0.78-8.49,1.48-12.76,2.1c-2.98,0.43-5.97,0.81-8.97,1.12c-2.83,0.3-5.66,0.54-8.49,0.71
				C-39.62,29.56-41.27,29.64-42.91,29.64" />
                    <path style="clip-path:url(#SVGID_00000067921306819836683690000017871339270367670458_);fill:#CC1249;" d="M182.6,44.74
				c-2.6,0-5.2-0.1-7.79-0.29c-0.81-0.06-1.61-0.13-2.42-0.21c0-0.2-0.01-0.39-0.01-0.59c1.04,0.1,2.08,0.18,3.12,0.26
				c2.4,0.17,4.81,0.24,7.21,0.24c0.95,0,1.9-0.01,2.85-0.03c0.95-0.02,1.91-0.05,2.86-0.1c-0.02,0.2-0.05,0.4-0.08,0.58
				c-1.8,0.08-3.6,0.12-5.4,0.13C182.83,44.74,182.72,44.74,182.6,44.74 M193.68,44.26c-0.07-0.18-0.13-0.37-0.21-0.55
				c0.07-0.01,0.14-0.01,0.22-0.02c3.1-0.23,6.17-0.61,9.24-1.03c4-0.55,7.98-1.21,11.94-1.98c2.54-0.49,5.09-0.98,7.62-1.5
				c2.99-0.62,5.98-1.29,8.97-1.93c2.74-0.59,5.48-1.17,8.22-1.76c2.24-0.48,4.48-0.98,6.73-1.43c0.12-0.03,0.25-0.05,0.37-0.08
				v0.53c-0.02,0-0.04,0.01-0.05,0.01c-3.98,0.79-7.93,1.73-11.9,2.56c-5.51,1.16-11,2.4-16.52,3.49
				c-5.42,1.07-10.85,2.04-16.33,2.78C199.23,43.71,196.46,44.01,193.68,44.26 M152.61,40.37c-4.54-1.34-9-2.95-13.39-4.74
				c-0.12-0.05-0.24-0.1-0.36-0.15c0.04-0.16,0.08-0.31,0.11-0.47c0.08,0.03,0.16,0.06,0.23,0.09c4.49,1.77,9.02,3.44,13.66,4.79
				C152.77,40.05,152.69,40.21,152.61,40.37 M134.32,33.62c-0.88-0.36-1.75-0.73-2.62-1.09c-0.02-0.04-0.04-0.08-0.06-0.13
				c0-0.13,0-0.26,0-0.39c1.05,0.44,2.11,0.89,3.17,1.33C134.65,33.43,134.48,33.53,134.32,33.62 M114.49,25.45
				c-2.93-1.11-5.88-2.16-8.87-3.11c-5.47-1.74-11.01-3.17-16.64-4.24c0.02-0.16,0.04-0.32,0.06-0.47c0.93,0.18,1.86,0.36,2.79,0.56
				c3.12,0.66,6.22,1.4,9.27,2.3c4.49,1.32,8.93,2.81,13.32,4.46C114.44,25.11,114.46,25.28,114.49,25.45 M-4.25,24.88v-0.5
				c0.11-0.02,0.22-0.04,0.33-0.06c3.34-0.64,6.68-1.24,10.02-1.87c4.09-0.77,8.17-1.6,12.27-2.33C22.9,19.3,27.44,18.5,32,17.83
				c3.24-0.48,6.48-0.92,9.72-1.3c2.55-0.3,5.11-0.52,7.67-0.71c3.71-0.28,7.42-0.43,11.13-0.52c0.85-0.02,1.7-0.03,2.54-0.03
				c2.25,0,4.49,0.07,6.73,0.16c2.48,0.1,4.96,0.3,7.43,0.53c3.85,0.36,7.67,0.9,11.47,1.61c-0.11,0.13-0.23,0.27-0.35,0.42
				c-1.68-0.31-3.36-0.59-5.05-0.84c-3.51-0.51-7.03-0.85-10.57-1.07c-2.01-0.13-4.03-0.16-6.04-0.26c-1.3-0.07-2.6-0.1-3.9-0.1
				c-0.8,0-1.6,0.01-2.4,0.04c-2.52,0.08-5.05,0.16-7.57,0.31c-2.46,0.15-4.91,0.33-7.36,0.57c-2.7,0.26-5.4,0.6-8.09,0.94
				c-7.74,0.97-15.41,2.37-23.07,3.78c-4.03,0.74-8.05,1.55-12.08,2.32C0.05,24.07-2.1,24.48-4.25,24.88" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M138.85,35.48
			c-1.51-0.62-3.02-1.24-4.53-1.87c0.17-0.09,0.33-0.19,0.49-0.29c1.38,0.57,2.77,1.14,4.16,1.69
			C138.93,35.17,138.89,35.33,138.85,35.48 M131.7,32.52c-3.73-1.56-7.46-3.14-11.19-4.69c-1.99-0.82-4-1.62-6.01-2.39
			c-0.02-0.17-0.05-0.34-0.08-0.51c0.49,0.18,0.97,0.37,1.46,0.56c4.04,1.57,8.03,3.24,12.03,4.92c1.25,0.52,2.49,1.05,3.74,1.58
			c0,0.13,0,0.26,0,0.39C131.66,32.44,131.68,32.48,131.7,32.52" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000065069516678167616430000016458847128806967942_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000125569358789832518130000010490190254526743942_">
                        <use xlink:href="#SVGID_00000065069516678167616430000016458847128806967942_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#BD1544;" d="M188.35,44.61
				c0.03-0.18,0.05-0.38,0.08-0.58c1.68-0.08,3.37-0.2,5.05-0.33c0.07,0.18,0.14,0.37,0.21,0.55c-0.19,0.02-0.37,0.03-0.56,0.05
				C191.53,44.44,189.94,44.54,188.35,44.61 M172.39,44.25c-2.55-0.25-5.1-0.6-7.63-1.02c-2.41-0.4-4.8-0.91-7.17-1.5
				c-1.67-0.41-3.34-0.87-4.99-1.36c0.08-0.16,0.17-0.32,0.25-0.48c0.52,0.15,1.05,0.3,1.58,0.44c4.25,1.15,8.54,2.11,12.89,2.73
				c1.68,0.24,3.36,0.43,5.05,0.59C172.38,43.86,172.39,44.05,172.39,44.25 M88.98,18.1c-0.21-0.04-0.42-0.08-0.63-0.12
				c0.12-0.15,0.23-0.29,0.35-0.42c0.12,0.02,0.23,0.04,0.35,0.07C89.02,17.78,89,17.94,88.98,18.1" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#EFD1D9;" d="M246.79,78.12V77.6
				c3.86-0.89,7.73-1.73,11.61-2.5c5.4-1.07,10.82-1.97,16.29-2.61c2.57-0.3,5.15-0.55,7.73-0.69c1.5-0.08,3.01-0.2,4.51-0.23v0.51
				c-4.27,0.12-8.51,0.48-12.75,0.99c-5.32,0.64-10.59,1.52-15.85,2.55C254.47,76.39,250.62,77.24,246.79,78.12 M-44.41,71.96
				c0-0.17,0-0.34,0-0.51c3.56-0.23,7.11-0.46,10.66-0.86c6.66-0.74,13.25-1.85,19.81-3.15c3.24-0.64,6.47-1.32,9.69-2.03v0.5
				c-3.37,0.73-6.75,1.44-10.13,2.11c-5.21,1.02-10.44,1.93-15.71,2.63c-3.05,0.41-6.11,0.76-9.18,0.94
				C-40.99,71.69-42.7,71.83-44.41,71.96" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#CC1249;" d="M187.35,89.64
				c0.08-0.18,0.16-0.36,0.25-0.54c2-0.13,4.01-0.33,6.01-0.53c2.81-0.29,5.6-0.66,8.39-1.1c-0.16,0.2-0.33,0.4-0.5,0.6
				c-0.08,0.01-0.16,0.02-0.24,0.04c-3.36,0.5-6.73,0.89-10.11,1.23C189.88,89.46,188.61,89.56,187.35,89.64 M211.3,86.33
				c0.01-0.18,0.02-0.35,0.03-0.53c0.17-0.03,0.35-0.07,0.52-0.1c5.92-1.18,11.79-2.56,17.66-3.97c5.76-1.38,11.51-2.8,17.28-4.12
				v0.52c-3.07,0.71-6.14,1.44-9.21,2.18c-8.24,1.98-16.47,4.03-24.78,5.72C212.29,86.13,211.8,86.23,211.3,86.33 M146.66,83.83
				c-3.73-1.46-7.37-3.15-10.92-5.03c-0.51-0.27-1.02-0.54-1.52-0.81c-0.02-0.19-0.04-0.37-0.07-0.56c1.74,0.94,3.5,1.85,5.27,2.71
				c2.36,1.15,4.76,2.2,7.19,3.14c0,0.03,0.01,0.05,0.01,0.08C146.63,83.52,146.65,83.68,146.66,83.83 M112.62,66.14
				c-4.2-2.04-8.5-3.86-12.95-5.37c-0.72-0.24-1.44-0.48-2.16-0.7c-0.03-0.17-0.06-0.34-0.09-0.51c3.84,1.21,7.6,2.63,11.28,4.27
				c1.24,0.55,2.47,1.12,3.69,1.71C112.47,65.74,112.54,65.94,112.62,66.14 M-4.25,65.91v-0.5c2.4-0.52,4.8-1.06,7.2-1.59
				c6.08-1.35,12.14-2.75,18.24-3.99c3.83-0.78,7.66-1.55,11.52-2.21c4.13-0.7,8.28-1.35,12.44-1.83c-0.03,0.16-0.06,0.32-0.09,0.48
				c-2.85,0.34-5.69,0.74-8.53,1.19c-4.01,0.63-8,1.36-11.98,2.16c-7.62,1.53-15.21,3.25-22.79,4.96
				C-0.24,65.03-2.25,65.47-4.25,65.91 M54.17,55.4c-0.06-0.14-0.11-0.28-0.16-0.42c0.82-0.06,1.65-0.11,2.47-0.15
				c2.57-0.14,5.15-0.25,7.73-0.25c0.02,0,0.05,0,0.07,0c0.01,0.17,0.03,0.34,0.04,0.52c-0.1,0-0.21,0-0.31,0
				c-1.54,0-3.08,0.02-4.62,0.06C57.64,55.19,55.9,55.28,54.17,55.4" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#BD1544;" d="M180.23,89.86
				c-1.45,0-2.91-0.03-4.36-0.07c-7.39-0.24-14.67-1.34-21.78-3.41c-2.52-0.73-5-1.58-7.43-2.54c-0.02-0.16-0.03-0.31-0.05-0.47
				c0-0.03,0-0.05-0.01-0.08c5.99,2.34,12.18,4.04,18.61,4.99c3.17,0.47,6.36,0.76,9.56,0.93c1.75,0.09,3.5,0.13,5.24,0.13
				c1.4,0,2.79-0.02,4.19-0.06c1.13-0.03,2.26-0.09,3.38-0.16c-0.08,0.18-0.16,0.36-0.25,0.54
				C184.98,89.79,182.61,89.86,180.23,89.86 M201.5,88.07c0.17-0.2,0.33-0.4,0.5-0.6c3.12-0.49,6.23-1.06,9.33-1.67
				c-0.01,0.18-0.02,0.35-0.03,0.53C208.04,86.97,204.78,87.57,201.5,88.07 M134.22,77.99c-3.77-2.04-7.47-4.22-11.19-6.34
				c-3.42-1.95-6.88-3.81-10.4-5.52c-0.08-0.2-0.15-0.4-0.23-0.6c4.21,2.04,8.32,4.28,12.38,6.61c3.11,1.78,6.22,3.58,9.38,5.28
				C134.17,77.62,134.19,77.8,134.22,77.99 M97.51,60.07c-5.35-1.68-10.79-2.94-16.34-3.75c-3.09-0.45-6.19-0.74-9.3-0.96
				c-2.52-0.18-5.04-0.24-7.55-0.25c-0.01-0.17-0.03-0.34-0.04-0.52c0.86,0,1.72,0.01,2.59,0.04c8.56,0.26,17.02,1.26,25.32,3.42
				c1.76,0.46,3.51,0.96,5.25,1.51C97.45,59.72,97.48,59.9,97.51,60.07 M45.05,56.27c0.03-0.16,0.06-0.32,0.09-0.48
				c0.6-0.07,1.2-0.13,1.81-0.2c2.35-0.24,4.69-0.45,7.05-0.61c0.05,0.14,0.11,0.28,0.16,0.42c-1.74,0.12-3.47,0.28-5.2,0.44
				C47.66,55.98,46.35,56.12,45.05,56.27" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#EFD1D9;" d="M246.79,132.19v-0.5
				c4.41-1.03,8.83-2.01,13.27-2.89c5.36-1.06,10.74-1.97,16.16-2.61c3.31-0.39,6.62-0.73,9.96-0.81c0.25-0.01,0.5-0.07,0.75-0.11
				v0.51c-8.6,0.48-17.11,1.61-25.56,3.26C256.49,129.99,251.63,131.06,246.79,132.19 M-44.41,125.52c0-0.17,0-0.34,0-0.51
				c3.02-0.09,6.01-0.41,9-0.74c6.4-0.7,12.74-1.84,19.04-3.13c4.05-0.84,8.09-1.75,12.11-2.71v0.5c-7.67,1.82-15.36,3.5-23.14,4.75
				c-3.92,0.63-7.85,1.17-11.81,1.48C-40.94,125.3-42.67,125.47-44.41,125.52" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#CC1249;" d="M183.03,144.12
				c-0.02-0.15-0.02-0.31,0-0.48c2.33-0.1,4.65-0.28,6.98-0.49c4.36-0.4,8.69-0.99,13-1.7c-0.58,0.26-1.19,0.52-1.81,0.78
				c-2.35,0.37-4.71,0.7-7.08,0.98c-2.53,0.3-5.06,0.54-7.6,0.7C185.35,143.99,184.19,144.06,183.03,144.12 M205.24,141.56
				c0.1-0.18,0.19-0.36,0.28-0.54c2.55-0.45,5.1-0.95,7.64-1.47c5.54-1.14,11.04-2.43,16.53-3.75c5.7-1.37,11.39-2.78,17.1-4.11v0.5
				c-0.78,0.18-1.57,0.37-2.35,0.55c-11.6,2.73-23.14,5.74-34.85,8.01C208.14,141.03,206.69,141.3,205.24,141.56 M-4.25,118.93v-0.5
				c2.26-0.54,4.52-1.09,6.77-1.64c10.36-2.54,20.7-5.16,31.19-7.13c1.92-0.36,3.85-0.71,5.79-1.02c0.1,0.15,0.2,0.3,0.3,0.45
				c-6.82,1.1-13.58,2.52-20.3,4.11c-7.11,1.68-14.21,3.45-21.31,5.16C-2.63,118.54-3.44,118.74-4.25,118.93" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#BD1544;" d="M177.32,144.28
				c-0.69,0-1.38-0.01-2.07-0.03c-3.48-0.09-6.95-0.29-10.41-0.7c-3.53-0.42-7.02-1-10.48-1.78c-4.32-0.98-8.53-2.28-12.66-3.91
				c-6.01-2.37-11.7-5.37-17.07-8.95c-3.47-2.31-6.72-4.92-10.19-7.23c-2.18-1.45-4.41-2.8-6.7-4.06
				c-4.88-2.68-9.98-4.83-15.29-6.48c-4.82-1.5-9.73-2.59-14.74-3.23c-2.88-0.37-5.77-0.63-8.68-0.78c-1.88-0.1-3.76-0.14-5.64-0.14
				c-1.46,0-2.91,0.03-4.37,0.08c-5.47,0.2-10.92,0.77-16.34,1.57c-0.97,0.14-1.93,0.29-2.9,0.45c-0.1-0.15-0.2-0.3-0.3-0.45
				c3.41-0.56,6.83-1.03,10.28-1.38c2.96-0.3,5.92-0.49,8.88-0.65c1.74-0.09,3.47-0.13,5.21-0.13c1.22,0,2.43,0.02,3.64,0.07
				c8.29,0.3,16.44,1.49,24.4,3.93c4.85,1.49,9.54,3.37,14.04,5.68c5,2.57,9.68,5.65,14.17,9.04c4.11,3.1,8.44,5.86,13.01,8.25
				c6.02,3.15,12.3,5.56,18.87,7.26c4.62,1.19,9.3,2.02,14.04,2.51c2.13,0.22,4.27,0.34,6.41,0.45c1.51,0.08,3.03,0.1,4.54,0.1
				c1.04,0,2.07-0.01,3.11-0.03c0.98-0.02,1.96-0.05,2.93-0.09c-0.02,0.17-0.02,0.33,0,0.48
				C181.12,144.22,179.22,144.28,177.32,144.28 M201.19,142.23c0.61-0.26,1.22-0.53,1.81-0.78c0.84-0.14,1.68-0.28,2.51-0.43
				c-0.08,0.18-0.18,0.36-0.28,0.54C203.89,141.79,202.54,142.02,201.19,142.23" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#EFD1D9;" d="M246.79,22.76v-0.51
				c3.2-0.62,6.4-1.2,9.61-1.73c4.04-0.67,8.1-1.26,12.17-1.71c2.7-0.3,5.41-0.5,8.11-0.69c2.7-0.19,5.4-0.23,8.1-0.23
				c0.72,0,1.43,0,2.15,0.01v0.38c-2.27,0.05-4.55,0.03-6.81,0.15c-3.24,0.17-6.48,0.37-9.71,0.71c-3.8,0.4-7.59,0.86-11.37,1.46
				c-3.71,0.59-7.42,1.22-11.11,1.95C247.55,22.6,247.17,22.68,246.79,22.76 M-42.69,18.27c-0.57,0-1.15,0-1.72-0.01
				c0-0.13,0-0.26,0-0.38c2.84-0.09,5.69-0.1,8.53-0.28c3.58-0.23,7.15-0.53,10.71-0.95c4.01-0.47,8-1,11.99-1.62
				c2.98-0.47,5.96-0.98,8.93-1.51v0.48c-2.45,0.43-4.9,0.85-7.36,1.25c-3.94,0.64-7.89,1.22-11.85,1.71
				c-2.74,0.34-5.48,0.6-8.23,0.84C-35.35,18.09-39.02,18.27-42.69,18.27" />
                    <path style="clip-path:url(#SVGID_00000125569358789832518130000010490190254526743942_);fill:#CC1249;" d="M183.48,32.45
				c-1.37,0-2.74-0.02-4.11-0.05c-2.84-0.07-5.68-0.28-8.5-0.6c-0.05-0.19-0.1-0.37-0.15-0.56c0.38,0.04,0.77,0.08,1.15,0.12
				c3.82,0.38,7.65,0.57,11.48,0.57c1.39,0,2.78-0.02,4.17-0.07c4.22-0.15,8.44-0.47,12.63-0.98c3.92-0.48,7.83-1.05,11.72-1.71
				c5.36-0.92,10.69-1.99,16.02-3.05c3.85-0.77,7.69-1.64,11.54-2.42c2.45-0.49,4.9-0.99,7.35-1.46v0.51
				c-5.03,0.99-10.06,2.03-15.08,3.09c-6.26,1.32-12.52,2.58-18.82,3.7c-3.68,0.65-7.38,1.22-11.08,1.69
				c-2.8,0.35-5.61,0.6-8.43,0.84C190.07,32.34,186.77,32.45,183.48,32.45 M158.47,29.67c-4.92-1.13-9.76-2.57-14.55-4.22
				c-4.24-1.46-8.42-3.06-12.6-4.69c-0.03-0.18-0.06-0.36-0.1-0.54c4.78,1.86,9.58,3.68,14.45,5.3c4.28,1.42,8.6,2.67,12.99,3.67
				C158.61,29.35,158.54,29.51,158.47,29.67 M126.91,19.04c-3.04-1.18-6.09-2.34-9.15-3.46c-0.16-0.22-0.32-0.44-0.49-0.66
				c3.26,1.17,6.5,2.41,9.74,3.66C126.97,18.73,126.94,18.88,126.91,19.04 M-4.25,13.98v-0.48c1.04-0.18,2.08-0.37,3.12-0.56
				c6.04-1.08,12.08-2.21,18.12-3.26c6.63-1.16,13.28-2.24,19.96-3.07c3.92-0.49,7.85-0.91,11.78-1.22
				c4.64-0.36,9.29-0.57,13.94-0.57c1.32,0,2.63,0.02,3.95,0.05c3.26,0.09,6.52,0.31,9.78,0.58c7.26,0.59,14.4,1.83,21.48,3.52
				c3.66,0.87,7.28,1.9,10.86,3.04c0.71,0.22,1.41,0.45,2.12,0.68c0.1,0.19,0.19,0.38,0.28,0.57c-0.88-0.29-1.75-0.58-2.63-0.85
				c-7.06-2.24-14.24-4.01-21.55-5.2c-3.75-0.61-7.53-1.03-11.31-1.35c-3.75-0.33-7.5-0.51-11.26-0.52c-0.53,0-1.05,0-1.58,0
				c-3.31,0-6.63,0.06-9.94,0.27c-3.05,0.19-6.1,0.4-9.14,0.69c-2.98,0.28-5.95,0.68-8.92,1.06c-6.24,0.79-12.44,1.86-18.63,2.94
				c-4.42,0.77-8.82,1.61-13.23,2.4C0.55,13.13-1.85,13.56-4.25,13.98" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M131.33,20.76
			c-1.11-0.43-2.22-0.87-3.33-1.3c-0.36-0.14-0.72-0.28-1.08-0.42c0.03-0.15,0.06-0.31,0.09-0.46c1.18,0.46,2.36,0.92,3.54,1.38
			c0.23,0.09,0.46,0.18,0.69,0.27C131.26,20.4,131.3,20.58,131.33,20.76 M117.76,15.58c-2.19-0.8-4.4-1.57-6.61-2.3
			c-0.09-0.19-0.18-0.38-0.28-0.57c2.14,0.71,4.28,1.45,6.4,2.21C117.43,15.14,117.6,15.36,117.76,15.58" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000070819587790821032760000009448353388062804880_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000034080261424635816810000005294871898695442324_">
                        <use xlink:href="#SVGID_00000070819587790821032760000009448353388062804880_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000034080261424635816810000005294871898695442324_);fill:#BD1544;" d="M170.86,31.8
				c-1.67-0.19-3.33-0.42-5-0.68c-2.49-0.4-4.95-0.88-7.4-1.44c0.07-0.16,0.14-0.32,0.2-0.48c0.31,0.07,0.62,0.14,0.94,0.21
				c3.67,0.81,7.38,1.42,11.11,1.84C170.77,31.42,170.82,31.61,170.86,31.8" />
                    <path style="clip-path:url(#SVGID_00000034080261424635816810000005294871898695442324_);fill:#CC1249;" d="M205.06,104.88
				c0.13-0.19,0.26-0.39,0.38-0.58c0.61-0.11,1.23-0.22,1.84-0.33c7.27-1.34,14.47-3.04,21.66-4.78c5.95-1.44,11.89-2.92,17.85-4.32
				v0.49c-0.7,0.17-1.41,0.33-2.11,0.5c-8.47,2.03-16.91,4.2-25.4,6.13C214.55,103.04,209.82,104.04,205.06,104.88 M147.67,102.28
				c-1.41-0.51-2.81-1.06-4.19-1.64c-2.56-1.08-5.05-2.3-7.49-3.61c-0.02-0.18-0.04-0.37-0.06-0.55c3.81,2.05,7.74,3.83,11.8,5.3
				C147.71,101.94,147.69,102.11,147.67,102.28 M116.04,84.85c-2.16-1.25-4.35-2.43-6.58-3.53c0-0.17-0.01-0.33-0.01-0.49
				c2.24,1.11,4.44,2.29,6.6,3.54C116.05,84.53,116.05,84.69,116.04,84.85" />
                    <path style="clip-path:url(#SVGID_00000034080261424635816810000005294871898695442324_);fill:#BD1544;" d="M178.88,107.45
				c-1.23,0-2.46-0.02-3.68-0.06c-5.97-0.19-11.89-0.9-17.72-2.24c-3.33-0.76-6.6-1.72-9.8-2.88c0.02-0.17,0.04-0.34,0.06-0.51
				c1.24,0.45,2.49,0.87,3.76,1.26c4.95,1.52,10,2.57,15.13,3.2c2.36,0.29,4.73,0.44,7.1,0.57c1.56,0.09,3.12,0.12,4.68,0.12
				c1.24,0,2.49-0.02,3.73-0.04c3.03-0.05,6.06-0.3,9.08-0.59c4.78-0.45,9.51-1.16,14.23-1.99c-0.12,0.2-0.25,0.39-0.38,0.58
				c-2.6,0.46-5.21,0.87-7.83,1.23c-3.03,0.41-6.07,0.7-9.11,0.96C185.04,107.32,181.96,107.45,178.88,107.45 M135.99,97.02
				c-1.02-0.55-2.03-1.12-3.03-1.7c-5.37-3.11-10.46-6.67-15.81-9.82c-0.37-0.22-0.74-0.43-1.11-0.65c0-0.16,0.01-0.32,0.01-0.48
				c2.09,1.2,4.14,2.46,6.18,3.75c4.18,2.65,8.31,5.38,12.65,7.78c0.35,0.19,0.7,0.38,1.04,0.57
				C135.95,96.65,135.97,96.83,135.99,97.02" />
                    <path style="clip-path:url(#SVGID_00000034080261424635816810000005294871898695442324_);fill:#CC1249;" d="M-4.25,82.5v-0.48
				c8.62-1.94,17.21-4.07,25.85-5.92c7.07-1.51,14.16-2.88,21.31-3.86c0.63-0.09,1.26-0.17,1.89-0.25c0.03,0.16,0.06,0.31,0.09,0.47
				c-2.73,0.35-5.45,0.75-8.17,1.19c-3.42,0.56-6.82,1.21-10.21,1.9c-4.16,0.85-8.31,1.77-12.45,2.72
				c-4.12,0.94-8.23,1.94-12.35,2.88C-0.28,81.6-2.27,82.05-4.25,82.5 M104.43,79.01c-1.83-0.78-3.68-1.5-5.56-2.17
				c-1.08-0.38-2.16-0.74-3.25-1.08c0.05-0.15,0.1-0.29,0.15-0.44c2.99,0.94,5.93,2.03,8.83,3.27
				C104.54,78.73,104.48,78.87,104.43,79.01" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M95.62,75.76
			c-4.25-1.33-8.57-2.36-12.96-3.1c-2.95-0.5-5.92-0.82-8.91-1.1c-3.17-0.29-6.34-0.39-9.51-0.39c-1.39,0-2.78,0.02-4.18,0.05
			c-3.45,0.08-6.91,0.34-10.35,0.68c-1.61,0.16-3.22,0.35-4.83,0.55c-0.03-0.16-0.06-0.31-0.09-0.47c2.69-0.35,5.4-0.64,8.11-0.82
			c2.33-0.16,4.66-0.28,6.99-0.4c1.36-0.07,2.73-0.1,4.09-0.1c0.76,0,1.52,0.01,2.28,0.03c2.76,0.06,5.51,0.22,8.26,0.46
			c3.71,0.32,7.38,0.84,11.03,1.56c3.46,0.68,6.86,1.55,10.21,2.61C95.72,75.46,95.67,75.61,95.62,75.76" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000130648880603084095760000007527792670757999546_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000141450262727556657140000001961984794989853850_">
                        <use xlink:href="#SVGID_00000130648880603084095760000007527792670757999546_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000141450262727556657140000001961984794989853850_);fill:#BD1544;" d="M109.46,81.33
				c-1.66-0.82-3.34-1.59-5.04-2.32c0.06-0.14,0.11-0.28,0.17-0.42c0.74,0.32,1.48,0.64,2.21,0.98c0.89,0.41,1.77,0.83,2.65,1.26
				C109.45,80.99,109.46,81.16,109.46,81.33" />
                    <path style="clip-path:url(#SVGID_00000141450262727556657140000001961984794989853850_);fill:#EFD1D9;" d="M246.79,118.07v-0.51
				c5.09-1.2,10.2-2.33,15.34-3.31c4.34-0.83,8.7-1.56,13.08-2.09c2.91-0.35,5.82-0.63,8.74-0.83c0.99-0.07,2,0.04,2.98-0.22v0.51
				c-2.37,0.17-4.74,0.31-7.11,0.53c-2.92,0.27-5.82,0.63-8.72,1.07C262.92,114.45,254.83,116.15,246.79,118.07 M-44.41,111.37
				c0-0.17,0-0.34,0-0.51c2.63-0.06,5.25-0.34,7.87-0.61c6.98-0.71,13.88-1.94,20.75-3.35c3.86-0.8,7.71-1.67,11.55-2.58v0.48
				c-1.44,0.34-2.88,0.68-4.32,1.01c-6.5,1.51-13.04,2.85-19.63,3.85c-3.65,0.56-7.31,1.05-11,1.35
				C-40.94,111.15-42.67,111.32-44.41,111.37" />
                    <path style="clip-path:url(#SVGID_00000141450262727556657140000001961984794989853850_);fill:#CC1249;" d="M205.78,127.47
				c0.18-0.2,0.36-0.39,0.54-0.59c1.94-0.35,3.87-0.73,5.8-1.13c6.01-1.24,11.99-2.65,17.96-4.11c5.57-1.36,11.13-2.76,16.71-4.07
				v0.51c-0.49,0.12-0.98,0.23-1.47,0.35c-6.34,1.53-12.68,3.1-19.02,4.62C219.49,124.68,212.67,126.22,205.78,127.47 M-4.25,104.79
				v-0.48c1.1-0.26,2.21-0.52,3.31-0.79c9.86-2.36,19.68-4.85,29.61-6.88c1.48-0.3,2.97-0.6,4.46-0.88
				c0.05,0.15,0.11,0.31,0.16,0.46c-4.34,0.81-8.67,1.72-12.97,2.73C12.13,100.88,3.94,102.86-4.25,104.79 M110.2,104.36
				c-7.02-3.89-14.44-6.79-22.25-8.66c0.03-0.16,0.06-0.32,0.09-0.48c0.16,0.04,0.32,0.08,0.47,0.12c3.68,0.9,7.28,2.05,10.81,3.42
				c3.85,1.49,7.56,3.25,11.15,5.25C110.39,104.13,110.3,104.25,110.2,104.36 M48.38,93.92c-0.35-0.09-0.72-0.21-1.09-0.35
				c1.51-0.17,3.03-0.33,4.55-0.47c-0.07,0.09-0.14,0.18-0.23,0.26c-0.1,0.1-0.21,0.19-0.33,0.27
				C50.31,93.71,49.35,93.81,48.38,93.92" />
                    <path style="clip-path:url(#SVGID_00000141450262727556657140000001961984794989853850_);fill:#BD1544;" d="M177.61,130.27
				c-1.27,0-2.53-0.02-3.8-0.05c-4.07-0.11-8.13-0.5-12.16-1.14c-5.19-0.83-10.29-2.07-15.26-3.79c-6.47-2.24-12.57-5.26-18.34-8.96
				c-4.05-2.6-7.77-5.64-11.78-8.29c-1.99-1.31-4.01-2.54-6.07-3.68c0.09-0.12,0.18-0.23,0.27-0.35c2.29,1.28,4.52,2.65,6.7,4.12
				c3.66,2.47,7.11,5.25,10.83,7.64c5.31,3.42,10.9,6.26,16.83,8.44c5.93,2.18,12.02,3.7,18.27,4.57c2.94,0.41,5.9,0.66,8.87,0.83
				c1.79,0.1,3.58,0.14,5.37,0.14c1.65,0,3.3-0.03,4.95-0.06c2.75-0.05,5.5-0.31,8.25-0.57c5.3-0.51,10.55-1.29,15.78-2.25
				c-0.18,0.2-0.36,0.4-0.54,0.59c-1.6,0.29-3.21,0.57-4.82,0.82c-3.98,0.63-7.99,1.1-12.01,1.47
				C185.18,130.11,181.39,130.27,177.61,130.27 M33.29,96.22c-0.05-0.15-0.11-0.31-0.16-0.46c4.56-0.86,9.15-1.61,13.76-2.15
				c0.13-0.02,0.26-0.03,0.4-0.05c0.37,0.15,0.74,0.27,1.09,0.35c-3.56,0.4-7.1,0.92-10.63,1.51
				C36.26,95.68,34.78,95.94,33.29,96.22 M87.95,95.7c-2.13-0.51-4.29-0.95-6.48-1.31c-3.39-0.56-6.81-0.92-10.24-1.16
				c-2.43-0.17-4.85-0.24-7.28-0.24c-1.16,0-2.32,0.02-3.49,0.05c-2.46,0.07-4.91,0.25-7.37,0.44c-0.61,0.05-1.21,0.1-1.82,0.15
				c0.11-0.08,0.22-0.17,0.33-0.27c0.09-0.08,0.16-0.17,0.23-0.26c1.73-0.16,3.46-0.29,5.2-0.38c2.34-0.12,4.68-0.25,7.02-0.25
				c0.59,0,1.18,0.01,1.76,0.03c3.58,0.11,7.16,0.29,10.73,0.71c3.87,0.46,7.71,1.11,11.5,2.02C88.01,95.38,87.98,95.54,87.95,95.7" />
                    <path style="clip-path:url(#SVGID_00000141450262727556657140000001961984794989853850_);fill:#CC1249;" d="M199.7,111.46
				c0.19-0.2,0.38-0.39,0.57-0.59c4.22-0.65,8.41-1.43,12.6-2.31c5.12-1.06,10.2-2.27,15.28-3.5c6.21-1.51,12.41-3.07,18.63-4.54
				v0.52c-0.02,0-0.04,0.01-0.06,0.02c-6.45,1.54-12.88,3.14-19.33,4.7c-8.84,2.14-17.71,4.13-26.71,5.55
				C200.36,111.35,200.03,111.41,199.7,111.46 M146.3,107.68c-1.84-0.69-3.65-1.45-5.45-2.26c-1.31-0.59-2.6-1.22-3.87-1.89
				c-0.04-0.2-0.07-0.39-0.11-0.59c3.12,1.62,6.32,3.05,9.62,4.28C146.43,107.38,146.37,107.53,146.3,107.68 M115.58,90.25
				c-2.06-1.22-4.16-2.38-6.3-3.46c0.01-0.16,0.03-0.31,0.04-0.47c2.15,1.08,4.26,2.25,6.33,3.48
				C115.62,89.95,115.6,90.1,115.58,90.25" />
                    <path style="clip-path:url(#SVGID_00000141450262727556657140000001961984794989853850_);fill:#BD1544;" d="M178.26,113.17
				c-0.9,0-1.79-0.01-2.68-0.03c-3.2-0.07-6.4-0.33-9.58-0.71c-4.74-0.57-9.39-1.52-13.97-2.85c-1.93-0.56-3.84-1.2-5.71-1.91
				c0.06-0.15,0.13-0.3,0.19-0.45c0.24,0.09,0.47,0.18,0.71,0.26c5.84,2.12,11.85,3.52,17.99,4.34c2.67,0.35,5.36,0.55,8.05,0.7
				c1.68,0.09,3.35,0.13,5.03,0.13c1.4,0,2.81-0.02,4.21-0.05c2.8-0.06,5.59-0.3,8.38-0.57c3.15-0.3,6.29-0.7,9.41-1.18
				c-0.19,0.2-0.38,0.4-0.57,0.59c-2.92,0.44-5.86,0.79-8.8,1.08C186.69,112.96,182.48,113.17,178.26,113.17 M136.98,103.54
				c-5.36-2.8-10.38-6.17-15.42-9.52c-1.97-1.31-3.96-2.57-5.99-3.77c0.02-0.15,0.04-0.3,0.07-0.45c2.4,1.42,4.75,2.92,7.07,4.46
				c3.68,2.44,7.36,4.89,11.21,7.08c0.97,0.55,1.96,1.09,2.95,1.61C136.91,103.15,136.95,103.34,136.98,103.54" />
                    <path style="clip-path:url(#SVGID_00000141450262727556657140000001961984794989853850_);fill:#CC1249;" d="M-4.25,88.03v-0.49
				c0.14-0.03,0.28-0.07,0.42-0.1c4.3-1.01,8.61-1.99,12.91-3.02c4.57-1.1,9.14-2.14,13.73-3.13c2.43-0.53,4.87-1.03,7.32-1.5
				c0,0.16,0,0.32,0.01,0.48c-6.55,1.27-13.06,2.75-19.56,4.28C5.63,85.71,0.69,86.9-4.25,88.03 M102.14,83.56
				c-2.75-1.11-5.54-2.08-8.37-2.92c0.06-0.14,0.12-0.29,0.17-0.43c2.85,0.85,5.66,1.85,8.41,2.97
				C102.29,83.3,102.22,83.43,102.14,83.56 M32.41,79.84c-0.04-0.06-0.08-0.12-0.12-0.18c-0.05-0.08-0.09-0.17-0.14-0.25
				c3.24-0.6,6.5-1.15,9.76-1.61c1.4-0.2,2.8-0.39,4.21-0.56c0.05,0.16,0.1,0.31,0.16,0.47C41.64,78.26,37.02,78.98,32.41,79.84" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M93.77,80.63
			c-3.11-0.92-6.28-1.69-9.49-2.29c-3.61-0.68-7.26-1.12-10.92-1.43c-3.1-0.27-6.21-0.39-9.32-0.39c-1.18,0-2.36,0.02-3.54,0.05
			c-3.54,0.1-7.07,0.35-10.6,0.71c-1.21,0.12-2.41,0.26-3.62,0.4c-0.05-0.16-0.11-0.31-0.16-0.47c1.59-0.2,3.18-0.37,4.77-0.52
			c2.13-0.19,4.27-0.36,6.41-0.44c2.23-0.09,4.46-0.23,6.68-0.23c0.42,0,0.85,0.01,1.27,0.02c3.01,0.08,6.02,0.21,9.02,0.47
			c5.71,0.48,11.33,1.44,16.87,2.9c0.94,0.25,1.87,0.51,2.8,0.79C93.89,80.35,93.83,80.49,93.77,80.63 M30.13,80.27
			c0-0.16-0.01-0.32-0.01-0.48c0.68-0.13,1.35-0.26,2.03-0.39c0.05,0.08,0.09,0.17,0.14,0.25c0.04,0.06,0.08,0.12,0.12,0.18
			c-0.28,0.05-0.57,0.11-0.85,0.16C31.09,80.09,30.61,80.18,30.13,80.27" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000159465942343742445490000012589400749638991014_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000174568321689522775390000015952179947382104733_">
                        <use xlink:href="#SVGID_00000159465942343742445490000012589400749638991014_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000174568321689522775390000015952179947382104733_);fill:#BD1544;" d="M109.27,86.79
				c-0.88-0.44-1.76-0.87-2.65-1.29c-1.48-0.69-2.97-1.34-4.48-1.95c0.07-0.13,0.15-0.25,0.22-0.38c1.4,0.57,2.79,1.17,4.17,1.81
				c0.94,0.43,1.86,0.88,2.78,1.34C109.3,86.48,109.29,86.64,109.27,86.79" />
                    <path style="clip-path:url(#SVGID_00000174568321689522775390000015952179947382104733_);fill:#EFD1D9;" d="M246.79,60.74v-0.51
				c3.08-0.69,6.17-1.34,9.27-1.96c4.33-0.86,8.68-1.63,13.06-2.22c3.36-0.45,6.73-0.87,10.13-1.09c2.37-0.15,4.74-0.37,7.11-0.37
				c0.19,0,0.39,0,0.58,0v0.51c-0.08,0-0.17,0-0.25,0c-3.02,0-6.02,0.24-9.02,0.49c-7.38,0.61-14.66,1.82-21.92,3.24
				C252.75,59.44,249.76,60.08,246.79,60.74 M-44.1,55c-0.1,0-0.21,0-0.31-0.01c0-0.17,0-0.34,0-0.51c3.25-0.07,6.48-0.31,9.72-0.61
				c8.3-0.77,16.5-2.18,24.67-3.78c1.93-0.38,3.85-0.77,5.77-1.16v0.48C-4.7,49.5-5.15,49.6-5.6,49.69
				c-6.09,1.27-12.21,2.41-18.36,3.36c-3.8,0.58-7.61,1.08-11.44,1.41c-2.2,0.19-4.4,0.39-6.6,0.43C-42.7,54.9-43.4,55-44.1,55" />
                    <path style="clip-path:url(#SVGID_00000174568321689522775390000015952179947382104733_);fill:#CC1249;" d="M193.95,71.28
				c0.04-0.18,0.09-0.35,0.14-0.53c5.02-0.5,10.01-1.26,14.97-2.16c3.28-0.59,6.55-1.27,9.81-1.97c6.98-1.5,13.92-3.18,20.87-4.79
				c2.35-0.54,4.7-1.08,7.05-1.6v0.51c-2.99,0.67-5.98,1.36-8.97,2.06c-5.71,1.34-11.42,2.69-17.14,3.96
				c-5.56,1.23-11.14,2.36-16.76,3.24C200.6,70.52,197.28,70.95,193.95,71.28 M145.95,65.36c-1.15-0.45-2.29-0.92-3.43-1.41
				c-4.34-1.86-8.57-3.95-12.76-6.1c-0.08-0.22-0.15-0.43-0.24-0.64c1.95,0.99,3.91,1.96,5.88,2.93c3.47,1.71,6.98,3.31,10.58,4.71
				C145.97,65.02,145.96,65.19,145.95,65.36 M116.61,51.3c-3.36-1.57-6.76-3.04-10.22-4.36c-4.29-1.64-8.66-3.02-13.12-4.15
				c-0.97-0.24-1.94-0.47-2.91-0.69c-0.07-0.17-0.15-0.35-0.22-0.52c4.37,0.97,8.68,2.19,12.92,3.67c4.46,1.55,8.82,3.35,13.1,5.35
				C116.31,50.83,116.46,51.06,116.61,51.3 M-4.25,49.41v-0.48c4.01-0.82,8.01-1.68,12.01-2.53c5.36-1.14,10.73-2.26,16.11-3.27
				c6.35-1.2,12.71-2.29,19.13-3.04c3.1-0.37,6.2-0.66,9.31-0.89c-0.13,0.16-0.26,0.32-0.39,0.48c-0.51,0.04-1.02,0.07-1.52,0.11
				c-3.68,0.29-7.35,0.72-11.01,1.22c-3.98,0.54-7.94,1.2-11.89,1.91c-6.79,1.23-13.55,2.65-20.3,4.09
				C3.38,47.82-0.44,48.61-4.25,49.41 M81.06,40.41c-0.73-0.1-1.46-0.2-2.19-0.29c-2.15-0.26-4.31-0.45-6.48-0.59
				c-0.02-0.17-0.04-0.33-0.06-0.5c2.74,0.18,5.48,0.46,8.21,0.83c0.17,0.02,0.34,0.05,0.52,0.07
				C81.05,40.09,81.05,40.25,81.06,40.41 M53.47,39.56c0.04-0.16,0.08-0.31,0.12-0.47c0.4-0.03,0.8-0.05,1.2-0.08
				c2.85-0.18,5.71-0.26,8.56-0.26c0.7,0,1.39,0,2.09,0.01c-0.04,0.15-0.08,0.31-0.11,0.46c-0.77-0.01-1.54-0.02-2.31-0.02
				c-1.71,0-3.42,0.03-5.13,0.11C56.41,39.39,54.94,39.47,53.47,39.56" />
                    <path style="clip-path:url(#SVGID_00000174568321689522775390000015952179947382104733_);fill:#BD1544;" d="M180.93,71.98
				c-0.86,0-1.71-0.01-2.57-0.03c-4.35-0.1-8.68-0.52-12.98-1.2c-6.7-1.05-13.14-2.93-19.42-5.39c0.01-0.17,0.02-0.34,0.03-0.51
				c1.98,0.78,3.99,1.49,6.02,2.15c5.48,1.75,11.06,3.02,16.78,3.7c2.72,0.32,5.45,0.5,8.18,0.65c1.42,0.08,2.84,0.11,4.26,0.11
				c0.91,0,1.83-0.01,2.74-0.04c3.07-0.08,6.14-0.28,9.21-0.57c0.3-0.03,0.61-0.06,0.91-0.09c-0.04,0.18-0.09,0.35-0.14,0.53
				c-0.22,0.02-0.43,0.04-0.65,0.06C189.18,71.75,185.06,71.98,180.93,71.98 M129.76,57.86c-0.74-0.38-1.48-0.76-2.21-1.14
				c-3.62-1.87-7.25-3.7-10.94-5.42c-0.15-0.23-0.3-0.47-0.45-0.7c0.17,0.08,0.35,0.16,0.52,0.24c2.96,1.39,5.92,2.79,8.82,4.31
				c1.34,0.7,2.68,1.39,4.03,2.07C129.6,57.43,129.68,57.64,129.76,57.86 M90.36,42.1c-3.08-0.69-6.18-1.25-9.3-1.69
				c0-0.16-0.01-0.31-0.01-0.47c3.06,0.43,6.09,0.97,9.09,1.64C90.21,41.75,90.28,41.92,90.36,42.1 M51.91,39.67
				c0.13-0.16,0.26-0.33,0.39-0.48c0.43-0.03,0.86-0.06,1.29-0.09c-0.04,0.15-0.08,0.31-0.12,0.47
				C52.95,39.6,52.43,39.63,51.91,39.67 M72.38,39.53c-0.67-0.04-1.35-0.08-2.02-0.12c-1.68-0.09-3.36-0.15-5.04-0.18
				c0.04-0.15,0.08-0.31,0.11-0.46c0.74,0.01,1.48,0.02,2.21,0.04c1.56,0.04,3.11,0.11,4.66,0.21
				C72.34,39.2,72.36,39.37,72.38,39.53" />
                    <path style="clip-path:url(#SVGID_00000174568321689522775390000015952179947382104733_);fill:#CC1249;" d="M208.55,124.15
				c0.14-0.2,0.29-0.39,0.43-0.59c6.05-1.19,12.06-2.56,18.06-4.01c6.59-1.59,13.15-3.25,19.75-4.8v0.52
				c-5.31,1.25-10.61,2.57-15.91,3.86C223.47,120.94,216.04,122.71,208.55,124.15 M111.63,102.3c-2.14-1.23-4.33-2.38-6.59-3.43
				c-0.58-0.27-1.16-0.53-1.74-0.79c0.26-0.03,0.5-0.08,0.74-0.15c0.3,0.13,0.6,0.27,0.9,0.41c2.37,1.1,4.67,2.3,6.92,3.59
				C111.78,102.05,111.71,102.18,111.63,102.3" />
                    <path style="clip-path:url(#SVGID_00000174568321689522775390000015952179947382104733_);fill:#BD1544;" d="M177.57,127.48
				c-1.26,0-2.51-0.02-3.77-0.07c-8.7-0.33-17.24-1.64-25.53-4.37c-4.39-1.44-8.65-3.2-12.75-5.32c-4.56-2.36-8.85-5.14-12.97-8.2
				c-3.52-2.61-7.14-5.04-10.92-7.22c0.08-0.12,0.15-0.25,0.22-0.38c3.19,1.84,6.27,3.87,9.26,6.05
				c5.15,3.77,10.43,7.34,16.19,10.12c5.04,2.44,10.26,4.41,15.69,5.82c4.41,1.15,8.88,1.96,13.4,2.46
				c3.73,0.42,7.47,0.59,11.21,0.59c1.65,0,3.3-0.03,4.95-0.1c8.67-0.33,17.25-1.54,25.76-3.19c0.22-0.04,0.45-0.09,0.67-0.13
				c-0.14,0.2-0.29,0.4-0.43,0.59c-0.25,0.05-0.5,0.1-0.75,0.14c-5.32,1.01-10.67,1.85-16.06,2.4c-2.91,0.29-5.83,0.5-8.76,0.65
				C181.18,127.43,179.38,127.48,177.57,127.48" />
                    <path style="clip-path:url(#SVGID_00000174568321689522775390000015952179947382104733_);fill:#CC1249;" d="M-4.25,102.03v-0.5
				c1.5-0.35,3-0.71,4.49-1.07c9.13-2.2,18.25-4.46,27.44-6.38c1.54-0.32,3.08-0.63,4.62-0.93c0.05,0.16,0.09,0.32,0.14,0.47
				c-1.73,0.33-3.47,0.68-5.2,1.04c-5.51,1.15-10.99,2.44-16.47,3.75C5.77,99.62,0.76,100.85-4.25,102.03 M98.97,96.3
				c-3.38-1.28-6.83-2.36-10.35-3.22c0.05-0.15,0.1-0.3,0.15-0.45c3.38,0.84,6.69,1.87,9.93,3.09c0.07,0.17,0.14,0.33,0.23,0.5
				C98.94,96.25,98.95,96.28,98.97,96.3 M43.92,91.73c-0.2-0.13-0.4-0.27-0.6-0.41c0.16-0.02,0.33-0.05,0.49-0.07
				c2.7-0.37,5.41-0.67,8.12-0.91c0.02,0.06,0.04,0.13,0.06,0.19c0.03,0.09,0.06,0.19,0.08,0.28c-0.81,0.08-1.63,0.16-2.44,0.24
				C47.72,91.24,45.81,91.47,43.92,91.73" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M32.44,93.63
			c-0.05-0.16-0.09-0.32-0.14-0.47c3.66-0.7,7.33-1.32,11.02-1.83c0.2,0.14,0.4,0.28,0.6,0.41C40.07,92.25,36.25,92.9,32.44,93.63
			 M88.62,93.08c-1.9-0.47-3.82-0.87-5.76-1.22c-3.43-0.61-6.88-1.05-10.36-1.3c-2.18-0.16-4.37-0.15-6.55-0.28
			c-0.72-0.04-1.43-0.06-2.15-0.06c-1.68,0-3.37,0.09-5.04,0.14c-2.23,0.06-4.46,0.23-6.69,0.44c-0.02-0.09-0.05-0.19-0.08-0.28
			c-0.02-0.06-0.04-0.13-0.06-0.19c1.28-0.11,2.56-0.21,3.85-0.3c2.73-0.19,5.47-0.27,8.2-0.27c1.49,0,2.98,0.03,4.47,0.07
			c6.23,0.21,12.4,0.96,18.48,2.35c0.62,0.14,1.23,0.29,1.84,0.44C88.72,92.78,88.67,92.93,88.62,93.08" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000014620308662092764790000014549597426874911395_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000061464145349963771250000011802668740023465361_">
                        <use xlink:href="#SVGID_00000014620308662092764790000014549597426874911395_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000061464145349963771250000011802668740023465361_);fill:#BD1544;" d="M103.3,98.08
				c-1.43-0.63-2.88-1.22-4.33-1.78c-0.01-0.02-0.03-0.05-0.04-0.08c-0.08-0.17-0.16-0.34-0.23-0.5c1.8,0.67,3.57,1.41,5.33,2.2
				C103.8,98,103.56,98.05,103.3,98.08" />
                    <path style="clip-path:url(#SVGID_00000061464145349963771250000011802668740023465361_);fill:#EFD1D9;" d="M246.79,43.25v-0.51
				c1.65-0.35,3.31-0.69,4.97-1.02c4.83-0.98,9.69-1.81,14.57-2.5c3.23-0.46,6.48-0.83,9.74-1.09c3.21-0.26,6.42-0.49,9.64-0.49
				c0.41,0,0.82,0,1.24,0.01v0.38c-2.2,0.22-4.41,0.1-6.61,0.26c-2.67,0.19-5.33,0.4-7.99,0.68c-4.18,0.45-8.34,1.06-12.48,1.76
				c-2.77,0.47-5.51,1.02-8.27,1.55C249.98,42.59,248.38,42.92,246.79,43.25 M-43.82,38.03c-0.2,0-0.4,0-0.59,0
				c0-0.17,0-0.34,0-0.51c0.21,0,0.42,0,0.63,0c3.19,0,6.36-0.24,9.54-0.49c4.59-0.37,9.16-0.96,13.71-1.65
				c5.45-0.82,10.87-1.8,16.27-2.85v0.49c-6.45,1.24-12.92,2.42-19.43,3.32c-3.6,0.49-7.2,0.93-10.82,1.21
				C-37.6,37.78-40.7,38.03-43.82,38.03" />
                    <path style="clip-path:url(#SVGID_00000061464145349963771250000011802668740023465361_);fill:#CC1249;" d="M182.46,53.86
				c-0.81,0-1.61-0.01-2.42-0.03c-2.7-0.07-5.4-0.22-8.1-0.48c0.02-0.19,0.04-0.38,0.06-0.57c2.46,0.23,4.92,0.38,7.39,0.48
				c0.96,0.04,1.93,0.05,2.89,0.05c1.19,0,2.37-0.02,3.56-0.06c-0.08,0.19-0.16,0.38-0.24,0.57
				C184.56,53.84,183.51,53.86,182.46,53.86 M195.71,53.11c-0.02-0.18-0.04-0.35-0.06-0.53c1.7-0.18,3.39-0.38,5.08-0.6
				c5.29-0.7,10.54-1.62,15.77-2.65c3.77-0.74,7.52-1.6,11.28-2.42c4.86-1.06,9.72-2.11,14.56-3.21c1.48-0.34,2.96-0.66,4.45-0.97
				v0.51c-4.95,1.04-9.88,2.18-14.83,3.27c-3.82,0.84-7.63,1.7-11.45,2.51c-3.99,0.85-7.99,1.62-12.01,2.31
				c-3.62,0.62-7.26,1.14-10.9,1.58C196.96,52.99,196.33,53.06,195.71,53.11 M149.16,48.34c-3.39-1.15-6.72-2.46-10.03-3.85
				c-0.89-0.38-1.78-0.76-2.67-1.15c0.05-0.14,0.1-0.29,0.15-0.44c3.26,1.41,6.55,2.76,9.9,3.98c0.93,0.34,1.87,0.67,2.81,0.98
				C149.28,48.02,149.22,48.18,149.16,48.34 M127.26,39.22c-4.63-2.09-9.27-4.16-14-6.02c-8.02-3.16-16.23-5.71-24.71-7.34
				c-0.19-0.04-0.38-0.07-0.57-0.11c0.01-0.15,0.03-0.3,0.05-0.45c2.35,0.44,4.69,0.95,7.02,1.52c5.35,1.31,10.59,3,15.75,4.94
				c5.38,2.02,10.65,4.33,15.89,6.69C126.87,38.71,127.07,38.97,127.26,39.22 M-4.25,33.02v-0.49c1.16-0.23,2.32-0.46,3.48-0.69
				c5.16-1.04,10.34-2.01,15.51-3.04c5.17-1.03,10.35-1.99,15.55-2.82c3.92-0.62,7.86-1.15,11.8-1.63
				c3.52-0.43,7.05-0.73,10.59-0.97c2.86-0.19,5.72-0.31,8.58-0.31c0.45,0,0.9,0,1.35,0.01c0.45,0.01,0.91,0.01,1.36,0.01
				c0.17,0,0.34,0,0.51,0c0.17,0,0.35,0,0.52,0c0.6,0,1.21,0,1.81,0.01c1.96,0.04,3.92,0.14,5.87,0.27
				c-0.1,0.15-0.21,0.29-0.31,0.44c-3.08-0.21-6.17-0.32-9.26-0.32c-1.02,0-2.05,0.01-3.07,0.04c-3.54,0.09-7.08,0.27-10.62,0.56
				c-3.81,0.31-7.61,0.71-11.39,1.22c-3.01,0.4-6.01,0.91-9.01,1.37c-4.33,0.67-8.63,1.48-12.92,2.31
				c-5.65,1.09-11.29,2.27-16.94,3.37C-1.98,32.58-3.12,32.8-4.25,33.02 M83.99,25.08c-2.61-0.4-5.22-0.72-7.86-0.96
				c-0.91-0.08-1.82-0.16-2.74-0.23c0.04-0.15,0.08-0.3,0.11-0.45c1.07,0.08,2.14,0.17,3.21,0.27c2.5,0.23,4.99,0.55,7.47,0.93
				C84.12,24.79,84.05,24.93,83.99,25.08" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M136.47,43.34
			c-3.08-1.34-6.14-2.73-9.21-4.11c-0.19-0.26-0.39-0.51-0.59-0.77c1.09,0.49,2.19,0.98,3.28,1.48c2.22,1,4.44,2,6.67,2.96
			C136.57,43.05,136.52,43.19,136.47,43.34" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000075124042143697576140000013063292550695671199_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000005254364260922477070000001819430650939707051_">
                        <use xlink:href="#SVGID_00000075124042143697576140000013063292550695671199_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000005254364260922477070000001819430650939707051_);fill:#BD1544;" d="M185.6,53.81
				c0.08-0.19,0.16-0.38,0.24-0.57c0.65-0.02,1.31-0.05,1.96-0.07c2.62-0.11,5.23-0.31,7.84-0.58c0.02,0.18,0.04,0.35,0.06,0.53
				c-1.46,0.14-2.93,0.24-4.39,0.36C189.41,53.63,187.51,53.75,185.6,53.81 M171.95,53.35c-0.66-0.07-1.33-0.14-1.99-0.22
				c-5.52-0.68-10.94-1.78-16.27-3.35c-1.52-0.45-3.03-0.93-4.52-1.44c0.06-0.16,0.11-0.32,0.17-0.47
				c6.07,2.05,12.27,3.6,18.65,4.45c1.34,0.18,2.68,0.33,4.02,0.46C171.99,52.96,171.97,53.16,171.95,53.35 M87.98,25.76
				c-1.33-0.25-2.66-0.48-3.99-0.68c0.06-0.15,0.13-0.29,0.2-0.44c1.28,0.2,2.56,0.42,3.84,0.66
				C88.01,25.46,87.99,25.61,87.98,25.76 M73.4,23.89c-0.34-0.03-0.69-0.05-1.03-0.07c0.1-0.15,0.21-0.29,0.31-0.44
				c0.28,0.02,0.55,0.04,0.83,0.06C73.47,23.59,73.43,23.74,73.4,23.89" />
                    <path style="clip-path:url(#SVGID_00000005254364260922477070000001819430650939707051_);fill:#EFD1D9;" d="M246.79,134.97v-0.49
				c7.54-1.75,15.1-3.38,22.75-4.56c3.65-0.57,7.31-1.04,11-1.35c1.95-0.16,3.89-0.35,5.84-0.38c0.19,0,0.37-0.07,0.56-0.11v0.51
				c-10.1,0.52-20.06,2.05-29.94,4.12C253.58,133.42,250.18,134.18,246.79,134.97 M-44.41,128.33c0-0.17,0-0.34,0-0.51
				c2.63-0.06,5.25-0.34,7.87-0.61c7.15-0.73,14.21-2.01,21.25-3.48c3.7-0.77,7.37-1.62,11.05-2.5v0.51
				c-7.66,1.82-15.36,3.51-23.14,4.75c-3.92,0.63-7.85,1.16-11.81,1.48C-40.94,128.11-42.67,128.31-44.41,128.33" />
                    <path style="clip-path:url(#SVGID_00000005254364260922477070000001819430650939707051_);fill:#CC1249;" d="M202.72,144.74
				c0.1-0.11,0.2-0.22,0.31-0.33c0.08-0.08,0.15-0.17,0.23-0.25c5.67-0.95,11.3-2.12,16.93-3.37c7.49-1.67,14.93-3.55,22.39-5.32
				c1.4-0.33,2.81-0.66,4.21-0.99v0.49c-3.04,0.71-6.08,1.44-9.11,2.17c-5.25,1.27-10.49,2.53-15.75,3.74
				c-6.34,1.46-12.7,2.79-19.12,3.85C202.78,144.73,202.75,144.73,202.72,144.74 M-4.25,121.74v-0.51c0.69-0.16,1.38-0.33,2.07-0.49
				c8.66-2.08,17.28-4.3,25.97-6.24c2.07-0.46,4.14-0.91,6.22-1.33c0.01,0.16,0.02,0.33,0.04,0.5c-4.43,0.9-8.83,1.89-13.22,2.95
				c-6.4,1.54-12.8,3.13-19.2,4.67C-3,121.44-3.63,121.59-4.25,121.74 M31.72,113.34c-0.11-0.15-0.22-0.3-0.33-0.45
				c3.27-0.65,6.55-1.24,9.85-1.74c0.11,0.15,0.23,0.3,0.34,0.45c-0.45,0.07-0.9,0.14-1.36,0.21
				C37.39,112.27,34.55,112.78,31.72,113.34" />
                    <path style="clip-path:url(#SVGID_00000005254364260922477070000001819430650939707051_);fill:#BD1544;" d="M177.07,146.97
				c-1.38,0-2.76-0.02-4.13-0.04c-2.6-0.05-5.21-0.3-7.8-0.58c-4.55-0.5-9.04-1.31-13.47-2.45c-5.11-1.32-10.07-3.09-14.87-5.3
				c-6.09-2.8-11.76-6.29-17.14-10.26c-5.06-3.72-10.3-7.15-15.97-9.87c-7.74-3.71-15.85-6.2-24.34-7.49
				c-3.42-0.52-6.86-0.84-10.32-1.03c-1.87-0.1-3.75-0.15-5.62-0.15c-1.46,0-2.93,0.03-4.39,0.08c-5.84,0.22-11.66,0.84-17.44,1.72
				c-0.11-0.15-0.23-0.3-0.34-0.45c0.88-0.13,1.76-0.26,2.65-0.38c3.36-0.46,6.74-0.81,10.12-1.09c3.18-0.26,6.37-0.38,9.56-0.38
				c1.31,0,2.63,0.02,3.94,0.06c4.82,0.15,9.61,0.66,14.36,1.53c6.42,1.18,12.64,3.05,18.64,5.62c4.23,1.81,8.26,4,12.14,6.46
				c4.22,2.68,8.12,5.82,12.3,8.54c6,3.92,12.36,7.13,19.11,9.55c5.91,2.11,11.97,3.57,18.17,4.44c2.9,0.4,5.81,0.71,8.74,0.83
				c1.93,0.08,3.86,0.23,5.79,0.23c0.3,0,0.6,0,0.89-0.01c3.84-0.11,7.67-0.25,11.5-0.58c4.73-0.4,9.43-1.04,14.1-1.82
				c-0.08,0.09-0.15,0.17-0.23,0.25c-0.1,0.11-0.2,0.22-0.31,0.33c-4.1,0.68-8.22,1.18-12.35,1.61
				C185.94,146.81,181.51,146.97,177.07,146.97 M30.05,113.67c-0.01-0.17-0.03-0.34-0.04-0.5c0.46-0.09,0.93-0.19,1.39-0.28
				c0.11,0.15,0.22,0.3,0.33,0.45C31.17,113.45,30.61,113.56,30.05,113.67" />
                    <path style="clip-path:url(#SVGID_00000005254364260922477070000001819430650939707051_);fill:#CC1249;" d="M202.65,108.14
				c0.16-0.2,0.31-0.39,0.47-0.58c2.8-0.47,5.59-0.99,8.37-1.56c5.33-1.08,10.63-2.32,15.92-3.6c6.46-1.56,12.91-3.19,19.38-4.71
				v0.51c-2.96,0.7-5.91,1.42-8.86,2.14c-9.97,2.45-19.92,4.95-30.01,6.87C206.17,107.53,204.41,107.85,202.65,108.14
				 M147.16,105.04c-3.67-1.34-7.25-2.94-10.74-4.79c-0.03-0.19-0.06-0.39-0.08-0.58c2.61,1.38,5.28,2.65,8.02,3.77
				c0.96,0.39,1.94,0.77,2.91,1.12C147.24,104.73,147.2,104.89,147.16,105.04 M115.89,87.6c-0.53-0.31-1.07-0.62-1.6-0.93
				c-1.59-0.9-3.21-1.76-4.84-2.58c0.01-0.16,0.01-0.32,0.01-0.48c2.2,1.1,4.35,2.28,6.47,3.52
				C115.92,87.29,115.91,87.44,115.89,87.6" />
                    <path style="clip-path:url(#SVGID_00000005254364260922477070000001819430650939707051_);fill:#BD1544;" d="M178.65,110.33
				c-1.79,0-3.57-0.06-5.35-0.15c-5.59-0.26-11.12-1.01-16.57-2.28c-3.26-0.76-6.45-1.72-9.57-2.86c0.04-0.16,0.08-0.32,0.11-0.48
				c5.12,1.87,10.38,3.22,15.78,4.06c3.06,0.48,6.14,0.82,9.24,0.97c2.13,0.1,4.26,0.24,6.39,0.24c0.46,0,0.91-0.01,1.37-0.02
				c2.95-0.1,5.89-0.23,8.83-0.46c4.78-0.38,9.52-1.01,14.23-1.8c-0.15,0.2-0.31,0.39-0.47,0.58c-3.25,0.54-6.51,0.99-9.78,1.34
				c-3.74,0.4-7.48,0.61-11.23,0.79C180.64,110.31,179.64,110.33,178.65,110.33 M136.42,100.26c-0.01-0.01-0.02-0.01-0.04-0.02
				c-3.44-1.82-6.74-3.87-10-5.99c-3.47-2.27-6.93-4.55-10.49-6.65c0.01-0.15,0.03-0.31,0.04-0.46c1.93,1.13,3.82,2.3,5.7,3.51
				c4,2.57,7.95,5.24,12.09,7.59c0.87,0.49,1.74,0.97,2.62,1.44C136.37,99.87,136.39,100.07,136.42,100.26" />
                    <path style="clip-path:url(#SVGID_00000005254364260922477070000001819430650939707051_);fill:#CC1249;" d="M-4.25,85.24v-0.46
				c1.94-0.45,3.89-0.9,5.83-1.36c6.12-1.44,12.25-2.86,18.38-4.24c3.42-0.77,6.85-1.49,10.29-2.15c-0.01,0.17-0.03,0.33-0.04,0.5
				c-3.74,0.71-7.47,1.5-11.18,2.36C11.27,81.67,3.51,83.45-4.25,85.24 M103.36,81.31c-1.72-0.72-3.47-1.38-5.24-2
				c-1.12-0.39-2.25-0.76-3.39-1.11c0.06-0.15,0.11-0.29,0.17-0.44c2.94,0.9,5.82,1.95,8.66,3.14
				C103.5,81.04,103.43,81.18,103.36,81.31 M31.03,77.37c-0.08-0.15-0.16-0.3-0.25-0.44c2.06-0.39,4.11-0.76,6.18-1.1
				c2.79-0.46,5.59-0.89,8.4-1.23c0.04,0.16,0.08,0.33,0.13,0.49c-2.53,0.31-5.06,0.67-7.58,1.08
				C35.61,76.54,33.32,76.94,31.03,77.37" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M94.74,78.2
			c-3.98-1.22-8.03-2.17-12.15-2.86c-3.01-0.51-6.05-0.86-9.1-1.11c-3.18-0.27-6.36-0.37-9.55-0.37c-1.27,0-2.55,0.02-3.82,0.05
			c-3.03,0.07-6.06,0.3-9.08,0.58c-1.86,0.17-3.71,0.38-5.56,0.6c-0.04-0.16-0.09-0.33-0.13-0.49c0.9-0.11,1.8-0.21,2.7-0.31
			c2.97-0.31,5.95-0.57,8.95-0.7c2.43-0.11,4.86-0.24,7.29-0.24c0.54,0,1.08,0.01,1.62,0.02c3.29,0.09,6.57,0.27,9.84,0.59
			c5.3,0.52,10.52,1.46,15.66,2.81c1.17,0.31,2.33,0.64,3.48,0.99C94.85,77.91,94.79,78.06,94.74,78.2 M30.2,77.53
			c0.01-0.17,0.02-0.33,0.04-0.5c0.18-0.03,0.36-0.07,0.54-0.1c0.08,0.15,0.16,0.3,0.25,0.44C30.75,77.42,30.48,77.48,30.2,77.53" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000163033067745857921440000000286106674770801338_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000175306332730459919560000007818401800109956772_">
                        <use xlink:href="#SVGID_00000163033067745857921440000000286106674770801338_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#BD1544;" d="M109.45,84.09
				c-2-1-4.02-1.92-6.08-2.78c0.07-0.13,0.14-0.27,0.2-0.4c0.74,0.31,1.47,0.62,2.2,0.95c1.25,0.56,2.48,1.14,3.7,1.75
				C109.46,83.77,109.45,83.93,109.45,84.09" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#EFD1D9;" d="M246.79,137.77v-0.51
				c2.67-0.62,5.35-1.22,8.03-1.79c6.23-1.33,12.5-2.51,18.82-3.34c3.1-0.4,6.2-0.71,9.3-0.98c1.33-0.11,2.67-0.09,4-0.27v0.51
				c-6.59,0.38-13.15,1.08-19.67,2.18c-6.14,1.04-12.24,2.27-18.3,3.69C248.24,137.43,247.51,137.6,246.79,137.77 M-44.41,131.13
				c0-0.17,0-0.34,0-0.51c3.25-0.05,6.48-0.42,9.7-0.8c5.55-0.64,11.06-1.58,16.54-2.69c4.66-0.95,9.3-1.99,13.92-3.09v0.5
				c-5.2,1.24-10.42,2.41-15.67,3.43c-5.28,1.03-10.58,1.89-15.92,2.48C-38.7,130.78-41.55,131.07-44.41,131.13" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#CC1249;" d="M199.3,148.02
				c0.23-0.2,0.46-0.4,0.69-0.61c1.48-0.22,2.96-0.46,4.44-0.71c5.34-0.91,10.65-2.01,15.93-3.19c5.31-1.18,10.6-2.47,15.88-3.74
				c3.51-0.85,7.02-1.69,10.54-2.51v0.51c-7.59,1.79-15.17,3.61-22.76,5.39c-6.91,1.62-13.85,3.08-20.86,4.25
				C201.88,147.63,200.59,147.83,199.3,148.02 M-4.25,124.55v-0.5c2.09-0.5,4.19-1.01,6.28-1.52c9.41-2.3,18.79-4.69,28.29-6.61
				c0.02,0.16,0.05,0.33,0.07,0.49c-0.71,0.14-1.42,0.29-2.12,0.44c-4.95,1.03-9.87,2.2-14.79,3.38
				C7.56,121.66,1.66,123.14-4.25,124.55 M33.66,115.77c-0.12-0.14-0.24-0.29-0.37-0.43c3.29-0.63,6.59-1.21,9.91-1.68
				c0.13,0.15,0.25,0.31,0.38,0.46C40.27,114.58,36.96,115.15,33.66,115.77" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#BD1544;" d="M177.04,149.76
				c-0.52,0-1.04,0-1.56-0.02c-7.55-0.17-15.03-0.96-22.4-2.73c-7.46-1.8-14.57-4.5-21.34-8.09c-4-2.12-7.82-4.56-11.49-7.21
				c-5.3-3.82-10.69-7.48-16.59-10.33c-7.49-3.63-15.34-6.12-23.55-7.46c-3.78-0.61-7.57-0.98-11.39-1.18
				c-1.76-0.09-3.52-0.13-5.27-0.13c-1.34,0-2.69,0.02-4.03,0.06c-3.65,0.1-7.28,0.41-10.91,0.82c-1.64,0.18-3.28,0.39-4.91,0.62
				c-0.13-0.15-0.26-0.31-0.38-0.46c1.21-0.17,2.43-0.33,3.65-0.47c3.03-0.35,6.07-0.63,9.13-0.83c2.54-0.17,5.08-0.24,7.62-0.24
				c1.07,0,2.14,0.01,3.21,0.04c3.09,0.08,6.18,0.32,9.26,0.7c4.12,0.51,8.18,1.27,12.2,2.31c4.81,1.25,9.44,2.97,13.95,5.03
				c6.14,2.8,11.74,6.48,17.2,10.41c4.25,3.06,8.64,5.88,13.29,8.3c8.62,4.49,17.74,7.43,27.31,9.01c3.27,0.54,6.56,0.86,9.86,1.1
				c2.47,0.18,4.94,0.24,7.41,0.24c1.2,0,2.41-0.02,3.61-0.04c2.54-0.05,5.08-0.25,7.62-0.45c3.84-0.31,7.66-0.78,11.47-1.35
				c-0.23,0.21-0.46,0.41-0.69,0.61c-2.76,0.4-5.53,0.73-8.31,1c-3.26,0.32-6.51,0.52-9.78,0.65
				C179.82,149.72,178.43,149.76,177.04,149.76 M30.39,116.41c-0.03-0.16-0.05-0.33-0.07-0.49c0.44-0.09,0.89-0.18,1.33-0.26
				c0.55-0.11,1.1-0.22,1.65-0.32c0.12,0.15,0.24,0.29,0.37,0.43C32.57,115.98,31.48,116.19,30.39,116.41" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#EFD1D9;" d="M246.79,57.85v-0.51
				c6.17-1.36,12.36-2.61,18.61-3.58c3.99-0.62,7.98-1.14,12.01-1.47c2.98-0.24,5.96-0.49,8.95-0.49c0.2,0,0.39,0,0.59,0v0.51
				c-0.11,0-0.21,0-0.32,0c-4.38,0-8.73,0.39-13.07,0.87c-6.04,0.66-12.03,1.65-17.99,2.83C252.62,56.59,249.7,57.21,246.79,57.85
				 M-44.32,52.19c-0.03,0-0.06,0-0.1,0c0-0.17,0-0.34,0-0.51c3.33-0.04,6.65-0.32,9.97-0.62c8.15-0.74,16.21-2.11,24.24-3.66
				c1.99-0.38,3.97-0.78,5.95-1.19v0.48c-0.01,0-0.02,0-0.02,0c-3.62,0.74-7.25,1.46-10.88,2.12c-5.24,0.95-10.51,1.79-15.81,2.38
				c-3.65,0.4-7.3,0.75-10.97,0.9C-42.73,52.12-43.52,52.19-44.32,52.19" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#CC1249;" d="M173.92,68.71
				c-0.68-0.05-1.37-0.11-2.05-0.17c-0.66-0.06-1.33-0.12-1.99-0.2c-0.05-0.17-0.08-0.35-0.1-0.54c1.64,0.18,3.29,0.31,4.94,0.41
				C174.46,68.39,174.19,68.56,173.92,68.71 M194.67,68.25c0.04-0.18,0.08-0.36,0.11-0.53c1.23-0.13,2.45-0.28,3.68-0.43
				c6.6-0.84,13.13-2.05,19.64-3.43c6.44-1.37,12.85-2.89,19.27-4.37c3.14-0.73,6.27-1.45,9.42-2.14v0.51
				c-3.99,0.88-7.98,1.8-11.96,2.74c-6.58,1.55-13.16,3.07-19.78,4.43c-3.99,0.82-8.01,1.56-12.04,2.17
				C200.24,67.61,197.46,67.96,194.67,68.25 M146.15,62.43c-1.02-0.4-2.04-0.81-3.06-1.23c-5.04-2.11-9.9-4.56-14.78-7.01
				c-0.09-0.21-0.18-0.41-0.27-0.62c2.34,1.16,4.68,2.32,7.03,3.46c3.65,1.78,7.34,3.44,11.12,4.9
				C146.18,62.09,146.16,62.26,146.15,62.43 M113.62,47.18c-1.69-0.74-3.39-1.45-5.11-2.13c-6.27-2.48-12.7-4.44-19.27-5.83
				c-0.06-0.17-0.11-0.33-0.17-0.5c0.75,0.16,1.51,0.32,2.26,0.5c6.08,1.4,12,3.31,17.8,5.6c1.28,0.51,2.55,1.03,3.81,1.57
				C113.17,46.65,113.4,46.91,113.62,47.18 M-4.25,46.69v-0.48c3.55-0.74,7.1-1.5,10.65-2.23c5.49-1.13,10.98-2.29,16.49-3.34
				c5.49-1.05,10.99-1.99,16.53-2.74c4.42-0.6,8.85-1.06,13.3-1.38c2.9-0.21,5.81-0.25,8.72-0.36c0.82-0.03,1.64-0.04,2.46-0.04
				c0.77,0,1.53,0.01,2.3,0.03c-0.05,0.16-0.1,0.33-0.15,0.49c-0.9-0.02-1.81-0.02-2.71-0.02c-1.38,0-2.76,0.02-4.15,0.05
				c-3.65,0.09-7.29,0.35-10.93,0.69c-6.03,0.57-12.02,1.39-17.98,2.41c-5.09,0.87-10.16,1.84-15.21,2.9
				C8.63,44.03,2.19,45.37-4.25,46.69 M81.08,37.79c-2.58-0.35-5.17-0.61-7.77-0.82c-0.4-0.03-0.81-0.06-1.21-0.09
				c-0.01-0.16-0.03-0.31-0.04-0.47c3.03,0.19,6.05,0.49,9.05,0.9C81.1,37.48,81.09,37.64,81.08,37.79" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#BD1544;" d="M180.88,69
				c-2.32,0-4.64-0.11-6.96-0.28c0.27-0.16,0.54-0.32,0.8-0.49c1.48,0.09,2.96,0.15,4.44,0.19c0.82,0.02,1.64,0.03,2.46,0.03
				c2.39,0,4.77-0.09,7.15-0.22c2.01-0.11,4.01-0.29,6.01-0.51c-0.04,0.18-0.07,0.36-0.11,0.53c-0.55,0.06-1.09,0.11-1.64,0.16
				c-4.02,0.39-8.05,0.58-12.08,0.59C180.93,69,180.9,69,180.88,69 M169.88,68.35c-3.48-0.39-6.92-0.99-10.33-1.77
				c-4.58-1.04-9.03-2.47-13.4-4.16c0.01-0.17,0.03-0.33,0.04-0.5c1.87,0.72,3.75,1.39,5.66,2c5.68,1.82,11.47,3.13,17.4,3.82
				c0.18,0.02,0.35,0.04,0.52,0.06C169.8,68,169.83,68.18,169.88,68.35 M128.31,54.19c-1.03-0.52-2.06-1.03-3.1-1.55
				c-3.83-1.9-7.68-3.75-11.6-5.46c-0.22-0.27-0.45-0.53-0.68-0.79c2.02,0.87,4.02,1.77,6,2.72c3.05,1.46,6.08,2.96,9.11,4.46
				C128.13,53.77,128.22,53.98,128.31,54.19 M89.24,39.21c-1.51-0.32-3.03-0.61-4.55-0.87c-1.2-0.2-2.4-0.39-3.6-0.55
				c0.01-0.16,0.02-0.31,0.02-0.47c2.67,0.37,5.32,0.83,7.97,1.39C89.13,38.88,89.18,39.05,89.24,39.21 M72.1,36.89
				c-2.02-0.14-4.04-0.22-6.06-0.25c0.05-0.16,0.1-0.33,0.15-0.49c1.81,0.05,3.62,0.15,5.43,0.25c0.15,0.01,0.29,0.02,0.44,0.03
				C72.07,36.58,72.09,36.73,72.1,36.89" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#EFD1D9;" d="M246.79,80.98v-0.5
				c2.89-0.67,5.78-1.33,8.68-1.92c5.93-1.21,11.88-2.32,17.9-3.05c3.94-0.47,7.88-0.91,11.85-1.02c0.5-0.01,1-0.11,1.51-0.11
				c0.07,0,0.14,0,0.21,0.01v0.51c-3.44,0.12-6.86,0.37-10.28,0.73c-7.19,0.75-14.3,2.01-21.37,3.48
				C252.44,79.69,249.61,80.32,246.79,80.98 M-44.41,74.76c0-0.17,0-0.34,0-0.51c3.89-0.25,7.79-0.52,11.67-0.98
				c6.01-0.72,11.98-1.72,17.92-2.89c3.53-0.7,7.05-1.44,10.57-2.21v0.47c-1.78,0.39-3.55,0.78-5.33,1.16
				c-6.42,1.37-12.87,2.57-19.38,3.48c-3.2,0.45-6.4,0.81-9.61,1.1c-1.56,0.14-3.13,0.19-4.7,0.29
				C-43.65,74.67-44.03,74.72-44.41,74.76" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#CC1249;" d="M186.12,92.6
				c0.06-0.16,0.12-0.33,0.18-0.49c4.12-0.22,8.22-0.67,12.31-1.24c0.11-0.02,0.23-0.03,0.34-0.05c-0.2,0.2-0.41,0.4-0.62,0.6
				c-0.85,0.12-1.71,0.23-2.56,0.33C192.57,92.14,189.35,92.41,186.12,92.6 M211.02,89.27c0.02-0.18,0.05-0.35,0.07-0.53
				c3.49-0.69,6.96-1.45,10.42-2.26c6.29-1.46,12.57-2.99,18.85-4.49c2.14-0.51,4.28-1.02,6.42-1.52v0.5
				c-1.75,0.4-3.49,0.82-5.24,1.24c-9.25,2.23-18.49,4.55-27.8,6.5C212.84,88.91,211.93,89.09,211.02,89.27 M147.02,86.97
				c-3.74-1.44-7.38-3.12-10.93-4.99c-0.51-0.27-1.02-0.54-1.53-0.82c-0.02-0.18-0.04-0.37-0.05-0.55c1.74,0.94,3.49,1.85,5.28,2.71
				c2.36,1.13,4.75,2.17,7.17,3.11C146.97,86.6,147,86.79,147.02,86.97 M113.78,69.49c-4.16-2.1-8.42-4-12.84-5.58
				c-1.05-0.37-2.1-0.73-3.15-1.07c-0.01-0.16-0.02-0.32-0.03-0.49c3.8,1.23,7.52,2.68,11.18,4.32c1.57,0.7,3.12,1.45,4.65,2.22
				C113.65,69.09,113.72,69.29,113.78,69.49 M-4.25,68.63v-0.47c2.92-0.64,5.83-1.29,8.74-1.96c6.4-1.45,12.81-2.9,19.24-4.2
				c5.71-1.16,11.43-2.19,17.2-3.01c1.25-0.18,2.5-0.34,3.75-0.49c-0.02,0.16-0.05,0.32-0.07,0.48c-4.18,0.51-8.35,1.16-12.5,1.89
				c-4.08,0.72-8.14,1.53-12.19,2.39c-6.21,1.32-12.41,2.74-18.6,4.12C-0.55,67.8-2.4,68.22-4.25,68.63 M55.35,57.98
				c-0.08-0.15-0.15-0.3-0.23-0.45c2.98-0.18,5.97-0.27,8.96-0.27c0.13,0,0.26,0,0.39,0c0.01,0.16,0.02,0.32,0.04,0.47
				c-0.23,0-0.46,0-0.7,0c-1.74,0-3.49,0.05-5.23,0.1C57.51,57.87,56.43,57.92,55.35,57.98" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#BD1544;" d="M179.58,92.8
				c-1.38,0-2.76-0.03-4.13-0.08c-7.62-0.31-15.12-1.45-22.44-3.68c-2.03-0.62-4.02-1.31-5.98-2.07c-0.02-0.18-0.04-0.37-0.07-0.55
				c2.99,1.16,6.05,2.15,9.17,2.96c4.54,1.18,9.14,1.95,13.79,2.45c3.12,0.33,6.26,0.45,9.39,0.45c1.6,0,3.2-0.03,4.8-0.08
				c0.74-0.02,1.47-0.05,2.2-0.09c-0.06,0.16-0.12,0.33-0.18,0.49c-0.29,0.02-0.58,0.03-0.87,0.05
				C183.36,92.75,181.47,92.8,179.58,92.8 M198.34,91.42c0.21-0.2,0.41-0.4,0.62-0.6c4.07-0.57,8.11-1.28,12.14-2.08
				c-0.02,0.18-0.04,0.35-0.07,0.53C206.82,90.11,202.59,90.84,198.34,91.42 M134.55,81.16c-4.21-2.29-8.32-4.77-12.47-7.16
				c-2.73-1.57-5.49-3.09-8.3-4.5c-0.06-0.2-0.12-0.4-0.19-0.6c3.97,2,7.83,4.2,11.67,6.44c3.07,1.78,6.12,3.58,9.24,5.27
				C134.52,80.79,134.54,80.97,134.55,81.16 M97.79,62.84c-4.3-1.39-8.68-2.5-13.14-3.31c-3.4-0.62-6.83-1.08-10.29-1.36
				c-2.71-0.22-5.42-0.32-8.14-0.4c-0.57-0.02-1.15-0.03-1.72-0.03c-0.01-0.16-0.02-0.32-0.04-0.47c1.04,0,2.08,0.02,3.12,0.04
				c2.88,0.07,5.76,0.29,8.63,0.58c5.36,0.55,10.64,1.48,15.86,2.82c1.91,0.49,3.81,1.05,5.68,1.65
				C97.77,62.52,97.78,62.68,97.79,62.84 M44.61,58.99c0.02-0.16,0.04-0.32,0.07-0.48c1.89-0.23,3.78-0.42,5.67-0.6
				c1.59-0.15,3.19-0.27,4.78-0.37c0.07,0.15,0.15,0.3,0.23,0.45c-2.2,0.13-4.4,0.33-6.6,0.54C47.37,58.66,45.99,58.82,44.61,58.99" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#CC1249;" d="M185.79,124.39
				c-0.01-0.16,0-0.32,0.02-0.49c1.58-0.11,3.15-0.25,4.73-0.39c2.67-0.25,5.33-0.57,7.97-0.95c-0.45,0.21-0.91,0.41-1.37,0.6
				c-0.12,0.05-0.23,0.09-0.35,0.13c-1.11,0.15-2.23,0.29-3.35,0.42C190.9,124,188.35,124.21,185.79,124.39 M210.68,120.92
				c0.11-0.19,0.22-0.37,0.32-0.56c0.37-0.08,0.75-0.15,1.12-0.23c4.93-1.01,9.84-2.16,14.73-3.34c6.65-1.6,13.28-3.28,19.93-4.85
				v0.51c-5.27,1.25-10.52,2.56-15.78,3.84C224.25,117.93,217.49,119.55,210.68,120.92 M112.79,100.12c-0.95-0.56-1.9-1.1-2.87-1.62
				c-1.26-0.68-2.53-1.32-3.81-1.94c0.1-0.11,0.2-0.23,0.3-0.35c2.23,1.08,4.42,2.25,6.57,3.51
				C112.91,99.85,112.85,99.98,112.79,100.12" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#BD1544;" d="M177.91,124.64
				c-0.9,0-1.8-0.01-2.7-0.02c-4.16-0.07-8.31-0.42-12.42-1.04c-5.16-0.77-10.23-1.95-15.17-3.63c-6.46-2.2-12.58-5.14-18.35-8.8
				c-3.93-2.5-7.62-5.34-11.5-7.91c-1.63-1.09-3.29-2.13-4.98-3.12c0.06-0.13,0.12-0.26,0.18-0.4c2.52,1.48,4.98,3.07,7.39,4.78
				c3.61,2.56,7.18,5.16,10.98,7.42c4.63,2.75,9.47,5.07,14.54,6.92c6.01,2.2,12.21,3.64,18.55,4.48c2.71,0.36,5.44,0.54,8.18,0.7
				c1.42,0.08,2.84,0.09,4.26,0.09c0.24,0,0.49,0,0.73,0c0.25,0,0.5,0,0.74,0c0.4,0,0.81,0,1.21,0c0.16,0,0.33,0,0.49,0
				c1.93,0,3.85-0.09,5.77-0.22c-0.03,0.17-0.04,0.33-0.02,0.49c-0.09,0.01-0.18,0.01-0.27,0.02
				C182.99,124.58,180.45,124.64,177.91,124.64 M196.8,123.29c0.12-0.04,0.23-0.09,0.35-0.13c0.46-0.2,0.92-0.4,1.37-0.6
				c4.18-0.61,8.34-1.36,12.48-2.19c-0.11,0.19-0.21,0.38-0.32,0.56c-0.73,0.15-1.46,0.29-2.19,0.43
				C204.61,122.1,200.71,122.76,196.8,123.29" />
                    <path style="clip-path:url(#SVGID_00000175306332730459919560000007818401800109956772_);fill:#CC1249;" d="M-4.25,99.23v-0.49
				c1.54-0.36,3.08-0.73,4.62-1.1c9.17-2.2,18.33-4.47,27.57-6.38c1.22-0.25,2.44-0.5,3.67-0.73c0.04,0.16,0.08,0.32,0.12,0.47
				c-5.12,0.98-10.22,2.11-15.3,3.3C9.53,95.93,2.64,97.61-4.25,99.23 M98.08,93.19c-1.08-0.39-2.17-0.77-3.27-1.12
				c-1.74-0.56-3.49-1.06-5.25-1.51c0.06-0.15,0.12-0.3,0.19-0.44c2.11,0.54,4.2,1.16,6.27,1.85c0.69,0.23,1.37,0.47,2.05,0.71
				C98.07,92.85,98.07,93.02,98.08,93.19 M40.85,89.43c-0.17-0.14-0.33-0.28-0.49-0.41c2.35-0.36,4.7-0.69,7.06-0.96
				c1.16-0.14,2.33-0.26,3.49-0.37c0.07,0.15,0.13,0.31,0.2,0.46C47.68,88.47,44.26,88.9,40.85,89.43" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M31.72,91.01
			c-0.04-0.16-0.08-0.32-0.12-0.47c2.91-0.56,5.83-1.07,8.76-1.53c0.16,0.14,0.33,0.28,0.49,0.41c-0.44,0.07-0.88,0.14-1.32,0.21
			C36.92,90.05,34.32,90.52,31.72,91.01 M89.56,90.56c-3.69-0.94-7.43-1.66-11.22-2.15c-3.16-0.41-6.33-0.63-9.51-0.79
			c-1.66-0.09-3.31-0.13-4.97-0.13c-2.19,0-4.37,0.07-6.56,0.19c-2.07,0.11-4.14,0.27-6.2,0.46c-0.07-0.16-0.13-0.31-0.2-0.46
			c2.11-0.2,4.22-0.35,6.34-0.44c2.2-0.09,4.4-0.23,6.6-0.23c0.24,0,0.48,0,0.72,0.01c4.73,0.07,9.45,0.34,14.15,0.97
			c3.73,0.5,7.4,1.21,11.03,2.14C89.69,90.26,89.62,90.41,89.56,90.56" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000100365039755454399910000004949757341782935984_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000152224948540038661840000002951563927229713280_">
                        <use xlink:href="#SVGID_00000100365039755454399910000004949757341782935984_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000152224948540038661840000002951563927229713280_);fill:#BD1544;" d="M106.11,96.56
				c-2.62-1.26-5.3-2.38-8.03-3.37c-0.01-0.17-0.01-0.34-0.01-0.5c2.85,1.04,5.63,2.22,8.34,3.53
				C106.31,96.33,106.21,96.45,106.11,96.56" />
                    <path style="clip-path:url(#SVGID_00000152224948540038661840000002951563927229713280_);fill:#EFD1D9;" d="M246.79,25.67v-0.5
				c3.24-0.63,6.49-1.25,9.75-1.8c3.98-0.67,7.97-1.25,11.98-1.69c2.53-0.28,5.06-0.53,7.6-0.72c2.77-0.21,5.54-0.3,8.32-0.3
				c0.83,0,1.67,0.01,2.51,0.02v0.38c-0.09,0-0.18,0-0.28,0c-2.1,0-4.19,0.1-6.28,0.17c-2.78,0.09-5.55,0.32-8.32,0.58
				c-4.19,0.39-8.35,0.93-12.51,1.58C255.28,24.05,251.03,24.83,246.79,25.67 M-42.81,21.08c-0.53,0-1.07,0-1.6-0.01
				c0-0.13,0-0.26,0-0.38c2.74-0.09,5.48-0.11,8.21-0.28c4.59-0.29,9.17-0.74,13.74-1.33c6.1-0.79,12.16-1.79,18.21-2.86v0.45
				c-1.68,0.3-3.36,0.61-5.04,0.91c-4.89,0.87-9.81,1.59-14.74,2.2c-3.26,0.4-6.54,0.72-9.82,0.95
				C-36.84,20.93-39.82,21.08-42.81,21.08" />
                    <path style="clip-path:url(#SVGID_00000152224948540038661840000002951563927229713280_);fill:#CC1249;" d="M183.15,35.51
				c-1.21,0-2.41-0.02-3.62-0.05c-2.67-0.08-5.33-0.27-7.99-0.55c-0.03-0.19-0.07-0.38-0.1-0.56c2.39,0.25,4.79,0.42,7.2,0.52
				c1.57,0.07,3.13,0.1,4.7,0.1c1.72,0,3.45-0.04,5.17-0.11c0.01,0.14,0.02,0.32,0.02,0.54C186.73,35.47,184.94,35.51,183.15,35.51
				 M189.34,35.36c-0.16-0.19-0.32-0.36-0.48-0.52c0.18-0.01,0.36-0.02,0.54-0.02c3.71-0.17,7.41-0.52,11.1-0.96
				c5.17-0.62,10.3-1.48,15.42-2.42c4.62-0.85,9.21-1.82,13.81-2.76c5.16-1.05,10.31-2.18,15.47-3.2c0.53-0.1,1.06-0.21,1.59-0.31
				v0.5c-1.97,0.39-3.93,0.79-5.9,1.2c-5.72,1.2-11.44,2.37-17.16,3.57c-4.51,0.95-9.04,1.8-13.59,2.56
				c-4.25,0.71-8.52,1.3-12.81,1.75C194.67,35.02,192.01,35.23,189.34,35.36 M157.15,32.39c-4.37-1.06-8.67-2.38-12.93-3.87
				c-1.37-0.48-2.74-0.98-4.1-1.48c0-0.18-0.01-0.35-0.01-0.52c2.48,0.92,4.98,1.8,7.5,2.62c3.24,1.05,6.5,1.99,9.79,2.79
				C157.31,32.08,157.23,32.24,157.15,32.39 M139.52,26.82c-2.62-0.98-5.22-2-7.82-3.03c-0.01-0.17-0.03-0.35-0.04-0.53
				c0.17,0.07,0.34,0.14,0.52,0.2c2.5,0.99,5,1.96,7.51,2.9C139.63,26.51,139.57,26.67,139.52,26.82 M126.16,21.58
				c-0.52-0.21-1.04-0.41-1.56-0.62c-1.44-0.57-2.89-1.13-4.34-1.68c-0.14-0.21-0.27-0.42-0.41-0.63c2.17,0.82,4.33,1.66,6.48,2.51
				C126.27,21.3,126.22,21.44,126.16,21.58 M-4.25,16.66v-0.45c0.79-0.14,1.58-0.28,2.36-0.42c5-0.89,9.98-1.88,14.98-2.77
				c5.16-0.92,10.34-1.81,15.51-2.64c4.91-0.79,9.84-1.42,14.78-1.92c4.88-0.49,9.77-0.85,14.68-0.94c1.79-0.03,3.58-0.12,5.37-0.12
				c0.48,0,0.97,0.01,1.45,0.02c3.75,0.12,7.51,0.27,11.25,0.59c5.62,0.48,11.17,1.32,16.69,2.45c5.02,1.03,9.96,2.38,14.85,3.91
				c1.52,0.48,3.03,0.97,4.53,1.49c0.06,0.18,0.12,0.36,0.18,0.54c-5.27-1.81-10.61-3.42-16.06-4.7c-4.8-1.13-9.65-2-14.54-2.63
				c-4.45-0.57-8.91-0.92-13.39-1.07c-2.05-0.07-4.11-0.11-6.16-0.11c-2.54,0-5.07,0.06-7.6,0.19c-6.26,0.33-12.49,0.9-18.7,1.73
				c-7.27,0.97-14.49,2.17-21.7,3.48C8.06,14.41,1.91,15.55-4.25,16.66" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M140.11,27.04
			c-0.2-0.07-0.4-0.15-0.6-0.22c0.05-0.15,0.11-0.3,0.16-0.46c0.14,0.05,0.28,0.1,0.42,0.16C140.11,26.69,140.11,26.86,140.11,27.04
			 M131.69,23.78c-1.85-0.73-3.69-1.47-5.54-2.21c0.06-0.14,0.12-0.28,0.17-0.43c1.78,0.7,3.55,1.4,5.32,2.11
			C131.67,23.43,131.68,23.61,131.69,23.78 M120.26,19.27c-2.61-0.99-5.23-1.95-7.87-2.86c-0.06-0.18-0.12-0.36-0.18-0.54
			c2.57,0.88,5.11,1.81,7.65,2.77C119.98,18.85,120.12,19.06,120.26,19.27" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000157284100832916687800000015278081553260116368_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000063618861369161361130000001200259940318177153_">
                        <use xlink:href="#SVGID_00000157284100832916687800000015278081553260116368_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000063618861369161361130000001200259940318177153_);fill:#BD1544;" d="M188.53,35.4
				c-0.01-0.22-0.02-0.4-0.02-0.54c0.12,0,0.24-0.01,0.36-0.01c0.16,0.16,0.32,0.33,0.48,0.52C189.07,35.37,188.8,35.39,188.53,35.4
				 M171.54,34.91c-1.06-0.11-2.12-0.24-3.17-0.38c-3.79-0.51-7.53-1.23-11.22-2.13c0.08-0.15,0.16-0.31,0.24-0.46
				c3.41,0.83,6.85,1.5,10.34,1.97c1.23,0.17,2.47,0.32,3.7,0.44C171.47,34.53,171.51,34.72,171.54,34.91" />
                    <path style="clip-path:url(#SVGID_00000063618861369161361130000001200259940318177153_);fill:#EFD1D9;" d="M246.79,40.35v-0.52
				c0.4-0.09,0.81-0.17,1.21-0.25c5.63-1.17,11.29-2.22,16.99-3.04c3.09-0.44,6.18-0.82,9.29-1.1c3.76-0.34,7.51-0.61,11.28-0.61
				c0.46,0,0.91,0,1.37,0.01v0.38c-3.81,0.2-7.63,0.26-11.44,0.61c-5.48,0.49-10.93,1.23-16.35,2.15
				C255.01,38.69,250.89,39.49,246.79,40.35 M-44.41,35.22c0-0.17,0-0.34,0-0.51c0.26,0,0.53,0,0.79,0c3.71,0,7.4-0.29,11.09-0.62
				c8.89-0.8,17.69-2.27,26.45-3.94c0.61-0.12,1.22-0.23,1.83-0.35v0.51c-5.68,1.09-11.37,2.11-17.09,2.94
				c-3.78,0.55-7.57,1.03-11.38,1.35c-2.5,0.2-4.99,0.4-7.49,0.52C-41.61,35.2-43.01,35.2-44.41,35.22" />
                    <path style="clip-path:url(#SVGID_00000063618861369161361130000001200259940318177153_);fill:#CC1249;" d="M182.55,50.82
				c-1.17,0-2.33-0.02-3.5-0.05c-2.29-0.07-4.57-0.21-6.85-0.43c0.01-0.19,0.02-0.37,0.04-0.55c0.01,0,0.01,0,0.01,0
				c3.43,0.32,6.86,0.5,10.29,0.5c0.83,0,1.66-0.01,2.5-0.03c0.64-0.02,1.28-0.04,1.92-0.06c-0.06,0.19-0.12,0.37-0.19,0.56
				C185.37,50.8,183.96,50.82,182.55,50.82 M195.28,50.16c-0.03-0.18-0.07-0.36-0.1-0.54c5.7-0.55,11.36-1.4,16.98-2.44
				c3.59-0.66,7.17-1.4,10.74-2.15c4.95-1.05,9.88-2.15,14.82-3.22c3.02-0.66,6.04-1.34,9.06-1.98v0.52
				c-1.22,0.26-2.43,0.52-3.64,0.78c-5.4,1.18-10.79,2.35-16.18,3.55c-6.24,1.39-12.51,2.64-18.81,3.73
				c-4.14,0.72-8.31,1.27-12.49,1.71C195.53,50.14,195.41,50.15,195.28,50.16 M150.18,45.65c-3.87-1.26-7.69-2.72-11.46-4.3
				c-0.46-0.19-0.92-0.38-1.37-0.58c0.05-0.15,0.1-0.3,0.14-0.45c1.54,0.65,3.09,1.29,4.65,1.9c2.72,1.07,5.46,2.07,8.22,2.97
				C150.3,45.34,150.24,45.49,150.18,45.65 M123.12,34.54c-3.43-1.49-6.88-2.94-10.37-4.27c-4.31-1.65-8.68-3.12-13.12-4.38
				c-3.75-1.06-7.54-1.96-11.36-2.68c0.02-0.15,0.04-0.31,0.06-0.46c2.94,0.55,5.86,1.21,8.77,1.97c7.25,1.91,14.28,4.43,21.2,7.29
				c1.29,0.53,2.57,1.08,3.85,1.63C122.48,33.93,122.8,34.24,123.12,34.54 M-4.25,30.32v-0.51c5.81-1.12,11.62-2.27,17.43-3.39
				c7.57-1.46,15.15-2.86,22.8-3.91c3.93-0.54,7.88-1,11.83-1.35c4.36-0.39,8.73-0.56,13.09-0.73c0.73-0.03,1.45-0.04,2.18-0.04
				c2.06,0,4.11,0.09,6.16,0.18c5.44,0.24,10.83,0.78,16.17,1.65c-0.08,0.14-0.16,0.29-0.24,0.43c-3.32-0.53-6.66-0.93-10.01-1.23
				c-4.04-0.37-8.09-0.5-12.14-0.5c-1.56,0-3.11,0.02-4.67,0.05c-2.67,0.06-5.34,0.24-8.01,0.44c-3.26,0.24-6.51,0.55-9.76,0.95
				c-3.6,0.45-7.2,0.95-10.78,1.53c-4.3,0.7-8.59,1.45-12.87,2.26c-6.2,1.18-12.38,2.44-18.57,3.64
				C-2.51,29.99-3.38,30.15-4.25,30.32" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M137.34,40.77
			c-4.76-2.02-9.48-4.16-14.22-6.22c-0.32-0.31-0.64-0.61-0.97-0.91c5.12,2.2,10.19,4.52,15.33,6.68
			C137.44,40.47,137.39,40.62,137.34,40.77" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000098934470424501955220000007898127753819195523_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000148627760425909679950000010337335533639700395_">
                        <use xlink:href="#SVGID_00000098934470424501955220000007898127753819195523_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#BD1544;" d="M186.78,50.75
				c0.06-0.19,0.13-0.37,0.19-0.56c2.58-0.1,5.16-0.28,7.74-0.52c0.16-0.02,0.32-0.03,0.48-0.05c0.04,0.18,0.07,0.36,0.1,0.54
				C192.45,50.45,189.62,50.65,186.78,50.75 M172.2,50.34c-2.85-0.28-5.7-0.68-8.53-1.2c-4.6-0.85-9.09-2.05-13.5-3.49
				c0.06-0.15,0.12-0.31,0.19-0.46c3.63,1.18,7.31,2.19,11.06,2.96c3.57,0.74,7.18,1.28,10.81,1.63
				C172.23,49.97,172.22,50.15,172.2,50.34 M88.27,23.21c-1.02-0.19-2.04-0.37-3.06-0.53c-0.01,0-0.02,0-0.03-0.01
				c0.08-0.15,0.16-0.29,0.24-0.43c0.97,0.16,1.94,0.33,2.91,0.51C88.31,22.9,88.29,23.05,88.27,23.21" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#EFD1D9;" d="M246.79,120.88v-0.5
				c2.83-0.67,5.67-1.32,8.52-1.93c5.94-1.28,11.92-2.38,17.94-3.22c2.98-0.41,5.98-0.75,8.99-0.95c1.4-0.1,2.8-0.24,4.21-0.24
				c0.16,0,0.33,0,0.49,0.01v0.38c-2.17,0.23-4.36,0.29-6.53,0.49c-3.34,0.32-6.67,0.72-9.99,1.22
				C262.46,117.34,254.61,119.04,246.79,120.88 M-44.15,114.18c-0.09,0-0.17,0-0.26-0.01c0-0.17,0-0.34,0-0.51
				c3.27-0.08,6.52-0.42,9.77-0.8c5.68-0.67,11.31-1.63,16.91-2.77c4.51-0.91,9-1.93,13.48-2.98v0.5
				c-4.79,1.13-9.59,2.21-14.42,3.16c-5.36,1.06-10.74,1.97-16.16,2.61c-2.57,0.3-5.14,0.58-7.73,0.69
				C-43.1,114.09-43.62,114.18-44.15,114.18" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#CC1249;" d="M202.22,130.87
				c0.23-0.2,0.46-0.4,0.69-0.6c1.38-0.23,2.75-0.48,4.13-0.74c6.69-1.25,13.31-2.77,19.93-4.35c6.61-1.58,13.21-3.24,19.82-4.8v0.5
				c-1.67,0.39-3.34,0.8-5.01,1.2c-8.63,2.09-17.24,4.29-25.92,6.16C211.32,129.22,206.78,130.12,202.22,130.87 M-4.25,107.61v-0.5
				c0.73-0.17,1.46-0.35,2.19-0.52c11.64-2.77,23.22-5.78,34.98-8.02c0.39-0.07,0.78-0.15,1.17-0.22c0.06,0.15,0.12,0.3,0.18,0.45
				c-1.1,0.2-2.2,0.41-3.3,0.63c-5.78,1.14-11.51,2.47-17.23,3.84C7.74,104.7,1.75,106.19-4.25,107.61 M108.43,106.28
				c-6.59-3.51-13.49-6.11-20.72-7.85c0-0.17,0-0.33,0-0.5c0.7,0.17,1.4,0.34,2.1,0.53c4.55,1.21,8.96,2.78,13.27,4.69
				c1.94,0.86,3.84,1.79,5.7,2.79C108.67,106.06,108.55,106.17,108.43,106.28" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#BD1544;" d="M177.49,133.09
				c-1.25,0-2.5-0.02-3.75-0.07c-7.9-0.28-15.69-1.37-23.3-3.59c-6.72-1.96-13.12-4.68-19.18-8.2c-3.79-2.2-7.36-4.72-10.87-7.35
				c-3.74-2.81-7.68-5.32-11.81-7.53c-0.05-0.02-0.09-0.05-0.14-0.07c0.12-0.11,0.24-0.22,0.35-0.33c2.89,1.55,5.69,3.26,8.39,5.13
				c2.9,2,5.71,4.14,8.59,6.17c3.96,2.79,8.18,5.12,12.56,7.16c5.7,2.65,11.64,4.62,17.8,5.96c3.74,0.81,7.52,1.39,11.32,1.77
				c3.28,0.33,6.56,0.46,9.85,0.46c1.62,0,3.24-0.03,4.86-0.09c6.97-0.25,13.89-1.07,20.76-2.23c-0.23,0.2-0.46,0.4-0.69,0.6
				c-2.08,0.34-4.16,0.65-6.25,0.92c-3.6,0.47-7.21,0.8-10.83,1.04C182.58,133,180.03,133.09,177.49,133.09 M34.28,98.8
				c-0.06-0.15-0.12-0.3-0.18-0.45c4.83-0.9,9.68-1.66,14.57-2.18c3.33-0.35,6.67-0.62,10.02-0.78c1.68-0.08,3.35-0.11,5.03-0.11
				c1.13,0,2.26,0.01,3.38,0.03c3.82,0.06,7.63,0.38,11.41,0.9c3.1,0.43,6.17,0.99,9.21,1.72c0,0.17,0,0.33,0,0.5
				c-0.93-0.22-1.86-0.43-2.8-0.62c-3.75-0.77-7.53-1.27-11.34-1.65c-3.05-0.3-6.1-0.38-9.15-0.38c-1.77,0-3.54,0.03-5.31,0.06
				c-3.13,0.06-6.27,0.37-9.39,0.69C44.55,97.07,39.4,97.86,34.28,98.8" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#EFD1D9;" d="M246.79,129.39v-0.5
				c2.38-0.56,4.77-1.1,7.16-1.62c7.04-1.53,14.11-2.89,21.27-3.76c3.69-0.45,7.38-0.86,11.1-0.94c0.21,0,0.42-0.07,0.62-0.11v0.51
				c-8.13,0.45-16.18,1.49-24.18,3C257.4,126.99,252.09,128.15,246.79,129.39 M-44.19,122.72c-0.07,0-0.15,0-0.22-0.01
				c0-0.17,0-0.34,0-0.51c3.01-0.1,6.01-0.41,9.01-0.74c6.34-0.7,12.61-1.82,18.86-3.1c4.11-0.85,8.21-1.78,12.29-2.76v0.5
				c-2.62,0.62-5.25,1.23-7.88,1.81c-6.54,1.44-13.11,2.71-19.74,3.61c-3.55,0.48-7.12,0.89-10.7,1.07
				C-43.11,122.64-43.65,122.72-44.19,122.72" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#CC1249;" d="M184.86,141.28
				c0.26-0.18,0.53-0.36,0.82-0.54c6.26-0.42,12.46-1.22,18.64-2.29c7.58-1.32,15.09-3,22.57-4.78c6.63-1.58,13.25-3.24,19.89-4.79
				v0.5c-1.13,0.27-2.27,0.53-3.4,0.8c-9.53,2.27-19.02,4.69-28.6,6.73c-5.44,1.16-10.91,2.2-16.42,2.97
				c-3.65,0.51-7.32,0.91-11.01,1.21C186.52,141.18,185.69,141.24,184.86,141.28 M-4.25,116.12v-0.5c1.19-0.28,2.37-0.57,3.56-0.85
				c11.22-2.71,22.39-5.61,33.73-7.78c1.62-0.31,3.25-0.61,4.88-0.88c0.09,0.15,0.18,0.31,0.27,0.46c-3.11,0.52-6.21,1.11-9.3,1.75
				c-4.81,0.99-9.59,2.1-14.36,3.25C8.26,113.05,2.01,114.63-4.25,116.12 M98.84,110.04c-0.69-0.04-1.36-0.12-2-0.26
				c-0.93-0.35-1.87-0.67-2.81-0.99c-0.59-0.3-1.15-0.64-1.68-1.03c1.06,0.32,2.11,0.67,3.16,1.04
				C96.63,109.19,97.74,109.6,98.84,110.04" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#BD1544;" d="M177.41,141.49
				c-1.4,0-2.79-0.02-4.19-0.05c-3.45-0.09-6.9-0.4-10.33-0.88c-4.34-0.61-8.63-1.46-12.84-2.67c-4.56-1.3-8.99-2.98-13.29-4.98
				c-6.75-3.13-12.85-7.28-18.78-11.72c-4.86-3.64-10.11-6.65-15.65-9.15c-1.81-0.82-3.64-1.57-5.49-2.25
				c0.64,0.14,1.31,0.23,2,0.26c3.55,1.42,7,3.08,10.35,4.95c4.43,2.47,8.53,5.42,12.59,8.45c3.55,2.65,7.3,4.98,11.21,7.06
				c4.66,2.49,9.52,4.52,14.55,6.13c5.34,1.7,10.79,2.88,16.36,3.56c4.05,0.49,8.11,0.78,12.19,0.78c0.43,0,0.87,0,1.3,0
				c2.49,0,4.98-0.01,7.47-0.17c0.27-0.02,0.55-0.04,0.82-0.05c-0.29,0.18-0.57,0.36-0.82,0.54
				C182.38,141.43,179.9,141.49,177.41,141.49 M94.03,108.79c-3.36-1.11-6.8-2.02-10.29-2.72c-3.52-0.71-7.08-1.16-10.65-1.49
				c-3.05-0.29-6.11-0.39-9.16-0.39c-1.49,0-2.97,0.02-4.46,0.06c-3.96,0.1-7.91,0.47-11.86,0.94c-3.16,0.38-6.3,0.83-9.43,1.36
				c-0.09-0.15-0.18-0.31-0.27-0.46c3.02-0.51,6.05-0.97,9.09-1.33c3.07-0.37,6.16-0.64,9.25-0.83c2.58-0.16,5.17-0.25,7.75-0.25
				c1.09,0,2.18,0.02,3.27,0.05c4.18,0.12,8.34,0.51,12.48,1.14c4.27,0.66,8.47,1.62,12.6,2.88
				C92.88,108.15,93.44,108.5,94.03,108.79" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#EFD1D9;" d="M246.79,46.16v-0.52
				c4.17-0.88,8.36-1.72,12.56-2.46c4.12-0.73,8.26-1.34,12.42-1.81c2.7-0.3,5.4-0.56,8.11-0.7c2.1-0.11,4.19-0.23,6.29-0.23
				c0.26,0,0.52,0,0.77,0.01v0.38c-0.36,0.11-0.72,0.13-1.09,0.13c-0.06,0-0.11,0-0.17,0c-0.13,0-0.27,0-0.4,0
				c-3.5,0-6.98,0.3-10.46,0.62c-7.52,0.69-14.95,1.94-22.35,3.41C250.57,45.37,248.67,45.76,246.79,46.16 M-43.18,40.86
				c-0.41,0-0.82-0.01-1.23-0.02c0-0.17,0-0.34,0-0.51c0.22,0,0.44,0,0.66,0c3.09,0,6.17-0.25,9.25-0.5
				c5.61-0.46,11.18-1.24,16.73-2.14c4.52-0.73,9.02-1.57,13.51-2.46v0.5c-2.64,0.51-5.28,1.01-7.92,1.49
				c-5.1,0.92-10.22,1.74-15.36,2.37c-3.18,0.39-6.37,0.72-9.56,0.95C-39.12,40.7-41.15,40.86-43.18,40.86" />
                    <path style="clip-path:url(#SVGID_00000148627760425909679950000010337335533639700395_);fill:#CC1249;" d="M182.07,56.85
				c-1.56,0-3.12-0.03-4.68-0.09c-1.94-0.07-3.87-0.21-5.8-0.4c0.02-0.19,0.05-0.38,0.07-0.57c1.71,0.17,3.43,0.29,5.16,0.38
				c1.79,0.09,3.59,0.13,5.38,0.13c0.75,0,1.51-0.01,2.26-0.02c-0.09,0.18-0.18,0.37-0.28,0.55
				C183.48,56.84,182.78,56.85,182.07,56.85 M195.9,56.08c-0.01-0.17-0.01-0.35-0.02-0.52c0.81-0.09,1.62-0.18,2.42-0.28
				c4.77-0.58,9.51-1.32,14.22-2.21c4.36-0.83,8.71-1.73,13.03-2.72c4.51-1.03,9.03-2.04,13.55-3.04c2.55-0.56,5.11-1.12,7.67-1.67
				v0.52c-1.83,0.39-3.66,0.79-5.48,1.2c-5.36,1.22-10.74,2.42-16.11,3.61c-5.02,1.11-10.05,2.16-15.11,3.06
				C205.38,54.87,200.66,55.57,195.9,56.08 M148.29,51.04c-3.16-1.11-6.27-2.35-9.35-3.68c-1.15-0.49-2.29-1-3.42-1.51
				c0.06-0.15,0.12-0.29,0.17-0.44c3.76,1.67,7.55,3.27,11.43,4.68c0.44,0.16,0.88,0.32,1.32,0.47
				C148.38,50.73,148.34,50.89,148.29,51.04 M129.99,43.34c-1.29-0.6-2.58-1.19-3.87-1.79c-7.78-3.59-15.63-6.99-23.82-9.56
				c-4.77-1.5-9.6-2.73-14.5-3.65c0.01-0.15,0.01-0.3,0.02-0.46c2.86,0.54,5.71,1.18,8.53,1.92c6.63,1.74,13.07,4.03,19.39,6.67
				c3.82,1.6,7.59,3.3,11.36,5.03c0.83,0.38,1.65,0.76,2.48,1.14C129.72,42.88,129.85,43.11,129.99,43.34 M-4.25,35.74v-0.5
				c1.43-0.28,2.85-0.56,4.27-0.85c5.77-1.16,11.54-2.27,17.31-3.42c6.81-1.36,13.66-2.53,20.54-3.48c3.74-0.51,7.5-0.91,11.26-1.22
				c4.81-0.4,9.62-0.62,14.44-0.62c0.62,0,1.23,0,1.85,0.01c1.84,0.02,3.69,0.09,5.53,0.2c-0.1,0.15-0.19,0.31-0.29,0.46
				c-0.32-0.02-0.64-0.03-0.97-0.05c-2.29-0.11-4.57-0.16-6.86-0.16c-1.81,0-3.62,0.03-5.43,0.1c-3.86,0.14-7.71,0.44-11.56,0.82
				c-7.19,0.7-14.32,1.82-21.43,3.07c-5.61,0.99-11.19,2.1-16.77,3.24C3.68,34.15-0.28,34.96-4.25,35.74 M83.02,27.54
				c-0.6-0.09-1.19-0.17-1.79-0.25c-2.83-0.37-5.66-0.65-8.5-0.84c0.04-0.16,0.07-0.31,0.11-0.47c1.17,0.08,2.33,0.18,3.5,0.29
				c2.29,0.21,4.56,0.49,6.83,0.82C83.13,27.24,83.08,27.39,83.02,27.54" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M135.52,45.86
			c-1.85-0.83-3.69-1.67-5.53-2.52c-0.14-0.23-0.27-0.46-0.41-0.69c2.03,0.93,4.07,1.86,6.11,2.77
			C135.63,45.57,135.57,45.72,135.52,45.86" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000055683795254373896820000015521951219583353504_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000143592315607848479280000017195188466484344977_">
                        <use xlink:href="#SVGID_00000055683795254373896820000015521951219583353504_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000143592315607848479280000017195188466484344977_);fill:#BD1544;" d="M184.19,56.83
				c0.09-0.18,0.19-0.36,0.28-0.55c0.75-0.01,1.49-0.03,2.24-0.05c3.07-0.09,6.13-0.34,9.18-0.67c0.01,0.17,0.01,0.35,0.02,0.52
				c-0.27,0.03-0.55,0.06-0.82,0.09C191.46,56.54,187.82,56.77,184.19,56.83 M171.6,56.35c-3.83-0.39-7.64-1.01-11.42-1.86
				c-4.04-0.91-8-2.08-11.89-3.45c0.05-0.16,0.09-0.31,0.14-0.47c6.09,2.13,12.31,3.74,18.71,4.67c1.51,0.22,3.02,0.4,4.53,0.54
				C171.65,55.98,171.63,56.17,171.6,56.35 M87.79,28.34c-1.58-0.3-3.17-0.56-4.76-0.8c0.05-0.15,0.1-0.3,0.16-0.44
				c1.55,0.23,3.09,0.49,4.63,0.78C87.8,28.03,87.8,28.19,87.79,28.34 M72.74,26.45c-0.69-0.05-1.38-0.09-2.07-0.13
				c0.1-0.15,0.19-0.31,0.29-0.46c0.63,0.04,1.27,0.08,1.9,0.12C72.81,26.14,72.77,26.3,72.74,26.45" />
                    <path style="clip-path:url(#SVGID_00000143592315607848479280000017195188466484344977_);fill:#EFD1D9;" d="M246.79,63.62v-0.51
				c3.83-0.86,7.67-1.67,11.52-2.4c4.72-0.9,9.46-1.66,14.22-2.24c2.99-0.37,5.99-0.68,9-0.82c1.67-0.08,3.35-0.23,5.02-0.23
				c0.13,0,0.25,0,0.38,0v0.51c-0.01,0-0.03,0-0.04,0c-3.45,0-6.88,0.3-10.31,0.61c-7.1,0.65-14.1,1.84-21.08,3.23
				C252.58,62.35,249.68,62.97,246.79,63.62 M-44.41,57.8c0-0.17,0-0.34,0-0.51c2.69-0.07,5.38-0.26,8.07-0.48
				c8.31-0.68,16.51-2.08,24.69-3.65c2.47-0.47,4.94-0.97,7.4-1.49v0.47c-3.38,0.7-6.77,1.39-10.16,2.02
				c-4.43,0.82-8.87,1.59-13.33,2.2c-3.45,0.47-6.91,0.86-10.38,1.09C-40.22,57.58-42.31,57.72-44.41,57.8" />
                    <path style="clip-path:url(#SVGID_00000143592315607848479280000017195188466484344977_);fill:#CC1249;" d="M193.1,74.33
				c0.05-0.17,0.1-0.34,0.15-0.52c1.2-0.12,2.41-0.26,3.61-0.41c4.47-0.55,8.92-1.24,13.34-2.09c3.26-0.62,6.5-1.29,9.74-2
				c7.82-1.73,15.61-3.63,23.41-5.43c1.14-0.26,2.29-0.52,3.44-0.78v0.51c-2.85,0.64-5.69,1.3-8.52,1.98
				c-5.31,1.26-10.62,2.53-15.95,3.72c-5.53,1.25-11.08,2.42-16.68,3.34C201.47,73.35,197.29,73.92,193.1,74.33 M145.87,68.33
				c-2.2-0.87-4.37-1.81-6.52-2.82c-2.84-1.33-5.65-2.73-8.44-4.16c-0.06-0.21-0.13-0.42-0.19-0.63c2.25,1.15,4.5,2.29,6.77,3.4
				c2.76,1.34,5.55,2.58,8.38,3.71C145.87,67.99,145.87,68.16,145.87,68.33 M118.7,55.09c-6.45-3.17-13.06-5.97-19.94-8.11
				c-2.34-0.73-4.69-1.38-7.07-1.95c-0.08-0.17-0.17-0.35-0.25-0.52c2.4,0.57,4.78,1.22,7.15,1.96c5.28,1.65,10.42,3.65,15.46,5.93
				c1.45,0.66,2.9,1.34,4.33,2.04C118.49,54.65,118.6,54.87,118.7,55.09 M-4.25,52.14v-0.47c2.03-0.42,4.05-0.86,6.08-1.3
				C7.13,49.2,12.46,48.11,17.78,47c5.41-1.13,10.83-2.17,16.29-3.02c4.49-0.7,8.98-1.36,13.51-1.76c0.85-0.08,1.7-0.15,2.55-0.22
				c-0.11,0.17-0.22,0.33-0.32,0.48c-2.74,0.24-5.47,0.53-8.19,0.87c-6.56,0.83-13.06,1.98-19.54,3.25
				c-6.73,1.32-13.43,2.8-20.14,4.23C-0.12,51.27-2.19,51.71-4.25,52.14 M81.23,43.05c-0.08-0.01-0.16-0.02-0.25-0.03
				c-2.72-0.38-5.45-0.65-8.18-0.84c-0.03-0.17-0.06-0.33-0.09-0.5c2.84,0.2,5.66,0.5,8.46,0.9C81.19,42.73,81.21,42.89,81.23,43.05
				 M52.81,42.23c0.03-0.15,0.06-0.29,0.1-0.44c1.13-0.08,2.26-0.15,3.39-0.22c2.46-0.15,4.93-0.22,7.39-0.22
				c0.4,0,0.79,0,1.19,0.01c-0.03,0.17-0.07,0.34-0.1,0.51c-0.36,0-0.72,0-1.09,0c-1.11,0-2.22,0.01-3.34,0.02
				C57.83,41.91,55.32,42.05,52.81,42.23" />
                    <path style="clip-path:url(#SVGID_00000143592315607848479280000017195188466484344977_);fill:#BD1544;" d="M180.59,74.94
				c-0.08,0-0.16,0-0.24,0c-8.93-0.01-17.7-1.19-26.27-3.75c-2.79-0.83-5.52-1.79-8.22-2.86c0-0.17,0.01-0.34,0.01-0.51
				c5.69,2.25,11.54,4.01,17.62,5.09c3.26,0.58,6.55,0.96,9.85,1.23c2.43,0.2,4.86,0.26,7.28,0.26c1.61,0,3.21-0.03,4.82-0.06
				c2.61-0.06,5.21-0.26,7.8-0.52c-0.05,0.17-0.1,0.34-0.15,0.52c-0.26,0.03-0.53,0.05-0.79,0.08
				C188.41,74.78,184.5,74.94,180.59,74.94 M130.91,61.35c-2.75-1.41-5.49-2.84-8.23-4.26c-1.32-0.68-2.64-1.35-3.98-2
				c-0.1-0.21-0.21-0.43-0.32-0.65c2.43,1.18,4.83,2.41,7.23,3.65c1.7,0.88,3.4,1.76,5.11,2.64
				C130.78,60.93,130.85,61.14,130.91,61.35 M91.69,45.03c-3.45-0.83-6.94-1.48-10.46-1.98c-0.02-0.16-0.04-0.32-0.06-0.47
				c3.45,0.49,6.87,1.13,10.27,1.93C91.53,44.68,91.61,44.86,91.69,45.03 M49.82,42.48c0.1-0.15,0.2-0.31,0.32-0.48
				c0.92-0.07,1.84-0.14,2.76-0.21c-0.03,0.15-0.07,0.29-0.1,0.44c-0.5,0.04-0.99,0.08-1.49,0.12
				C50.82,42.39,50.32,42.43,49.82,42.48 M72.81,42.18c-1.28-0.09-2.57-0.16-3.86-0.22c-1.39-0.06-2.79-0.09-4.18-0.1
				c0.03-0.17,0.06-0.34,0.1-0.51c1.6,0.02,3.2,0.06,4.8,0.14c1.02,0.05,2.04,0.11,3.05,0.18C72.74,41.84,72.77,42.01,72.81,42.18" />
                    <path style="clip-path:url(#SVGID_00000143592315607848479280000017195188466484344977_);fill:#EFD1D9;" d="M246.79,83.85v-0.52
				c6.89-1.59,13.8-3.08,20.78-4.18c3.48-0.55,6.97-0.99,10.48-1.35c2.96-0.3,5.92-0.47,8.88-0.62v0.51
				c-3.78,0.14-7.54,0.44-11.29,0.86C265.9,79.65,256.32,81.61,246.79,83.85 M-44.41,77.57c0-0.17,0-0.34,0-0.51
				c3.07-0.24,6.13-0.41,9.2-0.73c6.47-0.67,12.88-1.73,19.26-2.97c3.91-0.76,7.81-1.59,11.7-2.46v0.48
				c-5.84,1.3-11.7,2.52-17.6,3.54c-4.44,0.77-8.89,1.43-13.37,1.89c-2.87,0.29-5.74,0.58-8.63,0.64
				C-44.04,77.46-44.23,77.53-44.41,77.57" />
                    <path style="clip-path:url(#SVGID_00000143592315607848479280000017195188466484344977_);fill:#CC1249;" d="M185.28,95.59
				c0.03-0.17,0.07-0.35,0.11-0.52c3.23-0.16,6.45-0.44,9.66-0.83c-0.26,0.2-0.52,0.39-0.78,0.58c-1.22,0.14-2.45,0.26-3.67,0.38
				C188.82,95.37,187.05,95.5,185.28,95.59 M210.5,92.26c0.04-0.18,0.08-0.37,0.12-0.55c3-0.59,6-1.23,8.99-1.9
				c8.07-1.8,16.07-3.88,24.12-5.77c1.02-0.24,2.04-0.48,3.07-0.71v0.52c-0.03,0.01-0.06,0.01-0.09,0.02
				c-6.62,1.56-13.22,3.18-19.84,4.76C221.42,89.93,215.98,91.18,210.5,92.26 M147.39,90.12c-0.1-0.04-0.2-0.08-0.31-0.12
				c-4.22-1.63-8.28-3.58-12.24-5.72c-0.01-0.18-0.03-0.36-0.05-0.53c1.78,0.97,3.57,1.9,5.4,2.77c2.34,1.12,4.72,2.14,7.14,3.05
				C147.35,89.75,147.37,89.93,147.39,90.12 M114.68,72.74c-0.16-0.08-0.32-0.17-0.48-0.25c-2.46-1.27-4.95-2.46-7.48-3.56
				c-0.01-0.04-0.02-0.05-0.03-0.05c0,0.01,0,0.03,0,0.04c-2.05-0.89-4.13-1.72-6.25-2.48c-0.89-0.32-1.79-0.63-2.69-0.92
				c0.01-0.16,0.02-0.31,0.02-0.47c1.56,0.51,3.1,1.05,4.64,1.63c4.15,1.58,8.18,3.44,12.12,5.48
				C114.59,72.36,114.64,72.55,114.68,72.74 M-4.25,71.38V70.9c2.81-0.62,5.61-1.26,8.42-1.9c6.23-1.42,12.48-2.81,18.73-4.13
				c6.57-1.39,13.18-2.56,19.83-3.45c0.54-0.07,1.09-0.14,1.64-0.21c-0.01,0.16-0.03,0.33-0.04,0.49c-4.83,0.6-9.64,1.39-14.43,2.27
				c-3.34,0.62-6.67,1.31-9.99,2.03c-7.47,1.64-14.93,3.31-22.4,4.99C-3.08,71.12-3.67,71.25-4.25,71.38 M56.87,60.57
				c-0.1-0.15-0.2-0.31-0.3-0.47c2.43-0.12,4.85-0.16,7.28-0.16c0.28,0,0.56,0,0.85,0c0.02,0.14,0.05,0.28,0.07,0.42
				c-0.26,0-0.52,0-0.79,0c-0.57,0-1.14,0-1.72,0.01C60.47,60.41,58.67,60.47,56.87,60.57" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M179.59,95.73
			c-1.34,0-2.68-0.02-4.02-0.07c-9.68-0.35-19.11-2.08-28.19-5.54c-0.02-0.18-0.04-0.37-0.06-0.55c3.93,1.49,7.96,2.69,12.1,3.58
			c3.93,0.85,7.9,1.4,11.9,1.74c2.67,0.23,5.35,0.33,8.03,0.33c1.57,0,3.14-0.03,4.7-0.1c0.44-0.02,0.88-0.04,1.33-0.06
			c-0.04,0.17-0.08,0.34-0.11,0.52C183.38,95.68,181.49,95.73,179.59,95.73 M194.26,94.82c0.26-0.19,0.53-0.39,0.78-0.58
			c1.7-0.2,3.4-0.43,5.1-0.69c3.51-0.53,6.99-1.15,10.47-1.84c-0.04,0.18-0.08,0.37-0.12,0.55c-2.69,0.53-5.39,1.02-8.09,1.45
			C199.7,94.15,196.98,94.52,194.26,94.82 M134.84,84.28c-1.23-0.66-2.45-1.35-3.66-2.05c-5.49-3.17-10.87-6.54-16.49-9.49
			c-0.05-0.19-0.1-0.39-0.14-0.58c0.19,0.1,0.39,0.2,0.58,0.3c3.48,1.83,6.87,3.82,10.24,5.83c3.12,1.85,6.24,3.71,9.42,5.45
			C134.81,83.92,134.82,84.1,134.84,84.28 M97.76,65.52c-4.01-1.31-8.09-2.36-12.24-3.16c-3.61-0.69-7.25-1.19-10.91-1.51
			c-3.28-0.29-6.56-0.46-9.84-0.49c-0.03-0.14-0.05-0.29-0.07-0.42c1.21,0.01,2.41,0.02,3.62,0.04c3.12,0.06,6.23,0.33,9.33,0.7
			c4.44,0.52,8.83,1.28,13.16,2.36c2.35,0.59,4.68,1.25,6.98,2C97.78,65.21,97.77,65.36,97.76,65.52 M44.32,61.69
			c0.01-0.16,0.03-0.33,0.04-0.49c2.4-0.29,4.81-0.53,7.22-0.75c1.66-0.16,3.32-0.27,4.99-0.35c0.1,0.16,0.2,0.31,0.3,0.47
			c-1.36,0.07-2.72,0.16-4.07,0.26C49.96,61.05,47.14,61.34,44.32,61.69" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000164477844569256863910000004052137439253858983_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000004515275446676779000000011789502444937922989_">
                        <use xlink:href="#SVGID_00000164477844569256863910000004052137439253858983_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000004515275446676779000000011789502444937922989_);fill:#BD1544;" d="M106.73,68.93
				c-0.01,0-0.02-0.01-0.03-0.01c0-0.01,0-0.03,0-0.04C106.7,68.88,106.71,68.9,106.73,68.93" />
                    <path style="clip-path:url(#SVGID_00000004515275446676779000000011789502444937922989_);fill:#EFD1D9;" d="M246.79,37.44v-0.51
				c0.25-0.05,0.51-0.11,0.76-0.16c5.76-1.19,11.55-2.26,17.37-3.09c3.11-0.44,6.23-0.83,9.36-1.11c2.13-0.19,4.27-0.34,6.41-0.45
				c1.74-0.09,3.49-0.1,5.23-0.1c0.34,0,0.67,0,1.01,0v0.38c-3.18,0.13-6.36,0.15-9.54,0.4c-4.06,0.32-8.11,0.77-12.14,1.34
				C259.05,35.03,252.91,36.17,246.79,37.44 M-42.11,32.44c-0.37,0-0.74,0-1.11-0.01c-0.37,0-0.74-0.01-1.1-0.01
				c-0.03,0-0.06,0-0.1,0c0-0.13,0-0.25,0-0.38c0.25-0.12,0.51-0.13,0.77-0.13c0.04,0,0.07,0,0.11,0c0.04,0,0.08,0,0.12,0
				c4.61,0,9.2-0.4,13.78-0.86c7.36-0.73,14.65-1.97,21.92-3.27c1.15-0.21,2.3-0.42,3.46-0.64v0.47c-2.34,0.43-4.69,0.83-7.03,1.27
				c-3.43,0.64-6.87,1.17-10.31,1.66c-3.09,0.44-6.18,0.82-9.29,1.14c-3.23,0.33-6.47,0.53-9.71,0.72
				C-41.09,32.43-41.6,32.44-42.11,32.44" />
                    <path style="clip-path:url(#SVGID_00000004515275446676779000000011789502444937922989_);fill:#CC1249;" d="M183.26,47.75
				c-0.05,0-0.1,0-0.15,0c-3.59-0.01-7.17-0.12-10.75-0.47c0.01-0.18,0.01-0.35,0.01-0.53c1.61,0.14,3.23,0.25,4.85,0.34
				c1.73,0.1,3.46,0.14,5.19,0.14c1.42,0,2.83-0.02,4.24-0.06c0.4-0.01,0.79-0.02,1.18-0.04c-0.04,0.17-0.09,0.35-0.13,0.52
				C186.22,47.72,184.74,47.75,183.26,47.75 M194.61,47.2c-0.05-0.17-0.09-0.34-0.14-0.51c1.03-0.1,2.07-0.21,3.1-0.32
				c5.24-0.58,10.44-1.38,15.62-2.35c2.99-0.55,5.96-1.15,8.93-1.78c5.2-1.1,10.38-2.23,15.57-3.35c3.03-0.65,6.05-1.31,9.09-1.95
				v0.51c-1.99,0.41-3.97,0.84-5.96,1.27c-5.08,1.11-10.16,2.23-15.25,3.33c-4.69,1.01-9.39,1.98-14.12,2.82
				c-4.45,0.79-8.93,1.47-13.42,1.98C196.89,46.97,195.75,47.09,194.61,47.2 M151.32,42.99c-4.48-1.4-8.85-3.06-13.18-4.84
				c0.04-0.15,0.09-0.3,0.13-0.46c3.78,1.55,7.59,3.01,11.48,4.28c0.59,0.19,1.19,0.38,1.78,0.57
				C151.46,42.69,151.39,42.84,151.32,42.99 M114.75,28.3c-0.71-0.28-1.42-0.55-2.13-0.81c-6.61-2.48-13.35-4.56-20.25-6.07
				c-1.25-0.27-2.5-0.53-3.75-0.76c0.02-0.15,0.04-0.3,0.06-0.45c0.8,0.15,1.59,0.31,2.38,0.47c4.62,0.96,9.18,2.18,13.68,3.61
				c3.38,1.07,6.7,2.27,10,3.54C114.74,27.99,114.75,28.14,114.75,28.3 M-4.25,27.61v-0.47c4.13-0.78,8.25-1.61,12.38-2.41
				c6.59-1.27,13.18-2.56,19.81-3.63c5.14-0.83,10.29-1.58,15.47-2.11c3.8-0.39,7.61-0.69,11.43-0.9c2.79-0.15,5.58-0.24,8.37-0.24
				c1.09,0,2.19,0.01,3.28,0.04c3.26,0.09,6.53,0.27,9.78,0.57c3.57,0.34,7.13,0.8,10.66,1.41c-0.1,0.13-0.19,0.27-0.29,0.41
				c-2.83-0.48-5.68-0.87-8.54-1.17c-4.48-0.46-8.97-0.73-13.47-0.76c-0.89-0.01-1.78-0.01-2.67-0.01c-1.53,0-3.06,0.01-4.59,0.07
				c-3.01,0.1-6.02,0.31-9.02,0.56c-3.73,0.31-7.44,0.72-11.14,1.21c-3.64,0.48-7.27,1.06-10.9,1.66c-5.47,0.91-10.91,1.96-16.36,3
				c-4.09,0.78-8.17,1.61-12.26,2.39C-2.96,27.37-3.61,27.49-4.25,27.61" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M138.14,38.15
			c-1.47-0.61-2.94-1.23-4.41-1.85c-6.31-2.71-12.58-5.51-18.98-8c0-0.16-0.01-0.32-0.02-0.47c4.33,1.68,8.61,3.49,12.87,5.33
			c3.55,1.53,7.09,3.07,10.66,4.54C138.23,37.85,138.18,38,138.14,38.15" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000127743153740649410000000002943355226174214843_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000153687495232421651650000008371631295072275382_">
                        <use xlink:href="#SVGID_00000127743153740649410000000002943355226174214843_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000153687495232421651650000008371631295072275382_);fill:#BD1544;" d="M187.7,47.66
				c0.04-0.17,0.09-0.35,0.13-0.52c2.21-0.08,4.42-0.25,6.63-0.46c0.05,0.17,0.1,0.34,0.14,0.51
				C192.31,47.41,190.01,47.57,187.7,47.66 M172.36,47.29c-0.13-0.01-0.26-0.02-0.38-0.04c-6.89-0.69-13.63-2.09-20.25-4.13
				c-0.14-0.04-0.27-0.08-0.4-0.13c0.07-0.15,0.14-0.3,0.21-0.45c5.42,1.67,10.94,2.95,16.56,3.73c1.42,0.2,2.85,0.35,4.28,0.48
				C172.37,46.93,172.36,47.11,172.36,47.29 M88.62,20.65c-0.66-0.12-1.32-0.24-1.98-0.35c0.09-0.14,0.19-0.27,0.29-0.41
				c0.59,0.1,1.17,0.21,1.76,0.32C88.66,20.35,88.64,20.5,88.62,20.65" />
                    <path style="clip-path:url(#SVGID_00000153687495232421651650000008371631295072275382_);fill:#EFD1D9;" d="M246.79,19.84v-0.51
				c4.69-0.94,9.4-1.74,14.13-2.42c3.46-0.5,6.94-0.91,10.43-1.24c4.36-0.41,8.73-0.62,13.11-0.62c0.82,0,1.65,0.01,2.47,0.02v0.38
				c-2.55,0.05-5.1,0.03-7.64,0.15c-2.71,0.13-5.42,0.34-8.12,0.59c-4.48,0.42-8.94,1-13.39,1.7
				C254.1,18.48,250.44,19.14,246.79,19.84 M-44.41,15.46c0-0.13,0-0.25,0-0.38c2.08,0,4.16-0.09,6.24-0.17
				c2.88-0.1,5.76-0.3,8.63-0.58c3.44-0.34,6.88-0.7,10.31-1.17c4.8-0.65,9.58-1.41,14.35-2.24c0.21-0.04,0.41-0.07,0.62-0.11v0.47
				c-3.74,0.65-7.49,1.27-11.25,1.84c-4.28,0.64-8.58,1.2-12.89,1.59c-3.04,0.27-6.09,0.49-9.14,0.65
				C-39.83,15.48-42.12,15.41-44.41,15.46" />
                    <path style="clip-path:url(#SVGID_00000153687495232421651650000008371631295072275382_);fill:#CC1249;" d="M183.24,29.38
				c-1.35,0-2.69-0.02-4.04-0.05c-2.65-0.06-5.29-0.3-7.93-0.57c-0.45-0.05-0.9-0.1-1.35-0.15c-0.07-0.19-0.13-0.38-0.2-0.57
				c2.88,0.34,5.76,0.57,8.65,0.69c1.71,0.07,3.41,0.11,5.11,0.11c2.63,0,5.25-0.08,7.88-0.23c7.96-0.45,15.84-1.58,23.68-2.99
				c5.65-1.01,11.27-2.13,16.88-3.32c4.88-1.03,9.79-1.95,14.68-2.93c0.06-0.01,0.12-0.02,0.18-0.04v0.51
				c-1.98,0.38-3.95,0.77-5.93,1.16c-6.5,1.29-12.97,2.7-19.48,3.95c-4.86,0.93-9.72,1.84-14.62,2.57
				c-3.36,0.5-6.74,0.88-10.12,1.23C192.18,29.2,187.71,29.38,183.24,29.38 M159.5,26.87c-3.8-0.81-7.54-1.84-11.25-2.99
				c-5.95-1.85-11.78-4.04-17.59-6.25c-0.05-0.19-0.1-0.38-0.16-0.57c0.88,0.33,1.75,0.67,2.63,1c7.4,2.8,14.84,5.46,22.52,7.38
				c1.33,0.33,2.66,0.64,3.99,0.93C159.59,26.53,159.55,26.7,159.5,26.87 M127.28,16.34c-0.27-0.1-0.54-0.2-0.8-0.31
				c-3.95-1.5-7.92-2.97-11.92-4.33c-0.23-0.25-0.47-0.49-0.71-0.72c4.52,1.53,9,3.19,13.47,4.88
				C127.31,16.02,127.3,16.18,127.28,16.34 M-4.25,11.28v-0.47C3.76,9.4,11.77,7.95,19.79,6.56c4.6-0.8,9.22-1.5,13.86-2.12
				c3.87-0.51,7.75-1,11.64-1.34c3.7-0.32,7.41-0.54,11.12-0.7c1.9-0.08,3.8-0.1,5.69-0.1c1.33,0,2.66,0.01,3.99,0.02
				c3.12,0.02,6.24,0.22,9.35,0.46c6.06,0.48,12.07,1.34,18.02,2.57c5.14,1.06,10.21,2.4,15.22,3.95c0.17,0.21,0.33,0.41,0.48,0.63
				c-1.54-0.48-3.09-0.95-4.65-1.39c-6.96-1.97-14.01-3.5-21.19-4.45c-2.95-0.39-5.9-0.69-8.86-0.91
				c-3.78-0.29-7.56-0.45-11.35-0.45c-0.18,0-0.37,0-0.55,0c-3.86,0.02-7.73,0.1-11.59,0.38c-6.25,0.45-12.47,1.12-18.68,1.98
				C23.87,6.26,15.49,7.75,7.1,9.26C3.32,9.94-0.47,10.63-4.25,11.28" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M130.65,17.63
			c-1.12-0.43-2.25-0.86-3.37-1.28c0.01-0.16,0.02-0.33,0.03-0.49c1.06,0.4,2.12,0.8,3.18,1.21
			C130.55,17.25,130.6,17.44,130.65,17.63 M114.56,11.7c-1.79-0.61-3.58-1.2-5.39-1.77c-0.15-0.21-0.31-0.42-0.48-0.63
			c0.43,0.13,0.86,0.27,1.29,0.4c1.29,0.41,2.58,0.83,3.87,1.26C114.09,11.22,114.33,11.46,114.56,11.7" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000005236064201670591230000010305129373131650208_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000079483506709241683160000014273464176298189502_">
                        <use xlink:href="#SVGID_00000005236064201670591230000010305129373131650208_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#BD1544;" d="M169.92,28.61
				c-3.18-0.38-6.33-0.91-9.46-1.54c-0.32-0.06-0.64-0.13-0.96-0.2c0.05-0.17,0.1-0.34,0.15-0.51c3.13,0.67,6.28,1.21,9.47,1.6
				c0.2,0.03,0.41,0.05,0.61,0.07C169.78,28.23,169.85,28.42,169.92,28.61" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#EFD1D9;" d="M246.79,75.24v-0.51
				c3.36-0.77,6.73-1.51,10.11-2.19c4.48-0.9,8.97-1.72,13.5-2.33c3.32-0.45,6.65-0.87,10-1.09c2.18-0.14,4.36-0.31,6.54-0.36v0.51
				c-3.2,0.04-6.4,0.31-9.59,0.62c-7.2,0.7-14.31,1.9-21.39,3.35C252.89,73.87,249.84,74.55,246.79,75.24 M-44.41,69.15
				c0-0.17,0-0.34,0-0.51c3.64-0.21,7.28-0.45,10.91-0.86c7.01-0.79,13.96-1.97,20.88-3.35c2.79-0.56,5.58-1.15,8.37-1.75v0.49
				c-2.29,0.49-4.58,0.97-6.88,1.44c-5.27,1.06-10.55,2.06-15.87,2.83c-3.49,0.5-6.98,0.92-10.49,1.23
				C-39.8,68.87-42.11,68.95-44.41,69.15" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#CC1249;" d="M188.74,86.6
				c0.08-0.17,0.15-0.34,0.23-0.52c1.2-0.09,2.41-0.21,3.61-0.32c3.99-0.39,7.95-0.94,11.9-1.6c-0.14,0.2-0.27,0.4-0.41,0.6
				c-2.11,0.35-4.23,0.67-6.35,0.94C194.73,86.07,191.74,86.37,188.74,86.6 M211.34,83.41c0-0.17-0.01-0.34-0.01-0.52
				c0.4-0.08,0.79-0.16,1.19-0.24c6.12-1.24,12.2-2.66,18.28-4.12c5.32-1.28,10.65-2.58,15.99-3.8v0.51
				c-2.31,0.53-4.62,1.07-6.93,1.63c-9.21,2.21-18.41,4.5-27.7,6.38C211.88,83.3,211.61,83.36,211.34,83.41 M146.38,80.71
				c-3.88-1.53-7.65-3.3-11.33-5.27c-0.42-0.22-0.84-0.45-1.25-0.67c-0.03-0.18-0.05-0.37-0.08-0.55c1.98,1.06,3.97,2.08,5.99,3.05
				c2.18,1.05,4.39,2.01,6.63,2.9C146.35,80.35,146.36,80.53,146.38,80.71 M-4.25,63.17v-0.49c2.99-0.65,5.97-1.31,8.95-1.97
				c6.72-1.49,13.44-2.99,20.19-4.32c6.07-1.19,12.16-2.24,18.29-3.02c0.87-0.11,1.73-0.21,2.6-0.31c-0.04,0.17-0.09,0.34-0.13,0.51
				c-1.74,0.2-3.47,0.41-5.2,0.64c-4.27,0.58-8.52,1.33-12.76,2.12c-7.2,1.35-14.36,2.94-21.51,4.54
				C2.71,61.64-0.77,62.42-4.25,63.17 M111.2,62.71c-3.4-1.58-6.86-3.01-10.42-4.24c-1.29-0.45-2.59-0.87-3.9-1.27
				c-0.05-0.17-0.1-0.35-0.15-0.52c4.64,1.41,9.16,3.14,13.59,5.14c0.2,0.09,0.39,0.18,0.59,0.27
				C111.01,62.29,111.11,62.5,111.2,62.71 M80.88,53.64c-0.49-0.07-0.97-0.14-1.46-0.2c-0.54-0.07-1.09-0.14-1.63-0.2
				c-0.24-0.16-0.46-0.34-0.68-0.55c1.44,0.16,2.88,0.34,4.32,0.55C81.25,53.4,81.07,53.53,80.88,53.64 M53.28,52.83
				c-0.04-0.15-0.08-0.3-0.12-0.45c3.25-0.23,6.51-0.36,9.77-0.38c0.37,0,0.75,0,1.12,0c0,0,0.01,0,0.02,0c0,0.01,0,0.02,0,0.03
				c0.01,0.13,0.02,0.26,0.03,0.4c-0.16,0-0.32,0-0.47,0c-1.05,0-2.09,0.02-3.14,0.05C58.08,52.54,55.68,52.66,53.28,52.83" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#BD1544;" d="M180.4,86.92
				c-1.39,0-2.78-0.03-4.16-0.07c-7.88-0.27-15.63-1.48-23.19-3.8c-2.26-0.69-4.48-1.48-6.67-2.34c-0.02-0.18-0.03-0.36-0.04-0.54
				c5.82,2.29,11.82,4,18.05,5c3.52,0.56,7.07,0.91,10.63,1.09c1.7,0.09,3.39,0.12,5.09,0.12c1.32,0,2.64-0.02,3.96-0.04
				c1.64-0.03,3.27-0.13,4.9-0.25c-0.07,0.17-0.15,0.35-0.23,0.52c-0.24,0.02-0.48,0.04-0.72,0.05
				C185.48,86.84,182.94,86.92,180.4,86.92 M204.06,84.75c0.14-0.2,0.28-0.4,0.41-0.6c2.29-0.38,4.58-0.81,6.86-1.26
				c0.01,0.17,0.01,0.34,0.01,0.52C208.92,83.89,206.49,84.34,204.06,84.75 M133.79,74.77c-4.08-2.21-8.1-4.54-12.18-6.77
				c-3.42-1.87-6.89-3.66-10.42-5.3c-0.09-0.21-0.19-0.42-0.28-0.62c5.6,2.57,10.98,5.57,16.35,8.58c2.14,1.2,4.28,2.4,6.45,3.56
				C133.74,74.41,133.77,74.59,133.79,74.77 M96.88,57.19c-5.24-1.6-10.57-2.79-16-3.56c0.2-0.11,0.38-0.24,0.54-0.39
				c3.88,0.58,7.72,1.36,11.52,2.36c1.27,0.33,2.54,0.69,3.79,1.08C96.78,56.85,96.83,57.02,96.88,57.19 M45.66,53.56
				c0.04-0.17,0.09-0.34,0.13-0.51c2.36-0.26,4.72-0.48,7.09-0.65c0.09-0.01,0.19-0.01,0.28-0.02c0.04,0.15,0.08,0.3,0.12,0.45
				c-0.88,0.06-1.76,0.14-2.65,0.22C48.97,53.2,47.31,53.37,45.66,53.56 M77.78,53.24c-2.13-0.24-4.27-0.42-6.41-0.56
				c-2.42-0.15-4.85-0.25-7.28-0.26c-0.01-0.13-0.02-0.26-0.03-0.4c0-0.01,0-0.02,0-0.03c3.87,0,7.73,0.17,11.58,0.54
				c0.49,0.05,0.97,0.1,1.46,0.15C77.32,52.9,77.55,53.08,77.78,53.24" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#EFD1D9;" d="M246.79,54.96v-0.5
				c3.12-0.68,6.24-1.33,9.38-1.94c5.34-1.03,10.7-1.91,16.11-2.53c3.59-0.41,7.17-0.74,10.78-0.9c1.3-0.06,2.59-0.08,3.88-0.09
				v0.51c-0.27,0-0.55-0.01-0.82-0.01c-3.91,0-7.8,0.36-11.69,0.74c-6.71,0.67-13.34,1.79-19.94,3.1
				C251.91,53.85,249.35,54.4,246.79,54.96 M-44.41,49.38c0-0.17,0-0.34,0-0.51c3.91-0.04,7.8-0.37,11.68-0.75
				c6.85-0.68,13.64-1.78,20.41-3.05c2.69-0.5,5.38-1.03,8.07-1.58v0.47c-1.88,0.38-3.76,0.75-5.64,1.13
				c-5.04,1.01-10.11,1.87-15.2,2.59c-3.59,0.51-7.19,0.94-10.81,1.22C-38.74,49.12-41.57,49.32-44.41,49.38" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#CC1249;" d="M177.61,65.92
				c-2.54-0.09-5.07-0.28-7.59-0.57c0.04-0.18,0.08-0.35,0.11-0.52c2.66,0.3,5.33,0.49,8,0.57
				C177.96,65.58,177.79,65.75,177.61,65.92 M195.25,65.21c0.03-0.18,0.06-0.35,0.08-0.53c5.09-0.55,10.15-1.32,15.18-2.25
				c2.94-0.55,5.88-1.13,8.81-1.77c6-1.32,11.99-2.66,17.97-4.05c3.16-0.74,6.33-1.46,9.5-2.15v0.5c-3.96,0.87-7.9,1.78-11.85,2.7
				c-5.96,1.38-11.91,2.78-17.91,4.01c-4.43,0.91-8.88,1.76-13.35,2.45C200.88,64.56,198.07,64.91,195.25,65.21 M146.47,59.53
				c-1.77-0.68-3.53-1.4-5.27-2.16c-4.96-2.16-9.8-4.56-14.66-6.94c-0.12-0.23-0.24-0.46-0.36-0.68c3.46,1.68,6.9,3.39,10.38,5.03
				c3.28,1.54,6.6,2.98,9.98,4.27C146.51,59.21,146.49,59.37,146.47,59.53 M-4.25,43.96v-0.47c2.73-0.55,5.45-1.12,8.18-1.69
				c8.78-1.83,17.55-3.69,26.39-5.16c5.03-0.84,10.07-1.57,15.14-2.09c4.2-0.43,8.41-0.77,12.64-0.91c1.67-0.06,3.35-0.08,5.02-0.08
				c1.32,0,2.64,0.02,3.96,0.05c-0.06,0.15-0.11,0.3-0.17,0.45c-0.64-0.02-1.27-0.04-1.91-0.05c-0.59-0.01-1.19-0.02-1.78-0.02
				c-1.87,0-3.74,0.06-5.61,0.12c-3.86,0.13-7.71,0.43-11.55,0.83c-6.59,0.69-13.14,1.67-19.66,2.85
				c-6.52,1.18-13.01,2.51-19.5,3.88C3.19,42.46-0.53,43.21-4.25,43.96 M108.42,42.3c-2.37-0.91-4.76-1.75-7.17-2.52
				c0.42,0.02,0.83,0.03,1.24,0.05c0.27,0.04,0.53,0.08,0.79,0.14c0.97,0.33,1.94,0.67,2.91,1.03
				C106.96,41.36,107.7,41.8,108.42,42.3 M99.72,39.29c-3.27-1-6.59-1.87-9.95-2.59c-0.45-0.09-0.89-0.19-1.34-0.28
				c-0.04-0.17-0.08-0.34-0.11-0.51c3.4,0.68,6.76,1.53,10.1,2.5c0.55,0.16,1.1,0.33,1.65,0.5C99.94,39.04,99.83,39.16,99.72,39.29
				 M81.29,35.21c-1.66-0.23-3.32-0.43-4.98-0.59c-1.45-0.14-2.91-0.25-4.36-0.34c0-0.16-0.01-0.31-0.01-0.46
				c3.15,0.19,6.29,0.5,9.41,0.93C81.33,34.9,81.31,35.06,81.29,35.21" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#BD1544;" d="M181.42,65.98
				c-0.89,0-1.77-0.01-2.66-0.03c-0.39-0.01-0.77-0.02-1.16-0.03c0.18-0.17,0.35-0.34,0.53-0.51c1.18,0.04,2.36,0.06,3.55,0.06
				c0.99,0,1.98-0.01,2.98-0.04c3.41-0.09,6.82-0.34,10.23-0.69c0.15-0.02,0.3-0.03,0.45-0.05c-0.03,0.18-0.05,0.35-0.08,0.53
				c-0.43,0.05-0.86,0.09-1.29,0.13C189.79,65.77,185.61,65.98,181.42,65.98 M170.02,65.35c-0.34-0.04-0.68-0.08-1.02-0.12
				c-3.78-0.47-7.51-1.17-11.2-2.1c-3.86-0.96-7.62-2.19-11.33-3.6c0.02-0.16,0.04-0.32,0.07-0.49c3.49,1.33,7.03,2.48,10.66,3.41
				c4.24,1.09,8.54,1.88,12.9,2.37c0.01,0,0.02,0,0.03,0C170.09,65,170.05,65.18,170.02,65.35 M126.54,50.43
				c-0.22-0.11-0.44-0.21-0.66-0.32c-5.09-2.49-10.22-4.93-15.48-7.04c-0.66-0.26-1.31-0.52-1.97-0.77
				c-0.72-0.5-1.46-0.94-2.22-1.31c2.92,1.07,5.8,2.24,8.65,3.49c2.78,1.22,5.54,2.5,8.28,3.81c1.02,0.49,2.03,0.98,3.04,1.47
				C126.3,49.98,126.42,50.2,126.54,50.43 M103.29,39.96c-0.26-0.06-0.53-0.1-0.79-0.14c-0.41-0.02-0.83-0.03-1.24-0.05
				c-0.51-0.16-1.02-0.32-1.53-0.48c0.11-0.13,0.22-0.26,0.34-0.38C101.14,39.25,102.22,39.6,103.29,39.96 M88.43,36.43
				c-2.37-0.48-4.75-0.88-7.14-1.22c0.02-0.15,0.04-0.31,0.06-0.46c1.1,0.15,2.19,0.32,3.28,0.5c1.23,0.2,2.46,0.43,3.69,0.68
				C88.35,36.09,88.39,36.26,88.43,36.43 M71.95,34.28c-1.68-0.1-3.36-0.17-5.04-0.22c0.06-0.15,0.11-0.3,0.17-0.45
				c1.44,0.04,2.88,0.1,4.33,0.18c0.18,0.01,0.35,0.02,0.53,0.03C71.94,33.97,71.94,34.12,71.95,34.28" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#EFD1D9;" d="M177.77,158.04
				c-0.33,0-0.66,0-1-0.01c-1.15-0.03-2.29-0.06-3.44-0.11c0.05-0.15,0.13-0.29,0.23-0.43c0,0,0.01-0.01,0.01-0.01
				c1.33,0.05,2.65,0.08,3.98,0.08c0.99,0,1.97-0.01,2.96-0.05c1.61-0.05,3.22-0.13,4.83-0.23h5.93c-2.43,0.24-4.87,0.45-7.32,0.54
				C181.9,157.88,179.84,158.04,177.77,158.04 M246.79,146.18v-0.48c6.93-1.6,13.89-3.05,20.91-4.2c4.38-0.72,8.78-1.26,13.2-1.67
				c2.01-0.18,4.02-0.27,6.03-0.4v0.51c-2.69,0.16-5.37,0.34-8.06,0.59c-4.29,0.41-8.56,1.03-12.81,1.73
				c-3.73,0.61-7.44,1.37-11.14,2.14C252.21,144.96,249.49,145.56,246.79,146.18 M-44.2,139.68c-0.07,0-0.14,0-0.22,0
				c0-0.17,0-0.34,0-0.51c3.1-0.06,6.18-0.4,9.26-0.74c6.13-0.67,12.2-1.73,18.23-2.97c4.24-0.87,8.46-1.83,12.66-2.82v0.5
				c-2.14,0.51-4.29,1.01-6.44,1.5c-6.92,1.56-13.89,2.9-20.92,3.87c-3.59,0.5-7.2,0.9-10.83,1.08
				C-43.03,139.6-43.61,139.68-44.2,139.68" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#CC1249;" d="M191.28,157.27h-5.93
				c2.37-0.15,4.74-0.35,7.1-0.6c5.56-0.59,11.07-1.46,16.57-2.48c5.5-1.02,10.96-2.2,16.4-3.46c6.4-1.48,12.78-3.02,19.17-4.52
				c0.73-0.17,1.46-0.34,2.19-0.51v0.48c-5.51,1.26-11.01,2.59-16.52,3.89c-5.67,1.34-11.34,2.63-17.05,3.79
				c-4.04,0.83-8.09,1.54-12.17,2.17c-3.15,0.49-6.3,0.89-9.47,1.21C191.48,157.25,191.38,157.26,191.28,157.27 M-4.25,133.13v-0.5
				c1.29-0.31,2.58-0.62,3.87-0.93c9.52-2.31,19.01-4.76,28.6-6.77c1.46-0.31,2.93-0.6,4.39-0.89c0.07,0.16,0.13,0.31,0.2,0.47
				c-1.48,0.28-2.95,0.58-4.42,0.88c-4.74,0.98-9.46,2.09-14.17,3.23C8.06,130.12,1.91,131.66-4.25,133.13 M41.27,123.04
				c-0.18-0.14-0.37-0.28-0.55-0.43c1.14-0.18,2.29-0.35,3.44-0.5c2.2-0.3,4.4-0.55,6.61-0.75c0.17,0.15,0.34,0.29,0.51,0.44
				C47.93,122.11,44.59,122.53,41.27,123.04" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#BD1544;" d="M173.34,157.92
				c-2.86-0.11-5.71-0.27-8.56-0.59c-7.1-0.8-14.04-2.3-20.8-4.62c-5.62-1.93-11-4.41-16.15-7.37c-4.41-2.53-8.59-5.41-12.81-8.24
				c-3.89-2.6-7.87-5.05-12.08-7.09c-5.1-2.48-10.38-4.46-15.87-5.88c-4.45-1.15-8.97-1.94-13.54-2.4
				c-2.51-0.25-5.03-0.43-7.56-0.44c-1.04-0.01-2.09-0.11-3.14-0.11c-0.23,0-0.45,0-0.68,0.02c-3.09,0.16-6.19,0.21-9.28,0.46
				c-0.53,0.04-1.06,0.09-1.59,0.14c-0.17-0.15-0.34-0.29-0.51-0.44c1.36-0.13,2.73-0.24,4.09-0.35c2.8-0.21,5.6-0.28,8.4-0.29
				c0.12,0,0.23,0,0.35,0c2.44,0,4.9,0.09,7.35,0.29c4.07,0.33,8.11,0.84,12.11,1.68c5.87,1.22,11.54,3.05,17.02,5.48
				c4.7,2.08,9.13,4.62,13.43,7.43c4.41,2.88,8.7,5.95,13.25,8.63c5.03,2.96,10.27,5.47,15.75,7.48c6.23,2.28,12.64,3.83,19.21,4.76
				c2.8,0.39,5.6,0.66,8.42,0.83c1.15,0.07,2.29,0.13,3.44,0.17c0,0-0.01,0.01-0.01,0.01C173.47,157.63,173.39,157.77,173.34,157.92
				 M32.81,124.51c-0.07-0.15-0.14-0.31-0.2-0.47c2.7-0.52,5.4-1.01,8.11-1.43c0.18,0.14,0.36,0.29,0.55,0.43
				C38.44,123.47,35.62,123.97,32.81,124.51" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#CC1249;" d="M195.98,114.81
				c0.24-0.2,0.48-0.4,0.72-0.6c4.73-0.63,9.43-1.45,14.11-2.39c0.69-0.14,1.38-0.28,2.06-0.42c-0.18,0.22-0.35,0.42-0.51,0.61
				c-5.01,1.04-10.06,1.93-15.14,2.63C196.81,114.7,196.4,114.76,195.98,114.81 M214.28,111.61c0.04-0.17,0.07-0.35,0.11-0.53
				c4.29-0.92,8.57-1.93,12.83-2.96c6.52-1.58,13.03-3.22,19.56-4.76v0.51c-0.83,0.2-1.65,0.39-2.48,0.59
				c-7.99,1.93-15.98,3.9-23.98,5.79C218.32,110.72,216.3,111.18,214.28,111.61 M145.02,110.14c-2.49-0.97-4.92-2.05-7.3-3.25
				c-0.05-0.2-0.09-0.4-0.14-0.6c2.51,1.27,5.08,2.41,7.7,3.43C145.19,109.86,145.11,110,145.02,110.14 M115.11,92.83
				c-2.02-1.21-4.08-2.34-6.18-3.4c0.03-0.15,0.05-0.3,0.07-0.45c2.11,1.07,4.17,2.22,6.2,3.42
				C115.17,92.54,115.14,92.68,115.11,92.83" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#BD1544;" d="M177.78,116.02
				c-3.43,0-6.85-0.21-10.26-0.54c-6.52-0.64-12.89-1.99-19.09-4.09c-1.15-0.39-2.28-0.8-3.41-1.24c0.09-0.14,0.17-0.28,0.26-0.42
				c2.14,0.82,4.31,1.56,6.53,2.21c4.87,1.42,9.82,2.4,14.85,2.97c2.82,0.32,5.66,0.51,8.51,0.57c1.07,0.02,2.15,0.11,3.22,0.11
				c0.22,0,0.44,0,0.66-0.01c3.67-0.14,7.33-0.26,10.99-0.59c2.23-0.21,4.45-0.46,6.66-0.76c-0.24,0.2-0.48,0.4-0.72,0.6
				c-3.1,0.4-6.21,0.71-9.33,0.91c-2.16,0.14-4.32,0.19-6.49,0.26C179.37,116.01,178.57,116.02,177.78,116.02 M212.36,112.02
				c0.16-0.19,0.34-0.4,0.51-0.61c0.51-0.11,1.01-0.21,1.52-0.32c-0.03,0.18-0.07,0.35-0.11,0.53
				C213.64,111.75,213,111.88,212.36,112.02 M137.71,106.89c-2.74-1.38-5.41-2.9-8.01-4.56c-4.36-2.78-8.56-5.78-12.95-8.51
				c-0.55-0.34-1.09-0.67-1.65-1c0.03-0.14,0.06-0.29,0.09-0.43c2.15,1.28,4.27,2.63,6.36,4.02c3.5,2.34,6.96,4.74,10.58,6.89
				c1.79,1.06,3.6,2.06,5.44,2.99C137.62,106.49,137.67,106.69,137.71,106.89" />
                    <path style="clip-path:url(#SVGID_00000079483506709241683160000014273464176298189502_);fill:#CC1249;" d="M-4.25,90.79v-0.47
				c3.29-0.76,6.57-1.54,9.85-2.32c5.48-1.3,10.95-2.62,16.45-3.81c2.74-0.59,5.47-1.16,8.22-1.69c0.01,0.15,0.03,0.3,0.04,0.46
				c-7.01,1.37-13.98,2.97-20.93,4.62C4.84,88.65,0.3,89.74-4.25,90.79 M100.82,85.8c-2.65-1.03-5.33-1.94-8.06-2.73
				c0.06-0.14,0.12-0.29,0.18-0.43c2.75,0.79,5.45,1.72,8.12,2.76C100.98,85.53,100.9,85.67,100.82,85.8 M34.07,82.25
				c-0.09-0.14-0.19-0.27-0.28-0.41c2.78-0.51,5.57-0.97,8.37-1.37c1.65-0.24,3.29-0.45,4.95-0.65c0.06,0.15,0.13,0.3,0.2,0.45
				c-1.42,0.16-2.83,0.33-4.25,0.53C40.05,81.23,37.05,81.71,34.07,82.25" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M92.76,83.07
			c-1.18-0.34-2.38-0.66-3.58-0.95c-4.4-1.07-8.86-1.83-13.37-2.27c-2.52-0.25-5.03-0.4-7.56-0.52c-1.44-0.07-2.88-0.1-4.31-0.1
			c-0.75,0-1.5,0.01-2.25,0.03c-3.37,0.09-6.74,0.27-10.1,0.58c-1.43,0.13-2.86,0.27-4.29,0.43c-0.07-0.15-0.13-0.3-0.2-0.45
			c1.83-0.21,3.66-0.4,5.49-0.56c2.81-0.25,5.63-0.36,8.45-0.44c0.89-0.03,1.78-0.04,2.67-0.04c1.57,0,3.14,0.03,4.71,0.07
			c2.74,0.07,5.46,0.3,8.18,0.6c4.63,0.51,9.21,1.32,13.72,2.48c0.88,0.22,1.75,0.46,2.61,0.71C92.88,82.78,92.82,82.93,92.76,83.07
			 M30.31,82.96c-0.02-0.15-0.03-0.3-0.04-0.46c1.17-0.23,2.34-0.45,3.51-0.66c0.09,0.14,0.19,0.27,0.28,0.41
			c-1.05,0.19-2.1,0.38-3.14,0.59C30.72,82.88,30.52,82.92,30.31,82.96" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000040564314116354526280000012085311900970183054_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000070113619292673528290000006528689635999401898_">
                        <use xlink:href="#SVGID_00000040564314116354526280000012085311900970183054_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000070113619292673528290000006528689635999401898_);fill:#BD1544;" d="M108.93,89.42
				c-1.55-0.78-3.13-1.53-4.73-2.23c-1.12-0.49-2.25-0.96-3.38-1.4c0.08-0.13,0.16-0.26,0.24-0.4c1.6,0.63,3.18,1.3,4.74,2.02
				c1.08,0.5,2.15,1.01,3.2,1.55C108.98,89.12,108.95,89.27,108.93,89.42" />
                    <path style="clip-path:url(#SVGID_00000070113619292673528290000006528689635999401898_);fill:#EFD1D9;" d="M246.79,66.52v-0.5
				c1.08-0.25,2.16-0.49,3.23-0.73c7.17-1.61,14.4-2.96,21.7-3.89c3.35-0.42,6.7-0.79,10.07-0.95c1.62-0.08,3.24-0.24,4.87-0.24
				c0.09,0,0.18,0,0.28,0v0.51c-3.78,0.04-7.54,0.35-11.3,0.74c-7.36,0.75-14.64,2.04-21.88,3.54
				C251.43,65.49,249.1,66,246.79,66.52 M-43.84,60.61c-0.09,0-0.18,0-0.27,0c-0.09,0-0.18,0-0.26,0c-0.01,0-0.03,0-0.04,0
				c0-0.17,0-0.34,0-0.51c3.06-0.07,6.1-0.33,9.14-0.6c4.72-0.42,9.4-1.09,14.08-1.85c3.14-0.52,6.28-1.1,9.4-1.73
				c2.52-0.5,5.03-1.02,7.54-1.54v0.49c-2.14,0.45-4.27,0.89-6.41,1.32c-3.67,0.73-7.34,1.42-11.03,2.02
				c-3.71,0.61-7.43,1.14-11.17,1.55c-3.52,0.39-7.06,0.63-10.59,0.86C-43.58,60.61-43.71,60.61-43.84,60.61" />
                    <path style="clip-path:url(#SVGID_00000070113619292673528290000006528689635999401898_);fill:#CC1249;" d="M192.14,77.38
				c0.06-0.17,0.11-0.34,0.17-0.51c2.59-0.24,5.16-0.57,7.74-0.93c3.2-0.45,6.39-0.98,9.56-1.57c-0.08,0.18-0.17,0.37-0.27,0.58
				c-3.84,0.72-7.7,1.34-11.58,1.83C195.89,77.01,194.02,77.21,192.14,77.38 M210.29,74.76c-0.04-0.17-0.07-0.34-0.11-0.5
				c3.9-0.74,7.77-1.57,11.64-2.45c8.33-1.91,16.64-3.89,24.96-5.78v0.5c-2.7,0.61-5.39,1.24-8.08,1.87
				c-8.07,1.89-16.12,3.86-24.23,5.54C213.08,74.22,211.69,74.5,210.29,74.76 M145.87,71.35c-2.24-0.9-4.44-1.87-6.63-2.89
				c-2.5-1.17-4.95-2.43-7.4-3.71c-0.05-0.2-0.1-0.39-0.15-0.59c1.73,0.9,3.46,1.79,5.21,2.65c2.94,1.45,5.92,2.8,8.96,4.01
				C145.87,71.01,145.87,71.18,145.87,71.35 M119.52,58.29c-0.47-0.24-0.94-0.48-1.41-0.71c-1.92-0.95-3.84-1.87-5.79-2.74
				c-0.23-0.27-0.47-0.53-0.71-0.79c2.67,1.18,5.3,2.44,7.9,3.75C119.53,57.97,119.53,58.13,119.52,58.29 M-4.25,54.86v-0.49
				c2.12-0.44,4.25-0.89,6.37-1.34c2.85-0.6,5.68-1.26,8.53-1.86c5.73-1.22,11.47-2.42,17.24-3.48c4.01-0.74,8.04-1.4,12.08-1.96
				c2.91-0.4,5.82-0.72,8.74-1.01c-0.07,0.16-0.14,0.32-0.21,0.48c-2.6,0.24-5.19,0.54-7.77,0.89c-4.25,0.57-8.49,1.22-12.71,2.04
				c-4.09,0.8-8.17,1.58-12.25,2.42c-3.41,0.7-6.81,1.47-10.22,2.2C2.28,53.47-0.98,54.17-4.25,54.86 M104.79,51.72
				c-1.94-0.73-3.9-1.41-5.88-2.03c-1.9-0.59-3.82-1.14-5.74-1.63c-0.09-0.18-0.18-0.36-0.26-0.54c3.85,0.98,7.63,2.17,11.35,3.54
				C104.43,51.28,104.61,51.5,104.79,51.72 M81.75,45.75c-0.51-0.08-1.03-0.15-1.54-0.22c-2.27-0.3-4.55-0.54-6.83-0.72
				c-0.04-0.16-0.08-0.31-0.11-0.47c1.43,0.11,2.86,0.25,4.29,0.41c1.37,0.15,2.73,0.33,4.09,0.52
				C81.68,45.44,81.71,45.6,81.75,45.75 M52.43,44.89c0.01-0.16,0.03-0.32,0.04-0.48c3.73-0.28,7.46-0.4,11.2-0.4
				c0.25,0,0.5,0,0.75,0c-0.02,0.15-0.04,0.29-0.06,0.44c-0.36,0-0.71,0-1.07,0c-0.25,0-0.5,0-0.75,0
				C59.18,44.46,55.8,44.64,52.43,44.89L52.43,44.89" />
                    <path style="clip-path:url(#SVGID_00000070113619292673528290000006528689635999401898_);fill:#BD1544;" d="M180.81,77.95
				c-1.89,0-3.78-0.06-5.66-0.16c-9.01-0.48-17.78-2.19-26.27-5.29c-1.01-0.37-2.01-0.75-3.01-1.15c0-0.17-0.01-0.35-0.01-0.52
				c3.86,1.54,7.81,2.85,11.88,3.85c3.98,0.98,8.01,1.73,12.1,2.13c2.6,0.26,5.2,0.37,7.81,0.54c1.04,0.07,2.08,0.09,3.12,0.09
				c1.91,0,3.82-0.09,5.73-0.17c1.94-0.08,3.88-0.22,5.81-0.4c-0.05,0.17-0.11,0.34-0.17,0.51c-2.69,0.24-5.38,0.41-8.08,0.51
				C182.97,77.93,181.89,77.95,180.81,77.95 M209.34,74.94c0.09-0.2,0.18-0.4,0.27-0.58c0.19-0.04,0.38-0.07,0.57-0.11
				c0.04,0.17,0.08,0.34,0.11,0.5C209.98,74.82,209.66,74.88,209.34,74.94 M131.85,64.76c-1.85-0.97-3.69-1.95-5.54-2.91
				c-2.27-1.17-4.51-2.4-6.79-3.56c0.01-0.16,0.01-0.33,0-0.49c1.05,0.53,2.1,1.07,3.15,1.61c3.02,1.57,6.02,3.19,9.03,4.76
				C131.75,64.37,131.8,64.56,131.85,64.76 M112.32,54.84c-2.48-1.11-4.99-2.16-7.53-3.12c-0.18-0.22-0.36-0.44-0.54-0.66
				c2.48,0.91,4.92,1.91,7.35,2.98c0.01,0,0.01,0,0.02,0.01C111.85,54.31,112.09,54.57,112.32,54.84 M93.17,48.06
				c-3.76-0.97-7.57-1.74-11.42-2.31c-0.03-0.16-0.07-0.31-0.1-0.47c3.75,0.55,7.46,1.27,11.13,2.2c0.04,0.01,0.08,0.02,0.13,0.03
				C92.99,47.7,93.08,47.88,93.17,48.06 M48.49,45.21c0.07-0.16,0.14-0.32,0.21-0.48c0.31-0.03,0.62-0.06,0.94-0.09
				c0.95-0.09,1.89-0.17,2.84-0.24c-0.02,0.16-0.03,0.32-0.04,0.48C51.12,44.98,49.81,45.09,48.49,45.21 M73.38,44.82
				c-3-0.23-6-0.35-9.01-0.38c0.02-0.15,0.04-0.29,0.06-0.44c0.88,0,1.77,0.02,2.65,0.03c2.07,0.04,4.13,0.15,6.19,0.31
				C73.3,44.51,73.34,44.66,73.38,44.82" />
                    <path style="clip-path:url(#SVGID_00000070113619292673528290000006528689635999401898_);fill:#CC1249;" d="M206.99,101.66
				c0.1-0.19,0.2-0.37,0.29-0.56c0.83-0.15,1.66-0.31,2.49-0.48c4-0.78,7.99-1.64,11.96-2.59c8.35-2,16.69-4.03,25.05-6.02v0.49
				c-2.01,0.47-4.02,0.96-6.02,1.45c-6.15,1.5-12.32,2.96-18.47,4.44C217.22,99.61,212.12,100.72,206.99,101.66 M147.9,99.38
				c-4.23-1.52-8.3-3.42-12.25-5.56c-0.02-0.18-0.03-0.36-0.05-0.54c1.52,0.83,3.06,1.62,4.63,2.37c2.51,1.2,5.08,2.27,7.68,3.21
				C147.91,99.04,147.9,99.21,147.9,99.38 M116.02,82.01c-2.21-1.25-4.45-2.43-6.73-3.54c-0.01-0.05-0.01-0.11-0.02-0.16
				c-0.01-0.12-0.03-0.25-0.05-0.38c2.3,1.12,4.55,2.32,6.77,3.57C116,81.67,116.01,81.84,116.02,82.01" />
                    <path style="clip-path:url(#SVGID_00000070113619292673528290000006528689635999401898_);fill:#BD1544;" d="M179.34,104.5
				c-4.06,0-8.11-0.16-12.16-0.62c-6.57-0.76-12.98-2.23-19.2-4.46c-0.03-0.01-0.06-0.02-0.09-0.03c0.01-0.17,0.01-0.35,0.01-0.52
				c2.24,0.81,4.52,1.53,6.83,2.15c4.58,1.24,9.25,2.05,13.97,2.53c2.43,0.24,4.87,0.34,7.31,0.45c0.71,0.03,1.43,0.05,2.14,0.05
				c1.73,0,3.45-0.07,5.18-0.1c2.8-0.04,5.59-0.31,8.38-0.58c5.23-0.5,10.42-1.3,15.58-2.27c-0.1,0.19-0.19,0.37-0.29,0.56
				c-1.85,0.34-3.7,0.66-5.56,0.95c-3.25,0.51-6.51,0.95-9.79,1.26c-3.83,0.37-7.65,0.61-11.5,0.63
				C179.88,104.5,179.61,104.5,179.34,104.5 M135.65,93.83c-0.29-0.16-0.57-0.31-0.86-0.47c-5.86-3.24-11.37-7.05-17.16-10.42
				c-0.54-0.31-1.07-0.62-1.61-0.93c-0.01-0.17-0.01-0.33-0.02-0.5c2.78,1.58,5.51,3.24,8.22,4.95c3.75,2.36,7.5,4.71,11.38,6.83
				C135.61,93.46,135.63,93.65,135.65,93.83" />
                    <path style="clip-path:url(#SVGID_00000070113619292673528290000006528689635999401898_);fill:#CC1249;" d="M-4.25,79.74v-0.49
				c1.01-0.23,2.03-0.46,3.04-0.69c9.55-2.19,19.07-4.48,28.68-6.41c5.63-1.13,11.28-2.08,16.97-2.81c0.02,0.16,0.03,0.32,0.05,0.48
				c-1.47,0.19-2.93,0.39-4.4,0.6c-5.15,0.75-10.26,1.73-15.36,2.77c-6.62,1.35-13.19,2.93-19.78,4.42
				C1.87,78.3-1.18,79.05-4.25,79.74 M105.29,76.63c-2.38-1.03-4.81-1.98-7.28-2.82c-0.54-0.18-1.08-0.36-1.62-0.53
				c0.04-0.15,0.08-0.29,0.12-0.44c2.94,0.95,5.84,2.03,8.69,3.25c0.07,0.03,0.15,0.06,0.22,0.1
				C105.39,76.34,105.34,76.49,105.29,76.63" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M96.39,73.28
			c-5.65-1.8-11.41-3.08-17.29-3.84c-2.4-0.31-4.81-0.52-7.22-0.7c-2.54-0.19-5.08-0.25-7.62-0.25c-1.49,0-2.99,0.02-4.48,0.05
			c-2.97,0.06-5.93,0.29-8.89,0.57c-2.14,0.2-4.28,0.44-6.41,0.71c-0.02-0.16-0.04-0.32-0.05-0.48c0.44-0.06,0.88-0.11,1.33-0.17
			c3.56-0.43,7.13-0.76,10.72-0.95c2.52-0.13,5.03-0.24,7.55-0.24c0.6,0,1.21,0.01,1.81,0.02c3.71,0.09,7.42,0.3,11.11,0.71
			c4.38,0.48,8.71,1.23,12.98,2.27c2.22,0.54,4.42,1.16,6.59,1.86C96.48,72.99,96.44,73.14,96.39,73.28" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000147207533593613656540000006546168786438102700_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000116951474163872324100000003708845802972600983_">
                        <use xlink:href="#SVGID_00000147207533593613656540000006546168786438102700_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000116951474163872324100000003708845802972600983_);fill:#BD1544;" d="M109.29,78.47
				c-1.32-0.64-2.65-1.25-4-1.84c0.05-0.14,0.09-0.29,0.14-0.44c1.28,0.55,2.54,1.13,3.79,1.74c0.02,0.13,0.04,0.26,0.05,0.38
				C109.28,78.36,109.29,78.42,109.29,78.47" />
                    <path style="clip-path:url(#SVGID_00000116951474163872324100000003708845802972600983_);fill:#CC1249;" d="M187.69,121.42
				c0.2-0.17,0.41-0.35,0.61-0.52c1.88-0.16,3.76-0.36,5.65-0.58c3.77-0.45,7.52-1.03,11.25-1.69c-0.32,0.23-0.64,0.46-0.96,0.68
				c-3.98,0.69-7.97,1.28-11.99,1.72C190.73,121.19,189.21,121.31,187.69,121.42 M212.29,117.77c0.08-0.18,0.16-0.37,0.24-0.55
				c2.19-0.45,4.38-0.93,6.56-1.42c7.5-1.7,14.96-3.59,22.43-5.42c1.75-0.43,3.51-0.85,5.26-1.26v0.5
				c-1.79,0.42-3.59,0.86-5.38,1.29C231.72,113.27,222.06,115.75,212.29,117.77 M113.74,97.81c-0.99-0.59-1.99-1.16-2.99-1.71
				c-1.07-0.59-2.15-1.15-3.25-1.7c0.06-0.13,0.12-0.26,0.17-0.39c0.85,0.42,1.7,0.86,2.54,1.31c1.24,0.66,2.46,1.35,3.67,2.07
				C113.83,97.53,113.78,97.67,113.74,97.81" />
                    <path style="clip-path:url(#SVGID_00000116951474163872324100000003708845802972600983_);fill:#BD1544;" d="M177.99,121.83
				c-0.73,0-1.46-0.01-2.2-0.03c-3.37-0.08-6.74-0.31-10.09-0.71c-4.06-0.48-8.07-1.21-12.02-2.24c-6.71-1.75-13.11-4.31-19.2-7.63
				c-5.02-2.73-9.63-6.06-14.32-9.3c-2.1-1.45-4.24-2.83-6.43-4.13c0.05-0.14,0.1-0.28,0.14-0.42c3.17,1.89,6.23,3.97,9.25,6.09
				c4.33,3.05,8.75,5.94,13.47,8.36c5.7,2.92,11.65,5.17,17.86,6.72c4.31,1.08,8.69,1.79,13.11,2.25c3.47,0.36,6.94,0.51,10.42,0.51
				c1.26,0,2.52-0.02,3.77-0.06c2.18-0.06,4.36-0.19,6.53-0.38c-0.2,0.17-0.41,0.35-0.61,0.52c-0.89,0.06-1.78,0.12-2.67,0.18
				C182.68,121.75,180.33,121.83,177.99,121.83 M204.24,119.31c0.32-0.22,0.64-0.45,0.96-0.68c2.45-0.44,4.89-0.91,7.33-1.42
				c-0.08,0.18-0.16,0.37-0.24,0.55c-1.53,0.32-3.07,0.62-4.61,0.92C206.53,118.9,205.39,119.11,204.24,119.31" />
                    <path style="clip-path:url(#SVGID_00000116951474163872324100000003708845802972600983_);fill:#CC1249;" d="M-4.25,96.44v-0.48
				c8.41-1.98,16.81-4.01,25.23-5.94c3.34-0.77,6.69-1.48,10.06-2.13c0.03,0.16,0.06,0.32,0.09,0.48c-3.54,0.69-7.08,1.43-10.6,2.22
				C12.25,92.46,4.01,94.52-4.25,96.44 M98.43,90.53c-2.57-0.94-5.16-1.75-7.8-2.45c0.06-0.14,0.13-0.29,0.19-0.43
				c2.62,0.71,5.21,1.53,7.75,2.46C98.52,90.24,98.47,90.38,98.43,90.53 M38.24,87.1c-0.15-0.14-0.29-0.28-0.43-0.42
				c2.03-0.33,4.06-0.64,6.1-0.92c1.92-0.26,3.84-0.48,5.77-0.68c0.08,0.15,0.15,0.3,0.23,0.45c-0.74,0.08-1.48,0.16-2.22,0.24
				C44.52,86.13,41.37,86.58,38.24,87.1" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M31.13,88.38
			c-0.03-0.16-0.06-0.32-0.09-0.48c2.25-0.43,4.51-0.84,6.77-1.21c0.14,0.14,0.28,0.28,0.43,0.42c-1.49,0.25-2.98,0.51-4.47,0.78
			C32.89,88.04,32.01,88.21,31.13,88.38 M90.63,88.07c-2.8-0.74-5.63-1.36-8.5-1.84c-2.93-0.49-5.88-0.84-8.84-1.11
			c-3.13-0.29-6.27-0.31-9.41-0.31c-0.41,0-0.81,0-1.22,0c-2.66,0-5.31,0.1-7.95,0.29c-1.61,0.12-3.21,0.26-4.81,0.43
			c-0.07-0.15-0.15-0.3-0.23-0.45c1.53-0.15,3.07-0.29,4.61-0.41c3.25-0.26,6.51-0.39,9.76-0.39c1.23,0,2.45,0.02,3.67,0.05
			c5.56,0.16,11.09,0.74,16.57,1.79c2.2,0.42,4.38,0.92,6.53,1.5C90.76,87.79,90.7,87.93,90.63,88.07" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000173145899444666926020000012531144180075537557_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000163035483854759348190000000902362867721205155_">
                        <use xlink:href="#SVGID_00000173145899444666926020000012531144180075537557_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000163035483854759348190000000902362867721205155_);fill:#BD1544;" d="M107.49,94.4
				c-2.43-1.2-4.91-2.29-7.44-3.26c-0.54-0.21-1.08-0.41-1.62-0.61c0.04-0.14,0.09-0.28,0.14-0.42c3.09,1.14,6.12,2.44,9.09,3.91
				C107.61,94.14,107.55,94.27,107.49,94.4" />
                    <path style="clip-path:url(#SVGID_00000163035483854759348190000000902362867721205155_);fill:#EFD1D9;" d="M246.79,28.6v-0.53
				c5.2-1.04,10.42-1.99,15.67-2.75c3.4-0.49,6.81-0.93,10.24-1.22c2.45-0.21,4.9-0.37,7.36-0.52c1.19-0.07,2.38-0.08,3.57-0.08
				c0.23,0,0.46,0,0.69,0c0.23,0,0.46,0,0.69,0c0.64,0,1.28,0,1.92-0.02v0.38c-0.02,0-0.04,0-0.06,0c-2,0-3.99,0.09-5.99,0.16
				c-3.2,0.11-6.39,0.38-9.58,0.69c-4.84,0.48-9.65,1.18-14.45,1.98c-3.14,0.53-6.27,1.12-9.39,1.75
				C247.24,28.51,247.01,28.55,246.79,28.6 M-42.9,23.89c-0.5,0-1.01,0-1.51-0.02c0-0.13,0-0.25,0-0.38
				c3.8-0.13,7.59-0.21,11.38-0.53c6.07-0.51,12.11-1.29,18.13-2.23c3.56-0.56,7.11-1.17,10.65-1.81v0.46
				c-1.06,0.19-2.11,0.39-3.17,0.58c-5.66,1.01-11.35,1.92-17.06,2.61c-3.26,0.39-6.53,0.73-9.82,0.96
				C-37.16,23.73-40.03,23.89-42.9,23.89" />
                    <path style="clip-path:url(#SVGID_00000163035483854759348190000000902362867721205155_);fill:#CC1249;" d="M182.93,38.56
				c-0.67,0-1.35-0.01-2.02-0.02c-2.98-0.07-5.95-0.24-8.91-0.53c-0.02-0.17-0.04-0.35-0.06-0.52c0.47,0.05,0.94,0.09,1.41,0.13
				c3.22,0.27,6.45,0.45,9.67,0.45c1.04,0,2.08-0.02,3.12-0.06c0.82-0.03,1.64-0.07,2.46-0.11c0,0.17,0.01,0.34,0.01,0.52
				c-0.15,0.01-0.29,0.01-0.44,0.02C186.42,38.51,184.68,38.56,182.93,38.56 M191.15,38.28c-0.09-0.17-0.17-0.34-0.26-0.51
				c2.35-0.15,4.7-0.34,7.05-0.6c5.41-0.6,10.78-1.42,16.13-2.4c5.1-0.93,10.17-1.96,15.24-3.05c5.27-1.13,10.55-2.24,15.84-3.32
				c0.55-0.11,1.09-0.22,1.64-0.33v0.53c-4.38,0.88-8.74,1.8-13.11,2.71c-3.28,0.69-6.56,1.42-9.85,2.09
				c-5.68,1.17-11.37,2.27-17.11,3.13c-3.46,0.52-6.93,1-10.42,1.34C194.59,38.04,192.87,38.17,191.15,38.28 M155.64,35.05
				c-0.59-0.15-1.18-0.31-1.76-0.48c-4.18-1.17-8.28-2.56-12.35-4.07c-0.54-0.2-1.07-0.4-1.61-0.6c0.02-0.17,0.04-0.33,0.06-0.5
				c3.02,1.14,6.07,2.23,9.15,3.22c2.25,0.72,4.51,1.38,6.79,1.97C155.82,34.75,155.73,34.9,155.64,35.05 M138.38,29.32
				c-2.22-0.86-4.42-1.74-6.62-2.63c0-0.17,0-0.34,0.01-0.51c2.28,0.92,4.56,1.83,6.85,2.71C138.54,29.03,138.46,29.17,138.38,29.32
				 M123.95,23.52c-0.42-0.17-0.84-0.34-1.26-0.5c-0.14-0.22-0.29-0.44-0.43-0.66c0.85,0.34,1.71,0.68,2.56,1.02
				c-0.14,0.08-0.29,0.12-0.45,0.14C124.23,23.51,124.09,23.52,123.95,23.52 M113.27,19.44c-4.66-1.66-9.37-3.16-14.16-4.41
				c-5.82-1.52-11.72-2.66-17.69-3.42c-6.23-0.79-12.48-1.15-18.75-1.15c-1.16,0-2.33,0.01-3.5,0.04c-3.59,0.08-7.17,0.3-10.75,0.59
				c-4.51,0.36-9,0.84-13.48,1.46c-5.55,0.76-11.09,1.65-16.6,2.67C13.2,16.16,8.06,17.1,2.92,18.05c-2.39,0.44-4.78,0.89-7.17,1.33
				v-0.46c2.89-0.52,5.78-1.06,8.67-1.6c6.14-1.15,12.27-2.31,18.43-3.36c4.33-0.74,8.68-1.39,13.04-1.98
				c3.58-0.48,7.16-0.87,10.75-1.22c2.83-0.27,5.67-0.41,8.51-0.56c2.55-0.13,5.09-0.17,7.64-0.18c0.37,0,0.74,0,1.11,0
				c1.88,0,3.76,0.03,5.64,0.16c1.59,0.11,3.18,0.17,4.76,0.29c2.56,0.2,5.11,0.45,7.66,0.78c3.71,0.47,7.39,1.12,11.05,1.9
				c4.37,0.93,8.68,2.08,12.94,3.39c2.41,0.74,4.8,1.54,7.18,2.38C113.17,19.09,113.22,19.27,113.27,19.44" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M139.92,29.91
			c-0.51-0.2-1.03-0.39-1.54-0.59c0.08-0.14,0.15-0.28,0.23-0.43c0.46,0.18,0.91,0.35,1.37,0.52
			C139.96,29.57,139.94,29.74,139.92,29.91 M131.76,26.68c-2.6-1.05-5.2-2.12-7.81-3.17c0.14,0,0.28-0.01,0.42-0.01
			c0.16-0.01,0.31-0.06,0.45-0.14c1.52,0.61,3.04,1.22,4.56,1.84c0.8,0.32,1.59,0.65,2.39,0.97
			C131.76,26.35,131.76,26.51,131.76,26.68 M122.69,23.01c-0.59-0.23-1.18-0.47-1.77-0.7c-2.54-1-5.09-1.96-7.65-2.87
			c-0.05-0.18-0.09-0.35-0.14-0.53c3.07,1.09,6.11,2.25,9.13,3.44C122.4,22.58,122.55,22.79,122.69,23.01" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000079466718283488743910000004327397559016033724_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000087398742690344924680000003829610997503248050_">
                        <use xlink:href="#SVGID_00000079466718283488743910000004327397559016033724_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000087398742690344924680000003829610997503248050_);fill:#BD1544;" d="M188.61,38.41
				c0-0.18,0-0.35-0.01-0.52c0.76-0.04,1.53-0.08,2.29-0.13c0.09,0.17,0.18,0.34,0.26,0.51C190.31,38.33,189.46,38.37,188.61,38.41
				 M172,38c-1.22-0.12-2.44-0.26-3.65-0.43c-4.29-0.58-8.52-1.43-12.7-2.52c0.09-0.15,0.18-0.31,0.27-0.46
				c2.35,0.61,4.71,1.14,7.09,1.59c2.96,0.56,5.93,1,8.93,1.3C171.96,37.66,171.98,37.83,172,38" />
                    <path style="clip-path:url(#SVGID_00000087398742690344924680000003829610997503248050_);fill:#EFD1D9;" d="M246.79,16.9v-0.51
				c3.08-0.59,6.18-1.13,9.28-1.63c4.02-0.64,8.06-1.19,12.12-1.63c3.69-0.4,7.4-0.67,11.1-0.83c1.01-0.04,2.01-0.06,3.02-0.06
				c0.75,0,1.51,0.01,2.26,0.01c0.75,0.01,1.51,0.01,2.26,0.01c0.04,0,0.07,0,0.11,0v0.38c-0.57-0.02-1.14-0.02-1.71-0.02
				c-2.17,0-4.34,0.1-6.5,0.19c-3.11,0.12-6.22,0.37-9.32,0.7c-3.46,0.36-6.92,0.78-10.36,1.29
				C254.94,15.42,250.86,16.13,246.79,16.9 M-43.73,12.65c-0.23,0-0.46,0-0.69,0c0-0.13,0-0.25,0-0.38
				c2.23-0.05,4.46-0.04,6.69-0.15c3.28-0.17,6.56-0.39,9.84-0.7c4.1-0.38,8.18-0.92,12.26-1.48c2.8-0.39,5.59-0.84,8.37-1.32
				c1-0.17,2-0.35,3-0.52v0.48c-0.04,0.01-0.08,0.01-0.12,0.02c-2.91,0.51-5.82,1-8.74,1.43c-4.16,0.61-8.31,1.22-12.5,1.63
				c-3.06,0.3-6.12,0.58-9.19,0.77C-37.78,12.61-40.75,12.65-43.73,12.65" />
                    <path style="clip-path:url(#SVGID_00000087398742690344924680000003829610997503248050_);fill:#CC1249;" d="M184.14,26.33
				c-1.7,0-3.39-0.03-5.09-0.06c-3.14-0.06-6.27-0.36-9.39-0.74c-0.33-0.04-0.67-0.08-1-0.13c-0.09-0.19-0.17-0.39-0.26-0.58
				c0.56,0.08,1.12,0.15,1.69,0.22c4.46,0.54,8.93,0.77,13.4,0.77c1.62,0,3.23-0.03,4.85-0.08c5.37-0.18,10.71-0.71,16.04-1.44
				c7.18-0.98,14.3-2.28,21.4-3.71c5.83-1.18,11.66-2.33,17.49-3.5c1.17-0.23,2.34-0.46,3.51-0.69v0.51c-2.4,0.45-4.8,0.92-7.19,1.4
				c-5.89,1.18-11.78,2.4-17.68,3.55c-7.11,1.39-14.25,2.62-21.45,3.44c-2.81,0.32-5.62,0.55-8.43,0.76
				C189.41,26.26,186.77,26.33,184.14,26.33 M160.15,23.95c-1.57-0.33-3.14-0.69-4.7-1.07c-6.78-1.67-13.37-3.92-19.92-6.33
				c-1.98-0.73-3.97-1.46-5.96-2.19c-0.08-0.2-0.17-0.4-0.25-0.6c3.5,1.28,7,2.55,10.51,3.82c5.24,1.9,10.56,3.53,15.97,4.87
				c1.47,0.36,2.94,0.69,4.42,1C160.2,23.61,160.17,23.78,160.15,23.95 M127.3,13.52c-2.52-0.92-5.05-1.85-7.57-2.76
				c-7.12-2.58-14.34-4.82-21.71-6.56C92.95,3,87.83,2.07,82.66,1.41c-3.16-0.4-6.32-0.69-9.5-0.91c-3.63-0.25-7.26-0.36-10.9-0.36
				c-0.99,0-1.99,0.01-2.98,0.03c-2.91,0.05-5.82,0.19-8.72,0.39c-3.11,0.21-6.22,0.5-9.32,0.82c-6.7,0.7-13.36,1.68-20,2.76
				c-5.01,0.82-10,1.75-15,2.63c-3.5,0.61-7,1.19-10.49,1.81V8.09c7.29-1.26,14.57-2.55,21.86-3.8c6.26-1.08,12.53-2.06,18.84-2.83
				c4.11-0.51,8.23-0.91,12.36-1.21c4.39-0.32,8.78-0.51,13.17-0.53c0.24,0,0.49,0,0.73,0c4.09,0,8.18,0.11,12.25,0.44
				c8.44,0.69,16.78,2,25,4.06c7.33,1.83,14.51,4.14,21.6,6.71c1.9,0.69,3.8,1.38,5.69,2.07C127.27,13.17,127.29,13.35,127.3,13.52" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#BD1544;" d="M129.58,14.36
			c-0.76-0.28-1.51-0.56-2.27-0.83c-0.01-0.18-0.03-0.35-0.05-0.52c0.69,0.25,1.38,0.5,2.07,0.75
			C129.41,13.95,129.49,14.15,129.58,14.36" />
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000139997214662536121910000004799816863432844685_" y="0" width="242.53" height="153.01" />
                    </defs>
                    <clipPath id="SVGID_00000121992400830878332030000003010991861443904668_">
                        <use xlink:href="#SVGID_00000139997214662536121910000004799816863432844685_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M168.66,25.4
				c-2.86-0.37-5.69-0.86-8.51-1.45c0.03-0.17,0.05-0.34,0.07-0.51c2.71,0.56,5.43,1.01,8.18,1.38
				C168.48,25.02,168.57,25.21,168.66,25.4" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,135.96v-0.5
				c1.5-0.36,2.99-0.72,4.49-1.08c11.17-2.72,22.3-5.61,33.6-7.73c0.08,0.15,0.16,0.3,0.24,0.46c-1.64,0.31-3.28,0.63-4.92,0.97
				c-5.49,1.14-10.95,2.43-16.4,3.75C7.07,133.2,1.42,134.62-4.25,135.96 M44.71,125.39c-0.23-0.14-0.45-0.28-0.68-0.42
				c1.74-0.24,3.48-0.45,5.23-0.62c1.59-0.16,3.17-0.29,4.76-0.4c0.18,0.14,0.35,0.29,0.53,0.44c-1.36,0.1-2.72,0.22-4.08,0.35
				C48.54,124.91,46.62,125.13,44.71,125.39" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M162.2,159.32
				c-1.02,0-2.04,0-3.06,0c-1.27-0.26-2.54-0.51-3.8-0.77c-6.56-1.35-12.9-3.39-19.02-6.1c-5.36-2.37-10.43-5.26-15.34-8.44
				c-4.15-2.69-8.22-5.51-12.51-8.01c-4.57-2.66-9.31-4.92-14.28-6.73c-5.84-2.12-11.85-3.52-18-4.34
				c-2.71-0.36-5.44-0.57-8.18-0.71c-1.53-0.08-3.05-0.09-4.58-0.09c-0.34,0-0.67,0-1.01,0c-0.34,0-0.68,0-1.03,0
				c-0.15,0-0.3,0-0.45,0c-0.03,0-0.07,0-0.1,0c-2.1,0-4.2,0.1-6.3,0.25c-0.17-0.15-0.35-0.3-0.53-0.44
				c1.33-0.09,2.66-0.17,3.99-0.25c1.67-0.09,3.35-0.14,5.02-0.14c1.19,0,2.39,0.02,3.58,0.07c8.03,0.29,15.93,1.45,23.64,3.81
				c5.22,1.6,10.23,3.69,15.05,6.25c4.64,2.47,9.01,5.37,13.37,8.3c5.02,3.37,10.18,6.48,15.65,9.06c6.29,2.97,12.83,5.2,19.61,6.73
				c2.61,0.59,5.25,1.04,7.9,1.42C161.96,159.22,162.08,159.28,162.2,159.32 M34.07,127.1c-0.08-0.15-0.16-0.3-0.24-0.46
				c0.04-0.01,0.09-0.02,0.13-0.02c3.35-0.63,6.7-1.19,10.07-1.65c0.23,0.14,0.45,0.28,0.68,0.42
				C41.15,125.86,37.6,126.44,34.07,127.1" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M246.79,8.03V7.5
				c3.66-0.67,7.34-1.27,11.02-1.81c3.36-0.49,6.73-0.91,10.11-1.22c3.02-0.28,6.04-0.49,9.07-0.65c1.5-0.08,2.99-0.1,4.49-0.1
				c0.58,0,1.16,0,1.74,0.01c0.58,0,1.16,0.01,1.74,0.01c0.66,0,1.32,0,1.97-0.01V4.1c-0.19,0-0.37,0.01-0.56,0.01
				c-0.05,0-0.1,0-0.14,0c-0.56-0.02-1.12-0.02-1.68-0.02c-2.24,0-4.49,0.11-6.73,0.18c-3.26,0.1-6.52,0.38-9.77,0.69
				c-6.64,0.65-13.23,1.66-19.79,2.8C247.77,7.85,247.28,7.94,246.79,8.03 M98.1-4.26h-2.16c-3.7-0.78-7.43-1.43-11.19-1.93
				c-0.21-0.03-0.43-0.04-0.61-0.16c1.02,0,2.04,0,3.06,0c1.57,0.28,3.13,0.57,4.7,0.85C93.98-5.13,96.04-4.71,98.1-4.26" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M183.61,17.05
				c-2.38,0-4.76-0.05-7.14-0.2c-4.09-0.25-8.16-0.68-12.21-1.36c-0.74-0.12-1.47-0.25-2.21-0.39c-0.18-0.21-0.36-0.43-0.54-0.64
				c2.58,0.49,5.17,0.89,7.78,1.21c2.88,0.35,5.78,0.56,8.69,0.71c1.83,0.09,3.66,0.12,5.49,0.12c1.5,0,3.01-0.02,4.51-0.05
				c3.01-0.05,6.02-0.26,9.02-0.55c4.38-0.42,8.74-0.93,13.08-1.61c3.99-0.63,7.96-1.33,11.93-2.06c6.45-1.2,12.9-2.43,19.34-3.69
				c1.81-0.35,3.61-0.7,5.42-1.03v0.53c-4.82,0.86-9.62,1.82-14.43,2.74c-8.36,1.61-16.71,3.28-25.15,4.46
				c-3.89,0.55-7.79,1.03-11.7,1.34c-3.3,0.26-6.61,0.42-9.92,0.46C184.92,17.04,184.27,17.05,183.61,17.05 M159.28,14.55
				c-3.77-0.78-7.51-1.72-11.22-2.79c-5.47-1.58-10.86-3.41-16.24-5.28c-6.06-2.11-12.12-4.23-18.25-6.12
				c-4-1.23-8.03-2.38-12.1-3.37c-1.83-0.45-3.67-0.86-5.52-1.25h2.16c3.75,0.83,7.47,1.8,11.17,2.85c4.27,1.21,8.5,2.57,12.71,3.99
				c5.94,2,11.85,4.1,17.8,6.08c5.57,1.85,11.19,3.5,16.92,4.8c0.8,0.18,1.6,0.35,2.4,0.52C159.15,14.17,159.21,14.36,159.28,14.55" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M162.06,15.1
				c-0.93-0.17-1.86-0.35-2.78-0.55c-0.06-0.19-0.12-0.38-0.18-0.57c0.81,0.17,1.62,0.33,2.43,0.48
				C161.7,14.67,161.88,14.88,162.06,15.1" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,147.41v-0.58
				c0.63-0.15,1.25-0.3,1.88-0.45c5.52-1.33,11.02-2.68,16.54-4.01c8.2-1.98,16.43-3.82,24.76-5.19c0.74-0.12,1.49-0.24,2.23-0.35
				c0.16,0.17,0.33,0.34,0.49,0.51c-1.93,0.29-3.86,0.61-5.79,0.95c-7.87,1.4-15.65,3.22-23.41,5.09
				C6.88,144.72,1.33,146.13-4.25,147.41" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M128.4,159.32
				c-0.34,0-0.68,0-1.02,0c-1.95-1.12-3.92-2.22-5.85-3.38c-3.19-1.91-6.34-3.88-9.52-5.8c-5.2-3.15-10.54-6.05-16.2-8.3
				c-6.59-2.62-13.4-4.38-20.42-5.31c-2.77-0.36-5.57-0.58-8.37-0.71c-1.57-0.08-3.15-0.1-4.72-0.1c-1,0-1.99,0.01-2.99,0.02
				c-2.69,0.04-5.38,0.24-8.07,0.46c-3.22,0.27-6.42,0.66-9.6,1.13c-0.16-0.17-0.33-0.34-0.49-0.51c3.1-0.47,6.2-0.85,9.32-1.13
				c2.87-0.26,5.75-0.43,8.64-0.53c1.21-0.04,2.43-0.1,3.64-0.1c0.38,0,0.75,0.01,1.13,0.02c4.07,0.13,8.13,0.38,12.17,0.94
				c5.17,0.72,10.23,1.9,15.18,3.53c4.91,1.62,9.62,3.74,14.17,6.19c5.39,2.9,10.5,6.27,15.76,9.4c2.26,1.35,4.58,2.61,6.87,3.9
				C128.16,159.14,128.28,159.23,128.4,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,138.81v-0.5
				c2.22-0.53,4.44-1.07,6.66-1.62c5.59-1.38,11.19-2.71,16.79-4.05c5.33-1.27,10.69-2.43,16.08-3.41c0.09,0.15,0.19,0.3,0.28,0.45
				c-1.68,0.3-3.36,0.63-5.04,0.96c-6.1,1.22-12.16,2.67-18.21,4.13C6.78,136.11,1.27,137.49-4.25,138.81 M49.46,127.68
				c-0.35-0.12-0.7-0.26-1.04-0.4c0.81-0.09,1.62-0.17,2.44-0.24c1.78-0.16,3.56-0.26,5.34-0.36c0.03,0.16,0.03,0.32,0.02,0.48
				c-1.24,0.07-2.48,0.15-3.72,0.25C51.48,127.49,50.47,127.58,49.46,127.68" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M148.94,159.32
				c-0.55,0-1.1,0-1.66,0c-0.56-0.32-1.2-0.42-1.8-0.62c-3.98-1.31-7.87-2.84-11.66-4.62c-5.44-2.55-10.56-5.65-15.58-8.93
				c-5.38-3.51-10.75-7.01-16.56-9.77c-4.6-2.18-9.34-3.97-14.26-5.3c-3.9-1.05-7.86-1.84-11.87-2.34c-2.15-0.27-4.3-0.45-6.46-0.57
				c-2.11-0.12-4.22-0.24-6.33-0.24c-0.5,0-1,0.01-1.5,0.02c-1.68,0.05-3.36,0.12-5.05,0.21c0.02-0.16,0.01-0.32-0.02-0.48
				c1.27-0.07,2.54-0.13,3.81-0.19c0.92-0.05,1.83-0.07,2.75-0.07c1.72,0,3.43,0.07,5.14,0.15c7.6,0.35,15.07,1.54,22.36,3.8
				c5.85,1.82,11.41,4.27,16.75,7.26c3.6,2.02,7.06,4.25,10.5,6.52c5.17,3.4,10.43,6.65,16.03,9.31c4.65,2.21,9.44,4.08,14.4,5.48
				C148.28,159.04,148.63,159.11,148.94,159.32 M35.56,129.68c-0.09-0.15-0.19-0.3-0.28-0.45c1.36-0.25,2.72-0.48,4.09-0.7
				c3.01-0.49,6.03-0.92,9.06-1.25c0.35,0.14,0.69,0.28,1.04,0.4C44.8,128.15,40.17,128.84,35.56,129.68" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M246.79,5.11V4.59
				c0.15-0.03,0.31-0.05,0.46-0.08c5.16-0.92,10.34-1.72,15.55-2.34c3.35-0.4,6.71-0.73,10.07-0.98c3.19-0.24,6.39-0.29,9.59-0.29
				c1.49,0,2.99,0.01,4.48,0.02V1.3c-0.27,0-0.54,0.01-0.81,0.01c-0.09,0-0.18,0-0.27,0c-0.58-0.02-1.16-0.02-1.74-0.02
				c-2.31,0-4.61,0.11-6.92,0.18c-3.26,0.11-6.52,0.38-9.77,0.7c-6.64,0.65-13.23,1.65-19.79,2.8
				C247.34,5.01,247.06,5.06,246.79,5.11 M108.73-4.26h-1.75c-2.37-0.64-4.75-1.23-7.14-1.78c-0.35-0.08-0.71-0.12-1.03-0.32
				c0.68,0,1.36,0,2.04,0c0.28,0.09,0.56,0.2,0.85,0.27C104.06-5.52,106.4-4.91,108.73-4.26" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M183.97,14
				c-0.05,0-0.09,0-0.14,0c-3.37,0-6.74-0.15-10.11-0.42c-7.56-0.6-14.98-1.98-22.31-3.89c-5.89-1.53-11.66-3.41-17.42-5.36
				c-5.13-1.73-10.27-3.46-15.42-5.14c-3.83-1.25-7.69-2.41-11.58-3.45h1.75c7.9,2.21,15.67,4.85,23.44,7.48
				c6.54,2.21,13.11,4.34,19.79,6.08c4.4,1.14,8.85,2.08,13.34,2.77c2.87,0.44,5.76,0.74,8.66,0.99c3.27,0.29,6.55,0.36,9.83,0.36
				c1.2,0,2.4-0.01,3.6-0.03c3.8-0.05,7.59-0.36,11.37-0.72c4.72-0.45,9.4-1.11,14.08-1.85c4.34-0.69,8.67-1.46,12.98-2.31
				c3.42-0.68,6.84-1.32,10.26-1.96c3.56-0.67,7.13-1.33,10.69-1.97v0.52c-5.16,0.91-10.3,1.9-15.45,2.88
				c-3.8,0.72-7.6,1.41-11.4,2.14c-3.61,0.7-7.24,1.27-10.87,1.81c-4.24,0.63-8.5,1.13-12.78,1.5C192.19,13.8,188.08,14,183.97,14" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,141.64v-0.5
				c1.75-0.42,3.49-0.84,5.24-1.26c10.65-2.59,21.28-5.32,32.05-7.38c1.31-0.25,2.61-0.49,3.92-0.72c0.11,0.15,0.22,0.31,0.33,0.46
				c-3.41,0.59-6.82,1.26-10.21,1.97c-5.05,1.06-10.07,2.27-15.09,3.49C6.58,139.01,1.17,140.36-4.25,141.64" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M140.65,159.32
				c-0.43,0-0.85,0-1.28,0c-1.14-0.51-2.29-1.01-3.43-1.52c-4.62-2.03-9.05-4.41-13.35-7.03c-4.45-2.71-8.76-5.62-13.24-8.28
				c-7.27-4.32-14.94-7.72-23.15-9.82c-4.37-1.12-8.8-1.86-13.29-2.33c-3.46-0.37-6.92-0.51-10.39-0.51c-1.24,0-2.49,0.02-3.73,0.05
				c-3.64,0.09-7.28,0.42-10.91,0.82c-3.55,0.4-7.07,0.92-10.58,1.52c-0.11-0.15-0.22-0.31-0.33-0.46
				c3.77-0.66,7.57-1.22,11.38-1.63c3.1-0.33,6.21-0.56,9.33-0.7c1.61-0.07,3.21-0.1,4.81-0.1c0.88,0,1.76,0.01,2.64,0.02
				c4.84,0.09,9.66,0.53,14.44,1.37c5.78,1.02,11.39,2.59,16.84,4.77c3.95,1.58,7.75,3.49,11.44,5.6
				c5.56,3.17,10.81,6.84,16.3,10.11c5.01,2.98,10.21,5.59,15.64,7.71C140.07,159.04,140.36,159.19,140.65,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M246.79,2.17V1.65
				c4.45-0.77,8.92-1.48,13.41-2.05c3.48-0.44,6.96-0.81,10.44-1.1c3.98-0.33,7.96-0.42,11.95-0.42c1.45,0,2.89,0.01,4.34,0.03v0.38
				c-0.39,0-0.77,0.01-1.16,0.01c-0.17,0-0.33,0-0.5-0.01c-0.58-0.02-1.16-0.02-1.73-0.02c-2.29,0-4.58,0.11-6.87,0.19
				c-3.69,0.13-7.36,0.43-11.04,0.82c-6.02,0.63-12,1.54-17.97,2.55C247.37,2.07,247.08,2.12,246.79,2.17 M117.89-4.26h-1.52
				c-2-0.61-4-1.2-6.01-1.76c-0.33-0.09-0.64-0.22-0.96-0.33c0.55,0,1.11,0,1.66,0c2.01,0.62,4.02,1.23,6.03,1.85
				C117.35-4.42,117.62-4.34,117.89-4.26" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M183.87,10.92
				c-1.6,0-3.2-0.02-4.8-0.07c-3.05-0.09-6.1-0.36-9.14-0.7c-3.57-0.4-7.11-0.97-10.63-1.66c-3.82-0.75-7.59-1.69-11.34-2.73
				c-7.56-2.1-14.98-4.64-22.43-7.09c-3.04-1-6.09-1.99-9.16-2.92h1.52c1.48,0.45,2.96,0.9,4.43,1.38c5.33,1.74,10.66,3.51,16,5.23
				c6.29,2.03,12.65,3.83,19.11,5.23c4.67,1.01,9.4,1.74,14.15,2.22c4.07,0.41,8.15,0.59,12.22,0.59c1.82,0,3.64-0.03,5.47-0.1
				c5.09-0.18,10.16-0.66,15.22-1.31c6.15-0.78,12.27-1.79,18.37-2.89c5.66-1.03,11.3-2.19,16.96-3.21
				c2.32-0.42,4.64-0.84,6.96-1.24v0.53c-4.96,0.85-9.9,1.8-14.85,2.74c-7.05,1.34-14.11,2.68-21.21,3.76
				c-4.01,0.61-8.03,1.15-12.07,1.53c-3.93,0.37-7.87,0.62-11.82,0.69C185.84,10.91,184.85,10.92,183.87,10.92" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,150.3v-0.54
				c3.75-0.88,7.5-1.79,11.24-2.69c3.8-0.91,7.59-1.89,11.39-2.77c5.36-1.24,10.73-2.41,16.15-3.4c3.08-0.57,6.16-1.08,9.26-1.5
				c0.18,0.16,0.36,0.32,0.54,0.48c-2.6,0.34-5.19,0.75-7.77,1.19c-5.13,0.88-10.23,1.91-15.3,3.11
				C12.75,146.2,4.26,148.29-4.25,150.3" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M123.17,159.32
				c-0.3,0-0.6,0-0.89,0c-2.37-1.42-4.74-2.83-7.1-4.27c-4.53-2.77-9.11-5.45-13.9-7.76c-6.89-3.32-14.08-5.72-21.61-7.1
				c-3.43-0.63-6.88-1.03-10.35-1.3c-2.43-0.19-4.86-0.25-7.29-0.25c-1.22,0-2.44,0.02-3.66,0.05c-2.39,0.06-4.78,0.25-7.17,0.45
				c-2.3,0.19-4.59,0.45-6.87,0.75c-0.18-0.16-0.36-0.32-0.54-0.48c1.23-0.17,2.47-0.32,3.71-0.45c2.87-0.31,5.74-0.55,8.62-0.7
				c1.88-0.09,3.76-0.13,5.63-0.13c1.54,0,3.08,0.02,4.62,0.06c2.94,0.07,5.88,0.35,8.81,0.74c3.86,0.52,7.67,1.26,11.41,2.3
				c4.84,1.34,9.53,3.08,14.07,5.24c4.77,2.26,9.32,4.9,13.82,7.65c2.59,1.58,5.22,3.11,7.83,4.66
				C122.61,158.94,122.89,159.14,123.17,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,144.5v-0.53
				c2.86-0.68,5.71-1.38,8.57-2.06c7.98-1.91,15.92-3.94,23.96-5.6c3.53-0.73,7.07-1.41,10.63-2c0.12,0.15,0.25,0.3,0.37,0.44
				c-0.22,0.03-0.44,0.07-0.66,0.11c-4.53,0.74-9.03,1.64-13.51,2.63c-7.74,1.7-15.43,3.62-23.13,5.51
				C-0.1,143.51-2.17,144.01-4.25,144.5" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M134.14,159.32
				c-0.34,0-0.68,0-1.02,0c-7.01-3.32-13.52-7.49-20.08-11.59c-3.6-2.26-7.29-4.36-11.11-6.24c-7.53-3.7-15.44-6.2-23.72-7.54
				c-3.29-0.53-6.6-0.88-9.93-1.05c-1.72-0.09-3.44-0.19-5.16-0.19c-0.27,0-0.54,0-0.82,0.01c-3.26,0.07-6.53,0.14-9.79,0.42
				c-4.43,0.38-8.84,0.92-13.23,1.62c-0.12-0.15-0.25-0.3-0.37-0.44c2.43-0.4,4.88-0.75,7.33-1.04c3.41-0.4,6.84-0.69,10.27-0.91
				c2.02-0.13,4.04-0.13,6.05-0.13c0.16,0,0.33,0,0.49,0c2.86,0,5.73,0.12,8.6,0.38c4.58,0.42,9.1,1.15,13.56,2.24
				c4.45,1.09,8.77,2.57,12.97,4.38c6.01,2.59,11.63,5.89,17.14,9.4c6.04,3.85,12.15,7.57,18.67,10.59L134.14,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M184,7.87
				c-0.41,0-0.82,0-1.23-0.01c-2.8-0.02-5.6-0.1-8.39-0.33c-5.27-0.42-10.5-1.15-15.69-2.19c-7.78-1.57-15.38-3.79-22.93-6.2
				c-3.55-1.14-7.11-2.27-10.67-3.4h1.58c2.57,0.82,5.14,1.64,7.7,2.46c8.58,2.76,17.22,5.3,26.09,6.96
				c3.9,0.73,7.84,1.23,11.79,1.64c3.81,0.39,7.63,0.52,11.46,0.52c1.55,0,3.1-0.02,4.64-0.06c4.37-0.1,8.73-0.46,13.08-0.95
				c5.6-0.62,11.16-1.46,16.71-2.4c5.61-0.95,11.2-2.02,16.79-3.07c3.95-0.74,7.9-1.47,11.86-2.13v0.52
				c-3.45,0.59-6.9,1.22-10.34,1.87c-8.16,1.54-16.32,3.08-24.52,4.36c-5.33,0.83-10.68,1.51-16.06,1.93
				C191.92,7.7,187.96,7.87,184,7.87" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,153.16v-0.51
				c1.07-0.25,2.14-0.51,3.2-0.76c6.69-1.61,13.38-3.25,20.08-4.82c6.62-1.55,13.27-2.95,19.99-4.03c2.61-0.42,5.22-0.77,7.84-1.07
				c0.19,0.15,0.39,0.3,0.58,0.44c-6.67,0.73-13.26,1.9-19.82,3.28c-5.84,1.22-11.65,2.59-17.45,4.01
				C5.36,150.86,0.56,152.04-4.25,153.16" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M118.2,159.32
				c-0.3,0-0.6,0-0.89,0c-0.82-0.68-1.78-1.15-2.69-1.69c-4.44-2.63-8.9-5.22-13.57-7.45c-7.23-3.46-14.78-5.92-22.69-7.26
				c-3.83-0.65-7.69-1.05-11.57-1.22c-1.8-0.08-3.6-0.11-5.41-0.11c-1.26,0-2.51,0.02-3.77,0.05c-3.13,0.09-6.26,0.36-9.39,0.69
				c-0.26,0.03-0.53,0.05-0.79,0.08c-0.19-0.15-0.39-0.29-0.58-0.44c1.3-0.15,2.6-0.28,3.91-0.4c3.68-0.34,7.37-0.53,11.06-0.53
				c1.36,0,2.72,0.03,4.08,0.08c8.05,0.3,15.96,1.56,23.67,4c5.49,1.74,10.73,4.06,15.81,6.73c4.21,2.22,8.26,4.72,12.36,7.14
				C117.91,159.07,118.05,159.2,118.2,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M185.08,4.76
				c-0.2,0-0.4,0-0.6,0c-3.01-0.02-6.02-0.04-9.03-0.25c-4.45-0.31-8.87-0.8-13.27-1.55c-8.47-1.46-16.75-3.7-24.95-6.26
				c-1.02-0.32-2.04-0.64-3.06-0.95h1.77c3.42,1.06,6.86,2.08,10.31,3.02c3.94,1.07,7.9,2.02,11.9,2.83
				c3.13,0.63,6.27,1.15,9.43,1.55c2.48,0.31,4.97,0.55,7.47,0.73c2.31,0.17,4.62,0.22,6.93,0.29c0.7,0.02,1.4,0.03,2.1,0.03
				c2.1,0,4.19-0.08,6.29-0.17c1.86-0.08,3.73-0.16,5.59-0.32c2.26-0.18,4.52-0.4,6.78-0.67c3.2-0.38,6.41-0.76,9.59-1.26
				c4.99-0.78,9.97-1.67,14.94-2.56c5.91-1.06,11.81-2.22,17.74-3.18c0.58-0.09,1.15-0.19,1.73-0.29h0.06v0.56
				c-2.11,0.36-4.22,0.73-6.33,1.08c-2.7,0.45-5.39,1-8.08,1.49c-4.37,0.79-8.73,1.57-13.1,2.35c-4.47,0.8-8.97,1.49-13.47,2.06
				c-3.66,0.46-7.34,0.85-11.02,1.09C191.55,4.59,188.31,4.76,185.08,4.76" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,156.02v-0.51
				c1.19-0.28,2.39-0.56,3.58-0.84c9.81-2.35,19.6-4.84,29.49-6.87c5.04-1.03,10.1-1.94,15.19-2.6c2.14-0.28,4.29-0.5,6.45-0.7
				c0.23,0.15,0.47,0.3,0.7,0.45c-0.66,0.06-1.32,0.12-1.98,0.19c-6.96,0.66-13.85,1.84-20.69,3.25
				c-6.39,1.31-12.74,2.82-19.07,4.36C4.86,153.86,0.31,154.96-4.25,156.02" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M113.23,159.32
				c-0.3,0-0.6,0-0.89,0c-0.93-0.72-1.98-1.23-3-1.8c-4.74-2.67-9.59-5.13-14.67-7.11c-6.23-2.42-12.65-4.08-19.26-5
				c-2.82-0.39-5.64-0.64-8.49-0.79c-1.76-0.09-3.52-0.12-5.27-0.12c-1.53,0-3.07,0.02-4.6,0.06c-1.96,0.05-3.93,0.2-5.89,0.38
				c-0.24-0.15-0.47-0.3-0.7-0.45c0.51-0.05,1.02-0.09,1.53-0.14c3.28-0.29,6.56-0.36,9.84-0.36c0.73,0,1.45,0,2.18,0.01
				c4.96,0.04,9.87,0.54,14.75,1.41c6.96,1.23,13.66,3.34,20.1,6.22c3.7,1.66,7.31,3.53,10.82,5.56c0.99,0.58,1.99,1.14,2.99,1.71
				C112.87,159.02,113.09,159.11,113.23,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M184.83,1.7
				c-1.4,0-2.79-0.02-4.19-0.04c-3.18-0.06-6.35-0.26-9.52-0.58c-3.7-0.38-7.37-0.89-11.03-1.55c-3.49-0.63-6.95-1.42-10.39-2.28
				c-1.91-0.47-3.81-0.98-5.7-1.51h2.11c4.16,1.12,8.35,2.11,12.58,2.92c3.94,0.76,7.91,1.32,11.9,1.76
				c4.43,0.48,8.87,0.66,13.31,0.66c1.65,0,3.3-0.02,4.95-0.07c3.5-0.09,6.99-0.36,10.48-0.69c6.5-0.61,12.94-1.56,19.38-2.62
				c3.69-0.61,7.37-1.28,11.06-1.95h3.45c-2.85,0.52-5.69,1.04-8.55,1.53c-4.65,0.8-9.29,1.6-13.96,2.26
				c-3.51,0.5-7.03,0.95-10.55,1.33c-1.85,0.2-3.72,0.26-5.58,0.44C191.33,1.61,188.08,1.7,184.83,1.7" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M2.49,157.27H0.31
				c2.14-0.51,4.28-1.02,6.42-1.53c5.89-1.4,11.77-2.85,17.69-4.13c5.52-1.2,11.07-2.27,16.66-3.1c3.59-0.53,7.19-0.91,10.81-1.22
				c1-0.09,2-0.16,3-0.21c0.3,0.15,0.59,0.3,0.89,0.45c-2.25,0.1-4.49,0.32-6.73,0.53c-6.54,0.61-13.01,1.71-19.45,2.98
				c-5.57,1.1-11.09,2.39-16.61,3.71C9.5,155.6,5.99,156.43,2.49,157.27" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M108,159.32
				c-0.3,0-0.6,0-0.89,0c-1.96-1.2-4.04-2.17-6.11-3.16c-3.88-1.85-7.87-3.43-11.97-4.71c-4.86-1.52-9.82-2.56-14.86-3.24
				c-4.3-0.58-8.62-0.76-12.94-0.76c-1.39,0-2.77,0.02-4.16,0.05c-0.42,0.01-0.85,0.02-1.27,0.04c-0.3-0.15-0.6-0.3-0.89-0.45
				c2.15-0.12,4.31-0.17,6.46-0.17c1.43,0,2.85,0.02,4.28,0.07c5.89,0.18,11.71,0.98,17.46,2.31c8.03,1.87,15.56,4.98,22.82,8.82
				C106.62,158.48,107.4,158.75,108,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M14.6,157.27h-2.16
				c5.73-1.36,11.47-2.7,17.25-3.85c5.37-1.07,10.78-1.97,16.22-2.62c2.48-0.3,4.98-0.49,7.47-0.68c2.49-0.2,4.99-0.27,7.48-0.27
				c0.08,0,0.16,0,0.25,0c0.45,0.18,0.91,0.36,1.37,0.53c-0.34,0-0.67,0-1.01,0c-1.45,0-2.89,0.02-4.34,0.06
				c-3.22,0.08-6.43,0.37-9.64,0.71c-4.96,0.53-9.89,1.3-14.79,2.22C26.62,154.5,20.6,155.83,14.6,157.27" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#BD1544;" d="M102.26,159.32
				c-0.34,0-0.68,0-1.02,0c-2.77-1.4-5.65-2.56-8.56-3.63c-5.28-1.93-10.7-3.34-16.26-4.19c-2.56-0.39-5.13-0.66-7.71-0.87
				c-2.08-0.17-4.15-0.24-6.23-0.26c-0.46-0.17-0.91-0.35-1.37-0.53c1.39,0,2.79,0.03,4.18,0.07c4.35,0.12,8.67,0.61,12.96,1.37
				c6.15,1.09,12.11,2.85,17.89,5.21c1.85,0.76,3.68,1.55,5.47,2.44C101.83,159.04,102.04,159.19,102.26,159.32" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M197.53,159.32
				c-1.32,0-2.64,0-3.95,0c1.17-0.16,2.35-0.33,3.52-0.49c3.34-0.44,6.67-0.97,9.99-1.56h2.61c-1.54,0.29-3.08,0.57-4.62,0.83
				c-2.32,0.39-4.66,0.72-6.98,1.09C197.89,159.22,197.71,159.28,197.53,159.32 M246.79,148.97v-0.5c4.89-1.11,9.78-2.17,14.71-3.1
				c5.15-0.98,10.33-1.8,15.55-2.36c2.95-0.32,5.91-0.63,8.88-0.67c0.34-0.01,0.68-0.07,1.01-0.11v0.51
				c-3.87,0.23-7.74,0.51-11.6,0.97c-5.93,0.7-11.81,1.72-17.66,2.9C254.03,147.34,250.41,148.15,246.79,148.97" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M209.69,157.27h-2.61
				c2.97-0.53,5.94-1.11,8.89-1.73c6.74-1.41,13.43-2.99,20.13-4.58c3.56-0.84,7.11-1.68,10.68-2.49v0.5
				c-0.63,0.14-1.25,0.28-1.88,0.43c-8.33,1.89-16.62,3.96-24.96,5.77C216.53,155.91,213.12,156.62,209.69,157.27" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M27.8,157.27H25.1
				c2.66-0.57,5.33-1.1,8.01-1.6c4.53-0.84,9.09-1.54,13.67-2.06c2.25-0.25,4.52-0.41,6.78-0.57c2.52-0.18,5.04-0.24,7.56-0.24
				c1,0,2.01,0.01,3.01,0.02c2.29,0.03,4.57,0.25,6.85,0.45c0.85,0.07,1.7,0.16,2.55,0.27c0.08,0.02,0.16,0.04,0.24,0.06
				c0.39,0.16,0.8,0.38,1.12,0.68c-0.67-0.1-1.34-0.18-2.02-0.26c-1.83-0.22-3.67-0.39-5.52-0.48c-2.01-0.1-4.01-0.23-6.02-0.23
				c-0.35,0-0.7,0-1.05,0.01c-3.2,0.08-6.4,0.2-9.6,0.47c-4.26,0.35-8.49,0.89-12.7,1.57c-3.08,0.5-6.15,1.07-9.21,1.69
				C28.45,157.14,28.13,157.2,27.8,157.27" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M-44.41,4.1
				c0-0.13,0-0.25,0-0.38c2.72-0.04,5.44-0.03,8.15-0.15c2.9-0.13,5.8-0.33,8.7-0.58c4.59-0.4,9.16-0.95,13.72-1.59
				c3.2-0.45,6.4-0.94,9.59-1.47V0.4c-2.92,0.47-5.84,0.94-8.77,1.36c-4.41,0.64-8.83,1.2-13.27,1.6c-3.13,0.28-6.25,0.5-9.39,0.66
				c-1.47,0.07-2.93,0.09-4.4,0.09c-0.44,0-0.89,0-1.33,0c-0.44,0-0.89,0-1.33,0C-43.31,4.1-43.86,4.1-44.41,4.1 M23.94-4.26h-3.02
				c2.86-0.45,5.72-0.88,8.59-1.28c1.72-0.25,3.45-0.46,5.18-0.69c0.17-0.02,0.33-0.08,0.49-0.13c1.36,0,2.72,0,4.08,0
				C38.39-6.1,37.5-6.1,36.61-5.99c-3.71,0.45-7.41,0.96-11.11,1.5C24.99-4.41,24.46-4.34,23.94-4.26" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M-4.25,0.4v-0.47
				c0.73-0.12,1.45-0.24,2.18-0.37c5.19-0.9,10.39-1.72,15.59-2.61c2.47-0.42,4.94-0.82,7.41-1.21h3.02
				C16.13-3.07,8.36-1.66,0.56-0.39C-1.05-0.13-2.65,0.14-4.25,0.4" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M213.47,159.32
				c-0.76,0-1.53,0-2.29,0c1.04-0.4,2.14-0.51,3.22-0.72c2.11-0.42,4.21-0.87,6.3-1.32h2.15c-2.05,0.46-4.09,0.9-6.15,1.32
				C215.62,158.81,214.55,159.08,213.47,159.32 M246.79,151.73v-0.5c3.54-0.81,7.08-1.58,10.64-2.29
				c5.16-1.03,10.35-1.96,15.57-2.64c3.54-0.46,7.08-0.86,10.64-1.03c1.1-0.05,2.2-0.16,3.3-0.24v0.51
				c-3.56,0.2-7.11,0.46-10.65,0.86c-7.55,0.84-15,2.2-22.42,3.77C251.5,150.67,249.14,151.2,246.79,151.73" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#CC1249;" d="M222.85,157.27h-2.15
				c1.76-0.38,3.52-0.78,5.28-1.18c5.63-1.29,11.26-2.61,16.88-3.94c1.31-0.31,2.62-0.61,3.93-0.91v0.5
				c-2.76,0.63-5.52,1.27-8.27,1.91C233.29,154.85,228.08,156.11,222.85,157.27" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M226.1,159.32
				c-0.68,0-1.36,0-2.04,0c0.81-0.36,1.68-0.43,2.53-0.63c2.03-0.47,4.06-0.95,6.08-1.42h2.1c-2.71,0.63-5.42,1.26-8.12,1.88
				C226.46,159.2,226.28,159.26,226.1,159.32 M246.79,154.49V154c3.1-0.7,6.21-1.38,9.32-2.02c4.83-1,9.68-1.87,14.56-2.57
				c3.63-0.52,7.27-0.98,10.94-1.2c1.78-0.11,3.55-0.24,5.33-0.36v0.51c-3.22,0.18-6.43,0.4-9.64,0.73
				c-6.02,0.62-12,1.61-17.94,2.75C255.15,152.64,250.96,153.53,246.79,154.49" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M-41.84,1.31
				c-0.86,0-1.71,0-2.57-0.01c0-0.13,0-0.25,0-0.38c2.82-0.04,5.65-0.03,8.47-0.15c2.96-0.13,5.92-0.34,8.88-0.58
				c4.63-0.38,9.24-0.98,13.84-1.6c3-0.4,5.98-0.88,8.97-1.36v0.49c-5.01,0.8-10.03,1.55-15.07,2.15
				c-3.77,0.45-7.55,0.79-11.34,1.08C-34.39,1.24-38.11,1.31-41.84,1.31 M7.72-4.26H4.85c3.76-0.62,7.53-1.24,11.29-1.86
				c0.31-0.05,0.61-0.15,0.92-0.23c0.98,0,1.96,0,2.93,0c-1.14,0.36-2.33,0.43-3.5,0.63C13.57-5.23,10.65-4.74,7.72-4.26" />
                    <path style="clip-path:url(#SVGID_00000121992400830878332030000003010991861443904668_);fill:#EFD1D9;" d="M237.96,159.32
				c-0.68,0-1.36,0-2.04,0c1.54-0.49,3.13-0.77,4.7-1.14c1.31-0.31,2.61-0.61,3.92-0.91h2.13c-2.73,0.61-5.45,1.24-8.17,1.86
				C238.31,159.17,238.14,159.26,237.96,159.32 M246.79,157.25v-0.48c3.37-0.76,6.75-1.49,10.14-2.17
				c5.14-1.04,10.31-1.94,15.51-2.63c3.03-0.4,6.06-0.74,9.11-0.96c1.8-0.14,3.6-0.16,5.39-0.35v0.51
				c-4.71,0.22-9.39,0.63-14.07,1.24c-5.86,0.76-11.66,1.81-17.45,2.99C252.53,155.97,249.66,156.6,246.79,157.25" />
                </g>
                <g style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);">
                    <defs>
                        <rect id="SVGID_00000170242279723470187510000007524032711247727273_" x="12.03" y="115.41" width="120.83" height="25.34" />
                    </defs>
                    <clipPath id="SVGID_00000154413593999842257590000001288997748775776399_">
                        <use xlink:href="#SVGID_00000170242279723470187510000007524032711247727273_" style="overflow:visible;" />
                    </clipPath>
                    <path style="clip-path:url(#SVGID_00000154413593999842257590000001288997748775776399_);fill:#FFFFFF;" d="M22.67,115.41
				c0.83,0.12,1.52,1,1.93,1.63c0.15,0.24,0.31,0.48,0.47,0.72c0.06,0.08,0.14,0.07,0.23,0.07c0.33-0.03,0.5-1.11,0.38-1.63
				c-0.01-0.03-0.01-0.13-0.04-0.23c0.38,0.23,0.69,1.35,0.69,2.16c0,0.32-0.02,0.63-0.02,0.95c0.08,0.21,0.31,0.2,0.38,0.17
				c0.36-0.2,0.64-0.6,0.8-1.16c0.11,0.7-0.34,2.26-0.78,3.24c-0.05,0.09-0.08,0.17-0.13,0.26c0,0-0.02-0.01-0.02-0.01
				c-0.11-0.23-0.22-0.46-0.33-0.68c-0.24-0.46-0.54-0.9-0.91-1.3c-0.25-0.27-0.52-0.51-0.81-0.71c-0.15-0.1-0.44-0.28-0.57-0.37
				c-0.01-0.29-0.06-0.56-0.11-0.84C23.64,116.77,23.41,115.98,22.67,115.41" />
                    <path style="clip-path:url(#SVGID_00000154413593999842257590000001288997748775776399_);fill:#FFFFFF;" d="M30.09,116.18
				c0.34,0.19,0.63,0.62,0.86,0.92c1.33,1.77,1.26,4.05,0.85,6.29c-0.04,0.23-0.26,0.82,0.06,0.92c0.05,0.01,0.1,0.02,0.2-0.02
				c1.19-0.52,2.04-2.27,2.23-3.48c0.08-0.47,0.02-1.43,0.02-1.43c0.28,0.21,0.52,0.83,0.65,1.15c0.84,2.03,0.2,4.04-0.49,5.88
				c-0.21,0.55-0.54,1.06-0.63,1.67c-0.01,0.07-0.01,0.14,0.03,0.19c0.05,0.08,0.15,0.12,0.25,0.12c0.02,0,0.05-0.01,0.07-0.01
				c1.29-0.52,2.37-1.76,2.92-3c0.03-0.07,0.21-0.48,0.24-0.52c0.5,2.06-0.01,3.79-1.37,5.24c-0.58,0.61-1.3,1.05-1.9,1.6
				c-0.2,0.18-0.21,0.51,0.14,0.51c0.11-0.01,0.31-0.06,0.43-0.08c0.31-0.08,0.6-0.18,0.9-0.3c0.81-0.34,1.5-0.84,2.11-1.54
				c0.13-0.15,0.35-0.43,0.36-0.43c-0.23,1.79-1.52,3.1-3.04,3.93c-0.29,0.16-1.28,0.53-1.39,0.84c-0.16,0.43,0.51,0.39,0.78,0.39
				c0.08,0,0.31-0.01,0.74-0.12c0.23-0.05,1.19-0.45,1.66-0.68c-0.06,0.23-0.23,0.42-0.39,0.6c-0.72,0.79-1.59,1.33-2.61,1.55
				c-0.23,0.05-1.44,0.17-1.53,0.29c-0.35,0.5,1.45,0.99,2.07,0.96c0.1-0.01,0.4-0.01,0.37-0.01c0,0.01-0.31,0.39-0.69,0.6
				c-1.21,0.67-2.59,0.57-3.8,0.02c-0.4-0.18-0.95-0.7-1.38-0.71c-0.12,0-0.22,0.05-0.3,0.13c-0.35,0.35,0.25,1.12,0.48,1.4
				c0.4,0.51,0.89,0.94,1.43,1.26c0.06,0.04,0.13,0.08,0.19,0.11c-0.18,0.01-2.05-0.22-2.95-0.58c-0.43-0.17-1.19-0.65-1.55-0.66
				c-0.3-0.01-0.57,0.33-0.79,0.51c-0.15,0.12-0.3,0.2-0.45,0.29c-0.53,0.32-1.09,0.52-1.71,0.66c-0.35,0.08-0.79,0.11-1.19,0.1
				c0,0,1.55-1.91,0.9-2.12c-0.22-0.07-1.31,0.69-1.61,0.83c-0.3,0.13-0.62,0.22-0.95,0.29c-0.55,0.12-1.18,0.17-1.77,0.09
				c-0.27-0.03-0.71-0.19-0.71-0.19c0.34-0.01,0.71-0.03,0.9-0.07c0.26-0.06,0.41-0.11,0.62-0.21c0.22-0.09,1.23-0.67,1.08-1
				c-0.11-0.24-0.59-0.08-0.78-0.07c-2.11,0.15-3.14-0.35-3.92-0.72c-0.11-0.05-0.85-0.39-1.09-0.68c1.4,0.34,2.68,0.01,2.92-0.02
				c0.34-0.07,1.01-0.2,0.83-0.59c-0.04-0.09-0.13-0.14-0.21-0.17c-0.31-0.08-0.62-0.16-0.93-0.24c-0.39-0.1-0.77-0.25-1.14-0.41
				c-1.78-0.73-3.41-2.24-3.94-4.14c-0.11-0.38-0.19-1.11-0.13-1.21c0.67,1.06,2.17,2.5,3.5,2.5c0.06-0.01,0.1-0.05,0.12-0.1
				c0.21-0.29-0.31-0.59-0.49-0.74c-1.78-1.44-2.93-3.45-3.12-5.75c-0.02-0.24-0.01-0.49,0.01-0.73c0.01-0.02,0.01-0.04,0.02-0.06
				v0.01c0.09,0.16,0.18,0.32,0.26,0.47c0.13,0.19,0.26,0.38,0.39,0.57c0.32,0.42,1.82,1.85,2.33,1.36c0.11-0.11,0.1-0.26,0.05-0.4
				c-0.18-0.59-0.53-1.09-0.75-1.66c-0.63-1.65-0.34-3.47,0.39-5c0.1-0.22,0.54-0.72,0.62-0.82c0.06-0.08-0.08,0.44-0.11,0.55
				c-0.3,1.2,0.19,2.36,0.9,3.19c0.16,0.18,0.35,0.44,0.62,0.44c0.13-0.01,0.18-0.1,0.21-0.16c0.1-0.22-0.07-0.66-0.09-0.92
				c-0.03-0.35-0.05-0.69-0.08-1.04c-0.06-1.38,0.35-2.74,1.02-3.76c0.12-0.18,0.25-0.35,0.38-0.53c-0.04,0.18-0.09,0.36-0.13,0.53
				c-0.07,0.24-0.11,0.5-0.14,0.76c-0.09,0.77,0.18,3.26,1.1,3.26c0.17-0.01,0.3-0.14,0.34-0.34c0.07-0.42-0.13-0.88-0.16-1.29
				c-0.09-1.17,0.23-2.38,0.85-3.21c0.08-0.11,0.18-0.25,0.27-0.34c-0.12,0.9-0.32,1.84-0.09,2.76c0.33,1.33,1.33,2.36,1.31,3.88
				c-0.02,1.14-0.51,2.14-0.91,3.11c-0.24,0.57-0.6,1.23-0.49,1.93c0.01,0.03,0.01,0.05,0.02,0.08c0.16,0.78,0.79,1.32,1.63,1.32
				c0.05,0,0.09-0.01,0.14-0.01c0.02,0,0.05-0.01,0.07-0.01c0.02,0,0.05-0.01,0.07-0.01c0.27-0.11,0.52-0.15,0.76-0.32
				c0.54-0.39,0.86-1,1.03-1.59c0.51-1.74,0.2-3.39-0.52-4.91c-0.21-0.44-0.45-0.86-0.74-1.26c-0.13-0.16-0.26-0.32-0.39-0.48
				c0.05-0.07,0.26,0.01,0.3,0.03c0.68,0.24,1.12,0.84,1.54,1.39c0.4,0.52,0.69,0.03,0.48-0.45c-0.38-0.86-1.3-2.1-2.32-2.24
				c-0.14-0.01-0.28-0.01-0.43-0.02h-0.01v-0.01h0.01c0.22-0.3,0.64-0.48,1.03-0.52c0.38-0.04,1,0.17,1.29,0.34
				c0.69,0.41,1.15,1.05,1.5,1.73c0.56,1.1,0.86,2.32,1.03,3.62c0.18,1.4,0.18,2.85,0.5,4.18c0.09,0.38,0.18,0.89,0.67,0.89
				c0.08-0.04,0.15-0.09,0.23-0.13c0.01-0.01,0.03-0.03,0.04-0.04c0.02-0.03,0.05-0.06,0.07-0.09c0.01-0.02,0.03-0.04,0.04-0.06
				c0.02-0.03,0.04-0.07,0.06-0.1c0.01-0.03,0.03-0.05,0.04-0.07c0.02-0.04,0.04-0.07,0.05-0.11c0.02-0.05,0.04-0.1,0.07-0.15
				c0.37-1.04,0.11-2.26,0.01-3.34c-0.11-1.13-0.17-2.34-0.01-3.47c0.13-0.89,0.43-1.77,0.82-2.53c0.45-0.88,1.1-1.61,1.11-2.72
				c0-0.06,0-0.12-0.01-0.18c0-0.03-0.01-0.06-0.01-0.08c0-0.03-0.01-0.06-0.01-0.09c0-0.02,0-0.04-0.01-0.06
				c0-0.01,0-0.03-0.01-0.04c0-0.02-0.01-0.03-0.01-0.05c-0.01-0.07-0.03-0.14-0.04-0.2c0-0.01,0-0.02-0.01-0.03
				c-0.01-0.05-0.03-0.11-0.04-0.16c-0.03-0.08-0.05-0.16-0.08-0.24c0-0.01-0.01-0.02-0.01-0.03c0-0.01-0.01-0.02-0.01-0.03
				C30.12,116.26,30.1,116.22,30.09,116.18" />
                </g>
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M22.82,124.21
			c0.01,0,0.3,0.85,0.36,1.33c0.08,0.65,0.07,2.97-1.04,2.77c-0.17-0.03-0.34-0.1-0.41-0.25c-0.37-0.78,0.19-1.2,0.67-2.19
			C22.61,125.43,22.79,124.86,22.82,124.21" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M43.1,139.93
			c0.63,0,0.92-0.19,0.92-0.61c0-0.37-0.26-0.61-0.94-0.61h-0.55v1.21H43.1L43.1,139.93z M42.53,138.25h0.38
			c0.57,0,0.78-0.29,0.78-0.61c0-0.38-0.21-0.55-0.7-0.55h-0.47V138.25L42.53,138.25z M43.08,136.62c0.77,0,1.17,0.42,1.17,0.98
			c0,0.38-0.21,0.64-0.58,0.8c0.56,0.11,0.91,0.5,0.91,0.97c0,0.76-0.71,1.03-1.38,1.03h-1.21v-3.79H43.08L43.08,136.62z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M45.65,138.28H46
			c0.39,0,0.74-0.1,0.74-0.61c0-0.37-0.22-0.58-0.63-0.58h-0.46V138.28L45.65,138.28z M46.15,136.62c0.76,0,1.15,0.5,1.15,1.05
			c0,0.44-0.22,0.77-0.63,0.93c0.22,0.14,0.33,0.23,0.69,0.79l0.67,1.01h-0.64l-0.6-0.93c-0.38-0.6-0.62-0.72-0.89-0.72h-0.24v1.65
			h-0.54v-3.79H46.15L46.15,136.62z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M49.27,138.8h1.19
			l-0.62-1.47L49.27,138.8L49.27,138.8z M50.04,136.6l1.69,3.81h-0.56l-0.51-1.12h-1.61l-0.48,1.12h-0.56l1.63-3.81H50.04
			L50.04,136.6z" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="52.6,136.62
			55.14,139.56 55.14,136.62 55.66,136.62 55.66,140.41 55.19,140.41 52.67,137.49 52.67,140.41 52.15,140.41 52.15,136.62
			52.6,136.62 		" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M57.8,139.92
			c0.96,0,1.5-0.53,1.5-1.43c0-0.82-0.51-1.38-1.58-1.38h-0.79v2.82H57.8L57.8,139.92z M57.82,136.62c1.27,0,2.04,0.78,2.04,1.85
			c0,1.17-0.84,1.94-2.04,1.94h-1.44v-3.79H57.82L57.82,136.62z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M62.37,136.84v0.62
			c-0.26-0.25-0.55-0.39-0.89-0.39c-0.34,0-0.56,0.18-0.56,0.44c0,0.21,0.04,0.39,0.62,0.73c0.8,0.46,0.97,0.73,0.97,1.24
			c0,0.56-0.44,0.99-1.1,0.99c-0.37,0-0.72-0.13-1.02-0.35v-0.69c0.27,0.35,0.63,0.56,1.02,0.56c0.32,0,0.56-0.21,0.56-0.5
			c0-0.26-0.07-0.43-0.82-0.87c-0.56-0.33-0.77-0.63-0.77-1.09c0-0.54,0.47-0.94,1.12-0.94C61.8,136.57,62.1,136.67,62.37,136.84
			L62.37,136.84z" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="63.73,136.62
			63.73,138.35 65.32,136.62 66,136.62 64.35,138.41 66.4,140.41 65.66,140.41 63.73,138.54 63.73,140.41 63.18,140.41
			63.18,136.62 63.73,136.62 		" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="67.06,136.62
			68.07,137.93 69.1,136.62 69.76,136.62 68.36,138.4 68.36,140.41 67.8,140.41 67.8,138.4 66.4,136.62 67.06,136.62 		" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M71.59,139.92
			c0.96,0,1.5-0.53,1.5-1.43c0-0.82-0.51-1.38-1.58-1.38h-0.79v2.82H71.59L71.59,139.92z M71.61,136.62c1.27,0,2.04,0.78,2.04,1.85
			c0,1.17-0.84,1.94-2.04,1.94h-1.44v-3.79H71.61L71.61,136.62z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M75.74,139.92
			c0.96,0,1.5-0.53,1.5-1.43c0-0.82-0.51-1.38-1.58-1.38h-0.79v2.82H75.74L75.74,139.92z M75.76,136.62c1.27,0,2.04,0.78,2.04,1.85
			c0,1.17-0.84,1.94-2.04,1.94h-1.44v-3.79H75.76L75.76,136.62z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M80.31,136.84v0.62
			c-0.26-0.25-0.55-0.39-0.89-0.39c-0.34,0-0.56,0.18-0.56,0.44c0,0.21,0.04,0.39,0.62,0.73c0.8,0.46,0.97,0.73,0.97,1.24
			c0,0.56-0.44,0.99-1.1,0.99c-0.37,0-0.72-0.13-1.02-0.35v-0.69c0.27,0.35,0.63,0.56,1.02,0.56c0.32,0,0.56-0.21,0.56-0.5
			c0-0.26-0.07-0.43-0.82-0.87c-0.56-0.33-0.77-0.63-0.77-1.09c0-0.54,0.47-0.94,1.12-0.94C79.74,136.57,80.04,136.67,80.31,136.84
			L80.31,136.84z" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="83.14,136.62
			83.14,137.11 81.66,137.11 81.66,138.16 83.14,138.16 83.14,138.65 81.66,138.65 81.66,140.41 81.12,140.41 81.12,136.62
			83.14,136.62 		" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M86.09,135.63
			c0.16,0,0.29,0.14,0.29,0.29c0,0.16-0.14,0.29-0.29,0.29c-0.16,0-0.29-0.13-0.29-0.29C85.79,135.77,85.93,135.63,86.09,135.63
			L86.09,135.63z M85.09,135.63c0.16,0,0.29,0.14,0.29,0.29c0,0.16-0.14,0.29-0.29,0.29c-0.16,0-0.29-0.13-0.29-0.29
			C84.79,135.77,84.93,135.63,85.09,135.63L85.09,135.63z M84.11,138.53c0,0.84,0.67,1.42,1.45,1.42c0.81,0,1.5-0.48,1.5-1.44
			c0-0.64-0.49-1.42-1.47-1.42C84.65,137.08,84.11,137.72,84.11,138.53L84.11,138.53z M87.62,138.51c0,1.01-0.75,1.95-2.07,1.95
			c-1.2,0-2-0.89-2-1.93c0-0.99,0.71-1.95,2.03-1.95C86.78,136.57,87.62,137.43,87.62,138.51L87.62,138.51z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M88.83,138.28h0.35
			c0.39,0,0.74-0.1,0.74-0.61c0-0.37-0.22-0.58-0.63-0.58h-0.46V138.28L88.83,138.28z M89.33,136.62c0.76,0,1.15,0.5,1.15,1.05
			c0,0.44-0.22,0.77-0.63,0.93c0.22,0.14,0.33,0.23,0.69,0.79l0.67,1.01h-0.64l-0.6-0.93c-0.38-0.6-0.62-0.72-0.89-0.72h-0.24v1.65
			h-0.54v-3.79H89.33L89.33,136.62z" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="93.77,136.62
			93.77,137.1 92.17,137.1 92.17,138.26 93.71,138.26 93.71,138.74 92.17,138.74 92.17,139.92 93.82,139.92 93.82,140.41
			91.62,140.41 91.62,136.62 93.77,136.62 		" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="94.96,136.62
			97.5,139.56 97.5,136.62 98.02,136.62 98.02,140.41 97.55,140.41 95.04,137.49 95.04,140.41 94.51,140.41 94.51,136.62
			94.96,136.62 		" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="99.4,136.62
			99.4,140.41 98.86,140.41 98.86,136.62 99.4,136.62 		" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="
			100.68,136.62 103.22,139.56 103.22,136.62 103.74,136.62 103.74,140.41 103.27,140.41 100.76,137.49 100.76,140.41
			100.23,140.41 100.23,136.62 100.68,136.62 		" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M107.79,137.45
			c-0.43-0.26-0.92-0.39-1.34-0.39c-0.82,0-1.47,0.62-1.47,1.46c0,0.84,0.62,1.47,1.49,1.47c0.29,0,0.44-0.03,0.86-0.17v-0.79h-0.73
			v-0.48h1.27v1.6c-0.47,0.22-0.92,0.31-1.39,0.31c-1.44,0-2.07-1.02-2.07-1.91c0-1.06,0.81-1.98,2.05-1.98
			c0.43,0,0.77,0.08,1.33,0.32V137.45L107.79,137.45z" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="
			110.81,136.62 110.81,137.1 109.2,137.1 109.2,138.26 110.75,138.26 110.75,138.74 109.2,138.74 109.2,139.92 110.86,139.92
			110.86,140.41 108.66,140.41 108.66,136.62 110.81,136.62 		" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="112,136.62
			114.54,139.56 114.54,136.62 115.06,136.62 115.06,140.41 114.59,140.41 112.07,137.49 112.07,140.41 111.55,140.41
			111.55,136.62 112,136.62 		" />
                <polygon style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" points="48.66,124.74
			48.66,128.02 44.61,128.02 44.61,124.74 41.91,124.74 41.91,133.68 44.61,133.68 44.61,130.19 48.66,130.19 48.66,133.68
			51.35,133.68 51.35,124.74 48.66,124.74 		" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M56.98,129.81
			c0-0.42-0.1-0.76-0.31-1.01c-0.21-0.25-0.51-0.38-0.9-0.38c-0.39,0-0.7,0.13-0.94,0.38c-0.24,0.26-0.36,0.59-0.36,1H56.98
			L56.98,129.81z M59.16,130.96h-4.62c0.09,0.4,0.31,0.73,0.66,0.98c0.35,0.25,0.75,0.38,1.2,0.38c0.78,0,1.65-0.22,2.59-0.66v1.44
			c-0.34,0.18-0.75,0.34-1.23,0.48c-0.48,0.14-1.1,0.22-1.85,0.22c-0.81,0-1.5-0.15-2.06-0.45c-0.57-0.3-0.99-0.71-1.27-1.23
			c-0.28-0.52-0.42-1.1-0.42-1.75c0-0.64,0.14-1.22,0.42-1.73c0.28-0.51,0.71-0.91,1.27-1.2c0.57-0.29,1.26-0.44,2.06-0.44
			c0.53,0,1.05,0.11,1.54,0.35c0.5,0.23,0.9,0.61,1.22,1.13c0.32,0.52,0.48,1.2,0.48,2.02V130.96L59.16,130.96z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M65.21,133.3
			c-0.24,0.12-0.57,0.23-1,0.34c-0.42,0.11-0.83,0.16-1.22,0.16c-1.67,0-2.51-0.82-2.51-2.45v-2.62h-1.05v-0.41l3.11-3.17h0.26v1.97
			h2.22v1.61h-2.22v2.1c0,0.24,0.02,0.44,0.05,0.59c0.03,0.15,0.12,0.28,0.26,0.38c0.14,0.1,0.35,0.15,0.63,0.15
			c0.37,0,0.85-0.15,1.46-0.46V133.3L65.21,133.3z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M69.81,132.09v-1.55
			c-0.55,0.22-0.95,0.43-1.21,0.6c-0.25,0.18-0.38,0.42-0.38,0.73c0,0.22,0.06,0.39,0.19,0.5c0.13,0.12,0.27,0.17,0.44,0.17
			c0.16,0,0.32-0.04,0.47-0.13C69.49,132.32,69.65,132.22,69.81,132.09 M73.04,133.35c-0.34,0.13-0.65,0.24-0.94,0.32
			c-0.29,0.08-0.63,0.12-1.04,0.12c-0.38,0-0.65-0.07-0.8-0.2c-0.15-0.13-0.26-0.3-0.32-0.49c-0.25,0.2-0.53,0.37-0.83,0.5
			c-0.3,0.13-0.71,0.2-1.2,0.2c-0.48,0-0.86-0.08-1.16-0.25c-0.29-0.17-0.5-0.37-0.62-0.63c-0.12-0.25-0.18-0.51-0.18-0.78
			c0-0.4,0.1-0.74,0.31-1c0.2-0.26,0.48-0.48,0.82-0.65c0.34-0.17,0.8-0.34,1.37-0.52c0.32-0.1,0.57-0.18,0.73-0.24
			c0.16-0.05,0.29-0.1,0.37-0.13c0.08-0.03,0.17-0.07,0.26-0.12c0-0.32-0.06-0.57-0.19-0.75c-0.13-0.18-0.41-0.27-0.84-0.27
			c-0.51,0-0.98,0.11-1.41,0.34c-0.43,0.22-0.83,0.51-1.19,0.86v-1.78c0.29-0.21,0.6-0.38,0.94-0.51c0.34-0.13,0.69-0.23,1.05-0.29
			c0.37-0.06,0.74-0.1,1.11-0.1c0.95,0,1.67,0.18,2.14,0.55c0.48,0.37,0.72,0.87,0.72,1.52v2.53c0,0.24,0.02,0.42,0.07,0.54
			c0.05,0.12,0.13,0.18,0.25,0.18c0.15,0,0.34-0.06,0.59-0.19V133.35L73.04,133.35z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M82.49,130.34
			l-1.31-3.26l-1.32,3.26H82.49L82.49,130.34z M86.6,133.71h-2.76l-0.73-1.81h-3.88l-0.74,1.81h-2.76l3.95-8.94h2.98L86.6,133.71
			L86.6,133.71z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M92.91,127.5
			l-0.86,2.33c-0.35-0.34-0.71-0.5-1.07-0.5c-0.33,0-0.61,0.11-0.85,0.33c-0.24,0.22-0.47,0.63-0.69,1.23v2.82h-2.32v-6.57h2.32
			v1.59c0.15-0.39,0.4-0.77,0.77-1.15c0.37-0.37,0.8-0.56,1.3-0.56c0.26,0,0.49,0.04,0.69,0.12
			C92.41,127.22,92.64,127.34,92.91,127.5" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M98.15,130.42
			c0-0.56-0.13-0.99-0.38-1.29c-0.25-0.29-0.56-0.44-0.9-0.44c-0.35,0-0.74,0.14-1.17,0.42v2.51c0.33,0.31,0.72,0.46,1.17,0.46
			c0.29,0,0.57-0.12,0.86-0.35C98.01,131.5,98.15,131.06,98.15,130.42 M100.6,130.47c0,0.76-0.15,1.39-0.44,1.89
			c-0.3,0.5-0.68,0.87-1.13,1.11c-0.46,0.24-0.93,0.35-1.41,0.35c-0.34,0-0.64-0.04-0.89-0.12c-0.25-0.08-0.45-0.17-0.59-0.26
			c-0.14-0.09-0.29-0.2-0.44-0.33v0.59h-2.32v-8.94h2.32v2.76c0.17-0.13,0.41-0.25,0.71-0.35c0.3-0.1,0.65-0.15,1.04-0.15
			c0.53,0,1,0.1,1.4,0.29c0.4,0.19,0.73,0.45,0.99,0.78c0.26,0.33,0.45,0.69,0.57,1.1C100.54,129.6,100.6,130.02,100.6,130.47" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M105.83,129.84
			c0-0.42-0.1-0.76-0.31-1.01c-0.21-0.25-0.51-0.38-0.9-0.38c-0.39,0-0.7,0.13-0.94,0.38c-0.24,0.26-0.36,0.59-0.36,1H105.83
			L105.83,129.84z M108.01,130.99h-4.62c0.09,0.4,0.31,0.73,0.66,0.98c0.35,0.25,0.75,0.38,1.2,0.38c0.78,0,1.65-0.22,2.59-0.66
			v1.44c-0.34,0.18-0.75,0.34-1.23,0.48c-0.48,0.14-1.1,0.22-1.85,0.22c-0.81,0-1.5-0.15-2.06-0.45c-0.57-0.3-0.99-0.71-1.27-1.23
			c-0.28-0.52-0.42-1.1-0.42-1.75c0-0.64,0.14-1.22,0.42-1.73c0.28-0.51,0.71-0.91,1.27-1.2c0.57-0.29,1.26-0.44,2.06-0.44
			c0.53,0,1.05,0.12,1.54,0.35c0.5,0.23,0.91,0.61,1.23,1.13c0.32,0.52,0.48,1.2,0.48,2.02V130.99L108.01,130.99z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M114.07,133.33
			c-0.24,0.12-0.57,0.23-1,0.34c-0.42,0.11-0.83,0.16-1.22,0.16c-1.67,0-2.51-0.82-2.51-2.45v-2.62h-1.05v-0.41l3.11-3.17h0.27v1.97
			h2.22v1.61h-2.22v2.1c0,0.25,0.01,0.44,0.04,0.59c0.03,0.15,0.12,0.28,0.26,0.38c0.14,0.1,0.35,0.15,0.63,0.15
			c0.37,0,0.86-0.16,1.46-0.46V133.33L114.07,133.33z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M119.37,129.84
			c0-0.42-0.1-0.76-0.31-1.01c-0.21-0.25-0.51-0.38-0.9-0.38c-0.39,0-0.7,0.13-0.94,0.38c-0.24,0.26-0.36,0.59-0.36,1H119.37
			L119.37,129.84z M121.55,130.99h-4.62c0.09,0.4,0.31,0.73,0.66,0.98c0.35,0.25,0.75,0.38,1.2,0.38c0.78,0,1.65-0.22,2.59-0.66
			v1.44c-0.34,0.18-0.75,0.34-1.23,0.48c-0.48,0.14-1.1,0.22-1.85,0.22c-0.81,0-1.5-0.15-2.06-0.45c-0.57-0.3-0.99-0.71-1.27-1.23
			c-0.28-0.52-0.42-1.1-0.42-1.75c0-0.64,0.14-1.22,0.42-1.73c0.28-0.51,0.71-0.91,1.27-1.2c0.57-0.29,1.25-0.44,2.06-0.44
			c0.53,0,1.05,0.12,1.54,0.35c0.5,0.23,0.91,0.61,1.23,1.13c0.32,0.52,0.48,1.2,0.48,2.02V130.99L121.55,130.99z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M129.18,133.71h-2.32
			v-3.54c0-0.36-0.06-0.68-0.18-0.95c-0.12-0.27-0.38-0.4-0.78-0.4c-0.19,0-0.36,0.04-0.5,0.11c-0.14,0.07-0.27,0.16-0.39,0.27
			c-0.11,0.11-0.21,0.22-0.29,0.32c-0.08,0.1-0.16,0.21-0.24,0.33v3.86h-2.32v-6.57h2.32v0.94c0.36-0.39,0.73-0.67,1.1-0.82
			c0.37-0.16,0.78-0.24,1.23-0.24c1.57,0,2.36,0.94,2.36,2.82V133.71L129.18,133.71z" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M131.38,125.68
			c0-0.07-0.03-0.13-0.09-0.17c-0.06-0.04-0.14-0.05-0.24-0.05h-0.21v0.44h0.19c0.11,0,0.2-0.02,0.26-0.05
			C131.35,125.82,131.38,125.76,131.38,125.68 M131.93,126.92h-0.52c-0.09-0.2-0.18-0.35-0.25-0.46c-0.07-0.11-0.12-0.18-0.16-0.21
			c-0.04-0.02-0.1-0.03-0.15-0.03v0.7h-0.44v-1.76h0.78c0.19,0,0.35,0.04,0.46,0.12c0.11,0.08,0.17,0.21,0.17,0.38
			c0,0.16-0.05,0.27-0.15,0.34c-0.1,0.07-0.21,0.12-0.33,0.14c0.1,0.04,0.17,0.1,0.22,0.16c0.05,0.07,0.12,0.17,0.19,0.31
			L131.93,126.92L131.93,126.92z M132.42,126.04c0-0.19-0.03-0.36-0.1-0.51c-0.07-0.16-0.16-0.3-0.29-0.42
			c-0.12-0.12-0.27-0.22-0.43-0.28c-0.16-0.07-0.33-0.1-0.51-0.1c-0.18,0-0.34,0.03-0.51,0.1c-0.16,0.07-0.31,0.16-0.43,0.28
			c-0.12,0.12-0.22,0.26-0.28,0.42c-0.07,0.16-0.1,0.33-0.1,0.51c0,0.24,0.06,0.46,0.18,0.66c0.12,0.2,0.28,0.36,0.48,0.48
			c0.2,0.12,0.42,0.18,0.67,0.18c0.24,0,0.46-0.06,0.67-0.18c0.2-0.12,0.36-0.28,0.48-0.48
			C132.36,126.5,132.42,126.28,132.42,126.04 M132.86,126.08c0,0.31-0.08,0.6-0.24,0.86c-0.16,0.27-0.37,0.48-0.65,0.63
			c-0.27,0.15-0.57,0.23-0.89,0.23c-0.32,0-0.61-0.08-0.89-0.23c-0.27-0.15-0.49-0.37-0.65-0.63c-0.16-0.27-0.24-0.55-0.24-0.86
			c0-0.24,0.05-0.47,0.14-0.68c0.09-0.21,0.22-0.39,0.38-0.55c0.16-0.16,0.35-0.28,0.57-0.37c0.22-0.09,0.44-0.13,0.68-0.13
			c0.24,0,0.47,0.04,0.69,0.13c0.22,0.09,0.41,0.21,0.57,0.37c0.16,0.16,0.29,0.34,0.38,0.55
			C132.81,125.61,132.86,125.84,132.86,126.08" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M216.98,98.98h-0.31
			v-2.94h0.31v1.22h1.75v-1.22h0.31v2.94h-0.31v-1.44h-1.75V98.98 M215.08,98.98h-0.31v-2.66h-1.01v-0.28h2.35v0.28h-1.02V98.98
			 M211.37,96.33v1.05h0.99c0.2,0,0.36-0.05,0.47-0.14c0.11-0.09,0.17-0.22,0.17-0.39c0-0.17-0.06-0.3-0.17-0.39
			c-0.11-0.09-0.27-0.14-0.47-0.14H211.37 M211.37,98.98h-0.31v-2.94h1.32c0.29,0,0.51,0.07,0.68,0.22
			c0.17,0.14,0.25,0.34,0.25,0.58c0,0.21-0.06,0.39-0.18,0.53c-0.12,0.14-0.29,0.23-0.51,0.26l0.95,1.35h-0.37l-0.92-1.32h-0.9
			V98.98 M210.21,98.98h-0.31v-2.94h0.31V98.98 M207.14,97.56v1.14h1.04c0.22,0,0.39-0.05,0.51-0.15c0.12-0.1,0.18-0.24,0.18-0.42
			c0-0.18-0.06-0.32-0.18-0.42c-0.12-0.1-0.29-0.15-0.51-0.15H207.14 M207.14,96.33v0.96h0.91c0.19,0,0.33-0.04,0.43-0.12
			c0.1-0.08,0.15-0.2,0.15-0.35c0-0.16-0.05-0.28-0.15-0.36c-0.1-0.08-0.25-0.13-0.43-0.13H207.14 M208.23,98.98h-1.4v-2.94h1.27
			c0.26,0,0.47,0.06,0.62,0.19c0.15,0.13,0.23,0.3,0.23,0.52c0,0.15-0.03,0.28-0.1,0.39c-0.07,0.11-0.16,0.19-0.28,0.23
			c0.2,0.03,0.35,0.12,0.47,0.26c0.12,0.14,0.17,0.32,0.17,0.53c0,0.25-0.09,0.45-0.26,0.6C208.76,98.91,208.53,98.98,208.23,98.98
			 M202.94,98.98h-0.31v-2.94h2v0.28h-1.69v0.98h1.37v0.28h-1.37V98.98 M199.61,98.39c0.22,0.24,0.5,0.35,0.83,0.35
			c0.33,0,0.61-0.12,0.83-0.35c0.22-0.24,0.34-0.53,0.34-0.88c0-0.35-0.11-0.64-0.34-0.88c-0.22-0.24-0.5-0.35-0.83-0.35
			c-0.33,0-0.61,0.12-0.83,0.35c-0.22,0.24-0.34,0.53-0.34,0.88C199.27,97.86,199.39,98.15,199.61,98.39 M198.96,97.51
			c0-0.43,0.14-0.8,0.42-1.08c0.28-0.29,0.63-0.44,1.06-0.44c0.42,0,0.77,0.15,1.05,0.44c0.28,0.29,0.42,0.65,0.42,1.08
			c0,0.43-0.14,0.8-0.42,1.09c-0.28,0.29-0.63,0.43-1.05,0.43c-0.43,0-0.78-0.14-1.06-0.43C199.1,98.31,198.96,97.95,198.96,97.51
			 M196.89,98.98h-2.08v-2.94h2.02v0.28h-1.7v0.94h1.4v0.28h-1.4v1.15h1.77V98.98 M193.22,98.98h-0.31v-2.66h-1.01v-0.28h2.35v0.28
			h-1.03V98.98 M190.58,96.42l-0.67,1.48h1.33L190.58,96.42 M189.45,98.98h-0.32l1.31-2.96h0.29l1.31,2.96h-0.33l-0.37-0.81h-1.54
			L189.45,98.98 M186.67,96.33v2.38h0.61c0.4,0,0.72-0.11,0.96-0.32c0.23-0.21,0.35-0.5,0.35-0.86c0-0.36-0.12-0.65-0.35-0.87
			c-0.24-0.22-0.56-0.32-0.96-0.32H186.67 M188.48,98.58c-0.29,0.27-0.68,0.41-1.15,0.41h-0.96v-2.94h0.96
			c0.47,0,0.86,0.14,1.15,0.41c0.29,0.27,0.44,0.63,0.44,1.06C188.92,97.95,188.77,98.3,188.48,98.58" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M213.36,129.69v2.38
			h0.61c0.4,0,0.72-0.11,0.96-0.32c0.24-0.21,0.35-0.5,0.35-0.86c0-0.37-0.12-0.65-0.35-0.87c-0.24-0.22-0.56-0.32-0.96-0.32H213.36
			 M215.16,131.94c-0.29,0.27-0.68,0.41-1.15,0.41h-0.96v-2.94h0.96c0.48,0,0.86,0.14,1.15,0.41c0.29,0.27,0.44,0.63,0.44,1.06
			C215.6,131.31,215.45,131.67,215.16,131.94 M212.2,132.34h-0.31v-2.94h0.31V132.34 M207.5,129.69v1.05h0.99
			c0.2,0,0.36-0.05,0.47-0.14c0.11-0.09,0.17-0.22,0.17-0.39c0-0.17-0.06-0.3-0.17-0.39c-0.11-0.09-0.27-0.14-0.47-0.14H207.5
			 M207.5,132.34h-0.31v-2.94h1.31c0.29,0,0.51,0.07,0.68,0.22c0.17,0.14,0.25,0.34,0.25,0.58c0,0.21-0.06,0.39-0.18,0.52
			c-0.12,0.14-0.29,0.23-0.51,0.27l0.95,1.35h-0.37l-0.92-1.32h-0.9V132.34 M206.58,132.34h-2.08v-2.94h2.02v0.28h-1.71v0.94h1.4
			v0.28h-1.4v1.15h1.77V132.34 M201.25,132.34h-0.31v-2.94h0.31l1.06,1.42l1.06-1.42h0.3v2.94h-0.31v-2.47l-0.96,1.29h-0.19
			l-0.97-1.29V132.34 M197.92,131.75c0.22,0.24,0.5,0.35,0.83,0.35c0.33,0,0.61-0.12,0.83-0.35c0.22-0.24,0.34-0.53,0.34-0.88
			c0-0.35-0.11-0.64-0.34-0.88c-0.22-0.23-0.5-0.35-0.83-0.35c-0.33,0-0.61,0.12-0.83,0.35c-0.22,0.24-0.34,0.53-0.34,0.88
			C197.59,131.22,197.7,131.51,197.92,131.75 M197.27,130.88c0-0.43,0.14-0.8,0.42-1.08c0.28-0.29,0.64-0.44,1.06-0.44
			c0.42,0,0.77,0.15,1.05,0.44c0.28,0.29,0.43,0.65,0.43,1.08c0,0.43-0.14,0.8-0.43,1.09c-0.28,0.29-0.63,0.43-1.05,0.43
			c-0.43,0-0.78-0.14-1.06-0.43C197.41,131.67,197.27,131.31,197.27,130.88 M196.01,132.34h-0.31v-2.66h-1.01v-0.28h2.35v0.28h-1.03
			V132.34 M194.05,132.17c-0.21,0.15-0.47,0.23-0.77,0.23c-0.21,0-0.42-0.03-0.61-0.09c-0.2-0.06-0.35-0.13-0.48-0.22v-0.35
			c0.12,0.1,0.27,0.19,0.46,0.26c0.2,0.07,0.4,0.11,0.61,0.11c0.23,0,0.42-0.05,0.57-0.15c0.15-0.1,0.22-0.24,0.22-0.4
			c0.02-0.16-0.06-0.29-0.24-0.4l-0.18-0.08l-0.18-0.06l-0.05-0.01l-0.08-0.03l-0.09-0.03c-0.17-0.05-0.28-0.08-0.31-0.09
			l-0.26-0.11c-0.09-0.05-0.16-0.09-0.22-0.15c-0.12-0.13-0.18-0.29-0.18-0.48c0-0.22,0.09-0.4,0.27-0.55
			c0.18-0.14,0.43-0.21,0.75-0.21c0.35,0,0.66,0.08,0.92,0.24v0.32c-0.29-0.18-0.59-0.27-0.91-0.27c-0.23,0-0.4,0.04-0.52,0.13
			c-0.12,0.09-0.18,0.2-0.18,0.35c-0.02,0.11,0.05,0.22,0.19,0.33l0.14,0.07l0.14,0.06l0.18,0.05l0.18,0.05
			c0.15,0.05,0.25,0.08,0.29,0.09l0.26,0.11c0.09,0.05,0.17,0.1,0.23,0.16c0.14,0.13,0.2,0.29,0.2,0.5
			C194.37,131.81,194.26,132.02,194.05,132.17 M191.28,132.05c-0.22,0.23-0.51,0.34-0.86,0.34c-0.36,0-0.65-0.11-0.87-0.34
			c-0.22-0.22-0.33-0.54-0.33-0.96v-1.69h0.31v1.69c0,0.32,0.08,0.56,0.24,0.74c0.16,0.18,0.38,0.27,0.65,0.27
			c0.28,0,0.49-0.09,0.65-0.27c0.16-0.18,0.24-0.42,0.24-0.74v-1.69h0.31v1.69C191.62,131.51,191.51,131.83,191.28,132.05
			 M188.64,132.14c-0.24,0.17-0.54,0.26-0.89,0.26c-0.43,0-0.79-0.14-1.08-0.43c-0.29-0.29-0.43-0.65-0.43-1.09
			c0-0.44,0.14-0.8,0.43-1.09c0.29-0.29,0.65-0.43,1.08-0.43c0.32,0,0.59,0.07,0.82,0.22v0.32c-0.26-0.17-0.53-0.25-0.82-0.25
			c-0.34,0-0.62,0.12-0.85,0.35c-0.23,0.23-0.34,0.52-0.34,0.88c0,0.36,0.11,0.65,0.34,0.88c0.23,0.23,0.52,0.35,0.86,0.35
			s0.63-0.1,0.87-0.29V132.14" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M211.96,115.49
			c-0.22,0.23-0.51,0.34-0.87,0.34c-0.36,0-0.65-0.11-0.87-0.34c-0.22-0.23-0.33-0.55-0.33-0.96v-1.69h0.31v1.69
			c0,0.32,0.08,0.56,0.24,0.74c0.16,0.18,0.38,0.27,0.65,0.27c0.28,0,0.49-0.09,0.65-0.27c0.16-0.18,0.24-0.43,0.24-0.74v-1.69h0.31
			v1.69C212.3,114.94,212.19,115.26,211.96,115.49 M207.16,113.13v1.05h0.99c0.2,0,0.36-0.04,0.47-0.14
			c0.11-0.09,0.17-0.22,0.17-0.39c0-0.17-0.06-0.3-0.17-0.39c-0.11-0.09-0.27-0.14-0.47-0.14H207.16 M207.16,115.78h-0.31v-2.94
			h1.32c0.29,0,0.51,0.07,0.68,0.22c0.17,0.14,0.25,0.34,0.25,0.58c0,0.21-0.06,0.39-0.18,0.53c-0.12,0.14-0.29,0.23-0.51,0.26
			l0.95,1.35h-0.37l-0.92-1.32h-0.9V115.78 M203.97,115.78h-0.31v-2.94h0.31v1.22h1.75v-1.22h0.31v2.94h-0.31v-1.44h-1.75V115.78
			 M202.07,115.78h-0.31v-2.66h-1.01v-0.28h2.35v0.28h-1.02V115.78 M196.47,113.13v2.38h0.61c0.4,0,0.72-0.11,0.96-0.32
			c0.24-0.21,0.35-0.5,0.35-0.87c0-0.36-0.12-0.65-0.35-0.86c-0.24-0.22-0.56-0.32-0.96-0.32H196.47 M198.27,115.38
			c-0.29,0.27-0.68,0.41-1.15,0.41h-0.96v-2.94h0.96c0.48,0,0.86,0.14,1.15,0.41c0.29,0.27,0.44,0.63,0.44,1.06
			C198.71,114.75,198.57,115.1,198.27,115.38 M195.31,115.78H195v-2.94h0.31V115.78 M194.42,115.78h-1.95v-2.94h0.31v2.66h1.64
			V115.78 M190.47,113.22l-0.67,1.48h1.34L190.47,113.22 M189.34,115.78h-0.32l1.31-2.96h0.29l1.32,2.96h-0.33l-0.36-0.81h-1.54
			L189.34,115.78 M187.92,115.8h-0.29l-1.29-2.96h0.34l1.1,2.53l1.1-2.53h0.34L187.92,115.8" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M169.92,18.68h-0.44
			v-3.8h-1.45v-0.4h3.35v0.4h-1.46V18.68 M164.39,17.83c0.31,0.34,0.71,0.5,1.18,0.5c0.47,0,0.87-0.17,1.18-0.5
			c0.32-0.34,0.48-0.76,0.48-1.25c0-0.5-0.16-0.91-0.48-1.25c-0.32-0.34-0.71-0.5-1.18-0.5c-0.47,0-0.87,0.17-1.18,0.5
			c-0.32,0.34-0.48,0.76-0.48,1.25C163.91,17.08,164.07,17.49,164.39,17.83 M163.46,16.58c0-0.62,0.2-1.14,0.6-1.55
			c0.4-0.42,0.91-0.62,1.51-0.62c0.6,0,1.1,0.21,1.51,0.62c0.4,0.42,0.61,0.93,0.61,1.55c0,0.62-0.2,1.14-0.61,1.55
			c-0.4,0.41-0.9,0.62-1.51,0.62c-0.61,0-1.11-0.21-1.51-0.62C163.66,17.72,163.46,17.2,163.46,16.58 M159.51,18.68h-0.44v-4.2h0.44
			v1.75h2.49v-1.75h0.45v4.2h-0.45v-2.05h-2.49V18.68" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M171.83,27.08h-2.97
			v-4.2h2.88v0.4h-2.44v1.34h2v0.4h-2v1.65h2.53V27.08 M164.89,23.28v1.51h1.41c0.29,0,0.51-0.07,0.67-0.2
			c0.16-0.13,0.24-0.32,0.24-0.55c0-0.24-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2H164.89 M164.89,27.08h-0.44v-4.2h1.88
			c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75c-0.18,0.2-0.42,0.33-0.73,0.38l1.36,1.93
			h-0.52l-1.32-1.89h-1.28V27.08 M163.23,27.08h-0.44v-4.2h0.44V27.08 M159.47,27.08h-0.44v-4.2h2.86v0.4h-2.42v1.4h1.96v0.4h-1.96
			V27.08" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M191.53,27.08h-0.44
			v-4.2h0.44v1.75h2.5v-1.75h0.44v4.2h-0.44v-2.05h-2.5V27.08 M190.19,26.78c-0.35,0.25-0.77,0.37-1.27,0.37
			c-0.61,0-1.13-0.2-1.54-0.61c-0.41-0.41-0.61-0.93-0.61-1.56c0-0.63,0.2-1.15,0.61-1.55c0.41-0.41,0.93-0.62,1.54-0.62
			c0.46,0,0.85,0.1,1.18,0.31v0.46c-0.37-0.24-0.76-0.35-1.17-0.35c-0.48,0-0.89,0.17-1.22,0.5c-0.33,0.33-0.49,0.75-0.49,1.25
			c0,0.51,0.16,0.93,0.49,1.26c0.33,0.33,0.74,0.5,1.23,0.5c0.49,0,0.9-0.14,1.25-0.42V26.78 M184.97,27.08h-0.44v-3.8h-1.45v-0.4
			h3.35v0.4h-1.46V27.08 M181.21,23.41l-0.95,2.12h1.91L181.21,23.41 M179.59,27.08h-0.46l1.87-4.22h0.41l1.88,4.22h-0.47
			l-0.52-1.16h-2.2L179.59,27.08 M175.13,27.1h-0.39l-1.45-4.22h0.49l1.17,3.5l1.09-3.52h0.36l1.09,3.52l1.16-3.5h0.48l-1.45,4.22
			h-0.39l-1.08-3.47L175.13,27.1" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M210.63,31.68v1.51
			h1.41c0.29,0,0.51-0.07,0.67-0.19c0.16-0.13,0.24-0.32,0.24-0.56c0-0.24-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2H210.63
			 M210.63,35.48h-0.44v-4.2h1.88c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75
			c-0.17,0.2-0.42,0.33-0.73,0.38l1.35,1.93h-0.52l-1.32-1.89h-1.28V35.48 M209.32,35.48h-2.97v-4.2h2.88v0.4h-2.44v1.34h2v0.4h-2
			v1.65h2.53V35.48 M203.64,35.55c-0.6,0-1.11-0.2-1.53-0.61c-0.41-0.41-0.61-0.93-0.61-1.56c0-0.63,0.2-1.15,0.61-1.55
			c0.41-0.41,0.93-0.62,1.54-0.62c0.53,0,0.98,0.13,1.33,0.4v0.45c-0.39-0.29-0.82-0.44-1.31-0.44c-0.49,0-0.9,0.17-1.24,0.5
			c-0.33,0.33-0.49,0.75-0.49,1.27c0,0.51,0.16,0.93,0.49,1.26c0.33,0.33,0.73,0.49,1.21,0.49c0.5,0,0.9-0.15,1.19-0.44v-1.63h0.44
			v1.81c-0.18,0.21-0.42,0.37-0.71,0.5C204.28,35.49,203.97,35.55,203.64,35.55 M199.11,31.81l-0.95,2.12h1.91L199.11,31.81
			 M197.5,35.48h-0.46l1.87-4.22h0.41l1.88,4.22h-0.47l-0.52-1.16h-2.2L197.5,35.48 M193.23,35.48h-0.43v-4.2h0.38l2.65,3.43v-3.43
			h0.43v4.2h-0.38l-2.66-3.44V35.48 M189.95,31.81l-0.95,2.12h1.91L189.95,31.81 M188.33,35.48h-0.46l1.87-4.22h0.41l1.88,4.22
			h-0.47l-0.52-1.16h-2.2L188.33,35.48 M183.63,35.48h-0.44v-4.2h0.44l1.52,2.03l1.52-2.03h0.43v4.2h-0.44v-3.53l-1.37,1.84h-0.27
			l-1.39-1.84V35.48 M179.64,35.48h-0.44v-3.8h-1.45v-0.4h3.35v0.4h-1.46V35.48 M176.92,35.48h-0.44v-4.2h0.44V35.48 M171.8,35.48
			h-0.44v-4.2h0.44l1.52,2.03l1.52-2.03h0.43v4.2h-0.44v-3.53l-1.37,1.84h-0.27l-1.39-1.84V35.48 M167.39,31.68v1.51h1.41
			c0.29,0,0.51-0.07,0.67-0.19c0.16-0.13,0.24-0.32,0.24-0.56c0-0.24-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2H167.39
			 M167.39,35.48h-0.44v-4.2h1.88c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75
			c-0.17,0.2-0.42,0.33-0.73,0.38l1.35,1.93h-0.52l-1.32-1.89h-1.28V35.48 M166.07,35.48h-2.97v-4.2h2.88v0.4h-2.44v1.34h2v0.4h-2
			v1.65h2.53V35.48 M159.56,31.68v1.54h1.29c0.3,0,0.53-0.07,0.69-0.2c0.16-0.13,0.25-0.32,0.25-0.57c0-0.25-0.08-0.44-0.24-0.57
			c-0.16-0.13-0.39-0.2-0.69-0.2H159.56 M159.56,35.48h-0.44v-4.2h1.76c0.42,0,0.74,0.11,0.99,0.32s0.36,0.49,0.36,0.85
			c0,0.36-0.12,0.65-0.36,0.86c-0.24,0.21-0.57,0.32-0.99,0.32h-1.32V35.48" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M132.75,28.41h-9.82
			v-11.9h9.61v2.19h-6.92V21h5.57v2.16h-5.57v3.06h7.13V28.41 M109.95,18.7v-2.19h10.98v2.19h-4.15v9.71h-2.69V18.7H109.95
			 M104.04,19.5l-1.93,4.13h3.83L104.04,19.5 M99.98,28.41h-2.82l5.61-11.97h2.58l5.61,11.97h-2.86l-1.29-2.75h-5.54L99.98,28.41
			 M96,27.78c-1.06,0.58-2.3,0.87-3.72,0.87c-2.03,0-3.72-0.58-5.07-1.73c-1.35-1.18-2.02-2.66-2.02-4.45c0-1.81,0.67-3.3,2-4.45
			c1.35-1.16,3.04-1.73,5.09-1.73c1.27,0,2.42,0.25,3.46,0.75v2.46c-1.01-0.61-2.16-0.92-3.44-0.92c-1.23,0-2.27,0.37-3.12,1.11
			c-0.82,0.73-1.23,1.65-1.23,2.79s0.42,2.07,1.27,2.81c0.84,0.73,1.9,1.09,3.2,1.09c1.43,0,2.63-0.35,3.59-1.04V27.78 M82.67,28.41
			h-2.67v-11.9h2.67V28.41 M70.85,28.41h-2.69v-11.9h9.59v2.19h-6.9v2.41h5.33v2.16h-5.33V28.41 M65.18,28.41h-2.67v-11.9h2.67
			V28.41 M49.44,18.7v-2.19h10.98v2.19h-4.15v9.71h-2.69V18.7H49.44 M39.92,18.65v2.89h3.22c0.61,0,1.09-0.12,1.43-0.37
			c0.34-0.25,0.51-0.61,0.51-1.07c0-0.45-0.17-0.81-0.51-1.06c-0.34-0.26-0.82-0.38-1.43-0.38H39.92 M39.92,28.41h-2.69v-11.9h6.06
			c1.4,0,2.49,0.33,3.29,0.99c0.81,0.65,1.22,1.49,1.22,2.53c0,0.79-0.24,1.48-0.72,2.07c-0.48,0.58-1.14,0.99-1.99,1.23l4.04,5.08
			H46l-3.81-4.81h-2.26V28.41 M35.08,28.41h-9.82v-11.9h9.61v2.19h-6.92V21h5.57v2.16h-5.57v3.06h7.13V28.41 M22.96,27.78
			c-1.06,0.58-2.3,0.87-3.72,0.87c-2.03,0-3.72-0.58-5.07-1.73c-1.35-1.18-2.02-2.66-2.02-4.45c0-1.81,0.67-3.3,2-4.45
			c1.35-1.16,3.04-1.73,5.09-1.73c1.27,0,2.42,0.25,3.46,0.75v2.46c-1.01-0.61-2.16-0.92-3.44-0.92c-1.23,0-2.28,0.37-3.12,1.11
			c-0.82,0.73-1.23,1.65-1.23,2.79s0.42,2.07,1.27,2.81c0.83,0.73,1.9,1.09,3.2,1.09c1.43,0,2.63-0.35,3.59-1.04V27.78" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M224.32,14.88v1.51
			h1.41c0.29,0,0.51-0.07,0.67-0.19c0.16-0.13,0.24-0.31,0.24-0.56c0-0.24-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2H224.32
			 M224.32,18.68h-0.44v-4.2h1.88c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75
			c-0.17,0.2-0.42,0.33-0.73,0.38l1.36,1.93h-0.52l-1.32-1.89h-1.28V18.68 M219.56,17.83c0.32,0.34,0.71,0.5,1.18,0.5
			c0.47,0,0.87-0.17,1.18-0.5c0.32-0.34,0.48-0.76,0.48-1.25c0-0.5-0.16-0.91-0.48-1.25c-0.32-0.34-0.71-0.5-1.18-0.5
			c-0.47,0-0.87,0.17-1.18,0.5c-0.32,0.34-0.48,0.76-0.48,1.25C219.08,17.08,219.24,17.49,219.56,17.83 M218.63,16.58
			c0-0.62,0.2-1.14,0.6-1.55c0.4-0.42,0.91-0.62,1.51-0.62c0.6,0,1.1,0.21,1.51,0.62c0.4,0.42,0.61,0.93,0.61,1.55
			c0,0.62-0.2,1.14-0.61,1.55c-0.4,0.41-0.9,0.62-1.51,0.62c-0.61,0-1.11-0.21-1.51-0.62C218.83,17.72,218.63,17.2,218.63,16.58
			 M216.83,18.68h-0.45v-3.8h-1.44v-0.4h3.35v0.4h-1.46V18.68 M213.07,15.01l-0.95,2.12h1.91L213.07,15.01 M211.45,18.68h-0.46
			l1.87-4.22h0.41l1.88,4.22h-0.47l-0.52-1.16h-2.2L211.45,18.68 M207.43,14.88v1.51h1.41c0.29,0,0.51-0.07,0.67-0.19
			c0.16-0.13,0.24-0.31,0.24-0.56c0-0.24-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2H207.43 M207.43,18.68h-0.44v-4.2h1.88
			c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75c-0.18,0.2-0.42,0.33-0.73,0.38l1.36,1.93
			h-0.52l-1.32-1.89h-1.28V18.68 M206.12,18.68h-2.97v-4.2h2.88v0.4h-2.44v1.34h2v0.4h-2v1.65h2.53V18.68 M199.6,14.88v1.54h1.29
			c0.3,0,0.53-0.07,0.69-0.2c0.16-0.13,0.25-0.32,0.25-0.57c0-0.25-0.08-0.44-0.24-0.57c-0.16-0.13-0.39-0.2-0.69-0.2H199.6
			 M199.6,18.68h-0.44v-4.2h1.76c0.42,0,0.75,0.11,0.99,0.32c0.24,0.21,0.36,0.49,0.36,0.85c0,0.36-0.12,0.65-0.36,0.86
			c-0.24,0.21-0.57,0.32-0.99,0.32h-1.32V18.68 M194.84,17.83c0.32,0.34,0.71,0.5,1.18,0.5c0.47,0,0.87-0.17,1.18-0.5
			c0.32-0.34,0.48-0.76,0.48-1.25c0-0.5-0.16-0.91-0.48-1.25c-0.32-0.34-0.71-0.5-1.18-0.5c-0.47,0-0.87,0.17-1.18,0.5
			c-0.32,0.34-0.48,0.76-0.48,1.25C194.36,17.08,194.52,17.49,194.84,17.83 M193.91,16.58c0-0.62,0.2-1.14,0.6-1.55
			c0.4-0.42,0.91-0.62,1.51-0.62c0.6,0,1.1,0.21,1.51,0.62c0.4,0.42,0.61,0.93,0.61,1.55c0,0.62-0.2,1.14-0.61,1.55
			c-0.4,0.41-0.9,0.62-1.51,0.62c-0.61,0-1.11-0.21-1.51-0.62C194.11,17.72,193.91,17.2,193.91,16.58 M188.54,14.48h0.44v1.82
			l1.94-1.82h0.59l-2.06,1.92l2.38,2.28h-0.62l-2.23-2.14v2.14h-0.44V14.48 M184.57,14.88v1.51h1.41c0.29,0,0.51-0.07,0.67-0.19
			c0.16-0.13,0.24-0.31,0.24-0.56c0-0.24-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2H184.57 M184.57,18.68h-0.44v-4.2H186
			c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75c-0.17,0.2-0.42,0.33-0.73,0.38l1.36,1.93
			h-0.52l-1.32-1.89h-1.28V18.68 M179.81,17.83c0.32,0.34,0.71,0.5,1.18,0.5c0.47,0,0.87-0.17,1.18-0.5
			c0.32-0.34,0.48-0.76,0.48-1.25c0-0.5-0.16-0.91-0.48-1.25c-0.32-0.34-0.71-0.5-1.18-0.5c-0.47,0-0.86,0.17-1.18,0.5
			c-0.32,0.34-0.48,0.76-0.48,1.25C179.33,17.08,179.49,17.49,179.81,17.83 M178.88,16.58c0-0.62,0.2-1.14,0.6-1.55
			c0.4-0.42,0.91-0.62,1.51-0.62c0.6,0,1.1,0.21,1.51,0.62c0.4,0.42,0.61,0.93,0.61,1.55c0,0.62-0.2,1.14-0.61,1.55
			c-0.4,0.41-0.9,0.62-1.51,0.62c-0.61,0-1.11-0.21-1.51-0.62C179.08,17.72,178.88,17.2,178.88,16.58 M174.46,18.7h-0.39l-1.45-4.22
			h0.49l1.17,3.5l1.09-3.52h0.36l1.09,3.52l1.16-3.5h0.48l-1.45,4.22h-0.39l-1.08-3.47L174.46,18.7" />
                <path style="clip-path:url(#SVGID_00000090998985456101407600000005993978295494186410_);fill:#FFFFFF;" d="M12.63,37.26h-0.46
			l1.87-4.22h0.41l1.88,4.22h-0.47l-0.52-1.16h-2.2L12.63,37.26z M14.24,33.59l-0.95,2.12h1.91l-0.95-2.12H14.24z M20.34,36.96
			c-0.34,0.25-0.77,0.37-1.27,0.37c-0.61,0-1.13-0.2-1.54-0.61s-0.61-0.93-0.61-1.56s0.2-1.15,0.61-1.55
			c0.41-0.41,0.93-0.62,1.54-0.62c0.46,0,0.85,0.1,1.18,0.31v0.46c-0.37-0.24-0.76-0.35-1.17-0.35c-0.48,0-0.89,0.17-1.22,0.5
			s-0.49,0.75-0.49,1.25s0.16,0.93,0.49,1.26c0.33,0.33,0.74,0.5,1.23,0.5s0.9-0.14,1.25-0.42V36.96L20.34,36.96z M24.72,36.96
			c-0.34,0.25-0.77,0.37-1.27,0.37c-0.61,0-1.13-0.2-1.54-0.61c-0.41-0.41-0.61-0.93-0.61-1.56s0.2-1.15,0.61-1.55
			c0.41-0.41,0.93-0.62,1.54-0.62c0.46,0,0.85,0.1,1.18,0.31v0.46c-0.37-0.24-0.76-0.35-1.17-0.35c-0.48,0-0.89,0.17-1.22,0.5
			s-0.49,0.75-0.49,1.25s0.16,0.93,0.49,1.26c0.33,0.33,0.74,0.5,1.23,0.5c0.49,0,0.9-0.14,1.25-0.42V36.96L24.72,36.96z
			 M25.68,35.16c0-0.62,0.2-1.14,0.6-1.55c0.4-0.42,0.91-0.62,1.51-0.62s1.1,0.21,1.51,0.62c0.4,0.42,0.61,0.93,0.61,1.55
			s-0.2,1.14-0.61,1.55c-0.4,0.41-0.9,0.62-1.51,0.62s-1.11-0.21-1.51-0.62C25.88,36.3,25.68,35.78,25.68,35.16L25.68,35.16z
			 M26.61,36.41c0.32,0.34,0.71,0.5,1.18,0.5s0.87-0.17,1.18-0.5c0.32-0.34,0.48-0.76,0.48-1.25s-0.16-0.91-0.48-1.25
			c-0.32-0.34-0.71-0.5-1.18-0.5s-0.87,0.17-1.18,0.5c-0.32,0.34-0.48,0.76-0.48,1.25S26.29,36.07,26.61,36.41z M31.67,37.26h-0.44
			v-4.2h1.88c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75s-0.42,0.33-0.73,0.38l1.36,1.93
			h-0.52l-1.32-1.89h-1.28v1.89L31.67,37.26z M31.67,33.46v1.51h1.41c0.29,0,0.51-0.06,0.67-0.19c0.16-0.13,0.24-0.31,0.24-0.55
			s-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2L31.67,33.46L31.67,33.46z M38.96,36.68c-0.42,0.39-0.96,0.58-1.64,0.58h-1.37
			v-4.2h1.37c0.68,0,1.23,0.19,1.64,0.58c0.42,0.39,0.63,0.9,0.63,1.52S39.38,36.29,38.96,36.68
			C38.96,36.68,38.96,36.68,38.96,36.68z M36.39,33.46v3.4h0.88c0.58,0,1.03-0.15,1.37-0.46c0.34-0.3,0.5-0.72,0.5-1.24
			s-0.17-0.93-0.5-1.24c-0.34-0.31-0.8-0.46-1.37-0.46H36.39z M41.39,37.26h-0.44v-4.2h0.44V37.26z M43.34,37.26h-0.43v-4.2h0.38
			l2.65,3.43v-3.43h0.43v4.2h-0.38l-2.66-3.44L43.34,37.26L43.34,37.26z M49.83,37.33c-0.6,0-1.11-0.2-1.53-0.61
			c-0.41-0.41-0.61-0.93-0.61-1.56s0.2-1.15,0.61-1.55c0.41-0.41,0.93-0.62,1.54-0.62c0.53,0,0.98,0.13,1.33,0.4v0.45
			c-0.39-0.29-0.82-0.44-1.31-0.44s-0.9,0.17-1.24,0.5c-0.33,0.33-0.49,0.75-0.49,1.27s0.16,0.93,0.49,1.26
			c0.33,0.33,0.73,0.49,1.21,0.49c0.5,0,0.9-0.15,1.19-0.44v-1.63h0.44v1.81c-0.18,0.21-0.42,0.37-0.71,0.49
			c-0.29,0.12-0.6,0.18-0.93,0.18L49.83,37.33z M55.94,37.26H55.5v-3.8h-1.45v-0.4h3.35v0.4h-1.46V37.26z M58.03,35.16
			c0-0.62,0.2-1.14,0.6-1.55c0.4-0.42,0.91-0.62,1.51-0.62s1.1,0.21,1.51,0.62c0.4,0.42,0.61,0.93,0.61,1.55s-0.2,1.14-0.61,1.55
			c-0.4,0.41-0.9,0.62-1.51,0.62s-1.11-0.21-1.51-0.62C58.23,36.3,58.03,35.78,58.03,35.16L58.03,35.16z M58.96,36.41
			c0.32,0.34,0.71,0.5,1.18,0.5s0.87-0.17,1.18-0.5c0.32-0.34,0.48-0.76,0.48-1.25s-0.16-0.91-0.48-1.25s-0.71-0.5-1.18-0.5
			s-0.87,0.17-1.18,0.5c-0.32,0.34-0.48,0.76-0.48,1.25S58.64,36.07,58.96,36.41z M67.81,37c-0.3,0.22-0.67,0.32-1.1,0.32
			c-0.3,0-0.6-0.04-0.87-0.12c-0.28-0.08-0.51-0.19-0.68-0.32v-0.5c0.17,0.14,0.39,0.27,0.65,0.37c0.29,0.1,0.58,0.16,0.87,0.16
			c0.33,0,0.6-0.07,0.82-0.22c0.21-0.14,0.32-0.34,0.32-0.58c0.02-0.22-0.09-0.41-0.35-0.57c-0.05-0.04-0.14-0.08-0.25-0.12
			l-0.26-0.09c0,0-0.04-0.01-0.07-0.02c-0.04,0-0.07-0.02-0.12-0.04c-0.04-0.01-0.09-0.03-0.13-0.04c-0.25-0.07-0.4-0.12-0.44-0.13
			c-0.13-0.04-0.25-0.09-0.37-0.15c-0.13-0.06-0.24-0.13-0.31-0.21c-0.18-0.18-0.26-0.41-0.26-0.68c0-0.32,0.13-0.58,0.38-0.78
			c0.26-0.2,0.61-0.31,1.07-0.31c0.5,0,0.94,0.12,1.31,0.35v0.46c-0.42-0.26-0.85-0.39-1.3-0.39c-0.32,0-0.57,0.06-0.75,0.19
			c-0.18,0.13-0.26,0.29-0.26,0.49c-0.02,0.16,0.07,0.32,0.27,0.47c0.03,0.03,0.1,0.06,0.2,0.1l0.2,0.08l0.26,0.07l0.25,0.07
			c0.22,0.07,0.36,0.11,0.41,0.13c0.12,0.04,0.24,0.09,0.37,0.16c0.13,0.07,0.24,0.14,0.32,0.23c0.2,0.18,0.29,0.42,0.29,0.72
			c0,0.38-0.15,0.67-0.46,0.89l0,0L67.81,37z M70.92,37.25h-0.44v-3.8h-1.45v-0.4h3.35v0.4h-1.46V37.25z M72.94,37.25h-0.46
			l1.87-4.22h0.41l1.88,4.22h-0.47l-0.52-1.16h-2.2C73.45,36.09,72.94,37.25,72.94,37.25z M74.55,33.58L73.6,35.7h1.91l-0.95-2.12
			H74.55z M78.14,37.25h-0.43v-4.2h0.38l2.65,3.43v-3.43h0.43v4.2h-0.38l-2.66-3.44v3.44H78.14z M85.68,36.67
			c-0.42,0.39-0.96,0.58-1.64,0.58h-1.37v-4.2h1.37c0.68,0,1.23,0.19,1.64,0.58c0.42,0.39,0.63,0.9,0.63,1.52
			S86.1,36.28,85.68,36.67C85.68,36.67,85.68,36.67,85.68,36.67z M83.11,33.45v3.4h0.88c0.58,0,1.03-0.15,1.37-0.46
			c0.34-0.3,0.5-0.72,0.5-1.24s-0.17-0.93-0.5-1.24c-0.34-0.31-0.8-0.46-1.37-0.46H83.11z M87.38,37.25h-0.46l1.87-4.22h0.41
			l1.88,4.22h-0.47l-0.52-1.16h-2.2C87.89,36.09,87.38,37.25,87.38,37.25z M88.99,33.58l-0.95,2.12h1.91L89,33.58H88.99z
			 M92.59,37.25h-0.44v-4.2h1.88c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75
			c-0.17,0.2-0.42,0.33-0.73,0.38l1.36,1.93h-0.52l-1.32-1.89h-1.28v1.89L92.59,37.25z M92.59,33.45v1.51H94
			c0.29,0,0.51-0.06,0.67-0.19c0.16-0.13,0.24-0.31,0.24-0.55s-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2L92.59,33.45
			L92.59,33.45z M99.88,36.67c-0.42,0.39-0.96,0.58-1.64,0.58h-1.37v-4.2h1.37c0.68,0,1.23,0.19,1.64,0.58
			c0.42,0.39,0.63,0.9,0.63,1.52S100.3,36.28,99.88,36.67C99.88,36.67,99.88,36.67,99.88,36.67z M97.31,33.45v3.4h0.88
			c0.58,0,1.03-0.15,1.37-0.46c0.34-0.3,0.5-0.72,0.5-1.24s-0.17-0.93-0.5-1.24c-0.34-0.31-0.8-0.46-1.37-0.46H97.31z M106.07,37
			c-0.3,0.22-0.67,0.32-1.1,0.32c-0.3,0-0.6-0.04-0.87-0.12c-0.28-0.08-0.51-0.19-0.68-0.32v-0.5c0.17,0.14,0.39,0.27,0.65,0.37
			c0.29,0.1,0.58,0.16,0.87,0.16c0.33,0,0.6-0.07,0.82-0.22c0.21-0.14,0.32-0.34,0.32-0.58c0.02-0.22-0.09-0.41-0.35-0.57
			c-0.05-0.04-0.14-0.08-0.25-0.12l-0.26-0.09c0,0-0.04-0.01-0.07-0.02c-0.04,0-0.08-0.02-0.12-0.04c-0.04-0.01-0.09-0.03-0.13-0.04
			c-0.25-0.07-0.4-0.12-0.44-0.13c-0.13-0.04-0.25-0.09-0.37-0.15c-0.13-0.06-0.24-0.13-0.31-0.21c-0.18-0.18-0.26-0.41-0.26-0.68
			c0-0.32,0.13-0.58,0.38-0.78c0.26-0.2,0.61-0.31,1.07-0.31c0.5,0,0.94,0.12,1.31,0.35v0.46c-0.42-0.26-0.85-0.39-1.3-0.39
			c-0.32,0-0.57,0.06-0.75,0.19c-0.18,0.13-0.26,0.29-0.26,0.49c-0.02,0.16,0.07,0.32,0.27,0.47c0.03,0.03,0.1,0.06,0.2,0.1
			l0.2,0.08l0.26,0.07l0.25,0.07c0.22,0.07,0.36,0.11,0.41,0.13c0.12,0.04,0.24,0.09,0.37,0.16c0.13,0.07,0.24,0.14,0.32,0.23
			c0.2,0.18,0.29,0.42,0.29,0.72c0,0.38-0.15,0.67-0.46,0.89l0,0L106.07,37z M109.79,37.25h-2v-4.2h1.82c0.37,0,0.67,0.09,0.88,0.27
			c0.22,0.18,0.32,0.43,0.32,0.75c0,0.22-0.05,0.4-0.15,0.56s-0.23,0.27-0.41,0.33c0.28,0.04,0.5,0.17,0.67,0.37
			c0.16,0.2,0.25,0.45,0.25,0.76c0,0.36-0.12,0.64-0.37,0.85S110.22,37.26,109.79,37.25L109.79,37.25z M108.23,33.45v1.37h1.3
			c0.27,0,0.47-0.06,0.62-0.17c0.15-0.12,0.22-0.28,0.22-0.5s-0.07-0.4-0.22-0.52s-0.35-0.18-0.62-0.18H108.23z M108.23,35.21v1.63
			h1.48c0.31,0,0.55-0.07,0.73-0.22c0.17-0.14,0.26-0.35,0.26-0.61s-0.09-0.45-0.26-0.6c-0.17-0.14-0.41-0.21-0.73-0.21
			L108.23,35.21L108.23,35.21z M112.89,37.24h-0.44v-4.2h2.86v0.4h-2.42v1.4h1.96v0.4h-1.96C112.89,35.24,112.89,37.24,112.89,37.24
			z M120.89,37.24h-2.86v-0.33l1.42-1.35c0.31-0.3,0.53-0.53,0.65-0.71c0.13-0.19,0.19-0.4,0.19-0.63c0-0.25-0.09-0.45-0.26-0.6
			c-0.18-0.15-0.41-0.23-0.69-0.23c-0.44,0-0.85,0.19-1.21,0.56v-0.5c0.36-0.32,0.77-0.48,1.25-0.48c0.39,0,0.71,0.11,0.97,0.33
			s0.39,0.51,0.39,0.86c0,0.28-0.07,0.54-0.21,0.77s-0.38,0.5-0.72,0.82l-1.13,1.09h2.21C120.89,36.84,120.89,37.24,120.89,37.24z
			 M122.39,36.69c-0.32-0.41-0.47-0.93-0.47-1.55s0.16-1.13,0.47-1.54c0.32-0.42,0.74-0.63,1.25-0.63s0.92,0.21,1.24,0.62
			s0.47,0.93,0.47,1.55s-0.16,1.14-0.47,1.55c-0.32,0.42-0.73,0.62-1.24,0.62S122.71,37.1,122.39,36.69
			C122.39,36.69,122.39,36.69,122.39,36.69z M124.56,36.4c0.24-0.34,0.36-0.76,0.36-1.26s-0.12-0.92-0.36-1.25s-0.55-0.5-0.92-0.5
			s-0.68,0.17-0.92,0.5c-0.24,0.34-0.36,0.75-0.36,1.25s0.12,0.92,0.35,1.26c0.24,0.33,0.55,0.5,0.92,0.5s0.68-0.17,0.92-0.5l0,0
			H124.56z M129.19,37.24h-2.86v-0.33l1.42-1.35c0.31-0.3,0.53-0.53,0.65-0.71c0.13-0.19,0.19-0.4,0.19-0.63
			c0-0.25-0.09-0.45-0.26-0.6c-0.18-0.15-0.41-0.23-0.69-0.23c-0.44,0-0.85,0.19-1.21,0.56v-0.5c0.36-0.32,0.77-0.48,1.25-0.48
			c0.39,0,0.71,0.11,0.97,0.33s0.39,0.51,0.39,0.86c0,0.28-0.07,0.54-0.21,0.77s-0.38,0.5-0.72,0.82l-1.13,1.09h2.21L129.19,37.24
			L129.19,37.24z M132.94,37.24h-2.86v-0.33l1.42-1.35c0.31-0.3,0.53-0.53,0.65-0.71c0.13-0.19,0.19-0.4,0.19-0.63
			c0-0.25-0.09-0.45-0.26-0.6c-0.18-0.15-0.41-0.23-0.69-0.23c-0.44,0-0.85,0.19-1.21,0.56v-0.5c0.36-0.32,0.77-0.48,1.25-0.48
			c0.39,0,0.71,0.11,0.97,0.33s0.39,0.51,0.39,0.86c0,0.28-0.07,0.54-0.21,0.77s-0.38,0.5-0.72,0.82l-1.13,1.09h2.21L132.94,37.24
			L132.94,37.24z" />
            </g>
            <path class="st83" d="M-57.45,22.95h-0.46l1.87-4.22h0.41l1.88,4.22h-0.47l-0.52-1.16h-2.2L-57.45,22.95z M-55.84,19.28l-0.95,2.12
		h1.91l-0.95-2.12H-55.84z M-49.74,22.65c-0.34,0.25-0.77,0.37-1.27,0.37c-0.61,0-1.13-0.2-1.54-0.61s-0.61-0.93-0.61-1.56
		s0.2-1.15,0.61-1.55c0.41-0.41,0.93-0.62,1.54-0.62c0.46,0,0.85,0.1,1.18,0.31v0.46c-0.37-0.24-0.76-0.35-1.17-0.35
		c-0.48,0-0.89,0.17-1.22,0.5c-0.33,0.33-0.49,0.75-0.49,1.25s0.16,0.93,0.49,1.26c0.33,0.33,0.74,0.5,1.23,0.5
		c0.49,0,0.9-0.14,1.25-0.42V22.65L-49.74,22.65z M-45.36,22.65c-0.34,0.25-0.77,0.37-1.27,0.37c-0.61,0-1.13-0.2-1.54-0.61
		s-0.61-0.93-0.61-1.56s0.2-1.15,0.61-1.55c0.41-0.41,0.93-0.62,1.54-0.62c0.46,0,0.85,0.1,1.18,0.31v0.46
		c-0.37-0.24-0.76-0.35-1.17-0.35c-0.48,0-0.89,0.17-1.22,0.5c-0.33,0.33-0.49,0.75-0.49,1.25s0.16,0.93,0.49,1.26
		c0.33,0.33,0.74,0.5,1.23,0.5c0.49,0,0.9-0.14,1.25-0.42V22.65L-45.36,22.65z M-44.4,20.85c0-0.62,0.2-1.14,0.6-1.55
		c0.4-0.42,0.91-0.62,1.51-0.62c0.6,0,1.1,0.21,1.51,0.62c0.4,0.42,0.61,0.93,0.61,1.55s-0.2,1.14-0.61,1.55
		c-0.4,0.41-0.9,0.62-1.51,0.62s-1.11-0.21-1.51-0.62C-44.2,21.99-44.4,21.47-44.4,20.85L-44.4,20.85z M-43.47,22.1
		c0.32,0.34,0.71,0.5,1.18,0.5s0.87-0.17,1.18-0.5c0.32-0.34,0.48-0.76,0.48-1.25s-0.16-0.91-0.48-1.25s-0.71-0.5-1.18-0.5
		s-0.87,0.17-1.18,0.5c-0.32,0.34-0.48,0.76-0.48,1.25S-43.79,21.76-43.47,22.1z M-38.41,22.95h-0.44v-4.2h1.88
		c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75s-0.42,0.33-0.73,0.38l1.36,1.93h-0.52
		l-1.32-1.89h-1.28v1.89L-38.41,22.95z M-38.41,19.15v1.51H-37c0.29,0,0.51-0.06,0.67-0.19c0.16-0.13,0.24-0.31,0.24-0.55
		s-0.08-0.43-0.24-0.56s-0.38-0.2-0.67-0.2L-38.41,19.15L-38.41,19.15z M-31.12,22.37c-0.42,0.39-0.96,0.58-1.64,0.58h-1.37v-4.2
		h1.37c0.68,0,1.23,0.19,1.64,0.58c0.42,0.39,0.63,0.9,0.63,1.52S-30.7,21.98-31.12,22.37C-31.12,22.37-31.12,22.37-31.12,22.37z
		 M-33.69,19.15v3.4h0.88c0.58,0,1.03-0.15,1.37-0.46c0.34-0.3,0.5-0.72,0.5-1.24s-0.17-0.93-0.5-1.24
		c-0.34-0.31-0.8-0.46-1.37-0.46H-33.69z M-28.69,22.95h-0.44v-4.2h0.44V22.95z M-26.74,22.95h-0.43v-4.2h0.38l2.65,3.43v-3.43h0.43
		v4.2h-0.38l-2.66-3.44L-26.74,22.95L-26.74,22.95z M-20.25,23.02c-0.6,0-1.11-0.2-1.53-0.61c-0.41-0.41-0.61-0.93-0.61-1.56
		s0.2-1.15,0.61-1.55c0.41-0.41,0.93-0.62,1.54-0.62c0.53,0,0.98,0.13,1.33,0.4v0.45c-0.39-0.29-0.82-0.44-1.31-0.44
		s-0.9,0.17-1.24,0.5c-0.33,0.33-0.49,0.75-0.49,1.27s0.16,0.93,0.49,1.26c0.33,0.33,0.73,0.49,1.21,0.49c0.5,0,0.9-0.15,1.19-0.44
		v-1.63h0.44v1.81c-0.18,0.21-0.42,0.37-0.71,0.49c-0.29,0.12-0.6,0.18-0.93,0.18L-20.25,23.02z M-14.14,22.95h-0.44v-3.8h-1.45
		v-0.4h3.35v0.4h-1.46V22.95z M-12.05,20.85c0-0.62,0.2-1.14,0.6-1.55c0.4-0.42,0.91-0.62,1.51-0.62s1.1,0.21,1.51,0.62
		c0.4,0.42,0.61,0.93,0.61,1.55s-0.2,1.14-0.61,1.55c-0.4,0.41-0.9,0.62-1.51,0.62s-1.11-0.21-1.51-0.62
		C-11.85,21.99-12.05,21.47-12.05,20.85L-12.05,20.85z M-11.12,22.1c0.32,0.34,0.71,0.5,1.18,0.5s0.87-0.17,1.18-0.5
		c0.32-0.34,0.48-0.76,0.48-1.25s-0.16-0.91-0.48-1.25s-0.71-0.5-1.18-0.5s-0.87,0.17-1.18,0.5c-0.32,0.34-0.48,0.76-0.48,1.25
		S-11.44,21.76-11.12,22.1z M-2.27,22.69c-0.3,0.22-0.67,0.32-1.1,0.32c-0.3,0-0.6-0.04-0.87-0.12c-0.28-0.08-0.51-0.19-0.68-0.32
		v-0.5c0.17,0.14,0.39,0.27,0.65,0.37c0.29,0.1,0.58,0.16,0.87,0.16c0.33,0,0.6-0.07,0.82-0.22c0.21-0.14,0.32-0.34,0.32-0.58
		c0.02-0.22-0.09-0.41-0.35-0.57c-0.05-0.04-0.14-0.08-0.25-0.12l-0.26-0.09c0,0-0.04-0.01-0.07-0.02c-0.04,0-0.07-0.02-0.12-0.04
		c-0.04-0.01-0.09-0.03-0.13-0.04c-0.25-0.07-0.4-0.12-0.44-0.13c-0.13-0.04-0.25-0.09-0.37-0.15c-0.13-0.06-0.24-0.13-0.31-0.21
		c-0.18-0.18-0.26-0.41-0.26-0.68c0-0.32,0.13-0.58,0.38-0.78c0.26-0.2,0.61-0.31,1.07-0.31c0.5,0,0.94,0.12,1.31,0.35v0.46
		c-0.42-0.26-0.85-0.39-1.3-0.39c-0.32,0-0.57,0.06-0.75,0.19c-0.18,0.13-0.26,0.29-0.26,0.49c-0.02,0.16,0.07,0.32,0.27,0.47
		c0.03,0.03,0.1,0.06,0.2,0.1l0.2,0.08l0.26,0.07l0.25,0.07c0.22,0.07,0.36,0.11,0.41,0.13c0.12,0.04,0.24,0.09,0.37,0.16
		c0.13,0.07,0.24,0.14,0.32,0.23c0.2,0.18,0.29,0.42,0.29,0.72c0,0.38-0.15,0.67-0.46,0.89l0,0L-2.27,22.69z M0.84,22.94H0.4v-3.8
		h-1.45v-0.4H2.3v0.4H0.84V22.94z M2.86,22.94H2.4l1.87-4.22h0.41l1.88,4.22H6.09l-0.52-1.16h-2.2C3.37,21.78,2.86,22.94,2.86,22.94
		z M4.47,19.27l-0.95,2.12h1.91l-0.95-2.12H4.47z M8.06,22.94H7.63v-4.2h0.38l2.65,3.43v-3.43h0.43v4.2h-0.38L8.05,19.5v3.44H8.06z
		 M15.6,22.36c-0.42,0.39-0.96,0.58-1.64,0.58h-1.37v-4.2h1.37c0.68,0,1.23,0.19,1.64,0.58c0.42,0.39,0.63,0.9,0.63,1.52
		S16.02,21.97,15.6,22.36C15.6,22.36,15.6,22.36,15.6,22.36z M13.03,19.14v3.4h0.88c0.58,0,1.03-0.15,1.37-0.46
		c0.34-0.3,0.5-0.72,0.5-1.24s-0.17-0.93-0.5-1.24c-0.34-0.31-0.8-0.46-1.37-0.46H13.03z M17.3,22.94h-0.46l1.87-4.22h0.41L21,22.94
		h-0.47l-0.52-1.16h-2.2C17.81,21.78,17.3,22.94,17.3,22.94z M18.91,19.27l-0.95,2.12h1.91l-0.95-2.12H18.91z M22.51,22.94h-0.44
		v-4.2h1.88c0.41,0,0.73,0.1,0.97,0.31c0.24,0.21,0.36,0.48,0.36,0.83c0,0.3-0.09,0.55-0.26,0.75c-0.17,0.2-0.42,0.33-0.73,0.38
		l1.36,1.93h-0.52l-1.32-1.89h-1.28v1.89L22.51,22.94z M22.51,19.14v1.51h1.41c0.29,0,0.51-0.06,0.67-0.19
		c0.16-0.13,0.24-0.31,0.24-0.55s-0.08-0.43-0.24-0.56c-0.16-0.13-0.38-0.2-0.67-0.2L22.51,19.14L22.51,19.14z M29.8,22.36
		c-0.42,0.39-0.96,0.58-1.64,0.58h-1.37v-4.2h1.37c0.68,0,1.23,0.19,1.64,0.58c0.42,0.39,0.63,0.9,0.63,1.52S30.22,21.97,29.8,22.36
		C29.8,22.36,29.8,22.36,29.8,22.36z M27.23,19.14v3.4h0.88c0.58,0,1.03-0.15,1.37-0.46c0.34-0.3,0.5-0.72,0.5-1.24
		s-0.17-0.93-0.5-1.24c-0.34-0.31-0.8-0.46-1.37-0.46H27.23z M35.99,22.69c-0.3,0.22-0.67,0.32-1.1,0.32c-0.3,0-0.6-0.04-0.87-0.12
		c-0.28-0.08-0.51-0.19-0.68-0.32v-0.5c0.17,0.14,0.39,0.27,0.65,0.37c0.29,0.1,0.58,0.16,0.87,0.16c0.33,0,0.6-0.07,0.82-0.22
		C35.89,22.24,36,22.04,36,21.8c0.02-0.22-0.09-0.41-0.35-0.57c-0.05-0.04-0.14-0.08-0.25-0.12l-0.26-0.09c0,0-0.04-0.01-0.07-0.02
		c-0.04,0-0.08-0.02-0.12-0.04c-0.04-0.01-0.09-0.03-0.13-0.04c-0.25-0.07-0.4-0.12-0.44-0.13c-0.13-0.04-0.25-0.09-0.37-0.15
		c-0.13-0.06-0.24-0.13-0.31-0.21c-0.18-0.18-0.26-0.41-0.26-0.68c0-0.32,0.13-0.58,0.38-0.78c0.26-0.2,0.61-0.31,1.07-0.31
		c0.5,0,0.94,0.12,1.31,0.35v0.46c-0.42-0.26-0.85-0.39-1.3-0.39c-0.32,0-0.57,0.06-0.75,0.19c-0.18,0.13-0.26,0.29-0.26,0.49
		c-0.02,0.16,0.07,0.32,0.27,0.47c0.03,0.03,0.1,0.06,0.2,0.1l0.2,0.08l0.26,0.07l0.25,0.07c0.22,0.07,0.36,0.11,0.41,0.13
		c0.12,0.04,0.24,0.09,0.37,0.16c0.13,0.07,0.24,0.14,0.32,0.23c0.2,0.18,0.29,0.42,0.29,0.72c0,0.38-0.15,0.67-0.46,0.89l0,0
		L35.99,22.69z M39.71,22.94h-2v-4.2h1.82c0.37,0,0.67,0.09,0.88,0.27c0.22,0.18,0.32,0.43,0.32,0.75c0,0.22-0.05,0.4-0.15,0.56
		s-0.23,0.27-0.41,0.33c0.28,0.04,0.5,0.17,0.67,0.37c0.16,0.2,0.25,0.45,0.25,0.76c0,0.36-0.12,0.64-0.37,0.85
		S40.14,22.95,39.71,22.94L39.71,22.94z M38.15,19.14v1.37h1.3c0.27,0,0.47-0.06,0.62-0.17c0.15-0.12,0.22-0.28,0.22-0.5
		s-0.07-0.4-0.22-0.52s-0.35-0.18-0.62-0.18H38.15z M38.15,20.9v1.63h1.48c0.31,0,0.55-0.07,0.73-0.22
		c0.17-0.14,0.26-0.35,0.26-0.61s-0.09-0.45-0.26-0.6c-0.17-0.14-0.41-0.21-0.73-0.21L38.15,20.9L38.15,20.9z M42.81,22.93h-0.44
		v-4.2h2.86v0.4h-2.42v1.4h1.96v0.4h-1.96C42.81,20.93,42.81,22.93,42.81,22.93z M50.81,22.93h-2.86V22.6l1.42-1.35
		c0.31-0.3,0.53-0.53,0.65-0.71c0.13-0.19,0.19-0.4,0.19-0.63c0-0.25-0.09-0.45-0.26-0.6c-0.18-0.15-0.41-0.23-0.69-0.23
		c-0.44,0-0.85,0.19-1.21,0.56v-0.5c0.36-0.32,0.77-0.48,1.25-0.48c0.39,0,0.71,0.11,0.97,0.33s0.39,0.51,0.39,0.86
		c0,0.28-0.07,0.54-0.21,0.77s-0.38,0.5-0.72,0.82l-1.13,1.09h2.21C50.81,22.53,50.81,22.93,50.81,22.93z M52.31,22.38
		c-0.32-0.41-0.47-0.93-0.47-1.55s0.16-1.13,0.47-1.54c0.32-0.42,0.74-0.63,1.25-0.63s0.92,0.21,1.24,0.62s0.47,0.93,0.47,1.55
		s-0.16,1.14-0.47,1.55C54.48,22.8,54.07,23,53.56,23S52.63,22.79,52.31,22.38C52.31,22.38,52.31,22.38,52.31,22.38z M54.48,22.09
		c0.24-0.34,0.36-0.76,0.36-1.26s-0.12-0.92-0.36-1.25s-0.55-0.5-0.92-0.5s-0.68,0.17-0.92,0.5c-0.24,0.34-0.36,0.75-0.36,1.25
		s0.12,0.92,0.35,1.26c0.24,0.33,0.55,0.5,0.92,0.5s0.68-0.17,0.92-0.5l0,0H54.48z M59.11,22.93h-2.86V22.6l1.42-1.35
		c0.31-0.3,0.53-0.53,0.65-0.71c0.13-0.19,0.19-0.4,0.19-0.63c0-0.25-0.09-0.45-0.26-0.6c-0.18-0.15-0.41-0.23-0.69-0.23
		c-0.44,0-0.85,0.19-1.21,0.56v-0.5c0.36-0.32,0.77-0.48,1.25-0.48c0.39,0,0.71,0.11,0.97,0.33s0.39,0.51,0.39,0.86
		c0,0.28-0.07,0.54-0.21,0.77s-0.38,0.5-0.72,0.82l-1.13,1.09h2.21L59.11,22.93L59.11,22.93z M62.86,22.93H60V22.6l1.42-1.35
		c0.31-0.3,0.53-0.53,0.65-0.71c0.13-0.19,0.19-0.4,0.19-0.63c0-0.25-0.09-0.45-0.26-0.6c-0.18-0.15-0.41-0.23-0.69-0.23
		c-0.44,0-0.85,0.19-1.21,0.56v-0.5c0.36-0.32,0.77-0.48,1.25-0.48c0.39,0,0.71,0.11,0.97,0.33s0.39,0.51,0.39,0.86
		c0,0.28-0.07,0.54-0.21,0.77s-0.38,0.5-0.72,0.82l-1.13,1.09h2.21L62.86,22.93L62.86,22.93z" />
        </g>
        <text transform="matrix(1 0 0 1 185.7323 107.0202)" class="st666">{{ card.birthDate }}</text>
        <text transform="matrix(1 0 0 1 185.7323 123.7887)" class="st666">{{ card.validThru }}</text>
        <text transform="matrix(1 0 0 1 185.7323 140.3992)" class="st666">{{ card.customerId }}</text>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" class="st667" [innerHtml]="name"></text>
    </svg>
</div>
