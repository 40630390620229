import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "haPhonenumber" })
export class HaPhoneNumberPipe implements PipeTransform {
    public transform(key: string): string {
        if (!key) {
            return "-";
        }
        if (this.isForeignPhoneNumber(key)) {
            return key;
        }

        const digits = key.replace(/[^\d]/, "");
        // eslint-disable-next-line max-len
        const regex = /(0(?:10|100|11|120|121|122|123|125|13|140|141|142|143|144|150|151|152|155|156|157|158|159|16|171|173|174|175|176|18|19|20|21|220|221|222|223|224|225|226|227|23|240|241|243|246|247|248|250|251|252|253|254|255|258|26|270|271|278|280|281|290|291|292|293|294|295|297|300|301|302|303|304|31|320|321|322|325|33|340|345|346|35|36|370|371|372|376|378|379|380|381|382|383|390|392|393|394|40|400|410|411|413|414|415|416|417|418|42|430|431|433|435|44|451|454|455|456|457|459|46|470|471|472|474|476|477|478|479|480|481|485|486|490|491|492|493|494|495|496|498|499|500|501|502|503|504|505|506|510|511|512|513|514|515|518|519|520|521|522|523|524|525|526|528|530|531|532|533|534|54|550|551|552|553|554|555|560|563|564|565|570|571|573|580|581|582|583|584|585|586|587|589|590|591|60|600|611|612|613|620|621|622|623|624|63|640|642|643|644|645|647|649|650|651|652|653|655|656|657|660|661|662|663|670|671|672|673|674|675|676|680|682|684|687|690|691|692|693|695|696|70|71|72|73|74|75|76|77|78|8|800|90|900|910|911|912|913|914|915|916|918|920|921|922|923|924|925|926|927|928|929|930|932|933|934|935|939|940|941|942|943|944|950|951|952|953|954|958|960|961|967|969|970|971|973|975|976|977|978|980|981|99))(?:(\d{2}(?!\d{3}$|\d{5}$|\d{7}$)|\d{3}))(?:(\d{2}(?!\d{1}$|\d{3}$)|\d{3}))(\d{0,3})/;

        return digits.replace(regex, "$1-$2 $3 $4");
    }

    private isForeignPhoneNumber(number: string): boolean {
        const regexp = new RegExp(/(^([0]{2})|^([+]))(?!46)([\d-\s]+)$/);

        return regexp.test(number);
    }
}
