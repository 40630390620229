import "@ha/util/common";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { TranslocoModule } from "@ngneat/transloco";

import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { InputRadioGroupComponent } from "./components/input-radio-group/input-radio-group.component";
import { InputComponent } from "./components/input/input.component";
import { SearchBoxComponent } from "./components/search-box/search-box.component";
import { SelectComponent } from "./components/select/select.component";
import { ValidationMessagesComponent } from "./components/validation-messages/validation-messages.component";

export { CheckboxComponent } from "./components/checkbox/checkbox.component";
export { InputComponent } from "./components/input/input.component";
export { InputRadioGroupComponent } from "./components/input-radio-group/input-radio-group.component";
export { SearchBoxComponent } from "./components/search-box/search-box.component";
export { SelectComponent } from "./components/select/select.component";
export { ValidationMessagesComponent } from "./components/validation-messages/validation-messages.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
    declarations: [
        CheckboxComponent,
        InputRadioGroupComponent,
        InputComponent,
        SearchBoxComponent,
        SelectComponent,
        ValidationMessagesComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CheckboxComponent,
        InputRadioGroupComponent,
        InputComponent,
        SelectComponent,
        SearchBoxComponent,
        ValidationMessagesComponent,
    ],
})
export class HaUiFormsModule { }
