/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { ServiceWorkerModule, SwRegistrationOptions } from "@angular/service-worker";
import { PermitQuestionStorageState } from "@ha/feature/permit";
import { SUPPORT_CONFIG } from "@ha/feature/support";
import { HaUiNavigationModule, SidebarState } from "@ha/ui/navigation";
import { HaUtilApplicationInsightsModule } from "@ha/util/application-insights";
import { HaUtilPwaModule, PwaState } from "@ha/util/pwa";
import { NgxsSelectSnapshotModule } from "@ngxs-labs/select-snapshot";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsModule } from "@ngxs/store";
import { AuthInterceptor } from "angular-auth-oidc-client";
import { FeatureNotificationModule, NOTIFICATION_CONFIG } from "@ha/feature/notification";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { routes } from "./app.routes";
import { AppAuthModule } from "./auth/auth.module";
import { BookmarksModule } from "./bookmarks/bookmarks.module";
import { BookmarksState } from "./bookmarks/state/bookmarks.state";
import { CertificateState } from "./certificate/state/certificate.state";
import { SupportConstants } from "./constants/support-constants";
import { CoreModule } from "./core/core.module";
import { SettingsState } from "./core/states/settings.state";
import { KnowledgeBankModule } from "./knowledge-bank/knowledge-bank.module";
import { TranslationModule } from "./translation/translation.module";
import { ApplicationFlagsEnum } from "@ha/ui/common";
import { UserAcceptNewAgreementModule } from "./user-accept-new-agreement/user-accept-new-agreement.module";

@NgModule({
    declarations: [AppComponent],
    imports: [
        TranslationModule,
        ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "ignore",
            anchorScrolling: "enabled",
            scrollPositionRestoration: "enabled",
            scrollOffset: [0, 100],
            preloadingStrategy: PreloadAllModules
        }),
        NgxsStoragePluginModule.forRoot({
            key: [SidebarState, CertificateState, PwaState, BookmarksState, PermitQuestionStorageState, SettingsState],
        }),
        NgxsFormPluginModule.forRoot(),
        NgxsModule.forRoot([], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: false,
                injectContainerState: false,
            },
        }),
        NgxsRouterPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        NgxsSelectSnapshotModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        HaUiNavigationModule,
        HaUtilPwaModule,
        HaUtilApplicationInsightsModule,

        CoreModule,
        AppAuthModule,
        BookmarksModule,
        KnowledgeBankModule,
        FeatureNotificationModule,
        UserAcceptNewAgreementModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: SUPPORT_CONFIG, useValue: SupportConstants },
        { provide: NOTIFICATION_CONFIG, useValue: { ApplicationFlags: ApplicationFlagsEnum.App } },
        { provide: SwRegistrationOptions, useFactory: () => ({ registrationStrategy: "registerImmediately" }) },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
