<div class="root" *transloco="let t; read:'permit'">
    <h2 class="root__question-text" [innerHTML]="t('workMethod.heading')"></h2>

    <div class="root__button-group">
        <div *ngFor="let workMethod of workMethods" class="root__button-group__confirm">
            <div class="root__button-group__confirm__button" (click)="toggleWorkMethod(workMethod)"
                 [class.root__button-group__confirm__button--active]="workMethod.isSelected$ | async">
                {{ workMethod.translations | haTranslate }}
            </div>
        </div>
    </div>

    <form *ngIf="isFreeTextOptionSelected$ | async" [formGroup]="form">
        <ha-input class="root__input" formControlName="workMethodManualInput" [label]="t('workMethod.other')"></ha-input>
    </form>

    <ng-container *ngFor="let workMethod of selectedWorkMethodsWithAlert$ | async">
        <pmt-permit-confirm-dialog [alert]="workMethod.alert" [safetyRules]="workMethod.safetyRules" (confirmedChange)="setAlertConfirmed(workMethod)">
        </pmt-permit-confirm-dialog>
    </ng-container>

    <div class="root__actions">
        <button class="root__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
        <button class="root__actions__continue" type="button" (click)="continue()"
                [class.root__actions__continue--disabled]="(workMethodsIsValid$ | async) === false">
            {{ t("next") }}
        </button>
    </div>
</div>
