<div class="modal" *transloco="let t; read:'permit.workplace.premium'">
    <form [formGroup]="form" (ngSubmit)="createWorkplace()">
        <div class="modal__content">
            <div>{{ t("intro") }}</div>
            <ha-input #nameInput class="modal__input" [autofocus]="true" autocomplete="off" formControlName="workplace" [label]="t('workplace')">
            </ha-input>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__save" type="submit">{{ t("create") }}</button>
        </div>
    </form>
</div>
