<ng-container *ngIf="(photoRequirement$ | async) !== undefined">
    <div *transloco="let t; read:'permit.permitPhoto.modal'" class="modal" [class.busy]="busy">
        <div class="modal__content">
            <div *ngIf="(photoRequirement$ | async) !== PhotoRequirement.Required && data.canDelete && (permitTrulyEnded$ | async) === false"
                 class="modal__content__delete">
                <div *ngIf="!deleteMode" (click)="deleteMode = true" class="modal__content__delete__anchor">{{ t("delete") }}</div>
                <ng-container *ngIf="deleteMode">
                    <div class="modal__content__delete__text">{{ t("deleteQuestion") }}</div>
                    <div class="modal__content__delete__confirm" (click)="deletePhoto(data.photo.guid)">{{ t("deleteYes") }}</div>
                    <div class="modal__content__delete__abort" (click)="deleteMode = false">{{ t("deleteNo") }}</div>
                </ng-container>
            </div>
            <img class="modal__content__photo" [src]="data.photo.url">
            <div class="modal__content__meta-data">
                <div class="modal__content__meta-data__user">{{ data.photo.author }}</div>
                <div class="modal__content__meta-data__date">{{ data.photo.uploadDate | haDate: "dateTimeStandard" }}</div>
            </div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>
        </div>
    </div>
</ng-container>
