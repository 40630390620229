import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { RecentPermitWorkplace } from "../../../models/recent-workplaces.model";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";
import { PermitWorkplaceSelectors } from "../../../states/permit-workplace.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-workplace-recent",
    templateUrl: "permit-workplace-recent.component.html",
    styleUrls: ["permit-workplace-recent.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitWorkplaceRecentComponent implements OnInit {
    @Select(PermitWorkplaceSelectors.recentWorkplaces)
    public recentWorkplaces$: Observable<RecentPermitWorkplace[]>;

    @Select(PermitWorkplaceSelectors.canLoadMoreRecentWorkplaces)
    public canLoadMore$: Observable<boolean>;

    @Select(PermitWorkplaceSelectors.loading)
    public loading$: Observable<boolean>;

    constructor(private store: Store) { }

    public ngOnInit(): void {
        this.store.dispatch(new PermitWorkplaceActions.GetRecentWorkplaceBasePermits());
    }

    public select(recentWorkplace: RecentPermitWorkplace) {
        if (!recentWorkplace.nodeId) {
            this.store.dispatch(new PermitWorkplaceActions.SetWorkplaceAndEmergencyLocation(recentWorkplace.workplace, recentWorkplace.emergencyLocation));
        } else {
            this.store.dispatch(new PermitWorkplaceActions.SetPremiumWorkplaceFromHistory(recentWorkplace));
        }
    }

    public loadMore(currentLength: number) {
        this.store.dispatch(new PermitWorkplaceActions.LoadMoreRecentWorkplaces(currentLength));
    }

    public fullWorkplaceName(recentWorkplace: RecentPermitWorkplace): string {
        if (recentWorkplace.workplace?.length > 0 && recentWorkplace.workplaceContainer == null)
            return recentWorkplace.workplace;
        else
            return recentWorkplace.workplace?.length > 0
                ? recentWorkplace.workplaceContainer + " - " + recentWorkplace.workplace
                : recentWorkplace.workplaceContainer;
    }
}
