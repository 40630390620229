import { EpiserverAngularModuleConfig } from "../models/episerver-angular-module-config";

export namespace EpiserverStateActions {
    export class SetCurrentConfig {
        public static readonly type = "[Episerver] SetCurrentConfig";
        constructor(public config: EpiserverAngularModuleConfig) {}
    }

    export class SearchAutocomplete {
        public static readonly type = "[Episerver] SearchAutocomplete";
        constructor(public text: string = "") {}
    }

    export class SearchClickTrack {
        public static readonly type = "[Episerver] SearchClickTrack";
        constructor(public query: string, public hitId: string, public trackId: string) {}
    }
}
