import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngxs/store";

import { PermitPaths } from "../../../permit-paths";
import { PreparedPermitListItem } from "../../models/prepared-permit-list-item.model";
import { PermitListActions } from "../../states/permit-list.actions";

@Component({
    selector: "pmt-prepared-permit-list-item",
    templateUrl: "./prepared-permit-list-item.component.html",
    styleUrls: ["./prepared-permit-list-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreparedPermitListItemComponent {
    @Input() public item!: PreparedPermitListItem;

    public permitPaths = PermitPaths;

    constructor(private store: Store) { }

    public onClick(): void {
        this.store.dispatch(new PermitListActions.ClaimPreparedPermit(this.item.id));
    }

    public fullPermitName(item: PreparedPermitListItem): string {
        if (item.workplaceContainer == null)
            return item.workplace;
        else
            return item.workplace?.length > 0 ? item.workplaceContainer + " - " + item.workplace : item.workplaceContainer;
    }
}
