import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { append, patch } from "@ngxs/store/operators";

import { HaError } from "../models/error.model";
import { ErrorsStateActions } from "./errors.actions";

export interface ErrorsStateModel {
    errors: HaError[];
}

@Injectable()
@State<ErrorsStateModel>({
    name: "errors",
    defaults: {
        errors: [],
    },
})
export class ErrorsState {
    @Action(ErrorsStateActions.New)
    public new(ctx: StateContext<ErrorsStateModel>, action: ErrorsStateActions.New): void {
        const newError: HaError = {
            correlationId: action.correlationId,
            date: new Date(),
            httpStatus: action.httpStatus,
            message: action.message,
            error: action.error,
        };

        ctx.setState(patch({
            errors: append([newError]),
        }));
    }

    @Action(ErrorsStateActions.Dismiss)
    public dismiss(ctx: StateContext<ErrorsStateModel>): void {
        ctx.patchState({
            errors: []
        });
    }
}
