import { ChangeDetectionStrategy, Component } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { ApplicationListModalComponent, ApplicationListModalComponentData } from "@ha/ui/navigation";
import { PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { ApplicationPaths } from "../../../constants/application-paths";
import { SettingsState } from "../../../core/states/settings.state";
import { HamburgerMenuStateActions } from "../../states/hamburger-menu.actions";
import { HamburgerMenuState } from "../../states/hamburger-menu.state";
import { ProfileStateActions } from "../../states/profile.actions";
import { ProfileState } from "../../states/profile.state";
import { Settings } from "./../../models/settings.model";
import { CurrentUserState } from "./../../states/current-user.state";
import { MobileNavigationComponentAnimations } from "./mobile-navigation.component.animations";

@Component({
    selector: "app-mobile-navigation",
    templateUrl: "./mobile-navigation.component.html",
    styleUrls: ["./mobile-navigation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [MobileNavigationComponentAnimations.slideTrigger],
})
export class MobileNavigationComponent {
    @Select(SettingsState.settings)
    public settings$: Observable<Settings>;

    @Select(CurrentUserState.showLinkToAdmin)
    public showLinkToAdmin$: Observable<boolean>;

    @Select(CurrentUserState.isHotworker)
    public isHotworker$: Observable<boolean>;

    @Select(CurrentUserState.isEducationParticipant)
    public isEducationParticipant$: Observable<boolean>;

    @Select(HamburgerMenuState.menuVisible)
    public menuVisible$: Observable<boolean>;

    @Select(ProfileState.menuOpen)
    public profileMenuVisible$: Observable<boolean>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    public applicationPaths = ApplicationPaths;

    constructor(private modalService: HaModalService, private store: Store) {}

    public openApplicationModal(): void {
        const applicationList = this.store.selectSnapshot(SettingsState.applicationList);
        this.modalService.open(
            ApplicationListModalComponent,
            true,
            <ApplicationListModalComponentData> { applicationList: applicationList },
        );
    }

    public toggleHamburgerMenu(): void {
        this.store.dispatch(new HamburgerMenuStateActions.ToggleMenu());
    }

    public hideAllMenus(): void {
        this.store.dispatch(new HamburgerMenuStateActions.HideAll());
        this.store.dispatch(new ProfileStateActions.HideMenu());
    }
}
