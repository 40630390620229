import { Selector } from "@ngxs/store";

import { AccountImpersonateResult } from "../models/account-impersonate-result.model";
import { AccountImpersonateState, AccountImpersonateStateModel } from "./account-impersonate.state";

export class AccountImpersonateSelectors {
    @Selector([AccountImpersonateState])
    public static result(state: AccountImpersonateStateModel): AccountImpersonateResult | undefined {
        return state.result;
    }
}
