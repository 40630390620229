import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";

import { AccordionBlockComponent } from "./components/accordion-block/accordion-block.component";
import { AccordionItemBlockComponent } from "./components/accordion-block/accordion-item-block.component";
import { AnchorMenuInlineComponent } from "./components/anchor-menu-inline/anchor-menu-inline.component";
import { AnchorMenuItemComponent } from "./components/anchor-menu/anchor-menu-item.component";
import { AnchorMenuComponent } from "./components/anchor-menu/anchor-menu.component";
import { DigitalBookModalComponent } from "./components/digital-book/digital-book-modal.component";
import { EpiserverPropertyComponent } from "./components/episerver-property/episerver-property.component";
import { ImageBlockComponent } from "./components/image-block/image-block.component";
import { IssuuContainerBlockComponent } from "./components/issuu-block/issuu-container-block.component";
import { IssuuItemBlockComponent } from "./components/issuu-block/issuu-item-block.component";
import { LinkItemComponent } from "./components/link-item/link-item.component";
import { LinkListBlockComponent } from "./components/link-list-block/link-list-block.component";
import { PropertyXhtmlStringComponent } from "./components/property-xhtml-string/property-xhtml-string.component";
import { VimeoVideoBlockComponent } from "./components/vimeo-video-block/vimeo-video-block.component";
import { VimeoVideoLinkComponent } from "./components/vimeo-video-block/vimeo-video-link.component";
import { VimeoVideoPlayerComponent } from "./components/vimeo-video-block/vimeo-video-player.component";
import { RegisterEpiComponent } from "./functions/register-epi-component";
import { EpiserverFindState } from "./states/episerver-find.state";
import { EpiserverState } from "./states/episerver.state";

export { AnchorMenuInlineComponent } from "./components/anchor-menu-inline/anchor-menu-inline.component";
export { AnchorMenuComponent } from "./components/anchor-menu/anchor-menu.component";
export { LinkListBlockComponent } from "./components/link-list-block/link-list-block.component";
export { PropertyXhtmlStringComponent } from "./components/property-xhtml-string/property-xhtml-string.component";
export { VimeoVideoBlockComponent } from "./components/vimeo-video-block/vimeo-video-block.component";
export { VimeoVideoLinkComponent } from "./components/vimeo-video-block/vimeo-video-link.component";
export { RegisterEpiComponent } from "./functions/register-epi-component";

RegisterEpiComponent("AccordionBlockComponent", AccordionBlockComponent);
RegisterEpiComponent("ImageBlockComponent", ImageBlockComponent);
RegisterEpiComponent("IssuuContainerBlockComponent", IssuuContainerBlockComponent);
RegisterEpiComponent("LinkListBlockComponent", LinkListBlockComponent);
RegisterEpiComponent("VimeoVideoBlockComponent", VimeoVideoBlockComponent);

@NgModule({
    imports: [CommonModule, RouterModule, NgxsModule.forFeature([EpiserverState, EpiserverFindState])],
    declarations: [
        AccordionBlockComponent,
        AccordionItemBlockComponent,
        AnchorMenuComponent,
        AnchorMenuInlineComponent,
        AnchorMenuItemComponent,
        DigitalBookModalComponent,
        EpiserverPropertyComponent,
        ImageBlockComponent,
        IssuuContainerBlockComponent,
        IssuuItemBlockComponent,
        LinkItemComponent,
        LinkListBlockComponent,
        PropertyXhtmlStringComponent,
        VimeoVideoBlockComponent,
        VimeoVideoLinkComponent,
        VimeoVideoPlayerComponent,
    ],
    exports: [
        AnchorMenuComponent,
        AnchorMenuInlineComponent,
        PropertyXhtmlStringComponent,
        LinkListBlockComponent,
        VimeoVideoBlockComponent,
        VimeoVideoLinkComponent,
    ],
})
export class FeatureEpiserverModule {}
