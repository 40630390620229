<a *ngIf="isInternal" [routerLink]="url" [title]="title">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a *ngIf="!isInternal" [href]="url  " [attr.title]="title" [attr.target]="target">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
