<div class="root" *transloco="let t; read:'permit.details.checkpoints'">
    <ng-container *ngIf="(fixedCheckpoints$ | async) || (customerCheckpoints$ | async) else loading">
        <h2 *ngIf="(fixedCheckpoints$ | async)" class="root__heading">{{ t("fixedCheckpointsHeading") }}</h2>

        <div *ngIf="(fixedCheckpoints$ | async)" class="root__rules">
            <div class="root__rules__rule" *ngFor="let ruleId of fixedRules$ | async">
                <div class="root__rules__rule__heading">
                    <div class="root__rules__rule__heading__line"></div>
                    <div class="root__rules__rule__heading__text">{{ t("safetyRuleHeading", { ruleId: ruleId }) }}</div>
                    <div class="root__rules__rule__heading__line"></div>
                </div>

                <div class="root__rules__rule__checkpoints">
                    <ng-container *ngFor="let checkpoint of (fixedCheckpoints$ | async)">
                        <pmt-permit-checkpoints-checkpoint *ngIf="checkpoint.ruleId === ruleId" [checkpoint]="checkpoint">
                        </pmt-permit-checkpoints-checkpoint>
                    </ng-container>
                </div>
            </div>
        </div>

        <h2 *ngIf="(customerCheckpoints$ | async) && (customerCheckpoints$ | async).length > 0" class="root__heading">{{ t("customerCheckpointsHeading") }}</h2>
        <div *ngIf="(customerCheckpoints$ | async) && (customerCheckpoints$ | async).length > 0" class="root__customer-checkpoints">
            <pmt-permit-checkpoints-customer-checkpoint *ngFor="let checkpoint of (customerCheckpoints$ | async)" [checkpoint]="checkpoint">
            </pmt-permit-checkpoints-customer-checkpoint>
        </div>
    </ng-container>
    <ng-template #loading>
        <div class="root__loading"></div>
    </ng-template>
</div>
