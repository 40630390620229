import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ImageBlock } from "./image-block";

@Component({
    templateUrl: "./image-block.component.html",
    styleUrls: ["./image-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageBlockComponent {
    public data: ImageBlock;

    public showCaption = false;
}
