<div *transloco="let t; read: 'ui.navigation'" class="modal">
    <div class="modal__content">
        <a *ngFor="let application of applicationList$ | async" class="puff" [href]="application.baseUrl">
            <ng-container *transloco="let t; read: 'ui.navigation.applications.' + application.internalIdentifier">
                <div class="puff__header">
                    <div class="puff__header__icon" [class]="'puff__header__icon--' + application.internalIdentifier"></div>
                    <div class="puff__header__heading" [innerHTML]="t('name')"></div>
                </div>

                <div class="puff__text" [innerHTML]="t('description')"></div>

                <div class="puff__bottom">
                    <div class="puff__bottom__line"></div>
                    <div class="puff__bottom__arrow" [class]="'puff__bottom__arrow--' + application.internalIdentifier"></div>
                </div>
            </ng-container>
        </a>

        <div class="modal__content__no-applications" *ngIf="(applicationList$ | async)?.length === 0">
            {{t('noApplications')}}
        </div>

    </div>
    <div class="modal__actions">
        <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
        <button class="modal__actions__sign-out" type="button" (click)="signOut()">{{ t("signOut") }}</button>
    </div>
</div>
