<div class="control" [class.control--invalid]="invalidAndDirty" [class.control--disabled]="isDisabled">
    <input [attr.id]="formControlName" [attr.name]="formControlName" class="control__input" type="checkbox" [(ngModel)]="value">
    <label [for]="formControlName" class="control__label">
        <svg viewBox="0 0 25 24" class="control__label__box" [class.control__label__box--ticked]="value">
            <path class="control__label__box__fill" d="M6,23c-2.9,0-5.2-2.3-5.2-5.2V7.3c0-2.9,2.3-5.2,5.2-5.2h10.5c2.9,0,5.2,2.3,5.2,5.2v10.5
		c0,2.9-2.3,5.2-5.2,5.2H6z" />
            <path class="control__label__box__border" d="M16.5,2.6c2.6,0,4.7,2.1,4.7,4.7v10.5c0,2.6-2.1,4.7-4.7,4.7H6c-2.6,0-4.7-2.1-4.7-4.7V7.3
		c0-2.6,2.1-4.7,4.7-4.7H16.5 M16.5,1.5H6c-3.2,0-5.8,2.6-5.8,5.8v10.5c0,3.2,2.6,5.8,5.8,5.8h10.5c3.2,0,5.8-2.6,5.8-5.8V7.3
        C22.3,4.1,19.7,1.5,16.5,1.5L16.5,1.5z" />
            <path class="control__label__box__tick" d="M5.3,11.1l5.7,6.4l6.1-8l6.1-8" />
        </svg>
        <div class="control__label__text" [innerHTML]="label"></div>
    </label>
</div>
