<ng-container *transloco="let t; read:'permit.team.add'">
    <div class="modal" [class.modal--no-scroll]="noScroll">
        <div class="modal__content">
            <div class="add-person">
                <div class="add-person__header">
                    <div class="add-person__header__heading">{{t(title)}}</div>

                    <ng-container *ngIf="showFireWatcherNotNeeded">
                        <ng-container *ngIf="!(permitTeam$ | async).fireWatchersNotNeeded">
                            <div *ngIf="role === PersonRole.FireWatcher && currentView !== View.NoFirewatcher" (click)="currentView = View.NoFirewatcher"
                                 class="add-person__header__link">{{t('noFireWatcherNeeded')}}</div>
                            <div *ngIf="role === PersonRole.FireWatcher && currentView === View.NoFirewatcher" (click)="currentView = View.LastUsed"
                                 class="add-person__header__link">{{t('fireWatcherNeeded')}}</div>
                        </ng-container>
                    </ng-container>
                </div>

                <ng-container *ngIf="currentView !== View.NoFirewatcher">
                    <div *ngIf="stagedPersons$ | async as stagedPersons" class="add-person__staged-users">
                        <div *ngFor="let person of stagedPersons" (click)="unstagePerson(person.certificateId)" class="add-person__staged-users__user">
                            {{person.firstName}}
                            {{person.lastName}}</div>
                    </div>

                    <div class="add-person__tab-menu">
                        <div class="item" (click)="currentView = View.LastUsed" [class.item--active]="currentView === View.LastUsed">{{t('tabs.lastUsed')}}
                        </div>
                        <div class="item" (click)="currentView = View.Search" [class.item--active]="currentView === View.Search">{{t('tabs.search')}}</div>
                        <div *ngIf="data.allowExternalCertificates" class="item" (click)="currentView = View.Other"
                             [class.item--active]="currentView === View.Other">
                            {{t('tabs.other')}}</div>
                    </div>

                    <pmt-last-used-persons-list *ngIf="currentView === View.LastUsed" [permitId]="permitId" [role]="role">
                    </pmt-last-used-persons-list>
                    <pmt-find-person *ngIf="currentView === View.Search" [permitId]="permitId" [role]="role">
                    </pmt-find-person>
                    <pmt-create-new-person *ngIf="currentView === View.Other && data.allowExternalCertificates" [permitId]="permitId" [role]="role">
                    </pmt-create-new-person>
                </ng-container>

                <ng-container *ngIf="currentView === View.NoFirewatcher">
                    <pmt-firewatcher-not-needed [permitId]="permitId" (requiredChange)="fireWatcherNotNeededChange($event)">
                    </pmt-firewatcher-not-needed>
                </ng-container>
            </div>
        </div>

        <div class="modal__actions" *ngIf="currentView !== View.NoFirewatcher">
            <button class="modal__actions__abort" type="button" (click)="abort()">{{ t("cancel") }}</button>
            <button class="modal__actions__add" type="button" (click)="addStagedPersons()"
                    [class.modal__actions__add--disabled]="(stagedPersons$ | async) === undefined || (stagedPersons$ | async)?.length <= 0">
                {{ t("add") }}<span *ngIf="(stagedPersons$ | async) && (stagedPersons$ | async)?.length > 0"> ({{(stagedPersons$ |
                    async)?.length}})</span>
            </button>
        </div>
    </div>
    <modal #alertModal class="alert-modal" [cancelLabel]="t('cancelAlert.cancel')" (cancel)="continue()" [okLabel]="t('cancelAlert.ok')" (ok)="abortClear()">
        <div [innerHtml]="t('cancelAlert.information')"></div>
    </modal>
</ng-container>
