import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Store, Select } from "@ngxs/store";
import { AccountUser, CurrentAccountSelectors } from "@ha/feature/auth";
import { Observable, Subject, takeUntil } from "rxjs";
import { PermitDetailsActions } from "../../states/permit-details.action";

export interface ExportPermitEmailModalComponentData {
    permitId: number;
}

@UntilDestroy()
@Component({
    templateUrl: "./export-permit-email-modal.component.html",
    styleUrls: ["./export-permit-email-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportPermitEmailModalComponent extends BaseModalComponent implements OnInit {
    @Select(CurrentAccountSelectors.currentAccountUser)
    public currentAccountUser$: Observable<AccountUser>;

    public data: ExportPermitEmailModalComponentData;

    public form = this.fb.group({
        email: ["", Validators.required],
    });

    public permitDetailsActionsSendPdfMail = PermitDetailsActions.SendPdfMail;

    constructor(private store: Store, private actions$: Actions, private fb: UntypedFormBuilder) {
        super();
    }
    public ngOnInit(): void {
        this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(PermitDetailsActions.SendPdfMail)).subscribe(() => {
            this.closeModal();
        });

        this.currentAccountUser$.pipe(takeUntil(new Subject())).subscribe(user => {
            this.form.get("email").setValue(user.email);
        });
    }

    public sendEmail(email: string): void {
        this.store.dispatch(new PermitDetailsActions.SendPdfMail(this.data.permitId, email));
    }

    public clearInput() {
        this.form.reset();
    }
}
