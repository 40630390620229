import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PhotoRequirement } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PhotoModalComponent, PhotoModalComponentData } from "../../../shared/components/photo-modal/photo-modal.component";
import { PermitPhoto } from "../../models/permit-photo.model";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";
import { PermitPhotoSelectors } from "../../states/permit-photo-selectors";
import { PermitPhotoActions } from "../../states/permit-photo.actions";

@UntilDestroy()
@Component({
    selector: "pmt-photo",
    templateUrl: "permit-photo.component.html",
    styleUrls: ["permit-photo.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitPhotoComponent implements OnInit {
    @Input() public photoRequirement: PhotoRequirement | undefined;

    @Select(PermitPhotoSelectors.permitPhotos)
    public photos$: Observable<PermitPhoto[]>;

    @Select(PermitCreateSelectors.isDemo)
    public isDemo$: Observable<boolean>;

    public PhotoRequirement = PhotoRequirement;

    constructor(private store: Store, private actions$: Actions, private modalService: HaModalService) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitPhotoActions.GetPermitPhotos());

        this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(PermitPhotoActions.GetPermitPhotos)).subscribe(() => {
            this.canContinue();
        });
    }

    public processSelectedImages(input: HTMLInputElement): void {
        const images = Array.from(input.files);
        for (const img of images) {
            this.store.dispatch(new PermitCreateActions.SetPermitPhoto(img));
        }
    }

    public removeImage(guid: string): void {
        this.store.dispatch(new PermitPhotoActions.RemovePermitPhoto(guid));
    }

    public canContinue(): boolean {
        if (this.photoRequirement && this.photoRequirement === PhotoRequirement.Required) {
            const photos = this.store.selectSnapshot(PermitPhotoSelectors.permitPhotos);
            if (photos?.length > 0) {
                return true;
            }

            return false;
        }

        return true;
    }

    public openModal(photo: PermitPhoto): void {
        this.modalService.open(PhotoModalComponent, true, <PhotoModalComponentData>{ photo: photo, canDelete: true });
    }

    public continue(): void {
        this.store.dispatch(new PermitStepActions.GoForward());
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards());
    }
}
