<div class="search" *transloco="let t; read:'permit.workplace.premium'">
    <div class="search__actions">
        <search-box class="search__actions__search-box" (triggerSearch)="search($event)" [(ngModel)]="query"
                [placeholder]="'Sök'" [focus]="false"></search-box>

        <div *ngIf="(searchResult$ | async) === undefined" class="search__actions__sort" (click)="toggleSort()"
                [class.search__actions__sort--descending]="sortedByNameDesc"></div>
    </div>

    <div class="search__result" *ngIf="searchResult$ | async as searchResults">
        <div class="search__top">
            <div class="search__top__count">{{ t("searchHits", { count: searchResults.length }) }}</div>
            <div class="search__top__clear" (click)="clearSearchResults()">{{ t("clearSearch") }}</div>
        </div>

        <pmt-workplace-search-result class="search__result__item" *ngFor="let result of searchResults" [searchResult]="result">
        </pmt-workplace-search-result>
    </div>
</div>
