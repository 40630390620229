<div *ngIf="details$ | async as details" class="root">
    <div class="root__header">
        <div class="root__header__left">
            <div class="root__header__left__company-name" [innerHTML]="details.companyName"></div>
            <div class="root__header__left__workplace" [innerHTML]="fullWorkplaceName(details)"></div>
            <div class="root__header__left__company-name" [innerHTML]="details.workplaceAdditionalInfo"></div>
        </div>
        <div class="root__header__right">
            <div class="root__header__right__top">
                <div *transloco="let t; read: 'permit.details.basic'" class="root__header__right__top__id" [innerHTML]="t('id', { id: details.id })"></div>
                <pmt-permit-public-status [status]="details.publicStatus"></pmt-permit-public-status>
            </div>
            <div class="root__header__right__bottom">
                <div class="root__header__right__bottom__flammable"></div>
            </div>
        </div>
    </div>
    <div class="root__emergency">
        <div class="root__emergency__number" [innerHTML]="details.emergencyNumber"></div>
        <div class="root__emergency__location" [innerHTML]="details.emergencyLocation"></div>
    </div>
</div>
