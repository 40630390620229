import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { IssuerStructureNode } from "../../../models/issuer-structure-node.model";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";

export interface CreateNewWorkplaceModalComponentData {
    workplaceContainer: IssuerStructureNode;
}

@UntilDestroy()
@Component({
    selector: "pmt-create-new-workplace-modal",
    templateUrl: "./create-new-workplace-modal.component.html",
    styleUrls: ["./create-new-workplace-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateNewWorkplaceModalComponent extends BaseModalComponent {
    public data: CreateNewWorkplaceModalComponentData;

    public form = this.fb.group({
        workplace: ["", Validators.required]
    });

    constructor(private store: Store, private fb: FormBuilder) {
        super();
    }

    public createWorkplace(): void {
        this.form.valueChanges;

        if (this.form.valid) {
            this.store.dispatch(new PermitWorkplaceActions.CreateWorkplace(this.data.workplaceContainer, this.form.value.workplace))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.closeModal();
                });
        }
    }
}
