import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { filter, map, Observable } from "rxjs";

import { CurrentAccountSelectors } from "../states/current-account.selectors";

@Injectable({ providedIn: "root" })
export class IsAdministratorOrImpersonatedGuard implements CanActivate {
    constructor(private store: Store) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(CurrentAccountSelectors.isAdministratorOrImpersonated)
            .pipe(
                // We can't let observable emit undefined because then it will resolve on first value.
                // We have to wait for selector to get an actual account from backend and resolve to either true or false
                filter(_ => _ !== undefined),
                map(_ => !!_)
            );
    }
}
