import { animate, animateChild, query, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthSelectors } from "@ha/feature/auth";
import { PwaState } from "@ha/util/pwa";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";

@Component({
    selector: "offline-signed-out",
    templateUrl: "./offline-signed-out.component.html",
    styleUrls: ["./offline-signed-out.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        // the fade-in/fade-out animation.
        trigger("fadeOut", [
            transition(":leave", [
                query(":leave", animateChild(), { optional: true }),
                animate(300, style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class OfflineSignedOutComponent {
    @Select(PwaState.isOffline)
    public isOffline$: Observable<boolean>;

    @Select(AuthSelectors.isAuthenticated)
    public isAuthenticated$: Observable<boolean>;
}
