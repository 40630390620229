import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PermitStep, PhotoRequirement } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitPhoto } from "../../../permit-create/models/permit-photo.model";
import { PermitPhotoSelectors } from "../../../permit-create/states/permit-photo-selectors";
import { PermitPhotoActions } from "../../../permit-create/states/permit-photo.actions";
import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PhotoModalComponent, PhotoModalComponentData } from "../../../shared/components/photo-modal/photo-modal.component";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";

@Component({
    selector: "pmt-details-photo",
    templateUrl: "./permit-details-photo.component.html",
    styleUrls: ["./permit-details-photo.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitDetailsPhotoComponent implements OnInit {
    @Input() public permitId: number;

    @Select(PermitPhotoSelectors.permitPhotos)
    public photos$: Observable<PermitPhoto[]>;

    @Select(PermitPhotoSelectors.permitPhotoRequirement)
    public photoRequirement$: Observable<PhotoRequirement>;

    @Select(PermitStepSelectors.currentStep)
    public currentStep$: Observable<CurrentStep>;

    @Select(PermitDetailsSelectors.currentUserIsObserver)
    public currentUserIsObserver$: Observable<boolean>;

    public PermitStep = PermitStep;
    public PhotoRequirement = PhotoRequirement;

    constructor(private store: Store, private haModalService: HaModalService) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitPhotoActions.GetPermitPhotoRequirement());
        this.store.dispatch(new PermitPhotoActions.GetPermitPhotos());
    }

    public processSelectedImages(input: HTMLInputElement): void {
        const images = Array.from(input.files);
        for (const img of images) {
            this.store.dispatch(new PermitPhotoActions.SavePermitPhoto(this.permitId, img));
        }
    }

    public cssUrl(url: string) {
        return `url(${url})`;
    }

    public removeImage(guid: string): void {
        this.store.dispatch(new PermitPhotoActions.RemovePermitPhoto(guid));
    }

    public openModal(photo: PermitPhoto): void {
        this.haModalService.open(PhotoModalComponent, true, <PhotoModalComponentData>
            { photo: photo, canDelete: this.store.selectSnapshot(PermitDetailsSelectors.currentUserIsIssuer) });
    }

    public canAddPhoto(photoRequirement: PhotoRequirement): boolean {
        return photoRequirement === PhotoRequirement.Prohibited ? false : true;
    }
}
