import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HaUiCommonModule } from "@ha/ui/common";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsModule } from "@ngxs/store";

import {
    PwaUpdateAvailableModalComponent,
} from "./components/pwa-update-available-modal/pwa-update-available-modal.component";
import { PwaState } from "./states/pwa.state";

@NgModule({
    imports: [
        CommonModule,
        HaUiCommonModule,
        TranslocoModule,
        NgxsModule.forFeature([PwaState]),
    ],
    declarations: [
        PwaUpdateAvailableModalComponent
    ]
})
export class HaUtilPwaModule {}
