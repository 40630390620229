<ng-container *ngIf="data">
    <ng-container *ngIf="!isArray">
        <div class="html" [innerHTML]="trustHtml(dataString)"></div>
    </ng-container>

    <ng-container *ngIf="isArray">
        <ng-container *ngFor="let item of dataFragments">
            <div *ngIf="item.html" class="html" [innerHTML]="trustHtml(item.html)"></div>

            <div *ngIf="item.content">
                <epi-property class="epi-property" *ngIf="item.content" [data]="item.content"></epi-property>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
