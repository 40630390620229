import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "ui-language",
    templateUrl: "./ui-language-selector.component.html",
    styleUrls: ["./ui-language-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiLanguageSelectorComponent {
    @Input() public currentLanguage!: string;
    @Input() public languages!: string[];
    @Input() public horizontal!: boolean;
    @Input() public lightBackground!: boolean;

    @Output() public languageChanged = new EventEmitter<string>();

    public setLanguage(languageCode: string): void {
        this.languageChanged.emit(languageCode);
    }
}
