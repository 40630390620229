<div class="permit-signature" *transloco="let t; read:'permit.signature'">
    <h2 class="permit-signature__heading" [innerHTML]="t('heading')"></h2>
    <div class="permit-signature__signtext" [innerHTML]="t('signText')"></div>
    <ng-container *ngIf="team$ | async as team">
        <div class="permit-signature__role">
            <h3 class="permit-signature__role__heading" [innerHTML]="t('roles.permitissuer')"></h3>
            <ng-container *ngIf="team.permitIssuer" [ngTemplateOutlet]="item"
                          [ngTemplateOutletContext]="{teamMembers: [team.permitIssuer], personRole: PersonRole.PermitIssuer}">
            </ng-container>
        </div>

        <ng-container *ngIf="!isNotFlammable">
            <div class="permit-signature__role">
                <h3 class="permit-signature__role__heading" [innerHTML]="t('roles.hotworker')"></h3>
                <ng-container *ngIf="team.hotWorkers" [ngTemplateOutlet]="item"
                              [ngTemplateOutletContext]="{teamMembers: team.hotWorkers, personRole: PersonRole.HotWorker}">
                </ng-container>
            </div>

            <div *ngIf="!team.fireWatchersNotNeeded" class="permit-signature__role">
                <h3 class="permit-signature__role__heading" [innerHTML]="t('roles.firewatcher')"></h3>
                <ng-container *ngIf="team.fireWatchers" [ngTemplateOutlet]="item"
                              [ngTemplateOutletContext]="{teamMembers: team.fireWatchers, personRole: PersonRole.FireWatcher}">
                </ng-container>
            </div>

            <div class="permit-signature__role">
                <h3 class="permit-signature__role__heading" [innerHTML]="t('roles.postfirewatcher')"></h3>
                <ng-container *ngIf="team.postFireWatchers" [ngTemplateOutlet]="item"
                              [ngTemplateOutletContext]="{teamMembers: team.postFireWatchers, personRole: PersonRole.PostFireWatcher}">
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

    <div class="permit-signature__actions">
        <button class="permit-signature__actions__back" type="button" (click)="cancel()">{{ t("cancel") }}</button>
        <button class="permit-signature__actions__continue" type="button" (click)="continue()"
                [class.permit-signature__actions__continue--disabled]="(teamSigned$ | async) === false">
            {{ t("next") }}
        </button>
    </div>

    <ng-template #item let-teamMembers="teamMembers" let-personRole="personRole">
        <div class="permit-signature__role__person-list">
            <div *ngFor="let teamMember of teamMembers" class="permit-signature__role__person-list__item"
                 [class.permit-signature__role__person-list__item--signed]="teamMember?.signature || (isDemo && personRole !== PersonRole.PermitIssuer)"
                 (click)="showSignPerson(teamMember, personRole)">
                <div class="permit-signature__role__person-list__item__text">{{teamMember.firstName}} {{teamMember.lastName}}</div>
                <div class="permit-signature__role__person-list__item__sign"></div>
            </div>
        </div>
    </ng-template>
</div>
