import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";

import { UserAgreement } from "../models/user-agreement.model";
import { UserDetails } from "../models/user-details.model";
import { UserService } from "../services/user.service";
import { UserStateActions } from "./user.actions";

export interface UserStateModel {
    user: UserDetails;
    agreement: UserAgreement;
    agreementLatestPublishedVersionNumber: number;
}

@Injectable()
@State<UserStateModel>({
    name: "user",
    defaults: {
        user: undefined,
        agreement: undefined,
        agreementLatestPublishedVersionNumber: undefined,
    },
})
export class UserState {
    constructor(private userService: UserService) {}

    @Selector()
    public static user(state: UserStateModel) {
        return state.user;
    }

    @Selector()
    public static agreement(state: UserStateModel) {
        return state.agreement;
    }

    @Selector()
    public static agreementLatestPublishedVersionNumber(state: UserStateModel) {
        return state?.agreementLatestPublishedVersionNumber;
    }

    @Action(UserStateActions.GetUser)
    public getUser(ctx: StateContext<UserStateModel>) {
        return this.userService.getUserDetails().pipe(tap((data) => {
            ctx.patchState({
                user: data,
            });
        }),);
    }

    @Action(UserStateActions.GetAgreement)
    public getAgreement(ctx: StateContext<UserStateModel>) {
        return this.userService.getAgreement().pipe(tap((data) => {
            ctx.patchState({
                agreement: data,
            });
        }),);
    }

    @Action(UserStateActions.GetAgreementLatestPublishedVersionNumber)
    public getAgreementLatestPublishedVersionNumber(ctx: StateContext<UserStateModel>) {
        return this.userService.getAgreementLatestPublishedVersionNumber().pipe(tap((data) => {
            ctx.patchState({
                agreementLatestPublishedVersionNumber: data,
            });
        }),);
    }
}
