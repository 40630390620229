import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { Store } from "@ngxs/store";
import { BarcodeFormat } from "@zxing/browser";
import { Result } from "@zxing/library";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";


@Component({
    selector: "pmt-card-qr-scanner",
    templateUrl: "card-qr-scanner.component.html",
    styleUrls: ["card-qr-scanner.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CardQrScannerComponent {
    @Output() public scanComplete = new EventEmitter<string>();

    @ViewChild("scanner", { static: false })
    public scanner: ZXingScannerComponent;

    public allowedFormats = [BarcodeFormat.QR_CODE];

    public isTorchOn = false;

    public isTorchCompatible = false;
    private cameras: MediaDeviceInfo[];

    public get canChangeCamera() {
        return this.cameras?.length > 1;
    }

    constructor(private store: Store) {
    }

    public setCardGuid(cardGuid: string) {
        this.scanComplete.emit(cardGuid);
    }

    public changeCamera() {
        if (this.cameras.length < 2) {
            return;
        }

        this.isTorchOn = false;

        const currentDevice = this.cameras.find(_ => _.deviceId === this.scanner.device.deviceId);
        const firstDeviceNotCurrent = this.cameras.find(_ => _.deviceId !== currentDevice.deviceId);
        this.scanner.device = firstDeviceNotCurrent;
    }

    public toggleTorch() {
        this.isTorchOn = !this.isTorchOn;
    }

    public torchCompatibleHandler(event: boolean) {
        // console.log("torchCompatible", event);
        this.isTorchCompatible = event;

        if (this.isTorchOn && !this.isTorchCompatible) {
            this.isTorchOn = false;
        }
    }

    public camerasFoundHandler(event: MediaDeviceInfo[]) {
        // console.log("camerasFound", event);
        this.cameras = event;
    }

    public camerasNotFoundHandler(event: any) {
        // console.log("camerasNotFound", event);
    }

    public scanSuccessHandler(event: string) {
        // console.log("scanSuccess", event);

        const url = new URL(event);
        const pathArray = url.pathname.split("/");
        // console.log(pathArray);

        if (pathArray[1] === "card") {
            this.setCardGuid(pathArray[2]);
        }
    }

    public scanErrorHandler(event: any) {
        // console.log("scanError", event);
    }

    public scanFailureHandler(event: any) {
        // console.log("scanFailure", event);
    }

    public scanCompleteHandler(event: Result) {
        // console.log("scanComplete", event);
    }
}
