<div class="search" (clickOutside)="clickOutside()">
    <div class="search__box" [class.search__box--empty]="!value">
        <input #searchInput class="search__box__input" type="text" (focus)="onFocus()" (blur)="onBlur($event)" [(ngModel)]="value"
               (keyup)="searchKeypress($event)" />
        <div class="search__box__icon" (click)="clickSearch($event)"></div>
        <label class="search__box__placeholder">{{ placeholder }}</label>
    </div>
    <div *ngIf="!autocompleteHidden && autocompleteList?.length > 0" class="search__autocomplete">
        <div *ngFor="let item of autocompleteList" class="search__autocomplete__item" [class.search__autocomplete__item-selected]="isSelected(item)"
        [innerHTML]="item.formattedText" (click)="autoCompleteSearch(item)"></div>
    </div>
</div>

