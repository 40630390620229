import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";


export interface PermitCheckpointsModalComponentData {
    permitId: number;
}

@Component({
    templateUrl: "./permit-checkpoints-modal.component.html",
    styleUrls: ["./permit-checkpoints-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitCheckpointsModalComponent extends BaseModalComponent {
    public data: PermitCheckpointsModalComponentData;
}

