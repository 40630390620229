export namespace AccountImpersonateActions {
    export class ImpersonateStart {
        public static readonly type = "[AccountImpersonate] ImpersonateStart";
        constructor(public identityUserId: string) {}
    }

    export class ImpersonateStop {
        public static readonly type = "[AccountImpersonate] ImpersonateStop";
    }
}
