import { WorkMethod } from "../models/fixed-template.model";

export namespace PermitCreateActions {

    export class GetActiveFixedTemplate {
        public static readonly type = "[PermitCreate] GetActiveFixedTemplate";
    }

    export class GetPermit {
        public static readonly type = "[PermitCreate] GetPermit";
        constructor(public permitId: number) { }
    }

    export class GetWorkplaceDetails {
        public static readonly type = "[PermitCreate] GetWorkplaceDetails";
        constructor(public workplaceId: number, public subscriptionId: number) { }
    }

    export class ResetToDefault {
        public static readonly type = "[PermitCreate] ResetToDefault";
    }

    export class ToggleWorkMethod {
        public static readonly type = "[PermitCreate] ToggleWorkMethod";
        constructor(public workMethod: WorkMethod) { }
    }

    export class ToggleWorkMethodAlertConfirmed {
        public static readonly type = "[PermitCreate] ConfirmWorkMethodAlert";
        constructor(public workMethod: WorkMethod) { }
    }

    export class SaveWorkMethods {
        public static readonly type = "[PermitCreate] SaveWorkMethods";
        constructor(public permitId: number) { }
    }

    export class SetIsFlammableHotWork {
        public static readonly type = "[PermitCreate] SetIsFlammableHotWork";
        constructor(public isFlammableHotWork: boolean) { }
    }

    export class SetManualWorkMethod {
        public static readonly type = "[PermitCreate] SetManualWorkMethod";
        constructor(public text: string) { }
    }


    export class SetEmergencyLocation {
        public static readonly type = "[PermitCreate] SetEmergencyLocation";
        constructor(public permitId: number, public emergencyLocation: string, public latitude: number, public longitude: number) { }
    }

    export class SetPermitPhoto {
        public static readonly type = "[PermitCreate] SetPermitPhoto";
        constructor(public photo: File) { }
    }

    export class SetFireAlarm {
        public static readonly type = "[PermitCreate] SetFireAlarm";
        constructor(public permitId: number, public text: string) { }
    }

    export class SetPostWorkMonitoringTime {
        public static readonly type = "[PermitCreate] PostWorkMonitoringTime";
        constructor(public permitId: number, public postWorkMonitoringTime: number) { }
    }

    export class StartPermit {
        public static readonly type = "[PermitCreate] StartPermit";
        constructor(public validTime: number) { }
    }

    export class ExtendPermit {
        public static readonly type = "[PermitCreate] ExtendPermit";
        constructor(public hours: number) { }
    }

    export class EndDemoOrNotFlammableHotWork {
        public static readonly type = "[PermitCreate] EndDemoOrNotFlammableHotWork";
    }

    export class SetIsDemo {
        public static readonly type = "[PermitCreate] SetIsDemo";
        constructor(public isDemo: boolean) { }
    }
}
