import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NotificationStatus } from "../../models/notification-status.enum";
import { Notification } from "../../models/notification.model";

@Component({
    selector: "ntf-notification-list-item",
    templateUrl: "notification-list-item.component.html",
    styleUrls: ['notification-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationListItemComponent {
    @Input() public notification: Notification;

    public NotificationStatus = NotificationStatus;
}
