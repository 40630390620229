import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResult } from "@ha/data/basic";
import { ContentReference } from "@ha/feature/episerver";

import { ApplicationPaths } from "../../constants/application-paths";
import { Bookmark } from "./../models/bookmark";

@Injectable({
    providedIn: "root",
})
export class BookmarksService {
    constructor(private httpClient: HttpClient) {}

    public getBookmarks() {
        const options = {
            params: {
                angularModuleRoute: ApplicationPaths.KnowledgeBank.Root,
            },
        };

        return this.httpClient.get<Bookmark[]>("/api/cms/angular/bookmarks", options);
    }

    public toggleBookmark(contentLink: ContentReference) {
        return this.httpClient.put<BaseResult>("/api/bookmarks/toggle", { contentId: contentLink.id });
    }
}
