import { Location } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    OnChanges,
    OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { of, Subject } from "rxjs";
import { auditTime, delay, filter } from "rxjs/operators";

import { XhtmlStringCustomFragment } from "../../models/episerver-base-types.model";

interface AsidePageNavigationLink {
    id: string;
    name: string;
}

@UntilDestroy()
@Component({
    selector: "anchor-menu",
    templateUrl: "anchor-menu.component.html",
    styleUrls: ["anchor-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorMenuComponent implements OnInit, OnChanges {
    @Input() public heading: string;
    @Input() public toTheTopText: string;

    @Input() public data: {
        value: string | XhtmlStringCustomFragment[];
        propertyDataType: string;
    };

    public items: AsidePageNavigationLink[];
    public currentFragment: string;
    public navigationClass: string;
    private isClickLock = false;

    private onWindowScrollSubject$ = new Subject();

    constructor(private route: ActivatedRoute, private router: Router, private location: Location, private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.route.fragment.pipe(untilDestroyed(this)).subscribe((fragment) => {
            this.clickLock();
            this.currentFragment = fragment ?? this.items[0].id;
        });

        this.onWindowScrollSubject$
            .pipe(
                untilDestroyed(this),
                filter(() => !this.isClickLock),
                auditTime(300),
            )
            .subscribe(() => {
                this.setClosestFragment();
            });
    }

    public ngOnChanges(): void {
        this.getHeadings();
    }

    @HostListener("window:scroll", ["$event"])
    public onWindowScroll(event: Event): void {
        this.onWindowScrollSubject$.next(event);
    }

    public setClosestFragment(): void {
        let closestItem: AsidePageNavigationLink;
        let closestDistance = Number.MAX_SAFE_INTEGER;

        if (window.scrollY === 0) {
            closestItem = this.items[0];
        } else {
            const halfWindowHeight = window.innerHeight / 2 - 140;
            Array.from(this.items).forEach((item) => {
                const element = document.querySelector("#" + item.id);
                if (element) {
                    const distance = Math.abs(element.getBoundingClientRect().top - halfWindowHeight);
                    if (distance < closestDistance) {
                        closestDistance = distance;
                        closestItem = item;
                    }
                }
            });
        }

        const newFragment = closestItem?.id ?? this.items[0].id;

        if (this.currentFragment !== newFragment) {
            this.currentFragment = newFragment;
            this.setLocation();
            this.cdr.markForCheck();
        }
    }

    public setLocation(): void {
        const url = this.router.createUrlTree(["."], { relativeTo: this.route, fragment: this.currentFragment });
        this.location.replaceState(url.toString());
    }

    public scrollToTop(): void {
        window.scroll({ top: 0, left: 0 });
    }

    private getHeadings() {
        const items: AsidePageNavigationLink[] = [];

        function getHeadings(html: string) {
            if (!html) {
                return;
            }

            const outer = html.match(/<h2 id=".*">.*<\/h2>/g);

            if (outer) {
                outer.forEach((outerMatch) => {
                    const inner = /<h2 id="(.*?)">(.*?)<\/h2>/.exec(outerMatch);
                    items.push({
                        id: inner[1],
                        name: inner[2],
                    });
                });
            }
        }

        if (Array.isArray(this.data.value)) {
            const fragments = this.data.value;
            fragments.forEach((fragment) => {
                if (fragment.html) {
                    getHeadings(fragment.html);
                }
            });
        } else {
            getHeadings(this.data.value);
        }

        this.items = items;
    }

    private clickLock() {
        this.isClickLock = true;
        of(true)
            .pipe(untilDestroyed(this), delay(1000))
            .subscribe(() => (this.isClickLock = false));
    }
}
