import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";

import { SharedModule } from "../shared/shared.module";
import { ExportPermitEmailModalComponent } from "./components/export-permit-email-modal/export-permit-email-modal.component";
import { ExportPermitComponent } from "./components/export-permit/export-permit.component";
import { PermitBasicDetailsComponent } from "./components/permit-basic-details/permit-basic-details.component";
import {
    PermitChangeIssuerModalComponent,
} from "./components/permit-change-issuer/change-issuer-modal/change-issuer-modal.component";
import {
    PermitChangeIssuerPremiumComponent,
} from "./components/permit-change-issuer/change-issuer-premium/change-issuer-premium.component";
import { PermitFindIssuerComponent } from "./components/permit-change-issuer/find-issuer/find-issuer.component";
import { PermitChangeIssuerComponent } from "./components/permit-change-issuer/permit-change-issuer.component";
import { PermitCheckpointsModalComponent } from "./components/permit-checkpoints-modal/permit-checkpoints-modal.component";
import {
    PermitCheckpointsCheckpointComponent,
} from "./components/permit-checkpoints/permit-checkpoints-checkpoint.component";
import {
    PermitCheckpointsCustomerCheckpointComponent,
} from "./components/permit-checkpoints/permit-checkpoints-customer-checkpoint.component";
import { PermitCheckpointsComponent } from "./components/permit-checkpoints/permit-checkpoints.component";
import {
    PermitCommentCreateComponent,
} from "./components/permit-comments/permit-comment-create-modal/permit-comment-create-modal.component";
import {
    PermitCommentRemoveModalComponent,
} from "./components/permit-comments/permit-comment-remove-modal/permit-comment-remove-modal.component";
import {
    PermitCommentsCommentComponent,
} from "./components/permit-comments/permit-comments-comment/permit-comments-comment.component";
import { PermitCommentsComponent } from "./components/permit-comments/permit-comments.component";
import { PermitCompletedComponent } from "./components/permit-completed/permit-completed.component";
import { PermitDetailsPhotoComponent } from "./components/permit-details-photo/permit-details-photo.component";
import {
    PermitDetailsTeamRemoveOrSignModalComponent,
} from "./components/permit-details-team/permit-details-team-remove-or-sign/permit-details-team-remove-or-sign.component";
import { PermitDetailsTeamComponent } from "./components/permit-details-team/permit-details-team.component";
import { PermitDetailsComponent } from "./components/permit-details/permit-details.component";
import {
    PermitEndSignatureModalComponent,
} from "./components/permit-end-signature-modal/permit-end-signature-modal.component";
import { PermitEndSignatureComponent } from "./components/permit-end-signature/permit-end-signature.component";
import { PermitNotificationsComponent } from "./components/permit-notifications/permit-notifications.component";
import { PermitSummaryComponent } from "./components/permit-summary/permit-summary.component";
import { PermitChangeIssuerState } from "./states/change-issuer-state/change-issuer.state";
import { PermitDetailsState } from "./states/permit-details.state";
import { ValidTimeComponent } from "./components/permit-valid-time/valid-time.component";
import { PermitValidityPeriodModalComponent } from "./components/permit-validity-period-modal/permit-validity-period-modal.component";
import { PermitTeamContactModalComponent } from "./components/permit-team-contact-modal/permit-team-contact-modal.component";

@NgModule({
    imports: [
        CommonModule,
        AngularSignaturePadModule,
        SharedModule,
        NgxsModule.forFeature([PermitDetailsState, PermitChangeIssuerState]),
    ],
    exports: [PermitDetailsComponent, PermitSummaryComponent, ExportPermitComponent],
    declarations: [
        PermitChangeIssuerComponent,
        PermitChangeIssuerModalComponent,
        PermitChangeIssuerPremiumComponent,
        PermitCheckpointsCheckpointComponent,
        PermitCheckpointsComponent,
        PermitCheckpointsCustomerCheckpointComponent,
        PermitCheckpointsModalComponent,
        PermitCommentCreateComponent,
        PermitCommentRemoveModalComponent,
        PermitCommentsCommentComponent,
        PermitCommentsComponent,
        PermitCommentsComponent,
        PermitCompletedComponent,
        PermitDetailsComponent,
        PermitDetailsTeamComponent,
        PermitFindIssuerComponent,
        PermitDetailsTeamRemoveOrSignModalComponent,
        PermitEndSignatureComponent,
        PermitEndSignatureModalComponent,
        PermitTeamContactModalComponent,
        PermitNotificationsComponent,
        PermitDetailsPhotoComponent,
        PermitSummaryComponent,
        PermitBasicDetailsComponent,
        ExportPermitComponent,
        ExportPermitEmailModalComponent,
        PermitValidityPeriodModalComponent,
        ValidTimeComponent
    ],
    providers: [],
})
export class PermitDetailsModule {}
