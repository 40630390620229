import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HaUiFormsModule } from "@ha/ui/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsModule } from "@ngxs/store";

import {
    SubscriptionInvitationAnswerComponent,
} from "./components/subscription-invitation-answer-modal/subscription-invitation-answer.component";
import { SubscriptionInvitationsComponent } from "./components/subscription-invitations/subscription-invitations.component";
import { SubscriptionsState } from "./states/subscriptions.state";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxsFormPluginModule,
        NgxsModule.forFeature([SubscriptionsState]),
        ReactiveFormsModule,
        RouterModule,
        HaUiFormsModule,
        TranslocoModule,
    ],
    exports: [
        SubscriptionInvitationsComponent
    ],
    declarations: [
        SubscriptionInvitationAnswerComponent,
        SubscriptionInvitationsComponent
    ],
    providers: [
    ]
})
export class SubscriptionsModule {}
