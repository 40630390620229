<div class="root" *transloco="let t; read:'knowledgeBank.startPage'">
    <knowledge-bank-search-box [isMain]="true"></knowledge-bank-search-box>

    <xhtmlstring [data]="data.mainBody" class="root__intro"></xhtmlstring>

    <div class="root__section-heading">{{ t("bookmarks") }}</div>
    <bookmarks direction="horizontal"></bookmarks>

    <div class="root__section-heading">{{ t("sections") }}</div>
    <knowledge-bank-start-page-sections></knowledge-bank-start-page-sections>
</div>
