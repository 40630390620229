import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CurrentUser } from "./../models/current-user.model";

@Injectable({ providedIn: "root" })
export class CurrentUserService {
    constructor(private httpClient: HttpClient) {}

    public get() {
        return this.httpClient.get<CurrentUser>("/api/currentuser/get");
    }
}
