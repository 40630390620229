import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HaUiCommonModule } from "@ha/ui/common";
import { HaUiFormsModule } from "@ha/ui/forms";
import { HaUtilPipesModule } from "@ha/util/pipes";
import { HaUtilDirectivesModule } from "@ha/utils/directives";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsModule } from "@ngxs/store";

import { PermitCreateSignPersonModalComponent } from "./components/permit-create-sign-person-modal/permit-create-sign-person-modal.component";
import { PermitPublicStatusComponent } from "./components/permit-public-status/permit-public-status.component";
import { PhotoModalComponent } from "./components/photo-modal/photo-modal.component";
import { TimeStepperComponent } from "./components/time-stepper.component";
import { ViewSignatureModalComponent } from "./components/view-signature-modal/view-signature-modal.component";
import { PermitUserState } from "./states/permit-user.state";

@NgModule({
    imports: [
        AngularSignaturePadModule,
        CommonModule,
        FormsModule,
        HaUiCommonModule,
        HaUiFormsModule,
        HaUtilDirectivesModule,
        HaUtilPipesModule,
        NgxsModule.forFeature([PermitUserState]),
        RouterModule,
        TranslocoModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        HaUiCommonModule,
        HaUiFormsModule,
        HaUtilDirectivesModule,
        HaUtilPipesModule,
        PermitCreateSignPersonModalComponent,
        PermitPublicStatusComponent,
        PhotoModalComponent,
        RouterModule,
        TimeStepperComponent,
        TranslocoModule,
        ViewSignatureModalComponent,
    ],
    declarations: [
        PermitCreateSignPersonModalComponent,
        TimeStepperComponent,
        ViewSignatureModalComponent,
        PermitPublicStatusComponent,
        PhotoModalComponent,
    ],
})
export class SharedModule {}
