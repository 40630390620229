import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
    selector: "pmt-time-stepper",
    templateUrl: "./time-stepper.component.html",
    styleUrls: ["./time-stepper.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeStepperComponent implements OnInit {
    @Input() public label: string;
    @Input() public default: number;
    @Input() public minValue: number | null;
    @Input() public maxValue: number | null;
    @Input() public smallStep: number;
    @Input() public bigStep: number;

    public form: UntypedFormGroup;

    @Output() public selectedValue = new EventEmitter<number>();

    constructor(private fb: UntypedFormBuilder) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            time: [{ value: this.default, disabled: true }, Validators.required]
        });

        this.selectedValue.emit(this.default);
    }

    public reset(): void {
        this.form.get("time").setValue(this.default);
        this.selectedValue.emit(this.default);
    }

    public change(change: number): void {
        const newValue = this.form.get("time").value as number + change;
        this.form.get("time").setValue(newValue);

        this.selectedValue.emit(newValue);
    }

    public canChange(value: number): boolean {
        const newValue = this.form.get("time").value as number + value;

        if (newValue < this.minValue || newValue > this.maxValue) {
            return false;
        }

        return true;
    }
}
