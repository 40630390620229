import { Injectable } from "@angular/core";
import { BaseResult, PersonRole } from "@ha/data/basic";
import { BaseValidationResult } from "@ha/ui/forms";
import { Action, State, StateContext } from "@ngxs/store";
import { Observable, tap } from "rxjs";

import { PermitTeamActions } from "../../permit-team/states/permit-team.action";
import { PermitStepActions } from "../../permit/states/permit-step.action";
import { CheckpointDto } from "../models/checkpoint-dto";
import { CheckpointSummaryDto } from "../models/checkpoint-summary-dto";
import { CreateCommentResult } from "../models/create-comment-result";
import { PermitBasicDetailsDto } from "../models/permit-basic-details.model";
import { PermitCommentsDto } from "../models/permit-comments-dto";
import { PermitNotificationsDto } from "../models/permit-notifications-dto";
import { PermitSummaryDto } from "../models/permit-summary.model";
import { PermitDetailsService } from "../services/permit-details.service";
import { PermitDetailsActions as PermitDetailsActions } from "./permit-details.action";

export interface PermitDetailsStateModel {
    fixedCheckpoints: CheckpointDto[];
    customerCheckpoints: CheckpointDto[];
    basicDetails: PermitBasicDetailsDto;
    summary: PermitSummaryDto;
    notifications: PermitNotificationsDto;
    comments: PermitCommentsDto;
    showPermitStarted: boolean;
    currentUserRoles: PersonRole[];
}

@Injectable()
@State<PermitDetailsStateModel>({
    name: "permitDetails"
})
export class PermitDetailsState {
    constructor(private permitDetailsService: PermitDetailsService) { }

    @Action(PermitDetailsActions.GetSummary)
    public getSummary(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.GetSummary): Observable<PermitSummaryDto> {
        return this.permitDetailsService.getSummary(action.permitId).pipe(tap(summary => {
            ctx.patchState({
                summary: summary
            });
        }));
    }

    @Action(PermitDetailsActions.GetBasicDetails)
    public getBasicDetails(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.GetBasicDetails): Observable<PermitBasicDetailsDto> {
        return this.permitDetailsService.getBasicDetails(action.permitId).pipe(tap(result => {
            ctx.patchState({
                basicDetails: result
            });
        }));
    }

    @Action(PermitDetailsActions.GetNotifications)
    public getNotifications(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.GetNotifications): Observable<PermitNotificationsDto> {
        return this.permitDetailsService.getNotifications(action.permitId).pipe(tap(notifications => {
            ctx.patchState({
                notifications: notifications
            });
        }));
    }

    @Action(PermitDetailsActions.GetCheckpoints)
    public getCheckpoints(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.GetCheckpoints): Observable<CheckpointSummaryDto> {
        return this.permitDetailsService.getCheckpoints(action.permitId).pipe(tap(data => {
            ctx.patchState({
                fixedCheckpoints: data.fixedCheckpoints,
                customerCheckpoints: data.customerCheckpoints
            });
        }));
    }

    @Action(PermitDetailsActions.GetComments)
    public getComments(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.GetSummary): Observable<PermitCommentsDto> {
        return this.permitDetailsService.getComments(action.permitId).pipe(tap(data => {
            ctx.patchState({
                comments: data
            });
        }));
    }

    @Action(PermitDetailsActions.GetCurrentUserRoles)
    public getCurrentUserRoles(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.GetCurrentUserRoles): Observable<PersonRole[]> {
        return this.permitDetailsService.getCurrentUserRoles(action.permitId).pipe(tap((data: PersonRole[]) => {
            ctx.patchState({
                currentUserRoles: data
            });
        }));
    }

    @Action(PermitDetailsActions.CreateComment)
    public createComment(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.CreateComment): Observable<CreateCommentResult> {
        return this.permitDetailsService.createComment(action).pipe(tap(result => {
            if (result.success) {
                ctx.dispatch(new PermitDetailsActions.GetComments(action.permitId));
            }
        }));
    }

    @Action(PermitDetailsActions.RemoveComment)
    public removeComment(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.RemoveComment): Observable<boolean> {
        return this.permitDetailsService.removeComment(action.comment.id).pipe(tap(result => {
            if (result) {
                ctx.dispatch(new PermitDetailsActions.GetComments(action.comment.permitId));
            }
        }));
    }

    @Action(PermitDetailsActions.ShowPermitStarted)
    public showPermitStarted(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.ShowPermitStarted): void {
        ctx.patchState({ showPermitStarted: action.show });
    }

    @Action(PermitDetailsActions.EndPermitWithSignature)
    public endPermitWithSignature(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.EndPermitWithSignature): Observable<BaseValidationResult> {
        return this.permitDetailsService.endPermit(action.command).pipe(tap(result => {
            if (result.success) {
                ctx.dispatch(new PermitDetailsActions.GetSummary(action.command.permitId));
                ctx.dispatch(new PermitDetailsActions.GetBasicDetails(action.command.permitId));
                ctx.dispatch(new PermitStepActions.GetCurrentStep());
                ctx.dispatch(new PermitTeamActions.GetTeam(action.command.permitId));
            }
        }));
    }

    @Action(PermitDetailsActions.SendPdfMail)
    public sendPdfMail(ctx: StateContext<PermitDetailsStateModel>, action: PermitDetailsActions.SendPdfMail): Observable<BaseResult> {
        return this.permitDetailsService.sendPdfMail(action).pipe(tap(result => {
            if (result.success) {
                // console.log("Success");
            }
        }));
    }
}
