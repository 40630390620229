import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FeatureEpiserverModule } from "@ha/feature/episerver";
import { HaUiCommonModule } from "@ha/ui/common";
import { HaUtilPipesModule } from "@ha/util/pipes";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsModule } from "@ngxs/store";

import { NotificationHeaderComponent } from "./components/notification-header/notification-header.component";
import { UnreadNotificationsModalComponent } from "./components/notification-header/unread-notifications-modal.component";
import { NotificationListActionsModalComponent } from "./components/notification-list/notification-list-actions-modal.component";
import { NotificationListItemComponent } from "./components/notification-list/notification-list-item.component";
import { NotificationListComponent } from "./components/notification-list/notification-list.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { RemoveNotificationModalComponent } from "./components/notification/remove-notification-modal.component";
import { NotificationState } from "./states/notification.state";

@NgModule({
    imports: [
        CommonModule,
        HaUiCommonModule,
        NgxsModule.forFeature([NotificationState]),
        HaUtilPipesModule,
        RouterModule,
        TranslocoModule,
        FeatureEpiserverModule,
    ],
    exports: [NotificationHeaderComponent],
    declarations: [
        NotificationComponent,
        NotificationHeaderComponent,
        NotificationListActionsModalComponent,
        NotificationListComponent,
        NotificationListItemComponent,
        RemoveNotificationModalComponent,
        UnreadNotificationsModalComponent,
    ],
    providers: [],
})
export class NotificationModule {}
