import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EpiserverAngularModuleConfig } from "@ha/feature/episerver";
import { Observable } from "rxjs";

import { ApplicationPaths } from "../../constants/application-paths";
import { KnowledgeBankNavigation } from "../models/knowledge-bank-navigation";
import { StartPageSection } from "../models/start-page-sections";

@Injectable({
    providedIn: "root",
})
export class KnowledgeBankService {
    constructor(private httpClient: HttpClient) {}

    public getConfig(): Observable<EpiserverAngularModuleConfig> {
        return this.httpClient.get<EpiserverAngularModuleConfig>("/api/cms/knowledgebank/config");
    }

    public getStartPageSections(): Observable<StartPageSection[]> {
        const options = {
            params: {
                angularModuleRoute: ApplicationPaths.KnowledgeBank.Root,
            },
        };

        return this.httpClient.get<StartPageSection[]>("/api/cms/knowledgebank/sections", options);
    }

    public getNavigationLinks(pageReferenceId: number): Observable<KnowledgeBankNavigation> {
        const options = {
            params: {
                pageReferenceId: pageReferenceId.toString(),
                angularModuleRoute: ApplicationPaths.KnowledgeBank.Root,
            },
        };

        return this.httpClient.get<KnowledgeBankNavigation>("/api/cms/knowledgebank/navigationLinks", options);
    }
}
