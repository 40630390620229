import { Injectable } from "@angular/core";
import { NotificationActions } from "@ha/feature/notification";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";

import { BookmarksActions } from "../../bookmarks/state/bookmarks.actions";
import { KnowledgeBankActions } from "../../knowledge-bank/state/knowledge-bank.actions";
import { CurrentUserActions } from "../states/current-user.actions";
import { SettingsStateActions } from "../states/settings.actions";

@Injectable({ providedIn: "root" })
export class AppInitService {
    constructor(private store: Store, private actions$: Actions) {}

    public init(): void {
        this.store.dispatch(new SettingsStateActions.GetSettings());

        this.actions$.pipe(ofActionSuccessful(CurrentUserActions.Get)).subscribe(() => {
            this.store.dispatch(new BookmarksActions.GetBookmarks());
            this.store.dispatch(new KnowledgeBankActions.Init());
            setTimeout(() => {
                this.store.dispatch(new NotificationActions.GetUnseenNotificationCount());
            }, 1500);
        });
    }
}
