import { Injectable } from "@angular/core";
import { AuthSelectors } from "@ha/feature/auth";
import { HaModalService } from "@ha/ui/common";
import { Network } from "@ngx-pwa/offline";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";

import {
    PwaUpdateAvailableModalComponent,
} from "../components/pwa-update-available-modal/pwa-update-available-modal.component";
import { PwaService } from "./../services/pwa.service";
import { PwaActions } from "./pwa.actions";

export interface PwaStateModel {
    updateAvailable: boolean;
    isOnline: boolean;
    showInstallModal: boolean;
    installDismissDate: Date;
    isOnInstallPage: boolean;
    isRunningAsPwa: boolean;
    isDesktop: boolean;
    isMobile: boolean;
}

@Injectable()
@State<PwaStateModel>({ name: "pwa" })
export class PwaState implements NgxsOnInit {
    constructor(private network: Network, private pwaService: PwaService, private modalService: HaModalService) {}

    @Selector()
    public static isOnline(state: PwaStateModel) {
        return state.isOnline;
    }

    @Selector()
    public static isOffline(state: PwaStateModel) {
        return !state.isOnline;
    }

    @Selector()
    public static isRunningAsPwa(state: PwaStateModel) {
        return state.isRunningAsPwa;
    }

    @Selector()
    public static isDesktop(state: PwaStateModel) {
        return state.isDesktop;
    }

    @Selector()
    public static isMobile(state: PwaStateModel) {
        return state.isMobile;
    }

    @Selector([PwaState, AuthSelectors.isAuthenticated])
    public static showInstallModal(pwaState: PwaStateModel, isAuthenticated: boolean) {
        if (!isAuthenticated) {
            return false;
        }

        if (pwaState.isRunningAsPwa || pwaState.isDesktop) {
            return false;
        }

        if (pwaState.installDismissDate) {
            const diffTime = new Date().getTime() - new Date(pwaState.installDismissDate).getTime();
            const diffDays = diffTime / (1000 * 3600 * 24);
            const showModalAgain = diffDays > 7;

            return showModalAgain && pwaState.showInstallModal;
        }

        if (pwaState.isOnInstallPage) {
            return false;
        }

        return pwaState.showInstallModal;
    }

    public ngxsOnInit(ctx: StateContext<PwaStateModel>) {
        this.network.onlineChanges.subscribe((isOnline) => {
            ctx.patchState({
                isOnline: isOnline,
            });
        });

        ctx.patchState({
            isRunningAsPwa: this.pwaService.isRunningAsPwa,
            isDesktop: this.pwaService.isDesktop,
            isMobile: this.pwaService.isMobile,
        });
    }

    @Action(PwaActions.InstallGoTo)
    public installGoTo(ctx: StateContext<PwaStateModel>) {
        ctx.patchState({
            showInstallModal: false,
        });
    }

    @Action(PwaActions.InstallDismiss)
    public installDismiss(ctx: StateContext<PwaStateModel>) {
        ctx.patchState({
            installDismissDate: new Date(),
        });
    }

    @Action(PwaActions.SetUpdateAvailable)
    public updateAvailable(ctx: StateContext<PwaStateModel>) {
        ctx.patchState({
            updateAvailable: true,
        });

        ctx.dispatch(new PwaActions.OpenModalIfUpdateAvailable());
    }

    @Action(PwaActions.OpenModalIfUpdateAvailable)
    public openModalIfUpdateAvailable(ctx: StateContext<PwaStateModel>) {
        if (ctx.getState().updateAvailable) {
            this.modalService.open(PwaUpdateAvailableModalComponent, false, undefined);
        }
    }
}
