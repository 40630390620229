import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PhotoRequirement } from "@ha/data/basic";
import { BaseValidationResult } from "@ha/ui/forms";
import { Observable } from "rxjs";

import { PermitPhoto } from "../models/permit-photo.model";

@Injectable({ providedIn: "root" })
export class PermitPhotoService {
    constructor(private httpClient: HttpClient) {}

    public savePermitPhoto(permitId: number, photo: File): Observable<boolean> {
        const formData = new FormData();
        formData.append("permitId", permitId.toString());
        formData.append("photo", photo);

        return this.httpClient.post<boolean>("/api/PermitPhoto/SavePermitPhoto", formData);
    }

    public getPermitPhotos(permitId: number): Observable<PermitPhoto[]> {
        const options = {
            params: {
                permitId: permitId,
            },
        };

        return this.httpClient.get<PermitPhoto[]>("/api/PermitPhoto/GetPhotos", options);
    }

    public getPermitPhotoRequirement(permitId: number): Observable<PhotoRequirement> {
        const options = {
            params: {
                permitId: permitId,
            },
        };

        return this.httpClient.get<PhotoRequirement>("/api/PermitPhoto/GetPhotoRequirement", options);
    }

    public removePhoto(fileName: string): Observable<BaseValidationResult> {
        const options = {
            params: {
                fileName: fileName,
            },
        };

        return this.httpClient.delete<BaseValidationResult>("/api/PermitPhoto/RemovePhoto", options);
    }
}
