export namespace UserActivateActions {
    export class Submit {
        public static readonly type = "[UserActivate] Submit";
    }

    export class GetActivationAgreement {
        public static readonly type = "[UserActivate] GetActivationAgreement";
    }

    export class ValidateUser {
        public static readonly type = "[UserActivate] ValidateUser";
    }

    export class AcceptAgreement {
        public static readonly type = "[UserActivate] AcceptAgreement";
    }

    export class RejectAgreement {
        public static readonly type = "[UserActivate] RejectAgreement";
    }

    export class ValidateIdentityAndToken {
        public static readonly type = "[UserActivate] ValidateIdentityAndToken";
    }

    export class AcceptNewAgreement {
        public static readonly type = "[UserActivate] AcceptNewAgreement";
    }
}
