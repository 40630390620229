<ng-container *transloco="let t; read:'permit.safetyRule'">
    <div class="modal">
        <div class="modal__content">
            <div class="modal__content__rule" *ngFor="let rule of data.safetyRules">
                <h2 class="modal__content__rule__heading" [innerHTML]="rule.titles | haTranslate"></h2>
                <p class="modal__content__rule__text" [innerHTML]="rule.translations | haTranslate"></p>
            </div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>
        </div>
    </div>
</ng-container>
