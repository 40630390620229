import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FeatureEpiserverModule, RegisterEpiComponent } from "@ha/feature/episerver";
import { HaUiFormsModule } from "@ha/ui/forms";
import { HaUiNavigationModule } from "@ha/ui/navigation";
import { NgxsModule } from "@ngxs/store";
import { AutoLoginAllRoutesGuard } from "angular-auth-oidc-client";
import { NgxMasonryModule } from "ngx-masonry";

import { BookmarksModule } from "../bookmarks/bookmarks.module";
import { ApplicationPaths } from "../constants/application-paths";
import { SharedModule } from "../shared/shared.module";
import { FloatingKnowledgebankNavigationComponent } from "./components/knowledge-bank-menu/floating-knowledgebank-navigation.component";
import { KnowledgeBankMenuItemComponent } from "./components/knowledge-bank-menu/knowledge-bank-menu-item.component";
import { KnowledgeBankMenuComponent } from "./components/knowledge-bank-menu/knowledge-bank-menu.component";
import { KnowledgeBankPageComponent } from "./components/knowledge-bank-page/knowledge-bank-page.component";
import { PageNavigationComponent } from "./components/knowledge-bank-page/page-navigation.component";
import { KnowledgeBankRootComponent } from "./components/knowledge-bank-root/knowledge-bank-root.component";
import { KnowledgeBankSearchBoxComponent } from "./components/knowledge-bank-search-box/knowledge-bank-search-box.component";
import { KnowledgeBankSearchResultsExtraContentComponent } from "./components/knowledge-bank-search-results/knowledge-bank-search-results-extra-content.component";
import { KnowledgeBankSearchResultsItemComponent } from "./components/knowledge-bank-search-results/knowledge-bank-search-results-item.component";
import { KnowledgeBankSearchResultsComponent } from "./components/knowledge-bank-search-results/knowledge-bank-search-results.component";
import { KnowledgeBankSearchComponent } from "./components/knowledge-bank-search/knowledge-bank-search.component";
import { KnowledgeBankStartPageSectionsComponent } from "./components/knowledge-bank-start-page/knowledge-bank-start-page-sections.component";
import { KnowledgeBankStartPageComponent } from "./components/knowledge-bank-start-page/knowledge-bank-start-page.component";
import { KnowledgeBankContentResolver } from "./services/knowledge-bank.resolver";
import { KnowledgeBankSearchState } from "./state/knowledge-bank-search.state";
import { KnowledgeBankState } from "./state/knowledge-bank.state";

export const KnowledgeBankRoutes: Routes = [{
    path: ApplicationPaths.KnowledgeBank.Root,
    data: { title: "titles.knowledgeBank", autoBreadcrumbsDisabled: true },
    canActivate: [AutoLoginAllRoutesGuard],
    children: [{
        path: "search",
        component: KnowledgeBankSearchComponent,
    }, {
        path: "",
        outlet: "sidebar",
        component: KnowledgeBankMenuComponent,
    }, {
        path: "",
        children: [{
            path: "**",
            component: KnowledgeBankRootComponent,
            resolve: {
                content: KnowledgeBankContentResolver,
            },
        }],
    }],
}];

RegisterEpiComponent("KnowledgeBankStartPageComponent", KnowledgeBankStartPageComponent);
RegisterEpiComponent("KnowledgeBankPageComponent", KnowledgeBankPageComponent);
RegisterEpiComponent("KnowledgeBankChildPageComponent", KnowledgeBankPageComponent);

@NgModule({
    declarations: [
        KnowledgeBankMenuComponent,
        KnowledgeBankMenuItemComponent,
        KnowledgeBankPageComponent,
        KnowledgeBankRootComponent,
        KnowledgeBankSearchBoxComponent,
        KnowledgeBankSearchComponent,
        KnowledgeBankSearchResultsComponent,
        KnowledgeBankSearchResultsExtraContentComponent,
        KnowledgeBankSearchResultsItemComponent,
        KnowledgeBankStartPageComponent,
        KnowledgeBankStartPageSectionsComponent,
        FloatingKnowledgebankNavigationComponent,
        PageNavigationComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxsModule.forFeature([KnowledgeBankState, KnowledgeBankSearchState]),
        NgxMasonryModule,
        BookmarksModule,
        FeatureEpiserverModule,

        SharedModule,
        HaUiFormsModule,
        HaUiNavigationModule,
    ],
    exports: [KnowledgeBankSearchBoxComponent, FloatingKnowledgebankNavigationComponent],
})
export class KnowledgeBankModule {}
