import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FeatureEpiserverModule } from "@ha/feature/episerver";
import { NgxsModule } from "@ngxs/store";

import { SharedModule } from "../shared/shared.module";
import { BookmarksPanelComponent } from "./components/bookmarks-panel/bookmarks-panel.component";
import { BookmarksComponent } from "./components/bookmarks/bookmarks.component";
import { BookmarksState } from "./state/bookmarks.state";

@NgModule({
    declarations: [BookmarksComponent, BookmarksPanelComponent],
    imports: [
        SharedModule,
        CommonModule,
        FeatureEpiserverModule,
        RouterModule,
        NgxsModule.forFeature([BookmarksState]),
    ],
    exports: [BookmarksComponent, BookmarksPanelComponent],
})
export class BookmarksModule {}
