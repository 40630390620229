import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy } from "@ngneat/until-destroy";


// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PwaUpdateAvailableModalComponentData {
}

@UntilDestroy()
@Component({
    templateUrl: "./pwa-update-available-modal.component.html",
    styleUrls: ["./pwa-update-available-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PwaUpdateAvailableModalComponent extends BaseModalComponent {
    public override data: PwaUpdateAvailableModalComponentData;

    public update(): void {
        location.reload();
    }

    public cancel(): void {
        this.closeModal();
    }
}

