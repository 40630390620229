export const PermitPaths = {
    Root: "permit",
    Create: "create",
    CreateDemo: "createdemo",
    Details: "details",
    Prepare: "prepare",
    PreparedPermits: "preparedpermits",
    Sign: "sign",
    Summary: "summary",
    Pdf: "pdf"
};
