import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { AutoLoginAllRoutesGuard } from "angular-auth-oidc-client";

import { NotificationListComponent } from "./notification/components/notification-list/notification-list.component";
import { NotificationComponent } from "./notification/components/notification/notification.component";
import { NotificationModule } from "./notification/notification.module";

export { NotificationHeaderComponent } from "./notification/components/notification-header/notification-header.component";
export { NotificationComponent } from "./notification/components/notification/notification.component";

export { NOTIFICATION_CONFIG } from "./notification/services/notification.service";
export const NotificaitonPaths = {
    Root: "notification",
};

export const NotificationRoutes: Routes = [{
    path: "notification",
    data: { title: "global.titles.notifications" },
    canActivate: [AutoLoginAllRoutesGuard],
    children: [{
        path: "",
        component: NotificationListComponent,
    }, {
        path: ":notificationId",
        data: { autoBreadcrumbsDisabled: true },
        children: [{
            path: "",
            component: NotificationComponent,
        }],
    }],
}];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(NotificationRoutes), TranslocoModule, NotificationModule],
    exports: [NotificationModule],
    declarations: [],
    providers: [],
})
export class FeatureNotificationModule {}
