import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Subscription } from "rxjs";

import { PermitPersonWithStatus } from "../../../shared/models/permit-person.model";
import { PermitTeamActions } from "../../states/permit-team.action";
import { PermitTeamSelectors } from "../../states/permit-team.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-stage-person",
    templateUrl: "stage-person.component.html",
    styleUrls: ["stage-person.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class StagePersonComponent implements OnInit, OnDestroy {
    @Input() public person: PermitPersonWithStatus;
    @Input() public permitId: number;
    @Input() public role: PersonRole;

    public staged = false;

    private stagedSubscription: Subscription;

    public get isDisabled(): boolean {
        return this.person.certificateExpired || !this.person.canBeAdded;
    }

    constructor(private store: Store, private changeRef: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.stagedSubscription = this.store.select(PermitTeamSelectors.stagedPersons)
            .pipe(untilDestroyed(this))
            .subscribe(stagedPersons => {
                if (stagedPersons) {
                    this.staged = stagedPersons.findIndex(_ => _.certificateId === this.person.certificateId) > -1;
                } else {
                    this.staged = false;
                }

                this.changeRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.stagedSubscription.unsubscribe();
    }

    public toggleStagePerson(person: PermitPersonWithStatus) {
        if (!person.canBeAdded || person.certificateExpired) {
            return;
        }

        if (this.staged) {
            this.unstagePerson(person.certificateId);
        } else {
            this.stagePerson(person);
        }
    }

    private stagePerson(person: PermitPersonWithStatus) {
        this.store.dispatch(new PermitTeamActions.StagePerson(person));
    }

    private unstagePerson(certificateId: number) {
        this.store.dispatch(new PermitTeamActions.UnstagePerson(certificateId));
    }
}
