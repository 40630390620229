import { PermitStep } from "@ha/data/basic";
import { Selector } from "@ngxs/store";

import { CurrentStep } from "../models/current-step.model";
import { PermitCompononentEnum } from "../models/permit-component.enum.";
import { PermitStepState, PermitStepStateModel } from "./permit-step.state";

export class PermitStepSelectors {
    @Selector([PermitStepState])
    public static currentStep(state: PermitStepStateModel): CurrentStep {
        return state?.currentStep;
    }

    @Selector([PermitStepState])
    public static permitId(state: PermitStepStateModel): number {
        return state?.permitId;
    }

    @Selector([PermitStepState])
    public static currentComponent(state: PermitStepStateModel): PermitCompononentEnum {
        if (!state.currentStep?.step) {
            return PermitCompononentEnum.Nothing;
        }

        switch (state.currentStep?.step) {
            case PermitStep.started:
            case PermitStep.ended:
            case PermitStep.archived:
            case PermitStep.expiredNotSigned:
                return PermitCompononentEnum.Details;
            default:
                return PermitCompononentEnum.Create;
        }
    }

    @Selector([PermitStepState])
    public static permitTrulyEnded(state: PermitStepStateModel): boolean {
        return state.currentStep.step >= PermitStep.ended;
    }
}
