<div class="root" *transloco="let t; read:'permit.details.summary'">
    <div *ngIf="summary$ | async as summary">
        <div *ngIf="summary.startDate" class="root__content">
            <div class="root__content__heading">{{ t("validTime") }}</div>
            <div class="root__content__text">
                <div class="root__content__text__start-date">
                    {{ summary.startDate | haDate: "dateTimeStandard" }}
                </div>
                <div *ngIf="summary.endDate" class="root__content__text__closed-date">
                    {{ summary.endDate | haDate: "timeIfSameDayElseFullDate":summary.startDate }}
                </div>
                <div *ngIf="summary.premium && workplaceDetails$ | async as premiumPermitSetting" class="root__content__update-box">
                    <ng-container *ngIf="permit$ | async as permitSetting">
                        <button *ngIf="canExtend(currentUserRoles$ | async) && (premiumPermitSetting.permitDuration - permitSetting.validityPeriod > 0) && (summary.closedDate == null && !summary.closedBySystem)" class="root__content__update-button" (click)="openValidityPeriodModal()">{{ t("updateValidity") }}</button>
                        <div *ngIf="(summary.permitDurationComment != null && summary.permitDurationComment.length > 0)" class="root__info">{{summary.permitDurationComment}}</div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="root__content" *ngIf="(currentStep$ | async).step >= PermitStep.ended">
            <div class="root__content__heading">{{ t("closedByAndDate") }}</div>
            <div class="root__content__text">
                <ng-container *ngIf="!summary.closedBySystem && permitIssuer$ | async as issuer">
                    {{ issuer.firstName + ' ' + issuer.lastName }}
                </ng-container>
                <ng-container *ngIf="summary.closedBySystem">
                    {{ t("closedBySystem") }}
                </ng-container>
                {{ summary.closedDate | haDate: "dateTimeStandard" }}
            </div>
        </div>

        <div class="root__content">
            <div class="root__content__heading">{{ t("workMethods") }}</div>
            <div class="root__content__text">{{ getTranslatedWorkMethodText(summary.workMethods, summary.workMethodManualInput) }}</div>
        </div>

        <div class="root__content" *ngIf="summary.disabledFireAlarm">
            <div class="root__content__heading">{{ t("disabledFireAlarm") }}</div>
            <div class="root__content__text">{{ summary.disabledFireAlarm }}</div>
        </div>

        <div class="root__content" *ngIf="summary.operationManager">
            <div class="root__content__heading">{{ t("operationManager") }}</div>
            <div class="root__content__text">{{ summary.operationManager }}</div>
        </div>

        <div class="root__content" *ngIf="summary.superIntendent">
            <div class="root__content__heading">{{ t("superIntendent") }}</div>
            <div class="root__content__text">{{ summary.superIntendent }}</div>
        </div>

        <div class="root__content">
            <div class="root__content__heading">{{ t("postWorkMonitoringTime") }}</div>
            <div class="root__content__text">{{ summary.postWorkMonitoringTime }} min</div>
        </div>

        <div class="root__content">
            <div class="root__content__heading">{{ t("checkpointsHeading") }}</div>
            <div class="root__content__open-modal" (click)="openPermitCheckpointsModal()">
                {{ t("openCheckpointsModal") }}
            </div>
        </div>
    </div>
</div>
