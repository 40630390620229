import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";

import { SidebarActions } from "./sidebar.actions";

export interface SidebarStateModel {
    personalPanelExpanded: boolean;
}

@Injectable()
@State<SidebarStateModel>({
    name: "sidebar",
    defaults: {
        personalPanelExpanded: false,
    },
})
export class SidebarState {
    @Selector()
    public static personalPanelExpanded(state: SidebarStateModel): boolean {
        return state.personalPanelExpanded;
    }

    @Action(SidebarActions.TogglePersonalPanelExpanded)
    public TogglePersonalPanelExpanded(ctx: StateContext<SidebarStateModel>): void {
        ctx.patchState({ personalPanelExpanded: !ctx.getState().personalPanelExpanded });
    }
}
