import { Selector } from "@ngxs/store";

import { CustomerPermitTemplate } from "../models/customer-permit-template.model";
import { FixedTemplate, SpecialStepInformation, WorkMethod } from "../models/fixed-template.model";
import { Permit } from "../models/permit.model";
import { SafetyRule } from "../models/safety-rule";
import { WorkplaceDetails } from "../models/workplace-details.model";
import { PermitCreateState, PermitCreateStateModel } from "./permit-create.state";

export class PermitCreateSelectors {
    @Selector([PermitCreateState])
    public static allowExternalCertificates(state: PermitCreateStateModel): boolean {
        return state?.permit.allowExternalCertificates;
    }

    @Selector([PermitCreateState])
    public static fixedTemplateDetails(state: PermitCreateStateModel): FixedTemplate | undefined {
        return state?.fixedTemplate;
    }

    @Selector([PermitCreateState])
    public static customerPermitTemplate(state: PermitCreateStateModel): CustomerPermitTemplate | undefined {
        return state?.customerPermitTemplate;
    }

    @Selector([PermitCreateState])
    public static emergencyLocationSafetyRules(state: PermitCreateStateModel): SafetyRule[] | undefined {
        return state?.fixedTemplate?.emergencyLocation.safetyRules;
    }

    @Selector([PermitCreateState])
    public static fireAlarmInformation(state: PermitCreateStateModel): SpecialStepInformation | undefined {
        return state.fixedTemplate?.fireAlarm;
    }

    @Selector([PermitCreateState])
    public static isPremium(state: PermitCreateStateModel): boolean {
        if (!state || !state.permit) {
            return false;
        }

        return state.permit.isPremium;
    }

    @Selector([PermitCreateState])
    public static selectedWorkMethods(state: PermitCreateStateModel): WorkMethod[] {
        return state.permitQuestionAnswer?.selectedWorkMethods;
    }

    @Selector([PermitCreateState, PermitCreateSelectors.selectedWorkMethods])
    public static selectedWorkMethodsWithAlert(state: PermitCreateStateModel, selectedWorkMethods: WorkMethod[]): WorkMethod[] {
        const selectedWorkMethodIds = selectedWorkMethods.map(_ => _.id);
        const workMethods = state?.fixedTemplate?.workMethods.filter(_ => _.alert && selectedWorkMethodIds?.includes(_.id));
        const uniqueBySafetyRule = <WorkMethod[]>[];

        workMethods.forEach(_ => {
            const previouslyAddedSafetyRuleIds = uniqueBySafetyRule.flatMap(_ => _.safetyRules.flatMap(_ => _.id));
            const thisWorkMethodsSafetyRuleIds = _.safetyRules.flatMap(_ => _.id);

            // Only add workmethod if its safety rules is not included before
            if (!thisWorkMethodsSafetyRuleIds.every(_ => previouslyAddedSafetyRuleIds.includes(_))) {
                uniqueBySafetyRule.push(_);
            }
        });

        return uniqueBySafetyRule;
    }

    @Selector([PermitCreateState, PermitCreateSelectors.selectedWorkMethodsWithAlert, PermitCreateSelectors.isManualWorkMethodFormValid])
    public static workMethodsIsValid(
        state: PermitCreateStateModel,
        selectedWorkMethodsWithAlert: WorkMethod[],
        isOtherWorkMethodFormValid: boolean,
    ): boolean {
        return state.permitQuestionAnswer?.selectedWorkMethods.length > 0
            && selectedWorkMethodsWithAlert.every(_ => state.permitQuestionAnswer.confirmedleWorkMethodAlerts.some(wm => wm.id === _.id))
            && isOtherWorkMethodFormValid;
    }

    @Selector([PermitCreateState, PermitCreateSelectors.isManualWorkMethodFormEnabled])
    public static isManualWorkMethodFormValid(state: PermitCreateStateModel, isManualWorkMethodFormEnabled: boolean): boolean {
        return !isManualWorkMethodFormEnabled || state.permitQuestionAnswer?.workMethodManual?.length > 0;
    }

    @Selector([PermitCreateState])
    public static workMethodManual(state: PermitCreateStateModel): string {
        return state.permitQuestionAnswer?.workMethodManual;
    }

    @Selector([PermitCreateState])
    public static isManualWorkMethodFormEnabled(state: PermitCreateStateModel): boolean {
        return state.permitQuestionAnswer.selectedWorkMethods.some(_ => _.isFreeText);
    }

    @Selector([PermitCreateState])
    public static permit(state: PermitCreateStateModel): Permit {
        return state?.permit;
    }

    @Selector([PermitCreateState])
    public static permitId(state: PermitCreateStateModel): number {
        return state?.permit?.id;
    }

    @Selector([PermitCreateState])
    public static workplaceDetails(state: PermitCreateStateModel): WorkplaceDetails {
        return state?.workplaceDetails;
    }

    @Selector([PermitCreateState])
    public static companyName(state: PermitCreateStateModel): string {
        return state?.permit?.companyName;
    }

    @Selector([PermitCreateState])
    public static workplace(state: PermitCreateStateModel): string {
        return state?.permit?.workplace;
    }

    @Selector([PermitCreateState])
    public static emergencyLocation(state: PermitCreateStateModel): string {
        return state?.workplaceDetails?.emergencyLocation;
    }

    @Selector([PermitCreateState])
    public static allowGpsLocation(state: PermitCreateStateModel): boolean {
        return state?.workplaceDetails?.allowGpsLocation;
    }

    @Selector([PermitCreateState])
    public static fireWatcherRequired(state: PermitCreateStateModel): boolean {
        return state?.workplaceDetails?.fireWatcherRequired;
    }

    @Selector([PermitCreateState])
    public static isFlammableHotWork(state: PermitCreateStateModel): boolean {
        return state?.permit?.isFlammableHotWork || state?.permitQuestionAnswer?.isFlammableHotWork;
    }

    @Selector([PermitCreateState])
    public static isDemo(state: PermitCreateStateModel): boolean {
        return state?.permit?.isDemo || state?.isDemo;
    }
}
