import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";

import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitTeamActions } from "../../states/permit-team.action";


export interface RemovePersonModalComponentData {
    permitId: number;
    role: PersonRole;
    person: PermitPerson;
}

@UntilDestroy()
@Component({
    templateUrl: "remove-person-modal.component.html",
    styleUrls: ["remove-person-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RemovePersonModalComponent extends BaseModalComponent implements OnInit {
    public data: RemovePersonModalComponentData;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionSuccessful(PermitTeamActions.RemovePerson))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.closeModal();
            });
    }

    public removePerson() {
        this.store.dispatch(new PermitTeamActions.RemovePerson({
            permitId: this.data.permitId,
            personId: this.data.person.personId,
            role: this.data.role
        }));
    }
}
