<div class="root" *ngIf="notification$ | async as notification">
    <div class="root__mobile-header">
        <a class="root__mobile-header__parent" routerLink="..">Tillbaka till notiser</a>
        <div class="root__mobile-header__remove" (click)="remove()"></div>
    </div>

    <div class="root__heading" [innerHTML]="notification.heading.value"></div>

    <div class="root__header">
        <div class="root__header__heading" [innerHTML]="notification.heading.value"></div>
        <div class="root__header__remove" (click)="remove()"></div>
    </div>

    <div class="root__date" [innerHTML]="notification.startPublish | haDate:'dateTimeLongMonthWithYear'"></div>

    <xhtmlstring [data]="notification.mainBody" class="root__text"></xhtmlstring>
</div>
