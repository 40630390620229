<div class="top-bar">
    <a *ngIf="parentBreadcrumb$ | async as parentBreadcrumb" class="top-bar__parent" routerLink=".." [innerHTML]="parentBreadcrumb.text"></a>
    <div class="top-bar__bookmark" [class.top-bar__bookmark--bookmarked]="isBookmarked$ | async" (click)="toggleBookmark()"></div>
</div>

<div class="page" *transloco="let t; read:'knowledgeBank'">
    <div class="page__content">
        <h1 class="page__content__heading" [innerHTML]="data.heading.value"></h1>

        <anchor-menu-inline class="page__content__inline-anchor-menu" [heading]="t('anchorMenuHeading')" [data]="data.mainBody">
        </anchor-menu-inline>

        <xhtmlstring [data]="data.mainBody" class="page__content__main-body"></xhtmlstring>

        <page-footer [publishDate]="data.startPublish" [changeDate]="data.changed" [shareUrl]="mailtoUrl"></page-footer>

        <page-navigation [data]="data"></page-navigation>
    </div>

    <scroll-to-top></scroll-to-top>
    <div class="page__aside">
        <a class="page__aside__bookmark" [class.page__aside__bookmark--bookmarked]="isBookmarked$ | async" (click)="toggleBookmark()"
           [innerText]="(isBookmarked$ | async) === false ? t('bookmarkThisPage') : t('unbookmarkThisPage')"></a>
        <anchor-menu *ngIf="(isMobile$ | async) === false" [heading]="t('anchorMenuHeading')" [toTheTopText]="t('anchorMenutoTheTopText')"
                     [data]="data.mainBody">
        </anchor-menu>
    </div>
</div>
