<ng-container *transloco="let t; read:'feature.notification'">
    <div class="modal">
        <div class="modal__content">{{ t("removeVerification") }}</div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__remove" type="button" (click)="remove()">{{ t("remove") }}</button>
        </div>
    </div>
</ng-container>
