import { animate, animateChild, query, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { timer } from "rxjs";

@UntilDestroy()
@Component({
    selector: "splash-screen",
    templateUrl: "./splash-screen.component.html",
    styleUrls: ["./splash-screen.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger("fadeOut", [
            transition(":leave", [
                query(":leave", animateChild(), { optional: true }),
                animate(300, style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class SplashScreenComponent implements OnInit {
    public show = false;

    constructor(private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        const hasSplashBeenShown = sessionStorage.getItem("hasSplashBeenShown");
        if (!hasSplashBeenShown) {
            this.show = true;
            this.cdr.detectChanges();
            timer(1000).pipe(untilDestroyed(this)).subscribe(() => {
                this.show = false;
                sessionStorage.setItem("hasSplashBeenShown", "true");
                this.cdr.detectChanges();
            });
        }
    }
}
