export namespace PermitChangeIssuerActions {
    export class AbortChangeIssuer {
        public static readonly type = "[PermitChangeIssuerActions] AbortChangeIssuer";
        constructor(public permitId: number) {}
    }

    export class ChangeIssuer {
        public static readonly type = "[PermitChangeIssuerActions] ChangeIssuer";
        constructor(public permitId: number) {}
    }

    export class GetStatus {
        public static readonly type = "[PermitChangeIssuerActions] GetSettings";
        constructor(public permitId: number) {}
    }

    export class SearchIssuer {
        public static readonly type = "[PermitChangeIssuerActions] SearchIssuer";
        constructor(public permitId: number, public query: string) {}
    }

    export class SelectIssuer {
        public static readonly type = "[PermitChangeIssuerActions] SelectIssuer";
        constructor(public certificateId: number) {}
    }

    export class Reset {
        public static readonly type = "[PermitChangeIssuerActions] Reset";
    }
}
