<ng-container *transloco="let t; read:'permit.changeIssuer'">
    <ng-container *ngIf="(issuerChangeInProgress$ | async) else changeIssuer">
        <div *ngIf="(issuerToBe$ | async) as issuerToBe">
            <div class="name">({{ t("pendingIssuerChangeTo", { name: issuerToBe.fullName }) }})</div>
            <div *ngIf="(canChangeIssuer$ | async) && (currentUserRoles$ | async) && userIsIssuer((currentUserRoles$ | async))" class="anchor"
                 (click)="abortChange()">{{ t("abortChangeLink") }}</div>
        </div>
    </ng-container>
    <ng-template #changeIssuer>
        <div *ngIf="(canChangeIssuer$ | async) && (currentUserRoles$ | async) && userIsIssuer((currentUserRoles$ | async))" class="anchor"
             (click)="openChangeIssuerModal()">{{ t("changeLink") }}</div>
    </ng-template>
</ng-container>
