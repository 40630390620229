import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PermitPublicStatus } from "@ha/data/basic";
import { Store } from "@ngxs/store";

import { PermitListItem } from "../../models/permit-list-item.model";
import { PermitListType } from "../../models/permit-list-type.enum";

@Component({
    selector: "pmt-permit-list",
    templateUrl: "./permit-list.component.html",
    styleUrls: ["./permit-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitListComponent {
    @Input() public listType: PermitListType;

    @Input() public permits: PermitListItem[];

    public PublicPermitStatus = PermitPublicStatus;
    constructor(private store: Store) {}

    public statusKeys(): string[] {
        const keys = Object.keys(this.PublicPermitStatus);
        return keys.slice(keys.length / 2);
    }

    public statusExists(status: PermitPublicStatus): boolean {
        return this.permits.some(_ => _.publicStatus === status);
    }
}
