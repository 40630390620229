import { ChangeDetectionStrategy, Component } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { Navigate } from "@ngxs/router-plugin";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { NotificaitonPaths } from "../../../feature-notification.module";
import { NotificationSelectors } from "../../states/notification.selectors";
import { UnreadNotificationsModalComponent } from "./unread-notifications-modal.component";

@Component({
    selector: "ntf-notification-header",
    templateUrl: "./notification-header.component.html",
    styleUrls: ["./notification-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationHeaderComponent {
    @Select(NotificationSelectors.unseenNotificationsCount)
    public unreadNotificationsCount$: Observable<number>;

    @Select(NotificationSelectors.hasUnseenNotifications)
    public hasUnseenNotifications$: Observable<boolean>;

    constructor(private store: Store, private modalService: HaModalService) {}

    public openNotificationsModal(): void {
        if (this.store.selectSnapshot(NotificationSelectors.unseenNotificationsCount) > 0) {
            this.modalService.open(UnreadNotificationsModalComponent, true);
        } else {
            this.store.dispatch(new Navigate(NotificaitonPaths.Root.toRouteComponents()));
        }
    }
}
