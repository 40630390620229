import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Translation, TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ValidationFailure, ValidationResult } from "../../models/validation.model";
import { ValidationService } from "./validation.service";

/**
 * Displays validation messages for backend validation
 * Automatically translation validation messages with some predefined rules.
 * 1. First read.validation.propertyName.errorCode is read. I.e. userEdit.validation.firstName.notEmptyValidator
 * 2. If #1 has no translation general validation messages is read from validation.errorCode. I.e. validation.notEmptyValidator
 *
 * @export
 * @class ValidationMessagesComponent
 */
@UntilDestroy()
@Component({
    selector: "validation-messages",
    templateUrl: "./validation-messages.component.html",
    styleUrls: ["./validation-messages.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessagesComponent implements OnChanges {
    /**
     * Form group
     *
     * @type {UntypedFormGroup}
     * @memberof ValidationMessagesComponent
     */
    @Input() public form: UntypedFormGroup;

    /**
     * Translation group
     * If array they will be evaluated in order
     *
     * @type {(string | string[])}
     * @memberof ValidationMessagesComponent
     */
    @Input() public read: string | string[];

    /**
     * Validation result from backend
     *
     * @type {ValidationResult}
     * @memberof ValidationMessagesComponent
     */
    @Input() public validationResult!: ValidationResult;

    /**
     * Custom supplied validation text function provided by component using validation messages
     *
     * @memberof ValidationMessagesComponent
     */
    @Input() public translationCallback: (translation: string) => string;

    public errorIndex = 0;

    private allTranslations: Translation = this.translocoService
        .getTranslation()
        .get(this.translocoService.getActiveLang());

    constructor(
        private translocoService: TranslocoService,
        private el: ElementRef<HTMLElement>,
        private validationService: ValidationService,
        private changeDetectorRefef: ChangeDetectorRef,
    ) { }

    public ngOnChanges(): void {
        if (this.validationResult && !this.validationResult.isValid) {
            this.validationResult.errors.forEach((error) => {
                const control = this.form.get(this.camelCaseAllParts(error.propertyName));
                if (control) {
                    control.setErrors({ incorrect: true });
                    control.markAsTouched();
                }
            });

            this.focusInput();
        }

        // Find and set index of currently focussed control
        this.validationService.currentControl$.pipe(untilDestroyed(this)).subscribe((control) => {
            if (control && control.invalid && this.validationResult) {
                const parentControlName = this.validationService.getControlName(control.parent);
                const controlName = this.validationService.getControlName(control);
                const longControlName = `${parentControlName}.${controlName}`;

                this.errorIndex = this.validationResult.errors
                    .map((_) => this.camelCaseAllParts(_.propertyName))
                    .findIndex((_) => _ === controlName || _ === longControlName);

                this.changeDetectorRefef.markForCheck();
            }
        });
    }

    public get canChangeError(): boolean {
        return this.validationResult.errors.length > 1;
    }

    private get propertyNames(): string[] {
        if (!this.validationResult) {
            return [];
        }

        return [...new Set(this.validationResult.errors.map((_) => _.propertyName))];
    }

    public get numberOfControlsWithError(): number {
        const length = this.propertyNames.length;
        return length;
    }

    public previousError(): void {
        this.errorIndex = this.errorIndex <= 0 ? this.numberOfControlsWithError - 1 : this.errorIndex - 1;
        this.focusInput();
    }

    public nextError(): void {
        this.errorIndex = this.errorIndex >= this.numberOfControlsWithError - 1 ? 0 : this.errorIndex + 1;
        this.focusInput();
    }

    public translateError(error: ValidationFailure): string {
        let translation: string;
        if (Array.isArray(this.read)) {
            this.read.some((r) => {
                translation = this.translateKey(r, error.errorCode, error.propertyName);
                return !!translation;
            });
        } else {
            translation = this.translateKey(this.read, error.errorCode, error.propertyName);
        }

        if (this.translationCallback) {
            translation = this.translationCallback(translation);
        }

        return translation;
    }

    private translateKey(read: string, errorCode: string, propertyName: string) {
        errorCode = this.camelCaseAllParts(errorCode);
        propertyName = this.camelCaseAllParts(propertyName);
        const shortPropertyName = propertyName?.split(".").slice(-1)[0]; // i.e. invoiceAddress.recipient > recipient

        // If the component have custom validation messages set in lang file.
        // Example { "user": { "validation": { "firstName": { "notEmptyValidator": "Förnamn får inte vara tom" }}}}
        const validationMessageKey = `${read}.validation.${propertyName ? `${propertyName}.` : ""}${errorCode}`;
        const shortValidationMessageKey = `${read}.validation.${shortPropertyName ? `${shortPropertyName}.` : ""}${errorCode}`;

        let translation: string;
        if (this.allTranslations[validationMessageKey]) {
            translation = this.translocoService.translate(validationMessageKey);
        } else if (this.allTranslations[shortValidationMessageKey]) {
            translation = this.translocoService.translate(shortValidationMessageKey);
        } else {
            // If not use global validation messages from { "validation": { ... }}

            // Translate property name first, ie. firstName > "Förnamn"
            const propertyNameTranslation = this.translatePropertyName(read, propertyName, shortPropertyName);

            // Translate full validation message using the translated property
            // Example: propertyNameTranslation = "Förnamn".
            // Translation "validation.invalid" = "{{propertyName}} har ett felaktigt värde.". Will result in "Förnamn har ett felaktigt värde."
            translation = this.translocoService.translate(`validation.${errorCode}`, {
                propertyName: propertyNameTranslation,
            });
        }

        return translation;
    }

    private translatePropertyName(read: string, propertyName: string, shortPropertyName: string): string
    {
        let propertyNameTranslation: string;

        const readKey = `${read}.${propertyName}`;
        const shortReadKey = `${read}.${shortPropertyName}`;
        const globalKey = propertyName;
        const shortGlobalKey = shortPropertyName;

        if (!propertyName) {
            propertyNameTranslation = "";
        } else if (this.allTranslations[readKey]) {
            // Try with full propertyName first
            propertyNameTranslation = this.translocoService.translate<string>(readKey);
        } else if (this.allTranslations[shortReadKey]) {
            // Then the short one
            propertyNameTranslation = this.translocoService.translate<string>(shortReadKey);
        } else if (this.allTranslations[globalKey]) {
            // Use the global key not prefixed with read and without propertyNAme
            propertyNameTranslation = this.translocoService.translate<string>(globalKey);
        } else if (this.allTranslations[shortGlobalKey]) {
            // Use the global key not prefixed with read and without propertyNAme
            propertyNameTranslation = this.translocoService.translate<string>(shortGlobalKey);
        } else {
            propertyNameTranslation = "";
        }

        return propertyNameTranslation;
    }

    private focusInput() {
        if (this.errorIndex > this.numberOfControlsWithError) {
            return;
        }

        const propertyName = this.camelCaseAllParts(this.propertyNames[this.errorIndex]);
        let invalidControl = this.el.nativeElement.parentElement.querySelector<HTMLElement>(`[formcontrolname="${propertyName}"] input`);

        // if invalidControl is not found we need to search deeper. This happens for example if you have nesled formgroups. ie. Address
        if (!invalidControl) {
            const parts = propertyName.split(".");
            const selector = `#${parts[0]} [formcontrolname="${parts[1]}"] input`;
            invalidControl = this.el.nativeElement.parentElement.querySelector<HTMLElement>(selector);
        }

        invalidControl?.focus();
    }

    private camelCaseAllParts(text: string): string {
        return text
            ?.split(".")
            .map((part) => part.toCamelCase())
            .join(".");
    }
}
