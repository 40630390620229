import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { ApplicationPaths } from "../../../constants/application-paths";
import { CurrentUserState } from "../../states/current-user.state";
import { ProfileStateActions } from "../../states/profile.actions";
import { ProfileState } from "../../states/profile.state";
import { SettingsStateActions } from "../../states/settings.actions";
import { SettingsState } from "../../states/settings.state";

@Component({
    selector: "app-mobile-personal-panel",
    templateUrl: "./mobile-personal-panel.component.html",
    styleUrls: ["./mobile-personal-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobilePersonalPanelComponent {
    @Select(ProfileState.menuOpen)
    public menuOpen$: Observable<boolean>;

    @Select(SettingsState.currentLanguage)
    public currentLanguage$: Observable<string>;

    @Select(SettingsState.uiLanguages)
    public uiLanguages$: Observable<string[]>;

    @Select(CurrentUserState.isExternal)
    public isExternal$: Observable<boolean>;

    public applicationPaths = ApplicationPaths;

    constructor(private store: Store) {}

    public toggleMenu(): void {
        this.store.dispatch(new ProfileStateActions.ToggleMenu());
    }

    public languageChanged(languageCode: string): void {
        this.store.dispatch(new SettingsStateActions.ChangeLanguage(languageCode));
        this.toggleMenu();
    }
}
