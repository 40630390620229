import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Action, Actions, ofActionSuccessful, Selector, State, StateContext } from "@ngxs/store";
import { produce } from "immer";
import { Observable } from "rxjs";
import { skip, tap } from "rxjs/operators";

import { ApplicationPaths } from "../../constants/application-paths";
import { SettingsStateActions } from "../../core/states/settings.actions";
import { KnowledgeBankActions } from "../../knowledge-bank/state/knowledge-bank.actions";
import { KnowledgeBankSearchResult } from "../models/knowledge-bank-search-result";
import { KnowledgeBankSearchService } from "../services/knowledge-bank-search.service";

export interface KnowledgeBankSearchStateModel {
    query: string;
    page: number;
    result: KnowledgeBankSearchResult;
}

@Injectable()
@State<KnowledgeBankSearchStateModel>({
    name: "knowledgeBankSearch",
    defaults: {
        query: undefined,
        page: 1,
        result: undefined,
    },
})
export class KnowledgeBankSearchState {
    constructor(private knowledgeBankSearchService: KnowledgeBankSearchService, private router: Router, private actions$: Actions) {}

    @Selector()
    public static query(state: KnowledgeBankSearchStateModel): string {
        return state.query;
    }

    @Selector()
    public static page(state: KnowledgeBankSearchStateModel): number {
        return state.page;
    }

    @Selector()
    public static result(state: KnowledgeBankSearchStateModel): KnowledgeBankSearchResult {
        return state.result;
    }

    @Selector([KnowledgeBankSearchState.result])
    public static canLoadMore(result: KnowledgeBankSearchResult): boolean {
        return result?.items?.length < result?.totalMatches;
    }

    @Selector([KnowledgeBankSearchState.result])
    public static percentLoaded(result: KnowledgeBankSearchResult): number {
        if (result) {
            return Math.round((result.items.length / result.totalMatches) * 100);
        }

        return undefined;
    }

    @Selector([KnowledgeBankSearchState.result])
    public static itemsLeft(result: KnowledgeBankSearchResult): number {
        if (result) {
            return result.totalMatches - result.items.length;
        }

        return undefined;
    }

    public ngxsOnInit(ctx: StateContext<KnowledgeBankSearchStateModel>): void {
        this.actions$.pipe(ofActionSuccessful(SettingsStateActions.ChangeLanguage), skip(1)).subscribe(() => {
            ctx.dispatch(new KnowledgeBankActions.SearchInit());
        });
    }

    @Action(KnowledgeBankActions.Search)
    public search(ctx: StateContext<KnowledgeBankSearchStateModel>, action: KnowledgeBankActions.Search): Observable<KnowledgeBankSearchResult> {
        if (!action.query) {
            return;
        }

        ctx.patchState({
            result: undefined,
            page: 1,
            query: action.query,
        });

        this.UpdateUrl(ctx);

        const state = ctx.getState();
        return this.knowledgeBankSearchService.search(state.query, state.page).pipe(tap((data) => {
            ctx.patchState({
                result: data,
            });
        }));
    }

    @Action(KnowledgeBankActions.SearchInit)
    public searchInit(ctx: StateContext<KnowledgeBankSearchStateModel>): Observable<void> {
        const params = this.router.routerState.snapshot.root.queryParams;

        if (!(params && params.q)) {
            return;
        }

        ctx.patchState({
            query: params.q as string,
        });

        const state = ctx.getState();
        if (state.query) {
            return ctx.dispatch(new KnowledgeBankActions.Search(state.query));
        }
    }

    @Action(KnowledgeBankActions.SearchClearQuery)
    public searchClearQuery(ctx: StateContext<KnowledgeBankSearchStateModel>): void {
        ctx.patchState({
            query: undefined,
        });
    }

    @Action(KnowledgeBankActions.SearchLoadMore)
    public loadMore(ctx: StateContext<KnowledgeBankSearchStateModel>): Observable<KnowledgeBankSearchResult> {
        let state = ctx.getState();

        ctx.patchState({
            page: state.page + 1,
        });

        state = ctx.getState();
        return this.knowledgeBankSearchService.search(state.query, state.page).pipe(tap((data) => {
            ctx.setState(produce(ctx.getState(), (draft) => {
                draft.result.items.push(...data.items);
            }));
        }));
    }

    private UpdateUrl(ctx: StateContext<KnowledgeBankSearchStateModel>) {
        const state = ctx.getState();

        ctx.dispatch(new Navigate([ApplicationPaths.KnowledgeBank.Root, ApplicationPaths.KnowledgeBank.Search], {
            q: state.query || undefined,
        }));
    }
}
