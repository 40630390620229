<div class="permit" *transloco="let t; read:'permit'">
    <ng-container *ngIf="currentStep$ | async as currentStep">
        <pmt-select-role *ngIf="currentStep.step === PermitStep.preparationRoleSelection" [permitId]="permitId"></pmt-select-role>

        <pmt-prepare-completed *ngIf="currentStep.step === PermitStep.preparationComplete"></pmt-prepare-completed>

        <pmt-questions *ngIf="currentStep.step === PermitStep.preparationFixedQuestions || currentStep.step === PermitStep.preparationCustomerCheckpoints"
                       [permitId]="permitId" [isPrepare]="true" class="permit__question">
        </pmt-questions>

        <pmt-is-flammable-hotwork *ngIf="currentStep.step === PermitStep.isFlammableHotWork"></pmt-is-flammable-hotwork>

        <pmt-workplace *ngIf="currentStep.step === PermitStep.workplace"></pmt-workplace>

        <pmt-emergency-location *ngIf="currentStep.step === PermitStep.emergencyLocation && (fixedTemplate$ | async) as fixedTemplate"
                                [emergencyLocationSettings]="fixedTemplate.emergencyLocation" [permitId]="permitId">
        </pmt-emergency-location>

        <pmt-work-methods *ngIf="currentStep.step === PermitStep.workMethod && (fixedTemplate$ | async) as fixedTemplate"
                          [workMethods]="fixedTemplate.workMethods" [permitId]="permitId">
        </pmt-work-methods>

        <pmt-questions *ngIf="currentStep.step === PermitStep.fixedQuestions || currentStep.step === PermitStep.customerCheckpoints" [permitId]="permitId"
                       class="permit__question">
        </pmt-questions>

        <ng-container *ngIf="workplaceDetails$ | async as premiumPermitSettings; else basePermit">
            <!-- Refactor this. We should only look at the workplace settings before the permit is
                 actually created, or when working with data that's not stored on the permit entity -->
            <ng-container *ngIf="permit$ | async as permit">
                <pmt-photo *ngIf="currentStep.step === PermitStep.preparationPhoto || currentStep.step === PermitStep.photo"
                           [photoRequirement]="permit.photoRequirement">
                </pmt-photo>
                <pmt-fire-alarm *ngIf="currentStep.step === PermitStep.fireAlarm" [comment]="premiumPermitSettings.fireAlarmComment"></pmt-fire-alarm>
                <pmt-post-work-monitoring-time *ngIf="currentStep.step === PermitStep.postWorkMonitoringTime" [default]="permit.postWorkMonitoringTime"
                                               [comment]="premiumPermitSettings.postWorkMonitoringComment">
                </pmt-post-work-monitoring-time>
                <pmt-valid-time [default]="premiumPermitSettings.permitDuration" *ngIf="currentStep.step === PermitStep.duration" [comment]="premiumPermitSettings.permitDurationComment"></pmt-valid-time>
            </ng-container>
        </ng-container>

        <ng-template #basePermit>
            <ng-container *ngIf="permit$ | async as permit">
                <pmt-photo *ngIf="currentStep.step === PermitStep.photo" [photoRequirement]="permit.photoRequirement"></pmt-photo>
                <pmt-fire-alarm *ngIf="currentStep.step === PermitStep.fireAlarm"></pmt-fire-alarm>
                <pmt-post-work-monitoring-time *ngIf="currentStep.step === PermitStep.postWorkMonitoringTime" [default]="60"></pmt-post-work-monitoring-time>
                <pmt-valid-time [default]="8" *ngIf="currentStep.step === PermitStep.duration"></pmt-valid-time>
            </ng-container>
        </ng-template>

        <pmt-team *ngIf="currentStep.step === PermitStep.team" [permitId]="permitId"></pmt-team>
        <pmt-signature *ngIf="currentStep.step === PermitStep.signing"></pmt-signature>
    </ng-container>
</div>
