import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ValidationService {
    private currentControlSubject = new Subject<AbstractControl>();
    public currentControl$ = this.currentControlSubject.asObservable();

    public controlFocused(control: AbstractControl): void {
        this.currentControlSubject.next(control);
    }

    public getControlName(control: AbstractControl): string | null {
        const group = <UntypedFormGroup>control.parent;

        if (!group) {
            return null;
        }

        let name: string;
        Object.keys(group.controls).some((key) => {
            const childControl = group.get(key);

            if (childControl !== control) {
                return false;
            } else {
                name = key;
                return true;
            }
        });

        return name;
    }
}
