import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AbortChangeIssuerCommand, ChangeIssuerCommand, ChangeIssuerDto, IssuerDto } from "../models/change-issuer-dto";

@Injectable({ providedIn: "root" })
export class PermitChangeIssuerService {
    constructor(private httpClient: HttpClient) {}

    public abortChangeIssuer(command: AbortChangeIssuerCommand) {
        return this.httpClient.post<boolean>("/api/PermitPerson/AbortChangeIssuer", command);
    }

    public changeIssuer(command: ChangeIssuerCommand) {
        return this.httpClient.post<boolean>("/api/PermitPerson/ChangeIssuer", command);
    }

    public getStatus(permitId: number): Observable<ChangeIssuerDto> {
        return this.httpClient.get<ChangeIssuerDto>("/api/PermitChangeIssuer/ChangeIssuerPrerequisites", { params: { permitId } });
    }

    public searchIssuer(permitId: number, query: string): Observable<IssuerDto> {
        const options = {
            params: {
                permitId: permitId,
                query: query
            }
        };

        return this.httpClient.get<IssuerDto>("/api/PermitChangeIssuer/SearchIssuer", options);
    }
}
