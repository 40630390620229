import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { KnowledgeBankSearchResultItemExtraContentItem } from "../../models/knowledge-bank-search-result";

@Component({
    selector: "knowledge-bank-search-results-extra-content",
    templateUrl: "./knowledge-bank-search-results-extra-content.component.html",
    styleUrls: ["./knowledge-bank-search-results-extra-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankSearchResultsExtraContentComponent {
    @Input() public data: KnowledgeBankSearchResultItemExtraContentItem;

    public iconClass(c: string) {
        const parts = this.data.url.split(".");
        const ext = parts[parts.length - 1];
        return `${c}--${ext}`;
    }
}
