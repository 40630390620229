export namespace PwaActions {
    export class InstallDismiss {
        public static readonly type = "[Pwa] InstallDismiss";
    }

    export class InstallGoTo {
        public static readonly type = "[Pwa] InstallGoTo";
    }

    export class SetUpdateAvailable {
        public static readonly type = "[Pwa] UpdateAvailable";
    }

    export class OpenModalIfUpdateAvailable {
        public static readonly type = "[Pwa] OpenModalIfUpdateAvailable";
    }
}
