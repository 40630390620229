import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngxs/store";

import { PermitWorkPlaceViewEnum } from "../../../models/permit-work-place-view.enum";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";

@Component({
    selector: "pmt-workplace-base",
    templateUrl: "permit-workplace-base.component.html",
    styleUrls: ["permit-workplace-base.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitWorkplaceBaseComponent {
    public form = this.fb.group({
        workplace: ["", Validators.required]
    });

    constructor(private fb: UntypedFormBuilder, private store: Store) { }

    public createNew() {
        if (!this.form.valid) {
            return;
        }

        this.store.dispatch(new PermitWorkplaceActions.CreatePermit(this.form.get("workplace").value as string));
    }

    public cancel() {
        this.store.dispatch(new PermitWorkplaceActions.ChangeView(PermitWorkPlaceViewEnum.Start));
    }
}
