import { Injectable } from "@angular/core";
import { Action, NgxsOnInit, State, StateContext } from "@ngxs/store";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";

import { Account } from "../models/current-account.model";
import { CurrentAccountService } from "../services/current-account.service";
import { AuthActions } from "./auth.actions";

export interface AuthStateModel {
    isAuthenticated: boolean;
    currentAccount?: Account;
}

@Injectable() @State<AuthStateModel>({ name: "auth" })
export class AuthState implements NgxsOnInit {
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private currentAccountService: CurrentAccountService,
    ) {}

    public ngxsOnInit(ctx: StateContext<AuthStateModel>): void {
        this.oidcSecurityService.isAuthenticated$.pipe(filter((_) => _.isAuthenticated), take(1)).subscribe(() => {
            ctx.dispatch(new AuthActions.GetCurrentAccount());

            interface payloadFromIdToken {
                sub: string;
            }

            this.oidcSecurityService.getPayloadFromIdToken().subscribe((originalPayload: payloadFromIdToken) => {
                const sub = originalPayload.sub;
                this.oidcSecurityService.forceRefreshSession().subscribe(() => {
                    this.oidcSecurityService.getPayloadFromIdToken().subscribe((newPayload: payloadFromIdToken) => {
                        const newSub = newPayload.sub;

                        if (!!newSub && !!sub && sub !== newSub) {
                            location.reload();
                        }
                    });
                });
            });
        });

        this.oidcSecurityService.isAuthenticated$.pipe(map((_) => _.isAuthenticated)).subscribe((isAuthenticated) => {
            ctx.patchState({
                isAuthenticated: isAuthenticated,
            });
        });
    }

    @Action(AuthActions.SignOut)
    public logout(): void {
        this.oidcSecurityService.logoff().subscribe();
    }

    @Action(AuthActions.GetCurrentAccount)
    public getCurrentAccount(ctx: StateContext<AuthStateModel>): Observable<Account> {
        return this.currentAccountService.get().pipe(tap((currentAccount) => {
            if (currentAccount) {
                ctx.patchState({
                    currentAccount: currentAccount,
                });
            } else {
                ctx.setState({ currentAccount: undefined, isAuthenticated: false });
            }
        }));
    }
}
