import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HaModalService } from "@ha/ui/common";

import { CommentDto } from "../../../models/permit-comments-dto";
import {
    PermitCommentRemoveModalComponent,
    PermitCommentRemoveModalComponentData,
} from "../permit-comment-remove-modal/permit-comment-remove-modal.component";

@Component({
    selector: "pmt-permit-comments-comment",
    templateUrl: "permit-comments-comment.component.html",
    styleUrls: ["permit-comments-comment.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitCommentsCommentComponent {
    @Input() public comment: CommentDto;

    @Input() public canDelete: boolean;

    constructor(private haModalService: HaModalService) {}

    public remove() {
        this.haModalService.open(PermitCommentRemoveModalComponent, true, <PermitCommentRemoveModalComponentData>{ comment: this.comment });
    }
}
