import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthSelectors } from "@ha/feature/auth";
import { HaModalService, SlideAnimations } from "@ha/ui/common";
import { Breadcrumb, BreadcrumbSelectors } from "@ha/ui/navigation";
import { PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CurrentUserState } from "../../states/current-user.state";
import { ProfileStateActions } from "../../states/profile.actions";
import { ProfileState } from "../../states/profile.state";
import { SettingsStateActions } from "../../states/settings.actions";
import { SettingsState } from "../../states/settings.state";

@Component({
    selector: "mobile-header",
    templateUrl: "./mobile-header.component.html",
    styleUrls: ["./mobile-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [SlideAnimations.slideDownTrigger]
})
export class MobileHeaderComponent {
    @Select(AuthSelectors.isAuthenticated)
    public isAuthenticated$: Observable<boolean>;

    @Select(BreadcrumbSelectors.mobileBreadcrumb)
    public breadcrumb$: Observable<Breadcrumb>;

    @Select(CurrentUserState.isImpersonated)
    public currentUserIsImpersonated$: Observable<boolean>;

    @Select(ProfileState.menuOpen)
    public menuOpen$: Observable<boolean>;

    @Select(CurrentUserState.fullName)
    public currentUserFullName$: Observable<string>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    @Select(SettingsState.currentLanguage)
    public currentLanguage$: Observable<string>;

    @Select(SettingsState.uiLanguages)
    public uiLanguages$: Observable<string[]>;

    constructor(private store: Store) {}

    public toggleMenu(): void {
        this.store.dispatch(new ProfileStateActions.ToggleMenu());
    }

    public languageChanged(languageCode: string): void {
        this.store.dispatch(new SettingsStateActions.ChangeLanguage(languageCode));
    }
}
