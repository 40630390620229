<ng-container *transloco="let t; read:'feature.notification'">
    <div class="modal">
        <div class="modal__content">
            <h2 class="modal__content__heading">{{ t("whatToDo") }}</h2>

            <div class="modal__content__mark-as-read" (click)="markAllAsRead()">{{ t("markAsRead") }}</div>
            <div class="modal__content__remove-all" (click)="removeAll()">{{ t("removeAll") }}</div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>
        </div>
    </div>
</ng-container>
