<div class="show-menu-button" [class.active]="knowledgebankMenuVisible$ | async" @fadeInOutTrigger (click)="toggleKnowledgebankMenu()"></div>
<ha-dimmer class="dimmer" *ngIf="knowledgebankMenuVisible$ | async">
</ha-dimmer>
<ng-container *transloco="let t; read:'knowledgeBank'">
    <ng-container *ngIf="knowledgebankMenuVisible$ | async">
        <div class="hamburger-menu" *ngIf="menu$ | async as menu" @fadeInOutTrigger>
            <a class="hamburger-menu__heading" [routerLink]="menu.url">{{t("heading")}}</a>
            <ul class="hamburger-menu__list">
                <knowledge-bank-menu-item class="hamburger-menu__list__item" *ngFor="let child of menu.children" [item]="child"></knowledge-bank-menu-item>
            </ul>
            <div class="hamburger-menu__footer">
                <knowledge-bank-search-box class="hamburger-menu__footer__search"></knowledge-bank-search-box>
                <div class="hamburger-menu__footer__button" (click)="toggleKnowledgebankMenu()"></div>
            </div>
        </div>
    </ng-container>
</ng-container>
