import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { QuestionDto } from "../models/question.model";

@Injectable({ providedIn: "root" })
export class PermitQuestionService {
    constructor(private httpClient: HttpClient) {}

    public getCurrentQuestion(permitId: number, currentQuestionId: number): Observable<QuestionDto> {
        if (currentQuestionId) {
            const options = {
                params: {
                    permitId: permitId,
                    currentQuestionId: currentQuestionId
                },
            };

            return this.httpClient.get<QuestionDto>("/api/PermitQuestion/GetCurrentQuestion", options);
        } else {
            const options = {
                params: {
                    permitId: permitId,
                },
            };

            return this.httpClient.get<QuestionDto>("/api/PermitQuestion/GetCurrentQuestion", options);
        }
    }

    public getNextQuestion(permitId: number, currentQuestionId: number): Observable<QuestionDto> {
        if (currentQuestionId) {
            const options = {
                params: {
                    permitId: permitId,
                    currentQuestionId: currentQuestionId
                },
            };

            return this.httpClient.get<QuestionDto>("/api/PermitQuestion/GetNextQuestion", options);
        } else {
            const options = {
                params: {
                    permitId: permitId,
                },
            };

            return this.httpClient.get<QuestionDto>("/api/PermitQuestion/GetNextQuestion", options);
        }
    }

    public getPreviousQuestion(permitId: number, currentQuestionId: number): Observable<QuestionDto> {
        const options = {
            params: {
                permitId: permitId,
                currentQuestionId: currentQuestionId
            },
        };

        return this.httpClient.get<QuestionDto>("/api/PermitQuestion/GetPreviousQuestion", options);
    }
}
