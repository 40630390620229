import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";


export interface PermitTeamContactModalComponentData {
    name: string;
    email: string;
    phone: string;
}

@Component({
    templateUrl: "./permit-team-contact-modal.component.html",
    styleUrls: ["./permit-team-contact-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitTeamContactModalComponent extends BaseModalComponent {
    public data: PermitTeamContactModalComponentData;
}

