import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MenuItem } from "@ha/feature/episerver";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "knowledge-bank-menu-item",
    templateUrl: "./knowledge-bank-menu-item.component.html",
    styleUrls: ["./knowledge-bank-menu-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankMenuItemComponent implements OnInit {
    @Input()
    public item: MenuItem;
    @Input()
    public level = 1;

    public expanded: boolean;
    public isActive: boolean;

    constructor(private router: Router, private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.update();
            }
        });

        this.update();
    }

    public childIsActive(url: string): boolean {
        return this.router.url !== url && this.router.url.includes(url);
    }

    public toggleExpand(): void {
        this.expanded = !this.expanded;
    }

    private update() {
        this.expanded = this.router.isActive(this.item.url, false);
        this.isActive = this.router.isActive(this.item.url, true);
        this.cdr.detectChanges();
    }
}
