import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PwaActions, PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

@Component({
    selector: "install-modal",
    templateUrl: "./install-modal.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallModalComponent {
    @Select(PwaState.showInstallModal)
    public showInstallModal$: Observable<boolean>;

    constructor(private store: Store) {}

    public cancel(): void {
        this.store.dispatch(new PwaActions.InstallDismiss());
    }

    public ok(): void {
        this.store.dispatch(new PwaActions.InstallGoTo());
    }
}
