import { NotificationStatus } from "../models/notification-status.enum";

export namespace NotificationActions {
    export class GetAllNotifications {
        public static readonly type = "[NotificationActions] GetAllNotifications";
    }

    export class GetUnreadNotifications {
        public static readonly type = "[NotificationActions] GetUnreadNotifications";
    }

    export class GetUnseenNotificationCount {
        public static readonly type = "[NotificationActions] GetUnseenNotificationCount";
    }

    export class GetCurrentNotification {
        public static readonly type = "[NotificationActions] GetCurrentNotification";
        constructor(public notificationId: number) {}
    }

    export class SetNotificationStatus {
        public static readonly type = "[NotificationActions] SetNotificationStatus";
        constructor(public notificationId: number, public status: NotificationStatus) {}
    }

    export class SetStatusForAll {
        public static readonly type = "[NotificationActions] SetStatusForAll";
        constructor(public status: NotificationStatus) {}
    }

    export class SetStatusForAllUnread {
        public static readonly type = "[NotificationActions] SetStatusForAllUnread";
        constructor(public status: NotificationStatus) {}
    }

    export class RemoveNotification {
        public static readonly type = "[NotificationActions] RemoveNotification";
        constructor(public notificationId: number) {}
    }
}
