<div class="root" *transloco="let t; read:'permit.details.endPermit'">
    <ng-container *ngIf="(currentStep$ | async).step !== PermitStep.ended; else finished">
        <!-- Current user is Issuer -->
        <ng-container *ngIf="currentUserIsIssuer$ | async">
            <div class="root__info" *ngIf="!(basicDetails$ | async).endPostFireWatchSignatureRequired">
                {{ t("info") }}
            </div>

            <div class="root__reminder" *ngIf="summary$ | async as summary">
                <div *ngIf="summary.operationManager" class="root__reminder__text" [innerHTML]="t('contact', { operationManager: summary.operationManager })">
                </div>
            </div>

            <!-- EndPostFireWatchSignatureRequired === FALSE -->
            <ng-container *ngIf="!((basicDetails$ | async).endPostFireWatchSignatureRequired)">
                <div class="root__sign-button" (click)="openEndSignatureModal()">{{ t("signButton") }}</div>
            </ng-container>
        </ng-container>

        <!-- EndPostFireWatchSignatureRequired === TRUE -->
        <ng-container *ngIf="((basicDetails$ | async).endPostFireWatchSignatureRequired)">
            <!-- Current user is PostFireWatcher -->
            <ng-container *ngIf="currentUserIsPostFireWatcher$ | async">
                <!-- Button to self sign as PostFireWatcher -->
                <div class="root__sign-button" (click)="openPostFireWatchSignatureModal()" [class.root__sign-button--signed]="postFireWatcherHasSigned$ | async">
                    {{ t("postFireWatchSignatureButton") }}
                </div>
            </ng-container>

            <!-- Current user is Issuer -->
            <ng-container *ngIf="currentUserIsIssuer$ | async">
                <div class="root__signatures" *ngIf="team$ | async as team">
                    <h3 class="root__signatures__heading" [innerHTML]="t('postFireWatchSignatureButton')"></h3>

                    <div class="root__signatures__info">
                        {{ t("postFireWatchSignatureInfo") }}
                    </div>

                    <div class="root__signatures__person-list">
                        <div *ngFor="let teamMember of team.postFireWatchers" class="root__signatures__person-list__item"
                             [class.root__signatures__person-list__item--signed]="teamMember?.endSignature"
                             (click)="openPostFireWatchSignatureModal(teamMember)">
                            <div class="root__signatures__person-list__item__text">{{teamMember.firstName}} {{teamMember.lastName}}</div>
                            <div class="root__signatures__person-list__item__sign"></div>
                        </div>
                    </div>
                </div>

                <div class="root__sign-button" [class.disabled]="!((basicDetails$ | async).hasPostFireWatchEndSignature)" (click)="openEndSignatureModal()">
                    {{ t("signButton") }}
                </div>
            </ng-container>

        </ng-container>
    </ng-container>

    <ng-template #finished>
        <ng-container *ngIf="summary$ | async as summary">
            <div class="root__content">
                <div class="root__content__heading">{{ t("closedByAndDate") }}</div>
                <div class="root__content__text">
                    <div *ngIf="!summary.closedBySystem && permitIssuer$ | async as issuer">
                        {{ issuer.firstName + ' ' + issuer.lastName }}
                    </div>
                    <div *ngIf="summary.closedBySystem">{{ t("closedBySystem") }}</div>
                    {{ summary.endDate | haDate: "dateTimeStandard" }}
                </div>
            </div>
        </ng-container>
    </ng-template>
</div>
