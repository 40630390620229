import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "puff",
    templateUrl: "./puff.component.html",
    styleUrls: ["puff.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PuffComponent {
    @Input() public url: string[] | string | number | null | undefined;
    @Input() public icon: string;
    @Input() public heading: string;
    @Input() public text: string;
    @Input() public layout: "swag" | "default" = "default";

    public get urlFixed(): string | string[] {
        if (this.url instanceof Array) {
            return this.url;
        }
        return this.url.toString();
    }
}
