<ng-container *transloco="let t; read: 'knowledgeBank.search.results'">
    <div class="searching" *ngIf="isSearching$ | async">
        {{ t("searching") }}
    </div>
    <div class="result" *ngIf="(isSearching$ | async) === false">
        <ng-container *ngIf="result$ | async as result">
            <div class="result__summary" [innerHTML]="t('summary', { hits: result.items.length, total: result.totalMatches, query: result.query })">
            </div>

            <hr class="result__divider">

            <div class="result__items">
                <knowledge-bank-search-results-item class="result__items__item" [data]="item" *ngFor="let item of result.items"
                                                    (clickTrack)="clickTrack(result, item)">
                </knowledge-bank-search-results-item>
            </div>

            <button class="result__load-more" [class.result__load-more--disabled]="(canLoadMore$ | async) === false" (click)="loadMore()">
                {{ getButtonText(itemsLeft$ | async)}}
                <ng-container *ngIf="percentLoaded$ | async as percent">
                    <div class="result__load-more__progress-bar"></div>
                    <div class="result__load-more__progress-fill" [style]="'width: ' + percent + '%'"></div>
                </ng-container>
            </button>
        </ng-container>
    </div>


</ng-container>
