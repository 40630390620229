<ng-container *transloco="let t; read:'permit.details.comments.createModal'">
    <div class="modal">
        <div class="modal__content">
            <label>{{ t("comment") }}</label>
            <textarea #comment class="modal__content__comment" [(ngModel)]="text" rows="5"></textarea>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__create" type="button" (click)="createComment()">{{ t("create") }}</button>
        </div>
    </div>
</ng-container>
