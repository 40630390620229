import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { Select, Store } from "@ngxs/store";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable } from "rxjs";

import { PermitTeamActions } from "../../states/permit-team.action";
import { PermitTeamSelectors } from "../../states/permit-team.selectors";
import { PermitTeamSearch } from "../../states/permit-team.state";
import { ScanCardModalComponent, ScanCardModalComponentData } from "../scan-card-modal/scan-card-modal.component";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";


@Component({
    selector: "pmt-find-person",
    templateUrl: "find-person.component.html",
    styleUrls: ["find-person.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FindPersonComponent implements OnInit {
    @Input()
    public allowNewPersons: boolean;

    @Input()
    public permitId: number;

    @Input()
    public role: PersonRole;

    @Select(PermitTeamSelectors.searchResult)
    public searchResult$: Observable<PermitTeamSearch>;

    public get isDesktop(): boolean {
        return this.deviceService.isDesktop();
    }

    public query$: Observable<string>;

    public PersonRole = PersonRole;

    private isPremium: boolean;

    constructor(private store: Store, private modalService: HaModalService, private deviceService: DeviceDetectorService) {
    }

    public ngOnInit() {
        this.store.dispatch(new PermitTeamActions.ResetFind());

        this.isPremium = this.store.selectSnapshot(PermitCreateSelectors.isPremium);
    }

    public personStaged(certificateId: number): boolean {
        const stagedPersons = this.store.selectSnapshot(PermitTeamSelectors.stagedPersons);
        if (stagedPersons) {
            return stagedPersons.find(_ => _.certificateId == certificateId) != undefined;
        }

        return false;
    }

    public search(query: string) {
        this.store.dispatch(new PermitTeamActions.FindPerson({ query: query, role: this.role, isPremium: this.isPremium }));
    }

    public openScanCardModal() {
        this.modalService.open(ScanCardModalComponent, true, <ScanCardModalComponentData>{ permitId: this.permitId, role: this.role, isPremium: this.isPremium });
    }
}
