import { PersonRole } from "@ha/data/basic";

export namespace PreparePermitActions {
    export class GetWorkplaceContainerForPreparation {
        public static readonly type = "[PreparePermitActions] GetWorkplaceContainerForPreparation";
        constructor(public prefix: string, public workCode: string) { }
    }

    export class GetFixedTemplate {
        public static readonly type = "[PreparePermitActions] GetFixedTemplate";
    }

    export class CreatePreparedPermit {
        public static readonly type = "[PreparePermitActions] CreatePreparedPermit";
    }

    export class ResetState {
        public static readonly type = "[PreparePermitActions] ResetState";
    }

    export class SelectRole {
        public static readonly type = "[PreparePermitActions] SelectRole";
        constructor(public permitId: number, public roles: PersonRole[]) { }
    }

    export class ToggleSelectedWorkplace {
        public static readonly type = "[PreparePermitActions] ToggleSelectedWorkplace";
        constructor(public workplaceId: number) { }
    }
}
