import { Selector } from "@ngxs/store";

import { PrefixWorkCodeResponse } from "../models/prefix-work-code-response.model";
import { PreparePermitState, PreparePermitStateModel } from "./prepare-permit.state";

export class PreparePermitSelectors {
    @Selector([PreparePermitState])
    public static responseDetails(state: PreparePermitStateModel): PrefixWorkCodeResponse | undefined {
        return state.response;
    }

    @Selector([PreparePermitState])
    public static validCode(state: PreparePermitStateModel): boolean {
        if (state.response) {
            return state.response.validWorkCode;
        }

        return false;
    }

    @Selector([PreparePermitState])
    public static companyName(state: PreparePermitStateModel): string {
        return state?.response?.companyName;
    }

    @Selector([PreparePermitState])
    public static selectedWorkplaceId(state: PreparePermitStateModel): number | undefined {
        return state?.selectedWorkplaceId;
    }
}
