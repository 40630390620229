import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { IContent, Property } from "./../models/episerver-base-types.model";
import { MenuItem } from "./../models/menu-item.model";

type objectType = Record<string, unknown>;

function expandAndModifyContentData(obj: objectType, stack = "") {
    for (const property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            // Special treatment for PropertyLinkCollections
            if (obj["propertyDataType"] === "PropertyLinkCollection") {
                obj["expandedValue"] = undefined;
                continue;
            }

            if (typeof obj[property] === "object") {
                expandAndModifyContentData(obj[property] as objectType, stack + "." + property);
            } else {
                if (obj["expandedValue"]) {
                    obj["value"] = obj["expandedValue"];
                    delete obj["expandedValue"];
                }
            }
        }
    }

    if (obj && Object.prototype.hasOwnProperty.call(obj, "heading")) {
        const content = obj as { name: string; heading: Property; };
        if (!content.heading.value) {
            content.heading.value = content.name;
        }
    }
}

@Injectable({ providedIn: "root" })
export class EpiserverService {
    constructor(private httpClient: HttpClient) {}

    public getContentByUrl<T extends IContent>(
        contentUrl: string,
        langUrl: string,
        angularModuleRoute: string,
    ): Observable<T> {
        const options = {
            params: {
                contentUrl: contentUrl,
                expand: "*",
                rootUrl: langUrl,
                angularModuleRoute: angularModuleRoute,
            },
        };

        return this.httpClient.get<Array<T>>("/api/cms/episerver/v2.0/content/", options).pipe(
            map((data) => data[0]),
            tap((data) => expandAndModifyContentData(data as objectType, "")),
        );
    }

    public getContentById<T extends IContent>(
        contentReference: number,
        langUrl: string,
        angularModuleRoute: string,
    ): Observable<T> {
        const options = {
            params: {
                expand: "*",
                rootUrl: langUrl,
                angularModuleRoute: angularModuleRoute,
            },
        };

        const url = `/api/cms/episerver/v2.0/content/${contentReference}`;

        return this.httpClient.get<T>(url, options).pipe(
            tap((data) => expandAndModifyContentData(data as objectType, "")),
        );
    }

    public getMenuSide(startPageUrl: string, angularModuleRoute: string): Observable<MenuItem> {
        const options = {
            params: {
                startPageUrl,
                angularModuleRoute,
            },
        };

        return this.httpClient.get<MenuItem>("/api/cms/angular/menu/side/", options);
    }
}
