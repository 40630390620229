import { ComponentFactoryResolver, ComponentRef, Directive, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageStateSelectors } from "@ha/ui/common";
import { Store } from "@ngxs/store";
import { Subscription } from "rxjs";
import { filter, map, skip } from "rxjs/operators";

import { GetComponentTypeByName } from "../../functions/register-epi-component";
import { DynamicEpiPropertyBaseComponent } from "../../models/dynamic-epi-property-base.component";
import { EpiserverAngularModuleConfig } from "./../../models/episerver-angular-module-config";
import { IContent } from "./../../models/episerver-base-types.model";

/**
 * Base class for Episerver module root components
 * The dirived class should have a view containing
 * <ng-template #container></ng-template>
 * @export
 * @abstract
 * @class EpiserverPageComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EpiserverPageComponent implements OnInit, OnDestroy {
    @ViewChild("container", { read: ViewContainerRef, static: true })
    public container: ViewContainerRef;

    private componentRef: ComponentRef<unknown>;
    private routerSubscription: Subscription;
    private changeLanguageSubscription: Subscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private store: Store,
    ) {}

    public ngOnInit(): void {
        this.routerSubscription = this.activatedRoute.data.pipe(
            filter((_) => !!_),
            map((_: { content: IContent; }) => _.content),
            filter((_) => !!_),
        ).subscribe((content) => {
            this.createComponent(content);
        });

        this.changeLanguageSubscription = this.store.select(LanguageStateSelectors.currentLanguage).pipe(skip(1))
            .subscribe((language) => {
                this.changeLanguage(language);
            });
    }

    private changeLanguage(language: string) {
        const content = this.activatedRoute.snapshot.data["content"] as IContent;
        if (!content) {
            return;
        }

        this.navigateToLanguge(language, content);
    }

    private createComponent(content: IContent) {
        // Should not be needed. It was originally here cus route runGuardsAndResolvers: "always" was set. Causing this to run on every fragment change
        // if (content?.contentLink?.id === this.currentContent?.contentLink.id) {
        //     return;
        // }

        const currentLanguage = this.store.selectSnapshot(LanguageStateSelectors.currentLanguage);
        if (content.language.name !== currentLanguage) {
            this.navigateToLanguge(currentLanguage, content);
        }

        const componentType = this.getComponentType(content);

        this.componentRef?.destroy();
        const factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        this.componentRef = this.container.createComponent(factory);

        const instance = <DynamicEpiPropertyBaseComponent> this.componentRef.instance;
        instance.data = content;
        this.componentRef.changeDetectorRef.markForCheck();
    }

    private navigateToLanguge(language: string, content: IContent) {
        const backendConfig = this.store.selectSnapshot(this.getConfigStateSelector());
        const pageUrlInOtherLanguage = content.existingLanguages.find((_) => _.name === language)?.link;
        if (pageUrlInOtherLanguage) {
            const langBaseUrl = backendConfig.rootUrls.find((_) => _.lang === language).url;
            const newUrl = `${backendConfig.moduleRootPath}/${pageUrlInOtherLanguage.replace(langBaseUrl, "")}`;
            void this.router.navigateByUrl(newUrl);
        } else {
            // If page not found in other language go to module root
            void this.router.navigate([backendConfig.moduleRootPath]);
        }
    }

    private getComponentType(data: IContent) {
        let type: Type<DynamicEpiPropertyBaseComponent>;

        if (data.contentType) {
            const typeName = data.contentType[data.contentType.length - 1];
            type = GetComponentTypeByName(typeName);

            if (type === undefined) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                console.error(`[EpiserverPageComponent] Component type not found. TypeName: ${typeName} Data: ${data}`);
            }
        }

        return type;
    }

    protected abstract getConfigStateSelector(): (state: never, ...states: never[]) => EpiserverAngularModuleConfig;

    public ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }

        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }

        if (this.changeLanguageSubscription) {
            this.changeLanguageSubscription.unsubscribe();
        }
    }
}
