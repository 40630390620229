import { ChangeDetectionStrategy, Component } from "@angular/core";
import { EpiserverAngularModuleConfig, EpiserverPageComponent } from "@ha/feature/episerver";

import { KnowledgeBankState, KnowledgeBankStateModel } from "../../state/knowledge-bank.state";

@Component({
    templateUrl: "knowledge-bank-root.component.html",
    styleUrls: ["knowledge-bank-root.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankRootComponent extends EpiserverPageComponent {
    protected getConfigStateSelector(): (state: KnowledgeBankStateModel) => EpiserverAngularModuleConfig {
        return KnowledgeBankState.config;
    }
}
