import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, Store } from "@ngxs/store";

import { PermitDetailsActions } from "../../../states/permit-details.action";

export interface PermitCommentCreateComponentData {
    permitId: number;
}

@UntilDestroy()
@Component({
    templateUrl: "permit-comment-create-modal.component.html",
    styleUrls: ["permit-comment-create-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitCommentCreateComponent extends BaseModalComponent implements OnInit, AfterViewInit {
    public data: PermitCommentCreateComponentData;

    public text: string;

    @ViewChild("comment") private commentTextArea: ElementRef<HTMLElement>;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(untilDestroyed(this), ofActionCompleted(PermitDetailsActions.CreateComment)).subscribe(() => {
            this.closeModal();
        });
    }

    public ngAfterViewInit(): void {
        this.commentTextArea.nativeElement.focus();
    }

    public createComment() {
        this.store.dispatch(new PermitDetailsActions.CreateComment(this.data.permitId, this.text));
    }

    public close() {
        this.closeModal();
    }
}
