import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { AccordionItemBlock } from "./accordion-item-block";

@Component({
    selector: "accordion-item-block",
    templateUrl: "./accordion-item-block.component.html",
    styleUrls: ["./accordion-item-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemBlockComponent {
    @Input() public data: AccordionItemBlock;

    public isOpen = false;
}
