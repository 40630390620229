import { ErrorHandler, Injectable } from "@angular/core";

import { ApplicationInsightsMonitoringService } from "./application-insights-monitoring.service";

@Injectable()
export class ApplicationInsightsErrorHandlerService extends ErrorHandler {

    constructor(private applicationInsightsMonitoringService: ApplicationInsightsMonitoringService) {
        super();
    }

    public handleError(error: Error): void {
        console.error(error);
        this.applicationInsightsMonitoringService.logException(error);
    }
}
