import { Injectable } from "@angular/core";
import { BaseResult } from "@ha/data/basic";
import { BaseValidationResult } from "@ha/ui/forms";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { produce } from "immer";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { PermitDetailsActions } from "../../permit-details/states/permit-details.action";
import { PermitStepActions } from "../../permit/states/permit-step.action";
import {
    DisabledFireAlarmCommand,
    EmergencyLocationCommand,
    PostWorkMonitoringTimeCommand,
    StartPermitCommand,
    ExtendPermitCommand,
    WorkMethodsCommand,
} from "../models/create-permit-commands";
import { CustomerPermitTemplate } from "../models/customer-permit-template.model";
import { FixedTemplate } from "../models/fixed-template.model";
import { PermitAnswers } from "../models/permit-answers";
import { Permit } from "../models/permit.model";
import { WorkplaceDetails } from "../models/workplace-details.model";
import { PermitCreateService } from "../services/permit-create.service";
import { PermitCreateActions } from "./permit-create.actions";
import { PermitCreateSelectors } from "./permit-create.selectors";
import { PermitPhotoActions } from "./permit-photo.actions";

export interface PermitCreateStateModel {
    fixedTemplate: FixedTemplate | undefined;
    permit: Permit;
    customerPermitTemplate: CustomerPermitTemplate | undefined;
    permitQuestionAnswer: PermitAnswers;
    workplaceDetails: WorkplaceDetails;
    emergencyLocationForm: {
        model: {
            emergencyLocation: string;
        };
        dirty: boolean;
        status: string;
        errors: unknown;
    };
    setEmergencyLocationValidationResult: BaseValidationResult | undefined;
    isDemo: boolean;
}

const permitQuestionAnswerDefaults = <PermitAnswers>{
    disabledFireAlarm: "",
    permitPhotos: [],
    postWorkMonitoringTime: 60,
    workMethodManual: "",
    selectedWorkMethods: [],
    confirmedleWorkMethodAlerts: [],
    isFlammableHotWork: undefined,
};

const defaults: PermitCreateStateModel = {
    fixedTemplate: undefined,
    workplaceDetails: undefined,
    permit: undefined,
    customerPermitTemplate: undefined,
    permitQuestionAnswer: permitQuestionAnswerDefaults,
    emergencyLocationForm: {
        model: {
            emergencyLocation: "",
        },
        dirty: false,
        status: "",
        errors: {},
    },
    setEmergencyLocationValidationResult: undefined,
    isDemo: false
};

@Injectable()
@State<PermitCreateStateModel>({
    name: "permitCreate",
    defaults: defaults,
})
export class PermitCreateState {
    constructor(private store: Store, private permitCreateService: PermitCreateService) { }

    @Action(PermitCreateActions.GetActiveFixedTemplate)
    public getActiveFixedTemplate(ctx: StateContext<PermitCreateStateModel>): Observable<FixedTemplate> {
        return this.permitCreateService.getActiveFixedTemplate().pipe(tap((data) => {
            data.workMethods.forEach(workMethod => {
                workMethod.isSelected$ = this.store.select(PermitCreateSelectors.selectedWorkMethods)
                    .pipe(map(x => x?.some(y => y?.id === workMethod.id)));
            });


            ctx.patchState({ fixedTemplate: data });
        }));
    }

    @Action(PermitCreateActions.GetPermit)
    public getPermit(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.GetPermit): Observable<Permit> {
        return this.permitCreateService.getPermit(action.permitId).pipe(tap((permit) => {
            if (permit) {
                ctx.setState(produce(ctx.getState(), draft => {
                    draft.permit = permit;

                    draft.emergencyLocationForm.model.emergencyLocation = permit.emergencyLocation;

                    // draft.permitQuestionAnswer.selectedWorkMethods = draft.fixedTemplate.workMethods.filter(_ => permit.workMethodIds.includes(_.id));
                    // draft.permitQuestionAnswer.workMethodManual = permit.workMethodManualInput;

                    if (permit.workplaceId) {
                        this.store.dispatch(new PermitCreateActions.GetWorkplaceDetails(permit.workplaceId, permit.subscriptionId));
                    }
                }));
            } else {
                ctx.setState(defaults);
            }
        }));
    }

    @Action(PermitCreateActions.GetWorkplaceDetails)
    public getWorkplaceDetails(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.GetWorkplaceDetails): Observable<WorkplaceDetails> {
        return this.permitCreateService.getWorkplaceDetails(action.workplaceId).pipe(tap((data) => {
            if (data) {
                ctx.setState(produce(ctx.getState(), draft => {
                    draft.workplaceDetails = data;
                    // picked up from permit earlier: draft.emergencyLocationForm.model.emergencyLocation = data.emergencyLocation;
                }));
            }
        }));
    }

    @Action(PermitCreateActions.ResetToDefault)
    public resetToDefault(ctx: StateContext<PermitCreateStateModel>): void {
        ctx.setState(defaults);
    }

    @Action(PermitCreateActions.SetManualWorkMethod)
    public setManualWorkMethod(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SetManualWorkMethod): void {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.permitQuestionAnswer.workMethodManual = action.text;
        }));
    }

    @Action(PermitCreateActions.ToggleWorkMethod)
    public toggleWorkMethod(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.ToggleWorkMethod): void {
        ctx.setState(produce(ctx.getState(), draft => {
            const index = draft.permitQuestionAnswer?.selectedWorkMethods?.findIndex(_ => _.id === action.workMethod.id);
            if (index !== -1) {
                draft.permitQuestionAnswer?.selectedWorkMethods.splice(index, 1);

                const confirmedIndex = draft.permitQuestionAnswer?.confirmedleWorkMethodAlerts?.findIndex(_ => _.id === action.workMethod.id);
                if (confirmedIndex !== -1) {
                    draft.permitQuestionAnswer?.confirmedleWorkMethodAlerts.splice(confirmedIndex, 1);
                }
            } else {
                draft.permitQuestionAnswer?.selectedWorkMethods.push(action.workMethod);
            }
        }));
    }

    @Action(PermitCreateActions.ToggleWorkMethodAlertConfirmed)
    public toggleWorkMethodAlertConfirmed(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.ToggleWorkMethodAlertConfirmed): void {
        ctx.setState(produce(ctx.getState(), draft => {
            const index = draft.permitQuestionAnswer?.confirmedleWorkMethodAlerts?.findIndex(_ => _.id === action.workMethod.id);
            if (index !== -1) {
                draft.permitQuestionAnswer?.confirmedleWorkMethodAlerts.splice(index, 1);
            } else {
                draft.permitQuestionAnswer?.confirmedleWorkMethodAlerts.push(action.workMethod);
            }
        }));
    }

    @Action(PermitCreateActions.SaveWorkMethods)
    public saveWorkMethods(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SaveWorkMethods): Observable<BaseValidationResult> {
        const currentState = ctx.getState();

        const command: WorkMethodsCommand = {
            permitId: action.permitId,
            workMethodIds: currentState.permitQuestionAnswer?.selectedWorkMethods.map(_ => _.id),
            freeText: currentState.permitQuestionAnswer?.workMethodManual,
        };

        return this.permitCreateService.saveWorkMethods(command);
    }

    @Action(PermitCreateActions.SetEmergencyLocation)
    public setEmergencyLocation(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SetEmergencyLocation): Observable<BaseValidationResult> {
        const command: EmergencyLocationCommand = {
            permitId: action.permitId,
            emergencyLocation: action.emergencyLocation,
            latitude: action.latitude ? action.latitude.toString() : undefined,
            longitude: action.longitude ? action.longitude.toString() : undefined,
        };

        ctx.setState(produce(ctx.getState(), draft => {
            draft.emergencyLocationForm.model.emergencyLocation = action.emergencyLocation;
        }));

        return this.permitCreateService.setEmergencyLocation(command).pipe(
            tap((result: BaseValidationResult) => {
                ctx.patchState({ setEmergencyLocationValidationResult: result });
            })
        );
    }

    @Action(PermitCreateActions.SetPermitPhoto)
    public setPermitPhoto(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SetPermitPhoto): void {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.permitQuestionAnswer.permitPhotos.push(action.photo);
        }));

        if (action.photo) {
            this.store.dispatch(new PermitPhotoActions.SavePermitPhoto(ctx.getState().permit.id, action.photo));
        }
    }

    @Action(PermitCreateActions.SetFireAlarm)
    public setFireAlarm(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SetFireAlarm): Observable<BaseValidationResult> {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.permitQuestionAnswer.disabledFireAlarm = action.text;
        }));

        const command: DisabledFireAlarmCommand = {
            permitId: action.permitId,
            disabledFireAlarm: action.text
        };

        return this.permitCreateService.setDisabledFireAlarm(command).pipe(tap((result) => {
            if (!result.success) {
                throw new Error('setDisabledFireAlarm was not successful');
            }
        }));
    }

    @Action(PermitCreateActions.SetIsFlammableHotWork)
    public setIsFlammableHotWork(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SetIsFlammableHotWork): void {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.permitQuestionAnswer.isFlammableHotWork = action.isFlammableHotWork;
        }));

        ctx.dispatch(new PermitStepActions.GoForward());
    }

    @Action(PermitCreateActions.SetPostWorkMonitoringTime)
    public setPostWorkMonitoringTime(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SetPostWorkMonitoringTime): Observable<BaseValidationResult> {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.permitQuestionAnswer.postWorkMonitoringTime = action.postWorkMonitoringTime;
        }));

        const command: PostWorkMonitoringTimeCommand = {
            permitId: action.permitId,
            postWorkMonitoringTime: action.postWorkMonitoringTime
        };

        return this.permitCreateService.setPostWorkMonitoringTime(command).pipe(tap((result) => {
            if (!result.success) {
                throw new Error('setPostWorkMonitoringTime was not successful');
            }
        }));
    }

    @Action(PermitCreateActions.StartPermit)
    public startPermit(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.StartPermit): Observable<BaseResult> {
        const state = ctx.getState();
        const command: StartPermitCommand = {
            permitId: state.permit.id,
            permitDuration: action.validTime
        };

        return this.permitCreateService.startPermit(command).pipe(tap(result => {
            if (result.success) {
                ctx.dispatch(new PermitDetailsActions.ShowPermitStarted(true));
            }
        }));
    }

    @Action(PermitCreateActions.ExtendPermit)
    public extendPermit(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.ExtendPermit): Observable<BaseResult> {
        const state = ctx.getState();
        const command: ExtendPermitCommand = {
            permitId: state.permit.id,
            hours: action.hours
        };
        return this.permitCreateService.extendPermit(command).pipe(tap(result => {
            if (result.success) {
                ctx.dispatch(new PermitDetailsActions.GetSummary(state.permit.id));
                ctx.dispatch(new PermitDetailsActions.GetBasicDetails(state.permit.id));
                ctx.dispatch(new PermitCreateActions.GetPermit(state.permit.id));
                ctx.dispatch(new PermitCreateActions.GetActiveFixedTemplate());
            }
        }));
    }

    @Action(PermitCreateActions.EndDemoOrNotFlammableHotWork)
    public endDemoOrNotFlammableHotWork(ctx: StateContext<PermitCreateStateModel>): Observable<BaseResult> {
        return this.permitCreateService.endDemoOrNotFlammableHotWork(ctx.getState().permit.id).pipe(tap(result => {
            if (result.success) {
                ctx.dispatch(new PermitStepActions.GetCurrentStep);
                ctx.dispatch(new PermitDetailsActions.ShowPermitStarted(true));
            }
        }));
    }

    @Action(PermitCreateActions.SetIsDemo)
    public setIsDemo(ctx: StateContext<PermitCreateStateModel>, action: PermitCreateActions.SetIsDemo): void {
        ctx.patchState({ isDemo: action.isDemo });
    }
}
