import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable, take } from "rxjs";

import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { WorkMethod } from "../../models/fixed-template.model";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-work-methods",
    templateUrl: "permit-work-methods.component.html",
    styleUrls: ["permit-work-methods.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitWorkMethodsComponent implements OnInit {
    @Input() public workMethods!: WorkMethod[];

    @Input() public permitId: number;

    @Select(PermitCreateSelectors.selectedWorkMethodsWithAlert)
    public selectedWorkMethodsWithAlert$: Observable<WorkMethod[]>;

    @Select(PermitCreateSelectors.isManualWorkMethodFormEnabled)
    public isFreeTextOptionSelected$: Observable<boolean>;

    @Select(PermitCreateSelectors.workMethodsIsValid)
    public workMethodsIsValid$: Observable<boolean>;

    @Select(PermitCreateSelectors.workMethodManual)
    public workMethodManual$: Observable<string>;
    public form = this.fb.group({
        workMethodManualInput: ["", Validators.required]
    });

    constructor(private actions$: Actions, private store: Store, private fb: NonNullableFormBuilder) { }

    public ngOnInit(): void {
        this.form.setValue({ workMethodManualInput: this.store.selectSnapshot(PermitCreateSelectors.workMethodManual) });

        this.actions$.pipe(ofActionSuccessful(PermitCreateActions.SaveWorkMethods))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                this.store.dispatch(new PermitStepActions.GoForward(this.permitId));
            });

        this.form.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new PermitCreateActions.SetManualWorkMethod(this.form.value.workMethodManualInput));
            });
    }

    public toggleWorkMethod(workMethod: WorkMethod): void {
        this.store.dispatch(new PermitCreateActions.ToggleWorkMethod(workMethod));
    }

    public setAlertConfirmed(workMethod: WorkMethod) {
        this.store.dispatch(new PermitCreateActions.ToggleWorkMethodAlertConfirmed(workMethod));
    }

    public continue(): void {
        this.store.dispatch(new PermitCreateActions.SaveWorkMethods(this.permitId));
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards(this.permitId));
    }
}
