import { Injectable } from "@angular/core";
import { BaseValidationResult } from "@ha/ui/forms";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Action, Actions, ofActionSuccessful, State, StateContext, Store } from "@ngxs/store";
import { produce } from "immer";
import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";

import { PermitStepActions } from "../../permit/states/permit-step.action";
import { CheckpointAnswerCommand, QuestionAnswerCommand } from "../models/create-permit-commands";
import { CustomerQuestion, FixedQuestion, QuestionDto } from "../models/question.model";
import { PermitCreateService } from "../services/permit-create.service";
import { PermitQuestionService } from "../services/permit-question.service";
import { PermitCreateSelectors } from "./permit-create.selectors";
import { PermitQuestionStorageState, SaveLocalQuestion } from "./permit-question-storage.state";
import { PermitQuestionActions } from "./permit-question.actions";

export interface PermitQuestionStateModel {
    customerQuestion: CustomerQuestion;
    fixedQuestion: FixedQuestion;

    permitId: number;
    subscriptionId: number;
    templateId: number;

    loadingQuestion: boolean;
}

const defaults: PermitQuestionStateModel = {
    customerQuestion: undefined,
    fixedQuestion: undefined,

    permitId: undefined,
    subscriptionId: undefined,
    templateId: undefined,

    loadingQuestion: undefined,
};

@UntilDestroy()
@Injectable()
@State<PermitQuestionStateModel>({
    name: "permitQuestion",
    defaults: defaults,
})
export class PermitQuestionState {
    constructor(
        private store: Store,
        private permitCreateService: PermitCreateService,
        private permitQuestionService: PermitQuestionService,
        private actions$: Actions,
    ) {}

    @Action(PermitQuestionActions.GetCurrentQuestion)
    public getCurrentQuestion(ctx: StateContext<PermitQuestionStateModel>): Observable<QuestionDto> {
        ctx.setState(produce((draft) => {
            draft.loadingQuestion = true;
        }));

        const state = ctx.getState();

        return this.permitQuestionService.getCurrentQuestion(state.permitId, this.getCurrentQuestionId(state)).pipe(
            tap((result: QuestionDto) => {
                ctx.setState(produce(ctx.getState(), (draft) => {
                    if (result.fixedQuestion && (result.fixedQuestion !== null)) {
                        draft.fixedQuestion = result.fixedQuestion;
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, result.fixedQuestion.id));
                    } else {
                        draft.fixedQuestion = undefined;
                    }

                    if (result.customerQuestion && (result.customerQuestion !== null)) {
                        draft.customerQuestion = result.customerQuestion;
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, result.customerQuestion.id));
                    } else {
                        draft.customerQuestion = undefined;
                    }

                    if (!draft.customerQuestion && !draft.fixedQuestion) {
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, undefined));
                        ctx.dispatch(new PermitStepActions.GoForward());
                    }

                    draft.loadingQuestion = false;
                }));
            }),
        );
    }

    @Action(PermitQuestionActions.GetPermitAndCurrentQuestion)
    public getPermitAndCurrentQuestion(
        ctx: StateContext<PermitQuestionStateModel>,
        action: PermitQuestionActions.GetPermitAndCurrentQuestion,
    ): Observable<void> {
        const permit = this.store.selectSnapshot(PermitCreateSelectors.permit);

        ctx.setState(produce(ctx.getState(), (draft) => {
            draft.permitId = permit.id;
            draft.subscriptionId = permit.subscriptionId;
            draft.templateId = permit.customerPermitTemplateId;
        }));

        return ctx.dispatch(new PermitQuestionActions.GetCurrentQuestion());
    }

    @Action(PermitQuestionActions.GetNextQuestion)
    public getNextQuestion(ctx: StateContext<PermitQuestionStateModel>): Observable<QuestionDto> {
        ctx.setState(produce((draft) => {
            draft.loadingQuestion = true;
        }));
        const state = ctx.getState();

        return this.permitQuestionService.getNextQuestion(state.permitId, this.getCurrentQuestionId(state)).pipe(
            tap((result: QuestionDto) => {
                ctx.setState(produce(ctx.getState(), (draft) => {
                    if (result.fixedQuestion && (result.fixedQuestion !== null)) {
                        draft.fixedQuestion = result.fixedQuestion;
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, result.fixedQuestion.id));
                    } else {
                        draft.fixedQuestion = undefined;
                    }

                    if (result.customerQuestion && (result.customerQuestion !== null)) {
                        draft.customerQuestion = result.customerQuestion;
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, result.customerQuestion.id));
                    } else {
                        draft.customerQuestion = undefined;
                    }

                    if (!draft.customerQuestion && !draft.fixedQuestion) {
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, undefined));
                        ctx.dispatch(new PermitStepActions.GoForward());
                    }

                    draft.loadingQuestion = false;
                }));
            }),
        );
    }

    @Action(PermitQuestionActions.GetPreviousQuestion)
    public getPreviousQuestion(ctx: StateContext<PermitQuestionStateModel>): Observable<any> {
        ctx.setState(produce((draft) => {
            draft.loadingQuestion = true;
        }));
        const state = ctx.getState();

        return this.permitQuestionService.getPreviousQuestion(state.permitId, this.getCurrentQuestionId(state)).pipe(
            tap((result: QuestionDto) => {
                ctx.setState(produce(ctx.getState(), (draft) => {
                    if (result.fixedQuestion && (result.fixedQuestion !== null)) {
                        draft.fixedQuestion = result.fixedQuestion;
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, result.fixedQuestion.id));
                    } else {
                        draft.fixedQuestion = undefined;
                    }

                    if (result.customerQuestion && (result.customerQuestion !== null)) {
                        draft.customerQuestion = result.customerQuestion;
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, result.customerQuestion.id));
                    } else {
                        draft.customerQuestion = undefined;
                    }

                    if (!draft.customerQuestion && !draft.fixedQuestion) {
                        ctx.dispatch(new SaveLocalQuestion(state.permitId, undefined));
                        ctx.dispatch(new PermitStepActions.GoBackwards());
                    }

                    draft.loadingQuestion = false;
                }));
            }),
        );
    }

    @Action(PermitQuestionActions.ResetToDefault)
    public resetToDefault(ctx: StateContext<PermitQuestionStateModel>): void {
        ctx.setState(defaults);
    }

    @Action(PermitQuestionActions.SetFixedOrCustomerQuestionAnswer)
    public setQuestionOrCheckpointAnswer(
        ctx: StateContext<PermitQuestionStateModel>,
        action: PermitQuestionActions.SetFixedOrCustomerQuestionAnswer,
    ): void {
        const currentState = ctx.getState();

        this.actions$.pipe(
            ofActionSuccessful(PermitQuestionActions.SaveCheckpointAnswer, PermitQuestionActions.SaveQuestionAnswer),
            take(1),
            tap(() => {
                ctx.dispatch(new PermitQuestionActions.GetNextQuestion());
            }),
        ).subscribe();

        if (currentState.customerQuestion) {
            this.store.dispatch(
                new PermitQuestionActions.SaveCheckpointAnswer(currentState.customerQuestion.id, action.answer),
            );
        } else if (currentState.fixedQuestion) {
            this.store.dispatch(
                new PermitQuestionActions.SaveQuestionAnswer(currentState.fixedQuestion.id, action.answer),
            );
        }
    }

    @Action(PermitQuestionActions.SaveQuestionAnswer)
    public saveQuestionAnswer(
        ctx: StateContext<PermitQuestionStateModel>,
        action: PermitQuestionActions.SaveQuestionAnswer,
    ): Observable<BaseValidationResult> {
        const state = ctx.getState();

        const command: QuestionAnswerCommand = {
            permitId: state.permitId,
            fixedQuestionAnswer: {
                answer: action.answer,
                questionId: action.questionId,
            },
        };

        return this.permitCreateService.setFixedQuestionAnswer(command).pipe(tap((result) => {
            if (result.success) {
                // Do stuff?
            }
        }));
    }

    @Action(PermitQuestionActions.SaveCheckpointAnswer)
    public saveCheckpointAnswer(
        ctx: StateContext<PermitQuestionStateModel>,
        action: PermitQuestionActions.SaveCheckpointAnswer,
    ): Observable<BaseValidationResult> {
        const state = ctx.getState();

        const command: CheckpointAnswerCommand = {
            permitId: state.permitId,
            customerCheckpointAnswer: {
                answer: action.answer,
                checkpointId: action.checkpointId,
            },
        };

        return this.permitCreateService.setCheckpointAnswer(command).pipe(tap((result) => {
            if (result.success) {
                // Do stuff?
            }
        }));
    }

    private getCurrentQuestionId(state: PermitQuestionStateModel): number {
        const localPermitId = this.store.selectSnapshot(PermitQuestionStorageState.permitId);
        const localQuestionId = this.store.selectSnapshot(PermitQuestionStorageState.questionId);

        if (state.permitId == localPermitId) {
            if (localQuestionId) {
                return localQuestionId;
            }

            if (state.customerQuestion) {
                return state.customerQuestion.id;
            }

            if (state.fixedQuestion) {
                return state.fixedQuestion.id;
            }
        }

        return undefined;
    }
}
