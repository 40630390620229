import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Action, State, StateContext } from "@ngxs/store";
import produce from "immer";
import { delay, Observable, of, repeat, tap } from "rxjs";

import { NotificationPage } from "../models/notification-page";
import { NotificationStatus } from "../models/notification-status.enum";
import { Notification } from "../models/notification.model";
import { SetNotificationStatusCommand } from "../models/set-notification-status-command.model";
import { NotificationService } from "../services/notification.service";
import { NotificationActions } from "./notification.actions";

export interface NotificationStateModel {
    notifications: Notification[] | undefined;
    unreadNotifications: Notification[];
    unseenNotificationsCount: number;
    currentNotification: NotificationPage | undefined;
    loading: boolean | undefined;
}

const defaults = {
    notifications: undefined,
    unreadNotifications: [],
    unseenNotificationsCount: 0,
    currentNotification: undefined,
    loading: undefined,
};

@UntilDestroy()
@Injectable()
@State<NotificationStateModel>({
    name: "notification",
    defaults: defaults,
})
export class NotificationState {
    constructor(private notificationService: NotificationService) {}

    public ngxsOnInit(ctx: StateContext<NotificationStateModel>): void {
        of(null).pipe(delay(60 * 1000), repeat(), untilDestroyed(this)).subscribe(() => {
            ctx.dispatch(new NotificationActions.GetUnseenNotificationCount());
        });
    }

    @Action(NotificationActions.GetAllNotifications)
    public getAll(ctx: StateContext<NotificationStateModel>): Observable<Notification[]> {
        return this.notificationService.getAll().pipe(tap((notifications) => {
            if (notifications) {
                ctx.patchState({ notifications: notifications });
            }

            // Update count
            ctx.dispatch(new NotificationActions.GetUnseenNotificationCount());
        }));
    }

    @Action(NotificationActions.GetUnreadNotifications)
    public getUnreadNotifications(ctx: StateContext<NotificationStateModel>): Observable<Notification[]> {
        ctx.setState(produce((draft) => {
            draft.loading = true;
        }));

        return this.notificationService.getUnreadNotifications().pipe(tap((notifications) => {
            ctx.patchState({ unreadNotifications: notifications, loading: false });

            // Update count
            ctx.dispatch(new NotificationActions.GetUnseenNotificationCount());
        }));
    }

    @Action(NotificationActions.GetUnseenNotificationCount)
    public getUnseenNotificationCount(ctx: StateContext<NotificationStateModel>): Observable<number> {
        return this.notificationService.getUnseenNotificationCount().pipe(tap((count) => {
            ctx.patchState({ unseenNotificationsCount: count });
        }));
    }

    @Action(NotificationActions.GetCurrentNotification)
    public getCurrentNotification(
        ctx: StateContext<NotificationStateModel>,
        action: NotificationActions.GetCurrentNotification,
    ): Observable<NotificationPage> {
        ctx.patchState({ currentNotification: undefined });

        return this.notificationService.getNotificationPage(action.notificationId).pipe(tap((notificationPage) => {
            ctx.patchState({
                currentNotification: notificationPage,
            });
        }));
    }

    @Action(NotificationActions.SetNotificationStatus)
    public setNotificationStatus(
        ctx: StateContext<NotificationStateModel>,
        action: NotificationActions.SetNotificationStatus,
    ): Observable<boolean> {
        const command: SetNotificationStatusCommand = {
            notificationIds: [action.notificationId],
            status: action.status,
        };

        return this.setStatusForNotifications(ctx, command);
    }

    @Action(NotificationActions.SetStatusForAll)
    public setStatusForAll(
        ctx: StateContext<NotificationStateModel>,
        action: NotificationActions.SetStatusForAll,
    ): Observable<boolean> {
        const state = ctx.getState();

        const command: SetNotificationStatusCommand = {
            notificationIds: state.notifications?.map((_) => _.contentId) ?? [],
            status: action.status,
        };

        return this.setStatusForNotifications(ctx, command);
    }

    @Action(NotificationActions.SetStatusForAllUnread)
    public setStatusForAllUnread(
        ctx: StateContext<NotificationStateModel>,
        action: NotificationActions.SetStatusForAllUnread,
    ): Observable<boolean> {
        const state = ctx.getState();

        const command: SetNotificationStatusCommand = {
            notificationIds: state.unreadNotifications.map((_) => _.contentId),
            status: action.status,
        };

        return this.setStatusForNotifications(ctx, command);
    }

    @Action(NotificationActions.RemoveNotification)
    public removeNotification(
        ctx: StateContext<NotificationStateModel>,
        action: NotificationActions.RemoveNotification,
    ): Observable<boolean> {
        const command: SetNotificationStatusCommand = {
            notificationIds: [action.notificationId],
            status: NotificationStatus.Deleted,
        };

        return this.setStatusForNotifications(ctx, command);
    }

    private setStatusForNotifications(
        ctx: StateContext<NotificationStateModel>,
        command: SetNotificationStatusCommand,
    ): Observable<boolean> {
        return this.notificationService.setStatusForNotifications(command).pipe(tap((result) => {
            if (result) {
                ctx.dispatch(new NotificationActions.GetAllNotifications());
            }
        }));
    }
}
