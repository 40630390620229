import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PermitStep, PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitTeam } from "../../../permit-team/models/permit-team.model";
import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitTeamSelectors } from "../../../permit-team/states/permit-team.selectors";
import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitCreateSignPersonModalComponent, PermitCreateSignPersonModalComponentData } from "../../../shared/components/permit-create-sign-person-modal/permit-create-sign-person-modal.component";
import { ViewSignatureModalComponent, ViewSignatureModalComponentData } from "../../../shared/components/view-signature-modal/view-signature-modal.component";
import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitUserSelectors } from "../../../shared/states/permit-user.selectors";
import { PermitBasicDetailsDto } from "../../models/permit-basic-details.model";
import { PermitSummaryDto } from "../../models/permit-summary.model";
import { PermitDetailsActions } from "../../states/permit-details.action";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";
import { PermitEndSignatureModalComponent } from "../permit-end-signature-modal/permit-end-signature-modal.component";

@UntilDestroy()
@Component({
    selector: "pmt-permit-end-signature",
    templateUrl: "./permit-end-signature.component.html",
    styleUrls: ["./permit-end-signature.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitEndSignatureComponent implements OnInit {
    @Input()
    public permitId: number;

    @Select(PermitDetailsSelectors.basicDetails)
    public basicDetails$: Observable<PermitBasicDetailsDto>;

    @Select(PermitDetailsSelectors.summary)
    public summary$: Observable<PermitSummaryDto>;

    @Select(PermitTeamSelectors.permitIssuer)
    public permitIssuer$: Observable<PermitPerson>;

    @Select(PermitTeamSelectors.team)
    public team$: Observable<PermitTeam>;

    @Select(PermitStepSelectors.currentStep)
    public currentStep$: Observable<CurrentStep>;

    @Select(PermitDetailsSelectors.currentUserIsIssuer)
    public currentUserIsIssuer$: Observable<boolean>;

    @Select(PermitDetailsSelectors.currentUserIsPostFireWatcher)
    public currentUserIsPostFireWatcher$: Observable<boolean>;

    @Select(PermitTeamSelectors.postFireWatcherHasSigned)
    public postFireWatcherHasSigned$: Observable<boolean>;

    public PersonRole = PersonRole;

    public PermitStep = PermitStep;

    constructor(private store: Store, private modalService: HaModalService, private actions$: Actions) {}

    public ngOnInit(): void {
        this.actions$.pipe(ofActionSuccessful(PermitTeamActions.SignPermitWithSignature)).pipe(untilDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new PermitDetailsActions.GetBasicDetails(this.permitId));
            });
    }

    public openEndSignatureModal(): void {
        const issuer = this.store.selectSnapshot(PermitTeamSelectors.permitIssuer);
        const summary = this.store.selectSnapshot(PermitDetailsSelectors.summary);

        const data = <PermitCreateSignPersonModalComponentData> {
            person: issuer,
            permitId: this.permitId,
            role: PersonRole.PermitIssuer,
            disabledFireAlarm: summary.disabledFireAlarm,
        };

        this.modalService.open(PermitEndSignatureModalComponent, true, data);
    }

    public openPostFireWatchSignatureModal(permitPerson: PermitPerson = undefined): void {
        if (!permitPerson) {
            const currentPerson = this.store.selectSnapshot(PermitUserSelectors.currentPerson);
            permitPerson = this.store.selectSnapshot(PermitTeamSelectors.team).postFireWatchers.find((_) =>
                _.personId === currentPerson.personId
            );
        }

        if (!permitPerson.endSignature) {
            const data = <PermitCreateSignPersonModalComponentData> {
                person: permitPerson,
                permitId: this.permitId,
                role: PersonRole.PostFireWatcher,
                endPostWatchSignature: true
            };

            this.modalService.open(PermitCreateSignPersonModalComponent, true, data);
        } else {
            this.modalService.open(
                ViewSignatureModalComponent,
                true,
                <ViewSignatureModalComponentData> {
                    name: `${permitPerson.firstName} ${permitPerson.lastName}`,
                    signature: permitPerson.endSignature,
                },
            );
        }
    }
}
