import { Injectable } from "@angular/core";
import { BaseValidationResult } from "@ha/ui/forms";
import { Navigate } from "@ngxs/router-plugin";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { PermitPaths } from "../../permit-paths";
import { PermitListItem } from "../models/permit-list-item.model";
import { PreparedPermitListItem } from "../models/prepared-permit-list-item.model";
import { PermitListService } from "../services/permit-list.service";
import { PermitListActions } from "./permit-list.actions";
import { PermitWorkplaceItem } from "../models/permit-workplace-item.model";
import { PermitListSelectors } from "./permit-list.selectors";

export interface PermitListStateModel {
    permits: PermitListItem[] | undefined;
    preparedPermits: PreparedPermitListItem[] | undefined;
    ongoingPermits: PermitListItem[] | undefined;
    filteredPermits: PermitListItem[] | undefined;
    plannedPermits: PermitListItem[] | undefined;
    completedPermits: PermitListItem[] | undefined;
    selectedWorkplaces: string[] | undefined;
    workplaces: PermitWorkplaceItem[] | undefined;
    showAllSubscriptionPermits?: boolean;
}

@Injectable()
@State<PermitListStateModel>({
    name: "permitList",
})
export class PermitListState {
    constructor(private store: Store, private permitListService: PermitListService) {}

    @Action(PermitListActions.GetAllCompleted)
    public getAllCompleted(ctx: StateContext<PermitListStateModel>): Observable<PermitListItem[]> {
        return this.permitListService.getAllCompleted().pipe(tap((data) => {
            ctx.patchState({
                completedPermits: data,
            });
        }));
    }

    @Action(PermitListActions.GetAllOngoing)
    public getAllOngoing(ctx: StateContext<PermitListStateModel>, action: PermitListActions.GetAllOngoing): Observable<PermitListItem[]> {
        var includeAll = this.store.selectSnapshot(PermitListSelectors.showAllSubscriptionPermits);
        if (includeAll == undefined) {
            includeAll = false;
        }
        return this.permitListService.getAllOngoing(includeAll).pipe(tap((data) => {
            var wps = this.store.selectSnapshot(PermitListSelectors.workplaces).filter(item => item.isSelected).map(item => item.name);
            ctx.patchState({
                ongoingPermits: data,
                selectedWorkplaces: wps,
                filteredPermits: wps.length == 0 ? data : data?.filter(item => wps.indexOf(item.workplaceContainer) > -1),
            });
        }));
    }

    @Action(PermitListActions.ToggleShowAllPermits)
    public toggleShowAllPermits(ctx: StateContext<PermitListStateModel>, action: PermitListActions.ToggleShowAllPermits): void {
        var includeAll = this.store.selectSnapshot(PermitListSelectors.showAllSubscriptionPermits);
        ctx.patchState({
            showAllSubscriptionPermits: !includeAll
        });
    }

    @Action(PermitListActions.UpdateFilter)
    public updateFilter(ctx: StateContext<PermitListStateModel>, action: PermitListActions.UpdateFilter): void {
        var wps = this.store.selectSnapshot(PermitListSelectors.workplaces)
            .map(item => ({
                name: item.name,
                availablePermits: item.availablePermits,
                isSelected: action.value.indexOf(item.name) > -1,
            }));

        var data = this.store.selectSnapshot(PermitListSelectors.ongoingPermits);
        var selected = wps.filter(item => item.isSelected).map(item => item.name);

        ctx.patchState({
            workplaces: wps,
            selectedWorkplaces: action.value,
            filteredPermits: selected.length == 0 ? data : data?.filter(item => selected.indexOf(item.workplaceContainer) > -1),
        });
    }

    @Action(PermitListActions.GetAllPlanned)
    public getAllPlanned(ctx: StateContext<PermitListStateModel>): Observable<PermitListItem[]> {
        return this.permitListService.getAllPlanned().pipe(tap((data) => {
            ctx.patchState({
                plannedPermits: data,
            });
        }));
    }

    @Action(PermitListActions.GetAllPrepared)
    public getAllPrepared(ctx: StateContext<PermitListStateModel>): Observable<PreparedPermitListItem[]> {
        return this.permitListService.getAllPrepared().pipe(tap((data) => {
            ctx.patchState({
                preparedPermits: data,
            });
        }));
    }

    @Action(PermitListActions.ClaimPreparedPermit)
    public claimPreparedPermit(ctx: StateContext<PermitListStateModel>, action: PermitListActions.ClaimPreparedPermit): Observable<BaseValidationResult> {
        return this.permitListService.claimPreparedPermit(action.permitId).pipe(tap((result) => {
            if (result.success) {
                this.store.dispatch(new Navigate([PermitPaths.Root, action.permitId]));
            }
        }));
    }

    @Action(PermitListActions.RemovePlannedPermit)
    public removePlannedPermit(ctx: StateContext<PermitListStateModel>, action: PermitListActions.RemovePlannedPermit): Observable<BaseValidationResult> {
        return this.permitListService.removePlannedPermit(action.permitId).pipe(tap((result) => {
            if (result.success) {
                // Success
            }
        }));
    }
}
