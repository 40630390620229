import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "haCardNumber" })
export class HaCardNumberPipe implements PipeTransform {
    public transform(value: string) {
        if (value.length !== 12) {
            return value;
        }

        return [value.slice(0, 4), value.slice(4, 8), value.slice(8, 12)].join("&thinsp;");
    }
}
