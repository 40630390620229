import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Account } from "../models/current-account.model";

@Injectable({ providedIn: "root" })
export class CurrentAccountService {
    constructor(private httpClient: HttpClient) {}

    public get(): Observable<Account> {
        return this.httpClient.get<Account>("/api/AccountService/GetCurrentAccount");
    }
}
