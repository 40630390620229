import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "feedback",
    templateUrl: "./feedback.component.html",
    styleUrls: ["./feedback.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent {
    @Input() public type: "info" | "error" = "info";
}
