import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { KnowledgeBankSearchResultItem } from "../../models/knowledge-bank-search-result";

@Component({
    selector: "knowledge-bank-search-results-item",
    templateUrl: "./knowledge-bank-search-results-item.component.html",
    styleUrls: ["./knowledge-bank-search-results-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankSearchResultsItemComponent {
    @Input() public data: KnowledgeBankSearchResultItem;
    @Output() public clickTrack = new EventEmitter();

    public showAllMedia = false;

    public mousedown(event: MouseEvent): void {
        // Left or middle button
        if (event.button === 0 || event.button === 1) {
            this.clickTrack.emit();
        }
    }

    public toggleAllMedia(): void {
        this.showAllMedia = !this.showAllMedia;
    }
}
