import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { PermitStep } from "@ha/data/basic";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, Select, Store } from "@ngxs/store";
import { Observable, tap } from "rxjs";

import { CurrentStep } from "../../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../../permit/states/permit-step.selectors";
import { CustomerQuestion, CustomerQuestionAnswer, FixedQuestion } from "../../../models/question.model";
import { PermitCreateSelectors } from "../../../states/permit-create.selectors";
import { PermitQuestionActions } from "../../../states/permit-question.actions";

@UntilDestroy()
@Component({
    selector: "pmt-question",
    templateUrl: "permit-question.component.html",
    styleUrls: ["permit-question.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitQuestionComponent implements OnChanges {
    @Input() public fixedQuestion: FixedQuestion | null;
    @Input() public customerQuestion: CustomerQuestion | null;

    @Select(PermitStepSelectors.currentStep)
    public currentStep$!: Observable<CurrentStep>;

    @Select(PermitCreateSelectors.companyName)
    public companyName$: Observable<string>;

    public PermitStep = PermitStep;
    public CustomerQuestionAnswer = CustomerQuestionAnswer;

    public currentCustomerAnswer: CustomerQuestionAnswer;
    public alertConfirmed = false;

    public get canProceed() {
        return this.currentCustomerAnswer &&
            (!this.showConfirmDialog || (this.showConfirmDialog && this.alertConfirmed));
    }

    public get showConfirmDialog(): boolean {
        if (this.fixedQuestion?.alert?.answerIsYes && this.currentCustomerAnswer === CustomerQuestionAnswer.Yes) {
            return true;
        } else if (this.fixedQuestion?.alert) {
            if (this.fixedQuestion.alert.answerIsNo && this.currentCustomerAnswer === CustomerQuestionAnswer.No && !this.fixedQuestion.alert.confirmToProceed) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    constructor(private store: Store, private actions$: Actions) { }

    public ngOnChanges(): void {
        this.alertConfirmed = false;
        this.currentCustomerAnswer = undefined;

        if (this.fixedQuestion) {
            if (this.fixedQuestion.currentAnswer !== undefined) {
                this.select(this.fixedQuestion.currentAnswer);
            }
        }
        if (this.customerQuestion) {
            if (this.customerQuestion.currentAnswer !== undefined) {
                this.select(this.customerQuestion.currentAnswer);
            }
        }
    }

    public select(answer: CustomerQuestionAnswer) {
        this.currentCustomerAnswer = answer;
        this.alertConfirmed = false;
    }

    public setAlertConfirmed(confirmed: boolean) {
        this.alertConfirmed = confirmed;
    }

    public continue(): void {
        this.actions$.pipe(untilDestroyed(this), ofActionCompleted(PermitQuestionActions.SetFixedOrCustomerQuestionAnswer), tap(() => {
            this.reset();
        }));

        this.store.dispatch(new PermitQuestionActions.SetFixedOrCustomerQuestionAnswer(this.currentCustomerAnswer));
    }

    public goBack(): void {
        this.store.dispatch(new PermitQuestionActions.GetPreviousQuestion());
    }

    public getCheckpointAlternativeNumbers(customerQuestionAnswer: CustomerQuestionAnswer): number[] {
        const choiceNumbers: number[] = [];
        let i = 0;
        let cqa: number;

        while (CustomerQuestionAnswer[cqa = 1 << i++]) {
            if (customerQuestionAnswer & cqa) {
                choiceNumbers.push(cqa);
            }
        }

        return choiceNumbers;
    }

    private reset(): void {
        this.alertConfirmed = false;
        this.currentCustomerAnswer = undefined;
    }
}
