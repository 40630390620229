import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HaUtilPipesModule } from "@ha/util/pipes";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsActionsExecutingModule } from "@ngxs-labs/actions-executing";
import { NgxsModule } from "@ngxs/store";

import { DimmerComponent } from "./components/dimmer/dimmer.component";
import { ErrorsComponent } from "./components/errors/errors.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { FilterMenuCheckboxComponent } from "./components/filter-menu/filter-menu-checkbox/filter-menu-checkbox.component";
import { FilterMenuComponent } from "./components/filter-menu/filter-menu.component";
import { LogoComponent } from "./components/logo/logo.component";
import { ModalComponent } from "./components/modal/modal.component";
import { NewsPuffComponent } from "./components/news-puff/news-puff.component";
import { SplashScreenComponent } from "./components/splash-screen/splash-screen.component";
import { ButtonLoadingDirective } from "./directives/button-loading.directive";
import { ClickAnchorDirective } from "./directives/click-anchor.directive";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { CtrlClickDirective } from "./directives/ctrl-click.directive";
import { ErrorsState } from "./states/errors.state";
import { LanguageState } from "./states/language.state";

// HEAD
//
// master
export { SlideAnimations } from "./animations/slide-animations";
export { DimmerComponent } from "./components/dimmer/dimmer.component";
export { ErrorsComponent } from "./components/errors/errors.component";
export { FeedbackComponent } from "./components/feedback/feedback.component";
export { FilterMenuCheckboxComponent } from "./components/filter-menu/filter-menu-checkbox/filter-menu-checkbox.component";
export { FilterMenuComponent } from "./components/filter-menu/filter-menu.component";
export { LogoComponent } from "./components/logo/logo.component";
export { ModalComponent } from "./components/modal/modal.component";
export { NewsPuffComponent } from "./components/news-puff/news-puff.component";
export { SplashScreenComponent } from "./components/splash-screen/splash-screen.component";
export { ButtonLoadingDirective } from "./directives/button-loading.directive";
export { ClickAnchorDirective } from "./directives/click-anchor.directive";
export { ClickOutsideDirective } from "./directives/click-outside.directive";
export { CtrlClickDirective } from "./directives/ctrl-click.directive";
export { LanguageStateActions } from "./states/language.actions";
export { LanguageStateSelectors } from "./states/language.selectors";
export { LanguageState } from "./states/language.state";

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
        RouterModule,
        NgxsModule.forFeature([ErrorsState, LanguageState]),
        NgxsActionsExecutingModule.forRoot(),
        OverlayModule,
        HaUtilPipesModule,
    ],
    declarations: [
        ButtonLoadingDirective,
        ClickAnchorDirective,
        ClickOutsideDirective,
        CtrlClickDirective,
        DimmerComponent,
        ErrorsComponent,
        FeedbackComponent,
        FilterMenuCheckboxComponent,
        FilterMenuComponent,
        LogoComponent,
        ModalComponent,
        NewsPuffComponent,
        SplashScreenComponent,
    ],
    exports: [
        ButtonLoadingDirective,
        ClickAnchorDirective,
        ClickOutsideDirective,
        CtrlClickDirective,
        DimmerComponent,
        ErrorsComponent,
        FeedbackComponent,
        FilterMenuCheckboxComponent,
        FilterMenuComponent,
        LogoComponent,
        ModalComponent,
        NewsPuffComponent,
        SplashScreenComponent,
    ],
    providers: [],
})
export class HaUiCommonModule {}
