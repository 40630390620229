import { ChangeDetectionStrategy, Component } from "@angular/core";

import { AccordionBlock } from "./accordion-block";

@Component({
    templateUrl: "./accordion-block.component.html",
    styleUrls: ["./accordion-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionBlockComponent {
    public data: AccordionBlock;
}
