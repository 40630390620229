export namespace UserAgreementActions {
    export class OpenUserAgreementWhenSignedInModal {
        public static readonly type = "[PermitPersonUserAgreement] OpenUserAgreementWhenSignedInModal";
    }

    export class AcceptUserAgreementWhenSignedIn {
        public static readonly type = "[PermitPersonUserAgreement] AcceptUserAgreementWhenSignedIn";
    }

    export class RejectAgreementWhenSignedIn {
        public static readonly type = "[PermitPersonUserAgreement] RejectAgreementWhenSignedIn";
    }

    export class OpenUserAgreementOnIssuerUnit {
        public static readonly type = "[PermitPersonUserAgreement] OpenUserAgreementOnIssuerUnit";
        constructor(public isExternal: boolean) {}
    }

    export class AcceptUserAgreementOnIssuerUnit {
        public static readonly type = "[PermitPersonUserAgreement] AcceptUserAgreementOnIssuerUnit";
    }

    export class RejectAgreementOnIssuerUnit {
        public static readonly type = "[PermitPersonUserAgreement] RejectAgreementOnIssuerUnit";
    }
}
