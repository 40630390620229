<div class="root" (click)="getVideoMetadata(); player.open()">
    <div class="root__text">
        <span class="root__text__prefix">Video &ndash; </span>
        <span class="root__text__title" [innerHTML]="data?.title?.value"></span>
    </div>
    <div class="root__thumbnail">
        <img class="root__thumbnail__image" [src]="metaData?.thumbnail_url">
    </div>

</div>

<vimeo-video-player #player [metaData]="metaData">
</vimeo-video-player>
