<ng-container *transloco="let t; read:'permit.details.comments.removeModal'">
    <div class="modal">
        <div class="modal__content">
            <!-- <div class="modal__content__comment">{{ this.data.comment.text }}</div> -->
            <div class="modal__content__question" [innerHTML]="t('text', { author: this.data.comment.fullName })"></div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__remove" type="button" (click)="removeComment()">{{ t("remove") }}</button>
        </div>
    </div>
</ng-container>
