import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "digital-book-modal",
    templateUrl: "./digital-book-modal.component.html",
    styleUrls: ["digital-book-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalBookModalComponent {
    @Input()
    public bookUrl: string;
    public isOpen: boolean;

    constructor(private domSanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

    public get src(): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(this.bookUrl);
    }

    @HostListener("window:keyup", ["$event"])
    public windowKeyUp(event: KeyboardEvent): boolean {
        if (event.key === "Escape") {
            this.close();
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    public open(): void {
        this.isOpen = true;
        this.cdr.markForCheck();
    }

    public close(): void {
        this.isOpen = false;
        this.cdr.markForCheck();
    }
}
