import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from "@angular/core";

import { GetComponentTypeByName } from "../../functions/register-epi-component";
import { DynamicEpiPropertyBaseComponent } from "../../models/dynamic-epi-property-base.component";
import { IContent } from "../../models/episerver-base-types.model";

@Component({
    selector: "epi-property",
    template: "<ng-container #container></ng-container>",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EpiserverPropertyComponent implements OnInit, OnDestroy {
    @ViewChild("container", { read: ViewContainerRef, static: true })
    public container: ViewContainerRef;

    @Input()
    public data: IContent;
    @Input()
    public propertyName: string;

    private componentRef: ComponentRef<unknown>;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    public ngOnInit(): void {
        const componentType = this.getComponentType(this.data);

        // note: componentType must be declared within module.entryComponents
        const factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        this.componentRef = this.container.createComponent(factory);

        // set component context
        const instance = <DynamicEpiPropertyBaseComponent> this.componentRef.instance;

        instance.data = this.data;
    }

    private getComponentType(d: IContent) {
        let type: Type<unknown>;

        if (type === undefined) {
            // Not expanded
            if (d.contentType) {
                const contentTypes = d.contentType;
                type = GetComponentTypeByName(contentTypes[contentTypes.length - 1]);
            }

            if (type === undefined) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                console.error(`[EpiserverPageComponent] Component type not found. Data: ${d}`);
            }

            return type;
        }

        return undefined;
    }

    public ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
