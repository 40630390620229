import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { IssuerDto } from "../../../models/change-issuer-dto";
import { PermitChangeIssuerActions } from "../../../states/change-issuer-state/change-issuer.action";
import { PermitChangeIssuerSelectors } from "../../../states/change-issuer-state/change-issuer.selectors";
import { PermitTeam } from "../../../../permit-team/models/permit-team.model";
import { PermitTeamSelectors } from "../../../../permit-team/states/permit-team.selectors";


@Component({
    selector: "pmt-permit-change-issuer-premium",
    templateUrl: "change-issuer-premium.component.html",
    styleUrls: ["change-issuer-premium.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitChangeIssuerPremiumComponent {
    @Input() public permitId: number;

    @Select(PermitTeamSelectors.team)
    public permitTeam$: Observable<PermitTeam>;

    @Select(PermitChangeIssuerSelectors.availableIssuers)
    public availableIssuers$: Observable<IssuerDto[]>;

    @Select(PermitChangeIssuerSelectors.selectedNewIssuer)
    public selectedNewIssuer$: Observable<IssuerDto>;

    constructor(private store: Store, private haModalService: HaModalService) {}

    public toggleIssuer(issuer: IssuerDto) {
        this.store.dispatch(new PermitChangeIssuerActions.SelectIssuer(issuer.certificateId));
    }

    public isHotworker(issuer: IssuerDto, team: PermitTeam): boolean {
        return team.hotWorkers.some(hw => hw.certificateId == issuer.certificateId);
    }
}
