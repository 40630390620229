import { CustomerQuestionAnswer } from "../models/question.model";

export namespace PermitQuestionActions {
    export class GetNextQuestion {
        public static readonly type = "[PermitQuestion] GetNextQuestion";
    }

    export class GetPermit {
        public static readonly type = "[PermitQuestion] GetPermit";
        constructor(public permitId: number) { }
    }

    export class GetPermitAndCurrentQuestion {
        public static readonly type = "[PermitQuestion] GetPermitAndCurrentQuestion";
    }

    export class GetCurrentQuestion {
        public static readonly type = "[PermitQuestion] GetCurrentQuestion";
    }

    export class GetPreviousQuestion {
        public static readonly type = "[PermitQuestion] GetPreviousQuestion";
    }

    export class ResetToDefault {
        public static readonly type = "[PermitQuestion] ResetToDefault";
    }

    export class SetFixedOrCustomerQuestionAnswer {
        public static readonly type = "[PermitQuestion] SetQuestionAnswer";
        constructor(public answer: CustomerQuestionAnswer) { }
    }

    export class SaveQuestionAnswer {
        public static readonly type = "[PermitQuestion] SaveQuestionAnswer";
        constructor(public questionId: number, public answer: CustomerQuestionAnswer) { }
    }

    export class SaveCheckpointAnswer {
        public static readonly type = "[PermitQuestion] SaveCheckpointAnswer";
        constructor(public checkpointId: number, public answer: CustomerQuestionAnswer) { }
    }
}
