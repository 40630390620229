import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { produce } from "immer";
import { tap } from "rxjs";

import { PermitPerson } from "../models/permit-person.model";
import { PermitToSign } from "../models/permit-to-sign.model";
import { PermitUserService } from "../services/permit-user.service";
import { PermitUserActions } from "./permit-user.action";

export interface PermitUserStateModel {
    currentPerson: PermitPerson;
    permitsToSign: PermitToSign[];
    isHotWorker: boolean;
    canCreateDemoPermits: boolean;
    isExternal: boolean;
    isAdministrator: boolean;
}

@Injectable()
@State<PermitUserStateModel>({
    name: "permitUser",
})
export class PermitUserState {
    constructor(private permitUserService: PermitUserService, private store: Store) {}

    @Action(PermitUserActions.GetCurrentPerson)
    public getCurrentPerson(ctx: StateContext<PermitUserStateModel>) {
        return this.permitUserService.getCurrentPerson().pipe(tap(result => {
            ctx.setState(produce(draft => {
                draft.currentPerson = result;
                draft.isHotWorker = !!this.permitUserService.currentUser.isHotworker;
                draft.canCreateDemoPermits = !!this.permitUserService.currentUser.canCreateDemoPermits;
                draft.isExternal = !!this.permitUserService.currentUser.isExternal;
                draft.isAdministrator = !!this.permitUserService.currentUser.isAdministrator;
            }));
        }));
    }

    @Action(PermitUserActions.GetPermitsToSign)
    public getPermitsToSign(ctx: StateContext<PermitUserStateModel>) {
        return this.permitUserService.getPermitsToSign().pipe(tap(result => {
            ctx.setState(produce(draft => {
                draft.permitsToSign = result;
            }));
        }));
    }
}
