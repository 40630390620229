import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { UserAgreement, UserAgreementType } from "../models/user-agreement";

@Injectable({ providedIn: "root" })
export class UserAgreementService {
    constructor(private httpClient: HttpClient) {}

    public getUserAgreement(isExternal: boolean): Observable<UserAgreement> {
        if (isExternal) {
            return this.httpClient.get<UserAgreement>("/api/PermitPerson/GetUserAgreementOtherConceptsAndForeign");
        }
        return this.httpClient.get<UserAgreement>("/api/PermitPerson/GetUserAgreementHotWorker");
    }

    public acceptUserAgreementWhenSignedIn(type: UserAgreementType): Observable<boolean> {
        return this.httpClient.post<boolean>("/api/PermitPerson/AcceptUserAgreementWhenSignedIn", type);
    }
}
