<ng-container *transloco="let t; read:'pwaUpdate'">
    <div class="modal">
        <div class="modal__content" [innerHtml]="t('message')"></div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="cancel()">{{ t("cancel") }}</button>
            <button class="modal__actions__reload" type="button" (click)="update()">{{ t("update") }}</button>
        </div>
    </div>
</ng-container>
