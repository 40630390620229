/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { TRANSLOCO_SCOPE, TranslocoService } from "@ngneat/transloco";

/**
 * Provider factory factory to provide a scope, alias and loader for a application module
 *
 * @param {string} scope
 * @returns
 */
export const libTranslationProviderFactory = (scope: string, libraryName: string) => {
    return {
        provide: TRANSLOCO_SCOPE,
        useFactory: (translocoService: TranslocoService) => {
            return {
                scope: scope,
                loader: scopeLibLoader(translocoService, libraryName)
            };
        },
        deps: [TranslocoService]
    };
};

/**
 * Loader to lazy load a application modules i18n folders.
 *
 * @param {TranslocoService} translocoService
 * @param {string} libraryName
 * @returns
 */
export const scopeLibLoader = (translocoService: TranslocoService, libraryName: string) => {
    const languages = (translocoService.getAvailableLangs() as string[]);

    return languages.reduce((acc: any, lang: string) => {
        const [libraryShortName] = libraryName.split("/").slice(-1);
        // eslint-disable-next-line function-paren-newline
        acc[lang] = () => import(`../../../../../${libraryName}/src/i18n/${libraryShortName}.${lang}.json`);
        return acc;
    }, {});
};

// If we put these in the import() above we can configure Webpack to put all imports in the same module
// We should do this if the web server don't support http2 to reduce number of JS-files loaded
/* webpackMode: "lazy-once" */
/* webpackChunkName: "i18n" */
