import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad";
import { NgModule } from "@angular/core";

import { PermitCreateModule } from "../permit-create/permit-create.module";
import { PermitDetailsModule } from "../permit-details/permit-details.module";
import { PermitListModule } from "../permit-list/permit-list.module";
import { SharedModule } from "../shared/shared.module";
import { SubscriptionsModule } from "../subscriptions/subscriptions.module";
import { PermitNoAccessComponent } from "./components/permit-no-access/permit-no-access.component";
import { PermitRootComponent } from "./components/permit-root.component";
import { SignPermitsNoticeComponent } from "./components/sign-permits-notice/sign-permits-notice.component";
import { SignPersonModalComponent } from "./components/sign-person-modal/sign-person-modal.component";
import { PermitRootFilterModalComponent } from "./components/permit-root-filter-modal/permit-root-filter-modal.component";

@NgModule({
    imports: [
        AngularSignaturePadModule,
        PermitCreateModule,
        PermitDetailsModule,
        PermitDetailsModule,
        PermitListModule,
        SharedModule,
        SubscriptionsModule,
    ],
    exports: [],
    declarations: [PermitNoAccessComponent, PermitRootComponent, SignPermitsNoticeComponent, SignPersonModalComponent, PermitRootFilterModalComponent],
    providers: [],
})
export class PermitRootModule {}
