import { Injectable } from "@angular/core";
import { PwaState } from "@ha/util/pwa";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { tap } from "rxjs/operators";

import { CertificateCard } from "../models/certificate-card.model";
import { Certificate } from "../models/certificate.model";
import { CertificateService } from "../services/certificate.service";
import { CertificateStateActions } from "./certificate.actions";

export interface CertificateStateModel {
    card: CertificateCard;
    cardDataLoaded: boolean;
    hasCard: boolean;
    certificate: Certificate;
}

@Injectable()
@State<CertificateStateModel>({
    name: "certificate",
    defaults: undefined,
})
export class CertificateState {
    constructor(private certificateService: CertificateService, private store: Store) {}

    @Selector()
    public static certificate(state: CertificateStateModel) {
        return state.certificate;
    }

    @Selector()
    public static card(state: CertificateStateModel) {
        return state.card;
    }

    @Selector()
    public static hasCard(state: CertificateStateModel) {
        return state.hasCard;
    }

    @Selector()
    public static cardDataLoaded(state: CertificateStateModel) {
        return state.cardDataLoaded;
    }

    @Action(CertificateStateActions.GetCard)
    public getCard(ctx: StateContext<CertificateStateModel>) {
        if (this.store.selectSnapshot(PwaState.isOffline)) {
            return;
        }

        return this.certificateService.getCard().pipe(tap((card) => {
            if (card.success) {
                ctx.patchState({
                    card: card,
                    cardDataLoaded: true,
                    hasCard: true,
                });
            } else {
                ctx.patchState({
                    card: undefined,
                    cardDataLoaded: true,
                    hasCard: false,
                });
            }
        }),);
    }

    @Action(CertificateStateActions.GetCertificate)
    public getCertificate(ctx: StateContext<CertificateStateModel>) {
        if (this.store.selectSnapshot(PwaState.isOffline)) {
            return;
        }

        return this.certificateService.getCertificate().pipe(tap((cert) => {
            if (cert.success) {
                ctx.patchState({
                    certificate: cert,
                });
            } else {
                ctx.patchState({
                    certificate: undefined,
                });
            }
        }),);
    }

    @Action(CertificateStateActions.Clear)
    public clear(ctx: StateContext<CertificateStateModel>) {
        ctx.setState(undefined);
    }
}
