import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "qr-code",
    templateUrl: "./qr-code.component.html",
    styleUrls: ["./qr-code.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeComponent {
    @Input() public qrdata: string;
    @Input() public elementType: "img" | "url" | "canvas" | "svg" = "svg";
    @Input() public level: "L" | "M" | "Q" | "H" = "M";
    @Input() public scale = 1;

    public changeElementType(newValue: "img" | "url" | "canvas" | "svg"): void {
        this.elementType = newValue;
    }

    public changeLevel(newValue: "L" | "M" | "Q" | "H"): void {
        this.level = newValue;
    }

    public changeQrdata(newValue: string): void {
        this.qrdata = newValue;
    }

    public changeScale(newValue: number): void {
        this.scale = newValue;
    }
}
