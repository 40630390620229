import { NgSignaturePadOptions, SignaturePadComponent } from "@almothafar/angular-signature-pad";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ConceptType, PersonRole } from "@ha/data/basic";
import { BaseModalComponent } from "@ha/ui/common";
import { untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";

import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { UserAgreementActions } from "../../../user-agreement/states/user-agreement.actions";
import { PermitPerson } from "../../models/permit-person.model";

export interface PermitCreateSignPersonModalComponentData {
    permitId: number;
    role: PersonRole;
    person: PermitPerson;
    isDemo: boolean;
    disabledFireAlarm: string;
    endPostWatchSignature: boolean;
}

@Component({
    templateUrl: "permit-create-sign-person-modal.component.html",
    styleUrls: ["permit-create-sign-person-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitCreateSignPersonModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
    @ViewChild("signature")
    public signaturePad: SignaturePadComponent;

    public signaturePadOptions: NgSignaturePadOptions = undefined;

    public data: PermitCreateSignPersonModalComponentData;

    public hasSignature = false;

    public agreementAccepted = false;

    public get agrementAcceptedBefore() {
        return this.data.person.hasAcceptedUserAgreement;
    }

    public get isValid() {
        return this.hasSignature && (this.agrementAcceptedBefore || this.agreementAccepted || this.data.isDemo);
    }

    constructor(private store: Store, private actions$: Actions, private cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionSuccessful(UserAgreementActions.AcceptUserAgreementOnIssuerUnit)).pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.agreementAccepted = true;
            this.cdr.markForCheck();
        });

        this.actions$.pipe(ofActionSuccessful(UserAgreementActions.RejectAgreementOnIssuerUnit)).pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.agreementAccepted = false;
            this.cdr.markForCheck();
        });
    }

    public ngAfterViewInit(): void {
        this.getScreenSize();
        this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }

    @HostListener("window:resize", ["$event"])
    public getScreenSize() {
        let width = 400;
        if (window.innerWidth < width) {
            width = window.innerWidth;
        }

        this.signaturePadOptions = {
            minWidth: 1,
            maxWidth: 4,
            backgroundColor: "#ffffff",
            canvasWidth: width,
            canvasHeight: width / 2,
        };

        this.cdr.detectChanges();
    }

    public clear() {
        this.signaturePad.clear();
        this.hasSignature = !this.signaturePad.isEmpty();
    }

    public openAgreement(event: MouseEvent): void {
        event.preventDefault();
        const isExternal = this.data.person.concept !== ConceptType.HetaArbeten;
        this.store.dispatch(new UserAgreementActions.OpenUserAgreementOnIssuerUnit(isExternal));
    }

    public save() {
        if (this.isValid) {
            this.store.dispatch(
                new PermitTeamActions.SignPermitWithSignature({
                    permitId: this.data.permitId,
                    personId: this.data.person.personId,
                    role: this.data.role,
                    signatureData: this.signaturePad.toDataURL(),
                    endPostWatchSignature: this.data.endPostWatchSignature
                }),
            );
            this.closeModal();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public drawComplete(event: MouseEvent | Touch): void {
        this.hasSignature = !this.signaturePad.isEmpty();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public drawStart(event: MouseEvent | Touch): void {
    }
}
