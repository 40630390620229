<ng-container *transloco="let t; read:'permit.team.search.scanCard'">
    <div class="modal">
        <div class="modal__content">
            <h2 class="modal__content__heading" [innerHTML]="t('heading')"></h2>

            <div class="modal__content__info" [innerHTML]="t('info')"></div>

            <pmt-card-qr-scanner class="modal__content__scanner" (scanComplete)="scanCompleteHandler($event)"></pmt-card-qr-scanner>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
        </div>
    </div>
</ng-container>
