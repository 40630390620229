<ng-container *transloco="let t; read:'permit.team.removePerson'">
    <div class="modal">
        <div class="modal__content" [innerHtml]="t('text', { name: this.data.person.firstName + ' ' + this.data.person.lastName })">
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("no") }}</button>
            <button class="modal__actions__remove" type="button" (click)="removePerson()">{{ t("yes") }}</button>
        </div>
    </div>
</ng-container>
