import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";
import { Permit } from "../../../permit-create/models/permit.model";
import { Observable, filter, pairwise, startWith } from "rxjs";
import { Select, Store } from "@ngxs/store";
import { ActivatedRoute } from "@angular/router";
import { FixedTemplate } from "../../../permit-create/models/fixed-template.model";
import { CustomerPermitTemplate } from "../../../permit-create/models/customer-permit-template.model";
import { PermitStep, PhotoRequirement } from "@ha/data/basic";
import { PermitCreateActions } from "../../../permit-create/states/permit-create.actions";
import { untilDestroyed } from "@ngneat/until-destroy";
import { PermitStepState, PermitStepStateModel } from "../../../permit/states/permit-step.state";
import { WorkplaceDetails } from "../../../permit-create/models/workplace-details.model";

export interface PermitValidityPeriodModalComponentData {
    permitId: number;
}

@Component({
    templateUrl: "./permit-validity-period-modal.component.html",
    styleUrls: ["./permit-validity-period-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitValidityPeriodModalComponent extends BaseModalComponent implements OnInit {
    public data: PermitValidityPeriodModalComponentData;

    @Input() public permitId: number | undefined;

    @Select(PermitCreateSelectors.fixedTemplateDetails)
    public fixedTemplate$: Observable<FixedTemplate>;

    @Select(PermitCreateSelectors.customerPermitTemplate)
    public customerTemplate$: Observable<CustomerPermitTemplate>;

    @Select(PermitCreateSelectors.permit)
    public permit$: Observable<Permit>;

    @Select(PermitCreateSelectors.workplaceDetails)
    public workplaceDetails$: Observable<WorkplaceDetails>;

    public PermitStep = PermitStep;
    public PhotoRequirement = PhotoRequirement;
    public canProceed = true;

    constructor(private store: Store, private activatedRoute: ActivatedRoute) {
        super();
    }

    public ngOnInit(): void {
        this.store.dispatch(new PermitCreateActions.GetActiveFixedTemplate());

        this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
            const permitId = parseInt(params.permitId as string);
            if (isNaN(permitId) || !params.permitId) {
                //this.store.dispatch(new PermitStepActions.StartCreate());
            }
        });

        this.store.select(PermitStepState).pipe(
            untilDestroyed(this),
            startWith(undefined),
            pairwise(),
            filter(([previousState, currentState]: PermitStepStateModel[]) => {
                // Cancel if no permit id
                if (!currentState.permitId) {
                    return false;
                }

                // If previous state was empty we want to get permit
                if (!previousState) {
                    return true;
                }

                // If permit id have changed we want to get permit
                if (currentState.permitId !== previousState.permitId) {
                    return true;
                }

                return false;
            }),
        ).subscribe(([, currentSate]: PermitStepStateModel[]) => {
            this.store.dispatch(new PermitCreateActions.GetPermit(currentSate.permitId));
        });
    }

    public save(): void {
        var value = +(<HTMLInputElement>document.getElementsByClassName("control__box__input")[0]).value;
        this.canProceed = false;
        this.store.dispatch(new PermitCreateActions.ExtendPermit(value));
        this.closeModal();
    }
}

