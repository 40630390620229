import { ChangeDetectionStrategy, Component } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { Application, ApplicationListModalComponent, ApplicationListModalComponentData } from "@ha/ui/navigation";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { SettingsState } from "../../states/settings.state";

@Component({
    selector: "app-no-access",
    templateUrl: "./no-access.component.html",
    styleUrls: ["./no-access.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoAccessComponent {
    @Select(SettingsState.applicationList)
    public applicationList$: Observable<Application[]>;

    constructor(private modalService: HaModalService) {
    }

    public openApplicationModal(applicationList: Application[]): void {
        this.modalService.open(ApplicationListModalComponent, true, <ApplicationListModalComponentData>{ applicationList: applicationList });
    }
}
