import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, Store } from "@ngxs/store";

import { CommentDto } from "../../../models/permit-comments-dto";
import { PermitDetailsActions } from "../../../states/permit-details.action";

export interface PermitCommentRemoveModalComponentData {
    comment: CommentDto;
}

@Component({
    templateUrl: "permit-comment-remove-modal.component.html",
    styleUrls: ["permit-comment-remove-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitCommentRemoveModalComponent extends BaseModalComponent implements OnInit {
    public data: PermitCommentRemoveModalComponentData;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionCompleted(PermitDetailsActions.RemoveComment), untilDestroyed(this)).subscribe(() => {
            this.closeModal();
        });
    }


    public removeComment() {
        this.store.dispatch(new PermitDetailsActions.RemoveComment(this.data.comment));
    }

    public close() {
        this.closeModal();
    }
}
