<div *transloco="let t; read 'personalPanel'" class="mobile-personal-panel mobile-personal-panel--expanded">
    <div class="mobile-personal-panel__divider"></div>
    <div class="mobile-personal-panel__content">
        <div class="mobile-personal-panel__content__link-list">
            <a *ngIf="(isExternal$ | async) !== true" (click)="toggleMenu();" [routerLink]="applicationPaths.User.Root"
               class="mobile-personal-panel__content__link-list__item mobile-personal-panel__content__link-list__item--profile">{{t("myProfile")}}</a>
            <a (click)="toggleMenu();" [routerLink]="applicationPaths.SignOut" aria-label="sign out"
               class="mobile-personal-panel__content__link-list__item mobile-personal-panel__content__link-list__item--sign-out">{{t("signOut")}}
            </a>
        </div>
        <div class="mobile-personal-panel__content__lang-selector">
            <ui-language [languages]="uiLanguages$ | async" [currentLanguage]="currentLanguage$ | async" (languageChanged)="languageChanged($event)">
            </ui-language>
        </div>
    </div>
</div>
