import { ChangeDetectionStrategy, Component, Inject, Injectable, InjectionToken } from "@angular/core";
import { SupportConfiguration } from "../models/support-configuration.model";

export const SUPPORT_CONFIG = new InjectionToken<SupportConfiguration>("SupportConfiguration");

@Injectable({ providedIn: "root" })
@Component({
    templateUrl: "./support.component.html",
    styleUrls: ["./support.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportComponent {
    public mail;
    public mailUrl;
    public phone;
    public phoneUrl;

    constructor(@Inject(SUPPORT_CONFIG) private supportConfig: SupportConfiguration) {
        this.mail = this.supportConfig.Mail;
        this.mailUrl = this.supportConfig.MailUrl;
        this.phone = this.supportConfig.Phone;
        this.phoneUrl = this.supportConfig.PhoneUrl;
    }
}
