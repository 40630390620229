import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PhotoRequirement } from "@ha/data/basic";
import { BaseValidationResult } from "@ha/ui/forms";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { produce } from "immer";
import { Observable, of, tap } from "rxjs";

import { PermitStepSelectors } from "../../permit/states/permit-step.selectors";
import { PermitPhoto } from "../models/permit-photo.model";
import { PermitPhotoService } from "../services/permit-photo.service";
import { PermitPhotoActions } from "./permit-photo.actions";

export interface PermitPhotoStateModel {
    photos: PermitPhoto[];
    photoRequirement: PhotoRequirement;
}

@Injectable()
@State<PermitPhotoStateModel>({
    name: "permitPhotoplace",
    defaults: {
        photos: [],
        photoRequirement: undefined,
    }
})
export class PermitPhotoState {
    constructor(private store: Store, private permitPhotoService: PermitPhotoService, private activatedRoute: ActivatedRoute) {}

    @Action(PermitPhotoActions.GetPermitPhotos)
    public getPermitPhotos(ctx: StateContext<PermitPhotoStateModel>): Observable<PermitPhoto[]> {
        const permitId = this.store.selectSnapshot(PermitStepSelectors.permitId);
        if (permitId) {
            return this.permitPhotoService.getPermitPhotos(permitId).pipe(tap((result) => {
                ctx.setState(produce(ctx.getState(), draft => {
                    draft.photos = result;
                }));
            }));
        }

        return of();
    }

    @Action(PermitPhotoActions.GetPermitPhotoRequirement)
    public getPhotoRequirement(ctx: StateContext<PermitPhotoStateModel>): Observable<PhotoRequirement> {
        const permitId = this.store.selectSnapshot(PermitStepSelectors.permitId);

        return this.permitPhotoService.getPermitPhotoRequirement(permitId).pipe(tap((result) => {
            ctx.setState(produce(ctx.getState(), draft => {
                draft.photoRequirement = result;
            }));
        }));
    }

    @Action(PermitPhotoActions.SavePermitPhoto)
    public savePermitPhoto(ctx: StateContext<PermitPhotoStateModel>, action: PermitPhotoActions.SavePermitPhoto): Observable<boolean> {
        const permitId = this.store.selectSnapshot(PermitStepSelectors.permitId);
        return this.permitPhotoService.savePermitPhoto(permitId, action.photo).pipe(tap((result) => {
            if (result) {
                this.store.dispatch(new PermitPhotoActions.GetPermitPhotos());
            }
        }));
    }

    @Action(PermitPhotoActions.RemovePermitPhoto)
    public removePermitPhoto(ctx: StateContext<PermitPhotoStateModel>, action: PermitPhotoActions.RemovePermitPhoto): Observable<BaseValidationResult> {
        return this.permitPhotoService.removePhoto(action.fileName).pipe(tap((result) => {
            if (result) {
                this.store.dispatch(new PermitPhotoActions.GetPermitPhotos());
            }
        }));
    }
}
