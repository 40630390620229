import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable, take } from "rxjs";

import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-valid-time",
    templateUrl: "./valid-time.component.html",
    styleUrls: ["./valid-time.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidTimeComponent implements OnInit {
    @Input() public default: number;
    @Input() public comment: string;

    @Select(PermitCreateSelectors.isPremium)
    public isPremium$: Observable<boolean>;

    public validDateTime: string;
    public selectedValue: number;
    public canProceed = true;

    constructor(private store: Store, private actions$: Actions) { }

    public ngOnInit(): void {
        this.selectedValue = this.default;
        this.validDateTime = this.getDateTime(this.selectedValue);

        this.actions$.pipe(ofActionSuccessful(PermitCreateActions.StartPermit))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                this.store.dispatch(new PermitStepActions.GetCurrentStep());
            });
    }

    public getDateTime(time: number): string {
        const newDate = new Date(new Date().setHours(new Date().getHours() + time));
        return newDate.toJSON();
    }

    public setValue(value: number): void {
        if (this.store.selectSnapshot(PermitCreateSelectors.isPremium) === true) {
            this.canProceed = value <= this.default;
        }

        this.selectedValue = value;
        this.validDateTime = this.getDateTime(this.selectedValue);
    }

    public start(): void {
        const isDemo = this.store.selectSnapshot(PermitCreateSelectors.isDemo);

        // Set valid time and start permit
        if (isDemo) {
            this.store.dispatch(new PermitCreateActions.EndDemoOrNotFlammableHotWork());
        } else {
            this.store.dispatch(new PermitCreateActions.StartPermit(this.selectedValue));
        }
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards());
    }
}
