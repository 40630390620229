<div class="create" *transloco="let t; read:'permit.team.createPerson'">
    <ng-container *ngIf="(creatingPerson$ | async) else create">
        <div class="create__information">Skapar användare...</div>
    </ng-container>
    <ng-template #create>
        <div *ngIf="!form && !createdPersonName" class="create__information">
            <div [innerHtml]="t('preInformation.line1')"></div>
            <div [innerHtml]="t('preInformation.line2')"></div>
            <div class="create__information__button" (click)="start()">{{t('preInformation.start')}}</div>
        </div>

        <form *ngIf="form && !createdPersonName" [formGroup]="form" [ngxsFormDebounce]="0" ngxsForm="permitTeam.newPersonForm" class="create__form">
            <ha-select class="root__form__select" formControlName="conceptType" [label]="t('chooseConceptCountry')" [options]="options">
            </ha-select>

            <ng-container *ngIf="form.get('conceptType').value">
                <ha-input class="create__form__field" formControlName="firstName" [label]="t('firstName')"></ha-input>
                <ha-input class="create__form__field" formControlName="lastName" [label]="t('lastName')"></ha-input>
                <ha-input class="create__form__field" formControlName="birthDate" isDateType="true" type="date" [label]="t('birthDate')" [max]="nowDate">
                </ha-input>
                <ha-input class="create__form__field" formControlName="email" [label]="t('email')"></ha-input>
                <ha-input class="create__form__field" formControlName="mobile" [label]="t('mobile')"></ha-input>
                <ng-container *ngIf="form.get('certificateIdentifier') !== null">
                    <ha-input *ngIf="isActiveConcept(ConceptType.BrandfarligaArbeten)" class="create__form__field" formControlName="certificateIdentifier"
                              [label]="t('certificateIdentifier')"></ha-input>
                    <ha-input *ngIf="!isActiveConcept(ConceptType.BrandfarligaArbeten)" class="create__form__field" formControlName="certificateIdentifier"
                              [label]="t('cardNumber')"></ha-input>
                </ng-container>
                <span *ngIf="this.activeConcept() > 2" class="create__form__info" [innerHTML]="t('otherConceptInfo')"></span>
                <ha-input class="create__form__field" formControlName="expirationDate" isDateType="true" type="date" [label]="t('expirationDate')"
                          [min]="nowDate" [max]="maxExpirationDate">
                </ha-input>

                <validation-messages *ngIf="(newPersonFormResponse$ | async)" read="permit.team.createPerson" [form]="form"
                                     [validationResult]="(newPersonFormResponse$ | async)?.validationResult"></validation-messages>

                <button class="create__form__button" [ngClass]="form.valid ? 'create__form__button--create' : 'create__form__button--disabled'"
                        [disabled]="!form.valid" (click)="createPerson()">{{t('create')}}</button>
            </ng-container>
        </form>

        <div *ngIf="createdPersonName" class="create__information">
            <div [innerHtml]="t('postInformation.line', { name: createdPersonName })"></div>
            <div class="create__information__button" (click)="start()">{{t('postInformation.restart')}}</div>
        </div>
    </ng-template>
</div>
