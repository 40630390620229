<ng-container *transloco="let t; read:'permit.changeIssuer'">
    <div class="modal">
        <div *ngIf="(isPremium$ | async) !== undefined && (isPremium$ | async) === true" class="modal__content">
            <h2 class="modal__content__heading">{{t("heading")}}</h2>
            <pmt-permit-change-issuer-premium [permitId]="data.permitId"></pmt-permit-change-issuer-premium>
        </div>

        <div *ngIf="(isPremium$ | async) !== undefined && (isPremium$ | async) === false" class="modal__content">
            <h2 class="modal__content__heading">{{t("heading")}}</h2>
            <pmt-find-issuer [permitId]="data.permitId"></pmt-find-issuer>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__change" [class.modal__actions__change--disabled]="(issuerSelected$ | async) === undefined" type="button"
                    (click)="changeIssuer()">{{t("change") }}</button>
        </div>
    </div>
</ng-container>
