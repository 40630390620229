import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

import { FilterCheckboxState } from "../../../models/filter-checkbox-state";

@UntilDestroy()
@Component({
    selector: "prm-filter-menu-checkbox",
    templateUrl: "./filter-menu-checkbox.component.html",
    styleUrls: ["./filter-menu-checkbox.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuCheckboxComponent {
    @Input() public isChecked: FilterCheckboxState | boolean | null;

    public FilterCheckboxState = FilterCheckboxState;

    public get showTick() {
        return this.isChecked === true || this.isChecked === FilterCheckboxState.checked;
    }

    public get showDot() {
        return this.isChecked !== true && this.isChecked === FilterCheckboxState.indeterminate;
    }
}
