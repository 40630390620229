import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { CertificateCard } from "../models/certificate-card.model";
import { Certificate } from "../models/certificate.model";

@Injectable({
    providedIn: "root",
})
export class CertificateService {
    constructor(private httpClient: HttpClient) {}

    public getCard() {
        return this.httpClient.get<CertificateCard>("/api/CurrentUser/Card");
    }

    public getCertificate() {
        return this.httpClient.get<Certificate>("/api/CurrentUser/Certificate");
    }
}
