<div class="root" *transloco="let t; read:'permit'">
    <h2 class="root__question-text" [innerHTML]="t('postWorkMonitoringTime.heading')"></h2>

    <div *ngIf="comment" class="root__comment" [innerHTML]="comment"></div>

    <pmt-time-stepper [label]="t('postWorkMonitoringTime.input')" [default]="default" [minValue]="default" (selectedValue)="setValue($event)" [smallStep]="15"
                      [bigStep]="60">
    </pmt-time-stepper>

    <div class="root__actions">
        <button class="root__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
        <button class="root__actions__continue" type="button" (click)="continue()" [class.root__actions__continue--disabled]="!canProceed">
            {{ t("next") }}
        </button>
    </div>
</div>
