import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ConceptType, PersonRole } from "@ha/data/basic";
import { SelectionListItem } from "@ha/ui/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";

import { CreateCertificateAndPersonResponse } from "../../models/create-certificate-person.model";
import { PermitTeamActions } from "../../states/permit-team.action";
import { PermitTeamSelectors } from "../../states/permit-team.selectors";


@UntilDestroy()
@Component({
    selector: "pmt-create-new-person",
    templateUrl: "create-new-person.component.html",
    styleUrls: ["create-new-person.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateNewPersonComponent implements OnInit, OnDestroy {
    public ConceptType = ConceptType;

    @Input()
    public permitId: number;

    @Input()
    public role: PersonRole;

    @Select(PermitTeamSelectors.creatingPerson)
    public creatingPerson$: Observable<boolean>;

    @Select(PermitTeamSelectors.newPersonFormResponse)
    public newPersonFormResponse$: Observable<CreateCertificateAndPersonResponse>;

    public form: UntypedFormGroup;

    public createdPersonName: string;

    public maxExpirationDate = new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString().split("T")[0];

    public nowDate = new Date().toISOString().split("T")[0];

    public get options(): SelectionListItem[] {
        return [
            {
                value: ConceptType.BrandfarligaArbeten,
                langKey: "permit.team.createPerson.concepts.sweden.brandfarligaArbeten"
            }, {
                value: ConceptType.Denmark,
                langKey: "permit.team.createPerson.concepts.denmark"
            }, {
                value: ConceptType.Finland,
                langKey: "permit.team.createPerson.concepts.finland"
            }, {
                value: ConceptType.Norway,
                langKey: "permit.team.createPerson.concepts.norway"
            }];
    }

    public get title(): string {
        if (this.role == PersonRole.FireWatcher) {
            return "headings.firewatcher";
        } else if (this.role == PersonRole.HotWorker) {
            return "headings.hotworker";
        } else if (this.role == PersonRole.PermitIssuer) {
            return "headings.permitissuer";
        } else if (this.role == PersonRole.PostFireWatcher) {
            return "headings.postfirewatcher";
        } else {
            return "error";
        }
    }

    private conceptTypeSubscription: Subscription;

    constructor(private fb: UntypedFormBuilder, private store: Store, private actions$: Actions, private ref: ChangeDetectorRef) {
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionSuccessful(PermitTeamActions.CreateCertificateAndStagePerson))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const response = this.store.selectSnapshot(PermitTeamSelectors.newPersonFormResponse);
                if (response.success) {
                    this.createdPersonName = `${this.form.get("firstName").value as string} ${this.form.get("lastName").value as string}`;
                    this.store.dispatch(new PermitTeamActions.ClearCreatePersonFormData()).pipe(untilDestroyed(this)).subscribe(_ => this.form.reset());
                }

                this.ref.detectChanges();
            });
    }

    public ngOnDestroy(): void {
        if (this.conceptTypeSubscription) {
            this.conceptTypeSubscription.unsubscribe();
        }
    }

    public createPerson() {
        if (this.form.valid) {
            this.store.dispatch(new PermitTeamActions.CreateCertificateAndStagePerson(this.permitId, this.role));
        } else {
            this.form.markAsDirty();
        }
    }

    public activeConcept() {
        return this.getCurrentActiveConcept();
    }

    public isActiveConcept(concept: ConceptType) {
        return concept == this.getCurrentActiveConcept();
    }

    public start() {
        this.createForm();
    }

    private createForm() {
        this.createdPersonName = undefined;

        this.form = this.fb.group({
            conceptType: [ConceptType.BrandfarligaArbeten, Validators.required],
            birthDate: ["", Validators.required],
            expirationDate: ["", Validators.required],
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            email: ["", Validators.required],
            mobile: ["", Validators.required]
        });

        if (!this.conceptTypeSubscription) {
            this.conceptTypeSubscription = this.form.get("conceptType").valueChanges
                .pipe(untilDestroyed(this))

                .subscribe((concept: ConceptType) => {
                    if (concept == ConceptType.BrandfarligaArbeten || concept == ConceptType.Norway || concept == ConceptType.Finland) {
                        if (this.form.get("certificateIdentifier") == null) {
                            this.form.addControl("certificateIdentifier", new UntypedFormControl("", Validators.required));
                        }
                    } else if (concept == ConceptType.Denmark) {
                        if (this.form.get("certificateIdentifier") !== null) {
                            this.form.removeControl("certificateIdentifier");
                        }
                    }
                });
        }
    }

    private getCurrentActiveConcept(): ConceptType {
        if (!this.form || !this.form.get("conceptType").value) {
            return undefined;
        }

        return this.form.get("conceptType").value as ConceptType;
    }
}
