import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Workplace } from "@ha/data/basic";
import { Store } from "@ngxs/store";
import { IssuerStructureNode } from "../../../models/issuer-structure-node.model";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";

@Component({
    selector: "pmt-workplace-search-result",
    templateUrl: "workplace-search-result.component.html",
    styleUrls: ["workplace-search-result.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class WorkplaceSearchResultComponent {
    @Input() public searchResult: (IssuerStructureNode | Workplace);

    constructor(private store: Store) { }

    public isStructureNode(node: IssuerStructureNode | Workplace): IssuerStructureNode {
        if ("structureNodeId" in node) {
            return node;
        }

        return undefined;
    }

    public isWorkplace(node: IssuerStructureNode | Workplace): Workplace {
        if ("isDefaultWorkplace" in node) {
            return node;
        }

        return undefined;
    }

    public stepInto(result: IssuerStructureNode | Workplace): void {
        const node = this.isStructureNode(result);
        if (node !== undefined) {
            this.store.dispatch(new PermitWorkplaceActions.ToggleSelectedNode(node));
        }

        const workplace = this.isWorkplace(result);
        if (workplace !== undefined) {
            this.store.dispatch(new PermitWorkplaceActions.ToggleSelectedWorkplace(workplace));
        }
    }
}
