export enum PermitPublicStatus {
    none = 0,
    underPreparation = 1,
    prepared = 2,
    planned = 3,
    readyForSigning = 4,
    ongoing = 5,
    expiredNotSigned = 6,
    completed = 7,
    pendingArchiving = 8,
}
