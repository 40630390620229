import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";
import { take } from "rxjs";

import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitCreateActions } from "../../states/permit-create.actions";

@UntilDestroy()
@Component({
    selector: "pmt-post-work-monitoring-time",
    templateUrl: "./post-work-monitoring-time.component.html",
    styleUrls: ["./post-work-monitoring-time.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostWorkMonitoringTimeComponent implements OnInit {
    @Input() public default: number;
    @Input() public comment: string;

    public selectedValue: number;
    public canProceed = true;

    constructor(private store: Store, private actions$: Actions) {}

    public ngOnInit(): void {
        this.selectedValue = this.default;

        this.actions$.pipe(ofActionSuccessful(PermitCreateActions.SetPostWorkMonitoringTime))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                this.store.dispatch(new PermitStepActions.GoForward());
            });
    }

    public setValue(value: number): void {
        if (value < this.default) {
            this.canProceed = false;
        } else {
            this.canProceed = true;
        }

        this.selectedValue = value;
    }

    public continue(): void {
        if (this.selectedValue === 0) {
            this.selectedValue = 60;
        }
        this.store.dispatch(new PermitCreateActions.SetPostWorkMonitoringTime(this.store.selectSnapshot(PermitStepSelectors.permitId), this.selectedValue));
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards());
    }
}
