import { Selector } from "@ngxs/store";

import { CertificateStatus } from "../../../permit-create/models/certificate-status.enum";
import { IssuerDto } from "../../models/change-issuer-dto";
import { PermitChangeIssuerState, PermitChangeIssuerStateModel } from "./change-issuer.state";


export class PermitChangeIssuerSelectors {
    @Selector([PermitChangeIssuerState])
    public static availableIssuers(state: PermitChangeIssuerStateModel): IssuerDto[] {
        return state?.availableIssuers;
    }

    @Selector([PermitChangeIssuerState])
    public static canChangeIssuer(state: PermitChangeIssuerStateModel): boolean {
        return state?.canChangeIssuer;
    }

    @Selector([PermitChangeIssuerState])
    public static issuerToBe(state: PermitChangeIssuerStateModel): IssuerDto {
        return state?.issuerToBe;
    }

    @Selector([PermitChangeIssuerState])
    public static issuerChangeInProgress(state: PermitChangeIssuerStateModel): boolean {
        return state?.issuerToBe != undefined;
    }

    @Selector([PermitChangeIssuerState])
    public static selectedNewIssuer(state: PermitChangeIssuerStateModel): IssuerDto {
        return state?.selectedNewIssuer;
    }

    @Selector([PermitChangeIssuerState])
    public static searchResult(state: PermitChangeIssuerStateModel): IssuerDto {
        return state?.searchResult;
    }

    @Selector([PermitChangeIssuerState])
    public static searchResultStatus(state: PermitChangeIssuerStateModel): CertificateStatus {
        return state?.searchResultStatus;
    }

    @Selector([PermitChangeIssuerState])
    public static searching(state: PermitChangeIssuerStateModel): boolean {
        return state?.searching;
    }

    @Selector([PermitChangeIssuerState])
    public static isPremium(state: PermitChangeIssuerStateModel): boolean {
        return state?.isPremium;
    }
}
