import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { EpiserverStateActions } from "@ha/feature/episerver";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, ofActionDispatched, Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";

import { KnowledgeBankSearchResult, KnowledgeBankSearchResultItem } from "../../models/knowledge-bank-search-result";
import { KnowledgeBankSearchState } from "../../state/knowledge-bank-search.state";
import { KnowledgeBankActions } from "./../../state/knowledge-bank.actions";

@UntilDestroy()
@Component({
    selector: "knowledge-bank-search-results",
    templateUrl: "./knowledge-bank-search-results.component.html",
    styleUrls: ["./knowledge-bank-search-results.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankSearchResultsComponent implements OnInit, OnDestroy {
    @Select(KnowledgeBankSearchState.result)
    public result$: Observable<KnowledgeBankSearchResult>;

    @Select(KnowledgeBankSearchState.canLoadMore)
    public canLoadMore$: Observable<boolean>;

    @Select(KnowledgeBankSearchState.percentLoaded)
    public percentLoaded$: Observable<number>;

    @Select(KnowledgeBankSearchState.itemsLeft)
    public itemsLeft$: Observable<number>;

    private isSearchingUnsubscribe = new Subject();
    public isSearching$ = new Subject<boolean>();

    constructor(private store: Store, private actions$: Actions, private translocoService: TranslocoService) {}

    public ngOnInit(): void {
        this.actions$.pipe(ofActionDispatched(KnowledgeBankActions.Search), untilDestroyed(this)).subscribe(() => {
            this.isSearching$.next(true);
        });
        this.actions$.pipe(ofActionCompleted(KnowledgeBankActions.Search), untilDestroyed(this)).subscribe(() => {
            this.isSearching$.next(false);
        });
    }

    public ngOnDestroy(): void {
        this.isSearchingUnsubscribe.next(undefined);
        this.isSearchingUnsubscribe.complete();
    }

    public clickTrack(result: KnowledgeBankSearchResult, item: KnowledgeBankSearchResultItem): void {
        this.store.dispatch(new EpiserverStateActions.SearchClickTrack(result.query, item.hitId, result.trackingId));
    }

    public loadMore(): void {
        this.store.dispatch(new KnowledgeBankActions.SearchLoadMore());
    }

    public getButtonText(itemsLeft: number): string {
        let langKey = "knowledgeBank.search.results.";
        langKey += itemsLeft === 0 ? "noMoreToLoad" : itemsLeft === 1 ? "loadOneMoreItem" : "loadMoreItems";

        return this.translocoService.translate(langKey, { itemsLeft: itemsLeft });
    }
}
