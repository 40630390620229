export namespace PermitPhotoActions {

    export class SavePermitPhoto {
        public static readonly type = "[PermitPhoto] SavePermitPhoto";
        constructor(public permitId: number, public photo: File) {}
    }

    export class RemovePermitPhoto {
        public static readonly type = "[PermitPhoto] RemovePermitPhoto";
        constructor(public fileName: string) {}
    }

    export class GetPermitPhotos {
        public static readonly type = "[PermitPhoto] GetPermitPhotos";
    }

    export class GetPermitPhotoRequirement {
        public static readonly type = "[PermitPhoto] GetPermitPhotoRequirement";
    }
}
