import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CoreInterceptor implements HttpInterceptor {
    public languageSelector!: (state: unknown, ...states: unknown[]) => string;

    constructor(private store: Store) {}

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let activeLang: string;

        if (this.languageSelector) {
            activeLang = this.store.selectSnapshot(this.languageSelector) ?? "sv";
        } else {
            activeLang = "sv";
        }

        req = req.clone({ headers: req.headers.set("accept-language", activeLang) });

        return next.handle(req);
    }
}
