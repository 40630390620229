<div class="root" *transloco="let t; read:'support'">
    <div class="root__intro" [innerHTML]="t('intro')"></div>

    <div class="root__contact-item root__contact-item--open">
        <div class="root__contact-item__header">
            <div class="root__contact-item__header__icon"></div>
            <div class="root__contact-item__header__text" [innerHTML]="t('openHeading')"></div>
        </div>
        <div class="root__contact-item__text" [innerHTML]="t('openText')"></div>
    </div>
    <div class="root__contact-item root__contact-item--phone">
        <div class="root__contact-item__header">
            <div class="root__contact-item__header__icon"></div>
            <div class="root__contact-item__header__text" [innerHTML]="t('phoneHeading')"></div>
        </div>
        <div class="root__contact-item__text">
            <a class="root__contact-item__text__anchor" [href]="phoneUrl">{{ phone }}</a>
        </div>
    </div>
    <div class="root__contact-item root__contact-item--mail">
        <div class="root__contact-item__header">
            <div class="root__contact-item__header__icon"></div>
            <div class="root__contact-item__header__text" [innerHTML]="t('mailHeading')"></div>
        </div>
        <div class="root__contact-item__text">
            <a class="root__contact-item__text__anchor" [href]="mailUrl">{{ mail }}</a>
        </div>
    </div>
</div>
