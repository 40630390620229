import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";

import { BreadcrumbLink } from "../../models/breadcrumb-link.model";
import { BreadcrumbSelectors } from "../../states/breadcrumb.selectors";

@Component({
    selector: "breadcrumbs",
    templateUrl: "./breadcrumbs.component.html",
    styleUrls: ["./breadcrumbs.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
    @Select(BreadcrumbSelectors.breadcrumbs)
    public breadcrumbs$: Observable<BreadcrumbLink[]> | undefined;
}
