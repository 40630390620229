import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { AutocompleteListItem } from "../models/autocomplete-item";

@Injectable({ providedIn: "root" })
export class EpiserverFindService {
    constructor(private httpClient: HttpClient) {}

    public autocomplete(query: string): Observable<AutocompleteListItem[]> {
        const options = {
            params: {
                query,
            },
        };

        return this.httpClient.get<AutocompleteListItem[]>("/api/cms/search/autocomplete", options);
    }

    public clickTrack(query: string, hitId: string, trackId: string) {
        if (!query) {
            return of(undefined);
        }

        const options = {
            params: {
                query,
                hitId,
                trackId,
            },
        };

        return this.httpClient.get<AutocompleteListItem[]>("/api/cms/search/track", options);
    }
}
