import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PwaState } from "@ha/util/pwa";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";

import { SidebarState } from "../../states/sidebar.state";

@Component({
    selector: "ha-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
    @Select(SidebarState.personalPanelExpanded)
    public personalPanelExpanded$!: Observable<boolean>;

    @Select(PwaState.isMobile)
    public isMobile$!: Observable<boolean>;
}
