export function toCamelCase(s: string): string {
    return s.charAt(0).toLowerCase() + s.slice(1);
}

declare global {
    interface String {
        toCamelCase(): string;
    }
}

String.prototype.toCamelCase = function () {
    return toCamelCase(this as string);
};
