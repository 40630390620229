<li *ngIf="item" class="item" [class]="'item--level-' + level">
    <div class="item__header" [class.item__header--active]="isActive">
        <a class="item__header__anchor" [routerLink]="item.url" [innerHTML]="item.name"> </a>
        <div *ngIf="item.children.length > 0" class="item__header__expander" [class.item__header__expander--expanded]="expanded"
             [class.item__header__expander--disabled]="childIsActive(item.url)" (click)="toggleExpand()">
        </div>
    </div>


    <ul *ngIf="item.children.length > 0" class="item__child-list" [class.item__child-list--expanded]="expanded">
        <knowledge-bank-menu-item *ngFor="let childItem of item.children" [item]="childItem" [level]="2"></knowledge-bank-menu-item>
    </ul>
</li>
