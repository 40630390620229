import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { Store } from "@ngxs/store";

import { SubscriptionInvitation } from "../../models/subscription-invitation";
import { SubscriptionsActions } from "../../states/subscriptions.actions";

export interface SubscriptionInviteAnswerComponentData {
    invitation: SubscriptionInvitation;
}

@Component({
    templateUrl: "./subscription-invitation-answer.component.html",
    styleUrls: ["./subscription-invitation-answer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionInvitationAnswerComponent extends BaseModalComponent {
    public data!: SubscriptionInviteAnswerComponentData;

    constructor(private store: Store) {
        super();
    }

    public accept(): void {
        this.store.dispatch(new SubscriptionsActions.AnswerInvitation(this.data.invitation, true));
        this.closeModal();
    }

    public deny(): void {
        this.store.dispatch(new SubscriptionsActions.AnswerInvitation(this.data.invitation, false));
        this.closeModal();
    }
}

