import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Actions, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CertificateStatus } from "../../../../permit-create/models/certificate-status.enum";
import { IssuerDto } from "../../../models/change-issuer-dto";
import { PermitChangeIssuerActions } from "../../../states/change-issuer-state/change-issuer.action";
import { PermitChangeIssuerSelectors } from "../../../states/change-issuer-state/change-issuer.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-find-issuer",
    templateUrl: "find-issuer.component.html",
    styleUrls: ["find-issuer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitFindIssuerComponent {
    @Input() public permitId: number;

    @Select(PermitChangeIssuerSelectors.searchResult)
    public searchResult$: Observable<IssuerDto>;

    @Select(PermitChangeIssuerSelectors.searchResultStatus)
    public searchResultStatus$: Observable<CertificateStatus>;

    @Select(PermitChangeIssuerSelectors.searching)
    public searching$: Observable<boolean>;

    @Select(PermitChangeIssuerSelectors.selectedNewIssuer)
    public selectedNewIssuer$: Observable<IssuerDto>;

    public query$: Observable<string>;

    public CertificateStatus = CertificateStatus;

    constructor(private store: Store, private actions$: Actions) {
    }

    public search(query: string) {
        this.store.dispatch(new PermitChangeIssuerActions.SearchIssuer(this.permitId, query));
    }

    public toggleIssuer(issuer: IssuerDto) {
        this.store.dispatch(new PermitChangeIssuerActions.SelectIssuer(issuer.certificateId));
    }
}
