import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { SpecialStepInformation } from "../../models/fixed-template.model";
import { WorkplaceDetails } from "../../models/workplace-details.model";
import { PermitCreateActions } from "../../states/permit-create.actions";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-fire-alarm",
    templateUrl: "./permit-fire-alarm.component.html",
    styleUrls: ["./permit-fire-alarm.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitFireAlarmComponent {
    @Select(PermitCreateSelectors.workplaceDetails)
    public workplaceDetails$: Observable<WorkplaceDetails>;

    @Select(PermitCreateSelectors.fireAlarmInformation)
    public fireAlarmInformation$: Observable<SpecialStepInformation>;

    @Input() public comment: string;

    public answerIsYes: boolean | undefined = undefined;

    public alertConfirmed = false;

    constructor(private fb: NonNullableFormBuilder, private store: Store, private actions$: Actions) {}

    public form = this.fb.group({
        fireAlarm: ["", Validators.required],
    });

    public get canProceed(): boolean {
        if (this.answerIsYes === false) {
            return true;
        } else if (this.answerIsYes === true) {
            return this.alertConfirmed && this.form.valid;
        }

        return false;
    }

    public continue(): void {
        if (this.answerIsYes) {
            this.actions$.pipe(ofActionSuccessful(PermitCreateActions.SetFireAlarm)).pipe(untilDestroyed(this), take(1))
                .subscribe(() => {
                    this.store.dispatch(new PermitStepActions.GoForward());
                });

            this.store.dispatch(
                new PermitCreateActions.SetFireAlarm(
                    this.store.selectSnapshot(PermitStepSelectors.permitId),
                    this.form.value.fireAlarm,
                ),
            );
        } else {
            this.store.dispatch(new PermitStepActions.GoForward());
        }
    }

    public select(answerIsYes: boolean) {
        this.answerIsYes = answerIsYes;
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards());
    }

    public setAlertConfirmed(confirmed: boolean) {
        this.alertConfirmed = confirmed;
    }
}
