<ng-container *transloco="let t; read:'permit.filters'">
    <div class="modal">
        <div class="modal__content">
            <div class="modal__content__heading">
                {{ t("title") }}
                <span *ngIf="filterCount > 0" class="modal__content__filter-reset" (click)="resetFilter()" [innerHTML]="filterCount"></span>
            </div>

            <div *ngIf="workplaces$ | async as workplaces" class="modal__content__details">
                <div *ngFor="let workplace of workplaces">
                    <label class="modal__content__details__workplace">
                        <div class="modal__content__details__workplace__item">
                            <div class="modal__content__details__workplace__item--name" [innerHTML]="workplace.name ?? t('basePermit')"></div>
                            <div class="modal__content__details__workplace__item--count" [innerHTML]="workplace.availablePermits"></div>
                        </div>
                        <div class="item__content">
                            <ha-checkbox [(ngModel)]="workplace.isSelected" (change)="onChange()" (ngModelChange)="select()"></ha-checkbox>
                        </div>
                    </label>
                    <hr class="modal__content__details__workplace--divider">
                </div>
            </div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="close()">{{ t("cancel") }}</button>
        </div>
    </div>
</ng-container>
