import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { VimeoMetaData } from "./vimeo-meta-data";

@Component({
    selector: "vimeo-video-player",
    templateUrl: "./vimeo-video-player.component.html",
    styleUrls: ["./vimeo-video-player.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VimeoVideoPlayerComponent {
    @Input()
    public metaData: VimeoMetaData;

    public isOpen = false;

    constructor(private domSanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

    public get playerSrc(): SafeResourceUrl {
        const url = `https://player.vimeo.com/video/${this.metaData.video_id}?autoplay=1`;
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    @HostListener("window:keyup", ["$event"])
    public windowKeyUp(event: KeyboardEvent): boolean {
        if (event.key === "Escape") {
            this.close();
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    public close(): void {
        this.unstyleBody();
        this.isOpen = false;
        this.cdr.markForCheck();
    }

    public open(): void {
        this.styleBody();
        this.isOpen = true;
        this.cdr.markForCheck();
    }

    private styleBody() {
        document.body.style.overflow = "hidden";
    }

    private unstyleBody() {
        document.body.style.overflow = null;
    }
}
