<div class="breadcrumbs" *ngIf="breadcrumbs$ | async as breadcrumbs">
    <div class="breadcrumbs__item" [class.breadcrumbs__item--top-level]="i === 0" [class.breadcrumbs__item--child]="i !== 0"
         *ngFor="let breadcrumb of breadcrumbs; let i = index">
        <a class="breadcrumbs__item__anchor" *ngIf="breadcrumbs.length > i+1; else simpleBreadcrumb" [routerLink]="(breadcrumb.hasDetailsLink ? breadcrumb.url: null)" [innerHtml]="breadcrumb.text"
           [class.breadcrumbs__item__anchor--highlighted]="breadcrumb.highlighted"></a>
        <ng-template #simpleBreadcrumb>
            <div class="breadcrumbs__item__text" [innerHtml]="breadcrumb.text" [class.breadcrumbs__item__text--highlighted]="breadcrumb.highlighted"></div>
        </ng-template>
    </div>
</div>
