import { Type } from "@angular/core";

import { DynamicEpiPropertyBaseComponent } from "../models/dynamic-epi-property-base.component";

export const EpiComponentRegistry = new Map<string, Type<DynamicEpiPropertyBaseComponent>>();

/**
 * Register a component as a episerver page or block component.
 * This will make the component loadable by name from backen type.
 * The component can be loades for a route or as a block in a content area.
 * @export
 * @param {string} name
 * @param {*} component
 */
export function RegisterEpiComponent(name: string, component: Type<DynamicEpiPropertyBaseComponent>): void {
    if (EpiComponentRegistry.get(name)) {
        throw new Error(`Episerver component ${name} already registered`);
    }

    EpiComponentRegistry.set(name, component);
}

export function GetComponentTypeByName(typeName: string): Type<DynamicEpiPropertyBaseComponent> {
    return EpiComponentRegistry.get(typeName + "Component");
}
