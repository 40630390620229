import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { SubscriptionInvitation } from "../models/subscription-invitation";

@Injectable({ providedIn: "root" })
export class SubscriptionsService {
    constructor(private httpClient: HttpClient) {}

    public getActiveAndPendingSubscriptions(): Observable<SubscriptionInvitation[]> {
        return this.httpClient.get<SubscriptionInvitation[]>("/api/CurrentIssuer/ActiveAndPendingSubscriptions");
    }

    public answerInvitation(subscriptionInvitation: SubscriptionInvitation, accept: boolean): Observable<boolean> {
        return this.httpClient.post<boolean>("/api/CurrentIssuer/AnswerInvitation", { subscriptionId: subscriptionInvitation.subscriptionId, accept: accept });
    }
}
