export enum PermitStep {
    preparationRoleSelection = 110,
    preparationFixedQuestions = 115,
    preparationCustomerCheckpoints = 120,
    preparationPhoto = 125,
    preparationComplete = 130,

    isFlammableHotWork = 200,
    workplace = 210,
    emergencyLocation = 215,
    workMethod = 220,
    fireAlarm = 223,
    fixedQuestions = 225,
    customerCheckpoints = 230,
    photo = 235,
    postWorkMonitoringTime = 245,
    team = 250,
    signing = 255,
    duration = 260,

    started = 310,
    expiredNotSigned = 315,

    ended = 410,
    archived = 415,
}
