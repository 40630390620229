import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CheckpointDto } from "../../models/checkpoint-dto";

@Component({
    selector: "pmt-permit-checkpoints-checkpoint",
    templateUrl: "permit-checkpoints-checkpoint.component.html",
    styleUrls: ["permit-checkpoints-checkpoint.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitCheckpointsCheckpointComponent {
    @Input() public checkpoint: CheckpointDto;
}
