import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";

import { ApplicationInsightsErrorHandlerService } from "./services/application-insights-error-handler.service";
import { ApplicationInsightsMonitoringService } from "./services/application-insights-monitoring.service";


@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (applicationInsightsMonitoringService: ApplicationInsightsMonitoringService) => () => {
                return applicationInsightsMonitoringService;
            },
            multi: true,
            deps: [ApplicationInsightsMonitoringService]
        },
        { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandlerService }
    ]
})
export class HaUtilApplicationInsightsModule { }
