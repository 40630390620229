import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HaUiCommonModule } from "@ha/ui/common";
import { HaUiFormsModule } from "@ha/ui/forms";
import { HaUtilPipesModule } from "@ha/util/pipes";
import { TranslocoModule } from "@ngneat/transloco";
import { QRCodeModule } from "angularx-qrcode";

import { AddressFormComponent } from "./components/address-form/address-form.component";
import { DummyComponent } from "./components/dummy/dummy.component";
import { PageFooterComponent } from "./components/page-footer/page-footer.component";
import { PuffComponent } from "./components/puff/puff.component";
import { QrCodeComponent } from "./components/qr-code/qr-code.component";
import { ScrollToTopComponent } from "./components/scroll-to-top/scroll-to-top.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        QRCodeModule,
        ReactiveFormsModule,
        RouterModule,
        TranslocoModule,
        HaUiCommonModule,
        HaUiFormsModule,
        HaUtilPipesModule,
    ],
    declarations: [
        AddressFormComponent,
        DummyComponent,
        PageFooterComponent,
        PuffComponent,
        QrCodeComponent,
        ScrollToTopComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
        HaUiCommonModule,
        HaUiFormsModule,
        HaUtilPipesModule,

        AddressFormComponent,
        PageFooterComponent,
        PuffComponent,
        QrCodeComponent,
        ReactiveFormsModule,
        ScrollToTopComponent,
    ]
})
export class SharedModule {}
