export namespace SettingsStateActions {
    export class GetSettings {
        public static readonly type = "[Settings] GetSettings";
    }

    export class ChangeLanguage {
        public static readonly type = "[Settings] ChangeLanguage";
        constructor(public language: string) {}
    }
}
