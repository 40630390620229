import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { CurrentStep } from "../models/current-step.model";

@Injectable({ providedIn: "root" })
export class PermitStepService {

    constructor(private httpClient: HttpClient) { }

    public getCurrentStep(permitId: number): Observable<CurrentStep> {
        const options = {
            params: {
                permitId: permitId,
            },
        };

        return this.httpClient.get<CurrentStep>("/api/Permit/GetCurrentStep", options);
    }

    public setNextStep(permitId: number): Observable<CurrentStep> {
        const options = {
            params: {
                permitId: permitId
            },
        };

        return this.httpClient.put<CurrentStep>("/api/Permit/SetNextStep", null, options);
    }

    public setPreviousStep(permitId: number): Observable<CurrentStep> {
        const options = {
            params: {
                permitId: permitId,
            },
        };

        return this.httpClient.put<CurrentStep>("/api/Permit/SetPreviousStep", null, options);
    }

}
