import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitUserActions } from "../../../shared/states/permit-user.action";
import { PermitUserSelectors } from "../../../shared/states/permit-user.selectors";
import { SubscriptionInvitation } from "../../models/subscription-invitation";
import { SubscriptionsActions } from "../../states/subscriptions.actions";
import { SubscriptionsSelectors } from "../../states/subscriptions.selectors";
import {
    SubscriptionInvitationAnswerComponent,
    SubscriptionInviteAnswerComponentData,
} from "../subscription-invitation-answer-modal/subscription-invitation-answer.component";

@UntilDestroy()
@Component({
    selector: "pmt-subscription-invitations",
    templateUrl: "./subscription-invitations.component.html",
    styleUrls: ["./subscription-invitations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionInvitationsComponent implements OnInit {
    @Select(SubscriptionsSelectors.invitations)
    public invitations$!: Observable<SubscriptionInvitation[]>;

    constructor(private store: Store, private modalService: HaModalService, private actions$: Actions) {}

    public ngOnInit(): void {
        this.getInvitations();

        this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(PermitUserActions.GetCurrentPerson)).subscribe(() => {
            if (this.store.selectSnapshot(PermitUserSelectors.currentPerson)) {
                this.getInvitations();
            }
        });

        this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(SubscriptionsActions.AnswerInvitation)).subscribe(() => {
            this.getInvitations();
        });
    }

    private getInvitations(): void {
        this.store.dispatch(new SubscriptionsActions.GetActiveAndPendingSubscriptions());
    }

    public answer(invitation: SubscriptionInvitation): void {
        this.modalService.open(SubscriptionInvitationAnswerComponent, true, <SubscriptionInviteAnswerComponentData>{ invitation: invitation });
    }
}
