import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

import { SelectionListItem } from "../../models/selection-list-item.model";
import { BaseControlValueAccessor, MakeValueAccessorProvider } from "../base-control-value-accessor.component";

export interface SelectItem {
    extraLabelText: string;
    isDisabled: boolean;
    text: string;
    value: string | number | boolean;
}

@Component({
    selector: "ha-select",
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"],
    providers: [MakeValueAccessorProvider(SelectComponent)],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends BaseControlValueAccessor<string> {
    @Input()
    public label: string;

    @Input()
    public set options(value: SelectionListItem[] | null) {
        this.listItems = this.genereateListItems(value);
    }

    @Input()
    public sortWhenTranslated: boolean;

    public listItems: SelectItem[];

    public expanded = false;

    public get isUnselected() {
        return this.value === undefined && this.listItems[0]?.value !== undefined;
    }

    public genereateListItems(options: SelectionListItem[] | null): SelectItem[] {
        const translocoService = this.injector.get(TranslocoService);

        const listItems = options?.filter(x => !x.isDisabled).map((item) => {
            return <SelectItem> {
                extraLabelText: item.extraLabelLangKey != null
                    ? translocoService.translate(item.extraLabelLangKey)
                    : item.extraLabelText,
                isDisabled: item.isDisabled,
                text: item.langKey != null ? translocoService.translate(item.langKey) : item.text,
                value: item.value,
            };
        });

        return this.sortWhenTranslated
            ? listItems?.sort((a, b) => {
                // sort items with undefined or null valus on top as we assume this is the default.
                if (!b.value) {
                    return 1;
                }

                return (a.text < b.text ? -1 : 1);
            })
            : listItems;
    }

    public get text(): string {
        return this.listItems?.find((_) => _.value === this.value)?.text;
    }

    public get extraLabelText(): string {
        return this.listItems?.find((_) => _.value == this.value)?.extraLabelText;
    }

    public toggleExpand(): void {
        this.expanded = !this.expanded;
    }

    public selectOption(option: SelectItem): void {
        this.value = option.value as string;
    }

    public close(): void {
        this.expanded = false;
    }

    private open(): void {
        this.expanded = true;
    }
}
