import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HaModalService } from "@ha/ui/common";
import { BreadcrumbActions } from "@ha/ui/navigation";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable, take } from "rxjs";

import { NotificationPage } from "../../models/notification-page";
import { NotificationStatus } from "../../models/notification-status.enum";
import { NotificationActions } from "../../states/notification.actions";
import { NotificationSelectors } from "../../states/notification.selectors";
import { RemoveNotificationModalComponent, RemoveNotificationModalComponentData } from "./remove-notification-modal.component";

@UntilDestroy()
@Component({
    selector: "ntf-notification",
    templateUrl: "notification.component.html",
    styleUrls: ["notification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
    @Select(NotificationSelectors.currentNotification)
    public notification$!: Observable<NotificationPage>;

    public notificationId: number;

    constructor(
        private store: Store,
        private activatedRoute: ActivatedRoute,
        private actions$: Actions,
        private router: Router,
        private modalService: HaModalService,
    ) {}

    public ngOnInit(): void {
        this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
            if (params["notificationId"]) {
                this.notificationId = params["notificationId"] as number;
                this.store.dispatch(new NotificationActions.GetCurrentNotification(this.notificationId));
            }
        });

        this.actions$.pipe(ofActionSuccessful(NotificationActions.GetCurrentNotification)).pipe(
            untilDestroyed(this),
            take(1),
        ).subscribe(() => {
            this.store.dispatch(
                new NotificationActions.SetNotificationStatus(this.notificationId, NotificationStatus.Read),
            );

            this.store.dispatch(
                new BreadcrumbActions.SetTextOrLangKey([{
                    text: this.store.selectSnapshot(NotificationSelectors.currentNotification)?.heading.value,
                }]),
            );
        });
    }

    public remove(): void {
        this.modalService.open(
            RemoveNotificationModalComponent,
            true,
            <RemoveNotificationModalComponentData> { notificationId: this.notificationId },
        );
    }
}
