import { PersonRole } from "@ha/data/basic";
import { Selector } from "@ngxs/store";

import { CheckpointDto } from "../models/checkpoint-dto";
import { PermitBasicDetailsDto } from "../models/permit-basic-details.model";
import { PermitCommentsDto } from "../models/permit-comments-dto";
import { PermitNotificationsDto } from "../models/permit-notifications-dto";
import { PermitSummaryDto } from "../models/permit-summary.model";
import { PermitDetailsState, PermitDetailsStateModel } from "./permit-details.state";

export class PermitDetailsSelectors {
    @Selector([PermitDetailsState])
    public static allowExternalCertificates(state: PermitDetailsStateModel): boolean {
        return state?.basicDetails.allowExternalCertificates;
    }

    @Selector([PermitDetailsState])
    public static fixedCheckpoints(state: PermitDetailsStateModel): CheckpointDto[] {
        return state?.fixedCheckpoints;
    }

    @Selector([PermitDetailsState])
    public static fixedRules(state: PermitDetailsStateModel): number[] {
        const ruleIds = state?.fixedCheckpoints?.map((_) => _.ruleId);
        return [...new Set(ruleIds)];
    }

    @Selector([PermitDetailsState])
    public static customerCheckpoints(state: PermitDetailsStateModel): CheckpointDto[] {
        return state?.customerCheckpoints;
    }

    @Selector([PermitDetailsState])
    public static currentUserRoles(state: PermitDetailsStateModel): PersonRole[] {
        return state?.currentUserRoles;
    }

    @Selector([PermitDetailsState])
    public static currentUserIsPostFireWatcher(state: PermitDetailsStateModel): boolean {
        return state?.currentUserRoles.some((_) => _ === PersonRole.PostFireWatcher);
    }

    @Selector([PermitDetailsState])
    public static currentUserIsIssuer(state: PermitDetailsStateModel): boolean {
        return state?.currentUserRoles?.includes(PersonRole.PermitIssuer);
    }

    @Selector([PermitDetailsState])
    public static currentUserIsObserver(state: PermitDetailsStateModel): boolean {
        return state?.currentUserRoles?.includes(PersonRole.Observer);
    }

    @Selector([PermitDetailsState])
    public static summary(state: PermitDetailsStateModel): PermitSummaryDto {
        return state?.summary;
    }

    @Selector([PermitDetailsState])
    public static basicDetails(state: PermitDetailsStateModel): PermitBasicDetailsDto {
        return state?.basicDetails;
    }

    @Selector([PermitDetailsState])
    public static notifications(state: PermitDetailsStateModel): PermitNotificationsDto {
        return state?.notifications;
    }

    @Selector([PermitDetailsState])
    public static comments(state: PermitDetailsStateModel): PermitCommentsDto {
        return state?.comments;
    }

    @Selector([PermitDetailsState])
    public static showPermitStarted(state: PermitDetailsStateModel): boolean {
        return !!state?.showPermitStarted;
    }
}
