import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseValidationResult } from "@ha/ui/forms";
import { Observable } from "rxjs";

import { CreatePreparedPermitCommand, SelectRoleCommand } from "../models/create-permit-commands";
import { CreatePermitResult } from "../models/create-permit-result.model";
import { PrefixWorkCodeResponse } from "../models/prefix-work-code-response.model";

@Injectable({ providedIn: "root" })
export class PreparePermitService {
    constructor(private httpClient: HttpClient) { }

    public getWorkplaceContainerForPreparation(prefix: string, workCode: string): Observable<PrefixWorkCodeResponse> {
        const options = {
            params: {
                prefix: prefix,
                workCode: workCode
            },
        };

        return this.httpClient.get<PrefixWorkCodeResponse>("/api/WorkplaceDetails/GetWorkplaceContainerForPreparation", options);
    }

    public createPreparedPermit(command: CreatePreparedPermitCommand): Observable<CreatePermitResult> {
        return this.httpClient.post<CreatePermitResult>("/api/Permit/CreatePreparedPermit", command);
    }

    public selectRole(command: SelectRoleCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/SelectRole", command);
    }
}
