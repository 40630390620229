<ng-container *transloco="let t; read:'permit.signature-notice'">
    <div *ngFor="let permit of permitsToSign$ | async" class="permit" (click)="signPermit(permit)">
        <div class="permit__icon permit__icon--sign"></div>
        <div class="permit__text" [innerHtml]="t('sign', { role: t('roles.' + PersonRole[permit.role].toLowerCase()), workplace: permit.workplace })"></div>
        <div class="permit__arrow"></div>
    </div>
    <div *ngFor="let permit of permitsToClaim$ | async" class="permit" (click)="signPermit(permit)">
        <div class="permit__icon permit__icon--claim"></div>
        <div class="permit__text" [innerHtml]="t('claim', { workplace: permit.workplace })"></div>
        <div class="permit__arrow"></div>
    </div>
</ng-container>
