import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";

import { KnowledgeBankActions } from "../../state/knowledge-bank.actions";

@Component({
    selector: "knowledge-bank-search",
    templateUrl: "./knowledge-bank-search.component.html",
    styleUrls: ["./knowledge-bank-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankSearchComponent implements OnInit {
    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new KnowledgeBankActions.SearchInit());
    }
}
