<div *transloco="let t; read 'personalPanel'" class="personal-panel" [class.personal-panel--expanded]="menuOpen$ | async">
    <div class="personal-panel__header" (click)="toggleMenu()">
        <div class="personal-panel__header__user-icon"></div>
        <div class="personal-panel__header__name">{{ name$ | async }}</div>
        <div class="personal-panel__header__expander"></div>
    </div>
    <div class="personal-panel__divider"></div>
    <div class="personal-panel__content" *ngIf="menuOpen$ | async" @slideDownTrigger>
        <div class="personal-panel__content__link-list">
            <a *ngIf="(isExternal$ | async) !== true" (click)="toggleMenu()" [routerLink]="applicationPaths.User.Root.toRouteComponents()"
               class="personal-panel__content__link-list__item personal-panel__content__link-list__item--profile" [innerHTML]="t('myProfile')">
            </a>
            <a (click)="toggleMenu()" [routerLink]="applicationPaths.SignOut.toRouteComponents()" aria-label="sign out"
               class="personal-panel__content__link-list__item personal-panel__content__link-list__item--sign-out" [innerHTML]="t('signOut')">
            </a>
        </div>
        <div class="personal-panel__content__lang-selector">
            <ui-language [languages]="uiLanguages$ | async" [currentLanguage]="currentLanguage$ | async" (languageChanged)="languageChanged($event)">
            </ui-language>
        </div>
    </div>
</div>
