import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { delay, Observable, of, repeat, take } from "rxjs";
import { NotificationStatus } from "../../models/notification-status.enum";
import { Notification } from "../../models/notification.model";
import { NotificationActions } from "../../states/notification.actions";
import { NotificationSelectors } from "../../states/notification.selectors";
import { NotificationListActionsModalComponent } from "./notification-list-actions-modal.component";

@UntilDestroy()
@Component({
    selector: "ntf-notification-list",
    templateUrl: 'notification-list.component.html',
    styleUrls: ['notification-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationListComponent implements OnInit {
    @Select(NotificationSelectors.notifications)
    public notifications$: Observable<Notification[]>;

    constructor(private store: Store, private actions$: Actions, private modalService: HaModalService) { }

    public ngOnInit() {
        this.store.dispatch(new NotificationActions.GetAllNotifications());

        this.actions$.pipe(ofActionSuccessful(NotificationActions.SetStatusForAll))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                this.store.dispatch(new NotificationActions.GetAllNotifications());
            });

        of(null).pipe(delay(60 * 1000), repeat(), untilDestroyed(this)).subscribe(() => {
            this.store.dispatch(new NotificationActions.GetAllNotifications());
        });
    }

    public openActionsModal(): void {
        this.modalService.open(NotificationListActionsModalComponent, true);
    }
}
