import { setDefaultOptions } from "date-fns";
import { enUS, sv } from "date-fns/esm/locale";

export function setDateFnsLanguage(language: string) {
    if (language === "sv") {
        setDefaultOptions({ locale: sv });
    } else {
        setDefaultOptions({ locale: enUS });
    }
}

