import { Injectable } from "@angular/core";
import { EpiserverContentResolver } from "@ha/feature/episerver";

import { ApplicationPaths } from "../../constants/application-paths";
import { KnowledgeBankState } from "../state/knowledge-bank.state";

@Injectable({ providedIn: "root" })
export class KnowledgeBankContentResolver extends EpiserverContentResolver {
    protected angularModuleRoute = ApplicationPaths.KnowledgeBank.Root;

    protected getConfigStateSelector() {
        return KnowledgeBankState.config;
    }
}
