import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PermitStep, PersonRole } from "@ha/data/basic";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitUserActions } from "../../../shared/states/permit-user.action";
import { DetailsTab } from "../../models/details-tab.enum";
import { PermitBasicDetailsDto } from "../../models/permit-basic-details.model";
import { PermitDetailsActions } from "../../states/permit-details.action";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";

@UntilDestroy()
@Component({
    selector: "pmt-permit-details",
    templateUrl: "permit-details.component.html",
    styleUrls: ["permit-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitDetailsComponent implements OnInit {
    @Input()
    public permitId: number;

    @Select(PermitDetailsSelectors.currentUserRoles)
    public currentUserRoles$: Observable<PersonRole[]>;

    @Select(PermitStepSelectors.currentStep)
    public currentStep$: Observable<CurrentStep>;

    @Select(PermitDetailsSelectors.showPermitStarted)
    public showPermitStarted$: Observable<boolean>;

    @Select(PermitDetailsSelectors.basicDetails)
    public details$: Observable<PermitBasicDetailsDto>;

    public PermitStep = PermitStep;
    public DetailsTab = DetailsTab;
    public currentTab = DetailsTab.summary;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitUserActions.GetCurrentPerson());
        this.store.dispatch(new PermitDetailsActions.GetBasicDetails(this.permitId));
        this.store.dispatch(new PermitDetailsActions.GetCurrentUserRoles(this.permitId));
    }

    public getMenuItemVisibility(tab: DetailsTab, userRoles: PersonRole[]): boolean {
        switch (tab) {
            case DetailsTab.summary:
                return true;
            case DetailsTab.team:
                return userRoles?.some((_) => _ === PersonRole.PermitIssuer || _ === PersonRole.Observer);
            case DetailsTab.comments:
                return true;
            case DetailsTab.photos:
                return true;
            case DetailsTab.notifications:
                return userRoles?.some((_) => _ === PersonRole.PermitIssuer || _ === PersonRole.Observer);
            case DetailsTab.complete:
                return userRoles?.some((_) => _ === PersonRole.PermitIssuer || _ === PersonRole.PostFireWatcher);
            default:
                return false;
        }
    }

    public isCurrentTab(tab: DetailsTab): boolean {
        return this.currentTab === tab;
    }

    public setTab(tab: DetailsTab): void {
        this.currentTab = tab;
    }
}
