import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { IssuerDto } from "../../models/change-issuer-dto";
import { PermitChangeIssuerActions } from "../../states/change-issuer-state/change-issuer.action";
import { PermitChangeIssuerSelectors } from "../../states/change-issuer-state/change-issuer.selectors";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";
import {
    PermitChangeIssuerModalComponent,
    PermitChangeIssuerModalComponentData,
} from "./change-issuer-modal/change-issuer-modal.component";

@UntilDestroy()
@Component({
    selector: "pmt-permit-change-issuer",
    templateUrl: "permit-change-issuer.component.html",
    styleUrls: ["permit-change-issuer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitChangeIssuerComponent implements OnInit {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.currentUserRoles)
    public currentUserRoles$: Observable<PersonRole[]>;

    @Select(PermitChangeIssuerSelectors.canChangeIssuer)
    public canChangeIssuer$: Observable<boolean>;

    @Select(PermitChangeIssuerSelectors.issuerChangeInProgress)
    public issuerChangeInProgress$: Observable<boolean>;

    @Select(PermitChangeIssuerSelectors.issuerToBe)
    public issuerToBe$: Observable<IssuerDto>;

    constructor(private store: Store, private haModalService: HaModalService, private actions$: Actions) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitChangeIssuerActions.GetStatus(this.permitId));

        this.actions$.pipe(ofActionCompleted(PermitChangeIssuerActions.ChangeIssuer, PermitChangeIssuerActions.AbortChangeIssuer))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new PermitChangeIssuerActions.GetStatus(this.permitId));
            });
    }

    public abortChange() {
        this.store.dispatch(new PermitChangeIssuerActions.AbortChangeIssuer(this.permitId));
    }

    public userIsIssuer(personRoles: PersonRole[]): boolean {
        if (personRoles.find(_ => _ === PersonRole.PermitIssuer)) {
            return true;
        }

        return false;
    }

    public openChangeIssuerModal(): void {
        this.store.dispatch(new PermitChangeIssuerActions.GetStatus(this.permitId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.haModalService.open(PermitChangeIssuerModalComponent, true, <PermitChangeIssuerModalComponentData>{ permitId: this.permitId });
            });
    }
}
