import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";

import { NotificaitonPaths } from "../../../feature-notification.module";
import { NotificationActions } from "../../states/notification.actions";

export interface RemoveNotificationModalComponentData {
    notificationId: number;
}

@UntilDestroy()
@Component({
    templateUrl: "./remove-notification-modal.component.html",
    styleUrls: ["./remove-notification-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveNotificationModalComponent extends BaseModalComponent {
    public override data: RemoveNotificationModalComponentData;

    constructor(private store: Store, private router: Router) {
        super();
    }

    public remove(): void {
        this.store.dispatch(new NotificationActions.RemoveNotification(this.data.notificationId)).pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.store.dispatch(new Navigate(NotificaitonPaths.Root.toRouteComponents()));
        });
    }
}
