import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { VimeoMetaData } from "./vimeo-meta-data";
import { VimeoVideoBlock } from "./vimeo-video-block";
import { VimeoVideoService } from "./vimeo-video.service";

@UntilDestroy()
@Component({
    selector: "vimeo-video-block",
    templateUrl: "./vimeo-video-block.component.html",
    styleUrls: ["./vimeo-video-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VimeoVideoBlockComponent implements OnInit {
    public data: VimeoVideoBlock;
    public metaData: VimeoMetaData;

    constructor(private vimeoVideoService: VimeoVideoService, private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.getVideoMetadata();
    }

    public getVideoMetadata(): void {
        this.vimeoVideoService.getVideoMetadata(this.data.vimeoUrl.value)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.metaData = data;
                this.cdr.markForCheck();
            });
    }
}
