<div class="item" *ngIf="data">
    <ng-container [ngSwitch]="data.type">
        <div class="item__link" [class]="iconClass('item__link')" *ngSwitchCase="'LinkListBlock'">
            <a class="item__link__anchor" target="_blank" [href]="data.url" download>{{ data.name }}</a>
        </div>

        <div class="item__vimeo-video" *ngSwitchCase="'VimeoVideoBlock'">
            <vimeo-video-link [url]="data.url">
                <div class="item__vimeo-video__anchor" [innerHTML]="data.name"></div>
            </vimeo-video-link>
        </div>
    </ng-container>
</div>
