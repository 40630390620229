import { ActivatedRoute } from "@angular/router";

import { Breadcrumb } from "../../models/breadcrumb.model";

export function createBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: Breadcrumb[] = []): Breadcrumb[] | undefined {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
        return breadcrumbs;
    }

    for (const child of children) {
        if (child.snapshot.url.map((segment) => segment.path).length === 0) {
            return createBreadcrumbs(child, url, breadcrumbs);
        }

        const routeURL: string = child.snapshot.url.map((segment) => segment.path).join("/");
        if (routeURL !== "") {
            url += `/${routeURL}`;
        }

        const name = child.snapshot.data["title"] as string;
        if (name) {
            breadcrumbs.push({ langKey: name, url, hasDetailsLink: true });
        }

        return createBreadcrumbs(child, url, breadcrumbs);
    }

    return undefined;
}
