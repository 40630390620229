import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { actionsExecuting } from "@ngxs-labs/actions-executing";
import { Select, Store } from "@ngxs/store";
import { map, Observable } from "rxjs";
import { PermitUserActions } from "../../../shared/states/permit-user.action";

import { PermitWorkPlaceViewEnum } from "../../models/permit-work-place-view.enum";
import { PermitWorkplaceActions } from "../../states/permit-workplace.actions";
import { PermitWorkplaceSelectors } from "../../states/permit-workplace.selectors";

@Component({
    selector: "pmt-workplace",
    templateUrl: "permit-workplace.component.html",
    styleUrls: ["permit-workplace.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitWorkplaceComponent implements OnInit {
    @Output() public affirmative = new EventEmitter<boolean>();

    @Select(PermitWorkplaceSelectors.view)
    public view$: Observable<PermitWorkPlaceViewEnum>;

    public creatingPermit$ = this.store.select(actionsExecuting([
        PermitWorkplaceActions.CreatePermit,
        PermitWorkplaceActions.SetWorkplaceAndEmergencyLocation,
        PermitWorkplaceActions.SetPremiumWorkplace,
        PermitWorkplaceActions.SetPremiumWorkplaceFromHistory,
    ])).pipe(map(_ => {
        return _ === null ? undefined : true;
    }));

    public permitWorkPlaceViewEnum = PermitWorkPlaceViewEnum;

    constructor(private store: Store) { }

    public ngOnInit(): void {
        this.store.dispatch(new PermitWorkplaceActions.ChangeView(PermitWorkPlaceViewEnum.Start));
        this.store.dispatch(new PermitWorkplaceActions.GetCurrentIssuerCompanyStructure());

        this.store.dispatch(new PermitUserActions.GetCurrentPerson());
    }
}
