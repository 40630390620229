import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Actions, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { PermitListSelectors } from "../../../permit-list/states/permit-list.selectors";
import { PermitWorkplaceItem } from "../../../permit-list/models/permit-workplace-item.model";
import { PermitListActions } from "../../../permit-list/states/permit-list.actions";

export interface PermitRootFilterModalComponent {

}

@UntilDestroy()
@Component({
    templateUrl: "permit-root-filter-modal.component.html",
    styleUrls: ["permit-root-filter-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitRootFilterModalComponent extends BaseModalComponent {
    @Select(PermitListSelectors.workplaces)
    public workplaces$: Observable<PermitWorkplaceItem[]>;

    public filterCount: number = 0;

    public selected: string[];

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit(): void {
        var wps = this.store.selectSnapshot(PermitListSelectors.workplaces).filter(item => item.isSelected).map(item => item.name);
        this.selected = wps;
    }

    public resetFilter(): void {
        this.selected = [];
        this.filterCount = 0;
        this.store.dispatch(new PermitListActions.UpdateFilter(this.selected));
    }

    public select(): void {
        this.filterCount = this.store.selectSnapshot(PermitListSelectors.workplaces).filter(item => item.isSelected).length;
    }

    public onChange(): void {
        var wps = this.store.selectSnapshot(PermitListSelectors.workplaces).filter(item => item.isSelected).map(item => item.name);
        this.selected = wps;
        this.store.dispatch(new PermitListActions.UpdateFilter(this.selected));
    }

    public close(): void {
        this.store.dispatch(new PermitListActions.UpdateFilter(this.selected));
        this.closeModal();
    }
}
