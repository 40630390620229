import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Account, AccountImpersonateActions, AuthActions, CurrentAccountSelectors } from "@ha/feature/auth";
import { BaseModalComponent } from "@ha/ui/common";
import { Select, Store } from "@ngxs/store";
import { map, Observable } from "rxjs";

import { Application } from "../../models/application.model";

export interface ApplicationListModalComponentData {
    applicationList: Application[];
}

@Component({
    templateUrl: "./application-list-modal.component.html",
    styleUrls: ["./application-list-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationListModalComponent extends BaseModalComponent {

    public data: ApplicationListModalComponentData;

    @Select(CurrentAccountSelectors.currentAccount)
    public currentAccount$: Observable<Account>;

    public signOutAction = AuthActions.SignOut;

    public impersonateStopAction = AccountImpersonateActions.ImpersonateStop;

    public get applicationList$(): Observable<Application[]> {
        return this.currentAccount$.pipe(map(account => {
            const accessToApplications = Object.entries(account.accessToApplications)
                .filter((app) => app[1] || (app[0] === "admin" && account.isImpersonated))
                .map(_ => _[0]);

            return this.data.applicationList.filter(_ => accessToApplications.includes(_.internalIdentifier));
        }));
    }

    constructor(private store: Store) {
        super();
    }

    public signOut(): void {
        this.closeModal();
        this.store.dispatch(new AuthActions.SignOut());
    }
}
