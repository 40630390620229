import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CertificateCard } from "../models/certificate-card.model";
import { CertificateStateActions } from "../state/certificate.actions";
import { CertificateState } from "../state/certificate.state";

@Component({
    selector: "certificate-card",
    templateUrl: "./certificate-card.component.html",
    styleUrls: ["./certificate-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateCardComponent implements OnInit {
    @Select(PwaState.isOffline)
    public isOffline$: Observable<boolean>;

    @Select(PwaState.isOnline)
    public isOnline$: Observable<boolean>;

    @Select(CertificateState.card)
    public card$: Observable<CertificateCard>;

    @Select(CertificateState.cardDataLoaded)
    public cardDataLoaded$: Observable<boolean>;

    @Select(CertificateState.hasCard)
    public hasCard$: Observable<boolean>;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new CertificateStateActions.GetCard());
    }
}
