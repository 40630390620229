import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LinkItem } from "../../models/episerver-base-types.model";

import { LinkListBlock } from "./link-list-block.model";

@Component({
    templateUrl: "./link-list-block.component.html",
    styleUrls: ["./link-list-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkListBlockComponent {
    @Input() public data: LinkListBlock;

    public getIconClass(item: LinkItem): string {
        if (!item.href) {
            return null;
        }

        if (item.href.startsWith("mailto:")) {
            return "email";
        }

        if (!item.href.startsWith("/")) {
            return "external";
        }

        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(item.href)[1];

        return extension === undefined ? "default" : extension;
    }
}
