import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { PwaState } from "@ha/util/pwa";
import { toCamelCase } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import {
    AddPersonModalComponent,
    AddPersonModalComponentData,
} from "../../../permit-team/components/add-person-modal/add-person-modal.component";
import { PermitTeam } from "../../../permit-team/models/permit-team.model";
import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitTeamSelectors } from "../../../permit-team/states/permit-team.selectors";
import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";
import {
    PermitDetailsTeamRemoveOrSignModalComponent,
    PermitDetailsTeamRemoveOrSignModalComponentData,
} from "./permit-details-team-remove-or-sign/permit-details-team-remove-or-sign.component";
import { PermitTeamContactModalComponent, PermitTeamContactModalComponentData } from "../permit-team-contact-modal/permit-team-contact-modal.component";
import { PermitUserSelectors } from "../../../shared/states/permit-user.selectors";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";
import { Permit } from "../../../permit-create/models/permit.model";
import { PermitCreateActions } from "../../../permit-create/states/permit-create.actions";

@UntilDestroy()
@Component({
    selector: "pmt-details-team",
    templateUrl: "permit-details-team.component.html",
    styleUrls: ["permit-details-team.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitDetailsTeamComponent implements OnInit {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.currentUserIsIssuer)
    public currentUserIsIssuer$: Observable<boolean>;

    @Select(PermitTeamSelectors.team)
    public permitTeam$: Observable<PermitTeam>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    @Select(PermitCreateSelectors.permit)
    public permit$: Observable<Permit>;

    public canProceed = false;
    public PersonRole = PersonRole;
    public currentPerson: PermitPerson;

    constructor(private modalService: HaModalService, private store: Store) {
    }

    public ngOnInit(): void {
        this.store.dispatch(new PermitTeamActions.GetTeam(this.permitId));
        this.store.dispatch(new PermitCreateActions.GetPermit(this.permitId));

        this.store.select(PermitTeamSelectors.teamValid)
            .pipe(untilDestroyed(this))
            .subscribe((valid) => {
                this.canProceed = valid;
            });

        this.store.select(PermitUserSelectors.currentPerson)
            .pipe(untilDestroyed(this))
            .subscribe((permitUser) => {
                this.currentPerson = permitUser;
            });
    }

    public filterSigned(permitPersons: PermitPerson[]) {
        return permitPersons?.filter(_ => _.signature);
    }

    public filterNotSigned(permitPersons: PermitPerson[]) {
        return permitPersons?.filter(_ => !_.signature);
    }

    public personRoleToString(value: PersonRole): string {
        return toCamelCase(PersonRole[value]);
    }

    public canContact(firstName: string, lastName: string, email: string, phone: string) {
        if ((email == null || email.length == 0) && (phone == null || phone.length == 0)) {
            return false;
        }
        if (this.currentPerson.firstName == firstName && this.currentPerson.lastName == lastName) {
            return false;
        }

        return true;
    }

    public openContact(firstName: string, lastName: string, email: string, phone: string) {
        this.modalService.open(PermitTeamContactModalComponent, true, <PermitTeamContactModalComponentData>{
            name: `${firstName} ${lastName}`,
            email: email,
            phone: phone
        });
    }

    public add(role: PersonRole): void {
        this.store.dispatch(new PermitTeamActions.SetAddPersonView(role));
        this.modalService.open(AddPersonModalComponent, true, <AddPersonModalComponentData>{
            permitId: this.permitId,
            role: role,
            allowExternalCertificates: this.store.selectSnapshot(PermitDetailsSelectors.allowExternalCertificates),
            detailsMode: true,
        });
    }

    public removeOrSign(permitPerson: PermitPerson, role: PersonRole) {
        this.modalService.open(PermitDetailsTeamRemoveOrSignModalComponent, false, <PermitDetailsTeamRemoveOrSignModalComponentData>{
            permitId: this.permitId,
            person: permitPerson,
            role: role,
        });
    }
}
