import { Injectable } from "@angular/core";
import { EpiserverAngularModuleConfig, EpiserverService, EpiserverStateActions, MenuItem } from "@ha/feature/episerver";
import { Breadcrumb, BreadcrumbActions } from "@ha/ui/navigation";
import { RouterNavigation } from "@ngxs/router-plugin";
import { Action, Actions, ofActionSuccessful, Selector, State, StateContext, Store } from "@ngxs/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map, mergeMap, skip, take, tap } from "rxjs/operators";

import { Bookmark } from "../../bookmarks/models/bookmark";
import { ApplicationPaths } from "../../constants/application-paths";
import { CurrentUserState } from "../../core/states/current-user.state";
import { InitSelector } from "../../core/states/init.selector";
import { SettingsStateActions } from "../../core/states/settings.actions";
import { SettingsState } from "../../core/states/settings.state";
import { KnowledgeBankNavigation } from "../models/knowledge-bank-navigation";
import { StartPageSection } from "../models/start-page-sections";
import { KnowledgeBankService } from "../services/knowledge-bank.service";
import { KnowledgeBankActions } from "./knowledge-bank.actions";

export interface KnowledgeBankStateModel {
    config: EpiserverAngularModuleConfig;
    menu: MenuItem;
    bookmarks: Bookmark[];
    startPageSections: StartPageSection[];
    navigationLinks: KnowledgeBankNavigation;
}

@Injectable()
@State<KnowledgeBankStateModel>({
    name: "knowledgeBank",
})
export class KnowledgeBankState {
    constructor(
        private knowledgeBankService: KnowledgeBankService,
        private episerverService: EpiserverService,
        private store: Store,
        private actions$: Actions,
    ) {}

    @Selector()
    public static menu(state: KnowledgeBankStateModel): MenuItem {
        return state.menu;
    }

    @Selector()
    public static startPageSections(state: KnowledgeBankStateModel): StartPageSection[] {
        return state.startPageSections;
    }

    @Selector()
    public static config(state: KnowledgeBankStateModel): EpiserverAngularModuleConfig {
        return state.config;
    }

    @Selector()
    public static navigationLinks(state: KnowledgeBankStateModel): KnowledgeBankNavigation {
        return state.navigationLinks;
    }

    public ngxsOnInit(ctx: StateContext<KnowledgeBankStateModel>): void {
        this.actions$.pipe(ofActionSuccessful(SettingsStateActions.ChangeLanguage), skip(1)).subscribe(() => {
            ctx.dispatch(new KnowledgeBankActions.GetMenuSide());
        });

        // Start listening and combine get menu and navigation events
        combineLatest([
            this.actions$.pipe(ofActionSuccessful(KnowledgeBankActions.GetMenuSide)),
            this.actions$.pipe(ofActionSuccessful(RouterNavigation)),
        ]).pipe(
            map(([, routerNavigation]) => (routerNavigation as RouterNavigation).event.url),
            filter((url) => url.includes(ApplicationPaths.KnowledgeBank.Root)),
        ).subscribe((url) => {
            this.setBreadcrumbs(ctx, url);
        });
    }

    @Action(KnowledgeBankActions.Init)
    public init(ctx: StateContext<KnowledgeBankStateModel>): void {
        if (
            this.store.selectSnapshot(CurrentUserState.isExternal)
            || !this.store.selectSnapshot(InitSelector.isAppInitialized)
        ) {
            return;
        }

        this.knowledgeBankService.getConfig().subscribe((config) => {
            config.moduleRootPath = ApplicationPaths.KnowledgeBank.Root;

            ctx.patchState({
                config: config,
            });

            this.store.dispatch(new EpiserverStateActions.SetCurrentConfig(config));

            return ctx.dispatch(new KnowledgeBankActions.GetMenuSide());
        });
    }

    @Action(KnowledgeBankActions.GetMenuSide)
    public getMenuSide(ctx: StateContext<KnowledgeBankStateModel>): Observable<MenuItem> {
        const currentLanguage$ = this.store.select(SettingsState.currentLanguage).pipe(filter((_) => !!_));
        const backendConfig$ = this.store.select(KnowledgeBankState.config).pipe(filter((_) => !!_));

        return combineLatest([currentLanguage$, backendConfig$]).pipe(
            take(1),
            map(([lang, config]) => config.rootUrls.find((_) => _.lang === lang).url),
            mergeMap((url) => {
                return this.episerverService.getMenuSide(url, ctx.getState().config.moduleRootPath).pipe(tap((data) => {
                    ctx.patchState({
                        menu: data,
                    });
                }));
            }),
        );
    }

    @Action(KnowledgeBankActions.GetStartPageSections)
    public getStartPageSections(ctx: StateContext<KnowledgeBankStateModel>): Observable<StartPageSection[]> {
        ctx.patchState({
            startPageSections: undefined,
        });

        return this.knowledgeBankService.getStartPageSections().pipe(tap((data) => {
            ctx.patchState({
                startPageSections: data,
            });
        }));
    }

    @Action(KnowledgeBankActions.GetNavigationLinks)
    public getNavigationLinks(
        ctx: StateContext<KnowledgeBankStateModel>,
        action: KnowledgeBankActions.GetNavigationLinks,
    ): Observable<KnowledgeBankNavigation> {
        return this.knowledgeBankService.getNavigationLinks(action.pageReferenceId).pipe(tap((data) => {
            ctx.patchState({
                navigationLinks: data,
            });
        }));
    }

    public setBreadcrumbs(ctx: StateContext<KnowledgeBankStateModel>, currentUrl: string): Observable<void> {
        function createBreadcrumbs(menu: MenuItem, path: string, breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
            let url: string;
            if (path === "/") {
                return breadcrumbs;
            } else if (path.indexOf("/") >= 0) {
                url = path.split("/")[1];
            } else {
                url = path;
            }

            if (menu.url.endsWith(url)) {
                breadcrumbs.push({ text: menu.name, url: menu.url, langKey: undefined, hasDetailsLink: true });
            } else {
                const index = menu.children.findIndex((_) => _.url.includes(url));
                if (index >= 0) {
                    menu = menu.children[index];
                    breadcrumbs.push({ text: menu.name, url: menu.url, langKey: undefined, hasDetailsLink: true });
                }
            }

            if (path.indexOf("/") !== path.lastIndexOf("/")) {
                path = path.slice(path.indexOf("/", 1));
                return createBreadcrumbs(menu, path, breadcrumbs);
            }

            return breadcrumbs;
        }

        const state = ctx.getState();

        currentUrl = currentUrl.split("#")[0];

        // Ugly hack for search page
        if (currentUrl.includes(ApplicationPaths.KnowledgeBank.Search)) {
            return ctx.dispatch(
                new BreadcrumbActions.Set([
                    { langKey: "knowledgeBank.title", url: ApplicationPaths.KnowledgeBank.Root, hasDetailsLink: true },
                    { langKey: "knowledgeBank.search.title", url: ApplicationPaths.KnowledgeBank.Search, hasDetailsLink: true },
                ]),
            );
        } else {
            const breadcrumbs = createBreadcrumbs(state.menu, currentUrl);
            return ctx.dispatch(new BreadcrumbActions.Set(breadcrumbs));
        }
    }
}
