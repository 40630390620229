import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { Select, Store } from "@ngxs/store";
import { Observable, of } from "rxjs";

import { PermitPersonWithStatus } from "../../../shared/models/permit-person.model";
import { PermitTeamActions } from "../../states/permit-team.action";
import { PermitTeamSelectors } from "../../states/permit-team.selectors";

@Component({
    selector: "pmt-last-used-persons-list",
    templateUrl: "last-used-persons-list.component.html",
    styleUrls: ["last-used-persons-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LastUsedPersonsListComponent implements OnInit {
    @Input()
    public permitId: number;

    @Input()
    public role: PersonRole;

    @Select(PermitTeamSelectors.recentTeamMembers)
    public recentTeamMembers$: Observable<PermitPersonWithStatus[]>;

    @Select(PermitTeamSelectors.recentTeamMembersFullyLoaded)
    public recentTeamMembersFullyLoaded$: Observable<boolean>;

    public query: string;

    @Select(PermitTeamSelectors.recentTeamMembersSearchResult)
    public result$: Observable<PermitPersonWithStatus[]>;

    private INITIAL_TAKE = 40;
    private LOAD_MORE_TAKE = 10;
    private take = this.INITIAL_TAKE;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.loadTeamMembers(true);
    }

    private loadTeamMembers(restart: boolean): void {
        this.store.dispatch(new PermitTeamActions.GetRecentTeamMembers(this.permitId, this.take, restart));
    }

    public loadMore(): void {
        this.take = this.LOAD_MORE_TAKE;
        this.loadTeamMembers(false);
    }

    public search(query: string) {
        this.store.dispatch(new PermitTeamActions.SearchRecentTeamMembers(query));
    }

    public clearSearchResults() {
        this.query = "";
        this.search("");
    }
}
