<ng-container *transloco="let t; read:'permit.details.comments'">
    <ng-container *ngIf="(comments$ | async) !== undefined">
        <div class="comments" *ngIf="comments$ | async as comments">
            <pmt-permit-comments-comment *ngFor="let comment of getCommentsWithAvatarId(comments.comments)" [comment]="comment"
                                         [canDelete]="comments.currentUserIsIssuer">
            </pmt-permit-comments-comment>
        </div>

        <div class="no-comments" *ngIf="(comments$ | async)?.comments.length <= 0">{{ t("noComments") }}</div>
    </ng-container>
    <button *ngIf="(currentStep$ | async)?.step !== PermitStep.ended && ((currentUserIsObserver$ | async) === false)" class="create-comment-button"
            (click)="createComment()">
        <div class="create-comment-button__text">{{ t("createCommentButton") }}</div>
    </button>

</ng-container>
