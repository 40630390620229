import { getAuthorityUrl } from "@ha/feature/auth";
import { LogLevel, OpenIdConfiguration } from "angular-auth-oidc-client";

import { ApplicationPaths } from "../../constants/application-paths";

export const openIdConfiguration: OpenIdConfiguration = {
    configId: "HetaArbetenApp",
    authority: getAuthorityUrl(),
    redirectUrl: window.location.origin,
    postLoginRoute: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    unauthorizedRoute: ApplicationPaths.Unauthorized,
    secureRoutes: ["/api"],
    triggerAuthorizationResultEvent: true,
    clientId: "HetaArbetenApp",
    scope: "appApi openid profile",
    responseType: "code",
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    autoUserInfo: true,
    renewUserInfoAfterTokenRenew: true,
    ngswBypass: true,
    logLevel: LogLevel.Warn,
};
