<div class="root">
    <form [formGroup]="form">
        <ha-input class="root__input" formControlName="time" [label]="label" [readOnlyOnDisabled]="true" type="number">
        </ha-input>
    </form>

    <div class="root__button-group">
        <div class="root__button-group__time">
            <button class="root__button-group__time__button" (click)="change(-bigStep)"
                    [class.root__button-group__time__button--disabled]="!canChange(-bigStep)">-{{ bigStep }}</button>
        </div>
        <div class="root__button-group__time">
            <button class="root__button-group__time__button" (click)="change(-smallStep)"
                    [class.root__button-group__time__button--disabled]="!canChange(-smallStep)">-{{ smallStep }}</button>
        </div>
        <div class="root__button-group__time">
            <button class="root__button-group__time__button root__button-group__time__button--reset" (click)="reset()"></button>
        </div>
        <div class="root__button-group__time">
            <button class="root__button-group__time__button" (click)="change(smallStep)"
                    [class.root__button-group__time__button--disabled]="!canChange(smallStep)">+{{ smallStep }}
            </button>
        </div>
        <div class="root__button-group__time">
            <button class="root__button-group__time__button" (click)="change(bigStep)"
                    [class.root__button-group__time__button--disabled]="!canChange(bigStep)">+{{ bigStep }}
            </button>
        </div>
    </div>
</div>
