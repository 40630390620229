<ng-container *transloco="let t; read:'permit.signature'">
    <div class="modal">
        <div class="modal__content">
            <pmt-permit-summary class="modal__content__summary" [permitId]="data.permitId"></pmt-permit-summary>

            <div class="modal__content__heading">
                {{ t("signature") }}
            </div>

            <signature-pad *ngIf="signaturePadOptions" class="modal__content__signature" #signature [options]="signaturePadOptions"
                           (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad>

            <div class="modal__content__details">
                <div class="modal__content__details__person">
                    <div class="modal__content__details__person__name">{{ data.name }}</div>
                    <div class="modal__content__details__person__role" [innerHtml]="t('roles.' + PersonRole[data.role].toLowerCase())"></div>
                </div>
                <button class="modal__content__details__clear-button" (click)="clear()">{{ t("clear") }}</button>
            </div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__save" [class.modal__actions__save--disabled]="!isValid" type="button" (click)="save()">{{ t("save") }}</button>
        </div>
    </div>
</ng-container>
