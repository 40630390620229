<div *transloco="let t; read: 'pageFooter'" class="footer">
    <div class="footer__data">
        <div *ngIf="publishDate" class="footer__data__item">
            <div class="footer__data__item__label">{{ t("publishDate") }}:</div>
            <div class="footer__data__item__value">{{publishDate | haDate:"fullDateShortMonth"}}</div>
        </div>
        <div *ngIf="changeDate" class="footer__data__item">
            <div class="footer__data__item__label">{{ t("changeDate") }}:</div>
            <div class="footer__data__item__value">{{changeDate | haDate:"fullDateShortMonth"}}</div>
        </div>
    </div>
    <a [href]="shareUrl" class="footer__share">
        <div class="footer__share__icon"></div>
        <div class="footer__share__text">{{ t("share") }}</div>
    </a>
</div>
