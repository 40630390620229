import { ComponentType, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { fromEvent, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { BaseModalComponent } from "../..";

@Injectable({ providedIn: "root" })
export class HaModalService implements OnDestroy {
    private overlayRefs!: OverlayRef[];

    private routerSubscription: Subscription;

    constructor(private overlay: Overlay, private router: Router) {
        this.routerSubscription = this.router.events
            .pipe(
                filter((e) => e instanceof NavigationStart),
                filter(() => !!this.overlayRefs)
            )
            .subscribe(() => {
                this.removeAll();
            });
    }

    public ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.removeAll();
    }

    public open<T extends BaseModalComponent>(component: ComponentType<T>, closeOnEscapeOrOutsideClick: boolean, componentData: unknown = undefined): void {
        const positionStrategy = this.overlay
            .position()
            .global();
        // .centerHorizontally()
        // .centerVertically();

        // We create the overlay
        this.overlayRefs = this.overlayRefs ?? [];

        const newOverlay = this.overlay.create({
            backdropClass: "ha-modal-backdrop",
            disposeOnNavigation: true,
            hasBackdrop: true,
            panelClass: ["ha-modal"],
            positionStrategy: positionStrategy,
        });

        this.overlayRefs.push(newOverlay);

        // If modal doesn't require an active choice, close
        // on backdrop click and escape keypress.
        if (closeOnEscapeOrOutsideClick) {
            newOverlay.backdropClick().subscribe(() => {
                this.removeLatest();
            });

            fromEvent<KeyboardEvent>(document, "keydown").subscribe((event: KeyboardEvent) => {
                if (event.key === "Escape") {
                    this.removeLatest();
                }
            });
        }

        //Then we create a portal to render a component
        const componentPortal = new ComponentPortal(component);

        //We render the portal in the overlay
        const componentRef = newOverlay.attach(componentPortal);
        componentRef.instance.data = componentData;

        // Remove overlay on close modal event from component
        componentRef.instance.closeModalEvent.subscribe(() => {
            this.removeLatest();
        });
    }

    private removeLatest() {
        const overlay = this.overlayRefs.splice(-1)[0];
        overlay.detach();
    }

    private removeAll() {
        this.overlayRefs.forEach(_ => {
            _.dispose();
        });

        this.overlayRefs.length = 0;
    }
}
