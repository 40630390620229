import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CertificateCard } from "../models/certificate-card.model";

@Component({
    selector: "certificate-card-face",
    templateUrl: "./certificate-card-face.component.html",
    styleUrls: ["./certificate-card-face.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateCardFaceComponent {
    @Input()
    public card: CertificateCard;

    public get name(): string {
        return `${this.card.firstName} ${this.card.lastName}`;
    }

    public openUserPermissionPage(event: MouseEvent): void {
        event.stopPropagation();
        window.open(this.card.qrCodeUrl, "_blank");
    }
}
