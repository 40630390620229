import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from "@angular/core";

@Component({
    selector: "modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit, OnChanges {
    @ViewChild("modal") private modal!: ElementRef;

    @Input() public cancelLabel!: string;
    @Input() public closeLabel!: string;
    @Input() public okLabel!: string;

    @Input() public autoOpen!: boolean;

    @Input() public width: "narrow" | "wide" = "narrow";
    @Input() public textSource: "from-translation" | "from-editor" = "from-translation";

    @Output() public cancel = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public close = new EventEmitter();
    @Output() public ok = new EventEmitter();

    public showModal = false;
    public invalidInputs = false;
    public scrollHeight!: number;

    constructor(private renderer: Renderer2, private crd: ChangeDetectorRef) { }

    public ngOnInit(): void {
        if (this.autoOpen) {
            this.openModal();
        }
    }

    public ngOnChanges(): void {
        if (this.closeLabel && (this.okLabel || this.cancelLabel)) {
            console.error("modal.component.ts: 'closeLabel' cannot be combined with 'cancelLabel' or 'okLabel'.");
            this.invalidInputs = true;
        } else if ((this.cancelLabel && !this.okLabel) || (!this.cancelLabel && this.okLabel)) {
            console.error("modal.component.ts: 'cancelLabel' and 'okLabel' must be passed together.");
            this.invalidInputs = true;
        }
    }

    @HostListener("window:keyup", ["$event"])
    public windowKeyUp(event: KeyboardEvent): boolean {
        if (event.key === "Escape") {
            this.emitCancel();
            this.emitClose();
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    public openModal(): void {
        if (this.invalidInputs) {
            return;
        }

        this.showModal = true;
        this.crd.detectChanges();

        this.disableBodyScroll();
    }

    public emitCancel(): void {
        this.cancel.emit();
        this.closeModal();
    }

    public emitClose(): void {
        this.close.emit();
        this.closeModal();
    }

    public emitOk(): void {
        this.ok.emit();
        this.closeModal();
    }

    public scrollDown(): void {
        const modalElement = this.modal.nativeElement as HTMLElement;

        // Only calculate scroll height the first time.
        if (this.scrollHeight === undefined) {
            this.scrollHeight = modalElement ? modalElement.offsetHeight - 150 : 0;
        }

        if (modalElement) {
            modalElement.scrollBy(0, this.scrollHeight);
        }
    }

    private closeModal(): void {
        this.enableBodyScroll();
        this.showModal = false;
    }

    private disableBodyScroll() {
        this.renderer.addClass(document.body, "no-scroll");
    }

    private enableBodyScroll() {
        this.renderer.removeClass(document.body, "no-scroll");
    }
}
