import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { XhtmlStringCustomFragment } from "../../models/episerver-base-types.model";

interface AsidePageNavigationLink {
    id: string;
    name: string;
}

@UntilDestroy()
@Component({
    selector: "anchor-menu-inline",
    templateUrl: "./anchor-menu-inline.component.html",
    styleUrls: ["./anchor-menu-inline.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorMenuInlineComponent implements OnInit, OnChanges {
    @Input() public heading: string;
    @Input() public toTheTopText: string;

    @Input() public data: {
        value: string | XhtmlStringCustomFragment[];
        propertyDataType: string;
    };

    public items: AsidePageNavigationLink[];
    public currentFragment: string;
    public navigationClass: string;

    constructor(private route: ActivatedRoute) {}

    public ngOnInit(): void {
        this.route.fragment.pipe(untilDestroyed(this)).subscribe((fragment) => {
            this.currentFragment = fragment;
            this.scrollToCurrentFragment();
        });

        setTimeout(() => {
            this.scrollToCurrentFragment();
        });
    }

    public ngOnChanges(): void {
        this.getHeadings();
    }

    private getHeadings() {
        const items: AsidePageNavigationLink[] = [];

        function getHeadings(html: string) {
            if (!html) {
                return;
            }

            const outer = html.match(/<h2 id=".*">.*<\/h2>/g);

            if (outer) {
                outer.forEach((outerMatch) => {
                    const inner = /<h2 id="(.*?)">(.*?)<\/h2>/.exec(outerMatch);
                    items.push({
                        id: inner[1],
                        name: inner[2],
                    });
                });
            }
        }

        if (Array.isArray(this.data.value)) {
            const fragments = this.data.value;
            fragments.forEach((fragment) => {
                if (fragment.html) {
                    getHeadings(fragment.html);
                }
            });
        } else {
            getHeadings(this.data.value);
        }

        this.items = items;
    }

    private scrollToCurrentFragment() {
        const element = document.querySelector("#" + this.currentFragment);
        if (element) {
            element.scrollIntoView({ behavior: "auto", block: "center" });
        }
    }
}
