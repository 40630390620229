<pmt-permit-completed *ngIf="(showPermitStarted$ | async) === true"></pmt-permit-completed>

<ng-container *ngIf="(details$ | async)?.isFlammableHotWork && (details$ | async)?.isDemo === false">
    <div class="details" *transloco="let t; read:'permit.details'">

        <ng-container *ngIf="(showPermitStarted$ | async) === false && (details$ | async)">
            <div class="menu">
                <div *ngIf="getMenuItemVisibility(DetailsTab.summary, currentUserRoles$ | async)" (click)="setTab(DetailsTab.summary)"
                     class="menu__item menu__item--summary" [class.menu__item--selected]="isCurrentTab(DetailsTab.summary)">
                    <div class="menu__item__text">{{ t("tabs.summary") }}</div>
                </div>
                <div *ngIf="getMenuItemVisibility(DetailsTab.team, currentUserRoles$ | async)" (click)="setTab(DetailsTab.team)"
                     class="menu__item menu__item--team" [class.menu__item--selected]="isCurrentTab(DetailsTab.team)">
                    <div class="menu__item__text">{{ t("tabs.team") }}</div>
                </div>
                <div *ngIf="getMenuItemVisibility(DetailsTab.comments, currentUserRoles$ | async)" (click)="setTab(DetailsTab.comments)"
                     class="menu__item menu__item--comments" [class.menu__item--selected]="isCurrentTab(DetailsTab.comments)">
                    <div class="menu__item__text">{{ t("tabs.comments") }}</div>
                </div>
                <div *ngIf="getMenuItemVisibility(DetailsTab.photos, currentUserRoles$ | async)" (click)="setTab(DetailsTab.photos)"
                     class="menu__item menu__item--photos" [class.menu__item--selected]="isCurrentTab(DetailsTab.photos)">
                    <div class="menu__item__text">{{ t("tabs.photos") }}</div>
                </div>
                <div *ngIf="getMenuItemVisibility(DetailsTab.notifications, currentUserRoles$ | async)" (click)="setTab(DetailsTab.notifications)"
                     class="menu__item menu__item--notifications" [class.menu__item--selected]="isCurrentTab(DetailsTab.notifications)">
                    <div class="menu__item__text">{{ t("tabs.notifications") }}</div>
                </div>
                <div *ngIf="getMenuItemVisibility(DetailsTab.complete, currentUserRoles$ | async)" (click)="setTab(DetailsTab.complete)"
                     class="menu__item menu__item--complete" [class.menu__item--selected]="isCurrentTab(DetailsTab.complete)">
                    <div class="menu__item__text">{{ t("tabs.complete") }}</div>
                </div>
            </div>

            <pmt-permit-basic-details [permitId]="permitId"></pmt-permit-basic-details>
            <pmt-permit-summary *ngIf="isCurrentTab(DetailsTab.summary)" [permitId]="permitId"></pmt-permit-summary>
            <pmt-export-permit *ngIf="isCurrentTab(DetailsTab.summary)" [permitId]="permitId">
            </pmt-export-permit>
            <pmt-details-team *ngIf="isCurrentTab(DetailsTab.team)" [permitId]="permitId"></pmt-details-team>
            <pmt-permit-comments *ngIf="isCurrentTab(DetailsTab.comments)" [permitId]="permitId"></pmt-permit-comments>
            <pmt-permit-notifications *ngIf="isCurrentTab(DetailsTab.notifications)" [permitId]="permitId"></pmt-permit-notifications>
            <pmt-details-photo *ngIf="isCurrentTab(DetailsTab.photos)" [permitId]="permitId"></pmt-details-photo>
            <pmt-permit-end-signature *ngIf="isCurrentTab(DetailsTab.complete)" [permitId]="permitId"></pmt-permit-end-signature>
        </ng-container>
    </div>
</ng-container>
