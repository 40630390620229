import { Routes } from "@angular/router";

import { PermitCreateComponent } from "./permit-create/components/permit-create/permit-create.component";
import { PreparePermitComponent } from "./permit-create/components/prepare-permit/prepare-permit.component";
import { ExportPermitComponent } from "./permit-details/components/export-permit/export-permit.component";
import { PreparedPermitListComponent } from "./permit-list/components/prepared-permit-list/prepared-permit-list.component";
import { PermitPaths } from "./permit-paths";
import { PermitRootComponent } from "./permit-root/components/permit-root.component";
import { PermitComponent } from "./permit/components/permit/permit.component";

export const routes: Routes = [
    {
        path: "",
        component: PermitRootComponent,
        data: { title: "Tillstånd" },
    },
    {
        path: PermitPaths.PreparedPermits,
        component: PreparedPermitListComponent,
        data: { title: "Förberedda tillstånd" },
    },
    {
        path: PermitPaths.Create,
        component: PermitCreateComponent,
        data: { title: "permit.createNew" }
    },
    {
        path: PermitPaths.CreateDemo,
        component: PermitCreateComponent,
        data: { title: "permit.createNewDemo" }
    },
    {
        path: PermitPaths.Prepare,
        component: PreparePermitComponent,
        data: { title: "Förbered tillstånd" },
        children: [
            {
                path: "",
                children: [
                    {
                        path: ":permitId",
                        component: PreparePermitComponent,
                        data: { autoBreadcrumbsDisabled: true }
                    }
                ]
            },
        ]
    },
    {
        path: ":permitId",
        data: { title: "Tillstånd" },
        children: [
            {
                path: "",
                children: [
                    {
                        path: "",
                        component: PermitComponent,
                    },
                    {
                        path: PermitPaths.Pdf,
                        component: ExportPermitComponent,
                        data: { autoBreadcrumbsDisabled: true }
                    },
                ],
            },
        ],
    },
];
