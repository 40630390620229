import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HaUiCommonModule } from "@ha/ui/common";
import { HaUiFormsModule } from "@ha/ui/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsModule } from "@ngxs/store";

import { ApplicationListModalComponent } from "./components/application-list-modal/application-list-modal.component";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import {
    ImpersonatedInformationPanelComponent,
} from "./components/impersonated-information-panel/impersonated-information-panel.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UiLanguageSelectorComponent } from "./components/ui-language-selector/ui-language-selector.component";
import { BreadcrumbState } from "./states/breadcrumb.state";
import { SidebarState } from "./states/sidebar.state";

export { SidebarComponent } from "./components/sidebar/sidebar.component";
export { UiLanguageSelectorComponent } from "./components/ui-language-selector/ui-language-selector.component";
export { SidebarState } from "./states/sidebar.state";
export {
    ImpersonatedInformationPanelComponent,
} from "./components/impersonated-information-panel/impersonated-information-panel.component";

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
        RouterModule,
        NgxsModule.forFeature([BreadcrumbState, SidebarState]),
        HaUiFormsModule,
        HaUiCommonModule,
    ],
    declarations: [
        BreadcrumbsComponent,
        SidebarComponent,
        UiLanguageSelectorComponent,
        ApplicationListModalComponent,
        ImpersonatedInformationPanelComponent
    ],
    exports: [
        BreadcrumbsComponent,
        SidebarComponent,
        UiLanguageSelectorComponent,
        ImpersonatedInformationPanelComponent
    ],
    providers: []
})
export class HaUiNavigationModule {}
