import { Injectable } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { Navigate } from "@ngxs/router-plugin";
import { Action, Actions, NgxsOnInit, ofActionSuccessful, State, StateContext, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { PermitUserService } from "../../shared/services/permit-user.service";
import { PermitUserActions } from "../../shared/states/permit-user.action";
import { PermitUserSelectors } from "../../shared/states/permit-user.selectors";
import {
    AcceptUserAgreementOnIssuerUnitModalComponent,
} from "../components/user-agreement-modal/accept-user-agreement-on-issuer-unit-modal.component";
import {
    AcceptUserAgreementWhenSignedInModalComponent,
    AcceptUserAgreementWhenSignedInModalComponentData,
} from "../components/user-agreement-modal/accept-user-agreement-when-signed-in-modal.component";
import { UserAgreement } from "../models/user-agreement";
import { UserAgreementService } from "../services/user-agreement.service";
import { UserAgreementActions } from "./user-agreement.actions";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserAgreementStateModel {
    agreement: UserAgreement;
}

@Injectable()
@State<UserAgreementStateModel>({
    name: "permitPersonUserAgreement",
})
export class UserAgreementState implements NgxsOnInit {
    constructor(
        private userAgreementService: UserAgreementService,
        private permitUserService: PermitUserService,
        private actions$: Actions,
        private store: Store,
        private modalService: HaModalService
    ) {}

    public ngxsOnInit(ctx?: StateContext<UserAgreementStateModel>): void {
        // Wait to load current person
        this.actions$.pipe(ofActionSuccessful(PermitUserActions.GetCurrentPerson)).subscribe(() => {
            const currentPerson = this.store.selectSnapshot(PermitUserSelectors.currentPerson);
            const haveAccessToPermit = this.store.selectSnapshot(PermitUserSelectors.haveAccessToPermit);

            // If user has access to permit
            // and person is missing in permit service or havent accepted agreement
            if (haveAccessToPermit && (!currentPerson || (currentPerson && currentPerson.hasValidCertificate && !currentPerson.hasAcceptedUserAgreement))) {
                // Get agreement text and open agreement modal
                ctx.dispatch(new UserAgreementActions.OpenUserAgreementWhenSignedInModal());
            }
        });
    }

    @Action(UserAgreementActions.OpenUserAgreementWhenSignedInModal)
    public openUserAgreementWhenSignedIn(ctx: StateContext<UserAgreementStateModel>): Observable<UserAgreement> {
        const isExternal = !!this.permitUserService.currentUser.isExternal;
        return this.getUserAgreement(ctx, isExternal).pipe(tap((userAgreement) => {
            this.modalService.open(AcceptUserAgreementWhenSignedInModalComponent, false, <AcceptUserAgreementWhenSignedInModalComponentData>{ userAgreement: userAgreement });
        }));
    }

    @Action(UserAgreementActions.AcceptUserAgreementWhenSignedIn)
    public acceptUserAgreementWhenSignedIn(ctx: StateContext<UserAgreementStateModel>): Observable<boolean> {
        return this.userAgreementService.acceptUserAgreementWhenSignedIn(ctx.getState().agreement.type).pipe(tap(() => {
            ctx.dispatch(new PermitUserActions.GetCurrentPerson());
        }));
    }

    @Action(UserAgreementActions.RejectAgreementWhenSignedIn)
    public rejectAgreementWhenSignedIn(ctx: StateContext<UserAgreementStateModel>): void {
        ctx.dispatch(new Navigate(["/"]));
    }

    @Action(UserAgreementActions.OpenUserAgreementOnIssuerUnit)
    public openUserAgreementOnIssuerUnit(ctx: StateContext<UserAgreementStateModel>, action: UserAgreementActions.OpenUserAgreementOnIssuerUnit): Observable<UserAgreement> {
        return this.getUserAgreement(ctx, action.isExternal).pipe(tap((userAgreement) => {
            this.modalService.open(AcceptUserAgreementOnIssuerUnitModalComponent, false, <AcceptUserAgreementWhenSignedInModalComponentData>{ userAgreement: userAgreement });
        }));
    }

    private getUserAgreement(ctx: StateContext<UserAgreementStateModel>, isExternal: boolean): Observable<UserAgreement> {
        return this.userAgreementService.getUserAgreement(isExternal)
            .pipe(tap(agreement => {
                ctx.setState({
                    agreement: agreement,
                });
            }));
    }
}
