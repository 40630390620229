<div class="root" *transloco="let t; read:'permit.team.noFireWatcher'">
    <ng-container *ngIf="permitIssuer$ | async as permitIssuer; else noIssuer">
        <div class="root__description" [innerHtml]="t('description', { name: permitIssuer.firstName + ' ' + permitIssuer.lastName })"></div>
        <form [formGroup]="form" class="root__form">
            <ha-checkbox formControlName="notNeeded" [label]="t('noFireWatcher')"></ha-checkbox>
            <ha-input type="text" formControlName="comment" [label]="t('noFireWatcherComment')"></ha-input>
        </form>
    </ng-container>
    <ng-template #noIssuer>
        <div class="root__missing-issuer">{{t("missingIssuer")}}</div>
    </ng-template>

    <div class="root__actions">
        <button class="root__actions__abort" type="button" (click)="cancel()">{{ t("cancel") }}</button>
        <button class="root__actions__save" type="button" (click)="save()" [class.root__actions__save--disabled]="!form || !form.valid">
            {{ t("save") }}
        </button>
    </div>
</div>
