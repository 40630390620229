import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";

import { PermitPersonSignature } from "../../models/permit-person.model";

export interface ViewSignatureModalComponentData {
    name: string;
    signature: PermitPersonSignature;
}

@Component({
    templateUrl: "view-signature-modal.component.html",
    styleUrls: ["view-signature-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ViewSignatureModalComponent extends BaseModalComponent {
    public data: ViewSignatureModalComponentData;

    constructor() {
        super();
    }
    public close() {
        this.closeModal();
    }
}
