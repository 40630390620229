import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { EpiserverService } from "@ha/feature/episerver";
import { LanguageStateSelectors } from "@ha/ui/common";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { NotificationConfiguration } from "../models/notification-configuration.model";
import { NotificationPage } from "../models/notification-page";
import { Notification } from "../models/notification.model";
import { SetNotificationStatusCommand } from "../models/set-notification-status-command.model";

export const NOTIFICATION_CONFIG = new InjectionToken<NotificationConfiguration>("NotificationConfiguration");

@Injectable({ providedIn: "root" })
export class NotificationService {
    private readonly rootUrl = "/sv/kunskapsbank/";

    private readonly angularModuleRoute = "knowledgebank";

    constructor(
        private httpClient: HttpClient,
        private episerverService: EpiserverService,
        private store: Store,
        @Inject(NOTIFICATION_CONFIG) private notificationConfig: NotificationConfiguration,
    ) {}

    public get currentLanguage() {
        return this.store.selectSnapshot(LanguageStateSelectors.currentLanguage);
    }

    public getAll(): Observable<Notification[]> {
        const options = {
            params: {
                languageCode: this.currentLanguage,
                application: this.notificationConfig.ApplicationFlags,
            },
        };

        return this.httpClient.get<Notification[]>("/api/Notification/GetAll", options);
    }

    public getUnreadNotifications(): Observable<Notification[]> {
        const options = {
            params: {
                languageCode: this.currentLanguage,
                application: this.notificationConfig.ApplicationFlags,
            },
        };

        return this.httpClient.get<Notification[]>("/api/Notification/GetUnreadNotifications", options);
    }

    public getUnseenNotificationCount(): Observable<number> {
        const options = {
            params: {
                languageCode: this.currentLanguage,
                application: this.notificationConfig.ApplicationFlags,
            },
        };

        return this.httpClient.get<number>("/api/Notification/UnseenNotificationCount", options);
    }

    public getNotificationPage(notificationId: number): Observable<NotificationPage> {
        return this.episerverService.getContentById(notificationId, this.rootUrl, this.angularModuleRoute);
    }

    public setStatusForNotifications(command: SetNotificationStatusCommand): Observable<boolean> {
        return this.httpClient.post<boolean>("/api/Notification/CreateOrUpdate", command);
    }
}
