<ng-container>
    <div class="modal" *transloco="let t; read:'permit.subscriptions.answerModal'">
        <div class="modal__content" [innerHtml]="t('answerText', { name: data.invitation.subscriptionName })"></div>

        <div class="modal__actions">
            <button class="modal__actions__deny" type="button" (click)="deny()">{{ t("deny") }}</button>
            <button class="modal__actions__accept" type="button" (click)="accept()">{{ t("accept") }}</button>
        </div>
    </div>
</ng-container>
