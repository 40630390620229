import { Selector } from "@ngxs/store";

import { Account, AccountUser } from "../models/current-account.model";
import { AuthState, AuthStateModel } from "./auth.state";

export class CurrentAccountSelectors {
    @Selector([AuthState])
    public static currentAccount(state: AuthStateModel): Account | undefined {
        return state?.currentAccount;
    }

    @Selector([CurrentAccountSelectors.currentAccount])
    public static isAdministrator(account: Account | undefined): boolean | undefined {
        return account?.isAdministrator;
    }

    @Selector([CurrentAccountSelectors.currentAccount])
    public static isSuperman(account: Account | undefined): boolean | undefined {
        return account?.isSuperman;
    }

    @Selector([CurrentAccountSelectors.currentAccount])
    public static isAdministratorOrImpersonated(account: Account | undefined): boolean | undefined {
        if (account === undefined) {
            return undefined;
        }

        return account && (account.isAdministrator || account.isImpersonated);
    }

    @Selector([CurrentAccountSelectors.currentAccount])
    public static currentAccountUser(account: Account | undefined): AccountUser | undefined {
        return account?.user;
    }

    @Selector([CurrentAccountSelectors.currentAccount])
    public static currentAccountRealUser(account: Account | undefined): AccountUser | undefined {
        return account?.realUser;
    }
}
