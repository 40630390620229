<offline-signed-out></offline-signed-out>
<splash-screen></splash-screen>
<errors></errors>
<install-modal></install-modal>

<div class="screen-size">{{ screenSize }}</div>

<!-- Normal -->
<div class="app app--authenticated" *ngIf="showApp$ | async">
    <main-navigation class="app__navigation"></main-navigation>
    <header class="app__header"></header>
    <mobile-header></mobile-header>
    <div class="app__content">
        <div class="app__content__main">
            <router-outlet></router-outlet>
        </div>
        <ha-sidebar class="app__content__sidebar">
            <bookmarks-panel top></bookmarks-panel>
        </ha-sidebar>
    </div>
    <app-mobile-navigation></app-mobile-navigation>
</div>

<!-- Not authenticated -->
<div class="app" *ngIf="(isAuthenticated$ | async) === false">
    <mobile-header></mobile-header>
    <div class="app__content">
        <div class="app__content__main">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<!-- Authenticated but no user -->
<div class="app" *ngIf="(isAuthenticated$ | async) === true && (noUserFound$ | async) === true">
    <div class="app__content">
        <div class="app__content__main">
            <app-no-access></app-no-access>
        </div>
    </div>
</div>
