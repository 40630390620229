import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "ha-filter-menu",
    templateUrl: "./filter-menu.component.html",
    styleUrls: ["./filter-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuComponent {
    @Input() public filterCount: number;
    @Output() public clearFilter = new EventEmitter();

    public showMenu = false;

    public toggleMenu(event: MouseEvent): void {
        event.stopPropagation();

        this.showMenu = !this.showMenu;
    }

    public hideMenu(): void {
        this.showMenu = false;
    }

    public clear() {
        this.clearFilter.emit();
    }
}
