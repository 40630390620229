import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { InputComponent } from "@ha/ui/forms";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PrefixWorkCodeResponse } from "../../models/prefix-work-code-response.model";
import { PreparePermitStep } from "../../models/prepare-permit-step.model";
import { PreparePermitActions } from "../../states/prepare-permit.actions";
import { PreparePermitSelectors } from "../../states/prepare-permit.selectors";

@Component({
    selector: "pmt-prepare-permit",
    templateUrl: "./prepare-permit.component.html",
    styleUrls: ["./prepare-permit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreparePermitComponent implements OnInit {
    @Select(PreparePermitSelectors.responseDetails)
    public responseDetails$: Observable<PrefixWorkCodeResponse>;

    @Select(PreparePermitSelectors.validCode)
    public validCode$: Observable<boolean>;

    @Select(PreparePermitSelectors.selectedWorkplaceId)
    public selectedWorkplaceId$: Observable<number>;

    @ViewChild("prefix") private prefixInput: InputComponent;

    public PreparePermitStep = PreparePermitStep;

    constructor(public fb: FormBuilder, public store: Store) { }

    public form = this.fb.group({
        prefix: ["", Validators.required],
        workCode: ["", Validators.required]
    });

    public ngOnInit(): void {
        this.store.dispatch(new PreparePermitActions.GetFixedTemplate());
        this.store.dispatch(new PreparePermitActions.ResetState());
    }

    public validate(): void {
        this.store.dispatch(new PreparePermitActions.GetWorkplaceContainerForPreparation(
            this.form.value.prefix,
            this.form.value.workCode
        ));

        this.form.disable();
    }

    public toggleWorkplace(workplaceId: number): void {
        this.store.dispatch(new PreparePermitActions.ToggleSelectedWorkplace(workplaceId));
    }

    public next(): void {
        this.store.dispatch(new PreparePermitActions.CreatePreparedPermit());
    }

    public changeWorkCode(): void {
        this.form.enable();
        this.form.reset();
        this.store.dispatch(new PreparePermitActions.ResetState());
        this.prefixInput.setFocus();
    }
}
