import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Pipe({ name: "haDate" })
export class HaDatePipe implements PipeTransform {
    public constructor(private translocoService: TranslocoService) {}

    public transform(value: string | undefined, format?: string, compareValue?: string): string {
        if (!value) {
            return "";
        }

        value = value.replace(" ", "T"); // Safari (and FireFox?) Date parse inconsistency
        const date = new Date(value);

        // Date for comparison. If compareValue is not specified, use new date.
        const compareWithDate = compareValue !== undefined ? new Date(compareValue.replace(" ", "T")) : new Date();

        const activeLang = this.translocoService.getActiveLang();

        if (format === "dateTimeStandard") {
            // Example: 2021-02-22 18:09
            return date.toLocaleDateString(activeLang, { hour: "2-digit", minute: "2-digit" });
        } else if (format === "time") {
            // Example: 18:09
            return date.toLocaleTimeString(activeLang, { hour: "2-digit", minute: "2-digit" });
        } else if (format === "date") {
            // Example: 2021-02-22
            return date.toLocaleDateString(activeLang, { year: "numeric", month: "2-digit", day: "2-digit" });
        } else if (format === "birthDate") {
            // Example: 2021-02-22
            return date.toLocaleDateString("sv", { year: "numeric", month: "2-digit", day: "2-digit" });
        } else if (format === "fullDateShortMonth") {
            // Example: 22 feb 2021
            return date.toLocaleDateString(activeLang, { year: "numeric", month: "short", day: "numeric" }).replace(/\./g, "");
        } else if (format === "fullDateShortMonthYearIfDiffering") {
            // Example: 22 feb [or] 22 feb 2021
            return date.toLocaleDateString(activeLang, {
                year: compareWithDate.getFullYear() !== date.getFullYear() ? "numeric" : undefined,
                month: "short",
                day: "numeric"
            }).replace(/\./g, "");
        } else if (format === "timeIfSameDayElseFullDate") {
            // Example: 13:41 [or] 2022-03-30 13:41
            const dayDiffers = compareWithDate.toDateString() !== date.toDateString();

            return dayDiffers
                ? date.toLocaleDateString(activeLang, { hour: "2-digit", minute: "2-digit" })
                : date.toLocaleTimeString(activeLang, { hour: "2-digit", minute: "2-digit" });
        } else if (format === "dateTimeLongMonthNoYear") {
            // Example: 22 februari 18:09
            return date.toLocaleString(activeLang, {
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
        } else if (format === "dateTimeLongMonthWithYear") {
            // Example: 22 februari 2022 18:09
            return date.toLocaleString(activeLang, {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
        }  else if (format === "dayOfMonthAndShortMonth") {
            // Example: 22 feb
            return date.toLocaleDateString(activeLang, { month: "short", day: "numeric" }).replace(/\./g, "");
        } else if (format === "monthAndYear") {
            // Example: feb 2024
            return date.toLocaleDateString(activeLang, { month: "short", year: "numeric" });
        }

        return date.toLocaleDateString(activeLang);
    }
}
