import { ContentReference } from "@ha/feature/episerver";

export namespace BookmarksActions {
    export class GetBookmarks {
        public static readonly type = "[Bookmarks] GetBookmarks";
    }

    export class ToggleBookmark {
        public static readonly type = "[Bookmarks] ToggleBookmark";
        constructor(public contentLink: ContentReference) {}
    }

    export class ToggleBookmarksPanelExpanded {
        public static readonly type = "[Sidebar] ToggleBookmarksPanelExpanded";
    }
}
