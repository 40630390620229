<div *ngIf="validationResult && !validationResult.isValid" class="validation">
    <button type="button" (click)="previousError()" class="validation__button validation__button--previous"
            [class.validation__button--disabled]="!canChangeError"></button>

    <div *ngIf="validationResult.errors[this.errorIndex] as error" class="validation__message">
        {{ translateError(error) }}
    </div>

    <button type="button" (click)="nextError()" class="validation__button validation__button--next"
            [class.validation__button--disabled]="!canChangeError"></button>
</div>
