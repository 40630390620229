import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { BaseModalComponent, HaModalService } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";

import { PermitTeamActions } from "../../../../permit-team/states/permit-team.action";
import { PermitCreateSignPersonModalComponent, PermitCreateSignPersonModalComponentData } from "../../../../shared/components/permit-create-sign-person-modal/permit-create-sign-person-modal.component";
import { PermitPerson } from "../../../../shared/models/permit-person.model";

export interface PermitDetailsTeamRemoveOrSignModalComponentData {
    permitId: number;
    role: PersonRole;
    person: PermitPerson;
}

@UntilDestroy()
@Component({
    templateUrl: "permit-details-team-remove-or-sign.component.html",
    styleUrls: ["permit-details-team-remove-or-sign.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitDetailsTeamRemoveOrSignModalComponent extends BaseModalComponent implements OnInit {
    public data: PermitDetailsTeamRemoveOrSignModalComponentData;

    public isRemoveMode = false;

    constructor(private store: Store, private actions$: Actions, private modalService: HaModalService) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionSuccessful(PermitTeamActions.RemovePerson)).pipe(untilDestroyed(this)).subscribe(
            () => {
                this.closeModal();
            },
        );
    }

    public removePerson() {
        this.store.dispatch(
            new PermitTeamActions.RemovePerson({
                permitId: this.data.permitId,
                personId: this.data.person.personId,
                role: this.data.role,
            }),
        );
    }

    public openSignatureModal() {
        this.closeModal();
        this.modalService.open(
            PermitCreateSignPersonModalComponent,
            true,
            <PermitCreateSignPersonModalComponentData> {
                person: this.data.person,
                permitId: this.data.permitId,
                role: this.data.role,
            },
        );
    }
}
