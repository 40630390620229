<div *transloco="let t; read:'permit'" class="root">
    <ng-container *ngIf="fixedQuestion">
        <div *ngIf="fixedQuestion.translations as questionTranslations" class="root__question-text" [innerHTML]="questionTranslations | haTranslate"></div>
        <pmt-safety-rule class="root__safety-rule-anchor" [safetyRules]="fixedQuestion.safetyRules">
        </pmt-safety-rule>

        <div *ngIf="fixedQuestion.customerComment" class="root__comment">
            <p *ngIf="companyName$ | async as name"><em>{{ name }}</em></p>
            <p>{{ fixedQuestion.customerComment.translations | haTranslate }}</p>
        </div>

        <ng-template [ngTemplateOutlet]="choices"></ng-template>
    </ng-container>

    <ng-container *ngIf="customerQuestion">
        <div *ngIf="customerQuestion?.translations as checkpointTranslations" class="root__question-text" [innerHTML]="checkpointTranslations | haTranslate">
        </div>

        <pmt-safety-rule class="root__safety-rule-anchor" [safetyRules]="customerQuestion.safetyRules"></pmt-safety-rule>

        <div class="root__choices" *transloco="let t; read:'permit.choices'">
            <ng-container *ngFor="let choice of getCheckpointAlternativeNumbers(customerQuestion.alternatives)">
                <div class="root__choices__item" [class.root__choices__item--active]="currentCustomerAnswer === choice" (click)="select(choice)">
                    {{ t(choice.toString()) }}
                </div>
            </ng-container>
        </div>
    </ng-container>

    <pmt-permit-confirm-dialog class="root__alert" *ngIf="showConfirmDialog" [alert]="fixedQuestion.alert" (confirmedChange)="setAlertConfirmed($event)">
    </pmt-permit-confirm-dialog>

    <ng-template #choices>
        <div class="root__choices">
            <div class="root__choices__item" [class.root__choices__item--active]="currentCustomerAnswer === CustomerQuestionAnswer.Yes"
                 (click)="select(CustomerQuestionAnswer.Yes)">
                {{ t("yes") }}
            </div>
            <div class="root__choices__item" [class.root__choices__item--active]="currentCustomerAnswer === CustomerQuestionAnswer.No"
                 (click)="select(CustomerQuestionAnswer.No)">
                {{ t("no") }}
            </div>
        </div>
    </ng-template>

    <div class="root__actions">
        <button class="root__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
        <button class="root__actions__continue" type="button" (click)="continue()" [class.root__actions__continue--disabled]="!canProceed">
            {{ t("next") }}
        </button>
    </div>
</div>
