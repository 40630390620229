import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Actions, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";
import { PermitCreateActions } from "../../../permit-create/states/permit-create.actions";

@UntilDestroy()
@Component({
    selector: "pmt-valid-time",
    templateUrl: "./valid-time.component.html",
    styleUrls: ["./valid-time.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidTimeComponent implements OnInit {
    @Input() public default: number;
    @Input() public maxValue: number;
    @Input() public endDateTime: string;

    @Select(PermitCreateSelectors.isPremium)
    public isPremium$: Observable<boolean>;

    public validDateTime: string;

    public selectedValue: number;

    constructor(private store: Store, private actions$: Actions) { }

    public ngOnInit(): void {
        this.selectedValue = this.default;
        this.validDateTime = this.getDateTime(this.selectedValue);
    }

    public getDateTime(time: number): string {
        const newDate = new Date(new Date(this.endDateTime).setHours(new Date(this.endDateTime).getHours() + time));
        return newDate.toJSON();
    }

    public setValue(value: number): void {
        this.selectedValue = value;
        this.validDateTime = this.getDateTime(this.selectedValue);
    }
}
