import { HttpClient } from "@angular/common/http";
import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { Network } from "@ngx-pwa/offline";
import { Store } from "@ngxs/store";
import { DeviceDetectorService } from "ngx-device-detector";
import { concat, interval } from "rxjs";
import { first, take, filter } from "rxjs/operators";

import { BeforeInstallPromptEvent } from "../models/before-install-prompt-event";
import { PwaActions } from "../states/pwa.actions";

@Injectable({ providedIn: "root" })
export class PwaService {
    public static deferredPrompt?: BeforeInstallPromptEvent;

    private periodicUpgradeCheck = false;

    public get isRunningAsPwa(): boolean {
        return ["fullscreen", "standalone", "minimal-ui"].some((displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches,);
    }

    public get isDesktop(): boolean {
        return this.deviceService.isDesktop();
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public get isIos(): boolean {
        return this.deviceService.os === "iOS" || this.deviceService.os === "Mac";
    }

    public get isAndroid(): boolean {
        return this.deviceService.os === "Android";
    }

    public get isSafari(): boolean {
        return this.deviceService.browser === "Safari";
    }

    public get isChrome(): boolean {
        return this.deviceService.browser === "Chrome";
    }

    constructor(
        private app: ApplicationRef,
        private swUpdate: SwUpdate,
        private network: Network,
        private deviceService: DeviceDetectorService,
        private store: Store,
        private httpClient: HttpClient,
    ) {}

    public init(trackAppStarted: boolean): void {
        // Always check for updates on startup
        console.log("PWA: Initial update check..");
        this.pwaCheckForUpdate();

        // Check for PWA updates every minute
        interval(1 * 1 * 60 * 1000).subscribe(() => {
            console.log("PWA: Periodic update check..");
            this.periodicUpgradeCheck = true;
            this.pwaCheckForUpdate();
        });

        // When PWA have been updated
        this.swUpdate.versionUpdates
            .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
            .subscribe(() => {
            if (this.network.online) {
                void this.swUpdate.activateUpdate().then((success) => {
                    if (success) {
                        // Initial
                        console.log("PWA: New version is ready - please update");
                        if (!this.periodicUpgradeCheck) {
                            window.location.reload();
                        } else {
                            this.store.dispatch(new PwaActions.OpenModalIfUpdateAvailable());
                            this.store.dispatch(new PwaActions.SetUpdateAvailable());
                        }
                    }
                });
            }
        });

        window.addEventListener("beforeinstallprompt", function (beforeInstallPromptEvent) {
            // Prevent the mini-infobar from appearing on mobile
            beforeInstallPromptEvent.preventDefault();

            // Stash the event so it can be triggered later.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            PwaService.deferredPrompt = beforeInstallPromptEvent as BeforeInstallPromptEvent;
        });



        if (trackAppStarted) {
            // Track app install to backend
            window.addEventListener("appinstalled", () => {
                this.httpClient
                    .post("/api/pwa/AppInstalled", { userAgent: JSON.stringify(this.deviceService.getDeviceInfo()) })
                    .subscribe(() => {
                        location.href = "/";
                    });
            });

            // Track application starts to backend
            interval(3000)
                .pipe(take(1))
                .subscribe(() => {
                    if (this.network.online) {
                        this.httpClient
                            .post("/api/pwa/AppStarted", {
                                pwa: this.isRunningAsPwa,
                                userAgent: JSON.stringify(this.deviceService.getDeviceInfo()),
                            })
                            .subscribe();
                    }
                });
        }
    }

    public addToHomeScreen(): void {
        if (!PwaService.deferredPrompt) {
            return;
        }

    }

    private pwaCheckForUpdate(): void {
        void this.swUpdate.checkForUpdate();
    }
}
