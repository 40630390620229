<div class="find" *transloco="let t; read:'permit.changeIssuer.search'">
    <div class="find__information" [innerHtml]="t('information')"></div>
    <search-box class="find__search-box" (triggerSearch)="search($event)" [ngModel]="query$ | async" [placeholder]="t('search')">
    </search-box>
    <div class="find__search-loader" *ngIf="searching$ | async"></div>
    <div class="find__search-result" *ngIf="searchResultStatus$ | async as searchResultStatus">
        <ng-container *ngIf="searchResultStatus === CertificateStatus.ValidCertificate else noResult">
            <div *ngIf="(searchResult$ | async) as searchResult" class="find__search-result__issuer" (click)="toggleIssuer(searchResult)">
                <div class="find__search-result__issuer__details">
                    <div class="find__search-result__issuer__details__name">{{searchResult.fullName}}</div>
                    <div class="find__search-result__issuer__details__date"
                         [class.find__search-result__issuer__details__date--expiring]="searchResult.expiringSoon">
                        {{t('certifiedTo', { date:
                        searchResult.expirationDate | haDate:'date' })}}
                    </div>
                </div>
                <div class="find__search-result__issuer__checkmark"
                     [class.find__search-result__issuer__checkmark--selected]="(selectedNewIssuer$ | async) && (selectedNewIssuer$ | async).certificateId === searchResult.certificateId">
                </div>
            </div>
        </ng-container>
        <ng-template #noResult>
            <div *ngIf="(searching$ | async) === false" class="find__search-result__error">
                <span *ngIf="searchResultStatus === CertificateStatus.CertificateNotFound">{{t("errorMessages.notfound")}}</span>
                <span *ngIf="searchResultStatus === CertificateStatus.ExpiredCertificate">{{t("errorMessages.notvalid")}}</span>
                <span *ngIf="searchResultStatus === CertificateStatus.InvalidSearchTerm">{{t("errorMessages.invalidSearchTerm")}}</span>
                <span *ngIf="searchResultStatus === CertificateStatus.NotSelectable">{{t("errorMessages.notSelectable")}}</span>
            </div>
        </ng-template>
    </div>
</div>
