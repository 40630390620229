import { Injectable } from "@angular/core";
import { LanguageStateSelectors } from "@ha/ui/common";
import { Action, Selector, State, StateContext } from "@ngxs/store";

import { EpiserverAngularModuleConfig } from "../models/episerver-angular-module-config";
import { EpiserverStateActions } from "./episerver-state-actions";

export interface EpiserverStateModel {
    currentConfig: EpiserverAngularModuleConfig;
}

@State<EpiserverStateModel>({
    name: "episerver",
}) @Injectable()
export class EpiserverState {
    @Selector()
    public static currentConfig(state: EpiserverStateModel) {
        return state.currentConfig;
    }

    @Selector([EpiserverState, LanguageStateSelectors.currentLanguage])
    public static rootUrl(state: EpiserverStateModel, currentLanguage: string) {
        return state.currentConfig?.rootUrls.find((_) => _.lang === currentLanguage).url;
    }

    @Action(EpiserverStateActions.SetCurrentConfig)
    public setCurrentConfig(ctx: StateContext<EpiserverStateModel>, action: EpiserverStateActions.SetCurrentConfig) {
        ctx.patchState({
            currentConfig: action.config,
        });
    }
}
