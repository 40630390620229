<ng-container *transloco="let t; read:'permit.details.extensionModal'">
    <div class="modal">
        <div class="modal__content">
            <ng-container *ngIf="workplaceDetails$ | async as premiumPermitSetting; else loading">
                <ng-container *ngIf="permit$ | async as permitSetting">
                    <pmt-valid-time [endDateTime]="permitSetting.endDate" [default]="(premiumPermitSetting.permitDuration - permitSetting.validityPeriod) > 8 ? 8 : (premiumPermitSetting.permitDuration - permitSetting.validityPeriod)" [maxValue]="(premiumPermitSetting.permitDuration - permitSetting.validityPeriod)"></pmt-valid-time>
                </ng-container>
            </ng-container>
            <ng-template #loading>
                <div class="modal__loading"></div>
            </ng-template>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>

            <button class="modal__actions__continue" type="button" (click)="save()" [class.root__actions__continue--disabled]="!canProceed">
                {{ t("extend") }}
            </button>
        </div>
    </div>
</ng-container>
