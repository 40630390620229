import { Directive, EventEmitter, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy({ checkProperties: true })
@Directive()
export abstract class BaseModalComponent {
    @Output() public closeModalEvent = new EventEmitter<void>();

    public data: unknown;

    public busy = false;

    public closeModal(): void {
        this.closeModalEvent.emit();
    }
}
