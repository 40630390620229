<ng-container *transloco="let t; read:'permit.details.removeOrSign'">
    <div class="modal">
        <div class="modal__content">
            <div class="modal__content__text" [innerHtml]="t('text', { name: this.data.person.firstName + ' ' + this.data.person.lastName })">
            </div>
            <button class="modal__content__sign-button" (click)="openSignatureModal()">{{ t("signButton") }}</button>

            <button class="modal__content__remove-button" *ngIf="!isRemoveMode" (click)="isRemoveMode = true">
                {{ t("removeButton") }}
            </button>

            <div class="modal__content__remove-question" *ngIf="isRemoveMode">
                <span class="modal__content__remove-question__question">{{ t("removeQuestion") }}</span>
                <span class="modal__content__remove-question__yes" (click)="removePerson()">{{ t("removeYes") }}</span>
                <span class="modal__content__remove-question__no" (click)="isRemoveMode = false">{{ t("removeNo") }}</span>
            </div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
        </div>
    </div>
</ng-container>
