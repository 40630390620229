export namespace CertificateStateActions {
    export class GetCard {
        public static readonly type = "[Certificate] GetCard";
    }

    export class GetCertificate {
        public static readonly type = "[Certificate] GetCertificate";
    }

    export class Clear {
        public static readonly type = "[Certificate] Clear";
    }
}
