import { Component, ChangeDetectionStrategy } from "@angular/core";

@Component({
    selector: "pmt-prepare-completed",
    templateUrl: "./prepare-completed.component.html",
    styleUrls: ["./prepare-completed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepareCompletedComponent {
}
