import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PermitStep } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { CommentDto, PermitCommentsDto } from "../../models/permit-comments-dto";
import { PermitDetailsActions } from "../../states/permit-details.action";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";
import {
    PermitCommentCreateComponent,
    PermitCommentCreateComponentData,
} from "./permit-comment-create-modal/permit-comment-create-modal.component";

@Component({
    selector: "pmt-permit-comments",
    templateUrl: "permit-comments.component.html",
    styleUrls: ["permit-comments.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitCommentsComponent implements OnInit {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.comments)
    public comments$: Observable<PermitCommentsDto>;

    @Select(PermitStepSelectors.currentStep)
    public currentStep$: Observable<CurrentStep>;

    @Select(PermitDetailsSelectors.currentUserIsObserver)
    public currentUserIsObserver$: Observable<boolean>;

    public PermitStep = PermitStep;

    public avatarId: number;

    constructor(private store: Store, private modalService: HaModalService) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitDetailsActions.GetComments(this.permitId));
    }

    public getCommentsWithAvatarId(comments: CommentDto[]): CommentDto[] {
        this.avatarId = 0;

        const commentsWithAvatarId = comments.map((item) => Object.assign({}, item, { avatarId: undefined }));

        commentsWithAvatarId.forEach((comment, index, array) => {
            array[index].avatarId = <string>array.find(_ => _.personId === comment.personId && _.avatarId !== undefined)?.avatarId ?? (++this.avatarId).toString();
        });

        return commentsWithAvatarId;
    }

    public createComment() {
        this.modalService.open(PermitCommentCreateComponent, true, <PermitCommentCreateComponentData>{ permitId: this.permitId });
    }
}
