import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GeocodingResult } from "../models/geocoding-result.model";

const url = "https://maps.googleapis.com/maps/api/geocode/json";

@Injectable({ providedIn: "root" })
export class GoogleGeolocationService {
    constructor(private httpClient: HttpClient) {}

    public getReverseGeocodeAddress(lat: number, long: number, key: string): Observable<GeocodingResult> {
        let params = new HttpParams();
        params = params.append("latlng", `${lat},${long}`);
        params = params.append("key", key);
        params = params.append("result_type", "street_address");

        return this.httpClient.get<GeocodingResult>(url, { params });
    }
}
