<ng-container *transloco="let t; read:'permit.list.removePermit'">
    <div class="modal">
        <div class="modal__content">
            <div class="modal__content__question" [innerHTML]="t('confirmQuestion', { workplace: data.workplace })"></div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__remove" type="button" (click)="removePermit()">{{ t("remove") }}</button>
        </div>
    </div>
</ng-container>
