import { SubscriptionInvitation } from "../models/subscription-invitation";

export namespace SubscriptionsActions {

    export class GetActiveAndPendingSubscriptions {
        public static readonly type = "[Subscriptions] GetInvitations";
    }

    export class AnswerInvitation {
        public static readonly type = "[Subscriptions] AnswerInvitation";
        constructor(public subscriptionInvitation: SubscriptionInvitation, public accept: boolean) {}
    }
}
