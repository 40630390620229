<ng-container *ngIf="fireAlarmInformation$ | async as fireAlarmInformation">
    <div class="root" *transloco="let t; read:'permit'">
        <div class="root__question-text" [innerHTML]="fireAlarmInformation.translations | haTranslate"></div>

        <pmt-safety-rule [safetyRules]="fireAlarmInformation.safetyRules"></pmt-safety-rule>

        <div *ngIf="comment" class="root__comment" [innerHTML]="comment"></div>

        <div class="root__choices">
            <div class="root__choices__item" [class.root__choices__item--active]="answerIsYes === true" (click)="select(true)">{{ t("yes") }}</div>
            <div class="root__choices__item" [class.root__choices__item--active]="answerIsYes === false" (click)="select(false)">{{ t("no") }}</div>
        </div>

        <ng-container *ngIf="answerIsYes === true">
            <div class="root__info-text">
                <p>{{ t("fireAlarm.infoText") }}</p>
                <p *ngIf="(workplaceDetails$ | async)?.operationsManager as manager">
                    {{ t('fireAlarm.operationsManager', { manager: manager }) }}
                </p>
            </div>

            <form [formGroup]="form">
                <ha-input class="root__input" formControlName="fireAlarm" [label]="t('fireAlarm.input')"></ha-input>
            </form>

            <pmt-permit-confirm-dialog class="root__alert" [alert]="fireAlarmInformation.alert" [(confirmed)]="alertConfirmed">
            </pmt-permit-confirm-dialog>
        </ng-container>

        <div class="root__actions">
            <button class="root__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
            <button class="root__actions__continue" type="button" (click)="continue()" [class.root__actions__continue--disabled]="!canProceed">
                {{ t("next") }}
            </button>
        </div>
    </div>
</ng-container>
