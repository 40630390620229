<ng-container *ngIf="(currentUserIsExternal$ | async) === false">
    <ng-container *transloco="let t; read: 'bookmarks'">
        <div class="bookmark-panel" [class.bookmark-panel--expanded]="(bookmarksExpanded$ | async) && (bookmarksCount$ | async) > 0"
             [class.bookmark-panel--empty]="(bookmarksCount$ | async) < 1">
            <div class="bookmark-panel__header" (click)="toggleBookmarksOpen()">
                <div class="bookmark-panel__header__heading">
                    {{ t("panelHeading") }} <div class="bookmark-panel__header__counter">{{ bookmarksCount$ | async }}</div>
                </div>
                <div class="bookmark-panel__header__expander"></div>
            </div>
            <div class="bookmark-panel__content">
                <bookmarks direction="vertical"></bookmarks>
            </div>
        </div>
    </ng-container>
</ng-container>
