export namespace KnowledgeBankActions {
    export class Init {
        public static readonly type = "[KnowledgeBank] Init";
    }

    export class GetMenuSide {
        public static readonly type = "[KnowledgeBank] GetMenu";
    }

    export class GetStartPageSections {
        public static readonly type = "[KnowledgeBank] GetStartPageSections";
    }

    export class SearchInit {
        public static readonly type = "[KnowledgeBank] SearchInit";
    }

    export class SearchClearQuery {
        public static readonly type = "[KnowledgeBank] SearchClearQuery";
    }

    export class Search {
        public static readonly type = "[KnowledgeBank] Search";
        constructor(public query: string) {}
    }

    export class SearchLoadMore {
        public static readonly type = "[KnowledgeBank] SearchLoadMore";
    }

    export class GetNavigationLinks {
        public static readonly type = "[KnowledgeBank] GetNavigationLinks";
        constructor(public pageReferenceId: number) {}
    }
}
