import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PreparedPermitListItem } from "../../models/prepared-permit-list-item.model";
import { PermitListActions } from "../../states/permit-list.actions";
import { PermitListSelectors } from "../../states/permit-list.selectors";


@Component({
    selector: "pmt-prepared-permit-list",
    templateUrl: "./prepared-permit-list.component.html",
    styleUrls: ["./prepared-permit-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreparedPermitListComponent implements OnInit {
    @Select(PermitListSelectors.preparedPermits)
    public permits$!: Observable<PreparedPermitListItem[]>;

    constructor(private store: Store, private location: Location) { }

    public ngOnInit(): void {
        this.store.dispatch(new PermitListActions.GetAllPrepared());
    }

    public refresh(): void {
        this.store.dispatch(new PermitListActions.GetAllPrepared());
    }

    public cancel(): void {
        this.location.back();
    }
}
