import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitNotificationsDto } from "../../models/permit-notifications-dto";
import { PermitDetailsActions } from "../../states/permit-details.action";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";

@Component({
    selector: "pmt-permit-notifications",
    templateUrl: "permit-notifications.component.html",
    styleUrls: ["permit-notifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitNotificationsComponent implements OnInit {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.notifications)
    public notifications$: Observable<PermitNotificationsDto>;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitDetailsActions.GetNotifications(this.permitId));
    }
}
