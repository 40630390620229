import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";
import { take } from "rxjs";

import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PreparePermitActions } from "../../states/prepare-permit.actions";

@UntilDestroy()
@Component({
    selector: "pmt-select-role",
    templateUrl: "./select-role.component.html",
    styleUrls: ["./select-role.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectRoleComponent implements OnInit {
    @Input() public permitId: number;

    public selectedRoles: PersonRole[] = [];
    public PersonRole = PersonRole;

    constructor(public store: Store, private actions$: Actions) {}

    public get canContinue(): boolean {
        return this.selectedRoles.length > 0;
    }

    public ngOnInit(): void {
        this.actions$.pipe(ofActionSuccessful(PreparePermitActions.SelectRole))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                this.store.dispatch(new PermitStepActions.GoForward());
            });
    }

    public toggleRole(role: PersonRole): void {
        if (this.selectedRoles.includes(role)) {
            const i = this.selectedRoles.indexOf(role);
            this.selectedRoles.splice(i, 1);
        } else {
            this.selectedRoles.push(role);
        }
    }

    public roleSelected(role: PersonRole): boolean {
        return this.selectedRoles.includes(role);
    }

    public lockedByAnotherRole(role: PersonRole): boolean {
        return this.selectedRoles.includes(PersonRole.FireWatcher) && role === PersonRole.HotWorker
            || this.selectedRoles.includes(PersonRole.HotWorker) && role === PersonRole.FireWatcher;
    }

    public translateRoleEnum(role: PersonRole): string {
        return "selectRole.roles." + `${role}`;
    }

    public continue(): void {
        this.store.dispatch(new PreparePermitActions.SelectRole(this.permitId, this.selectedRoles));
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards());
    }
}
