import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitToSign } from "../../../shared/models/permit-to-sign.model";
import { PermitUserActions } from "../../../shared/states/permit-user.action";
import { PermitUserSelectors } from "../../../shared/states/permit-user.selectors";
import { SignPersonModalComponent, SignPersonModalComponentData } from "../sign-person-modal/sign-person-modal.component";

@UntilDestroy()
@Component({
    selector: "pmt-sign-permits-notice",
    templateUrl: "sign-permits-notice.component.html",
    styleUrls: ["sign-permits-notice.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignPermitsNoticeComponent implements OnInit {
    @Select(PermitUserSelectors.permitsToSign)
    public permitsToSign$: Observable<PermitToSign[]>;

    @Select(PermitUserSelectors.permitsToClaim)
    public permitsToClaim$: Observable<PermitToSign[]>;

    public PersonRole = PersonRole;

    constructor(private store: Store, private modalService: HaModalService, private actions$: Actions) {}

    public ngOnInit(): void {
        this.getPermitsToSign();

        this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(PermitUserActions.GetCurrentPerson)).subscribe(() => {
            if (this.store.selectSnapshot(PermitUserSelectors.currentPerson)) {
                this.getPermitsToSign();
            }
        });

        this.actions$.pipe(ofActionSuccessful(PermitTeamActions.SignPermitWithSignature))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new PermitUserActions.GetCurrentPerson());
            });
    }

    public getPermitsToSign(): void {
        this.store.dispatch(new PermitUserActions.GetPermitsToSign());
    }

    public signPermit(permit: PermitToSign) {
        const currentPerson = this.store.selectSnapshot(PermitUserSelectors.currentPerson);
        this.modalService.open(
            SignPersonModalComponent,
            true,
            <SignPersonModalComponentData>{
                person: currentPerson,
                permitId: permit.permitId,
                role: permit.role,
                name: `${currentPerson.firstName} ${currentPerson.lastName}`
            }
        );
    }
}
