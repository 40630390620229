import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { LanguageStateSelectors } from "@ha/ui/common";
import { Store } from "@ngxs/store";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, mergeMap, switchMap, take } from "rxjs/operators";

import { EpiserverAngularModuleConfig } from "../models/episerver-angular-module-config";
import { IContent } from "../models/episerver-base-types.model";
import { EpiserverService } from "./episerver.service";

@Injectable({ providedIn: "root" })
export abstract class EpiserverContentResolver implements Resolve<IContent> {
    protected abstract angularModuleRoute: string;

    constructor(private episerverService: EpiserverService, private store: Store) {
        // this.store.select(this.getConfigStateSelector()).pipe(filter((_) => !!_), take(1)).subscribe((_) =>
        //     this.store.dispatch(new EpiserverStateActions.SetCurrentConfig(_))
        // );
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<IContent> {
        const settings$ = combineLatest([
            this.store.select(LanguageStateSelectors.currentLanguage).pipe(filter((_) => !!_)),
            this.store.select(this.getConfigStateSelector()).pipe(filter((_) => !!_)),
        ]).pipe(take(1));

        const currentLangData$ = settings$.pipe(
            map(([lang, config]) => config.rootUrls.find((_) => _.lang === lang).url),
            map((langUrl) => ({ langUrl, fullUrl: langUrl + route.url.join("/") })),
            mergeMap((_) =>
                this.episerverService.getContentByUrl<IContent>(_.fullUrl, _.langUrl, this.angularModuleRoute)
            ),
            take(1),
        );

        const secondLangData$ = settings$.pipe(
            map(([lang, config]) => config.rootUrls.find((_) => _.lang !== lang).url),
            map((langUrl) => ({ langUrl, fullUrl: langUrl + route.url.join("/") })),
            mergeMap((_) =>
                this.episerverService.getContentByUrl<IContent>(_.fullUrl, _.langUrl, this.angularModuleRoute)
            ),
            take(1),
        );

        return currentLangData$.pipe(switchMap((_) => (_ ? of(_) : secondLangData$)));
    }

    protected abstract getConfigStateSelector(): (state: unknown, ...states: unknown[]) => EpiserverAngularModuleConfig;
}
