import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { Action, NgxsOnInit, State, StateContext } from "@ngxs/store";
import { produce } from "immer";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, mergeAll, take, tap, toArray } from "rxjs/operators";

import { createBreadcrumbs } from "../components/breadcrumbs/create-breadcrumb";
import { Breadcrumb } from "../models/breadcrumb.model";
import { BreadcrumbActions } from "./breadcrumb.actions";

export interface BreadcrumbStateModel {
    breadcrumbs: Breadcrumb[];
}

@Injectable() @State<BreadcrumbStateModel>({ name: "breadcrumb" })
export class BreadcrumbState implements NgxsOnInit {
    constructor(private router: Router, private titleService: Title, private translocoService: TranslocoService) {}

    public ngxsOnInit(ctx: StateContext<BreadcrumbStateModel>): void {
        combineLatest([
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
            this.translocoService.events$.pipe(filter((_) => !!_)),
        ]).subscribe(() => {
            let route = this.router.routerState.root;
            while (route.firstChild) {
                if (route.snapshot.data["autoBreadcrumbsDisabled"]) {
                    return;
                }

                route = route.firstChild;
            }

            ctx.dispatch(new BreadcrumbActions.Set(this.createBreadCrumbs()));
        });
    }

    @Action(BreadcrumbActions.Set)
    public set(ctx: StateContext<BreadcrumbStateModel>, action: BreadcrumbActions.Set) {
        return this.translateBreadcrumbs(action.breadcrumbs).pipe(
            take(1),
            tap((breadcrumbs) => {
                ctx.setState({
                    breadcrumbs: breadcrumbs ?? [],
                });

                this.prepareBrowerTitle(breadcrumbs);
            }),
        );
    }

    /**
     * Set the text or langKey of current breadcrumbs.
     *
     * @param {StateContext<BreadcrumbStateModel>} ctx
     * @param {BreadcrumbActions.SetTextOrLangKey} action
     * @memberof BreadcrumbState
     */
    @Action(BreadcrumbActions.SetTextOrLangKey)
    public setTextOrLangKey(
        ctx: StateContext<BreadcrumbStateModel>,
        action: BreadcrumbActions.SetTextOrLangKey,
    ): Observable<Breadcrumb[]> {
        return this.translateBreadcrumbs(this.createBreadCrumbs()).pipe(
            take(1),
            tap((breadcrumbs) => {
                ctx.setState({
                    breadcrumbs: breadcrumbs ?? [],
                });

                ctx.setState(produce(ctx.getState(), (draft) => {
                    action.names.reverse().forEach((_, i) => {
                        if (!draft.breadcrumbs[draft.breadcrumbs.length - i - 1]) {
                            return;
                        }

                        draft.breadcrumbs[draft.breadcrumbs.length - i - 1].langKey = undefined;

                        if (_.langKey) {
                            draft.breadcrumbs[draft.breadcrumbs.length - i - 1].text = this.translocoService.translate<
                                string
                            >(_.langKey);
                        } else if (_.text) {
                            draft.breadcrumbs[draft.breadcrumbs.length - i - 1].text = _.text;
                        }
                    });
                }));

                this.prepareBrowerTitle(ctx.getState().breadcrumbs);
            }),
        );
    }

    private prepareBrowerTitle(breadcrumbs: Breadcrumb[] | undefined): void {
        if (!breadcrumbs) {
            return;
        }

        const breadcrumb = breadcrumbs[breadcrumbs.length - 1];

        if (breadcrumb.langKey) {
            this.translocoService.selectTranslate(breadcrumb.langKey).pipe(take(1)).subscribe((translation: string) => {
                this.setBrowserTitle(translation.replace(/<.*?>/g, ""), breadcrumb.url !== "");
            });
        } else if (breadcrumb.text) {
            this.setBrowserTitle(breadcrumb.text.replace(/<.*?>/g, ""), breadcrumb.url !== "");
        }
    }

    private setBrowserTitle(title: string, includeAppTitle: boolean = true) {
        if (includeAppTitle) {
            this.translocoService.selectTranslate("appName").pipe(take(1)).subscribe((translation: string) => {
                this.titleService.setTitle(`${title} – ${translation.replace(/<.*?>/g, "")}`);
            });
        } else {
            this.titleService.setTitle(title);
        }
    }

    private createBreadCrumbs() {
        const breadcrumbs = createBreadcrumbs(this.router.routerState.root);

        if (breadcrumbs?.length === 0) {
            breadcrumbs.push({ langKey: "appName", url: "", text: "", hasDetailsLink: true, });
        }

        return breadcrumbs ?? [];
    }

    private translateBreadcrumbs(breadcrumbs: Breadcrumb[] | undefined): Observable<Breadcrumb[]> {
        if (!breadcrumbs) {
            return of([]);
        }

        return combineLatest(breadcrumbs.map((_) => this.translocoService.selectTranslate<string>(_.langKey ?? "")))
            .pipe(
                take(1),
                mergeAll(),
                map((_) => (_ === "" ? undefined : _)),
                map((translation, i) => (<Breadcrumb> {
                    url: breadcrumbs[i].url,
                    text: translation ?? breadcrumbs[i].text,
                    translationData: breadcrumbs[i].translationData,
                    highlighted: breadcrumbs[i].highlighted,
                    hasDetailsLink: breadcrumbs[i].hasDetailsLink,
                })),
                toArray(),
            );
    }
}
