import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable, take } from "rxjs";
import { NotificationStatus } from "../../models/notification-status.enum";
import { Notification } from "../../models/notification.model";
import { NotificationActions } from "../../states/notification.actions";
import { NotificationSelectors } from "../../states/notification.selectors";

@UntilDestroy()
@Component({
    templateUrl: "./unread-notifications-modal.component.html",
    styleUrls: ["./unread-notifications-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnreadNotificationsModalComponent extends BaseModalComponent implements OnInit {
    @Select(NotificationSelectors.unreadNotifications)
    public notifications$: Observable<Notification[]>;

    @Select(NotificationSelectors.loading)
    public loading$: Observable<boolean>;

    public NotificationStatus = NotificationStatus;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit(): void {
        this.store.dispatch(new NotificationActions.GetUnreadNotifications());

        this.actions$.pipe(ofActionSuccessful(NotificationActions.SetStatusForAllUnread))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                this.store.dispatch(new NotificationActions.GetUnreadNotifications());
            });
    }

    public markAllAsRead(): void {
        this.store.dispatch(new NotificationActions.SetStatusForAllUnread(NotificationStatus.Read));
    }
}
