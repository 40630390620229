<div class="root" *transloco="let t; read:'permit'">
    <h2 class="root__question-text" [innerHTML]="t('validTime.headingExtension')"></h2>

    <div *ngIf="(isPremium$ | async) === true" class="root__info"[innerHTML]="t('validTime.premiumInfoExtension', { time: maxValue.toString() })"></div>

    <pmt-time-stepper [label]="t('validTime.inputExtension')" [default]="default" [minValue]="1" [maxValue]="(isPremium$ | async) === true ? maxValue : 168"
                      (selectedValue)="setValue($event)" [smallStep]="1" [bigStep]="2">
    </pmt-time-stepper>
    <div class="root__date" [innerHTML]="t('validTime.dateExtension', { date: validDateTime | haDate: 'dateTimeStandard' })"></div>

    <!-- <div class="root__actions">
        <button class="root__actions__continue" type="button" (click)="save()" [class.root__actions__continue--disabled]="!canProceed">
            {{ t("validTime.save") }}
        </button>
    </div> -->
</div>
