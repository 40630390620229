import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "haPersonalNumber" })
export class HaPersonalNumberPipe implements PipeTransform {
    public transform(key: string): string {
        if (key) {
            key = key.replace(/\D/g, "");

            if (key.length === 12) {
                return key.substring(0, 8) + "-" + key.substring(8, 12);
            } else {
                return "-";
            }
        } else {
            return "-";
        }
    }
}
