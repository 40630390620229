import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PermitPublicStatus } from "@ha/data/basic";

@Component({
    selector: "pmt-permit-public-status",
    templateUrl: "./permit-public-status.component.html",
    styleUrls: ["./permit-public-status.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitPublicStatusComponent {
    @Input() public status: PermitPublicStatus;
}
