<div class="header" [class.header--open]="menuOpen$ | async">
    <div *transloco="let t;" class="header__content">
        <div class="header__content__icon"></div>
        <div class="header__content__heading" *ngIf="(menuOpen$ | async) === false">
            <div *ngIf="breadcrumb$ | async as breadcrumb" class="header__content__heading__text"
                 [innerHtml]="breadcrumb.langKey ? t(breadcrumb.langKey) : breadcrumb.text"></div>
        </div>
        <div class="header__content__heading" *ngIf="(menuOpen$ | async)">
            <div *ngIf="currentUserFullName$ | async as name" class="header__content__heading__text" [innerHtml]="name"></div>
        </div>

        <ntf-notification-header *ngIf="(isAuthenticated$ | async) === true"></ntf-notification-header>

        <div class="header__content__expander" *ngIf="(isAuthenticated$ | async) === true"
             [class.header__content__expander--impersonated]="currentUserIsImpersonated$ | async" (click)="toggleMenu()">
        </div>
        <ui-language *ngIf="(isAuthenticated$ | async) === false" [languages]="uiLanguages$ | async" [currentLanguage]="currentLanguage$ | async"
                     (languageChanged)="languageChanged($event)" [horizontal]="true">
        </ui-language>
    </div>
    <div class="header__mobile-notifications"></div>
    <app-mobile-personal-panel class="header__mobile-personal-panel" *ngIf="menuOpen$ | async" @slideDownTrigger>
    </app-mobile-personal-panel>
    <ha-dimmer class="header__dimmer" *ngIf="menuOpen$ | async" (click)="toggleMenu()">
    </ha-dimmer>
</div>
