import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DynamicEpiPropertyBaseComponent } from "@ha/feature/episerver";
import { BreadcrumbLink, BreadcrumbSelectors } from "@ha/ui/navigation";
import { PwaState } from "@ha/util/pwa";
import { TranslocoService } from "@ngneat/transloco";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { BookmarksActions } from "../../../bookmarks/state/bookmarks.actions";
import { BookmarksState } from "./../../../bookmarks/state/bookmarks.state";
import { KnowledgeBankPage } from "../../models/knowledge-bank-page";

@Component({
    templateUrl: "./knowledge-bank-page.component.html",
    styleUrls: ["knowledge-bank-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankPageComponent implements OnInit, DynamicEpiPropertyBaseComponent {
    public data: KnowledgeBankPage;
    public isBookmarked$: Observable<boolean>;
    public url: string;

    @Select(BreadcrumbSelectors.previousBreadcrumb)
    public parentBreadcrumb$: Observable<BreadcrumbLink>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    public get mailtoUrl(): string {
        const kbTitle = this.translocoService.translate<string>("knowledgeBank.heading");

        return `mailto:?body=${window.location.origin}${window.location.pathname}&subject=${kbTitle}%20%E2%80%93%20${this.data.heading.value}`;
    }

    constructor(private store: Store, private translocoService: TranslocoService) {}

    public ngOnInit(): void {
        this.isBookmarked$ = this.store.select(BookmarksState.isBookmarked(this.data.contentLink.id));
    }

    public toggleBookmark(): void {
        this.store.dispatch(new BookmarksActions.ToggleBookmark(this.data.contentLink));
    }
}
