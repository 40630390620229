import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { XhtmlStringCustomFragment } from "../../models/episerver-base-types.model";

@Component({
    selector: "xhtmlstring",
    templateUrl: "property-xhtml-string.component.html",
    styleUrls: ["property-xhtml-string.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyXhtmlStringComponent {
    constructor(private domSanitizer: DomSanitizer) {}

    @Input() public data: {
        value: string | XhtmlStringCustomFragment[];
        propertyDataType: string;
    };
    @Input() public propertyName: string;

    public get isArray(): boolean {
        return Array.isArray(this.data.value);
    }

    public get dataString(): string {
        return (this.data.value as string) ?? "";
    }

    public get dataFragments(): XhtmlStringCustomFragment[] {
        return (this.data.value as XhtmlStringCustomFragment[]) ?? [];
    }

    public trustHtml(html: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }
}
