import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PhotoRequirement } from "@ha/data/basic";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitPhoto } from "../../../permit-create/models/permit-photo.model";
import { PermitPhotoSelectors } from "../../../permit-create/states/permit-photo-selectors";
import { PermitPhotoActions } from "../../../permit-create/states/permit-photo.actions";
import { CurrentStep } from "../../../permit/models/current-step.model";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";

export interface PhotoModalComponentData {
    photo: PermitPhoto;
    canDelete: boolean;
}

@UntilDestroy()
@Component({
    templateUrl: "./photo-modal.component.html",
    styleUrls: ["./photo-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoModalComponent extends BaseModalComponent implements OnInit {
    @Select(PermitPhotoSelectors.permitPhotoRequirement)
    public photoRequirement$: Observable<PhotoRequirement>;

    @Select(PermitStepSelectors.permitTrulyEnded)
    public permitTrulyEnded$: Observable<boolean>;

    public data: PhotoModalComponentData;
    public deleteMode = false;
    public PhotoRequirement = PhotoRequirement;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit(): void {
        this.store.dispatch(new PermitPhotoActions.GetPermitPhotoRequirement());

        this.actions$.pipe(ofActionSuccessful(PermitPhotoActions.RemovePermitPhoto))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.closeModal();
            });
    }

    public deletePhoto(guid: string): void {
        this.busy = true;
        this.store.dispatch(new PermitPhotoActions.RemovePermitPhoto(guid));
    }
}
