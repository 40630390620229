import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs/internal/Observable";

import { KnowledgeBankNavigation } from "../../models/knowledge-bank-navigation";
import { KnowledgeBankPage } from "../../models/knowledge-bank-page";
import { KnowledgeBankActions } from "../../state/knowledge-bank.actions";
import { KnowledgeBankState } from "../../state/knowledge-bank.state";

@Component({
    selector: "page-navigation",
    templateUrl: "./page-navigation.component.html",
    styleUrls: ["./page-navigation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNavigationComponent implements OnInit {
    @Input()
    public data: KnowledgeBankPage;

    @Select(KnowledgeBankState.navigationLinks)
    public navigationLinks$: Observable<KnowledgeBankNavigation>;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new KnowledgeBankActions.GetNavigationLinks(this.data.contentLink.id as number));
    }
}
