import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Account, AuthActions, AuthSelectors } from "@ha/feature/auth";
import { PwaService, PwaState } from "@ha/util/pwa";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { combineLatest, map, Observable } from "rxjs";

import { CurrentUserActions } from "./core/states/current-user.actions";
import { CurrentUserState } from "./core/states/current-user.state";
import { SettingsStateActions } from "./core/states/settings.actions";
import { HaModalService } from "@ha/ui/common";
import { UserAcceptNewAgreementComponent } from "./user-accept-new-agreement/user-accept-new-agreement.component";
import { UserService } from "./user/services/user.service";
import { UserActivateState } from "./user-activate/states/user-activate.state";
import { UserStateActions } from "./user/state/user.actions";
import { UserState } from "./user/state/user.state";

@UntilDestroy()
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    public screenSize: string;
    public printMode = false;

    @Select(PwaState.isOffline)
    public isOffline$: Observable<boolean>;

    @Select(AuthSelectors.isAuthenticated)
    public isAuthenticated$: Observable<boolean>;

    @Select(CurrentUserState.noUserFound)
    public noUserFound$: Observable<boolean>;

    @Select(AuthSelectors.currentAccount)
    public currentAccount$: Observable<Account>;

    @Select(UserState.agreementLatestPublishedVersionNumber)
    public agreementLatestPublishedVersionNumber$: Observable<number>;

    public showApp$: Observable<boolean>;

    constructor(
        private pwaService: PwaService,
        private activatedRoute: ActivatedRoute,
        private actions$: Actions,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private store: Store,
        private haModalService: HaModalService,
        private userService: UserService,
    ) {

        const isUserInitialized$ = this.actions$.pipe(ofActionSuccessful(CurrentUserActions.Get), map(() => true));
        this.showApp$ = combineLatest([isUserInitialized$, this.isAuthenticated$])
            .pipe(map(([isAppInitialized, isAuthenticated]): boolean => isAppInitialized && isAuthenticated));
    }

    public ngOnInit(): void {
        this.pwaService.init(true);
        this.setScreenSize();
        this.showApproveModalIfNewAgreement();

        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
            if (params.print) {
                this.printMode = true;
            }
        });

        // Transloco sucks workaround
        this.actions$.pipe(ofActionSuccessful(SettingsStateActions.ChangeLanguage), untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    }

    @HostListener("window:resize")
    public onResize(): void {
        this.setScreenSize();
    }

    private setScreenSize() {
        this.screenSize = `${window.innerWidth} ×  ${window.innerHeight}`;
    }

    private showApproveModalIfNewAgreement() {
        let doCheckToShowModal = false;

        combineLatest([this.router.events, this.showApp$])
            .pipe(untilDestroyed(this))
            .subscribe(([event, showApp]) => {
                if (showApp && event instanceof NavigationStart) {
                    doCheckToShowModal = true;
                    this.store.dispatch(new AuthActions.GetCurrentAccount());
                    this.store.dispatch(new UserStateActions.GetAgreementLatestPublishedVersionNumber());
                }
            });

        combineLatest([this.currentAccount$, this.agreementLatestPublishedVersionNumber$])
            .pipe(untilDestroyed(this))
            .subscribe(([currentAccount, agreementLatestPublishedVersionNumber]) => {
                const agreementAcceptedVersionNumber = +currentAccount?.user?.agreementVersionId;
                if (doCheckToShowModal) {
                    const acceptedDuringImpersonation = this.store.selectSnapshot(UserActivateState.newAgreementAcceptedDuringImpersonation);
                    if (agreementLatestPublishedVersionNumber > agreementAcceptedVersionNumber && !acceptedDuringImpersonation) {
                        this.haModalService.open(UserAcceptNewAgreementComponent, false);
                    }
                    doCheckToShowModal = false;
                }
            });

    }
}
