import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { libTranslationProviderFactory } from "@ha/util/common";
import { TranslocoModule } from "@ngneat/transloco";

import { SupportComponent } from "./components/support.component";

export { SupportComponent, SUPPORT_CONFIG } from "./components/support.component";

export const routes: Routes = [{ path: "", component: SupportComponent }];

@NgModule({
    declarations: [SupportComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        TranslocoModule,
    ],
    exports: [SupportComponent],
    providers: [
        libTranslationProviderFactory("support", "feature/support")
    ]
})
export class HaFeatureSupportModule {}
