import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { actionsExecuting } from "@ngxs-labs/actions-executing";
import { ActionType, Store } from "@ngxs/store";

@UntilDestroy()
@Directive({
    selector: "button[loading]",
})
export class ButtonLoadingDirective implements AfterViewInit {
    @Input() public loading: ActionType;

    private loadingClass = "button-loading-directive-loading";

    constructor(private readonly renderer: Renderer2, private readonly element: ElementRef, private store: Store) { }

    public ngAfterViewInit(): void {
        this.store.select(actionsExecuting([this.loading]))
            .pipe(untilDestroyed(this))
            .subscribe((actionsExecuting) => {
                if (actionsExecuting) {
                    this.renderer.addClass(this.element.nativeElement, this.loadingClass);
                } else {
                    this.renderer.removeClass(this.element.nativeElement, this.loadingClass);
                }
            });
    }
}
