export namespace PermitStepActions {
    export class Reset {
        public static readonly type = "[PermitStepActions] Reset";
    }

    export class StartCreate {
        public static readonly type = "[PermitStepActions] StartCreate";
    }

    export class GetCurrentStep {
        public static readonly type = "[PermitStepActions] GetCurrentStep";
        constructor(public permitId: number = undefined) { }
    }

    export class GoForward {
        public static readonly type = "[PermitStepActions] GoForward";
        constructor(public permitId: number = undefined) { }
    }

    export class GoBackwards {
        public static readonly type = "[PermitStepActions] GoBackwards";
        constructor(public permitId: number = undefined) { }
    }

    export class GoToValidity {
        public static readonly type = "[PermitStepActions] GoToValidity";
        constructor(public permitId: number = undefined) { }
    }
}
