import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { SettingsState } from "../../core/states/settings.state";
import { UserAgreement } from "../../user/models/user-agreement.model";
import { UserActivateAgreementCommand } from "../models/user-activate-agreement-command.model";
import { UserActivateCommand } from "../models/user-activate-command.model";
import { UserActivateResult } from "../models/user-activate-result.model";
import { UserValidateCommand } from "../models/user-validate-command.model";
import { UserActivateService } from "../services/user-activate.service";
import { UserActivateActions } from "./user-activate.actions";
import { UserAcceptNewAgreementCommand } from "../models/user-accept-new-agreement-command.model";
import { AuthSelectors } from "@ha/feature/auth";

export interface UserActivateStateModel {
    form: {
        model: UserActivateCommand;
        dirty: boolean;
        status: string;
        errors: unknown;
    };
    validUser: boolean;
    agreement: UserAgreement;
    result: UserActivateResult;
    validIdentityAndToken: boolean;
    queryParameters: { userId: string; token: string; };
    newAgreementAcceptedDuringImpersonation: boolean,
}

const defaults: UserActivateStateModel = {
    form: {
        model: undefined,
        dirty: false,
        status: "",
        errors: {},
    },
    validUser: false,
    agreement: undefined,
    result: undefined,
    validIdentityAndToken: undefined,
    queryParameters: undefined,
    newAgreementAcceptedDuringImpersonation: undefined,
};

@Injectable()
@State<UserActivateStateModel>({
    name: "userActivate",
    defaults: defaults,
})
export class UserActivateState implements NgxsOnInit {
    @Selector()
    public static validationResult(state: UserActivateStateModel) {
        return state.result?.validationResult;
    }

    @Selector()
    public static invalidRequest(state: UserActivateStateModel) {
        return state.result && !state.result.success && state.result.validationResult == null;
    }

    @Selector()
    public static hasQueryParameters(state: UserActivateStateModel): boolean {
        return !!state.queryParameters?.userId && !!state.queryParameters?.token;
    }

    @Selector()
    public static activationAgreement(state: UserActivateStateModel) {
        return state.agreement;
    }

    @Selector()
    public static validUser(state: UserActivateStateModel) {
        return state.validUser;
    }

    @Selector()
    public static validIdentityAndToken(state: UserActivateStateModel) {
        return state.validIdentityAndToken;
    }

    @Selector()
    public static user(state: UserActivateStateModel) {
        return state.queryParameters?.userId || state.form.model.identity;
    }

    @Selector()
    public static newAgreementAcceptedDuringImpersonation(state: UserActivateStateModel) {
        return state.newAgreementAcceptedDuringImpersonation;
    }

    constructor(
        private userActivateService: UserActivateService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
    ) {}

    public ngxsOnInit(ctx: StateContext<UserActivateStateModel>) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const id = this.route.snapshot.queryParamMap.get("id");
                const t = this.route.snapshot.queryParamMap.get("t");

                ctx.patchState({
                    queryParameters: {
                        userId: id,
                        token: t,
                    },
                });
            }
        });
    }

    @Action(UserActivateActions.AcceptAgreement)
    public AcceptAgreement(ctx: StateContext<UserActivateStateModel>) {
        this.SetAcceptAgreement(ctx, true);
    }

    @Action(UserActivateActions.RejectAgreement)
    public RejectAgreement(ctx: StateContext<UserActivateStateModel>) {
        this.SetAcceptAgreement(ctx, false);
    }

    @Action(UserActivateActions.ValidateUser)
    public validateUser(ctx: StateContext<UserActivateStateModel>) {
        const state = ctx.getState();
        const formModel = ctx.getState().form.model;
        const data: UserValidateCommand = {
            identity: formModel?.identity ?? "",
            userId: state.queryParameters?.userId,
            token: state.queryParameters?.token ?? formModel.token,
        };

        return this.userActivateService.validateUser(data).pipe(tap((result) => {
            ctx.patchState({
                result: result,
                validUser: result.success,
            });
        }));
    }

    @Action(UserActivateActions.GetActivationAgreement)
    public getAgreement(ctx: StateContext<UserActivateStateModel>) {
        const state = ctx.getState();
        const formModel = ctx.getState().form.model;

        const data: UserActivateAgreementCommand = {
            identity: formModel?.identity ?? "",
            userId: state.queryParameters?.userId,
            language: this.store.selectSnapshot(SettingsState.currentLanguage),
        };

        return this.userActivateService.getHotworkerAgreement(data).pipe(tap((result) => {
            ctx.patchState({
                agreement: result,
            });
        }));
    }

    @Action(UserActivateActions.Submit)
    public submit(ctx: StateContext<UserActivateStateModel>) {
        const state = ctx.getState();
        const formModel = state.form.model;

        const data: UserActivateCommand = {
            identity: formModel.identity ?? "",
            token: state.queryParameters?.token ?? formModel.token,
            userId: state.queryParameters?.userId,
            newPassword: formModel.newPassword,
            newPasswordConfirm: formModel.newPasswordConfirm,
            agreementAccepted: formModel.agreementAccepted,
            agreementVersion: state.agreement.pageVersion,
        };

        return this.userActivateService.activate(data).pipe(tap((result) => {
            if (result.success) {
                ctx.setState(defaults);
                location.href = result.postActivateSigninRedirectUri;
            } else {
                ctx.patchState({
                    result: result,
                });
            }
        }));
    }

    @Action(UserActivateActions.ValidateIdentityAndToken)
    public validateIdentityAndToken(ctx: StateContext<UserActivateStateModel>): Observable<boolean> {
        const queryParameters = ctx.getState().queryParameters;
        return this.userActivateService.validateIdentityAndToken(queryParameters.userId, queryParameters.token).pipe(tap((result) => {
            ctx.patchState({ validIdentityAndToken: result });
        }));
    }

    private SetAcceptAgreement(ctx: StateContext<UserActivateStateModel>, accepted: boolean) {
        ctx.setState(patch({
            form: patch({
                model: patch({
                    agreementAccepted: accepted,
                }),
            }),
        }));
    }

    @Action(UserActivateActions.AcceptNewAgreement)
    public acceptNewAgreement(ctx: StateContext<UserActivateStateModel>): Observable<boolean> {
        const state = ctx.getState();

        const data: UserAcceptNewAgreementCommand = {
            userId: state.queryParameters?.userId,
            agreementVersion: state.agreement.pageVersion,
        };

        const currentAccount = this.store.selectSnapshot(AuthSelectors.currentAccount);
        if (currentAccount.isImpersonated) {
            ctx.patchState({ newAgreementAcceptedDuringImpersonation: true });
            return of(false);
        }

        return this.userActivateService.acceptNewAgreement(data);
    }
}
