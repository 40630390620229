import { Injectable } from "@angular/core";
import { BaseResult } from "@ha/data/basic";
import { Action, Actions, createSelector, ofActionSuccessful, Selector, State, StateContext, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { skip, tap } from "rxjs/operators";

import { CurrentUserState } from "../../core/states/current-user.state";
import { InitSelector } from "../../core/states/init.selector";
import { SettingsStateActions } from "../../core/states/settings.actions";
import { Bookmark } from "../models/bookmark";
import { BookmarksService } from "./../services/bookmarks.service";
import { BookmarksActions } from "./bookmarks.actions";

export interface BookmarksStateModel {
    bookmarksExpanded: boolean;
    bookmarks: Bookmark[];
}

@State<BookmarksStateModel>({
    name: "bookmarks",
    defaults: {
        bookmarksExpanded: false,
        bookmarks: [],
    },
})
@Injectable()
export class BookmarksState {
    constructor(private bookmarksService: BookmarksService, private actions$: Actions, private store: Store) {}

    @Selector()
    public static bookmarksExpanded(state: BookmarksStateModel): boolean {
        return state.bookmarksExpanded;
    }

    @Selector()
    public static bookmarks(state: BookmarksStateModel): Bookmark[] {
        return state.bookmarks;
    }

    @Selector()
    public static bookmarksCount(state: BookmarksStateModel): number {
        return state.bookmarks.length;
    }

    public static isBookmarked(contentId: number): (bookmarks: Bookmark[]) => boolean {
        return createSelector([BookmarksState.bookmarks], (bookmarks: Bookmark[]) => {
            return bookmarks.findIndex((_) => _.contentId === contentId) !== -1;
        });
    }

    public ngxsOnInit(ctx: StateContext<BookmarksStateModel>): void {
        this.actions$.pipe(ofActionSuccessful(SettingsStateActions.ChangeLanguage), skip(1)).subscribe(() => {
            ctx.dispatch(new BookmarksActions.GetBookmarks());
        });
    }

    @Action(BookmarksActions.GetBookmarks)
    public getBookmarks(ctx: StateContext<BookmarksStateModel>): Observable<Bookmark[]> {
        if (this.store.selectSnapshot(CurrentUserState.isExternal) || !this.store.selectSnapshot(InitSelector.isAppInitialized)) {
            return;
        }

        return this.bookmarksService.getBookmarks().pipe(tap((bookmarks) => {
            ctx.patchState({
                bookmarks: bookmarks,
            });
        }));
    }

    @Action(BookmarksActions.ToggleBookmark)
    public toggleBookmark(ctx: StateContext<BookmarksStateModel>, { contentLink }: BookmarksActions.ToggleBookmark): Observable<BaseResult> {
        return this.bookmarksService.toggleBookmark(contentLink).pipe(tap((result) => {
            if (result.success) {
                ctx.dispatch(new BookmarksActions.GetBookmarks());
            }
        }));
    }

    @Action(BookmarksActions.ToggleBookmarksPanelExpanded)
    public toggleBookmarksPanelExpanded(ctx: StateContext<BookmarksStateModel>): void {
        ctx.patchState({ bookmarksExpanded: !ctx.getState().bookmarksExpanded });
    }
}
