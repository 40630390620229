import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { AutocompleteListItem, EpiserverFindState, EpiserverStateActions } from "@ha/feature/episerver";
import { SearchBoxComponent } from "@ha/ui/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionDispatched, Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { KnowledgeBankSearchState } from "../../state/knowledge-bank-search.state";
import { KnowledgeBankActions } from "../../state/knowledge-bank.actions";

@UntilDestroy()
@Component({
    selector: "knowledge-bank-search-box",
    templateUrl: "./knowledge-bank-search-box.component.html",
    styleUrls: ["./knowledge-bank-search-box.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankSearchBoxComponent implements OnInit {
    /**
     * If true it will try to focus on search and page load.
     *
     * If false it will not ever try to focus and will clear on search
     *
     * @type {boolean}
     * @memberof KnowledgeBankSearchBoxComponent
     */
    @Input()
    public isMain: boolean;

    @ViewChild(SearchBoxComponent)
    private searchBoxComponent: SearchBoxComponent;

    public query$: Observable<string>;

    @Select(EpiserverFindState.autocompleteList)
    public autocompleteList$: Observable<AutocompleteListItem[]>;

    constructor(private store: Store, private actions$: Actions) {}

    public ngOnInit(): void {
        // we only want to update query in the main component
        if (this.isMain) {
            this.query$ = this.store.select(KnowledgeBankSearchState.query);
        }

        this.actions$.pipe(ofActionDispatched(KnowledgeBankActions.Search)).pipe(untilDestroyed(this)).subscribe(() => {
            if (!this.isMain) {
                // not focus components should reset on search
                this.searchBoxComponent.reset();
            } else {
                // main search should focus on search
                this.searchBoxComponent.setFocus();
            }
        });
    }

    public search(query: string): void {
        this.store.dispatch(new KnowledgeBankActions.Search(query));
    }

    public queryChange(query: string): void {
        this.store.dispatch(new EpiserverStateActions.SearchAutocomplete(query));
    }
}
