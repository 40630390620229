<div *transloco="let t; read:'permit'" class="root">
    <div class="root__question-text" [innerHTML]="t('isFlammableHotWork')"></div>

    <div class="root__choices">
        <div class="root__choices__item" [class.root__choices__item--active]="isFlammableHotWork === true" (click)="select(true)">
            {{ t("yes") }}
        </div>
        <div class="root__choices__item" [class.root__choices__item--active]="isFlammableHotWork === false" (click)="select(false)">
            {{ t("no") }}
        </div>
    </div>

    <div class="root__actions">
        <button class="root__actions__back" type="button" (click)="goBack()">{{ t("back") }}</button>
        <button class="root__actions__continue" type="button" (click)="continue()" [class.root__actions__continue--disabled]="!canProceed">
            {{ t("next") }}
        </button>
    </div>
</div>
