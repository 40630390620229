<ng-container *transloco="let t; read:'permit'">
    <div class="root">
        <div class="root__dialog" *ngIf="alert">
            <div [innerHTML]="alert.translations | haTranslate"></div>
            <div *ngIf="alert.placeholder" class="root__dialog__text" [innerHTML]="alert.placeholder.translations | haTranslate"></div>
            <pmt-safety-rule *ngIf="safetyRules" [safetyRules]="safetyRules"></pmt-safety-rule>

            <div class="root__confirm" *ngIf="alert.confirmToProceed">
                <div class="root__confirm__button" [class.root__confirm__button--active]="confirmed" (click)="toggleConfirm()">{{ t('confirm') }}</div>
            </div>
        </div>
    </div>
</ng-container>
