<ng-container *transloco="let t; read:'permit.details.export'">
    <div class="modal">
        <form [formGroup]="form" (ngSubmit)="sendEmail(this.form.get('email').value)">
            <div class="modal__content">
                <div [innerHTML]="t('mailIntro')"></div>
                <ha-input class="modal__content__input" formControlName="email" [label]="t('email')"></ha-input>
                <button class="modal__actions__close" style="padding: 0; margin: 0 0.5rem;" type="button" (click)="clearInput()">{{ t("clear") }}</button>
            </div>

            <div class="modal__actions">
                <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>
                <button class="modal__actions__send" type="submit" [loading]="permitDetailsActionsSendPdfMail">{{ t("send") }}</button>
            </div>
        </form>
    </div>
</ng-container>
