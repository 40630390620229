import { Component } from "@angular/core";

import { DimmerComponentAnimations } from "./dimmer.component.animation";

@Component({
    selector: "ha-dimmer",
    templateUrl: "./dimmer.component.html",
    styleUrls: ["./dimmer.component.scss"],
    animations: [DimmerComponentAnimations.fadeInOutTrigger],
})
export class DimmerComponent {}
