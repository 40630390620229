import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitPerson } from "../models/permit-person.model";
import { PermitToSign } from "../models/permit-to-sign.model";

interface CurrentUserStateModel {
    currentUser: {
        currentUser: CurrentUser;
    };
}

interface CurrentUser {
    isHotworker: boolean;
    canCreateDemoPermits: boolean;
    isExternal: boolean;
    isAdministrator: boolean;
}

@Injectable({ providedIn: "root" })
export class PermitUserService {
    constructor(private httpClient: HttpClient, private store: Store) {}

    public get currentUser(): CurrentUser {
        return this.store.selectSnapshot<CurrentUser>((_: CurrentUserStateModel) => _.currentUser.currentUser);
    }

    public getCurrentPerson(): Observable<PermitPerson> {
        return this.httpClient.get<PermitPerson>("/api/PermitPerson/GetCurrentPerson");
    }

    public getPermitsToSign(): Observable<PermitToSign[]> {
        return this.httpClient.get<PermitToSign[]>("/api/PermitPerson/GetPermitsToSign");
    }
}
