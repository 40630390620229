import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { BaseModalComponent, ModalComponent } from "@ha/ui/common";
import { PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";

import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitTeam } from "../../models/permit-team.model";
import { PermitTeamActions } from "../../states/permit-team.action";
import { PermitTeamSelectors } from "../../states/permit-team.selectors";


export interface AddPersonModalComponentData {
    allowExternalCertificates: boolean;
    permitId: number;
    role: PersonRole;
    detailsMode: boolean;
}

export enum AddPersonView {
    LastUsed = 1,
    Search = 2,
    Other = 3,
    NoFirewatcher = 4
}

@Component({
    templateUrl: "add-person-modal.component.html",
    styleUrls: ["add-person-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddPersonModalComponent extends BaseModalComponent {
    @ViewChild("alertModal") private alertModal: ModalComponent;

    public get permitId() {
        return this.data.permitId;
    }

    public get role() {
        return this.data.role;
    }

    public get showFireWatcherNotNeeded() {
        if (this.store.selectSnapshot(PermitCreateSelectors.fireWatcherRequired)) {
            return false;
        } else if (this.data.detailsMode) {
            return false;
        }

        return true;
    }

    public data: AddPersonModalComponentData;
    public PersonRole = PersonRole;
    public noScroll = false;

    @Select(PermitTeamSelectors.team)
    public permitTeam$: Observable<PermitTeam>;

    @Select(PermitTeamSelectors.stagedPersons)
    public stagedPersons$: Observable<PermitPerson[]>;

    public currentView: AddPersonView = AddPersonView.LastUsed;
    public View = AddPersonView;

    public get title(): string {
        if (this.role == PersonRole.FireWatcher) {
            return "titles.firewatcher";
        } else if (this.role == PersonRole.HotWorker) {
            return "titles.hotworker";
        } else if (this.role == PersonRole.PermitIssuer) {
            return "titles.permitissuer";
        } else if (this.role == PersonRole.PostFireWatcher) {
            return "titles.postfirewatcher";
        } else {
            return "error";
        }
    }

    constructor(private store: Store) {
        super();
    }

    public addStagedPersons(): void {
        this.store.dispatch(new PermitTeamActions.AddStagedPersonsToPermit(this.permitId, this.role));
        this.close();
    }

    public abort(): void {
        const stagedPersons = this.store.selectSnapshot(PermitTeamSelectors.stagedPersons);
        if (stagedPersons && stagedPersons.length > 0) {
            if (this.store.selectSnapshot(PwaState.isMobile)) {
                this.noScroll = true;
                this.alertModal.openModal();
            } else {
                this.abortClear();
            }
        } else {
            this.close();
        }
    }

    public continue(): void {
        this.noScroll = false;
    }

    public abortClear(): void {
        this.store.dispatch(new PermitTeamActions.UnstageAll());
        this.close();
    }

    public fireWatcherNotNeededChange(notNeeded: boolean) {
        if (notNeeded) {
            this.abort();
        } else {
            this.currentView = AddPersonView.LastUsed;
        }
    }

    public unstagePerson(certificateId: number) {
        this.store.dispatch(new PermitTeamActions.UnstagePerson(certificateId));
    }

    private close() {
        this.store.dispatch(new PermitTeamActions.SetAddPersonView(undefined));
        this.closeModal();
    }
}
