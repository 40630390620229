import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MenuItem } from "@ha/feature/episerver";
import { PwaState } from "@ha/util/pwa";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";

import { KnowledgeBankState } from "../../state/knowledge-bank.state";

@Component({
    selector: "knowledge-bank-menu",
    templateUrl: "./knowledge-bank-menu.component.html",
    styleUrls: ["./knowledge-bank-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBankMenuComponent {
    @Select(KnowledgeBankState.menu)
    public menu$: Observable<MenuItem>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;
}
