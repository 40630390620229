import { Injectable } from "@angular/core";
import { LanguageStateActions } from "@ha/ui/common";
import { SelectionListItem } from "@ha/ui/forms";
import { Application } from "@ha/ui/navigation";
import { setDateFnsLanguage } from "@ha/util/common";
import { TranslocoService } from "@ngneat/transloco";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { EducationActions } from "../../education/states/education.actions";
import { Settings } from "../models/settings.model";
import { SettingsService } from "../services/settings.service";
import { SettingsStateActions } from "./settings.actions";

export interface SettingsStateModel {
    settings: Settings;
    currentLanguage: string;
}

@Injectable()
@State<SettingsStateModel>({
    name: "settings",
})
export class SettingsState {
    constructor(private settingsService: SettingsService, private translocoService: TranslocoService) {}

    @Selector()
    public static currentLanguage(state: SettingsStateModel): string {
        return state?.currentLanguage;
    }

    @Selector()
    public static uiLanguages(state: SettingsStateModel): string[] {
        return state?.settings?.uiLanguages;
    }

    @Selector()
    public static settings(state: SettingsStateModel): Settings {
        return state?.settings;
    }

    @Selector()
    public static uiLanguagesSelectOptions(state: SettingsStateModel): SelectionListItem[] {
        return state.settings?.uiLanguages?.map((lang) => {
            return <SelectionListItem> { value: lang, langKey: `languagesInNative.${lang}` };
        });
    }

    @Selector()
    public static certificationLanguagesSelectOptions(state: SettingsStateModel): SelectionListItem[] {
        return state.settings?.certificationLanguages?.map((lang) => {
            return <SelectionListItem> { value: lang, langKey: `languagesInNative.${lang}` };
        });
    }

    @Selector()
    public static applicationList(state: SettingsStateModel): Application[] {
        return state.settings?.applications.filter((_) =>
            _.internalIdentifier !== "www" && _.internalIdentifier !== "app"
        );
    }

    public ngxsOnInit(ctx: StateContext<SettingsStateModel>): void {
        const lang = ctx.getState().currentLanguage ?? (navigator.language.startsWith("sv") ? "sv" : "en");
        this.setLanguage(ctx, lang);
    }

    @Action(SettingsStateActions.GetSettings)
    public getSettings(ctx: StateContext<SettingsStateModel>): Observable<Settings> {
        return this.settingsService.getSettings().pipe(tap((settings) => {
            ctx.patchState({
                settings: settings,
            });
        }));
    }

    @Action(SettingsStateActions.ChangeLanguage)
    public changeLanguage(ctx: StateContext<SettingsStateModel>, action: SettingsStateActions.ChangeLanguage): void {
        this.setLanguage(ctx, action.language);
        ctx.dispatch(new EducationActions.ChangeLanguage(action.language, null));
        ctx.dispatch(new LanguageStateActions.SetLanguage(action.language));
    }

    private setLanguage(ctx: StateContext<SettingsStateModel>, language: string) {
        if (!language) {
            return;
        }

        this.translocoService.setActiveLang(language);

        setDateFnsLanguage(language);

        ctx.patchState({
            currentLanguage: language,
        });
    }
}
