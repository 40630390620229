import { Selector } from "@ngxs/store";

import { BreadcrumbLink } from "../models/breadcrumb-link.model";
import { BreadcrumbState, BreadcrumbStateModel } from "./breadcrumb.state";

export class BreadcrumbSelectors {
    @Selector([BreadcrumbState])
    public static breadcrumbs(state: BreadcrumbStateModel): BreadcrumbLink[] {
        return state.breadcrumbs?.map(_ => <BreadcrumbLink>{ text: _.text, url: _.url, highlighted: _.highlighted, hasDetailsLink: _.hasDetailsLink });
    }

    @Selector([BreadcrumbSelectors.breadcrumbs])
    public static previousBreadcrumb(breadcrumbs: BreadcrumbLink[]): BreadcrumbLink | undefined {
        if (breadcrumbs?.length > 0) {
            return breadcrumbs[breadcrumbs.length - 2];
        }

        return undefined;
    }

    @Selector([BreadcrumbSelectors.breadcrumbs])
    public static mobileBreadcrumb(breadcrumbs: BreadcrumbLink[]): BreadcrumbLink | undefined {
        if (breadcrumbs) {
            return breadcrumbs[0];
        }

        return undefined;
    }
}
