export const ApplicationPaths = {
    ApiAuthorizationClientConfigurationUrl: "/_configuration/App",
    DefaultLoginRedirectPath: "/",
    Certificate: {
        Root: "certificate",
        Id06Consent: "id06consent",
        SsgConsent: "ssgconsent",
        ReplacementCardRequest: "replacementcard",
        CardOrder: "cardorder",
    },
    Education: {
        Root: "education",
        CardOrder: "cardorder",
        CheckDetails: "checkdetails",
        Id06Consent: "id06consent",
        SsgConsent: "ssgconsent",
        InstructorAgreement: "instructoragreement",
        Prestudy: "prestudy",
        PrestudyResult: "prestudyresult",
        PrestudyResultParam: "prestudyresult/:responseId",
        Review: "review",
        Test: "test",
        TestResult: "testresult",
        TestResultParam: "testresult/:responseId",
        FirefightingExercise: "firefightingexercise",
        FirefightingExerciseParam: "firefightingexercise/:firefightingExerciseId",
    },
    CardOrder: "cardorder",
    Install: "install",
    IssuerActivate: "issuer-activate",
    Id06Consent: "id06consent",
    SsgConsent: "ssgconsent",
    KnowledgeBank: {
        Root: "knowledgebank",
        Search: "search",
    },
    PasswordForgot: "forgot",
    PasswordResetLink: "reset-link",
    PasswordResetSupport: "reset-support",
    Permit: {
        Root: "permit",
        Create: "create",
    },
    SignOut: "logout",
    Support: "support",
    Unauthorized: `authentication/unauthorized`,
    User: {
        Root: "user",
        ChangePassword: "changepassword",
        Edit: "edit",
    },
    UserActivate: "activate",
    PublicEducationEvents: "publiceducationevents",
};
