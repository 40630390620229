import { NgSignaturePadOptions, SignaturePadComponent } from "@almothafar/angular-signature-pad";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    ViewChild,
} from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Actions, Store } from "@ngxs/store";

import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitPerson } from "../../../shared/models/permit-person.model";

export interface SignPersonModalComponentData {
    permitId: number;
    role: PersonRole;
    person: PermitPerson;
    name: string;
}

@UntilDestroy()
@Component({
    templateUrl: "sign-person-modal.component.html",
    styleUrls: ["sign-person-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignPersonModalComponent extends BaseModalComponent implements AfterViewInit {
    @ViewChild("signature")
    public signaturePad: SignaturePadComponent;

    public signaturePadOptions: NgSignaturePadOptions = undefined;

    public data: SignPersonModalComponentData;

    public hasSignature = false;

    public agreementAccepted = false;

    public PersonRole = PersonRole;

    public get agrementAcceptedBefore() {
        return this.data.person.hasAcceptedUserAgreement;
    }

    public get isValid() {
        return this.hasSignature && (this.agrementAcceptedBefore || this.agreementAccepted);
    }

    constructor(private store: Store, private actions$: Actions, private cdr: ChangeDetectorRef) {
        super();
    }

    public ngAfterViewInit(): void {
        this.getScreenSize();
        this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }

    @HostListener("window:resize", ["$event"])
    public getScreenSize(): void {
        let width = 400;
        if (window.innerWidth < width) {
            width = window.innerWidth;
        }

        this.signaturePadOptions = {
            minWidth: 1,
            maxWidth: 4,
            backgroundColor: "#ffffff",
            canvasWidth: width,
            canvasHeight: width / 2,
        };

        this.cdr.detectChanges();
    }

    public clear() {
        this.signaturePad.clear();
        this.hasSignature = !this.signaturePad.isEmpty();
    }

    public save() {
        if (this.isValid) {
            this.store.dispatch(
                new PermitTeamActions.SignPermitWithSignature({
                    permitId: this.data.permitId,
                    personId: this.data.person.personId,
                    role: this.data.role,
                    signatureData: this.signaturePad.toDataURL(),
                    endPostWatchSignature: false
                }),
            );
            this.closeModal();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public drawComplete(event: MouseEvent | Touch) {
        this.hasSignature = !this.signaturePad.isEmpty();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public drawStart(event: MouseEvent | Touch) {
    }
}
