import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";

import { HamburgerMenuStateActions } from "./hamburger-menu.actions";
import { ProfileStateActions } from "./profile.actions";

export interface ProfileStateModel {
    menuOpen: boolean;
}

@Injectable()
@State<ProfileStateModel>({
    name: "profile",
    defaults: {
        menuOpen: false,
    },
})
export class ProfileState {
    @Selector()
    public static menuOpen(state: ProfileStateModel): boolean {
        return state.menuOpen;
    }

    @Action(ProfileStateActions.HideMenu)
    public hideMenu(ctx: StateContext<ProfileStateModel>): void {
        ctx.patchState({
            menuOpen: false,
        });
    }

    @Action(ProfileStateActions.ToggleMenu)
    public toggleMenu(ctx: StateContext<ProfileStateModel>): void {
        const currentState = ctx.getState();

        if (!currentState.menuOpen) {
            ctx.dispatch(new HamburgerMenuStateActions.HideAll());
        }

        ctx.patchState({
            menuOpen: !currentState.menuOpen,
        });
    }
}
