export function toRouteComponents(s: string): string[] {
    return s.length > 1 ? s.split("/") : [s];
}

declare global {
    interface String {
        toRouteComponents(): string[];
    }
}

String.prototype.toRouteComponents = function () {
    return toRouteComponents(this as string);
};
