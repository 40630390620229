import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HaFeatureAuthModule } from "@ha/feature/auth";
import { AuthModule } from "angular-auth-oidc-client";

import { ApplicationPaths } from "../constants/application-paths";
import { moduleTranslationProviderFactory } from "../translation/scope-loader";
import { SharedModule } from "./../shared/shared.module";
import { SignOutComponent } from "./components/sign-out.component";
import { openIdConfiguration } from "./functions/openIdConfiguration";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        AuthModule.forRoot({
            config: openIdConfiguration,
        }),
        RouterModule.forChild([
            { path: ApplicationPaths.SignOut, component: SignOutComponent },
        ]),
        HaFeatureAuthModule,
        FormsModule,
        SharedModule,
    ],
    declarations: [SignOutComponent],
    exports: [SignOutComponent],
    providers: [
        moduleTranslationProviderFactory("auth")
    ],
})
export class AppAuthModule {}
