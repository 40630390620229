export namespace EducationActions {
    export class ToggleDetailsOpen {
        public static readonly type = "[Education] ToggleDetailsOpen";
    }

    export class GetStatus {
        public static readonly type = "[Education] GetStatus";
        constructor(public educationEventId: number) {}
    }

    export class GetDetails {
        public static readonly type = "[Education] GetDetails";
        constructor(public educationEventId: number) {}
    }

    export class GetInstructorAgreement {
        public static readonly type = "[Education] GetInstructorAgreement";
    }

    export class AcceptInstructorAgreement {
        public static readonly type = "[Education] AcceptInstructorAgreement";
    }

    export class GetReview {
        public static readonly type = "[Education] GetReview";
    }

    export class SubmitReview {
        public static readonly type = "[Education] SubmitReview";
    }

    export class GetTest {
        public static readonly type = "[Education] GetTest";
    }

    export class GetTestResult {
        public static readonly type = "[Education] GetTestResult";
        constructor(public responseId: number) {}
    }

    export class SubmitTest {
        public static readonly type = "[Education] SubmitTest";
    }

    export class GetPrestudy {
        public static readonly type = "[Education] GetPrestudy";
    }

    export class SubmitPrestudy {
        public static readonly type = "[Education] SubmitPrestudy";
    }

    export class GetPrestudyResult {
        public static readonly type = "[Education] GetPrestudyResult";
        constructor(public responseId: number) {}
    }

    export class AnswerQuestion {
        public static readonly type = "[Education] AnswerQuestion";
        constructor(public responseAnswerId: number, public choiceId?: number, public text?: string) {}
    }

    export class ChangeQuestion {
        public static readonly type = "[Education] ChangeQuestion";
        constructor(public questionId: number) {}
    }

    export class GoToNextQuestion {
        public static readonly type = "[Education] GoToNextQuestion";
    }

    export class ChangeLanguage {
        public static readonly type = "[Education] ChangeLanguage";
        constructor(public language: string, public getQuestionnaireCallback: () => void) {}
    }
}
