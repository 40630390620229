import { HaError } from "../models/error.model";

export namespace ErrorsStateActions {
    export class New {
        public static readonly type = "[Errors] New";
        constructor(
            public correlationId: string,
            public httpStatus: number,
            public message: string,
            public error: string,
        ) {}
    }

    export class Dismiss {
        public static readonly type = "[Errors] Dismiss";
        constructor(public error: HaError) {}
    }
}
