<ng-container *transloco="let t; read:'permit.changeIssuer'">
    <div *ngIf="permitTeam$ | async as team">
        <div class="issuer" [class.issuer--disabled]="isHotworker(issuer, team)" *ngFor="let issuer of availableIssuers$ | async" (click)="!isHotworker(issuer, team) && toggleIssuer(issuer)">
            <div class="issuer__details">
                <div class="issuer__details__name">{{issuer.fullName}}</div>
                <div *ngIf="!isHotworker(issuer, team)" class="issuer__details__date" [class.issuer__details__date--expiring]="issuer.expiringSoon">
                    {{t('certifiedTo', { date:issuer.expirationDate | haDate:'date' })}}
                </div>
                <div *ngIf="isHotworker(issuer, team)" class="issuer__details__date">
                    {{t('notSelectable')}}
                </div>
            </div>
            <div class="issuer__checkmark"
                [class.issuer__checkmark--disabled]="isHotworker(issuer, team)"
                [class.issuer__checkmark--selected]="(selectedNewIssuer$ | async) && (selectedNewIssuer$ | async).certificateId === issuer.certificateId">
            </div>
        </div>
    </div>
</ng-container>
