import { Selector } from "@ngxs/store";

import { NotificationPage } from "../models/notification-page";
import { Notification } from "../models/notification.model";
import { NotificationState, NotificationStateModel } from "./notification.state";

export class NotificationSelectors {
    @Selector([NotificationState])
    public static notifications(state: NotificationStateModel): Notification[] | undefined {
        return state?.notifications;
    }

    @Selector([NotificationState])
    public static unreadNotifications(state: NotificationStateModel): Notification[] {
        return state?.unreadNotifications;
    }

    @Selector([NotificationState])
    public static unseenNotificationsCount(state: NotificationStateModel): number {
        return state?.unseenNotificationsCount || 0;
    }

    @Selector([NotificationState])
    public static hasUnseenNotifications(state: NotificationStateModel): boolean {
        return state && state.unseenNotificationsCount > 0;
    }

    @Selector([NotificationState])
    public static currentNotification(state: NotificationStateModel): NotificationPage | undefined {
        return state?.currentNotification;
    }

    @Selector([NotificationState])
    public static loading(state: NotificationStateModel): boolean | undefined {
        return state?.loading;
    }
}
