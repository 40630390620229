<div class="root" *transloco="let t; read:'permit.prepare'">
    <h2 class="root__question-text" [innerHTML]="t('selectRole.heading')"></h2>

    <div class="root__button-group">
        <!-- Hetarbetare -->
        <div class="root__button-group__confirm">
            <div class="root__button-group__confirm__button" (click)="toggleRole(PersonRole.HotWorker)"
                 [class.root__button-group__confirm__button--active]="roleSelected(PersonRole.HotWorker)"
                 [class.root__button-group__confirm__button--disabled]="lockedByAnotherRole(PersonRole.HotWorker)">
                {{ t(translateRoleEnum(PersonRole.HotWorker))}}</div>
        </div>

        <!-- Brandvakt -->
        <div class="root__button-group__confirm">
            <div class="root__button-group__confirm__button" (click)="toggleRole(PersonRole.FireWatcher)"
                 [class.root__button-group__confirm__button--active]="roleSelected(PersonRole.FireWatcher)"
                 [class.root__button-group__confirm__button--disabled]="lockedByAnotherRole(PersonRole.FireWatcher)">{{
                t(translateRoleEnum(PersonRole.FireWatcher)) }}</div>
        </div>

        <!-- Brandvakt efterbevakning -->
        <div class="root__button-group__confirm">
            <div class="root__button-group__confirm__button" (click)="toggleRole(PersonRole.PostFireWatcher)"
                 [class.root__button-group__confirm__button--active]="roleSelected(PersonRole.PostFireWatcher)">{{
                t(translateRoleEnum(PersonRole.PostFireWatcher)) }}</div>
        </div>
    </div>

    <div class="root__actions">
        <button class="root__actions__back" type="button" (click)="goBack()">{{ t("cancel") }}</button>
        <button class="root__actions__continue" type="button" (click)="continue()" [class.root__actions__continue--disabled]="!canContinue">
            {{ t("continue") }}
        </button>
    </div>
</div>
