import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Workplace } from "@ha/data/basic";
import { Navigate } from "@ngxs/router-plugin";
import { Action, State, StateContext, Store } from "@ngxs/store";
import produce from "immer";
import { Observable, tap } from "rxjs";

import { PermitPaths } from "../../permit-paths";
import { IssuerStructureNode } from "../models/issuer-structure-node.model";
import { PermitWorkPlaceViewEnum } from "../models/permit-work-place-view.enum";
import { RecentPermitWorkplace } from "../models/recent-workplaces.model";
import { StructureNodeBreadcrumb } from "../models/structure-node-breadcrumb.model";
import { PermitCreateService } from "../services/permit-create.service";
import { PermitCreateActions } from "./permit-create.actions";
import { PermitCreateSelectors } from "./permit-create.selectors";
import { PermitWorkplaceActions } from "./permit-workplace.actions";
import { CreatePermitResult } from "../models/create-permit-result.model";

export interface PermitWorkplaceStateModel {
    structure: IssuerStructureNode[];
    flatStructure: IssuerStructureNode[];
    recentWorkplaces: RecentPermitWorkplace[];
    recentWorkplacesToShow: RecentPermitWorkplace[];
    view: PermitWorkPlaceViewEnum;
    loading: boolean;
    selectedNode: IssuerStructureNode | undefined;
    selectedNodeBreadcrumbs: StructureNodeBreadcrumb[];
    isDemo: boolean;
    selectedWorkplace: Workplace | undefined;
    selectedSubscriptionId: number | undefined;
    freeTextForm: {
        model: { additionalInfo: string },
        dirty: boolean,
        status: string,
        errors: unknown,
    },
    searchResult: (IssuerStructureNode | Workplace)[];
}

const defaults: PermitWorkplaceStateModel = {
    structure: undefined,
    flatStructure: undefined,
    recentWorkplaces: [],
    recentWorkplacesToShow: [],
    view: PermitWorkPlaceViewEnum.Start,
    loading: undefined,
    selectedNode: undefined,
    selectedNodeBreadcrumbs: undefined,
    isDemo: false,
    selectedWorkplace: undefined,
    selectedSubscriptionId: undefined,
    freeTextForm: {
        model: undefined,
        dirty: false,
        status: "",
        errors: {},
    },
    searchResult: undefined,
};

@Injectable()
@State<PermitWorkplaceStateModel>({
    name: "permitWorkplace",
    defaults: defaults
})
export class PermitWorkplaceState {
    constructor(private permitCreateService: PermitCreateService, private store: Store, private router: Router) {}

    @Action(PermitWorkplaceActions.GetCurrentIssuerCompanyStructure)
    public getCurrentIssuerCompanyStructure(
        ctx: StateContext<PermitWorkplaceStateModel>,
        action: PermitWorkplaceActions.GetCurrentIssuerCompanyStructure
    ): Observable<IssuerStructureNode[]> {
        return this.permitCreateService.getCurrentIssuerCompanyStructure(action.sortByDescending).pipe(tap((data) => {
            ctx.patchState({ structure: data });
            this.flattenTree(ctx);
        }));
    }

    @Action(PermitWorkplaceActions.GetRecentWorkplaceBasePermits)
    public getRecentWorkplaceBasePermits(
        ctx: StateContext<PermitWorkplaceStateModel>,
    ): Observable<RecentPermitWorkplace[]> {
        ctx.setState(produce((draft) => {
            draft.loading = true;
            draft.recentWorkplaces = [];
            draft.recentWorkplacesToShow = [];
        }));

        return this.permitCreateService.getRecentWorkplaceContainers().pipe(tap((workplaces) => {
            ctx.patchState({
                recentWorkplaces: workplaces,
                recentWorkplacesToShow: workplaces.slice(0, 3),
                loading: false,
            });
        }));
    }

    @Action(PermitWorkplaceActions.LoadMoreRecentWorkplaces)
    public loadMoreRecentWorkplaces(
        ctx: StateContext<PermitWorkplaceStateModel>,
        action: PermitWorkplaceActions.LoadMoreRecentWorkplaces,
    ) {
        const state = ctx.getState();
        const slicedArray = [...state.recentWorkplaces.slice(0, action.currentLength + 3)];
        ctx.patchState({
            recentWorkplacesToShow: slicedArray,
        });
    }

    @Action(PermitWorkplaceActions.ChangeView)
    public changeView(ctx: StateContext<PermitWorkplaceStateModel>, action: PermitWorkplaceActions.ChangeView): void {
        if (action.view == PermitWorkPlaceViewEnum.Start) {
            ctx.patchState({ selectedNode: undefined, selectedWorkplace: undefined });
        }

        ctx.patchState({
            view: action.view,
        });
    }

    @Action(PermitWorkplaceActions.CreatePermit)
    public createPermit(ctx: StateContext<PermitWorkplaceStateModel>, action: PermitWorkplaceActions.CreatePermit) {
        return this.permitCreateService.createPermit({
            workplace: action.workplace,
            isFlammableHotWork: this.store.selectSnapshot(PermitCreateSelectors.isFlammableHotWork),
            isDemo: this.getIsDemo(),
        }).pipe(tap((result) => {
            if (result.success) {
                ctx.setState(defaults);
                ctx.dispatch(new Navigate([PermitPaths.Root, result.newPermitId]));
            }
        }));
    }

    @Action(PermitWorkplaceActions.SetWorkplaceAndEmergencyLocation)
    public setWorkplaceAndEmergencyLocation(
        ctx: StateContext<PermitWorkplaceStateModel>,
        action: PermitWorkplaceActions.SetWorkplaceAndEmergencyLocation,
    ) {
        this.permitCreateService.createPermit({
            workplace: action.workplace,
            isFlammableHotWork: this.store.selectSnapshot(PermitCreateSelectors.isFlammableHotWork),
            isDemo: this.getIsDemo(),
        }).subscribe((result: CreatePermitResult) => {
            if (result.success) {
                ctx.setState(defaults);
                ctx.dispatch(
                    new PermitCreateActions.SetEmergencyLocation(
                        result.newPermitId,
                        action.emergencyLocation,
                        undefined,
                        undefined,
                    ),
                )
                .subscribe(_ => {
                    ctx.dispatch(new Navigate([PermitPaths.Root, result.newPermitId]));
                });
            }
        });
    }

    @Action(PermitWorkplaceActions.SetPremiumWorkplace)
    public setPremiumWorkplace(ctx: StateContext<PermitWorkplaceStateModel>) {
        const state = ctx.getState();
        const workplaceContainer = state.flatStructure.find(_ => _.structureNodeId === state.selectedWorkplace.nodeId);

        return this.permitCreateService.createPermit({
            workplace: !state.selectedWorkplace.isDefaultWorkplace ? state.selectedWorkplace.name : null,
            workplaceAdditionalInfo: state.freeTextForm.model.additionalInfo,
            workplaceContainer: workplaceContainer.name,
            companyName: workplaceContainer.companyName,
            customerPermitTemplateId: workplaceContainer.templateId,
            workplaceId: state.selectedWorkplace.id,
            isFlammableHotWork: this.store.selectSnapshot(PermitCreateSelectors.isFlammableHotWork),
            isDemo: this.getIsDemo(),
        }).pipe(tap((result) => {
            if (result.success) {
                ctx.setState(defaults);
                ctx.dispatch(new Navigate([PermitPaths.Root, result.newPermitId]));
            }
        }));
    }

    @Action(PermitWorkplaceActions.SetPremiumWorkplaceFromHistory)
    public setPremiumWorkplaceFromHistory(
        ctx: StateContext<PermitWorkplaceStateModel>,
        action: PermitWorkplaceActions.SetPremiumWorkplaceFromHistory,
    ) {
        return this.permitCreateService.createPermit({
            workplace: action.recentWorkplace.workplace,
            workplaceAdditionalInfo: action.recentWorkplace.workplaceAdditionalInfo,
            workplaceContainer: action.recentWorkplace.workplaceContainer,
            companyName: action.recentWorkplace.companyName,
            customerPermitTemplateId: action.recentWorkplace.templateId,
            workplaceId: action.recentWorkplace.nodeId,
            isFlammableHotWork: this.store.selectSnapshot(PermitCreateSelectors.isFlammableHotWork),
            isDemo: this.getIsDemo(),
        }).pipe(tap((result) => {
            if (result.success) {
                ctx.patchState({ selectedNode: undefined, selectedWorkplace: undefined });
                ctx.dispatch(new Navigate([PermitPaths.Root, result.newPermitId]));
            }
        }));
    }

    @Action(PermitWorkplaceActions.CreateWorkplace)
    public createWorkplace(
        ctx: StateContext<PermitWorkplaceStateModel>,
        action: PermitWorkplaceActions.CreateWorkplace,
    ) {
        const state = ctx.getState();
        return this.permitCreateService.createWorkplace(action.workplace, state.selectedNode.structureNodeId).pipe(tap((result) => {
            if (result.success) {
                ctx.dispatch(new PermitWorkplaceActions.GetCurrentIssuerCompanyStructure());

                // Select new workplace
                const workplace = state.selectedNode.workplaces.find(_ => _.id === result.newWorkplaceId);
                ctx.patchState({ selectedWorkplace: workplace });
            }
        }));
    }

    @Action(PermitWorkplaceActions.ToggleSelectedNode)
    public toggleSelectedNode(ctx: StateContext<PermitWorkplaceStateModel>, action: PermitWorkplaceActions.ToggleSelectedNode) {
        const defaultWorkplace = action.node?.workplaces?.find(_ => _.isDefaultWorkplace);

        if (action.node === undefined) {
            ctx.setState(produce((draft) => {
                if (draft.freeTextForm.model !== undefined) {
                    draft.freeTextForm.model.additionalInfo = undefined;
                }
            }));
        }

        ctx.patchState({
            selectedNode: action.node,
            selectedWorkplace: defaultWorkplace,
            searchResult: undefined
        });

        this.getSelectedNodeBreadcrumbs(ctx);
    }

    @Action(PermitWorkplaceActions.ToggleSelectedWorkplace)
    public toggleSelectedWorkplace(ctx: StateContext<PermitWorkplaceStateModel>, action: PermitWorkplaceActions.ToggleSelectedWorkplace): void {
        const state = ctx.getState();
        const workplace = state.selectedWorkplace?.id === action.workplace.id ? undefined : action.workplace;

        if (state.searchResult !== undefined) {
            const parentNode = state.flatStructure.find(_ => _.structureNodeId === workplace?.nodeId);
            ctx.patchState({ selectedNode: parentNode });
        }

        ctx.patchState({
            selectedWorkplace: workplace,
            searchResult: undefined
        });

        this.getSelectedNodeBreadcrumbs(ctx);
    }

    @Action(PermitWorkplaceActions.SelectSubscription)
    public selectSubscription(ctx: StateContext<PermitWorkplaceStateModel>, action: PermitWorkplaceActions.SelectSubscription): void {
        // Reset selections when changing subscription
        if (ctx.getState().selectedSubscriptionId > 0) {
            this.resetSelections(ctx);
        }

        ctx.patchState({ selectedSubscriptionId: action.subscriptionId });
    }

    @Action(PermitWorkplaceActions.SearchWorkplace)
    public searchNodesAndWorkplaces(
        ctx: StateContext<PermitWorkplaceStateModel>,
        action: PermitWorkplaceActions.SearchWorkplace): (IssuerStructureNode | Workplace)[] {
        const searchResult = new Set<(IssuerStructureNode | Workplace)>();
        const addedWorkplaces = new Set<Workplace>();

        ctx.setState(produce((draft) => {
            if (draft.freeTextForm.model !== undefined) {
                draft.freeTextForm.model.additionalInfo = undefined;
            }

            draft.selectedWorkplace = undefined;
        }));

        if (action.query.length <= 0) {
            this.resetSelections(ctx);
            ctx.dispatch(new PermitWorkplaceActions.GetCurrentIssuerCompanyStructure());
            return undefined;
        }

        const state = ctx.getState();

        for (const node of state.flatStructure.filter(_ => _.subscriptionId === state.selectedSubscriptionId && _.parentId !== null)) {
            if (node.name.toLowerCase().includes(action.query.toLowerCase())) {
                searchResult.add(node);
            }

            const workplaces = node.workplaces.filter(_ => !_.isDefaultWorkplace && _.name.toLowerCase().includes(action.query.toLowerCase()));

            workplaces.forEach(workplace => {
                const workplaceWithHierarchy = Object.assign({ hierarchy: node.hierarchy }, workplace);

                searchResult.add({...workplaceWithHierarchy});
                addedWorkplaces.add(workplace);
            });
        }

        ctx.patchState({ searchResult: Array.from(searchResult) });
        return Array.from(searchResult);
    }

    private flattenTree(ctx: StateContext<PermitWorkplaceStateModel>): void {
        const state = ctx.getState();
        const flattenedNodes: IssuerStructureNode[] = [];

        function climbTree(node: IssuerStructureNode) {
            flattenedNodes.push(node);
            node.childNodes.forEach(child => climbTree(child));
        }

        state.structure.forEach(rootNode => climbTree(rootNode));
        ctx.patchState({ flatStructure: flattenedNodes });
    }

    private getSelectedNodeBreadcrumbs(ctx: StateContext<PermitWorkplaceStateModel>): void {
        const state = ctx.getState();
        const breadcrumbs: StructureNodeBreadcrumb[] = [];
        const nodes = state.flatStructure;

        function traverse(node: IssuerStructureNode) {
            breadcrumbs.unshift({ name: node.name, node: node });
            if (node.parentId !== null) {
                const parentNode = nodes.find(_ => _.structureNodeId === node.parentId);
                traverse(parentNode);
            }
        }

        const startNode = nodes.find(_ => _.structureNodeId === state.selectedNode?.structureNodeId);
        if (startNode) {
            traverse(startNode);
            ctx.patchState({ selectedNodeBreadcrumbs: breadcrumbs });
        }
    }

    private getIsDemo(): boolean {
        const isDemo = this.router.routerState.snapshot.url.includes(PermitPaths.CreateDemo);
        this.store.dispatch(new PermitCreateActions.SetIsDemo(isDemo));
        return isDemo;
    }

    private resetSelections(ctx: StateContext<PermitWorkplaceStateModel>) {
        ctx.patchState({
            searchResult: undefined,
            selectedNode: undefined,
            selectedWorkplace: undefined,
            selectedNodeBreadcrumbs: undefined
        });
    }
}
