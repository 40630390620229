import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MenuItem } from "@ha/feature/episerver";
import { PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { HamburgerMenuStateActions } from "../../../core/states/hamburger-menu.actions";
import { HamburgerMenuState } from "../../../core/states/hamburger-menu.state";
import { KnowledgeBankState } from "../../../knowledge-bank/state/knowledge-bank.state";
import { FloatingKnowledgebankNavigationComponentAnimations } from "./floating-knowledgebank-navigation.component.animations";

@Component({
    selector: "floating-knowledgebank-navigation",
    templateUrl: "./floating-knowledgebank-navigation.component.html",
    styleUrls: ["./floating-knowledgebank-navigation.component.scss"],
    animations: [FloatingKnowledgebankNavigationComponentAnimations.fadeInOutTrigger],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingKnowledgebankNavigationComponent {
    @Select(KnowledgeBankState.menu)
    public menu$: Observable<MenuItem>;

    @Select(HamburgerMenuState.knowledgebankMenuVisible)
    public knowledgebankMenuVisible$: Observable<boolean>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    constructor(private store: Store) {}

    public toggleKnowledgebankMenu(): void {
        this.store.dispatch(new HamburgerMenuStateActions.ToggleKnowledgebankMenu());
    }
}
