<div class="puff" [class]="colorClass">
    <div class="puff__header" (click)="openCard()">
        <div class="puff__header__icon" [class]="'puff__header__icon--' + item.icon"></div>
        <div class="puff__header__heading" [innerHTML]="item.heading | haTranslate"></div>
        <div class="puff__header__toggle" [class.puff__header__toggle--open]="isOpen"></div>
    </div>

    <ng-container *ngIf="isOpen">
        <div class="puff__body" [innerHTML]="item.body | haTranslate">
        </div>
        <div class="puff__bottom" *ngIf="hasUrl" (click)="onClick()">
            <div class="puff__bottom__line"></div>
            <div class="puff__bottom__arrow"></div>
        </div>
    </ng-container>
</div>
