import { NgModule } from "@angular/core";
import { HaUiNavigationModule } from "@ha/ui/navigation";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";

import { SharedModule } from "../shared/shared.module";
import { UserAcceptNewAgreementComponent } from "./user-accept-new-agreement.component";
import { moduleTranslationProviderFactory } from "../translation/scope-loader";

@NgModule({
    imports: [
        SharedModule,
        NgxsFormPluginModule,
        HaUiNavigationModule,
    ],
    exports: [UserAcceptNewAgreementComponent],
    declarations: [UserAcceptNewAgreementComponent],
    providers: [
        moduleTranslationProviderFactory("userAcceptNewAgreement")
    ],
})
export class UserAcceptNewAgreementModule {}
