import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AccountImpersonateCommand } from "../models/account-impersonate-command.model";
import { AccountImpersonateResult } from "../models/account-impersonate-result.model";

@Injectable({
    providedIn: "root",
})
export class AccountImpersonateService {
    constructor(private httpClient: HttpClient) {}

    public impersonateStart(command: AccountImpersonateCommand): Observable<AccountImpersonateResult> {
        return this.httpClient.post<AccountImpersonateResult>("/api/AccountService/ImpersonateStart", command);
    }

    public impersonateStop(): Observable<AccountImpersonateResult> {
        return this.httpClient.get<AccountImpersonateResult>("/api/AccountService/ImpersonateStop");
    }
}
