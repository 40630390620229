import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { UserAgreement } from "../../user/models/user-agreement.model";
import { UserActivateAgreementCommand } from "../models/user-activate-agreement-command.model";
import { UserActivateCommand } from "../models/user-activate-command.model";
import { UserActivateResult } from "../models/user-activate-result.model";
import { UserValidateCommand } from "../models/user-validate-command.model";
import { UserAcceptNewAgreementCommand } from "../models/user-accept-new-agreement-command.model";

@Injectable({ providedIn: "root" })
export class UserActivateService {
    constructor(private httpClient: HttpClient) {}

    public activate(payload: UserActivateCommand): Observable<UserActivateResult> {
        return this.httpClient.post<UserActivateResult>("/api/AccountService/activate", payload);
    }

    public getHotworkerAgreement(payload: UserActivateAgreementCommand): Observable<UserAgreement> {
        return this.httpClient.post<UserAgreement>("/api/user/gethotworkeragreement", payload);
    }

    public validateUser(payload: UserValidateCommand): Observable<UserActivateResult> {
        return this.httpClient.post<UserActivateResult>("/api/AccountService/validateuser", payload);
    }

    public validateIdentityAndToken(identityUserId: string, token: string): Observable<boolean> {
        return this.httpClient.post<boolean>("/api/AccountService/ValidateIdentityAndToken", { identityUserId, token });
    }

    public acceptNewAgreement(data: UserAcceptNewAgreementCommand): Observable<boolean> {
        return this.httpClient.post<boolean>("/api/AccountService/acceptNewAgreement", data);
    }

}
