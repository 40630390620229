import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { Alert } from "../../models/fixed-template.model";
import { SafetyRule } from "../../models/safety-rule";

@Component({
    selector: "pmt-permit-confirm-dialog",
    templateUrl: "./permit-confirm-dialog.component.html",
    styleUrls: ["./permit-confirm-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitConfirmDialogComponent {
    @Input() public alert: Alert | undefined;
    @Input() public safetyRules: SafetyRule[];
    @Input() public confirmed = false;
    @Output() public confirmedChange = new EventEmitter<boolean>();


    public toggleConfirm() {
        this.confirmed = !this.confirmed;

        this.confirmedChange.emit(this.confirmed);
    }
}
