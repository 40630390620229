import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApplicationPaths } from "../../constants/application-paths";
import { KnowledgeBankSearchResult } from "../models/knowledge-bank-search-result";

@Injectable({
    providedIn: "root",
})
export class KnowledgeBankSearchService {
    constructor(private httpClient: HttpClient) {}

    public search(query: string, page: number = 1): Observable<KnowledgeBankSearchResult> {
        const options = {
            params: {
                query,
                page: page.toString(),
                angularModuleRoute: ApplicationPaths.KnowledgeBank.Root,
            },
        };

        return this.httpClient.get<KnowledgeBankSearchResult>("/api/cms/knowledgebank/search", options);
    }
}
