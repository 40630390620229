import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { HaError } from "../../models/error.model";
import { ErrorsStateActions } from "../../states/errors.actions";
import { ErrorSelectors } from "./errors.selectors";

@Component({
    selector: "errors",
    templateUrl: "./errors.component.html",
    styleUrls: ["./errors.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsComponent {
    @Select(ErrorSelectors.firstError)
    public error$!: Observable<HaError>;

    public constructor(private store: Store) {}

    public getShortCorrelationId(error: HaError): string {
        return error.correlationId.slice(error.correlationId.length - 6);
    }

    public dismiss(error: HaError): void {
        this.store.dispatch(new ErrorsStateActions.Dismiss(error));
    }
}
