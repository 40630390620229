import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngxs/store";

@Pipe({ name: "haStripHtml" })
export class HaStripHtmlPipe implements PipeTransform {
    constructor(private store: Store) {
    }

    public transform(value: string): string {
        if (!value) {
            return "";
        }

        const tmp = document.createElement("div");
        tmp.innerHTML = value;
        return tmp.textContent || tmp.innerText || "";
    }
}
