import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";
import { PermitPaths } from "../../../permit-paths";

import { PermitCompononentEnum } from "../../models/permit-component.enum.";
import { PermitStepActions } from "../../states/permit-step.action";
import { PermitStepSelectors } from "../../states/permit-step.selectors";

@UntilDestroy()
@Component({
    templateUrl: "permit.component.html",
    styleUrls: ["permit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitComponent implements OnInit {
    @Select(PermitStepSelectors.currentComponent)
    public currentComponent$: Observable<PermitCompononentEnum>;

    @Select(PermitCreateSelectors.isDemo)
    public isDemo$: Observable<boolean>;

    public permitId: number | undefined;

    public PermitPaths = PermitPaths;
    public PermitCompononent = PermitCompononentEnum;

    constructor(private store: Store, private activatedRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitStepActions.Reset());

        this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
            const permitId = parseInt(params.permitId as string);
            if (params.permitId && !isNaN(permitId)) {
                this.permitId = permitId;
                this.store.dispatch(new PermitStepActions.GetCurrentStep(this.permitId));
            }
        });
    }
}
