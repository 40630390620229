<div class="root" *transloco="let t; read:'permit.workplace'">
    <h2 class="root__heading" [innerHTML]="t('heading')"></h2>

    <div *ngIf="(creatingPermit$ | async) === true" class="root__creating">
        <div class="root__creating__icon"></div>
        <div class="root__creating__text">
            {{ t('creatingPermit') }}
        </div>
    </div>

    <ng-container *ngIf="(creatingPermit$ | async) !== true">
        <ng-container *ngIf="view$ | async as view">
            <pmt-workplace-start *ngIf="view === permitWorkPlaceViewEnum.Start"></pmt-workplace-start>
            <pmt-workplace-base *ngIf="view === permitWorkPlaceViewEnum.Base"></pmt-workplace-base>
            <pmt-workplace-premium *ngIf="view === permitWorkPlaceViewEnum.Premium"></pmt-workplace-premium>
        </ng-container>
    </ng-container>
</div>
