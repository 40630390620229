import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
    selector: "page-footer",
    templateUrl: "./page-footer.component.html",
    styleUrls: ["./page-footer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageFooterComponent {
    @Input() public publishDate: string;
    @Input() public changeDate: string;
    @Input() public shareUrl: string;
}
