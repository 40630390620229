<div class="no-access" *transloco="let t; read: 'noAccess'">
    <img class="no-access__img" src="assets/images/404-APP.svg">

    <div class="no-access__heading" [innerHTML]="t('heading')"></div>
    <div class="no-access__text" [innerHTML]="t('noAccessToPage')"></div>

    <div class="no-access__anchor" *ngIf="applicationList$ | async as applicationList" (click)="openApplicationModal(applicationList)">
        {{ t("chooseApplication") }}
    </div>
</div>
