import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FeatureNotificationModule } from "@ha/feature/notification";
import { HaUiCommonModule } from "@ha/ui/common";
import { HaUiNavigationModule } from "@ha/ui/navigation";
import { CoreInterceptor, HttpErrorInterceptor } from "@ha/util/network";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsModule, Store } from "@ngxs/store";

import { CertificateCardModule } from "../certificate/certificate-card.module";
import { KnowledgeBankModule } from "../knowledge-bank/knowledge-bank.module";
import { SharedModule } from "../shared/shared.module";
import { BookmarksModule } from "./../bookmarks/bookmarks.module";
import { HeaderComponent } from "./components/header/header.component";
import { PersonalPanelComponent } from "./components/header/personal-panel.component";
import { Http404Component } from "./components/Http404/http-404.component";
import { InstallModalComponent } from "./components/install-modal/install-modal.component";
import { MainNavigationComponent } from "./components/main-navigation/main-navigation.component";
import { MobileHeaderComponent } from "./components/mobile-header/mobile-header.component";
import { MobilePersonalPanelComponent } from "./components/mobile-header/mobile-personal-panel.component";
import { MobileNavigationComponent } from "./components/mobile-navigation/mobile-navigation.component";
import { NoAccessComponent } from "./components/no-access/no-access.component";
import { OfflineSignedOutComponent } from "./components/offline-signed-out/offline-signed-out.component";
import { AppInitService } from "./services/app-init.service";
import { CurrentUserState } from "./states/current-user.state";
import { HamburgerMenuState } from "./states/hamburger-menu.state";
import { ProfileState } from "./states/profile.state";
import { SettingsState } from "./states/settings.state";

@NgModule({
    declarations: [
        InstallModalComponent,
        MainNavigationComponent,
        MobileNavigationComponent,
        MobilePersonalPanelComponent,
        OfflineSignedOutComponent,
        MobileHeaderComponent,
        PersonalPanelComponent,
        Http404Component,
        HeaderComponent,
        NoAccessComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        TranslocoModule,
        NgxsModule.forFeature([SettingsState, CurrentUserState, ProfileState, HamburgerMenuState]),
        CertificateCardModule,
        BookmarksModule,
        KnowledgeBankModule,
        HaUiNavigationModule,
        FeatureNotificationModule
    ],
    exports: [
        InstallModalComponent,
        MainNavigationComponent,
        MobileNavigationComponent,
        MobilePersonalPanelComponent,
        OfflineSignedOutComponent,
        MobileHeaderComponent,
        HeaderComponent,
        NoAccessComponent,
        HaUiCommonModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: function OnInit(service: AppInitService) {
                return () => service.init();
            },
            deps: [AppInitService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: (store: Store) => {
                const s = new CoreInterceptor(store);
                s.languageSelector = SettingsState.currentLanguage;
                return s;
            },
            deps: [Store],
            multi: true,
        }
    ],
})
export class CoreModule {}
