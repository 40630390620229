<ng-container *transloco="let t; read:'permit.signature'">
    <div class="modal">
        <div class="modal__content">
            <signature-pad *ngIf="signaturePadOptions" class="modal__content__signature" #signature [options]="signaturePadOptions"
                           (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad>

            <div class="modal__content__details">
                <div class="modal__content__details__name-and-clear">
                    <div class="modal__content__details__name-and-clear__name">
                        {{ data.person.firstName }} {{ data.person.lastName }}
                    </div>
                    <button class="modal__content__details__name-and-clear__clear-button" (click)="clear()">{{ t("clear") }}</button>
                </div>

                <div class="modal__content__details__agreement" *ngIf="!agrementAcceptedBefore && !data.isDemo">
                    <ha-checkbox (click)="openAgreement($event)" [(ngModel)]="agreementAccepted" [ngModelOptions]="{standalone: true}"
                                 [label]="t('agreementAccepted')">
                    </ha-checkbox>
                </div>
            </div>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("cancel") }}</button>
            <button class="modal__actions__save" [class.modal__actions__save--disabled]="!isValid" type="button" (click)="save()">{{ t("save") }}</button>
        </div>
    </div>
</ng-container>
