<div class="root" *transloco="let t; read:'permit.workplace.recent'">
    <div class="root__workplaces" *ngIf="recentWorkplaces$ | async as workplaces">
        <ng-container *ngIf="workplaces.length > 0">
            <h3 class="root__workplaces__heading">{{ t("heading") }}</h3>

            <div class="root__workplaces__item" *ngFor="let workplace of workplaces" (click)="select(workplace)">
                <div class="root__workplaces__item__content">
                    <div class="root__workplaces__item__content__company" [innerHTML]="workplace.companyName"></div>
                    <div class="root__workplaces__item__content__workplace" [innerHTML]="fullWorkplaceName(workplace)"></div>
                    <div *ngIf="workplace.workplaceAdditionalInfo" class="root__workplaces__item__content__company"
                        [innerHTML]="workplace.workplaceAdditionalInfo"></div>
                    <div *ngIf="workplace.emergencyLocation" class="root__workplaces__item__content__emergencyLocation"
                         [innerHTML]="workplace.emergencyLocation"></div>
                </div>

                <div class="root__workplaces__item__arrow"></div>
            </div>

            <div *ngIf="canLoadMore$ | async" class="root__workplaces__load-more" (click)="loadMore(workplaces.length)">{{ t("loadMore") }}</div>
        </ng-container>
    </div>

    <div *ngIf="(loading$ | async) === true" class="root__loading"></div>
</div>
