<ng-container *ngIf="(haveAccessToPermit$ | async) === true">
    <div class="root" *transloco="let t; read:'permit'">
        <pmt-sign-permits-notice class="root__signatures"></pmt-sign-permits-notice>
        <pmt-subscription-invitations class="root__invitations"></pmt-subscription-invitations>

        <ng-container *ngIf="(isHotworkerOrPremiumIssuer$ | async) === true">
            <div class="root__create" (click)="create()">{{ t("createNew") }}</div>
            <div class="root__prepare" (click)="preparePermit()">{{ t("prepareWithCode") }}</div>
            <a class="root__prepared-permits" *ngIf="hasActiveSubscription$ | async" [routerLink]="PermitPaths.PreparedPermits">{{ t("getPreparedPermit") }}</a>
            <div *ngIf="(showCreateDemoButton$ | async) === true" class="root__create-demo-anchor" (click)="createDemo()">{{ t("createNewDemo") }}</div>
        </ng-container>

        <ng-container *ngIf="(isHotworkerOrPremiumIssuer$ | async) === false && (showCreateDemoButton$ | async) === true">
            <div class="root__create-demo" (click)="createDemo()">{{ t("createNewDemo") }}</div>
        </ng-container>

        <div class="root__tab-menu">
            <div class="item" [class.item--active]="selectedListType === PermitListType.Ongoing" (click)="changeTab(PermitListType.Ongoing)"
                    [innerHtml]="t('list.ongoing')"></div>
            <div class="item" [class.item--active]="selectedListType === PermitListType.Planned" (click)="changeTab(PermitListType.Planned)"
                    [innerHtml]="t('list.planned')"></div>
            <div class="item" [class.item--active]="selectedListType === PermitListType.Completed" (click)="changeTab(PermitListType.Completed)"
                    [innerHtml]="t('list.completed')"></div>
        </div>

        <div>
            <div *ngIf="selectedListType === PermitListType.Ongoing" class="root__filters">
                <ha-checkbox *ngIf="hasActiveSubscription$ | async"
                    [(ngModel)]="showAllSubscriptionPermits" [ngModelOptions]="{standalone: true}"
                    (click)="showSubscriptionPermits($event)"
                    [label]="t('showSubscriptionPermits')"
                    class="root__subscription-checkbox">
                </ha-checkbox>
                <ha-checkbox *ngIf="!(hasActiveSubscription$ | async)"
                    [(ngModel)]="showAllSubscriptionPermits" [ngModelOptions]="{standalone: true}"
                    [disabled]="true"
                    [label]="t('showSubscriptionPermits')"
                    class="root__subscription-checkbox">
                </ha-checkbox>

                <div class="filter">
                    <div *ngIf="filteredPermits$ | async as filter" class="filter__bar">
                        <div *ngIf="filter.length > 0" (click)="toggleFilter($event)" class="filter__bar__button">
                            <div class="filter__bar__button__icon"></div>
                            <div class="filter__bar__button__text">{{ t("filter") }}</div>
                            <div class="filter__bar__button__count" [innerHTML]="(selectedWorkplaces$ | async)?.length"></div>
                        </div>
                        <div *ngIf="filter.length == 0" class="filter__bar__button-disabled">
                            <div class="filter__bar__button-disabled__icon"></div>
                            <div class="filter__bar__button-disabled__text">{{ t("filter") }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="loader" *ngIf="loading$ | async"></div>

        <pmt-permit-list *ngIf="selectedListType === PermitListType.Ongoing" [permits]="filteredPermits$ | async"></pmt-permit-list>
        <pmt-permit-list *ngIf="selectedListType === PermitListType.Planned" [permits]="plannedPermits$ | async"></pmt-permit-list>

        <ng-container *ngIf="selectedListType === PermitListType.Completed">
            <pmt-permit-list [permits]="completedPermits$ | async"></pmt-permit-list>
        </ng-container>
    </div>
</ng-container>

<pmt-no-access *ngIf="(haveAccessToPermit$ | async) === false"></pmt-no-access>
