import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import format from "date-fns/format";

import { BaseControlValueAccessor, MakeValueAccessorProvider } from "../base-control-value-accessor.component";

@Component({
    selector: "ha-input",
    templateUrl: "./input.component.html",
    styleUrls: ["./input.component.scss"],
    providers: [MakeValueAccessorProvider(InputComponent)],
})
export class InputComponent extends BaseControlValueAccessor<string> implements OnInit, AfterViewInit {
    @Input() public label: string;
    @Input() public name: string;
    @Input() public type = "text";
    @Input() public autocomplete = "off";
    @Input() public autofocus = false;
    @Input() public readOnlyOnDisabled = false;
    @Input() public prefix: string;
    @Input() public maxlength: number;
    @Input() public uppercaseOnly = false;
    @Input() public min: string | number;
    @Input() public max: string | number;

    @ViewChild("inputElement", { static: false }) private inputElement: ElementRef<HTMLElement>;

    @Output() public dateChanged = new EventEmitter<Date>();

    public isDateType: boolean;

    public override get value(): string {
        return super.value;
    }

    public override set value(v: string) {
        // Fix for input of type date.
        if (this.isDateType) {
            // Set to null when cleared to avoid empty string.
            // Set value to yyyy-MM-dd format
            super.value = !v || v == "" ? null : format(new Date(v), "yyyy-MM-dd");
        } else {
            super.value = v;
        }
    }

    public get hasNeitherValueNorFocusNorPrefix(): boolean {
        return !this.value && !this.hasFocus && !this.prefix;
    }

    public ngOnInit(): void {
        // Fix for input of type date. We want the
        // date type to be active only when focused.
        if (this.type == "date") {
            this.isDateType = true;
            this.type = "text";
        }
    }

    public ngAfterViewInit(): void {
        if (this.autofocus) {
            this.hasFocus = true;
            this.inputElement.nativeElement.focus();
            this.changeDetectorRef.detectChanges();
        }
    }

    public setFocus(): void {
        setTimeout(() => {
            this.inputElement.nativeElement.focus();
            this.changeDetectorRef.detectChanges();
        });
    }

    public onInputFocus(): void {
        // Fix for input of type date. We want the
        // date type to be active only when focused.
        if (this.isDateType) {
            this.type = "date";
        }
    }

    public onInputBlur(): void {
        // Fix for input of type date. We want the
        // date type to be active only when focused.
        if (this.isDateType) {
            this.type = "text";
        }
    }

    public emitDate(date: MatDatepickerInputEvent<unknown, unknown>): void {
        if (Object.prototype.toString.call(date.value) === "[object Date]") {
            this.dateChanged.emit(date.value as Date);
        }
    }
}
