import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResult, Workplace } from "@ha/data/basic";
import { BaseValidationResult } from "@ha/ui/forms";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";

import {
    CheckpointAnswerCommand,
    CreatePermitCommand,
    DisabledFireAlarmCommand,
    EmergencyLocationCommand,
    PostWorkMonitoringTimeCommand,
    QuestionAnswerCommand,
    StartPermitCommand,
    ExtendPermitCommand,
    WorkMethodsCommand,
} from "../models/create-permit-commands";
import { CreatePermitResult } from "../models/create-permit-result.model";
import { CreateWorkplaceResult } from "../models/create-workplace-result.model";
import { Checkpoint, CustomerPermitTemplate } from "../models/customer-permit-template.model";
import { FixedQuestionComment } from "../models/fixed-question-comment.model";
import { FixedTemplate } from "../models/fixed-template.model";
import { IssuerStructureNode } from "../models/issuer-structure-node.model";
import { Permit } from "../models/permit.model";
import { RecentPermitWorkplace } from "../models/recent-workplaces.model";
import { WorkplaceDetails } from "../models/workplace-details.model";

@Injectable({ providedIn: "root" })
export class PermitCreateService {


    constructor(private httpClient: HttpClient, private store: Store) { }

    public getActiveFixedTemplate(): Observable<FixedTemplate> {
        return this.httpClient.get<FixedTemplate>("/api/FixedTemplate/GetActiveFixedTemplate");
    }

    public getFixedTemplateComments(subscriptionId: number, customerPermitTemplateId: number): Observable<FixedQuestionComment[]> {
        const options = {
            params: {
                subscriptionId: subscriptionId,
                customerPermitTemplateId: customerPermitTemplateId,
            },
        };

        return this.httpClient.get<FixedQuestionComment[]>("/api/FixedQuestionComment/GetAllForTemplate", options);
    }

    public getPermit(permitId: number): Observable<Permit> {
        const options = {
            params: {
                permitId: permitId,
            },
        };

        return this.httpClient.get<Permit>("/api/Permit/Get", options);
    }

    public getCustomerTemplate(customerPermitTemplateId: number, subscriptionId: number): Observable<CustomerPermitTemplate> {
        const options = {
            params: {
                customerPermitTemplateId: customerPermitTemplateId,
                subscriptionId: subscriptionId
            },
        };

        return this.httpClient.get<CustomerPermitTemplate>("/api/CustomerPermitTemplate/Get", options);
    }

    public getPreparatoryCheckpoints(templateId: number, subscriptionId: number): Observable<Checkpoint[]> {
        const options = {
            params: {
                templateId: templateId,
                subscriptionId: subscriptionId
            },
        };

        return this.httpClient.get<Checkpoint[]>("/api/CustomerCheckpoint/GetPreparatoryCheckpoints", options);
    }

    public getCurrentIssuerCompanyStructure(sortByDescending: boolean): Observable<IssuerStructureNode[]> {
        return this.httpClient.get<IssuerStructureNode[]>("/api/CurrentIssuer/CompanyStructure", { params: { sortByDescending: sortByDescending }});
    }

    public getRecentWorkplaceContainers(): Observable<RecentPermitWorkplace[]> {
        return this.httpClient.get<RecentPermitWorkplace[]>("/api/PermitList/GetRecentWorkplaceContainers");
    }

    public getWorkplacesForNode(nodeId: number): Observable<Workplace[]> {
        return this.httpClient.get<Workplace[]>("/api/CompanyStructure/GetWorkplacesForNode", {
            params: {
                nodeId: nodeId.toString()
            }
        });
    }

    public createWorkplace(name: string, nodeId: number): Observable<CreateWorkplaceResult> {
        return this.httpClient.post<CreateWorkplaceResult>("/api/CompanyStructure/CreateWorkplace", { name: name, parentNodeId: nodeId });
    }

    public createPermit(command: CreatePermitCommand): Observable<CreatePermitResult> {
        return this.httpClient.post<CreatePermitResult>("/api/Permit/CreatePermit", command);
    }

    public startPermit(command: StartPermitCommand): Observable<BaseResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/StartPermit", command);
    }

    public extendPermit(command: ExtendPermitCommand): Observable<BaseResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/ExtendPermit", command);
    }

    public endDemoOrNotFlammableHotWork(permitId: number): Observable<BaseResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/PermitEndDemoOrNotFlammableHotWork", { permitId: permitId });
    }

    public setFixedQuestionAnswer(command: QuestionAnswerCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/SetFixedQuestionAnswer", command);
    }

    public setCheckpointAnswer(command: CheckpointAnswerCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/SetCustomerCheckpointAnswer", command);
    }

    public setDisabledFireAlarm(command: DisabledFireAlarmCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/SetDisabledFireAlarm", command);
    }

    public setEmergencyLocation(command: EmergencyLocationCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/SetEmergencyLocation", command);
    }

    public setPostWorkMonitoringTime(command: PostWorkMonitoringTimeCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/SetPostWorkMonitoringTime", command);
    }

    public saveWorkMethods(command: WorkMethodsCommand): Observable<BaseValidationResult> {
        return this.httpClient.put<BaseValidationResult>("/api/Permit/SetWorkMethods", command);
    }

    public getWorkplaceDetails(workplaceId: number): Observable<WorkplaceDetails> {
        return this.httpClient.get<WorkplaceDetails>("/api/WorkplaceDetails/GetDetails", {
            params: {
                workplaceId: workplaceId
            }
        });
    }
}
