<ng-container *transloco="let t; read:'feature.notification'">
    <div class="modal">
        <div class="modal__content">
            <div class="modal__content__header">
                <h2 class="modal__content__header__heading">{{ t("notifications") }}</h2>
                <div class="modal__content__header__mark-as-read" (click)="markAllAsRead()">{{ t("markAsRead") }}</div>
            </div>
            <ng-container *ngIf="notifications$ | async as notifications">
                <ng-container *ngIf="notifications.length > 0">
                    <a class="modal__content__notification" *ngFor="let notification of notifications" [routerLink]="['notification', notification.contentId]"
                       [class.modal__content__notification--unread]="notification.status === NotificationStatus.Seen">
                        <div class="modal__content__notification__heading" [innerHTML]="notification.heading"></div>
                        <div class="modal__content__notification__text" [innerHTML]="notification.mainBody"></div>
                        <div class="modal__content__notification__text" [innerHTML]="notification.publishedDate | haDate:'dateTimeStandard'"></div>

                        <hr class="modal__content__notification__divider">
                    </a>
                </ng-container>

                <ng-container *ngIf="loading$ | async as loading">
                    <div *ngIf="loading === true" class="modal__content__loading"></div>
                    <div *ngIf="!loading && notifications.length <= 0" class="modal__content__notification__text">{{ t("noNotifications") }}</div>
                </ng-container>
            </ng-container>

            <a class="modal__content__show-all" routerLink="notification">{{ t("showAll") }}</a>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>
        </div>

        <div *ngIf="(loading$ | async) === true" class="modal__loading"></div>
    </div>
</ng-container>
