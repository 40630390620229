<ul class="list">
    <li class="list__item" *ngFor="let item of data.linkList.value">
        <link-item [link]="item">
            <div class="list__item__content">
                <div class="list__item__content__type" [class]="'list__item__content__type--' + getIconClass(item)"></div>
                <div class="list__item__content__title">{{ item.text }}</div>
                <div class="list__item__content__arrow"></div>
            </div>
        </link-item>
    </li>
</ul>
