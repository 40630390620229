import { Injectable } from "@angular/core";
import { State } from "@ngxs/store";

export interface LanguageStateModel {
    currentLanguage: string;
}

@Injectable()
@State<LanguageStateModel>({
    name: "language",
    defaults: {
        currentLanguage: "sv",
    },
})
export class LanguageState {
}
