<div class="control" [class.control--expanded]="expanded" [class.control--unselected]="isUnselected" [class.control--disabled]="isDisabled"
     (click)="toggleExpand()" (focus)="onFocus()" (clickOutside)="close()" (blur)="onBlur($event)" [class.control--invalid]="invalidAndDirty">
    <select [attr.name]="formControlName" class="control__native-select" [(ngModel)]="value">
        <option *ngIf="isUnselected" [ngValue]="undefined" disabled="true"></option>
        <option *ngFor="let item of listItems" [ngValue]="item.value" [innerHTML]="item.text" [disabled]="item.isDisabled"></option>
    </select>
    <div class="control__custom-select" [innerHTML]="text"></div>
    <label class="control__label">{{ label }}</label>
    <div class="control__arrow"></div>
    <div class="control__option-list">
        <div *ngFor="let item of listItems" (click)="selectOption(item)" class="control__option-list__item"
             [class.control__option-list__item--disabled]="item.isDisabled" [class.control__option-list__item--selected]="!isUnselected">
            <div class="control__option-list__item__text" [innerHTML]="item.text"></div>
            <div class="control__option-list__item__sub-text" [innerHTML]="item.extraLabelText"></div>
        </div>
    </div>
</div>
