import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { tap } from "rxjs/operators";

import { AutocompleteListItem } from "./../models/autocomplete-item";
import { EpiserverFindService } from "../services/episerver-find.service";
import { EpiserverStateActions } from "./episerver-state-actions";

export interface EpiserverFindStateModel {
    autocompleteList: AutocompleteListItem[];
}

@State<EpiserverFindStateModel>({
    name: "episerverFind",
}) @Injectable()
export class EpiserverFindState {
    constructor(private episerverFindService: EpiserverFindService) {}

    @Selector()
    public static autocompleteList(state: EpiserverFindStateModel): AutocompleteListItem[] {
        return state.autocompleteList;
    }

    @Action(EpiserverStateActions.SearchAutocomplete, { cancelUncompleted: true })
    public Autocomplete(ctx: StateContext<EpiserverFindStateModel>, action: EpiserverStateActions.SearchAutocomplete) {
        if (!action.text || action.text.length < 1) {
            ctx.patchState({
                autocompleteList: [],
            });

            return of(undefined);
        }

        return this.episerverFindService.autocomplete(action.text).pipe(tap((autocomplete) => {
            ctx.patchState({
                autocompleteList: autocomplete,
            });
        }));
    }

    @Action(EpiserverStateActions.SearchClickTrack)
    public SearchClickTrack(
        ctx: StateContext<EpiserverFindStateModel>,
        action: EpiserverStateActions.SearchClickTrack,
    ) {
        return this.episerverFindService.clickTrack(action.query, action.hitId, action.trackId);
    }
}
