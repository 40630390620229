import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Settings } from "../models/settings.model";

@Injectable({ providedIn: "root" })
export class SettingsService {
    constructor(private httpClient: HttpClient) {}

    public getSettings() {
        return this.httpClient.get<Settings>("/api/settings");
    }
}
