import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, Select, Store } from "@ngxs/store";
import { Observable, take } from "rxjs";

import { IssuerDto } from "../../../models/change-issuer-dto";
import { PermitChangeIssuerActions } from "../../../states/change-issuer-state/change-issuer.action";
import { PermitChangeIssuerSelectors } from "../../../states/change-issuer-state/change-issuer.selectors";


export interface PermitChangeIssuerModalComponentData {
    permitId: number;
}

@UntilDestroy()
@Component({
    templateUrl: "./change-issuer-modal.component.html",
    styleUrls: ["./change-issuer-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitChangeIssuerModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public data: PermitChangeIssuerModalComponentData;

    @Select(PermitChangeIssuerSelectors.selectedNewIssuer)
    public issuerSelected$: Observable<IssuerDto>;

    @Select(PermitChangeIssuerSelectors.isPremium)
    public isPremium$: Observable<boolean>;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionCompleted(PermitChangeIssuerActions.ChangeIssuer))
            .pipe(untilDestroyed(this), take(1))
            .subscribe(() => {
                this.closeModal();
            });
    }

    public ngOnDestroy(): void {
        this.store.dispatch(new PermitChangeIssuerActions.GetStatus(this.data.permitId));
        this.store.dispatch(new PermitChangeIssuerActions.Reset());
    }

    public changeIssuer() {
        this.store.dispatch(new PermitChangeIssuerActions.ChangeIssuer(this.data.permitId));
    }
}

