<div *ngIf="currentComponent$ | async as currentComponent" class="root">
    <ng-container *transloco="let t; read:'permit'">
        <div class="root__top-bar" [class.root__top-bar--is-demo]="isDemo$ | async">
            <a class="root__top-bar__anchor" [routerLink]="['/', PermitPaths.Root]">{{ t("permit") }}</a>
            <ng-container *ngIf="isDemo$ | async">
                <hr class="root__top-bar__divider">
                <div class="root__top-bar__demo">{{ t("demo") }}</div>
            </ng-container>
        </div>

        <div class="root__demo" *ngIf="isDemo$ | async">{{ t("demo") }}</div>
    </ng-container>

    <pmt-permit-create class="root__content" [class.root__content--is-demo]="isDemo$ | async" [permitId]="permitId"
            *ngIf="currentComponent === PermitCompononent.Create"></pmt-permit-create>

    <pmt-permit-details class="root__content" [permitId]="permitId" *ngIf="currentComponent === PermitCompononent.Details"></pmt-permit-details>
</div>
