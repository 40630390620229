import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HaUiFormsModule } from "@ha/ui/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsModule } from "@ngxs/store";

import { SharedModule } from "../shared/shared.module";
import { PermitListItemComponent } from "./components/permit-list-item/permit-list-item.component";
import { PermitListComponent } from "./components/permit-list/permit-list.component";
import { PreparedPermitListItemComponent } from "./components/prepared-permit-list-item/prepared-permit-list-item.component";
import { PreparedPermitListComponent } from "./components/prepared-permit-list/prepared-permit-list.component";
import { PermitListState } from "./states/permit-list.state";
import { RemovePlannedPermitModalComponent } from "./components/remove-planned-permit-modal/remove-planned-permit-modal.component";

@NgModule({
    imports: [
        SharedModule,
        NgxsModule.forFeature([PermitListState]),
        ReactiveFormsModule,
        HaUiFormsModule,
        TranslocoModule,
    ],
    exports: [
        PermitListComponent
    ],
    declarations: [
        PermitListComponent,
        PermitListItemComponent,
        PreparedPermitListComponent,
        PreparedPermitListItemComponent,
        RemovePlannedPermitModalComponent
    ],
    providers: [
    ]
})
export class PermitListModule {}
