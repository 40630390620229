import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";

import { BaseControlValueAccessor, MakeValueAccessorProvider } from "../base-control-value-accessor.component";
import { SearchBoxAutocompleteListItem } from "./search-box-autocomplete.model";

@Component({
    selector: "search-box",
    templateUrl: "search-box.component.html",
    styleUrls: ["search-box.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MakeValueAccessorProvider(SearchBoxComponent)],
})
export class SearchBoxComponent extends BaseControlValueAccessor<string> implements AfterViewInit {
    public override get value(): string {
        return super.value;
    }

    public override set value(v: string) {
        super.value = v;
    }

    @Output() public queryChange = new EventEmitter<string>();

    @Input() public focus: boolean;

    @Input() public autocompleteList: SearchBoxAutocompleteListItem[];

    @Input() public placeholder: string;

    @Output() public triggerSearch = new EventEmitter<string>();

    @ViewChild("searchInput", { static: true }) private searchInputElement: ElementRef<HTMLElement>;

    public autocompleteHidden = true;

    private selectedAutocompleteListItem: SearchBoxAutocompleteListItem;

    public ngAfterViewInit(): void {
        this.setFocus();
    }

    public reset(): void {
        this.value = undefined;
        this.autocompleteHidden = true;
        this.searchInputElement.nativeElement.blur();
        this.changeDetectorRef.detectChanges();
    }

    public clickOutside(): void {
        this.autocompleteHidden = true;
    }

    public setFocus(): void {
        if (this.focus) {
            setTimeout(() => {
                this.searchInputElement.nativeElement.focus();
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    public isSelected(autocompleteListItem: SearchBoxAutocompleteListItem): boolean {
        return this.selectedAutocompleteListItem === autocompleteListItem;
    }

    public searchKeypress(event: KeyboardEvent): void {
        switch (event.key) {
            case "ArrowDown": {
                this.setSelectedAutocompleteItem(true);
                break;
            }
            case "ArrowUp": {
                this.setSelectedAutocompleteItem(false);
                break;
            }
            case "Escape": {
                this.autocompleteHidden = true;
                this.selectedAutocompleteListItem = undefined;
                break;
            }
            case "Tab": {
                return;
            }
            case "Enter": {
                this.search();
                break;
            }
            default: {
                this.autocompleteHidden = false;
                this.queryChange.emit(this.value);
            }
        }

        event.preventDefault();
    }

    private setSelectedAutocompleteItem(down: boolean) {
        let newIndex: number;

        if (this.selectedAutocompleteListItem) {
            newIndex = this.autocompleteList.indexOf(this.selectedAutocompleteListItem) + (down ? 1 : -1);
        } else {
            newIndex = 0;
        }

        if (newIndex >= this.autocompleteList.length) {
            newIndex = 0;
        } else if (newIndex < 0) {
            newIndex = this.autocompleteList.length - 1;
        }

        this.selectedAutocompleteListItem = this.autocompleteList[newIndex];
        this.value = this.selectedAutocompleteListItem.text;
    }

    public autoCompleteSearch(autocompleteListItem: SearchBoxAutocompleteListItem): void {
        this.autocompleteHidden = true;
        this.triggerSearch.emit(autocompleteListItem.text);
    }

    public search(): void {
        this.autocompleteHidden = true;
        this.triggerSearch.emit(this.value);
    }

    public clickSearch(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.search();
    }
}
