import { Breadcrumb } from "../models/breadcrumb.model";

export namespace BreadcrumbActions {
    export class Set {
        public static readonly type = "[Breadcrumb] Set";
        constructor(public breadcrumbs: Breadcrumb[] | undefined) {}
    }

    export class SetTextOrLangKey {
        public static readonly type = "[Breadcrumb] SetTextOrLangKey";
        constructor(public names: { langKey?: string, text?: string; }[]) {}
    }
}
