<ng-container *transloco="let t; read: 'userAcceptNewAgreement'">
    <div class="modal">
        <h1 class="modal__heading">{{ t("heading") }}</h1>

        <ng-container *ngIf="activationAgreement$ | async; let agreement">
            <div class="modal__content" [innerHTML]="agreement.text"></div>
        </ng-container>

        <div class="modal__actions">
            <button class="modal__actions__cancel" type="button" (click)="cancel()">{{ t("cancel") }}</button>
            <button class="modal__actions__ok" type="button" (click)="accept()">{{t("accept") }}</button>
        </div>
    </div>
</ng-container>
