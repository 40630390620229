import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "haInlineDelimiterList" })
export class HaInlineDelimiterListPipe implements PipeTransform {
    public transform(value: string[], lastSeparator: string, wrapElement: string = "", separator: string = ", ") {
        let text = "";

        const startElement = wrapElement ? `<${wrapElement}>` : "";
        const endElement = wrapElement ? `</${wrapElement}>` : "";

        value.forEach((v, i) => {
            const isLast = i + 1 === value.length;
            const isSecondLast = i + 2 === value.length;
            text += `${startElement}${v}${endElement}${isLast ? "" : isSecondLast ? lastSeparator : separator}`;
        });

        return text;
    }
}
