import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IssuuContainerBlock } from "./issuu-container-block";

@Component({
    templateUrl: "issuu-container-block.component.html",
    styleUrls: ["issuu-container-block.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssuuContainerBlockComponent {
    public data: IssuuContainerBlock;
}
