import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[clickAnchor]",
})
export class ClickAnchorDirective {
    @Output()
    public clickAnchor = new EventEmitter();

    @HostListener("click", ["$event"])
    public click(event: MouseEvent): void {
        const element = event.target as HTMLElement;
        if (element.tagName.toLowerCase() === "a") {
            this.clickAnchor.emit(event);
        }
    }
}
