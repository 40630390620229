<div class="root" *transloco="let t; read: 'feature.notification'">
    <div class="root__header">
        <div class="root__header__heading">{{ t("notifications") }}</div>

        <ng-container *ngIf="notifications$ | async as notifications">
            <div class="root__header__actions" *ngIf="notifications.length > 0" (click)="openActionsModal()"></div>
        </ng-container>
    </div>

    <ng-container *ngIf="notifications$ | async as notifications">
        <div class="root__notification-list" *ngIf="notifications.length > 0">
            <ntf-notification-list-item *ngFor="let notification of notifications" [notification]="notification"></ntf-notification-list-item>
        </div>

        <div *ngIf="notifications.length <= 0" class="root__no-notifications">
            {{ t("noNotifications") }}
        </div>
    </ng-container>
</div>
