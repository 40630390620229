import { AfterContentInit, ChangeDetectorRef, Directive, forwardRef, Injector, Input } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { TranslocoService } from "@ngneat/transloco";
import { DeviceDetectorService } from "ngx-device-detector";

import { ValidationService } from "./validation-messages/validation.service";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseControlValueAccessor<T> implements ControlValueAccessor, AfterContentInit {
    @Input()
    public formControlName: string | number = Math.floor(Math.random() * 10000);

    constructor(
        protected injector: Injector,
        protected validationService: ValidationService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected deviceDetectorService: DeviceDetectorService,
        protected translocoService: TranslocoService,
        protected dateAdapter: DateAdapter<unknown>,
    ) {
        this.translocoService.langChanges$.subscribe((lang) => {
            this.dateAdapter.setLocale(lang);
        });
    }

    public hasFocus = false;

    public set value(v: T) {
        if (v !== this._value) {
            const newValue = this._value !== v;
            this._value = v;

            if (newValue) {
                this.propagateChange(this._value);
            }
        }
    }
    public get value(): T {
        return this._value;
    }

    public get invalidAndDirty(): boolean {
        return this.control && this.control.invalid && this.control.touched;
    }

    public get isRequired(): boolean {
        if (this.control?.validator) {
            const validator = this.control.validator({} as AbstractControl);
            return (validator && (validator["required"] || validator["requiredTrue"])) as boolean;
        }

        return false;
    }

    public get isNotDesktop() {
        return !this.deviceDetectorService.isDesktop();
    }

    public isDisabled = false;

    public control: AbstractControl;

    protected _value: T = undefined;

    public ngAfterContentInit(): void {
        if (this.formControlName) {
            this.control = this.injector.get(NgControl).control;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateChange = (_: T): void => null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateTouched = (_: T): void => null;

    public registerOnChange(fn: (_: T) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: (_: T) => void): void {
        this.propagateTouched = fn;
    }

    public writeValue(value: T): void {
        this.value = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public markAsPristineAndUntouched(): void {
        this.control.markAsPristine();
        this.control.markAsUntouched();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onBlur(event: FocusEvent): void {
        this.hasFocus = false;
        this.propagateTouched(undefined);
    }

    public onChange(event: Event): void {
        this.propagateTouched(undefined);
    }

    public onFocus(): void {
        this.hasFocus = true;
        this.validationService.controlFocused(this.control);
    }
}

export function MakeValueAccessorProvider(type: unknown): { provide: unknown; useExisting: unknown; multi: boolean; } {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true,
    };
}
