import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";

import { SharedModule } from "../shared/shared.module";
import {
    AcceptUserAgreementOnIssuerUnitModalComponent,
} from "./components/user-agreement-modal/accept-user-agreement-on-issuer-unit-modal.component";
import {
    AcceptUserAgreementWhenSignedInModalComponent,
} from "./components/user-agreement-modal/accept-user-agreement-when-signed-in-modal.component";
import { UserAgreementState } from "./states/user-agreement.state";

@NgModule({
    imports: [
        SharedModule,
        NgxsModule.forFeature([UserAgreementState]),
    ],
    exports: [],
    declarations: [
        AcceptUserAgreementWhenSignedInModalComponent,
        AcceptUserAgreementOnIssuerUnitModalComponent
    ],
    providers: []
})
export class UserAgreementModule {}
