import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";

import { PermitBasicDetailsDto } from "../../models/permit-basic-details.model";
import { PermitDetailsSelectors } from "../../states/permit-details.selectors";

@Component({
    selector: "pmt-permit-basic-details",
    templateUrl: "./permit-basic-details.component.html",
    styleUrls: ["./permit-basic-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitBasicDetailsComponent {
    @Input() public permitId: number;

    @Select(PermitDetailsSelectors.basicDetails)
    public details$: Observable<PermitBasicDetailsDto>;

    public fullWorkplaceName(details: PermitBasicDetailsDto): string {
        if (details.workplaceContainer == null)
            return details.workplace;
        else
            return details.workplace?.length > 0 ? details.workplaceContainer + " - " + details.workplace : details.workplaceContainer;
    }
}
