import { ChangeDetectionStrategy, Component } from "@angular/core";
import { HaModalService } from "@ha/ui/common";
import { ApplicationListModalComponent, ApplicationListModalComponentData } from "@ha/ui/navigation";
import { PwaState } from "@ha/util/pwa";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { ApplicationPaths } from "../../../constants/application-paths";
import { CurrentUser } from "../../models/current-user.model";
import { Settings } from "../../models/settings.model";
import { CurrentUserState } from "../../states/current-user.state";
import { SettingsState } from "../../states/settings.state";

@Component({
    selector: "main-navigation",
    templateUrl: "./main-navigation.component.html",
    styleUrls: ["./main-navigation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent {
    @Select(SettingsState.settings)
    public settings$: Observable<Settings>;

    @Select(CurrentUserState.isExternal)
    public isExternal$: Observable<boolean>;

    @Select(CurrentUserState.showLinkToAdmin)
    public showLinkToAdmin$: Observable<boolean>;

    @Select(CurrentUserState.isImpersonated)
    public currentUserIsImpersonated$: Observable<boolean>;

    @Select(CurrentUserState.currentUser)
    public currentUser$: Observable<CurrentUser>;

    @Select(PwaState.isMobile)
    public isMobile$: Observable<boolean>;

    public applicationPaths = ApplicationPaths;

    constructor(private modalService: HaModalService, private store: Store) {}

    public openApplicationModal(): void {
        const applicationList = this.store.selectSnapshot(SettingsState.applicationList);
        this.modalService.open(ApplicationListModalComponent, true, <ApplicationListModalComponentData>{ applicationList: applicationList });
    }
}
