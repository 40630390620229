import { Selector } from "@ngxs/store";

import { PermitListItem } from "../models/permit-list-item.model";
import { PreparedPermitListItem } from "../models/prepared-permit-list-item.model";
import { PermitListState, PermitListStateModel } from "./permit-list.state";
import { PermitWorkplaceItem } from "../models/permit-workplace-item.model";

export class PermitListSelectors {

    @Selector([PermitListState])
    public static ongoingPermits(state: PermitListStateModel): PermitListItem[] | undefined {
        return state?.ongoingPermits;
    }

    @Selector([PermitListState])
    public static filteredPermits(state: PermitListStateModel): PermitListItem[] | undefined {
        return state?.filteredPermits;
    }

    @Selector([PermitListState])
    public static plannedPermits(state: PermitListStateModel): PermitListItem[] | undefined {
        return state?.plannedPermits;
    }

    @Selector([PermitListState])
    public static completedPermits(state: PermitListStateModel): PermitListItem[] | undefined {
        return state?.completedPermits;
    }

    @Selector([PermitListState])
    public static preparedPermits(state: PermitListStateModel): PreparedPermitListItem[] | undefined {
        return state?.preparedPermits;
    }

    @Selector([PermitListState])
    public static showAllSubscriptionPermits(state: PermitListStateModel): boolean | undefined {
        return state?.showAllSubscriptionPermits;
    }

    @Selector([PermitListState])
    public static selectedWorkplaces(state: PermitListStateModel): string[] | undefined {
        return state?.selectedWorkplaces;
    }

    @Selector([PermitListState])
    public static workplaces(state: PermitListStateModel): PermitWorkplaceItem[] | undefined {
        if (state?.ongoingPermits == null)
            return [];

        var workplaces = state?.ongoingPermits.filter((value, index, self) => self.indexOf(value) === index);
        return workplaces
            .map(item => ({
                name: item.workplaceContainer,
                availablePermits: state?.ongoingPermits.filter((x) => x.workplaceContainer === item.workplaceContainer).length,
                isSelected: state?.selectedWorkplaces?.indexOf(item.workplaceContainer) > -1,
            }))
            .filter((value, index, self) => self.findIndex(item => (item.name) === value.name) === index)
            .sort(function (a, b) {
                if (a.name === null) {
                    return -1;
                }
                if (b.name === null) {
                    return 1;
                }

                return a.name?.localeCompare(b.name);
            });
    }
}
