import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, Store } from "@ngxs/store";

import { PermitTeamActions } from "../../states/permit-team.action";


export interface ScanCardModalComponentData {
    permitId: number;
    role: PersonRole;
    isPremium: boolean;
}

@UntilDestroy()
@Component({
    templateUrl: "scan-card-modal.component.html",
    styleUrls: ["scan-card-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanCardModalComponent extends BaseModalComponent implements OnInit {
    public data: ScanCardModalComponentData;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit() {
        this.actions$.pipe(ofActionCompleted(PermitTeamActions.FindPerson), untilDestroyed(this)).subscribe(() => {
            this.closeModal();
        });
    }

    public scanCompleteHandler(cardGuid: string) {
        this.store.dispatch(new PermitTeamActions.FindPerson({ query: cardGuid, role: this.data.role, isPremium: this.data.isPremium }));
    }
}
