import "@ha/util/common";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";

import { AccountImpersonateState } from "./states/account-impersonate.state";
import { AuthState } from "./states/auth.state";

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forFeature([AuthState, AccountImpersonateState]),
    ]
})
export class HaFeatureAuthModule {}
