<ng-container *transloco="let t; read:'permit.details.teamContactModal'">
    <div class="modal">
        <div class="modal__content">
            <div>{{ t("intro", { name: data.name }) }}</div>
            <a *ngIf="data.phone" href="tel:{{ data.phone }}" class="modal__content__phone">{{ t("call") }}</a>
            <a *ngIf="data.email" href="mailto:{{ data.email }}" class="modal__content__email">{{ t("email") }}</a>
        </div>

        <div class="modal__actions">
            <button class="modal__actions__close" type="button" (click)="closeModal()">{{ t("close") }}</button>
        </div>
    </div>
</ng-container>
