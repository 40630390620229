import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "pmt-no-access",
    templateUrl: "permit-no-access.component.html",
    styleUrls: ["permit-no-access.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitNoAccessComponent {
}

