import { Component, OnInit } from "@angular/core";
import { AuthActions } from "@ha/feature/auth";
import { Store } from "@ngxs/store";

@Component({
    template: "",
})
export class SignOutComponent implements OnInit {
    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.signOut();
    }

    public signOut(): void {
        this.store.dispatch(new AuthActions.SignOut());
    }
}
