import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { NotificationStatus } from "../../models/notification-status.enum";
import { NotificationActions } from "../../states/notification.actions";

@UntilDestroy()
@Component({
    templateUrl: "notification-list-actions-modal.component.html",
    styleUrls: ["./notification-list-actions-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationListActionsModalComponent extends BaseModalComponent {
    constructor(private store: Store) {
        super();
    }

    public markAllAsRead() {
        this.store.dispatch(new NotificationActions.SetStatusForAll(NotificationStatus.Read))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.closeModal();
            });
    }

    public removeAll() {
        this.store.dispatch(new NotificationActions.SetStatusForAll(NotificationStatus.Deleted))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.closeModal();
            });
    }
}
