<ng-container *transloco="let t; read:'permit.userAgreement'">
    <div class="modal">
        <div class="modal__content" [innerHtml]="data.userAgreement[currentLanguage$ | async]"></div>

        <div class="modal__actions">
            <button class="modal__actions__reject" type="button" (click)="reject()">{{ t("reject") }}</button>
            <button class="modal__actions__accept" type="button" (click)="accept()" [loading]="UserAgreementActionsAcceptUserAgreement">{{ t("accept")
                }}</button>
        </div>
    </div>
</ng-container>
