import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { HaCardNumberPipe } from "./pipes/ha-card-number.pipe";
import { HaDatePipe } from "./pipes/ha-date.pipe";
import { HaInlineDelimiterListPipe } from "./pipes/ha-inline-delimeter-list.pipe";
import { HaPersonalNumberPipe } from "./pipes/ha-personal-number.pipe";
import { HaStripHtmlPipe } from "./pipes/ha-strip-html.pipe";
import { HaTranslatePipe } from "./pipes/ha-translate.pipe";
import { HaPhoneNumberPipe } from "./pipes/phone-number.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";

export { HaCardNumberPipe } from "./pipes/ha-card-number.pipe";
export { HaDatePipe } from "./pipes/ha-date.pipe";
export { HaInlineDelimiterListPipe } from "./pipes/ha-inline-delimeter-list.pipe";
export { HaPersonalNumberPipe } from "./pipes/ha-personal-number.pipe";
export { HaStripHtmlPipe } from "./pipes/ha-strip-html.pipe";
export { HaTranslatePipe } from "./pipes/ha-translate.pipe";
export { HaPhoneNumberPipe } from "./pipes/phone-number.pipe";
export { SafeHtmlPipe } from "./pipes/safe-html.pipe";

@NgModule({
    imports: [CommonModule],
    declarations: [
        HaCardNumberPipe,
        HaDatePipe,
        HaInlineDelimiterListPipe,
        HaPersonalNumberPipe,
        HaPhoneNumberPipe,
        HaTranslatePipe,
        HaStripHtmlPipe,
        SafeHtmlPipe,
    ],
    exports: [
        HaCardNumberPipe,
        HaDatePipe,
        HaInlineDelimiterListPipe,
        HaPersonalNumberPipe,
        HaPhoneNumberPipe,
        HaTranslatePipe,
        HaStripHtmlPipe,
        SafeHtmlPipe,
    ],
    providers: [HaDatePipe],
})
export class HaUtilPipesModule {}
