import { Injectable } from "@angular/core";
import { PermitStep } from "@ha/data/basic";
import { Action, State, StateContext } from "@ngxs/store";
import produce from "immer";
import { tap } from "rxjs";

import { PermitCreateActions } from "../../permit-create/states/permit-create.actions";
import { CurrentStep } from "../models/current-step.model";
import { PermitStepService } from "../services/permit-step.service";
import { PermitStepActions } from "./permit-step.action";

export interface PermitStepStateModel {
    currentStep: CurrentStep;
    permitId: number;
}

const defaults = {
    currentStep: {
        step: undefined,
        currentQuestionId: undefined,
    },
    permitId: undefined,
};

@Injectable()
@State<PermitStepStateModel>({
    name: "permitStep",
    defaults: defaults
})
export class PermitStepState {
    constructor(private permitStepService: PermitStepService) { }

    @Action(PermitStepActions.Reset)
    public reset(ctx: StateContext<PermitStepStateModel>) {
        ctx.setState(defaults);
    }

    @Action(PermitStepActions.StartCreate)
    public startCreate(ctx: StateContext<PermitStepStateModel>) {
        ctx.dispatch(new PermitCreateActions.ResetToDefault());
        ctx.setState(defaults);
        ctx.patchState({
            currentStep: {
                step: PermitStep.isFlammableHotWork,
            },
        });
    }

    @Action(PermitStepActions.GetCurrentStep)
    public getCurrentStep(ctx: StateContext<PermitStepStateModel>, action: PermitStepActions.GetCurrentStep) {
        const permitId = !action.permitId ? ctx.getState().permitId : action.permitId;
        return this.permitStepService.getCurrentStep(permitId).pipe(tap(result => {
            ctx.setState(produce(draft => {
                draft.currentStep = result;
                draft.permitId = permitId;
            }));
        }));
    }

    @Action(PermitStepActions.GoBackwards)
    public previousStep(ctx: StateContext<PermitStepStateModel>, action: PermitStepActions.GoBackwards) {
        // Special case workplace -> isFlammableHotWork because no backend saving
        if (ctx.getState().currentStep.step === PermitStep.workplace) {
            return ctx.setState(produce(draft => {
                draft.currentStep.step = PermitStep.isFlammableHotWork;
            }));
        } else {
            const permitId = !action.permitId ? ctx.getState().permitId : action.permitId;

            return this.permitStepService.setPreviousStep(permitId).pipe(tap(result => {
                ctx.setState(produce(draft => {
                    draft.currentStep = result;
                    draft.permitId = permitId;
                }));
            }));
        }
    }

    @Action(PermitStepActions.GoForward)
    public nextStep(ctx: StateContext<PermitStepStateModel>, action: PermitStepActions.GoForward) {
        // Special case isFlammableHotWork -> workplace because no backend saving
        if (ctx.getState().currentStep.step === PermitStep.isFlammableHotWork) {
            return ctx.setState(produce(draft => {
                draft.currentStep.step = PermitStep.workplace;
            }));
        } else {
            const permitId = !action.permitId ? ctx.getState().permitId : action.permitId;
            return this.permitStepService.setNextStep(permitId).pipe(tap(result => {
                ctx.setState(produce(draft => {
                    draft.currentStep = result;
                    draft.permitId = permitId;
                }));
            }));
        }
    }

    @Action(PermitStepActions.GoToValidity)
    public gotoValidity(ctx: StateContext<PermitStepStateModel>, action: PermitStepActions.GoForward) {
        var step = 260; //Validity Period
        const permitId = !action.permitId ? ctx.getState().permitId : action.permitId;
        return this.permitStepService.setNextStep(permitId).pipe(tap(result => {
            ctx.setState(produce(draft => {
                draft.currentStep = result;
                draft.permitId = permitId;
            }));
        }));
    }
}
