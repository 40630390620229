import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PermitPublicStatus, PersonRole } from "@ha/data/basic";
import { HaModalService } from "@ha/ui/common";
import { TranslocoService } from "@ngneat/transloco";

import { PermitListItem } from "../../models/permit-list-item.model";
import {
    RemovePlannedPermitModalComponent,
    RemovePlannedPermitModalComponentData,
} from "../remove-planned-permit-modal/remove-planned-permit-modal.component";

@Component({
    selector: "pmt-permit-list-item",
    templateUrl: "./permit-list-item.component.html",
    styleUrls: ["./permit-list-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitListItemComponent {
    @Input() public item!: PermitListItem;

    public PermitPublicStatus = PermitPublicStatus;
    public PersonRole = PersonRole;

    constructor(private haModalService: HaModalService, private translocoService: TranslocoService) { }

    public get roles() {
        return this.item.roles.map(_ => this.translocoService.translate("permit.permitRole." + _.toString())).join(", ");
    }

    public get isIssuer(): boolean {
        return this.item.roles.some(_ => _ == PersonRole.PermitIssuer);
    }

    public get routerLink() {
        if (this.isNotFlammableAndCompleted || this.isDemoAndCompleted) {
            return undefined;
        }

        return [this.item.id];
    }

    public get isNotFlammableAndCompleted() {
        return !this.item.isFlammableHotWork && this.item.publicStatus === PermitPublicStatus.completed;
    }

    public get isDemoAndCompleted() {
        return this.item.isDemo && this.item.publicStatus === PermitPublicStatus.completed;
    }

    public remove(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.haModalService.open(RemovePlannedPermitModalComponent, true, <RemovePlannedPermitModalComponentData>{ permitId: this.item.id, workplace: this.item.workplace });
    }

    public fullPermitName(item: PermitListItem): string {
        if (item.workplaceContainer == item.workplace) {
            return item.workplace;
        } else {
            return item.workplace?.length > 0 ? item.workplaceContainer + " - " + item.workplace : item.workplaceContainer;
        }
    }
}
