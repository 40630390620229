import { Routes } from "@angular/router";
import { NotificationRoutes } from "@ha/feature/notification";
import { AutoLoginAllRoutesGuard } from "angular-auth-oidc-client";

import { ApplicationPaths } from "./constants/application-paths";
import { Http404Component } from "./core/components/Http404/http-404.component";
import { KnowledgeBankRoutes } from "./knowledge-bank/knowledge-bank.module";

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const routes: Routes = [
    {
        path: "",
        loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [AutoLoginAllRoutesGuard],
    },
    {
        path: ApplicationPaths.Certificate.Root,
        loadChildren: () => import("./certificate/certificate.module").then((m) => m.CertificateModule),
        canActivate: [AutoLoginAllRoutesGuard],
        data: { title: "titles.certificate" },
    },
    {
        path: ApplicationPaths.User.Root,
        loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
        canActivate: [AutoLoginAllRoutesGuard],
        data: { title: "titles.user" },
    },
    {
        path: ApplicationPaths.PasswordResetSupport,
        loadChildren: () =>
            import("./password-reset-support/password-reset-support.module").then((m) => m.PasswordResetSupportModule),
        data: { title: "titles.passwordResetSupport" },
    },
    {
        path: ApplicationPaths.PasswordResetLink,
        loadChildren: () =>
            import("./password-reset-link/password-reset-link.module").then((m) => m.PasswordResetLinkModule),
        data: { title: "titles.passwordResetLink" },
    },
    {
        path: ApplicationPaths.PasswordForgot,
        loadChildren: () => import("./password-forgot/password-forgot.module").then((m) => m.PasswordForgotModule),
        data: { title: "titles.passwordForgot" },
    },
    {
        path: ApplicationPaths.Education.Root,
        loadChildren: () => import("./education/education.module").then((m) => m.EducationModule),
        canActivate: [AutoLoginAllRoutesGuard],
        data: { title: "titles.education", autoBreadcrumbsDisabled: true },
    },
    {
        path: ApplicationPaths.UserActivate,
        loadChildren: () => import("./user-activate/user-activate.module").then((m) => m.UserActivateModule),
        data: { title: "titles.userActivate" },
    },
    {
        path: ApplicationPaths.PublicEducationEvents,
        loadChildren: () =>
            import("@ha/feature/public-education-events").then((m) => m.FeaturePublicEducationEventsModule),
        canActivate: [AutoLoginAllRoutesGuard],
        data: { title: "titles.publicEducationEvents" },
    },
    {
        path: ApplicationPaths.Permit.Root,
        loadChildren: () => import("@ha/feature/permit").then((m) => m.FeaturePermitModule),
        canActivate: [AutoLoginAllRoutesGuard],
        data: { title: "titles.permit" },
    },
    ...NotificationRoutes,
    {
        path: ApplicationPaths.Support,
        loadChildren: () => import("@ha/feature/support").then((m) => m.HaFeatureSupportModule),
        canActivate: [AutoLoginAllRoutesGuard],
        data: { title: "titles.support" },
    },
    {
        path: ApplicationPaths.Install,
        loadChildren: () => import("./install/install.module").then((m) => m.InstallModule),
        canActivate: [AutoLoginAllRoutesGuard],
        data: { title: "titles.install" },
    },
    ...KnowledgeBankRoutes,
    {
        path: "**",
        component: Http404Component,
        data: { title: "titles.http404" },
    },
];
