import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Workplace } from "@ha/data/basic";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { IssuerStructureNode } from "../../../models/issuer-structure-node.model";
import { PermitWorkplaceActions } from "../../../states/permit-workplace.actions";
import { PermitWorkplaceSelectors } from "../../../states/permit-workplace.selectors";

@Component({
    selector: "pmt-workplace-search",
    templateUrl: "workplace-search.component.html",
    styleUrls: ["workplace-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class WorkplaceSearchComponent {
    @Select(PermitWorkplaceSelectors.searchResult)
    public searchResult$!: Observable<(IssuerStructureNode | Workplace)[]>;

    public query: string;
    public sortedByNameDesc = false;

    constructor(private store: Store) { }

    public search(query: string) {
        this.store.dispatch(new PermitWorkplaceActions.SearchWorkplace(query));
    }

    public clearSearchResults() {
        this.query = "";
        this.store.dispatch(new PermitWorkplaceActions.SearchWorkplace(""));
    }

    public toggleSort() {
        this.sortedByNameDesc = !this.sortedByNameDesc;
        this.store.dispatch(new PermitWorkplaceActions.GetCurrentIssuerCompanyStructure(this.sortedByNameDesc));
    }
}
