<div class="comment" *transloco="let t; read:'permit.details.comments'">
    <div class="comment__avatar" [class]="'comment__avatar--' + comment.avatarId">
        {{ comment.initials }}
    </div>
    <div class="comment__content">
        <div class="comment__content__text">{{ comment.text }}</div>
        <div class="comment__content__author">{{ comment.fullName }} {{ comment.created | haDate:"dateTimeStandard" }}</div>
        <div class="comment__content__remove" *ngIf="canDelete" (click)="remove()"></div>
    </div>
</div>
