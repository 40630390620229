import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Navigate } from "@ngxs/router-plugin";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { BehaviorSubject, delay, Observable, of, repeat } from "rxjs";

import { PermitQuestionActions } from "../../permit-create/states/permit-question.actions";
import { PermitListItem } from "../../permit-list/models/permit-list-item.model";
import { PermitListType } from "../../permit-list/models/permit-list-type.enum";
import { PermitListActions } from "../../permit-list/states/permit-list.actions";
import { PermitListSelectors } from "../../permit-list/states/permit-list.selectors";
import { PermitPaths } from "../../permit-paths";
import { PermitStepActions } from "../../permit/states/permit-step.action";
import { PermitUserActions } from "../../shared/states/permit-user.action";
import { PermitUserSelectors } from "../../shared/states/permit-user.selectors";
import { SubscriptionsSelectors } from "../../subscriptions/states/subscriptions.selectors";
import { HaModalService } from "@ha/ui/common";
import { PermitRootFilterModalComponent } from "./permit-root-filter-modal/permit-root-filter-modal.component";

@UntilDestroy()
@Component({
    selector: "pmt-root",
    templateUrl: "permit-root.component.html",
    styleUrls: ["permit-root.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitRootComponent implements OnInit {
    @Select(PermitUserSelectors.haveAccessToPermit)
    public haveAccessToPermit$!: Observable<boolean>;

    @Select(SubscriptionsSelectors.hasActiveSubscription)
    public hasActiveSubscription$!: Observable<boolean>;

    @Select(PermitUserSelectors.isHotworkerOrPremiumIssuer)
    public isHotworkerOrPremiumIssuer$!: Observable<boolean>;

    @Select(PermitUserSelectors.showCreateDemoButton)
    public showCreateDemoButton$!: Observable<boolean>;

    @Select(PermitListSelectors.completedPermits)
    public completedPermits$!: Observable<PermitListItem[]>;

    @Select(PermitListSelectors.ongoingPermits)
    public ongoingPermits$!: Observable<PermitListItem[]>;

    @Select(PermitListSelectors.filteredPermits)
    public filteredPermits$!: Observable<PermitListItem[]>;

    @Select(PermitListSelectors.plannedPermits)
    public plannedPermits$!: Observable<PermitListItem[]>;

    @Select(PermitListSelectors.showAllSubscriptionPermits)
    public showAllSubscriptionPermits$: Observable<false>;

    @Select(PermitListSelectors.selectedWorkplaces)
    public selectedWorkplaces$!: Observable<string[]>;

    public PermitListType = PermitListType;
    public selectedListType = PermitListType.Ongoing;
    public PermitPaths = PermitPaths;

    public loading$ = new BehaviorSubject<boolean>(true);
    public showAllSubscriptionPermits = false;

    constructor(private store: Store, private modalService: HaModalService, private actions$: Actions) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitUserActions.GetCurrentPerson());

        this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(PermitUserActions.GetCurrentPerson)).subscribe(() => {
            if (this.store.selectSnapshot(PermitUserSelectors.currentPerson)) {
                this.store.dispatch(new PermitListActions.GetAllOngoing()).subscribe(() => {
                    this.loading$.next(false);
                });
                this.store.dispatch(new PermitListActions.GetAllPlanned());
            }
        });

        // Get current person every 60 seconds.
        // Update current person and also to trigger fetching every thing else like permits to sign and invitations.
        of(null).pipe(delay(60 * 1000), repeat(), untilDestroyed(this)).subscribe(() => {
            this.store.dispatch(new PermitUserActions.GetCurrentPerson());
        });
    }

    public create(): void {
        this.store.dispatch(new PermitStepActions.StartCreate());
        this.store.dispatch(new PermitQuestionActions.ResetToDefault());
        this.store.dispatch(new Navigate([PermitPaths.Root, PermitPaths.Create]));
    }

    public createDemo(): void {
        this.store.dispatch(new PermitStepActions.StartCreate());
        this.store.dispatch(new PermitQuestionActions.ResetToDefault());
        this.store.dispatch(new Navigate([PermitPaths.Root, PermitPaths.CreateDemo]));
    }

    public changeTab(listType: PermitListType): void {
        this.selectedListType = listType;

        if (listType == PermitListType.Completed) {
            this.store.dispatch(new PermitListActions.GetAllCompleted());
        }
    }

    public preparePermit(): void {
        this.store.dispatch(new Navigate([PermitPaths.Root, PermitPaths.Prepare]));
    }

    public showSubscriptionPermits(event: MouseEvent): void {
        event.preventDefault();
        this.showAllSubscriptionPermits = !this.showAllSubscriptionPermits;
        this.loading$.next(true);
        this.store.dispatch(new PermitListActions.ToggleShowAllPermits()).subscribe(() => {
            if (!this.showAllSubscriptionPermits) {
                this.store.dispatch(new PermitListActions.UpdateFilter([]));
            }

            this.store.dispatch(new PermitListActions.GetAllOngoing()).subscribe(() => {
                this.loading$.next(false);
            });
        });
    }

    public toggleFilter(event: MouseEvent): void {
        event.stopPropagation();

        this.modalService.open(
            PermitRootFilterModalComponent,
            true,
            <PermitRootFilterModalComponent>{

            }
        );
    }
}
