import { Pipe, PipeTransform } from "@angular/core";
import { Translation } from "@ha/data/basic";
import { Store } from "@ngxs/store";

@Pipe({
    name: "haTranslate",
})
export class HaTranslatePipe implements PipeTransform {
    constructor(private store: Store) {
    }

    public transform(value: Translation[] | undefined): string {
        if (!value) {
            return "";
        }

        const currentLanguage = this.store.selectSnapshot<string>((_: { settings: { currentLanguage: string; }; }) =>
            _.settings.currentLanguage
        );

        return value.find((_) => _.language === currentLanguage)?.text ?? "Error haTranslate";
    }
}
