import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { CurrentUserState } from "../../../core/states/current-user.state";
import { BookmarksActions } from "../../state/bookmarks.actions";
import { BookmarksState } from "../../state/bookmarks.state";

@Component({
    selector: "bookmarks-panel",
    templateUrl: "./bookmarks-panel.component.html",
    styleUrls: ["./bookmarks-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarksPanelComponent {
    @Select(CurrentUserState.isExternal)
    public currentUserIsExternal$: Observable<boolean>;

    @Select(BookmarksState.bookmarksExpanded)
    public bookmarksExpanded$: Observable<boolean>;

    @Select(BookmarksState.bookmarksCount)
    public bookmarksCount$: Observable<number>;

    constructor(private store: Store) {}

    public toggleBookmarksOpen(): void {
        this.store.dispatch(new BookmarksActions.ToggleBookmarksPanelExpanded());
    }
}
