<div *transloco="let t; read: 'filterMenu'" class="filter">
    <div class="filter__bar">
        <div (click)="toggleMenu($event)" class="filter__bar__button">
            <div class="filter__bar__button__icon"></div>
            <div class="filter__bar__button__text">{{ t("title") }}</div>
        </div>
        <div class="filter__bar__count">{{ filterCount }}</div>
        <div class="filter__bar__clear" (click)="clear()">{{ t("clear") }}</div>
    </div>
    <div *ngIf="showMenu" (clickOutside)="hideMenu()" class="filter__menu">
        <ng-content></ng-content>
    </div>
</div>
