import { Selector } from "@ngxs/store";

import { CurrentUser } from "../models/current-user.model";
import { Settings } from "../models/settings.model";
import { CurrentUserState } from "./current-user.state";
import { SettingsState } from "./settings.state";

export class InitSelector {
    @Selector([SettingsState.settings, CurrentUserState.currentUser])
    public static isAppInitialized(settings: Settings, currentUser: CurrentUser): boolean {
        return !!settings && !!currentUser;
    }
}
