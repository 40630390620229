import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BaseModalComponent, LanguageStateSelectors } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { Observable, take } from "rxjs";

import { UserAgreement } from "../../models/user-agreement";
import { UserAgreementActions } from "../../states/user-agreement.actions";


export interface AcceptUserAgreementOnIssuerUnitModalComponentData {
    userAgreement: UserAgreement;
}

@UntilDestroy()
@Component({
    templateUrl: "./user-agreement-modal.component.html",
    styleUrls: ["./user-agreement-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcceptUserAgreementOnIssuerUnitModalComponent extends BaseModalComponent implements OnInit {
    @Select(LanguageStateSelectors.currentLanguage)
    public currentLanguage$: Observable<string>;

    public data: AcceptUserAgreementOnIssuerUnitModalComponentData;

    public UserAgreementActionsAcceptUserAgreement = UserAgreementActions.AcceptUserAgreementOnIssuerUnit;

    constructor(private store: Store, private actions$: Actions) {
        super();
    }

    public ngOnInit(): void {
        this.actions$.pipe(
            untilDestroyed(this),
            ofActionSuccessful(UserAgreementActions.AcceptUserAgreementOnIssuerUnit, UserAgreementActions.RejectAgreementOnIssuerUnit),
            take(1)
        ).subscribe(() => {
            this.closeModal();
        });
    }

    public accept(): void {
        this.store.dispatch(new UserAgreementActions.AcceptUserAgreementOnIssuerUnit());
    }

    public reject(): void {
        this.store.dispatch(new UserAgreementActions.RejectAgreementOnIssuerUnit());
    }
}

